import axios from 'axios';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
type Props={
    userId: string | undefined;
    ARC_member: string | undefined;
    ARC_no: string | undefined;
    }
const GenerateFinalReport = ({ userId ,ARC_member,ARC_no }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [allAssessorPlannerData, setAllAssessorPlannerData] = useState<any>([])
    console.log("allAssessorPlannerData:",allAssessorPlannerData);

    const [recommendedLoaction, setRecommendedLoaction] = useState<any>()
    const [otherLoaction, setOtherLoaction] = useState<any>()
    const [selectedReportCheck, setSelectedReportCheck] = useState<any>()
    const [reportTextValue, setReportTextValue] = useState<any>()
    const [permissionCheck, setPermissionCheck] = useState<any>()
    const [permissionText, setPermissionText] = useState<any>()
    const [anyCondition, setAnyCondition] = useState<any>()
    const [arcReportDate, setArcReportDate] = useState<any>()
    const [arcMemberName, setARCMemberName] = useState<any>()
    const [finalReportId, setFinalReportId] = useState<any>()
    const [signatureImageData, setSignatureImageData] = useState<any>();
    const [countryList, setCountryList] = useState<any>();
    const [selectedCountryIds, setSelectedCountryIds] = useState<any>([])
    const [scopeData, setScopeData] = useState<any>([])
    console.log("scopeData:",scopeData);

    const formatDate = (dateString: string) => {
        return moment(dateString).format('MM/DD/YYYY')
    }
    const fetchCountryData = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/certificate/list/country/`
            )
            setCountryList(response.data)
        } catch (error) {
            ////console.log(error)
        }
    }

    useEffect(() => {
        const fetchARCDetails = async () => {
            if (userId) {
                try {
                    ////console.log(`Fetching Witness review for CAD_id: ${CAD_id}`);
                    const reviewResponse = await axios.get(`${API_URL}/arc/management/final/report/filter/?cab=${userId}&assessor=${ARC_member}&cluster=&country=&create_at=&update_at=`);
                    console.log('fetchARCDetails:', reviewResponse?.data);
                    // setWitnessReviewData(reviewResponse?.data);
                    setSelectedCountryIds(reviewResponse?.data[0]?.country)
                    setOtherLoaction(reviewResponse?.data[0]?.other_location)
                    setRecommendedLoaction(reviewResponse?.data[0]?.recommended_location)
                    setPermissionCheck(reviewResponse?.data[0]?.accredited_permission)
                    setAnyCondition(reviewResponse?.data[0]?.any_arc_condition)
                    setPermissionText(reviewResponse?.data[0]?.accredited_permission_treason)
                    setSelectedReportCheck(reviewResponse?.data[0]?.accredited_check)
                    setReportTextValue(reviewResponse?.data[0]?.accredited_text)
                    setArcReportDate(reviewResponse?.data[0]?.arc_report_date)
                    setARCMemberName(reviewResponse?.data[0]?.arc_member_name)
                    setFinalReportId(reviewResponse?.data[0]?.id)
                    setSignatureImageData(reviewResponse?.data[0]?.arc_member_sign)
                } catch (error) {
                    //console.log(error);
                }
            }
        };

        fetchARCDetails();
    }, [userId]);
    const getScopeData = async () => {
        try {
            const response = await axios.get(
                `${API_URL}/account/cab/scheme/filter/?id=${userId}`
            )
            setScopeData(response.data)
        } catch (error) {
            ////console.log(error)
        }
    }
    useEffect(() => {
        fetchCountryData();
        getScopeData();
    }, [])
    return (

        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>
                        ARC Member Recommendation
                    </h3>
                </div>
            </div>
            <div className='card-body p-9'>

                <div className='mb-5 col-lg-12'>
                    {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
                    <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                        <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th className=' pe-2 fs-7'>Schemes(s)</th>
                                <th className=' pe-2 fs-7'>Select Scopes / Technical Area</th>

                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                            {scopeData[0]?.scheme_with_cluster?.map((item) => (
                                <tr key={item.scheme_id}>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {item?.scheme_name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <div>
                                                <ul>
                                                    {item?.clusters?.map((value=>
                                                        <li>
                                                           { value.name}
                                                        </li>
                                               )) }

                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row mt-10">
                    <div className=''>
                        <div className='mb-5 col-lg-12'>
                            <h5>Countries where UAF Accreditation Certificates can be issued  </h5>
                            <ul>

                                {countryList
                                    ?.filter(item => selectedCountryIds?.includes(item.id))
                                    ?.map(item => (
                                        <li>
                                            {item?.name}
                                        </li>
                                    ))}


                            </ul>
                        </div>
                    </div>
                </div>
                <div className='mt-10'>
                    <div className='mb-5 col-lg-12'>
                        <h5>Other Key Locations Recommended  </h5>
                        {recommendedLoaction}
                    </div>
                </div>
                <div className='mt-10'>
                    <div className='mb-5 col-lg-12'>
                        <h5>Other Locations Recommended (marketing etc Non-Key)  </h5>
                        {otherLoaction}
                    </div>
                </div>
                <div className='row mt-10'>
                    <div className='mb-5 col-lg-12'>
                        <h3>ARC Member Reports:</h3>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                            <thead>
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th style={{ width: "50%" }} className='pe-2 fs-7'><span style={{ display: "none" }}> Q</span></th>
                                    <th style={{ width: "50%" }} className='pe-2 fs-7'><span style={{ display: "none" }}>A</span></th>

                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        <h5>Accreditation is to be:</h5>
                                        <div className='text-gray-800 mb-1'>
                                            <div className=''>
                                                <input
                                                    type="radio"
                                                    id="granted"
                                                    name="options"
                                                    value="Granted"
                                                    checked={permissionCheck === 'Granted'}
                                                    readOnly
                                                />
                                                <label style={{ marginLeft: "5px" }}>Granted</label>
                                            </div>
                                            <div className=''>
                                                <input
                                                    type="radio"
                                                    id="maintained"
                                                    name="options"
                                                    value="Maintained"
                                                    checked={permissionCheck === 'Maintained'}
                                                    readOnly
                                                />
                                                <label style={{ marginLeft: "5px" }}>Maintained</label>
                                            </div>
                                            <div className=''>
                                                <input
                                                    type="radio"
                                                    id="reinstated"
                                                    name="options"
                                                    value="Reinstated"
                                                    checked={permissionCheck === 'Reinstated'}
                                                    readOnly
                                                />
                                                <label style={{ marginLeft: "5px" }}>Reinstated</label>
                                            </div>
                                            <div className=''>
                                                <input
                                                    type="radio"
                                                    id="expanded"
                                                    name="options"
                                                    value="Expanded"
                                                    checked={permissionCheck === 'Expanded'}
                                                    readOnly
                                                />
                                                <label style={{ marginLeft: "5px" }}>Expanded</label>
                                            </div>
                                        </div>
                                    </div>

                                    </td>
                                    <td>

                                        <div className='text-gray-800 mb-1'>
                                            {permissionText}
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td ><div className='text-gray-800 mb-1'>
                                        <h5> Subject to the conditions, if any:</h5>
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {anyCondition}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <h5>In case the Accreditation is not granted, the Reasons for:</h5>


                                            <div className='text-gray-800 mb-1'>
                                                <div className=''>
                                                    <input
                                                        type="radio"
                                                        id="not_granted"
                                                        name="reportOptions"
                                                        value="Not Granted"
                                                        checked={selectedReportCheck === 'Not Granted'}
                                                        readOnly
                                                    />
                                                    <label style={{ marginLeft: "5px" }}>Not Granted</label>
                                                </div>
                                                <div className=''>
                                                    <input
                                                        type="radio"
                                                        id="withdrawn"
                                                        name="reportOptions"
                                                        value="Withdrawn"
                                                        checked={selectedReportCheck === 'Withdrawn'}
                                                        readOnly
                                                    />
                                                    <label style={{ marginLeft: "5px" }}>Withdrawn</label>
                                                </div>
                                                <div className=''>
                                                    <input
                                                        type="radio"
                                                        id="suspended"
                                                        name="reportOptions"
                                                        value="Suspended"
                                                        checked={selectedReportCheck === 'Suspended'}
                                                        readOnly
                                                    />
                                                    <label style={{ marginLeft: "5px" }}>Suspended</label>
                                                </div>
                                                <div className=''>
                                                    <input
                                                        type="radio"
                                                        id="reduced"
                                                        name="reportOptions"
                                                        value="Reduced"
                                                        checked={selectedReportCheck === 'Reduced'}
                                                        readOnly
                                                    />
                                                    <label style={{ marginLeft: "5px" }}>Reduced</label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {reportTextValue}
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='row mt-10'>
                    <div className='mb-5 col-lg-12'>
                        <h3>Details of ARC Member :</h3>
                        <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
                            <thead>
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th className='pe-2 fs-7'>Name</th>
                                    <th className='pe-2 fs-7'>Date of Review</th>
                                    <th className='pe-2 fs-7'>Signature</th>
                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>
                                <tr>
                                    <td >
                                        <div className='text-gray-800 mb-1'>
                                            {arcMemberName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            {arcReportDate && arcReportDate ? formatDate(arcReportDate) : '--'}

                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <img src={signatureImageData} alt="ARC Member Signature" />
                                            {/* {signatureImageData} */}
                                        </div>
                                    </td>

                                </tr>


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>



    )
}

export default GenerateFinalReport