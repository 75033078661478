import axios from 'axios'
import React, {useEffect, useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import {toast} from 'react-toastify'
const BASE_URL = process.env.REACT_APP_API_URL
const AddReport = () => {
  const [allUserData, setAllUserData] = useState<any[]>([])
  const [countryList, setCountryList] = useState<any>()
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const [userData, setUserData] = useState<any>()

  const [addReportDetail, setAddReportDetail] = useState({
    cab: '',
    scheme: '',
    country: '',
    countryName: '',
    year: '',
    surveillance: '',
    auditor_count: '',
    audits: '',
    mandays: '',
  })

  // get country list

 

  useEffect(() => {
    const fetchCAB = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/account/approved/cab/user/`)
        setAllUserData(response?.data)
      } catch (error) {
        console.error('Error fetching cluster data:', error)
      }
    }
    fetchCAB()
  }, [])

  const handleUserChange = (selectedOption: {value: string; label: string} | null) => {
    if (selectedOption) {
      setSelectedUserId(selectedOption.value)
    }
  }

  const getUserDetails = () => {
    axios
      .get(`${BASE_URL}/account/patch/user/${selectedUserId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/country/list/?cab=${selectedUserId}`)
      setCountryList(response.data.certificates)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectedUserId) {
      getUserDetails()
      fetchCountryData()
    }
  }, [selectedUserId])

  const handleSchemeChange = (e) => {
    setAddReportDetail({...addReportDetail, scheme: e.value})
  }

  const handleCountryChange = (e) => {
    setAddReportDetail({
      ...addReportDetail,
      country: e?.value,
      countryName: e?.label,
    })
  }
  const ValidateFields = () => {
    if (!addReportDetail?.scheme) {
      toast.error('Please Enter Scheme')
      return false
    }
    if (!addReportDetail?.year) {
      toast.error('Please Enter Year')
      return false
    }
    return true
  }

  const handleAddReport = async () => {
    // Check if fields are valid
    if (!ValidateFields()) return

    // Prepare the updated report details
    const updatedReportDetail = {
      ...addReportDetail,
      cab: selectedUserId,
    }
    console.log('handle function hit')

    try {
      // Make the POST request to create a new report
      const response = await axios.post(`${BASE_URL}/certificate/audit/create/`, updatedReportDetail)

      // Check if the response indicates success
      if (response.status === 200 || response.status === 201) {
        toast.success('Report Created Successfully')
      } else {
        // Handle unexpected response status
        toast.error('Failed to create report. Please try again.')
      }
    } catch (error: any) {
      // Log the error for debugging purposes
      console.error('Error creating report:', error)

      // Provide feedback to the user
      if (error.response && error.response.data && error.response.data.message) {
        // If there's a specific error message from the server
        toast.error(`Error: ${error.response.data.message}`)
      } else {
        // Generic error message
        toast.error('An error occurred while creating the report. Please try again.')
      }
    }
  }

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Report</h3>
        </div>
      </div>
      <div className='card-body p-9'>
        <div className='row'>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className='required form-label'>CAB</label>
              <Select
                id='select_role_for_member'
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select User'
                options={allUserData?.map((item) => ({
                  label: `${item?.company}`,
                  value: item?.id,
                }))}
                onChange={handleUserChange}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className='required form-label'>Scheme</label>
              <Select
                // isMulti
                className='react-select-styled react-select-solid '
                name='User'
                classNamePrefix='react-select'
                options={userData?.scheme_names?.map((i) => ({label: i?.name, value: i?.id}))}
                onChange={(e) => handleSchemeChange(e)}
                value={userData?.scheme_names
                  ?.filter((item) => addReportDetail?.scheme?.includes(item.id))
                  ?.map((item) => ({label: item.name, value: item.id}))}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className='required form-label'>Country</label>
              <Select
                className='react-select-styled react-select-solid '
                classNamePrefix='react-select'
                options={countryList?.map((item) => ({value: item?.country, label: item?.country__name}))}
                placeholder='Select Country'
                onChange={(e) => handleCountryChange(e)}
                value={{
                  value: addReportDetail?.country,
                  label: countryList?.find((item) => item.country === addReportDetail?.country)?.country__name,
                }}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className='required form-label'>Report Year</label>
              <ReactDatePicker
                className='react_date_picker form-control form-control-solid'
                selected={addReportDetail?.year ? new Date(Number(addReportDetail.year), 0) : null} 
                placeholderText='--'
                onChange={(date) => {
                  if (date) {
                    setAddReportDetail({
                      ...addReportDetail,
                      year: date.getFullYear(), 
                    })
                  }
                }}
                showYearPicker
                dateFormat='yyyy'
                yearDropdownItemNumber={15}
                scrollableYearDropdown
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className=' form-label'>Surveillance certificates</label>
              <input
                className='form-control form-control-solid'
                name='User'
                onChange={(e) =>
                  setAddReportDetail({
                    ...addReportDetail,
                    surveillance: e?.target?.value,
                  })
                }
                value={addReportDetail?.surveillance}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className=' form-label'>Auditors</label>
              <input
                className='form-control form-control-solid'
                name='User'
                onChange={(e) =>
                  setAddReportDetail({
                    ...addReportDetail,
                    auditor_count: e?.target?.value,
                  })
                }
                value={addReportDetail?.auditor_count}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className=' form-label'>Overdue Audits/Certificates</label>
              <input
                className='form-control form-control-solid'
                name='User'
                onChange={(e) =>
                  setAddReportDetail({
                    ...addReportDetail,
                    audits: e?.target?.value,
                  })
                }
                value={addReportDetail?.audits}
              />
            </div>
          </div>
          <div className='col-lg-4 fv-row'>
            <div className='mb-5'>
              <label className=' form-label'>Total Audit Mandays</label>
              <input
                className='form-control form-control-solid'
                name='User'
                onChange={(e) =>
                  setAddReportDetail({
                    ...addReportDetail,
                    mandays: e?.target?.value,
                  })
                }
                value={addReportDetail?.mandays}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row p-5'>
        <div className='mb-2 col-6 align-text-end'>
          <button className='thm-btn' type='button' onClick={handleAddReport}>
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddReport
