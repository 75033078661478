import React, {useCallback, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getUserDetails} from './VerifiedAssessorFunction'
import {useParams} from 'react-router-dom'
import {UserDataType} from '../../AssessorInterface'
import axios from 'axios'
import moment from 'moment'
import {Button, Modal} from 'react-bootstrap'
import ReviewDocumentModal from '../models/ReviewDocumentModal'
import Select from 'react-select'
import JobOrderPlanner from '../../../organisation/AssessmentPlanner/component/modal/JobOrderPlanner'
import {debounce, IconButton, Tooltip} from '@mui/material'
import AddReportModal from '../models/AddReportModal'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import SignaturePadComponent from '../../../organisation/AssessmentPlanner/component/SignaturePadComponent'
import {usePermissions} from '../../../../permission_component/PermissionContext'
interface AssessorIDProps {
  userId: string
}
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: {id: string; name: string}[]
  programme_names: {id: string; name: string}[]
  accessor_approve_date: string
  create_at: string
}

interface DocumentItem {
  report_url: string
  remark: string
}

const VerifiedAssessorAssessment: React.FC<AssessorIDProps> = ({userId}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [jobOrderModal, setJobOrderModal] = useState<boolean>(false)

  const [selectedAssessment, setSelectedAssessment] = useState<any>()
  console.log(selectedAssessment, 'this is coming selected assessment')
  const [accessorList, setAccessorList] = useState<PendingAssessor[]>([])
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()
  // get assessment list
  const fetchData = async () => {
    try {
      const response = await axios.get<PendingAssessor[]>(
        `${BASE_URL}/team/accessor/verified/list/`
      )
      setAccessorList(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const [userData, setUserData] = useState<UserDataType>()
  console.log(userData, 'this is userData')
  const [loading, setLoading] = useState(false)
  const [showDocumentReviewModal, setShowDocumentReviewModal] = useState<boolean>(false)
  const [addReportClick, setAddReportClick] = useState<boolean>(false)
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>(
    []
  )

  console.log(assessorPlannerDataFromAssessment, 'assessorPlannerDataFromAssessment')

  const [singleRow, setSingleRow] = useState<any>([])
  console.log(singleRow, 'this is coming single row')
  const [rowStatus, setRowStatus] = useState<any>()
  const [assessorDetail, setAssesorDetail] = useState<any>()
  console.log(assessorDetail, 'DETAIL')

  const [cabCompany, setCabCompany] = useState<{company: string; name: string}>({
    company: '',
    name: '',
  })

  const [signatureImageData, setSignatureImageData] = useState<any>()
  const [coiData, setcoiData] = useState<any>()
  const [coiCAB, setCoiCAB] = useState<any>()
  console.log(signatureImageData, 'this is data is coming')
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [showAssessor, setShowassessor] = useState(false)
  const [showCAB, setShowCAB] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')
  const [showmulti, setShowMulti] = useState(false)
  const [multipleDocuments, setMultipleDocuments] = useState<DocumentItem[]>([])

  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const handlePdfIconClick = (url) => {
    console.log(url, 'this url is coming')
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') 
  }

  const fetchSignature = async (assessment) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/agreement/signature/filter/assessor/?assessor=${urlId}&assessor_planner=${assessment}`
        //  `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}`
      )
      const result = response.data?.[0]
      setcoiData(result)
      setSignatureImageData(result?.signature)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCabSignature = async (cab, assessment) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/agreement/signature/filter/assessor/?cab=${cab}&assessor_planner=${assessment}`
        //  `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}`
      )
      const result = response.data?.[0]
      setcoiData(result)
      setSignatureImageData(result?.signature)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCOIreport = (item, assessment, cab) => {
    console.log(item, 'this is coming assessor')
    console.log(assessment, 'this is my cab')
    setAssesorDetail(item?.assessor)
    setCoiCAB(cab)
    fetchSignature(assessment)
    setShowassessor(true)
  }

  const handleClosereport = () => {
    setShowassessor(false)
    setAssesorDetail(null)
    setSignatureImageData(null)
    setcoiData(null)
  }

  const handleCloseCAB = () => {
    setShowCAB(false)
    setSignatureImageData(null)
    setcoiData(null)
    setAssesorDetail(null)
    setCabCompany({
      company: '',
      name: '',
    })
  }

  const handleCabreport = (cab, cab_name, company, assessment, assessoritem) => {
    setCabCompany((prevState) => ({
      ...prevState,
      company: company,
      name: cab_name, // Corrected this line to update the company in state
    }))
    fetchCabSignature(cab, assessment)
    setAssesorDetail(assessoritem?.assessor)
    setShowCAB(true)
  }

  console.log(assessorPlannerDataFromAssessment, 'assessorPlannerDataFromAssessment')
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserDetails(urlId)
        setUserData(result)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchData()
  }, [])
  const getAssessorDataByAssessment = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/filter/scheme/assessor/planner/pagination/?assessor=${urlId}&page=${pagenumber}`
        //  `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}`
      )
      const result = response.data.results
      result.sort((a, b) => {
        // Convert createdAt strings to Date objects for comparison
        const dateA = new Date(a.create_at).getTime()
        const dateB = new Date(b.create_at).getTime()

        // Compare the dates
        return dateB - dateA
      })

      setAssessorPlannerDataFromAssessment(result)
      const pages = Math.ceil(response?.data?.count / 10) // Each page has 10 items
      setTotalPages(pages)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAssessorDataByAssessment()
  }, [pagenumber])

  const handleStatus = (e, item) => {
    if (e.value === 'Completed') {
      setShowDocumentReviewModal(true)
      // You can do something with the item (row data) here
      setSingleRow(item)
      setRowStatus(e.value)
    }
  }
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page)
    }
  }

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value
      console.log(searchValue, 'searchValue')
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/cab/filter/scheme/assessor/planner/pagination/?search=${searchValue}&assessor=${urlId}`
          )

          if (response.status === 200) {
            setAssessorPlannerDataFromAssessment(response.data?.results)
          }
        } catch (error) {
          console.log(error)
        } finally {
          // setLoader(false);
        }
      } else {
        getAssessorDataByAssessment()
      }
    }, 500),
    []
  )

  const generatePDF = () => {
    const input = document.getElementById('pdfContent')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgProps = pdf.getImageProperties(imgData)
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width

        let heightLeft = imgHeight
        let position = 0

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
        heightLeft -= pdfHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
          heightLeft -= pdfHeight
        }

        pdf.save('COIReport.pdf')
      })
    } else {
      console.error("Element with id 'pdfContent' not found.")
    }
  }

  const handleshowMultipleReport = (docs) => {
    setMultipleDocuments(docs)
    setShowMulti(true)
  }
  const handleClosemulti = () => {
    setShowMulti(false)
    setMultipleDocuments([])
  }
  const renderDocument = () => {
    if (pdfUrl.endsWith('.png') || pdfUrl.endsWith('.jpg') || pdfUrl.endsWith('.jpeg')) {
      // Render image
      return (
        <>
          {' '}
          <img src={pdfUrl} alt='Image Preview' style={{width: '100%', height: 'auto'}} />
          <a href={pdfUrl} download style={{display: 'block', marginTop: '10px'}}>
          <button className='btn btn-primary btn-sm ' type='button'>Download</button>
          </a>
        </>
      )
    } else {
      // Render DOCX using Google Docs Viewer
      return (
        <>
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            title='DOCX Viewer'
            width='100%'
            height='500px'
          ></iframe>
          <a href={pdfUrl} download style={{display: 'block', marginTop: '10px'}}>
            
            <button className='btn btn-primary btn-sm ' type='button'>Download</button>
          </a>
        </>
      )
    }
  }

  const totalPagesArray = Array.from({length: totalPages}, (_, index) => index + 1)
  return (
    <div id='assessor_assement'>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Assessments</h3>
              </div>
              <div className='d-flex align-items-center position-relative my-1'>
                <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='17.0365'
                      y='15.1223'
                      width='8.15546'
                      height='2'
                      rx='1'
                      transform='rotate(45 17.0365 15.1223)'
                      fill='currentColor'
                    />
                    <path
                      d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
                <input
                  id='assessor_assessment'
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Search...'
                  onChange={handleSearchChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearchChange(e)
                    }
                  }}
                />
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row gy-10 gx-xl-10'>
                <div className='col-xl-12'>
                  <div className=' card-xl-stretch mb-5 mb-xl-10 ' id='kt_profile_details_view'>
                    <table
                      style={{margin: '0 0 0 25px'}}
                      className='table align-middle table-row-dashed fs-6 gy-5 location_table_show'
                      id='kt_table_users'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          <th>Assessment#</th>
                          <th className='min-w-125px'>Cab</th>
                          <th className='min-w-125px'>Scheme Name</th>
                          <th className='min-w-125px'>Assessment Type</th>
                          <th className='min-w-125px'>Assessment Remarks</th>
                          <th className='min-w-125px'>Assessment Duration</th>
                          <th className='min-w-125px'>Assessment Dates</th>
                          <th className='min-w-125px'>Major NC CAP Date</th>
                          <th className='min-w-125px'>Major NC Closure Date</th>
                          <th className='min-w-125px'>Minor NC CAP Date</th>
                          <th className='min-w-125px'>Minor NC Closure Date</th>
                          <th className='min-w-125px'>COI CAB</th>
                          <th className='min-w-125px'>COI ASSESSOR</th>
                          <th className='min-w-125px'>Job Order Status</th>
                          <th className='min-w-125px'>Job Order Number</th>
                          <th className='min-w-200px'>Status</th>
                          <th className='min-w-125px'>Scope / Technical Areas</th>
                          <th className='min-w-125px'>Report Status</th>
                          <th className='min-w-125px'>Report</th>
                          {/* <th className='min-w-125px'>Report Remarks</th> */}
                          <th className='min-w-125px'>Action</th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {assessorPlannerDataFromAssessment?.map((item, indexKey) => {
                          return (
                            <tr key={item?.id}>
                              <td>{item?.assessment_number}</td>
                              <td>{item?.company_name}</td>
                              {/* <td>{item?.cab_name}</td> */}
                              <td>{item?.scheme_short_name}</td>
                              <td>{item?.assessment_type}</td>
                              <td>{item?.remark}</td>
                              <td>{item?.assessment_duration}</td>
                              <td>
                                {item?.assessment_multi_date
                                  ? JSON.parse(item.assessment_multi_date?.replace(/'/g, '"'))?.map(
                                      (date, index) => (
                                        <React.Fragment key={index}>
                                          {moment(new Date(date)).format('DD/MMM/YYYY')}
                                          <br />
                                        </React.Fragment>
                                      )
                                    )
                                  : '--'}
                              </td>
                              <td>
                                {item?.major_nc_cab_start_date
                                  ? moment(new Date(item?.major_nc_cab_start_date)).format(
                                      'DD/MMM/YYYY'
                                    )
                                  : '--'}
                              </td>
                              <td>
                                {item?.major_nc_cab_closer_date
                                  ? moment(new Date(item?.major_nc_cab_closer_date)).format(
                                      'DD/MMM/YYYY'
                                    )
                                  : '--'}
                              </td>
                              <td>
                                {item?.minor_nc_cab_start_date
                                  ? moment(new Date(item?.minor_nc_cab_start_date)).format(
                                      'DD/MMM/YYYY'
                                    )
                                  : '--'}
                              </td>
                              <td>
                                {item?.minor_nc_cab_end_date
                                  ? moment(new Date(item?.minor_nc_cab_end_date)).format(
                                      'DD/MMM/YYYY'
                                    )
                                  : '--'}
                              </td>

                              <td>
                                {item?.assessor_data?.find(
                                  (i) => i?.assessor?.assessor_id === urlId
                                )?.coi_cab === true ? (
                                  <td>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-success fw-bold'>
                                        <img
                                          src='/media/doc.png'
                                          alt=''
                                          onClick={() =>
                                            handleCabreport(
                                              item?.cab,
                                              item?.cab_name,
                                              item?.company_name,
                                              item?.assessor_planner,
                                              item?.assessor_data?.find(
                                                (i) => i?.assessor?.assessor_id === urlId
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-danger fw-bold'>
                                        Pending...
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </td>
                              <td>
                                {item?.assessor_data?.find(
                                  (i) => i?.assessor?.assessor_id === urlId
                                )?.coi_assessor === true ? (
                                  <td>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-success fw-bold'>
                                        <img
                                          src='/media/doc.png'
                                          alt=''
                                          onClick={() =>
                                            handleCOIreport(
                                              item?.assessor_data?.find(
                                                (i) => i?.assessor?.assessor_id === urlId
                                              ),
                                              item?.assessor_planner,
                                              item?.company_name
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-danger fw-bold'>
                                        Pending...
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </td>
                              <td>
                                {item?.assessor_data?.find(
                                  (i) => i?.assessor?.assessor_id === urlId
                                )?.assessor_job_order?.[0]?.is_accept === true ? (
                                  <>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-success fw-bold'>
                                        Approved
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <td>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-danger fw-bold'>
                                        Pending...
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </td>

                              <td>
                                {item?.assessor_data?.find(
                                  (i) => i?.assessor?.assessor_id === urlId
                                )?.assessor_job_order?.length ? (
                                  <>
                                    <div className='badge badge-light fw-bolder'>
                                      <div className='badge badge-light-success fw-bold'>
                                        {
                                          item?.assessor_data?.find(
                                            (i) => i?.assessor?.assessor_id === urlId
                                          )?.assessor_job_order?.[0]?.job_order_number
                                        }
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  '--'
                                )}
                              </td>

                              <td>
                                <Select
                                  id={`assessor_assessment_${indexKey}`}
                                  className='react-select-styled react-select-solid '
                                  classNamePrefix='react-select'
                                  options={[
                                    {label: 'Pending', value: 'Pending'},
                                    {label: 'Completed', value: 'Completed'},
                                  ]}
                                  placeholder='Select'
                                  onChange={(e) => handleStatus(e, item)}
                                />
                              </td>

                              <td className='text-center'>
                                <Tooltip
                                  title={
                                    <ul>
                                      {item?.cluster_values?.length > 0 ? (
                                        <>
                                          {item?.cluster_values?.map((cluster, index) => (
                                            <li className='fs-5' key={index}>
                                              {cluster?.name}
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <span className='fs-5'>Select Scope / Technical area</span>
                                      )}
                                    </ul>
                                  }
                                >
                                  <IconButton>
                                    <i className='fas fa-info-circle'></i>
                                  </IconButton>
                                </Tooltip>
                              </td>

                              <td>{item?.status ? item?.status : 'Pending'}</td>

                              <td>
                                {item?.multi_docs ? (
                                  <button
                                    className='btn btn-primary btn-sm '
                                    type='button'
                                    onClick={() => handleshowMultipleReport(item?.multi_docs)}
                                  >
                                    Reports
                                  </button>
                                ) : (
                                  <div className='badge badge-light fw-bolder'>
                                    <div className='badge badge-light-danger fw-bold'>
                                      Pending...
                                    </div>
                                  </div>
                                )}
                                {/* {item?.report ? (
                                  <a
                                    id={`assessor_assessment_${indexKey}`}
                                    onClick={() => handlePdfIconClick(item?.report)}
                                    className='me-3'
                                  >
                                    <img src='/media/doc.png' alt='' />
                                  </a>
                                ) : (
                                  '--'
                                )} */}
                              </td>
                              {/* <td>{item?.report_remark ? item?.report_remark : '--'}</td> */}
                              <td>
                                <div className='dropdown'>
                                  <button
                                    className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                                    type='button'
                                    id='dropdownMenuButton'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    Actions
                                  </button>
                                  <ul
                                    className='dropdown-menu'
                                    aria-labelledby='dropdownMenuButton'
                                  >
                                    {/* <li><a className="dropdown-item" href="#">Edit</a></li>
                                                                        <li><a className="dropdown-item" href="#">Delete</a></li> */}
                                    {/* {!item?.job_order_number ?
                                                                            <li><a className="dropdown-item" href="#" onClick={() => { setSelectedAssessment(item); setJobOrderModal(true) }}>Create Job Order</a></li>
                                                                            : null
                                                                        } */}
                                    {item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.coi_cab === true &&
                                    item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.coi_assessor === true &&
                                    !item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.assessor_job_order?.length ? (
                                      <>
                                        {superUserPermission?.is_superuser === true && (
                                          <li>
                                            <a
                                              className='dropdown-item'
                                              id={`assessor_${indexKey}`}
                                              href='#'
                                              onClick={() => {
                                                setSelectedAssessment(item)
                                                setJobOrderModal(true)
                                              }}
                                            >
                                              Create Job Order
                                            </a>
                                          </li>
                                        )}
                                      </>
                                    ) : null}
                                    {item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.coi_cab === true &&
                                    item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.coi_assessor === true &&
                                    item?.assessor_data?.find(
                                      (i) => i?.assessor?.assessor_id === urlId
                                    )?.assessor_job_order?.length &&
                                    item?.status === 'Completed' ? (
                                      <li>
                                        <a
                                          id={`assessor_report_${indexKey}`}
                                          className='dropdown-item'
                                          href='#'
                                          onClick={() => {
                                            setSelectedAssessment(item)
                                            setAddReportClick(true)
                                          }}
                                        >
                                          Add Report
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <ul className='pagination'>
                      <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
                        <a
                          id='page_link_1'
                          className='page-link'
                          onClick={() => handlePageChange(pagenumber - 1)}
                        >
                          <i className='previous'></i>
                        </a>
                      </li>
                      {totalPagesArray.map((page, index) => (
                        <li
                          key={page}
                          className={`page-item ${pagenumber === page ? 'active' : ''}`}
                        >
                          <a
                            id={`assessor_page_${index}`}
                            className='page-link'
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ))}
                      <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
                        <a
                          id='page_link_2'
                          className='page-link'
                          onClick={() => handlePageChange(pagenumber + 1)}
                        >
                          <i className='next'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderDocument()}
          {/* Display the PDF in the modal */}
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
          {/* <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe> */}
        </Modal.Body>
      </Modal>
      <Modal show={showmulti} onHide={handleClosemulti} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table
            style={{margin: '0 0 0 25px'}}
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_table_users'
          >
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th>Sr No</th>
                <th>Report</th>
                <th className='min-w-125px'>Remarks</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {multipleDocuments?.map((item, index) =>
                item?.report_url && item?.remark ? (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src='/media/doc.png'
                        alt='documents'
                        onClick={() => handlePdfIconClick(item?.report_url)}
                        style={{cursor: 'pointer'}}
                      />
                    </td>
                    <td>{item?.remark}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal show={showAssessor} onHide={handleClosereport} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title> Assessor COI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container py-20' id='pdfContent'>
            <div className='row'>
              <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                <img
                  alt='Logo'
                  src={'/media/logos/uaf_logo.png'}
                  className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                />
                <div className='uaf_title py-5 text-center'>
                  <h2 className=''>UNITED ACCREDITATION FOUNDATION</h2>
                  {/* <span className="fs-18">JOB ORDER</span> */}
                </div>
              </div>
            </div>
            {/* qualification */}
            <div className=' mb-5 mb-xl-10'>
              <div className='card-header cursor-pointer app_fee_card'>
                <div className='card-title m-0 d-flex justify-content-center align-items-center w-100'>
                  <h2 className='fw-bolder pt-5 m-0 invoice_cabName'>
                    CONFIDENTIALITY, IMPARTIALITY AND NON- DISCLOSURE STATEMENT
                  </h2>
                </div>
                <div className='card-body px-9 py-5'>
                  I hereby agree that all information in relation to the {coiCAB} during the
                  Assessments whether it was gained by myself prior to, during or after the
                  assignment, will be treated by myself as confidential information and will not be
                  reproduced or disclosed to any person or organization.
                  <br />
                  <br />
                  I further agree that I will restrict the above-mentioned information exclusively
                  for purposes directed by UAF. Permission to disclose any information about a CAB
                  may only be deemed to have been granted once I have obtained unambiguous written
                  permission from UAF’s Director Accreditation to do so.
                  <br />
                  <br />
                  I also confirm that I am fully aware of my duties and obligations as per Terms and
                  conditions of the “Independent Contractor Agreement” concluded between myself and
                  UAF.
                  <br />
                  <br />
                  I also declare that, I have no possibility of a conflict of interest between
                  myself, my family with the CAB
                  <br />
                  <br />
                  I also declare that I have no interests and associations I have /had with the
                  said-mentioned CAB and its personnel, and also declare any actual or perceived
                  commercial, financial, political or other pressure/s that could influence a
                  decision, recommendation, the business of UAF and/or may be to my personal gain.
                  <br />
                  <br />
                  I further agree that I will uphold the principles of impartiality and
                  non-discrimination, to protect the integrity of UAF and the accreditation system
                  by:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Observing a non-discriminatory attitude towards the CAB in the assessment
                      processes
                    </li>
                    <li>Treating the CAB in a fair and equitable manner; and</li>
                    <li>
                      Not providing consultancy to the CAB, nor suggesting the use of consultants.
                    </li>
                  </ul>
                  <br />
                  <br />
                  I declare that I will inform UAF in case of any conflict or relationship arising
                  in the future that may affect impartiality of assessments with this CAB before
                  accepting any future assessment(s).
                  <br />
                  <br />
                  Name of Assessor: {assessorDetail?.first_name} {assessorDetail?.last_name}
                  <br />
                  Date: {moment(coiData?.update_at).format('MM/DD/YYYY')}
                  <br />
                  Signature of Assessor:
                  {/* <SignaturePadComponent setSignatureImageData={setSignatureImageData}/> */}
                  <img
                    src={signatureImageData}
                    alt='Signature'
                    style={{width: '250px', height: 'auto'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className='text-end m-5'>
          <button className='btn btn-primary' onClick={generatePDF}>
            Download
          </button>
        </div>
      </Modal>
      <Modal show={showCAB} onHide={handleCloseCAB} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title> CAB COI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container py-20' id='pdfContent'>
            <div className='row'>
              <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                <img
                  alt='Logo'
                  src={'/media/logos/uaf_logo.png'}
                  className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                />
                <div className='uaf_title py-5 text-center'>
                  <h2 className=''>UNITED ACCREDITATION FOUNDATION</h2>
                  {/* <span className="fs-18">JOB ORDER</span> */}
                </div>
              </div>
            </div>
            {/* qualification */}
            <div className=' mb-5 mb-xl-10'>
              <div className='card-header cursor-pointer app_fee_card'>
                <div className='card-title m-0 d-flex justify-content-center align-items-center w-100'>
                  <h2 className='fw-bolder pt-5 m-0 invoice_cabName'>
                    CONFIDENTIALITY, IMPARTIALITY AND NON- DISCLOSURE STATEMENT
                  </h2>
                </div>
                <div className='card-body px-9 py-5'>
                  On behalf of the {cabCompany?.company}, I declare that we have no relationship,
                  interests and associations we have /had with the {assessorDetail?.first_name}{' '}
                  {assessorDetail?.last_name} and her/his company or related bodies, and also
                  declare any actual or perceived commercial, financial, political or other
                  pressure/s that could influence her/his assessments, recommendation, the business
                  of UAF and/or may be to our personal gain.
                  <br />
                  <br />
                  We have no objection in her/his appointment as Assessor and we do not identify any
                  conflict of Interest.
                  <br />
                  <br />
                  We declare that we will inform UAF in case of any conflict or relationship arising
                  in the future that may affect impartiality of assessments with this assessor
                  before accepting any future assessment(s).
                  <br />
                  <br />
                  On behalf of CAB
                  <br />
                  <br />
                  CAB Organisation: {cabCompany?.company}
                  <br />
                  Name of authorized person:{cabCompany?.name}
                  <br />
                  Date: {moment(coiData?.update_at).format('MM/DD/YYYY')}
                  <br />
                  Signature:
                  <img
                    src={signatureImageData}
                    alt='Signature'
                    style={{width: '250px', height: 'auto'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className='text-end m-5'>
          <button className='btn btn-primary' onClick={generatePDF}>
            Download
          </button>
        </div>
      </Modal>
      <ReviewDocumentModal
        rowStatus={rowStatus}
        singleRow={singleRow}
        show={showDocumentReviewModal}
        handleClose={() => setShowDocumentReviewModal(false)}
        refresh={() => getAssessorDataByAssessment()}
      />
      <AddReportModal
        show={addReportClick}
        handleClose={() => setAddReportClick(false)}
        refresh={() => getAssessorDataByAssessment()}
        selectedAssessment={selectedAssessment}
      />

      <JobOrderPlanner
        selectedAssessment={selectedAssessment}
        show={jobOrderModal}
        handleClose={() => setJobOrderModal(false)}
        accessorList={accessorList}
        urlId={urlId}
        getAssessorDataByAssessment={getAssessorDataByAssessment}
      />
    </div>
  )
}
export {VerifiedAssessorAssessment}
