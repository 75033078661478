import React from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
import CreateAssessmentPlannerWrapper from "./components/CreateAssessmentPlannerWrapper";
const organisationBreadCrumbs: Array<PageLink> = [
    {
      title: 'Organisation',
      path: '/organisation/CreateAssessment',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const CreateAssessmentPlanner: React.FC = () => {
    return (<>
        
            <PageTitle breadcrumbs={organisationBreadCrumbs}>Create Assessment</PageTitle>
            <CreateAssessmentPlannerWrapper/>
           
      </>
    );
  };
  
  export default CreateAssessmentPlanner;