import React from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { AssessorDetails} from './components/AssessorDetails'
import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'

import { AssessorDoc } from './components/AssessorDoc'
import { AssessorSetting } from './components/AssessorSetting'
import AssessorHeader from './components/AssessorHeader'
import AssessorOverview from './components/AssessorOverview'
import AssessorDocDetails from './components/AssessorDocDetails'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Assessor',
    path: '/assessor/leads',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AssessorPage: React.FC = () => {

  const Id = useParams()
   const userId = JSON.stringify(Id)


  return(
    <Routes>
    <Route
      element={
        <>
          <AssessorHeader  userId={userId}/>
          <Outlet />
        </>
      }
    >
      <Route
        path=':id/overview'

        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assessor Details</PageTitle>
            <AssessorOverview userId={userId} />
          </>

        }
      />
      <Route
        path=':id/documents'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <AssessorDoc userId={userId}/>
          </>
        }
      />
       <Route
        path=':id/documents/details/:itemId'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <AssessorDocDetails />
          </>
        }
      />
       {/* <Route path="/details/:itemId" component={AssessorDocDetail} />
      /> */}
       <Route
        path=':id/settings'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Setting</PageTitle>
            <AssessorSetting userId={userId} />
          </>
        }
      />
    {/*  <Route
        path='/documents'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <Documents />
          </>
        }
      />
      <Route
        path=':id/connections'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assigned Team</PageTitle>
            <Connections />
          </>
        }
      /> */}
      <Route index element={<Navigate to='/assessor/overview' />} />
    </Route>
  </Routes>
  )
}


export default AssessorPage