import React, {useEffect, useState} from 'react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  uniqueCab: string | undefined
}
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count: string
}
interface Field {
  date: Date | null
  note: string
}

type AdditionalFields = Field[][]

const FSMSEdit = ({uniqueCab}: Props) => {
  const cabID = uniqueCab
  const [fsmsAnnexure, setFSMSAnnxeure] = useState({
    iaf_1_1: "A",
    iaf_1_2_1: "AI",
    iaf_1_2_2: "AII",
    iaf_1_3_1: "",
    iaf_1_3_2: "",
    iaf_1_4_1: "",
    iaf_1_4_2: "",
    iaf_1_5_1: "",
    iaf_1_5_2: "",
    iaf_1_6_1: "",
    iaf_1_6_2: "",
    iaf_1_7_1: "",
    iaf_1_7_2: "",

    iaf_1_8: "B",
    iaf_1_9_1: "BI", iaf_1_9_2: "BII", iaf_1_9_3: "BIII",
    iaf_1_10_1: "", iaf_1_10_2: "", iaf_1_10_3: "",
    iaf_1_11_1: "", iaf_1_11_2: "", iaf_1_11_3: "",
    iaf_1_12_1: "", iaf_1_12_2: "", iaf_1_12_3: "",
    iaf_1_13_1: "", iaf_1_13_2: "", iaf_1_13_3: "",
    iaf_1_14_1: "", iaf_1_14_2: "", iaf_1_14_3: "",

    iaf_1_15: "C",
    iaf_1_16_1: "C0", iaf_1_16_2: "CI", iaf_1_16_3: "CII", iaf_1_16_4: "CIII", iaf_1_16_5: "CIV",
    iaf_1_17_1: "", iaf_1_17_2: "", iaf_1_17_3: "", iaf_1_17_4: "", iaf_1_17_5: "",
    iaf_1_18_1: "", iaf_1_18_2: "", iaf_1_18_3: "", iaf_1_18_4: "", iaf_1_18_5: "",
    iaf_1_19_1: "", iaf_1_19_2: "", iaf_1_19_3: "", iaf_1_19_4: "", iaf_1_19_5: "",
    iaf_1_20_1: "", iaf_1_20_2: "", iaf_1_20_3: "", iaf_1_20_4: "", iaf_1_20_5: "",
    iaf_1_21_1: "", iaf_1_21_2: "", iaf_1_21_3: "", iaf_1_21_4: "", iaf_1_21_5: "",

    iaf_1_22: "D",
    iaf_1_23: "",
    iaf_1_24: "",
    iaf_1_25: "",
    iaf_1_26: "",
    iaf_1_27: "",
    iaf_1_28: "",

    iaf_1_29: "E",
    iaf_1_30: "",
    iaf_1_31: "",
    iaf_1_32: "",
    iaf_1_33: "",
    iaf_1_34: "",
    iaf_1_35: "",


    iaf_1_36: "F",
    iaf_1_37_1: "FI", iaf_1_37_2: "FII",
    iaf_1_38_1: "", iaf_1_38_2: "",
    iaf_1_39_1: "", iaf_1_39_2: "",
    iaf_1_40_1: "", iaf_1_40_2: "",
    iaf_1_41_1: "", iaf_1_41_2: "",
    iaf_1_42_1: "", iaf_1_42_2: "",


    iaf_1_43: "G",
    iaf_1_44: "",
    iaf_1_45: "",
    iaf_1_46: "",
    iaf_1_47: "",
    iaf_1_48: "",
    iaf_1_49: "",

    iaf_1_50: "H",
    iaf_1_51: "",
    iaf_1_52: "",
    iaf_1_53: "",
    iaf_1_54: "",
    iaf_1_55: "",
    iaf_1_56: "",

    iaf_1_57: "I",
    iaf_1_58: "",
    iaf_1_59: "",
    iaf_1_60: "",
    iaf_1_61: "",
    iaf_1_62: "",
    iaf_1_63: "",

    iaf_1_64: "J",
    iaf_1_65: "",
    iaf_1_66: "",
    iaf_1_67: "",
    iaf_1_68: "",
    iaf_1_69: "",
    iaf_1_70: "",

    iaf_1_71: "K",
    iaf_1_72: "",
    iaf_1_73: "",
    iaf_1_74: "",
    iaf_1_75: "",
    iaf_1_76: "",
    iaf_1_77: "",

    iaf_1_78: "L",
    iaf_1_79: "",
    iaf_1_80: "",
    iaf_1_81: "",
    iaf_1_82: "",
    iaf_1_83: "",
    iaf_1_84: "",



});

  const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])

  useEffect(() => {
    const fetchtransferCertificate = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=40f65889-bbe5-4784-9e10-38863a220be9`
        )
        settransferCertificate(response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6))
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchtransferCertificate()
  }, [])
  useEffect(() => {
    const getFSMSannexureData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/arc/anexxure/report/list/?cab=${cabID}&scheme=40f65889-bbe5-4784-9e10-38863a220be9`
        )
        if (response.status === 200 || response.status === 201) {
          console.log(response.data, 'DATA')
          const jsonData = response.data[0]?.json_value // Assuming json_value is in the first object
          // setReportId(response.data[0]?.id)
          if (jsonData) {
            setFSMSAnnxeure((prevState) => ({
              ...prevState,
              ...jsonData,
            }))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    getFSMSannexureData()
  }, [])

  const [responseData, setResponseData] = useState<{[key: string]: any}>({})
  const [dataId, setDataId] = useState<string>()


  const [textareaValues, setTextareaValues] = useState({})



 

  useEffect(() => {
    const fetchFSMSData = async () => {
      try {
        console.log('Fetching QMS data for cabID:', cabID)
        const response = await axios.get(
          `${API_URL}/cab/global/data/filter/?cab=${cabID}&scheme=40f65889-bbe5-4784-9e10-38863a220be9`
        )
        setResponseData(response.data[0].details)
        setDataId(response.data[0]?.id)
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    if (cabID) fetchFSMSData()
  }, [])

  const handleNoteChange = (field, value) => {
    setResponseData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create noteDetails based on textareaValues
    const noteDetails = Object.keys(textareaValues).reduce((acc, key) => {
      if (textareaValues[key]) {
        acc[key] = textareaValues[key]; // Add only non-empty values
      }
      return acc;
    }, {});

    const payload = {
      details: JSON.stringify(responseData),
      cab: cabID,
      scheme: '40f65889-bbe5-4784-9e10-38863a220be9', 
    }

    try {
      const response = await axios.put(`${API_URL}/cab/global/data/${dataId}/update/`, payload)
      console.log('FSMS Data Submitted:', response.data)
      toast.success('FSMS Data Submitted')
    } catch (error) {
      console.error('Error saving data:', error)
      toast.error('Error saving data')
    }
  }



  // const handlePlanareaChange = (index, value) => {
  //   const newResponseData = [...responseData]
  //   newResponseData[index].planNote = value
  //   setResponseData(newResponseData)
  // }

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>FSMS Assessment</span>
        </h3>
      </div>
      {/* <h4 className='card-title align-items-start flex-column mt-2'>CAB: Example CAB Name</h4> */}

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle  fs-6 gy-5 px-5  table-bordered'>
            <thead>
            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='w-200px pe-2 fs-7' style={{ border: '1px solid #000' }}>Category</th>
                  <th className='w-200px pe-2 fs-7' style={{ border: '1px solid #000' }}>Subcategory</th>
                  <th className='w-200px pe-2 fs-7' style={{ border: '1px solid #000' }}>Category Awarded/Subcategory awarded</th>

                  <th className='w-1000px pe-2 fs-7' style={{ border: '1px solid #000' }}>
                    Witness to be planned in the year / Witness conducted date, Date of Witness and
                    Assessment Number
                  </th>
                  <th className='w-450px pe-2 fs-7' style={{ border: '1px solid #000' }}>
                    Planning the review of client files or verification of adequate competence for
                    non-critical codes during the annual office assessment, including the assessment
                    date and assessment number.
                  </th>
                  <th className='w-250px pe-2 fs-7' style={{ border: '1px solid #000' }}>
                    Total number of certificates issued, including details of the six countries with
                    the highest number of certificates issued since accreditation.
                  </th>
                  <th className='w-250px pe-2 fs-7' style={{ border: '1px solid #000' }}>
                    Total number of certificates transferred, including details of the six countries
                    with the highest number of certificates transferred since accreditation.
                  </th>
                </tr>
              </thead>
              <tbody
                className='text-gray-600 fw-semibold'
                style={{maxHeight: '400px', overflowY: 'auto'}}
              >
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      name='uaf_requirement'
                      value={fsmsAnnexure.iaf_1_1}
                      readOnly
                    />
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='AI'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='AII'
                        readOnly
                      />
                    </div>
                  </td>

                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure.iaf_1_3_1}
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure.iaf_1_3_2}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_4_1_1}
                          onChange={(e) => handleNoteChange('iaf_1_4_1_1', e.target.value)}
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_4_1_2}
                          onChange={(e) => handleNoteChange('iaf_1_4_1_2', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_4_2_1}
                          onChange={(e) => handleNoteChange('iaf_1_4_2_1', e.target.value)}
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_4_2_2}
                          onChange={(e) => handleNoteChange('iaf_1_4_2_2', e.target.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_5_1}
                        onChange={(e) => handleNoteChange('iaf_1_5_1', e.target.value)}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_5_2}
                        onChange={(e) => handleNoteChange('iaf_1_5_2', e.target.value)}
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={8}
                      name='uaf_requirement'
                      value={fsmsAnnexure.iaf_1_8}
                      readOnly
                    />
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='BI'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='BII'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='BIII'
                        readOnly
                      />
                    </div>
                  </td>

                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure.iaf_1_10_1}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure.iaf_1_10_2}
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure.iaf_1_10_3}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800 m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_1_1}
                          onChange={(e) => handleNoteChange('iaf_1_11_1_1', e.target.value)}
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_1_2}
                          onChange={(e) => handleNoteChange('iaf_1_11_1_2', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_2_1}
                          onChange={(e) => handleNoteChange('iaf_1_11_2_1', e.target.value)}
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_2_2}
                          onChange={(e) => handleNoteChange('iaf_1_11_2_2', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_3_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_11_3_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_11_3_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_11_3_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_12_1}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_12_1', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_12_2}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_12_2', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_12_3}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_12_3', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>

                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={15}
                      name='uaf_requirement'
                      value={fsmsAnnexure.iaf_1_15}
                      readOnly
                    />
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='C0'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='CI'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='CII'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='CIII'
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value='CIV'
                        readOnly
                      />
                    </div>
                  </td>

                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_17_1}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_17_2}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_17_3}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_17_4}
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_17_5}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_1_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_1_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_1_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_1_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_2_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_2_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_2_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_2_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_3_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_3_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_3_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_3_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_4_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_4_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800 m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_4_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_4_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_5_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_5_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_18_5_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_18_5_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_19_1}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_19_1', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_19_2}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_19_2', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_19_3}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_19_3', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_19_4}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_19_4', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_19_5}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_19_5', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>

                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                       
                        value={fsmsAnnexure?.iaf_1_22}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        
                        value={fsmsAnnexure?.iaf_1_23}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                       
                        value={fsmsAnnexure?.iaf_1_24}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_25_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_25_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_25_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_25_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <div className=''>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_26}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_26', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>

                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_29}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_30}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_31}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_32_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_32_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_32_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_32_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_33}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_33', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>

                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={5}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_36}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_37_1}
                        readOnly
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_37_2}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_38_1}
                       
                      />
                    </div>
                    <div className='text-gray-800  '>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_38_2}
                        
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_39_1_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_39_1_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_39_1_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_39_1_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_39_2_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_39_2_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_39_2_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_39_2_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_40_1}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_40_1', e.target.value)
                        }
                      />
                    </div>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_40_2}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_40_2', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>

                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_43}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_44}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_45}
                       
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_46_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_46_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_46_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_46_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_47}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_47', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_50}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_51}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_52}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_53_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_53_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_53_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_53_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_54}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_54', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_57}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  m-2'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_58}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  m-2'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_59}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800 m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_60_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_60_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_60_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_60_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_61}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_61', e.target.value)
                        }
                      />
                    </div>
                  </td>

                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_64}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_65}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_66}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_67_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_67_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_67_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_67_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_68}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_68', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_71}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_72}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_73}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800 m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_74_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_74_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_74_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_74_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_75}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_75', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                  </div>
                </td>
                </tr>
                <tr style={{ border: '1px solid #000' }}>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={fsmsAnnexure?.iaf_1_78}
                        readOnly
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_79}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_80}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex'>
                      <div className='text-gray-800  m-2'>
                        <label>Planned:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_81_1}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_81_1', e.target.value)
                          }
                        />
                      </div>
                      <div className='text-gray-800  m-2'>
                        <label>Conducted:</label>
                        <textarea
                          className='form-control form-control-solid'
                          rows={4}
                          name='uaf_requirement'
                          value={responseData?.iaf_1_81_2}
                          onChange={(e) =>
                            handleNoteChange('iaf_1_81_2', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        value={responseData?.iaf_1_82}
                        onChange={(e) =>
                          handleNoteChange('iaf_1_82', e.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_83}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800  mb-1'>
                      <textarea
                        className='form-control form-control-solid'
                        rows={4}
                        name='uaf_requirement'
                        readOnly
                        value={fsmsAnnexure?.iaf_1_84}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
        <div className='mb-5 mt-5'>
          <button type='submit' className='btn btn-primary' onClick={handleSubmit}>
            Update
            <i className='fa fa-angle-double-right' aria-hidden='true' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default FSMSEdit
