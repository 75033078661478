import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select, {SingleValue} from 'react-select'
import NCGraph from '../../../modules/nc-mangement/NCGraph'
import MyChart from './MyChart'
import StatusChart from './StatusChart'

const BASE_URL = process.env.REACT_APP_API_URL
interface GlobalNC {
  rca_pca_open: string
  approved_pending: string
  approved: string
  major_minor_evidence_open: string
}
interface CABType {
  value: string
  label: string
  id: string
}

interface CabInterface {
  id: string
  cab_name: string
  cab_id: string
  cab_company: string

  assessment_number: string
}
interface CABNCInterface {
  id: string
  cab_name: string
  category_name: string
  ncr_status_name: string
  cab_id: string
  assessment_number: string
  cab_company: string
}

const NCDashboard = () => {
  const [NCData, setNCData] = useState<CabInterface[]>([])
  const [globalNC, setglobalNC] = useState<GlobalNC>()
  const [selectedCAB, setSelectedCAB] = useState<SingleValue<CABType>>(null)
  const [apiData, setApiData] = useState<CABNCInterface[]>([])
  const [showGraph, setshowGraph] = useState(false)
  console.log(selectedCAB, 'this is my selected CAB')
  console.log(NCData, 'ffffffffff')

  const CABoptions: CABType[] = NCData.map((nc) => ({
    value: nc.cab_id,
    label: nc.cab_company,
    id: nc.cab_id,
  }))

  useEffect(() => {
    axios
      .get(`${BASE_URL}/cab/assessment/id/`)
      .then((response) => {
        const uniqueNCData = response.data.filter(
          (item, index, self) => index === self.findIndex((t) => t.cab_id === item.cab_id)
        )
        setNCData(uniqueNCData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${BASE_URL}/nc/management/status/`)
      .then((response) => {
        console.log(response,"this is resposne");
        setglobalNC(response?.data?.ncmanagement)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleCABChange = (selectedOption: SingleValue<CABType>) => {
    setSelectedCAB(selectedOption)
    if (selectedOption) {
      axios
        .get(`${BASE_URL}/nc/management/filter/?registrar=${selectedOption.value}`)
        .then((response) => setApiData(response.data))
        .catch((error) => {
          // console.log(error)
        })
    }
    setshowGraph(true)
  }
  const majorcount = apiData.filter((item) => item.category_name === 'Major').length
  const minorcount = apiData.filter((item) => item.category_name === 'Minor').length
  const opennc = apiData.filter((item) => item.ncr_status_name === 'Open').length
  const closenc = apiData.filter((item) => item.ncr_status_name === 'Close').length

  return (
    <div>
      <div className='mb-5 col-lg-4 mt-5' style={{color: '#136ba1'}}>
        <Select
        id="cab_drop"
          className='cab-control'
          value={selectedCAB}
          onChange={handleCABChange}
          options={CABoptions}
          placeholder='CAB Organisation'
          styles={{
            control: (base) => ({
              ...base,
              borderColor: '#136ba1',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#136ba1',
              },
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? '#136ba1' : 'white',
              color: state.isSelected ? 'white' : '#136ba1',
              '&:hover': {
                backgroundColor: '#136ba1',
                color: 'white',
              },
            }),
          }}
        />
      </div>

      {!showGraph && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {globalNC?.rca_pca_open}
                    </span>
                  </div>
                  <span className='pt-3 fw-semibold fs-1hx'>C.A Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {globalNC?.major_minor_evidence_open}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Evidence Pending</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {globalNC?.approved}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Closed NC's</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>

                      {globalNC?.approved_pending}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Open NC's</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showGraph && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {majorcount}
                    </span>
                  </div>
                  <span className='pt-3 fw-semibold fs-1hx'>Major NC</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {minorcount}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Minor NC</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {closenc}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Closed NC's</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
            <div className='card card-flush mb-5 mb-xl-5'>
              <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                      {opennc}
                    </span>
                  </div>
                  <span className=' pt-3 fw-semibold fs-1hx'>Open NC's</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showGraph && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
          <div className='col-lg-12 mb-5'>
            <div
              className='card card-flush px-6 py-3'
              style={{padding: '20px', borderRadius: '5px'}}
            >
              <h2>Open NC's</h2>
              <NCGraph />
            </div>
          </div>
        </div>
      )}
      {showGraph && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
          <div className='col-lg-6 mb-5'>
            <div
              className='card card-flush px-6 py-3'
              style={{padding: '20px', borderRadius: '5px'}}
            >
              <h2>NC Category</h2>
              <MyChart majorcount={majorcount} minorcount={minorcount} />
            </div>
          </div>

          <div className='col-lg-6 mb-5'>
            <div
              className='card card-flush px-6 py-3'
              style={{padding: '20px', borderRadius: '5px'}}
            >
              <h2>NC Status</h2>
              <StatusChart opennc={opennc} closenc={closenc} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NCDashboard
