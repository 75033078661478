import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { KTIcon } from '../../../_metronic/helpers';
import ARCAssignModal from './component/ARCAssignModal';
import ArcJobOrderModal from './component/ArcJobOrderModel';
import { Link } from 'react-router-dom';


const ARCAssign = () => {
    const accountBreadCrumbs: Array<PageLink> = [
        {
            title: 'Assign ARC',
            path: '/accreditation/cab/assign/arc/',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]
    const BASE_URL = process.env.REACT_APP_API_URL

    const [assignArcModal, setAssignArcModal] = useState<boolean>(false);
    const [arcJOModal, setArcJOModal] = useState<any>();

    const [arcData, setArcData] = useState<any>([]);

    console.log(arcData, 'arcData')
    const fetchAssesorUser = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/arc/assign/cab/assessor/list/`);
            const newArray: any = [];
            for (const item of response.data) {
                if (Array.isArray(item.assessor_details)) {
                    for (const assessor of item.assessor_details) {
                        newArray.push({
                            id: item.id,
                            assess_value: item?.assess_value,
                            create_at: item.create_at,
                            update_at: item.update_at,
                            cabId: item?.cab_details.id,
                            cab: `${item.cab_details.first_name} ${item.cab_details.last_name}`,
                            assessor: assessor.assessor,
                            assessor__first_name: assessor.assessor__first_name,
                            assessor__last_name: assessor.assessor__last_name,
                            assessor__email: assessor.assessor__email,
                            assessor_location: assessor.assessor_location,
                            assessor_role__name: assessor.assessor_role__name,
                            company: item.cab_details.company,
                            jobNumber: item.job_number
                        });
                    }
                } else {
                    console.log('item.assessor_details is not an array:', item.assessor_details);
                }
            }


            setArcData(newArray);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchAssesorUser();
    }, [])



    return (
        <div id="arc_id">
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assign ARC</PageTitle>
            <div className='card-body p-9'>
                <div className='row gy-10 gx-xl-10'>
                    <div className='card-title m-0 d-flex w-100 justify-content-end'>
                        <button type='button' className='btn btn-primary float-right' onClick={() => setAssignArcModal(true)}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Assign ARC
                        </button>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                            <table className="table align-middle table-row-dashed fs-6 gy-5 location_table_show" id="kt_table_users">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th className="min-w-80px text-center">S No</th>
                                        <th className="min-w-125px">ARC Number</th>
                                        <th className="min-w-125px">CAB</th>
                                        <th className="min-w-125px">ARC Member</th>
                                        <th className="min-w-125px">Loction</th>
                                        <th className="min-w-125px">Role</th>
                                        <th className="min-w-125px">Status</th>
                                        <th className="min-w-125px text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 fw-semibold">
                                    {
                                        arcData?.map((item, index) => (
                                            <tr>
                                                <td className="min-w-80px text-center">{index + 1}</td>
                                                <td>
                                                <Link
                                                id="arc_id_1"
                                                to={`/crafted/pages/profile/${item?.cabId}/arc`}
                                                >
                                                    {item.assess_value}
                                                </Link>
                                                </td>

                                                <td>
                                                    <Link  id="arc_id_2" to={`/crafted/pages/profile/${item?.cabId}/overview`} className='text-gray-800 text-hover-primary mb-1'>
                                                        {item?.company}
                                                    </Link>
                                                </td>

                                                <td>
                                                    <Link   id="arc_id_3" to={`/assessor/verified/${item?.assessor}/overview`} className='text-gray-800 text-hover-primary mb-1'>
                                                        {item?.assessor__first_name} {item?.assessor__last_name}
                                                    </Link>
                                                </td>
                                                <td>{item?.assessor_location}</td>
                                                <td>{item?.assessor_role__name}</td>
                                                <td>
                                                    <div className="badge badge-light fw-bolder">

                                                        {item?.jobNumber == null ? (
                                                            <div className="badge badge-light-danger fw-bold">Pending</div>

                                                        ) : (
                                                            <div className="badge badge-light-success fw-bold">Approved</div>
                                                        )}

                                                    </div>
                                                </td>
                                                <td className="text-end" >
                                                    {item?.jobNumber == null ? (
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-light btn-active-light-primary btn-sm dropdown-toggle"
                                                                type="button"
                                                                id={`dropdownMenuButton${index}`}
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                Actions
                                                            </button>


                                                            <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                                                                <li><a  id="arc_id_10" className="dropdown-item" onClick={() => setArcJOModal(item)}>Create Job Order</a></li>
                                                            </ul>

                                                        </div>
                                                    ) : (
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-light  btn-sm dropdown-toggle"
                                                                type="button"
 id="arc_id_5"
                                                            >
                                                                Actions
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ARCAssignModal show={assignArcModal} handleClose={() => setAssignArcModal(false)} fetchAssesorUser={() => fetchAssesorUser()} />
            <ArcJobOrderModal show={arcJOModal} handleClose={() => setArcJOModal('')} />


        </div>

    )
}

export default ARCAssign