import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

interface StatusChartProps {
  opennc: number;
  closenc: number;
}

const StatusChart: React.FC<StatusChartProps> = ({ opennc, closenc }) => {
  useEffect(() => {
    console.log(opennc, "these are OpenNC's");
    console.log(closenc, "these are CloseNC's");

    const options = {
      series: [opennc, closenc],
      chart: {
        width: 480,
        type: 'pie',
      },
      labels: ['Open', 'Close'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const chart = new ApexCharts(document.querySelector("#chart2"), options);
    chart.render();

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      chart.destroy();
    };
  }, [opennc, closenc]); // Dependency array ensures the effect runs when opennc or closenc change

  return (
    <div id="chart2"></div>
  );
};

export default StatusChart;
