import React from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
import CertificateWrapper from "./components/CertificateWrapper";
const organisationBreadCrumbs: Array<PageLink> = [
    {
        title: 'Organisation',
        path: 'crafted/certificates',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const Certificates: React.FC = () => {
    return (<>
        <PageTitle breadcrumbs={organisationBreadCrumbs}>Certificates</PageTitle>
        <CertificateWrapper/>
    </>
    );
};
export default Certificates;