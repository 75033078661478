import React from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
import HistoricalAssessmentWrapper from "./components/HistoricalAssessmentWrapper";
const organisationBreadCrumbs: Array<PageLink> = [
    {
      title: 'Organisation',
      path: '/organisation/HistoricalAssessment',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const HistoricalAssessment: React.FC = () => {
    return (<div id="histroical_cab_id">

            <PageTitle breadcrumbs={organisationBreadCrumbs}>Historical Assessment</PageTitle>
            <HistoricalAssessmentWrapper/>

      </div>
    );
  };

  export default HistoricalAssessment;