/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL
interface UserData {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: number
  company: string
  company_web: string
  subject: string
  message: string
  last_login: string
  is_cab_client: boolean
  cab_profile: string
  address: string
  country: string
  language: string
  currency: string
  img: string
  country_name: string
  programme_names: { id: string, name: string }[]
  scheme_names: { id: string, name: string }[]
  user_schemes: string
}
interface CertificateType {
  id: string;
  name: string;
}
export function UAFDocumentWrapper({ }) {
  const BASE_URL = process.env.REACT_APP_API_URL

  const [documentDetails, setDocumentDetails] = useState<any>({
    description: "",
    documentFile: null
  })

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredValue, setFilteredValue] = useState<UserData[]>([]);


  const [addDocumentModal, setAddDocumentModal] = useState<boolean>(false);
  const [documentData, setDocumentData] = useState<any>([]);
  const [certificateList, setCertificateList] = useState<CertificateType>();
  const [pdfUrl, setPdfUrl] = useState<any>('')
  const [showPdfModal, setShowPdfModal] = useState<boolean>(false)
  const [showDeleteAssessment, setShowDeleteAssessment] = useState<any>()



  console.log(certificateList, "certificateList......printed");
  console.log(documentData, "documentData");
  console.log(pdfUrl, "pdfUrl");

  const fetchDocumentData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/uaf/documents`
      )
      setDocumentData(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchDocumentData();
  }, [])



  useEffect(() => {
    const fetchCertificateList = async () => {
      try {
        const response = await axios.get(`${API_URL}/certificate/list/certification/`);
        setCertificateList(response.data);
      } catch (error) {
        // Handle errors
        console.error('API Error:', error);
        // throw error;
      }
    };
    fetchCertificateList();
  }, []);






  const handleDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentDetails({ ...documentDetails, documentFile: e.target.files ? e.target.files[0] : null });
  }

  const handleSubmit = async () => {


    try {
      if (!documentDetails?.description || documentDetails?.description?.trim() === "" || !documentDetails.documentFile) {
        toast.error("please enter description and upload document")
        return;
      }

      const formData = new FormData();
      formData.append("description", documentDetails?.description);
      formData.append("document", documentDetails.documentFile);
      const reponse = await axios.post(`${BASE_URL}/global/uaf/documents/`, formData);
      fetchDocumentData();
      toast.success("Document uploaded.");
      setAddDocumentModal(false);
      setDocumentDetails({ ...documentDetails, description: "", documentFile: null })


    } catch (error) {

    }
  };

  const handlePdfIconClick = (item) => {
    setPdfUrl(item?.document)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }

  const handleDeleteAssessment = async (item) => {

    console.log(item, 'jdskhfygtyegewhja')
    try {

      const response = await axios.delete(`${BASE_URL}/global/uaf/documents/${item?.id}/destroy/`);
      fetchDocumentData();




    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Documents
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
        </h3>
        <button type='button' className='btn btn-primary float-right' onClick={() => setAddDocumentModal(true)}>
          <KTIcon iconName='plus' className='fs-2' />
          Add Document
        </button>


      </div>
      {documentData?.length ? <>
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
            <table className="table align-middle table-row-dashed fs-6 gy-5 location_table_show" id="kt_table_users">
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-80px text-center">S No</th>
                  <th className="min-w-125px">Description</th>
                  <th className="min-w-125px">Document</th>
                  <th className="text-end min-w-100px">Action</th>

                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold">
                {documentData?.map((item, index) => {
                  return (
                    <tr>
                      <td className="min-w-80px text-center">{index + 1}</td>
                      <td>{item?.description}</td>
                      <td>
                        <a onClick={() => handlePdfIconClick(item)}>

                          <img src='/media/doc.png' alt='' />
                        </a>
                      </td>
                      <td className="text-center" >
                        <div className="dropdown">
                          <button
                            className="btn btn-light btn-active-light-primary btn-sm dropdown-toggle"
                            type="button"
                            id={`dropdownMenuButton${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Actions
                          </button>

                          <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                            <li><a className="dropdown-item" href="#" onClick={() =>setShowDeleteAssessment(item) }>Delete</a></li>

                          </ul>




                        </div>
                      </td>
                    </tr>
              )
                })}

            </tbody>
          </table>
        </div>
      </div>
    </> : null
}







< Modal show={addDocumentModal} onHide={() => setAddDocumentModal(false)} size="lg" >
  <Modal.Header closeButton>
    <Modal.Title>UAF Document</Modal.Title>
  </Modal.Header>
  <Modal.Body>

    <div className='row col-md-12'>
      <div className="col-md-6">

        <input name="" className='form-control form-control-lg border-1 mb-2' id="" onChange={(e) => setDocumentDetails({ ...documentDetails, description: e?.target?.value })} value={documentDetails?.description} placeholder='Add Document description' />
      </div>


      <div className="col-md-6 d-flex justify-content-center">
        <div className="contact-page__input-box text-center">
          <label htmlFor="certification_file" className="file-upload-upload">{documentDetails.documentFile ? "Doc Uploaded" : "Upload Doc"}</label>
          <input id="certification_file" type="file"
            onChange={handleDocument}
          />
        </div>
      </div>
    </div>

  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setAddDocumentModal(false)}>
      Close
    </Button>
    <Button
      variant="success"
      onClick={() => {
        handleSubmit()
        // OnEmailSend();
        // Here you can use editorData for further processing
      }}
    >
      Submit
    </Button>
  </Modal.Footer>
</Modal >

{/* modal */ }
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the PDF in the modal */}
          <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
        </Modal.Body>
      </Modal>


      <Modal show={showDeleteAssessment?.id} onHide={() => setShowDeleteAssessment('')} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Do you want to delete this Document ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteAssessment('')}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => { handleDeleteAssessment(showDeleteAssessment); setShowDeleteAssessment("") }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}
