import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { usePermissions } from '../../../permission_component/PermissionContext';

const CriticalTable = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  console.log(cabPermission, "cabPermission");

  const userId = localStorage.getItem("current_user_id");
  const [reportData, setReportData] = useState<any>()
  const fetchCertificateReport = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/scheme/list/`)
      setReportData(response?.data?.certificates)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }


  const fetchSingleReportData = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/scheme/list/${userId}`);
      setReportData(response?.data?.certificates)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }

  useEffect(() => {
    fetchCertificateReport()
  }, [])

  useEffect(() => {
    if(cabPermission?.is_cab_verify===true){
      fetchSingleReportData();
    }
  }, [])

  return (
    <>
      {reportData?.length > 0 && (
        <div className='card card-xxl-stretch mb-5 mb-xl-8' id='kt_profile_details_view'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>CERTIFICATE SCHEME CODE REPORT</span>
            </h3>
          </div>
          <div className='card-body py-3' style={{maxHeight: "400px",overflowY:"scroll"}}>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' id='kt_table_users'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-125px'>Scheme Name</th>
                    <th className='min-w-125px'>Certificate Count</th>
                    <th className='min-w-125px'>Critical Code</th>
                    <th className='min-w-125px'>Non-Critical Code</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {reportData?.map((i, index) => (
                    <tr key={index}>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{i?.scheme__name}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{i?.total_count}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{i?.iaf_critical_count}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{i?.non_iaf_critical_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CriticalTable
