import axios from 'axios';
import { AnyMxRecord } from 'dns';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { toast } from 'react-toastify';

const CabAnnualFee = ({ selectedOption }) => {
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    id: number;
    company: string;

  }
  const [cabUser, setCabUser] = useState<any>()
  const [cabId, setCabID] = useState<any>()
  console.log(cabId,"cabId");
  
  const [countData, setCountData] = useState<any>()
  const [programmes, setProgrammes] = useState<any[]>([])
  const [certificateData, setCertificateData] = useState<any>([])
  const [mergedData, setMergedData] = useState<any[]>([])
  const [totalActive, setTotalActive] = useState<any>(0)
  const [totalSuspended, setTotalSuspended] = useState<any>(0)
  const [totalWithdrawn, setTotalWithdrawn] = useState<any>(0)
  console.log(certificateData, "certificateData");
  const [annualFee, setAnnualFee] = useState({
    iaf_1_1: "Annual Fee MS – Quality management systems ",
    iaf_1_2: "",
    iaf_1_3: "",
    iaf_1_4: "",

    iaf_1_7: "Annual Certification Fees for QMS/EMS/OHSMS",
    iaf_1_8: "",
    iaf_1_9: "",
    iaf_1_10: "",

    iaf_1_13: "Annual Surveillance Office Fees. ",
    iaf_1_14: "",
    iaf_1_15: "",
    iaf_1_16: "",


    iaf_1_19: "Surveillance Office planning and reporting ",
    iaf_1_20: "",
    iaf_1_21: "",
    iaf_1_22: "",


    iaf_1_25: "Witness Assessment  ",
    iaf_1_26: "",
    iaf_1_27: "",
    iaf_1_28: "",

    iaf_1_31: "Each Witness assessment planning and Reporting  ",
    iaf_1_32: "",
    iaf_1_33: "",
    iaf_1_34: "",
  });
  console.log(annualFee, "FEE");

  const [value, setValue] = useState<User[]>([]);
  console.log(cabUser, "cabUser")
  useEffect(() => {
    axios.get(`${BASE_URL}/account/approved/cab/user/`)
      .then(response => {
        const data = response.data?.map(item => ({
          label: `${item?.company}`,
          value: item?.id
        }))
        setValue(data)
      })
      .catch(error => { console.log(error); });
  }, []);
  const fetchCountData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/total/count/data/?cab=${cabUser?.value}`
      )
      setCountData(response?.data)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }
  const fetchCertificateData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/programme/list/?${cabUser?.value}`
      )
      setCertificateData(response?.data?.certificates)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }
  const fetchProgramList = async () => {
    try {
      const responses = await axios.get(`${BASE_URL}/global/programme/list/`)
      setProgrammes(responses?.data)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }
  useEffect(() => {
    const mergeData = () => {
      if (certificateData.length && programmes.length) {
        const tempMergedData: any[] = programmes.map((program) => {
          const certificateItem = certificateData.find(
            (item) => item.programme === program.id
          ) || {
            programme: program.id,
            programme__name: program.name,
            active: 0,
            suspended: 0,
            withdrawn: 0,
          }
          return certificateItem
        })

        setMergedData(tempMergedData)

        // Calculate totals
        const activeSum = tempMergedData.reduce((sum, item) => sum + item.active, 0)
        const suspendedSum = tempMergedData.reduce((sum, item) => sum + item.suspended, 0)
        const withdrawnSum = tempMergedData.reduce((sum, item) => sum + item.withdrawn, 0)

        setTotalActive(activeSum)
        setTotalSuspended(suspendedSum)
        setTotalWithdrawn(withdrawnSum)
      }
    }

    mergeData()
  }, [certificateData, programmes])
  useEffect(() => {
    fetchProgramList();
  }, [])
  useEffect(() => {
    if (cabUser) {
      fetchCountData()
      fetchCertificateData()
    }
  }, [cabUser])



  const submitAnnualFee = async () => {

    try {
     const jsonData = JSON.stringify(annualFee);
      
      const response = await axios.post(`${BASE_URL}/payment/invoice/create/`, {
        cab:cabId,
        text:selectedOption,
        invoice: jsonData
      });
      if (response.status === 200 || response.status === 201) {
        toast.success('Annual & certificate fee generated successfully.');


      } else {
        toast.error('Please try again.');
      }



    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className='container'>
      <div className='row'>
        <div className="mb-5 col-6">
          <label className="required form-label">CAB User</label>
          <Select
            id="select_user"
            name="User"
            options={value}
            className='react-select-styled'
            classNamePrefix='react-select'
            value={cabUser}
            onChange={(e) => {
              setCabUser(e);
              setCabID(e?.value); 
            }}

          />
        </div>
        <div className="mb-5 col-6 px-10 py-5 ">
          <h4 className='fw-bolder mb-2'>CAB: <span className="inline_master_head">{cabUser?.label}</span></h4>
        </div>
      </div>
      {certificateData?.length > 0 ? (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' >
              <thead >
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>PROGRAM</th>
                  <th className='min-w-140px'>ACTIVE</th>
                  <th className='min-w-120px'>SUSPENDED</th>
                  <th className='min-w-100px '>WITHDRAWN</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {certificateData?.map((item) => (
                  <>
                    {item?.programme__name ? (
                      <tr key={item.id}>
                        <td className='min-w-125px ps-4 text-dark fw-bold text-hover-primary  fs-6'>{item?.programme__name}</td>
                        <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.active}</td>
                        <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.suspended}</td>
                        <td className='text-dark fw-bold text-hover-primary fs-6'>{item?.withdrawn}</td>

                        {/* <td>{getStatusCounts(item.id, 'Active')}</td>
                                         <td>{getStatusCounts(item.id, 'Suspended')}</td>
                                         <td>{getStatusCounts(item.id, 'Withdrawn')}</td> */}
                      </tr>
                    ) : null}
                  </>
                ))}
                <tr className='fw-bold'>
                  <td className='min-w-125px ps-4'>Total</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{totalActive}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{totalSuspended}</td>
                  <td className='text-dark fw-bold text-hover-primary fs-6'>{totalWithdrawn}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* end::Table container */}
          <div className='mb-5 col-lg-12'>
            {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
            <table className='fee_table table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
              <thead >
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='w-250px pe-2 fs-7'>Type of Fees </th>
                  <th className='w-250px pe-2 fs-7'>Manday Rate* Number of Mandays</th>
                  <th className='w-250px pe-2 fs-7'>Total Amount in US$</th>
                  <th className='w-250px pe-2 fs-7'>Remarks</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold' style={{ maxHeight: '400px', overflowY: 'auto' }}>

                <tr>
                  <td>

                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_1: e?.target?.value })}
                      value={annualFee.iaf_1_1}
                      readOnly

                    />
                  </td>
                  <td>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_2: e?.target?.value })}
                      value={annualFee.iaf_1_2}

                    />
                  </td>
                  <td> <textarea
                    rows={5}
                    name='uaf_requirement'
                    onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_3: e?.target?.value })}
                    value={annualFee.iaf_1_3}

                  /></td>
                  <td><textarea
                    rows={5}
                    name='uaf_requirement'
                    onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_4: e?.target?.value })}
                    value={annualFee?.iaf_1_4}

                  /></td>

                </tr>

                <tr>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_7: e?.target?.value })}
                      value={annualFee?.iaf_1_7}
                      readOnly
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_8: e?.target?.value })}
                      value={annualFee?.iaf_1_8}
                    />
                  </div></td>

                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_9: e?.target?.value })}
                      value={annualFee?.iaf_1_9}
                    />
                  </div></td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <div className=''>
                        <textarea

                          rows={5}
                          name='uaf_requirement'
                          onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_10: e?.target?.value })}
                          value={annualFee?.iaf_1_10}
                        />
                      </div>
                    </div>
                  </td>




                </tr>


                <tr>

                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_13: e?.target?.value })}
                      value={annualFee?.iaf_1_13}
                      readOnly
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_14: e?.target?.value })}
                      value={annualFee?.iaf_1_14}

                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_15: e?.target?.value })}
                      value={annualFee?.iaf_1_15}
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_16: e?.target?.value })}
                      value={annualFee?.iaf_1_16}
                    />
                  </div></td>


                </tr>

                <tr>

                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_19: e?.target?.value })}
                      value={annualFee?.iaf_1_19}
                      readOnly
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_20: e?.target?.value })}
                      value={annualFee?.iaf_1_20}
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_21: e?.target?.value })}
                      value={annualFee?.iaf_1_21}
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_22: e?.target?.value })}
                      value={annualFee?.iaf_1_22}
                    />
                  </div></td>


                </tr>

                <tr>

                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_25: e?.target?.value })}
                      value={annualFee?.iaf_1_25}
                      readOnly
                    />
                  </div></td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <div className=''>
                        <textarea

                          rows={5}
                          name='uaf_requirement'
                          onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_26: e?.target?.value })}
                          value={annualFee?.iaf_1_26}
                        />
                      </div>
                    </div>
                  </td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_27: e?.target?.value })}
                      value={annualFee?.iaf_1_27}
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_28: e?.target?.value })}
                      value={annualFee?.iaf_1_28}
                    />
                  </div></td>



                </tr>

                <tr>

                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_31: e?.target?.value })}
                      value={annualFee?.iaf_1_31}
                      readOnly
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_32: e?.target?.value })}
                      value={annualFee?.iaf_1_32}
                    />
                  </div></td>
                  <td><div className='text-gray-800  mb-1'>
                    <textarea
                      rows={5}
                      name='uaf_requirement'
                      onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_33: e?.target?.value })}
                      value={annualFee?.iaf_1_33}
                    />
                  </div></td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <textarea

                        rows={5}
                        name='uaf_requirement'
                        onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_34: e?.target?.value })}
                        value={annualFee?.iaf_1_34}
                      />
                    </div>
                  </td>


                </tr>
              </tbody>
            </table>
            <div className='text-gray-800  mb-1'>
              <button className='btn btn-primary' onClick={submitAnnualFee}>Save </button>
            </div>
          </div>
        </div>

      ) : null}

    </div>
  )
}

export default CabAnnualFee
