/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface Scheme {
  id: string
  name: string
}

interface Program {
  id: string
  name: string
}
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  certification_names: string[]
  programme_names: Program[]
  scheme_names: Scheme[]
  create_at: string
}
type Props = {
  className: string
}


const ListsWidget2: React.FC<Props> = ({className}) => {
  const BASE_URL = process.env.REACT_APP_API_URL
const [value, setValue] = useState<PendingAssessor[]>([])

const formatDate = (dateString: string) => {
  return moment(dateString).format('MM/DD/YYYY')
}



// function stringToColor(string: string) {
//   let hash = 0;
// let i;

// /* eslint-disable no-bitwise */
// for (i = 0; i < string.length; i += 1) {
//   hash = string.charCodeAt(i) + ((hash << 5) - hash);
// }

// let color = '#';

// for (i = 0; i < 3; i += 1) {
//   const value = (hash >> (i * 8)) & 0xff;
//   color += `00${value.toString(16)}`.slice(-2);
// }
// /* eslint-enable no-bitwise */

// return color;
// }

function stringAvatar(name: string) {
return {
  sx: {
    bgcolor: 'rgb(62,151,255)',
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}

const fetchData = async () => {
  try {
    const response = await axios.get<PendingAssessor[]>(
      `${BASE_URL}/team/accessor/pending/list/`
    )
    setValue(response.data)
  } catch (error) {
    console.log(error)
  }
}
useEffect(() => {
  fetchData()
}, [])

const sortByDate = (a: PendingAssessor, b: PendingAssessor) => {
  return new Date(b.create_at).getTime() - new Date(a.create_at).getTime();
};

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <Link to='/assessor/leads'>
      <div className='card-header border-0'>
      
        <h3 className='card-title fw-bold text-dark'>ASSESSOR LEADS</h3>
        
        {/* <div className='card-toolbar'>
         
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          
        </div> */}
      </div>
      </Link>
      
      <div className='card-body pt-2'>
       {value.sort(sortByDate).map((item) => (
        <div className='d-flex align-items-center bg-light-primary rounded p-3 mb-7'>
         
          <div className='symbol symbol-50px me-5'>
            {/* <img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' /> */}
            <Avatar {...stringAvatar('Kent Dodds')} />
          </div>
          <div className='flex-grow-1'>
            <Link to='/assessor/leads' className='text-dark fw-bold text-hover-primary fs-6'>
            {item?.first_name} {item?.last_name}
            </Link>
            <span className='text-muted d-block fw-semibold'>{item && item.create_at ? formatDate(item.create_at) : ''}</span>
          </div>
         
        </div>))}
       
        {/* <div className='d-flex align-items-center mb-7'>
        
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} className='' alt='' />
          </div>
         
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Sean Bean
            </a>
            <span className='text-muted d-block fw-semibold'>PHP, SQLite, Artisan CLI</span>
          </div>
         
        </div>
       
        <div className='d-flex align-items-center mb-7'>
          
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-11.jpg')} className='' alt='' />
          </div>
          
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Brian Cox
            </a>
            <span className='text-muted d-block fw-semibold'>PHP, SQLite, Artisan CLI</span>
          </div>
         
        </div>
       
        <div className='d-flex align-items-center mb-7'>
         
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-9.jpg')} className='' alt='' />
          </div>
       
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Francis Mitcham
            </a>
            <span className='text-muted d-block fw-semibold'>PHP, SQLite, Artisan CLI</span>
          </div>
         
        </div>
      
        <div className='d-flex align-items-center'>
         
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} className='' alt='' />
          </div>
          
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Dan Wilson
            </a>
            <span className='text-muted d-block fw-semibold'>PHP, SQLite, Artisan CLI</span>
          </div>
         
        </div> */}
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget2}
