import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

type Props = {
  uniqueCab: string | undefined;
};
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count:string
}

const MDQMSEdit = ({ uniqueCab }: Props) => {
  const cabID = uniqueCab;
  const [mdqmsAnnexure,setMDQMSAnnxeure] = useState({
    iaf_1_1: 'Non-active Medical Devices',
    iaf_1_2: '',
    iaf_1_3: '',
    iaf_1_4: '',
    iaf_1_5: '',
    iaf_1_6: '',
    iaf_1_7: 'Active Medical Devices (Non-implantable)',
    iaf_1_8: '',
    iaf_1_9: '',
    iaf_1_10: '',
    iaf_1_11: '',
    iaf_1_12: '',
    iaf_1_13: 'Active Implantable Medical Devices',
    iaf_1_14: '',
    iaf_1_15: '',
    iaf_1_16: '',
    iaf_1_17: '',
    iaf_1_18: '',
    iaf_1_19: 'In Vitro Diagnostic Medical Devices (IVD) ',
    iaf_1_20: '',
    iaf_1_21: '',
    iaf_1_22: '',
    iaf_1_23: '',
    iaf_1_24: '',
    iaf_1_25: 'Sterilization Method for Medical Devices',
    iaf_1_26: '',
    iaf_1_27: '',
    iaf_1_28: '',
    iaf_1_29: '',
    iaf_1_30: '',
    iaf_1_31: 'Devices Incorporating/Utilizing Specific Substances/Technologies',
    iaf_1_32: '',
    iaf_1_33: '',
    iaf_1_34: '',
    iaf_1_35: '',
    iaf_1_36: '',
    iaf_1_37: 'Parts or Services',
    iaf_1_38: '',
    iaf_1_39: '',
    iaf_1_40: '',
    iaf_1_41: '',
    iaf_1_42: '',
  });

  const [dates, setDates] = useState<Array<Date | null>>(Array(7).fill(null));
  const [textareaValues, setTextareaValues] = useState<string[]>(Array(7).fill(''));
  const [PlanareaValues, setPlanareaValues] = useState<string[]>(Array(7).fill(''));

  const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])
console.log(transferCertificate,"EMS Certificate")

useEffect(() => {
  const fetchtransferCertificate = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=3d350ed1-ae27-44fe-8b2a-3c6a6029b774`
      )
      settransferCertificate(response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6))
    } catch (error) {
      console.error('Error fetching standard data:', error)
    }
  }
  fetchtransferCertificate()
}, [])
// useEffect(() => {
//   const getMDQMSannexureData = async () => {
//       try {
//           const response = await axios.get(`${API_URL}/arc/anexxure/report/list/?cab=${cabID}&scheme=3d350ed1-ae27-44fe-8b2a-3c6a6029b774`);
//           if (response.status === 200 || response.status === 201) {
//               console.log(response.data, "DATA");
//               const jsonData = response.data[0]?.json_value; // Assuming json_value is in the first object
//               // setReportId(response.data[0]?.id)
//               if (jsonData) {
//                   setMDQMSAnnxeure(prevState => ({
//                       ...prevState,
//                       ...jsonData
//                   }));
//               }
//           }
//       } catch (error) {
//           console.log(error);
//       }
//   };
//   getMDQMSannexureData();
// }, [])

const [responseData, setResponseData] = useState<any[]>([])
const [dataId,setDataId] = useState<string>()

console.log(responseData, 'responseData')

useEffect(() => {
  const fetchMDQMSData = async () => {
    try {
      console.log('Fetching QMS data for cabID:', cabID)
      const response = await axios.get(
        `${API_URL}/cab/global/data/filter/?cab=${cabID}&scheme=3d350ed1-ae27-44fe-8b2a-3c6a6029b774`
      )
      setResponseData(response.data[0].details)
      setMDQMSAnnxeure(response?.data[0]?.emssubmit)
      setDataId(response.data[0]?.id)
    } catch (error) {
      console.error('Error fetching standard data:', error)
    }
  }
  if (cabID) fetchMDQMSData()
}, [])

const handleAnnexureChange = (key, value) => {
  setMDQMSAnnxeure({
    ...mdqmsAnnexure,
    [key]: value,
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Prepare details array from responseData for submission
  const detailsArray = responseData.map((item, index) => ({
    iafCode: `iaf_1_${index * 8 + 1}`,
    witnessDate: item?.witnessDate,
    conductNote:item.conductNote, 
    witnessNote: item?.witnessNote,
    planNote: item?.planNote
  }));

  const payload = {
     emssubmit: JSON.stringify(mdqmsAnnexure),
    details: JSON.stringify(detailsArray),
    cab: cabID,
    scheme: '3d350ed1-ae27-44fe-8b2a-3c6a6029b774',
  };

  try {
    // Make PUT request to update data
    const response = await axios.put(
      `${API_URL}/cab/global/data/${dataId}/update/`,
      payload
    );
    console.log('MDQMS Data Submitted:', response.data);
    // Handle success (e.g., show success message)
    toast.success("MDQMS Data Updated");
  } catch (error) {
    toast.error('Error saving data')
  }
};

const handleDateChange = (index, date) => {
  console.log(date,"this is date coming");
  const newResponseData = [...responseData];
  newResponseData[index].witnessDate = date;
  setResponseData(newResponseData);
};

const handleConductareaChange = (index, value) => {
  const newResponseData = [...responseData]
  newResponseData[index].conductNote = value
  setResponseData(newResponseData)
}

const handleTextareaChange = (index, value) => {
  const newResponseData = [...responseData];
  newResponseData[index].witnessNote = value;
  setResponseData(newResponseData);
};

const handlePlanareaChange = (index, value) => {
  const newResponseData = [...responseData];
  newResponseData[index].planNote = value;
  setResponseData(newResponseData);
};

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>MDQMS Assessment</span>
        </h3>
      </div>
      {/* <h4 className='card-title align-items-start flex-column mt-2'>CAB: Example CAB Name</h4> */}

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-dark bg-light' style={{ fontSize: '14px' }}>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                  Technical Areas Accredited to CABs
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                  Technical Areas awarded
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-200px rounded-start'>
                  *Witness to be planned in the year / Witness Date and Assessment Number
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                  Planning the review of client files or verification of adequate competence for the
                  annual office assessment, including the assessment date and assessment number.
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                  Total number of certificates issued, including details of the six countries with
                  the highest number of certificates issued since accreditation.
                </th>
                <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                  Total number of certificates transferred, including details of the six countries
                  with the highest number of certificates transferred since accreditation.
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(7)].map((_, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        {mdqmsAnnexure[`iaf_1_${index * 6 + 1}`]}
                      </div>
                    </div>
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                      {/* {mdqmsAnnexure[`iaf_1_${index * 6 + 2}`]} */}
                      <textarea
                      value={mdqmsAnnexure[`iaf_1_${index * 6 + 2}`]}
                      onChange={(e) => handleAnnexureChange(`iaf_1_${index * 6 + 2}`, e.target.value)}
                      className='form-control'
                      rows={5}
                    ></textarea>
                      </div>
                    </div>
                  </td>
                  <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                        <p style={{fontSize: '14px', fontWeight: '500'}}>Planned:</p>{' '}
                          <textarea
                            value={responseData[index]?.witnessNote}
                            onChange={(e) => handleTextareaChange(index, e.target.value)}
                            className='form-control'
                            rows={5}
                          ></textarea>
                          <p style={{fontSize: '14px', fontWeight: '500'}} className='mt-3'>
                            Conducted:
                          </p>
                          <textarea
                            value={responseData[index]?.conductNote}
                            onChange={(e) => handleConductareaChange(index, e.target.value)}
                            className='form-control'
                            rows={5}
                          ></textarea>
                        </div>
                      </div>
                    </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                      <textarea
                            value={responseData[index]?.planNote}
                            onChange={(e) => handlePlanareaChange(index, e.target.value)}
                            className='form-control'
                            rows={3}
                          ></textarea>
                      </div>
                    </div>
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                      {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item.country__name}: {item.total_count}
                      </div>
                    ))}
                      </div>
                    </div>
                  </td>
                  <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                      {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item.country__name}: {item.transferred}
                      </div>
                    ))}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='mb-5 mt-5'>
          <button type='submit' className='btn btn-primary' onClick={handleSubmit}>
            Update
            <i className='fa fa-angle-double-right' aria-hidden='true' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MDQMSEdit;
