import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
const API_URL = process.env.REACT_APP_API_URL

const CABNCReport = () => {
  //   const userIdObject = JSON.parse(userId)
  //   const uuid = userIdObject['*']
  //   const uuidRegex =
  //     /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  //   const uuids = uuid.match(uuidRegex)
  //   const urlId = uuids[0]
  //   const navigate = useNavigate()


  const { id } = useParams();
  
  console.log(id,"this isncid")

  const [cabNCData, setCabNCData] = useState<any>()

  const getCABNC = async () => {
    try {
      const response = await axios.get(`${API_URL}/nc/management/${id}/patch/`)
      setCabNCData(response?.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // console.log("Received id in NCReport:", id);
    getCABNC()
  }, [id])

  const doc = new jsPDF()
  //   const handlebutton = (id) => {
  //     if (id) {
  //       const url = `/apps/cab/accreditation-schedule/${id}`
  //       window.open(url, '_blank')
  //     }
  //   }

  const generatePDF = () => {
    const input = document.getElementById('pdfContent');
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
  
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
        }
  
        pdf.save('NCReport.pdf');
      });
    } else {
      console.error("Element with id 'pdfContent' not found.");
    }
  };
  
  return (
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>
        
        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer d-flex justify-content-between'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-2'>NC Report</h3>
              
            </div>
            
              
          
          </div>
          <div className='card-body p-9'>
            <div className='accordion' id='kt_accordion_1'>
              {/* {cabNCData?.map((item, index) => ( */}
              <div className='accordion-item assessment_detail_accordion'>
                <h2 className='accordion-header' id={`kt_accordion_1_header`}>
                  <div
                    className='accordion-button fs-4 fw-bold collapsed w-100 d-flex justify-content-between'
                    data-bs-toggle='collapse'
                    data-bs-target={`#kt_accordion_1_body_${cabNCData?.create_at}`}
                    aria-expanded='false'
                    aria-controls={`kt_accordion_1_body_${cabNCData?.create_at}`}
                  >
                    <span> Created At: {moment(cabNCData?.create_at).format('DD/MMM/YYYY')}</span>
                  </div>
                </h2>
                <div
                  id={`kt_accordion_1_body_${cabNCData?.create_at}`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`kt_accordion_1_header_${cabNCData?.create_at}`}
                  data-bs-parent='#kt_accordion_1'
                >
                  <div id='pdfContent' className='accordion-body px-20'>
                    <div className='row'>
                      <div className='d-flex flex-column justify-content-center align-items-center w-100'>
                        <img
                          alt='Logo'
                          src={'/media/logos/uaf_logo.png'}
                          className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                        />
                        <div className='uaf_title py-5 text-center'>
                          <h2 className=''>
                            NC Report <br />
                          </h2>
                          <span className='fs-18'>
                            United Accreditation Foundation
                            <br />
                            400, North Center Dr, STE 202, Norfolk, VA 23502, United States of
                            America{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row gy-10 gx-xl-10'>
                      <div className='col-xl-12'>
                        <div className='card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                          <table className='table table-bordered'>
                            <tbody>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessment No</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.assessment_number}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessment Type</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.assessment_name}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Last Updated</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>
                                    {cabNCData?.update_at
                                      ? moment(new Date(cabNCData?.update_at)).format('DD/MMM/YYYY')
                                      : '--'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>CAB Organisation</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.registrar_company}</span>
                                </td>
                              </tr>

                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NCR Number</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.ncr_number}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NC Status</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.ncr_status_name}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>NC Category</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.category_name}</span>
                                </td>
                              </tr>
                              {/* <tr>
                                  <td className='col-lg-6'>
                                    <label className='form-label'>
                                      Countries where UAF accredited certificates can be Issued
                                      (Subject to Compliance with UAF Policy For CAB Operations in
                                      Sanctioned Countries)
                                    </label>
                                  </td>
                                  <td className='col-lg-6'>
                                    {item?.country_data?.map((country, index) => (
                                      <span key={index}>
                                        {country?.name}
                                        {index < item.country_data.length - 1 ? ', ' : ''}
                                      </span>
                                    ))}
                                  </td>
                                </tr> */}
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Standard/Clause No.</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.standard_clause}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Scheme</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.scheme_text}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Team Leader</label>
                                </td>
                                <td className='col-lg-6'>
                                  {cabNCData?.team_leader_list?.map((item) => (
                                    <span key={item?.id}>
                                      {item?.first_name + ' ' + item?.last_name}
                                    </span>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Team Member</label>
                                </td>
                                <td className='col-lg-6'>
                                  {cabNCData?.team_member_list?.map((item) => (
                                    <span key={item?.id}>
                                      {item?.first_name + ' ' + item?.last_name}{' '}
                                    </span>
                                  ))}
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>UAF Requirement</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.uaf_requirements}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Non Conformity Observed</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.non_conformity_observed}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className='col-lg-6'>
                                  <label className='form-label'>Assessor Remark</label>
                                </td>
                                <td className='col-lg-6'>
                                  <span>{cabNCData?.remarks}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div
                            className='mb-10 mt-10 pb-10 text-center'
                            style={{borderBottom: '1px dotted #d3d3d3'}}
                          >
                            <h2 className=''>Remarks: </h2>
                            {cabNCData?.nc_management_remark.map((item) => (
                              <>
                                <h5>Date: {moment(item?.create_at).format('MM/DD/YY hh:mm A')}</h5>
                                {item?.rca_remark ? (
                                  <div className='mb-5'>
                                    <label className='form-label'>Root Cause Analysis (RCA)</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Root Cause Analysis'
                                      name='rca_remark'
                                      value={item?.rca_remark || ''}
                                      readOnly
                                      // onChange={handleChange}
                                    />
                                  </div>
                                ) : null}
                                {item?.pc_remark ? (
                                  <div className='mb-5'>
                                    <label className='form-label'>
                                      Proposed Correction and Corrective Action
                                    </label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Proposed Correction and Corrective Action'
                                      name='pc_remark'
                                      value={item?.pc_remark || ''}
                                      readOnly
                                      // onChange={handleChange}
                                    />
                                  </div>
                                ) : null}
                                {item?.cab_remark ? (
                                  <div className='mb-5'>
                                    <label className='form-label'>CAB Remarks</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter CAB Remarks'
                                      name='cab_remark'
                                      value={item?.cab_remark || ''}
                                      // onChange={handleChange}
                                      readOnly
                                    />
                                  </div>
                                ) : null}

                                {item?.assessor_remark ? (
                                  <div className='mb-5'>
                                    <label className='form-label'>Assessor Remarks</label>
                                    <textarea
                                      className='form-control form-control-solid'
                                      rows={5}
                                      placeholder='Enter Root Cause Analysis'
                                      name='rca_remark'
                                      value={item?.assessor_remark || ''}
                                      readOnly
                                      // onChange={handleChange}
                                    />
                                  </div>
                                ) : null}
                                <span
                                  style={{
                                    backgroundColor: item?.is_assessor_approved ? 'green' : 'red',
                                    color: 'white',
                                    padding: '2px 5px',
                                    borderRadius: '4px',
                                  }}
                                >
                                  Status:{' '}
                                  {item?.is_assessor_approved === true ? 'Accepted' : 'Rejected'}
                                </span>

                                {/* <span> Status:{item?.is_assessor_rejected === true ? 'Rejected': ''}</span> */}
                              </>
                            ))}
                          </div>

                          {/* <div
                              className='mb-10 mt-10  pb-10 text-center'
                              style={{borderBottom: '1px dotted #d3d3d3'}}
                            >
                              <h2 className=''>Certification Standards: </h2>
                              <ul style={{listStyleType: 'none'}}>
                                {standardData?.map((standard) => (
                                  <li className=' form-label'>
                                    {standard[0]?.name}--{standard[0]?.scheme_name}--Requirements
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className='mb-10 mt-10 text-center'>
                              <h2 className='mb-5'>SCOPE OF ACCREDITATION: </h2>

                              {schemeClusters?.map((scheme) => (
                                <table
                                  className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                                  id='kt_table_users'
                                  style={{border: '1px solid #d6d6d6'}}
                                >
                                  <thead>
                                    <tr className='text-center  fw-bold  text-uppercase gs-0'>
                                      <th className=' pe-2 fs-7' style={{background: '#f2f2f2'}}>
                                        {' '}
                                        {scheme?.scheme_name}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className='text-gray-600 fw-semibold '>
                                    {scheme?.clusters?.map((item) => (
                                      <tr>
                                        <td>
                                          <div className='text-gray-800 mb-1'>{item?.name}</div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ))}
                            </div>
                            <div className=' mt-10 text-end'>
                              <h2 style={{margin: '0 190px 0 0'}}>{item?.member_name} </h2>
                              <img src={item?.member_signature} alt='ARC Member Signature' />
                            </div>
                            <div className='mt-0 text-end'>
                              <h2 style={{margin: '0 120px 0 0'}}>
                                {formatDate(item?.signature_date)}{' '}
                              </h2>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-end m-5'>
                    <button className='btn btn-primary' onClick={generatePDF}>
                      Download
                    </button>
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CABNCReport
