import React from "react";
import { PageLink } from "../../../../../_metronic/layout/core";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";

type Props = {
    show: boolean
    handleClose: () => void
}
//   const modalsRoot = document.getElementById('root-modals') || document.body

const CreateBusinessUnit = ({ show, handleClose }: Props) => {

    return (<>

        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            //   onEntered={loadStepper}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>Create Business Unit</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className="mb-5">
                    <label  className="required form-label">Business Unit Name</label>
                    <input type="text" className="form-control form-control-solid" placeholder="Enter Business Unit Name" />
                </div>
                <div className="mb-5">
                    <label  className="required form-label">Business Unit Description</label>
                    <textarea  className="form-control form-control-solid"  rows={5} placeholder="Enter Business Unit Description" />
                </div>
                <div className="mb-5">
                    <label  className="required form-label">Business Unit Owner</label>
                    <input type="text" className="form-control form-control-solid" placeholder="Enter Business Unit Owner" />
                </div>
                <div className="mb-5">
                    <label  className="required form-label">Regulator Name</label>
                    <input type="text" className="form-control form-control-solid" placeholder="Enter Regulator Name" />
                </div>
                <div className="mb-5">
                    <label  className="required form-label">Business Unit Location</label>
                    <input type="text" className="form-control form-control-solid" placeholder="Enter Regulator Name" />
                </div>
                <div className="mb-5">

                    <input type="submit" className="btn btn-primary" placeholder="Enter Regulator Name" name="Submit" />
                </div>

            </div>

        </Modal>
    </>
    );
};

export default CreateBusinessUnit;