import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { usePermissions } from "../../../permission_component/PermissionContext";

const BASE_URL = process.env.REACT_APP_API_URL;

interface CertificateData {
    month: string;
    count: number;
}

const CertificateGraph = ({selectedCAB}) => {
    const cabId=selectedCAB
    console.log(cabId,"this is my selected CAB ID")
    const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
    const [certificateCount, setCertificateCount] = useState<CertificateData[]>([]);
    const [options, setOptions] = useState<ApexOptions>({
        chart: {
            id: "certificate-expiry-chart",
            type: "bar",
            height: 350,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: [],
            labels: {
                rotate: 0 // Straighten the month names
            }
        },
        yaxis: {
            title: {
                text: "Number of Certificates"
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '70%' // Adjust bar width
            }
        },
        tooltip: {
            y: {
                formatter: (val: number) => `Expire: ${val}`
            }
        }
    });

    useEffect(() => {
        const fetchProgrammes = async () => {
            try {
                let responses;
                if (cabId) {
                    responses = await axios.get(`${BASE_URL}/certificate/count/by/month/?cab=${cabId}`);
                } else if(superUserPermission?.is_superuser === true ) {
                    responses = await axios.get(`${BASE_URL}/certificate/count/by/month/`);
                }
                const data: CertificateData[] = responses?.data;
                const nextSixMonths = generateNextSixMonths();
                const completeData = completeMonthlyData(nextSixMonths, data);
                setCertificateCount(completeData);
                setOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        categories: nextSixMonths.map(month => month.split(' ')[0]) // Remove the year
                    }
                }));
            } catch (error) {
                console.error('Error fetching programme data:', error);
            }
        };
        fetchProgrammes();
    }, [cabId]);

    const generateNextSixMonths = (): string[] => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const nextSixMonths: string[] = [];
        const currentDate = new Date();
        let currentMonth = currentDate.getMonth(); // 0-11 for Jan-Dec
        let currentYear = currentDate.getFullYear();

        for (let i = 0; i < 6; i++) {
            nextSixMonths.push(`${months[currentMonth]} ${currentYear}`);
            currentMonth++;
            if (currentMonth > 11) {
                currentMonth = 0;
                currentYear++;
            }
        }

        return nextSixMonths;
    };

    const completeMonthlyData = (nextSixMonths: string[], data: CertificateData[]): CertificateData[] => {
        const monthYearMap = data.reduce((acc: { [key: string]: number }, item) => {
            const [year, month] = item.month.split('-');
            const monthName = new Date(parseInt(year), parseInt(month) - 1).toLocaleString('default', { month: 'long' });
            const key = `${monthName} ${year}`;
            acc[key] = item.count;
            return acc;
        }, {});

        return nextSixMonths.map(monthYear => ({
            month: monthYear.split(' ')[0], // Remove the year
            count: monthYearMap[monthYear] || 0
        }));
    };

    return (
        <>
            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>CERTIFICATE EXPIRES IN NEXT 6 MONTHS</span>
                    </h3>
                </div>

                <div className='card-body py-3'>
                    <ReactApexChart
                        options={options}
                        series={[{ data: certificateCount.map(item => item.count) }]}
                        type="bar"
                        height={415}
                    />
                </div>
            </div>
        </>
    );
};

export default CertificateGraph;
