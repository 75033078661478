import React from 'react';
import { Routes, Route, Navigate, Outlet, useParams } from 'react-router-dom';
import TrainingData from './TrainingData';
import ClusterTrainingData from './AddClusterData';
import ManualAssessorHeader from './ManualAssessorHeader';
import CreateAssessorManaul from './CreateManualAssesor';
import AddCriticalDoc from './AddCriticalDoc';
import AddCriticalData from './AddCriticalData';

const AddAssessorPage: React.FC = () => {
//   const { id } = useParams(); // Get the id from the route parameters
//   const userId = id || ''; // Ensure userId is always a string
  const location = useParams()
  // console.log(location,"printed IDIDIDI");
  // const uId = location?.id
   const vId = JSON.stringify(location)
  const userIdObject = JSON.parse(vId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const userId = uuids[0]

  console.log(userId, "baap")
  return (
    // <>hello</>
    <Routes>
      <Route
        element={
          <>
            <ManualAssessorHeader userId={userId} />
            <Outlet />
          </>
        }
      >
        <Route path="/edit/info/:id" element={<CreateAssessorManaul userId={userId} />} />
        <Route path="/edit/trainingdoc/:id" element={<TrainingData userId={userId} />} />
        <Route path="/edit/programs/info/:id" element={<ClusterTrainingData userId={userId} />} />
        <Route path="/edit/critical/doc/:id" element={<AddCriticalData userId={userId} />} />
        {/* Other routes */}
        <Route index element={<Navigate to={`/edit/info/${userId}`} />} />
      </Route>
    </Routes>
  );
};

export default AddAssessorPage;
