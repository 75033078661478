import Select, {MultiValue, SingleValue} from 'react-select'
import React, {useEffect, useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import axios from 'axios'
import SignaturePadComponent from '../profile/components/SignaturePadComponent'
import UAFLoader from '../../loader/UafLoader'
import {toast} from 'react-toastify'

const AssessorMonitoring = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [userData, setUserData] = useState<any>()
  const [monitorDate, setmonitorDate] = useState<Date | null>(null)
  const [signatureImageData, setSignatureImageData] = useState<any>()
  const [allUserData, setAllUserData] = useState<any>([])
  console.log(userData, 'userData')

  const [roleData, setRoleData] = useState<any>([])
  const [loader, setLoader] = useState<any>(false);
  const [value, setValue] = useState<any>({
    evaluator_name: '',
    relevant_clause: '',
    relevant_clause2: '',
    relevant_clause3: '',
    relevant_clause4: '',
    relevant_clause5: '',
    relevant_clause6: '',
    preparation: '',
    document_review: '',
    assessment_execution: '',
    assessment_gist: '',
    team_meeting: '',
    assessment_reporting: '',
    feedback: '',
    comment: '',
    remark: '',
    result: '',
  })

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/team/accessor/verified/list/`)
      if (response.status === 200 || response.status === 202) {
        setAllUserData(response.data)
      }
    } catch (error) {
      console.error('API Error:', error)
    }
  }
  useEffect(() => {
    fetchUserData()
  }, [])

  const handleUserChange = (selectedOption) => {
    const UserId = selectedOption.value
    console.log(UserId)
    const selectedUser = allUserData?.find((item) => item?.id === UserId)
    setUserData(selectedUser)
  }

  const handleDateChange = (date: Date | null) => {
    setmonitorDate(date)
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }))
  }
  const handleSignatureSave = (dataUrl: string) => {
    setSignatureImageData(dataUrl)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const postData = {
      ...value,
      assessor: userData?.id,
      monitor_date: monitorDate ? monitorDate.toISOString() : null,
      signature: signatureImageData,
    }

    try {
      setLoader(true)
      const response = await axios.post(`${BASE_URL}/performance/monitor/create`, postData)
      if (response.status === 200 || response.status === 201) {
        // Handle successful response
        setLoader(false);
        toast.success('Form submitted successfully:', response.data)
                // Reset state
                setUserData(null);
                setmonitorDate(null);
                setSignatureImageData(null);
                setValue({
                  evaluator_name: '',
                  relevant_clause: '',
                  relevant_clause2: '',
                  relevant_clause3: '',
                  relevant_clause4: '',
                  relevant_clause5: '',
                  relevant_clause6: '',
                  preparation: '',
                  document_review: '',
                  assessment_execution: '',
                  assessment_gist: '',
                  team_meeting: '',
                  assessment_reporting: '',
                  feedback: '',
                  comment: '',
                  remark: '',
                  result: '',
                });
      }
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <div id="assessor_monitoring_report">{loader ? <UAFLoader /> : null}
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-3 p-0 '> ASSESSOR / TECHNICAL EXPERT PERFORMANCE MONITORING REPORT</h2>
        </div>
      </div>
      <div className='card card-body py-5'>
        <div className='row jusify-content-space-between'>
          {/* <div className='col-md-6 d-flex align-items-center justify-content-start'>
            <Select
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Select Role'
              options={roleData?.map((item) => ({label: item?.name, value: item?.id}))}
              onChange={handleRoleChange}
            />
          </div> */}
          <div className='col-lg-6  align-items-center justify-content-start'>
            <Select
            id="select_role_for_member"
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Select User'
              options={allUserData?.map((item) => ({
                label: `${item?.first_name} ${item?.last_name}`,
                value: item?.id,
              }))}
              onChange={handleUserChange}
            />
          </div>
        </div>
      </div>
      <div className='card card-flush'>
        <form onSubmit={handleSubmit} id="submit_form_for_report">
          <div className='card-header pt-5'>
            <div className='col-lg-12'>
              <div className='my-1 row'>
                <h2 className='mb-5 col-lg-6'>Name:</h2>
                <h2 className='mb-5 col-lg-6'>
                  {userData?.first_name} {userData?.last_name}
                </h2>
                <h3 className='mb-5 col-lg-6'>Designation:</h3>
                <h3 className='mb-5 col-lg-6'>Assessor</h3>
                <h3 className='mb-5 col-lg-6'>Approved for scheme(s):</h3>
                <h3 className='mb-5 col-lg-6'>
                  {' '}
                  {userData?.scheme_names.map((item) => item.short_name).join(', ')}
                </h3>

                <h3 className='mb-5 col-6'>Name of Evaluator:</h3>

                <div className=' mb-5 col-6'>
                  <input
                  id="evaluator_name"
                    value={value?.evaluator_name}
                    name='evaluator_name'
                    className='form-control form-control-solid'
                    type='text'
                    onChange={handleInputChange}
                  />
                </div>

                <h3 className='mb-5 col-lg-6'>Date of monitoring:</h3>
                <div className='mb-5 col-lg-6'>
                  <ReactDatePicker
                  id="date_report"
                    selected={monitorDate}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={handleDateChange}
                    dropdownMode='select'
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card-body pt-5'>
            <div className='  my-1'>
              <h3 className='mb-5'>Criteria:</h3>
              <h5 className='mb-5'>A-Excellent</h5>
              <h5 className='mb-5'>B-Satisfactory </h5>
              <h5 className='mb-5'>
                C-Needs some improvement without affecting maintenance/ promotion of qualification.{' '}
              </h5>
              <h6 className='mb-5'>D-Needs focus on weak areas. </h6>
            </div>
            <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
              <thead>
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='col-1' style={{border: '1px solid black'}}>
                    S No
                  </th>
                  <th className='col-4 ' style={{border: '1px solid black'}}>
                    PARAMETERS
                  </th>
                  <th className='' style={{border: '1px solid black'}}>
                    Remark{' '}
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                <tr>
                  <td className='p-1' style={{border: '1px solid black'}}>
                    1
                  </td>
                  <td className='p-1' style={{border: '1px solid black'}}>
                    <div className='p-0'>
                      <div className='p-1' style={{borderBottom: '1px solid black'}}>
                        <h5>Relevant clause of ISO/IEC 17011</h5>
                      </div>
                      <div className='p-1' style={{borderBottom: '1px solid black'}}>
                        <h5>6.1.2.2</h5>
                      </div>
                      <div className='p-1' style={{borderBottom: '1px solid black'}}>
                        assessment principles, practices, and techniques
                      </div>
                      <div className='p-2' style={{borderBottom: '1px solid black'}}>
                        general management system principles and tools
                      </div>
                    </div>

                    <div className='p-0'>

                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      <h5>6.1.2.3</h5>
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      accreditation body's rules and processes
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      accreditation and accreditation scheme requirements and relevant guidance and
                      application documents
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      conformity assess assessment scheme requirements, other procedures and methods
                      used by the conformity assessment body.
                    </div>
                    </div>

                    <div className='p-0'>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      <h5>6.1.2.4</h5>
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      knowledge of risk-based assessment principles
                    </div>
                    </div>
                    <div className='p-0'>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      <h5>6.1.2.5</h5>
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      general regulatory requirements related to the conformity assessment
                      activities.
                    </div>
                    </div>
                    <div className='p-0'>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      <h5>6.1.2.6</h5>
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      knowledge of practices and processes of the conformity assessment body
                      business environment
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      communication skills appropriate to interact with all levels within the
                      conformity assessment body
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      note-taking and report-writing skills
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      opening and closing meeting skills
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      interviewing skills
                    </div>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      assessment management skills.
                    </div>
                    </div>
                    <div className='p-0'>
                    <div className='p-1' style={{borderBottom: '1px solid black'}}>
                      <h5>6.1.2.7</h5>
                    </div>
                    <div className='p-2'>note­taking and report writing skills</div>
                    </div>
                  </td>
                  <td className='p-0 align-top' style={{border: '1px solid black'}}>
                  <div className='p-0' style={{borderBottom: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause"
                      rows={5}
                      name='relevant_clause'
                      value={value.relevant_clause}
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='p-0' style={{borderBottom: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause2"
                      rows={8}
                      name='relevant_clause2'
                      value={value.relevant_clause2}
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='p-0' style={{borderBottom: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause3"
                      rows={2}
                      name='relevant_clause3'
                      value={value.relevant_clause3}
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='p-0' style={{borderBottom: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause4"
                      rows={3}
                      name='relevant_clause4'
                      value={value.relevant_clause4}
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='p-0' style={{borderBottom: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause5"
                      rows={11}
                      name='relevant_clause5'
                      value={value.relevant_clause5}
                      onChange={handleInputChange}
                    />
                    </div>
                    <div className='p-0' >
                    <textarea
                      className='form-control form-control-solid '
id="relevant_clause6"
                      rows={2}
                      name='relevant_clause6'
                      value={value.relevant_clause6}
                      onChange={handleInputChange}
                    />
                    </div>


                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    2
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Preparation of assessment</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Preparation prior to the assessment
                    </div>
                    <div className='p-2'>Communication with the CB prior to the assessment</div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="preparation"
                      name='preparation'
                      value={value?.preparation}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    3
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Document review</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Appropriate selection of items to review, preparation of the issues to be
                      confirmed on site
                    </div>
                    <div className='p-2'>Systematic and comprehensive review</div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='document_review'
                      id="document_review"

                      value={value?.document_review}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    4
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Assessment Execution</h5>
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Ability to identify the concerns w.r.to accreditation requirements
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Ability to identify areas of improvement.
                    </div>
                    <div className='p-2'>Clear evaluation/ evidence based recommendation</div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="assessment_execution"
                      name='assessment_execution'
                      value={value.assessment_execution}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    5
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Gist of assessment</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Coverage of the normative requirements
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Explanation to CAB w.r.to interpretation of applicable clause requirements.{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Understanding of the CAB’s quality system
                    </div>
                    <div className='p-2'>Not providing specific solutions </div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={8}
                      id="assessment_gist"
                      name='assessment_gist'
                      value={value?.assessment_gist}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    6
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Team meeting</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Involvement in the decision-making of the assessment team
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Clear and concise explanation of the information obtained during assessment to
                      the team members{' '}
                    </div>
                    <div className='p-2'>
                      Conclusion on the overall effectiveness of the CAB’s management system
                    </div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={8}
                      id="team_meeting"
                      name='team_meeting'
                      value={value?.team_meeting}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    7
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Assessment reporting</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Objective evidence captured w.r.to accreditation scope and normative
                      requirements in report
                    </div>
                    <div className='p-2'>Accurate and concise reporting </div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="assessment_reporting"
                      name='assessment_reporting'
                      value={value?.assessment_reporting}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    8
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      <h5>Interested parties feedback</h5>{' '}
                    </div>
                    <div className='p-2' style={{borderBottom: '1px solid black'}}>
                      Feedback from the team members of the assessment team
                    </div>
                    <div className='p-2'>Feedback from CABs and/or other interested parties </div>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="feedback"
                      name='feedback'
                      value={value?.feedback}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    9
                  </td>

                  <td className='p-0' style={{border: '1px solid black'}}>
                    <h5>Comment on frequency of performance monitoring of assessor/TE</h5>
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="comment"
                      name='comment'
                      value={value?.comment}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    10
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <h5>Any other Remarks</h5>{' '}
                  </td>
                  <td className='p-0' style={{border: '1px solid black'}}>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      id="report_remarks"
                      name='remark'
                      value={value?.remark}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
            <div className='mb-5 '>
              <label className='col-lg-4 fw-bold text-muted'>Result of Evaluation</label>

              <textarea
                className='form-control form-control-solid'
                rows={7}
                id="result"
                name='result'
                value={value?.result}
                onChange={handleInputChange}
              />
            </div>
            <div className='mb-5 '>
              <label className='col-lg-4 fw-bold text-muted'>Signature</label>

              <SignaturePadComponent onSave={handleSignatureSave} />
            </div>
          </div>
          <div className='card-footer text-end'>
            <button type='submit'id="submit_form_button_for_report" className='btn btn-primary'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AssessorMonitoring
