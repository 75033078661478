import React, { useEffect, useState } from 'react'
// import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { PageLink, PageTitle } from '../../../../../../../_metronic/layout/core'
import QMSProgram from './QMSProgram'
import EMSProgram from './EMSProgram'
import OHSMSProgram from './OHSMSProgram'
import FSMSProgram from './FSMSProgram'
import MDQMSProgram from './MDQMSProgram'

import GlobalAssessmentProgram from './GlobalAssessmentProgram'

import MainProgram from './MainProgram'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import OtherProgram from './OtherProgram'
const API_URL = process.env.REACT_APP_API_URL

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'CAB Assessment Program',
    path: '/accreditation/cab/assessmentprogram',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CABAssessmentProgram: React.FC = () => {
  const [tabValue, setTabValue] = React.useState('0')
  const [cabData, setCabData] = useState<any>();
  const [cabARCData, setCAbARCData] = useState<any>();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }
  const hasQMS = cabData?.scheme_names.some(scheme => scheme.short_name.includes("QMS"));
  const hasEMS = cabData?.scheme_names.some(scheme => scheme.short_name.includes("EMS"));
  const hasOHSMS = cabData?.scheme_names.some(scheme => scheme.short_name.includes("OHSMS"));
  const hasMDQMS = cabData?.scheme_names.some(scheme => scheme.short_name.includes("MDQMS"));
  const hasFSMS = cabData?.scheme_names.some(scheme => scheme.short_name.includes("FSMS"));
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/arc/management/schedule/data/get/?cab=${id}`
        )
        setCabData(response?.data?.cab_data)
        setCAbARCData(response?.data?.arc_data[0])

      } catch (error) {
        // console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [id])
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>CAB Assessment Program</PageTitle>
      <h2 className='py-5'>CAB Assessment Program</h2>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label='lab API tabs example'>
              <Tab label='Global' value='0' />
              <Tab label='Activity' value='1' />
              {hasQMS && <Tab label="QMS" value="2" />}
              {hasEMS && <Tab label='EMS' value='3' />}
              {hasOHSMS && <Tab label='OHSMS' value='4' />}
              {hasMDQMS && <Tab label='MDQMS' value='5' />}
              {hasFSMS &&
                <Tab label='FSMS' value='6' />
              }
              
              <Tab label='Other' value='7' />


            </TabList>
          </Box>
          <TabPanel value='0'>
            <MainProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='1'>
            <GlobalAssessmentProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='2'>
            <QMSProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='3'>
            <EMSProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='4'>
            <OHSMSProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='5'>
            <MDQMSProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='6'>
            <FSMSProgram uniqueCab={id} />
          </TabPanel>
          <TabPanel value='7'>
            <OtherProgram uniqueCab={id} />
          </TabPanel>

          {/* <TabPanel value='7'>
            <ISMSProgram uniqueCab={'7fc5d645-5139-4af1-b26c-b4cf7725c2c3'}/>
          </TabPanel>
          <TabPanel value='8'>
            <ENMSProgram uniqueCab={'7fc5d645-5139-4af1-b26c-b4cf7725c2c3'}/>
          </TabPanel>
          <TabPanel value='9'>
            <ABMSProgram uniqueCab={'7fc5d645-5139-4af1-b26c-b4cf7725c2c3'}/>
          </TabPanel> */}
        </TabContext>
      </Box>
    </>
  )
}

export default CABAssessmentProgram
