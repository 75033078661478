import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../../_metronic/helpers'
import moment from 'moment'
import { toast } from 'react-toastify'
import UAFLoader from '../../../loader/UafLoader'
import * as XLSX from 'xlsx'
import { debounce } from '@mui/material'

const API_URL = process.env.REACT_APP_API_URL

interface NCList {
  id: string
  first_name: string
  last_name: string
  email: string
  country_name: string
  is_cab_verify: boolean
  is_verify_accessor: boolean
  is_active: boolean
}
interface UserRoleType {
  id: string
  name: string
}

const AllUsers: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [value, setValue] = useState<NCList[]>([])
  console.log(value, 'djsdjfdfnm')
  const [loader, setLoader] = useState<any>(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredValue, setFilteredValue] = useState<any[]>([])
  const [userId, setuserId] = useState<string>('')
  const [typeFilter, setTypeFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [password, setPassword] = useState<string>('') // State for subject
  const [confirmPassword, setconfirmPassword] = useState<string>('')
  const [assignRoleShow, setAssignRoleShow] = useState(false);
  const [clickItemRole, setClickItemRole] = useState<any>();
  console.log(clickItemRole, 'clickItemRole')

  const [userRole, setUserRole] = useState<UserRoleType[]>([])
  console.log(userRole, 'userRole')
  const handleAssignRole = () => setAssignRoleShow(true);
  const handleRoleClose = () => setAssignRoleShow(false);



  const [Show, setShow] = useState(false)
  const [showActive, setShowActive] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(null)
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  console.log(checkedIds, 'checkedIds')
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 10

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value;
      console.log(searchValue, 'searchValue');
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/account/cab/assessor/list/pagination/?search=${searchValue}`
          );
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setFilteredValue(response.data?.results);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // setLoader(false);
        }
      } else {
        setFilteredValue([]);
        fetchData();
      }
    }, 500),
    []
  );

  const handleClose = () => setShow(false)
  const handleShow = (Id: string) => {
    setuserId(Id)
    setShow(true)
  }
  const handleActiveClose = () => setShowActive(false)

  const handleActiveShow = (Id: string, currentStatus) => {
    setuserId(Id)
    setIsActive(currentStatus)
    setShowActive(true)
  }
  const fetchRole = async () => {
    try {
      const response = await axios.get(`${API_URL}/role/list/`

      );
      setUserRole(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRole()
  }, []);

  const applyFilters = (data) => {
    let filteredData = data

    if (searchQuery) {
      filteredData = filteredData.filter((user) => {
        const fullName = `${user?.first_name}`.toLowerCase()
        const searchValue = searchQuery.toLowerCase()
        const categoryName = `${user?.last_name}`.toLowerCase()
        const search_email = `${user?.user_email}`.toLowerCase()

        return (
          fullName.includes(searchValue) ||
          categoryName.includes(searchValue) ||
          search_email.includes(searchValue)
        )
      })
    }

    if (typeFilter) {
      filteredData = filteredData.filter(
        (item) => item.category_name.toLowerCase() === typeFilter.toLowerCase()
      )
    }

    if (statusFilter) {
      filteredData = filteredData.filter(
        (item) => item.ncr_status_name.toLowerCase() === statusFilter.toLowerCase()
      )
    }

    if (dateFilter) {
      const now = moment()
      filteredData = filteredData.filter((item) => {
        const raiseDate = moment(item.create_at)
        switch (dateFilter) {
          case '1month':
            return raiseDate.isAfter(now.clone().subtract(1, 'month'))
          case '3months':
            return raiseDate.isAfter(now.clone().subtract(3, 'months'))
          case '6months':
            return raiseDate.isAfter(now.clone().subtract(6, 'months'))
          case '1year':
            return raiseDate.isAfter(now.clone().subtract(1, 'year'))
          default:
            return true
        }
      })
    }

    return filteredData
  }
  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}/account/cab/assessor/list/pagination/?page=${pagenumber}`);
      if (response.status === 200) {
        //   const userItem = response.data?.results.filter(
        //     (item) => item?.is_verify_accessor === true || item?.is_cab_verify === true
        //   );
        const pages = Math.ceil(response?.data?.count / 10);
        setTotalPages(pages);
        setValue(response.data?.results);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagenumber]);

  useEffect(() => {
    setFilteredValue(applyFilters(value));
    setCurrentPage(1);
  }, [searchQuery, typeFilter, statusFilter, dateFilter, value]);

 const handleDocumentSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error('Password and CofirmPassword Should Be Same')
      return
    }
    try {
      const formData = new FormData()

      formData.append('password1', password)
      formData.append('password2', confirmPassword)

      const response = await axios.put(
        `${API_URL}/account/user/change/password/${userId}/`,
        formData
      )

      toast.success('Password Changed')
      handleClose()

      setPassword('')
      setconfirmPassword('')
      setuserId('')

      console.log(response.data, 'response')
    } catch (error) {
      console.error('Error occurred while uploading documents:', error)
    }
    setPassword('')
    setconfirmPassword('')
    setuserId('')
  }
  const handleInactive = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/account/cab/user/status/${userId}/update/`,
        { is_active: !isActive } // Toggle status based on current state
      )

      toast.success('User status updated')
      handleClose()

      setuserId('')
      setIsActive(null) // Reset the state after the action is completed
      console.log(response.data, 'response')
    } catch (error) {
      console.error('Error occurred while updating user status:', error)
    }
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedIds([...checkedIds, name]);
    } else {
      setCheckedIds(checkedIds.filter(id => id !== name));
    }
  };
  useEffect(() => {
    if (clickItemRole) {
      const roles = clickItemRole?.assign_role_list?.map(item => item.id)
 
      setCheckedIds(roles);
    }

  }, [clickItemRole])

  const updateUserRole = async () => {
    try {
      const response = await axios.post(`${API_URL}/role/user/assign/`, {
        user: clickItemRole?.id,
        role: checkedIds
      });
      console.log(response.data);
      toast.success('Role Assign Successfully')
      handleRoleClose();
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const windowSize = 3; 


  const startPage = Math.max(1, pagenumber - 1);
  const endPage = Math.min(startPage + windowSize - 1, totalPages);

  const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page);
      setCurrentPage(page);
    }
  };
  const exportToExcel = async() => {
    const response = await axios.get(`${API_URL}/account/cab/assessor/list/`);

    const dataToExport = response.data > 0 ? response.data : value;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'All Users');
    XLSX.writeFile(workbook, 'all_users_data.xlsx');
  };
  return (
    <div id="user_list_id">
      {loader ? <UAFLoader /> : null}
      <h1 className='mb-5'>Users List</h1>
      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                type='text'
                id="user_list_2"
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e);
                  }
                }}
                placeholder='Search user'
              />
 </div>
            <Button
              style={{marginLeft:"20px"}}
                variant='primary'
                onClick={exportToExcel} // Export button
              >
                Export to Excel
              </Button>
          </div>
        </div>

        <div className='card-body pt-5 table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='min-w-50px'>SR </th>
                <th className='min-w-125px'>Name</th>
                <th className='min-w-130px'>Email</th>
                <th className='min-w-130px'>User Type</th>
                <th className='min-w-120px'>Country</th>
                <th className='min-w-120px'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
            {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item?.is_cab_verify ? (
                      <Link to={`/crafted/pages/profile/${item?.id}/overview`} className='text-gray-800 text-hover-primary mb-1'>
                        {item?.first_name + ' ' + item?.last_name}
                      </Link>
                    ) : null}
                    {item?.is_verify_accessor ? (
                      <Link to={`/assessor/verified/${item?.id}/overview`} className='text-gray-800 text-hover-primary mb-1'>

                        {item?.first_name + ' ' + item?.last_name}
                      </Link>
                    ) : null}
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div>
                  </td>
                  <td>
                    {item?.is_cab_verify || item?.is_verify_accessor ? (
                      <div className='text-gray-800 text-hover-primary mb-1'>
                        {item?.is_cab_verify ? 'CAB' : 'Assessor'}
                      </div>
                    ) : null}
                  </td>

                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>
                      {item?.country_name}
                    </div>
                  </td>
                  <td>
                    <DropdownButton id='dropdown-basic-button' title='Action'>
                      {item?.is_verify_accessor ? (
                        <Dropdown.Item onClick={() => { handleAssignRole(); setClickItemRole(item) }}>Change Role</Dropdown.Item>
                      ) : null}

                      <Dropdown.Item onClick={() => handleShow(item?.id)}>
                        Change Password
                      </Dropdown.Item>
                      {item?.is_active ? (
                        <Dropdown.Item onClick={() => handleActiveShow(item?.id, true)}>
                          Inactive
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item onClick={() => handleActiveShow(item?.id, false)}>
                          Active
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
      {/* Previous button */}
      <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
        <a id="user_list_222" className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
          <i className='previous'></i>
        </a>
      </li>

      {/* Pages in the sliding window */}
      {totalPagesArray.slice(startPage - 1, endPage).map((page, index) => (
        <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
          <a id={`user_${index}`} className='page-link' onClick={() => handlePageChange(page)}>
            {page}
          </a>
        </li>
      ))}

      {/* Next button */}
      <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
        <a id="user_list_233" className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
          <i className='next'></i>
        </a>
      </li>
    </ul>
        </div>
      </div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={Show}
        onHide={handleClose}
      >
        <div className='modal-header'>
          <h2>Change Password</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon className='fs-1' iconName='cross' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='required form-label'>New Password</label>
              <div className='input-group'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='form-control form-control-solid'
                  placeholder='New Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <div className='input-group-append'> */}
                <span
                  className='input-group-text'
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}
                    style={{ fontSize: '18px' }}
                  ></i>
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='required form-label'>Confirm New Password</label>
              <div className='input-group'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className='form-control form-control-solid'
                  placeholder='Confirm Password'
                  value={confirmPassword}
                  onChange={(e) => setconfirmPassword(e.target.value)}
                />
                {/* <div className='input-group-append'> */}
                <span
                  className='input-group-text'
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  <i
                    className={showConfirmPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}
                    style={{ fontSize: '18px' }}
                  ></i>
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className='text-center'>
            <div className='d-flex justify-content-start col-lg-2'>
              <button className='submit_butt_div' onClick={handleDocumentSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={showActive} onHide={handleActiveClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isActive ? 'User Inactive' : 'User Active'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do You Want to {isActive ? 'Inactive' : 'Active'} This User?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleActiveClose}>
            Close
          </Button>
          <Button
            variant={isActive ? 'danger' : 'success'}
            onClick={() => {
              handleActiveClose()
              handleInactive()
            }}
          >
            {isActive ? 'Inactive' : 'Active'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* model to select role */}
      <Modal show={assignRoleShow} onHide={handleRoleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='card-body '>
            <div className='row mb-7'>
              {userRole.map(item => (

                <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  ' key={item?.id}>
                  <div className=' d-flex'>
                    <input
                      type="checkbox"
                      name={item.id}
                      checked={checkedIds.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />
                    <label className=' ' style={{ padding: '4px 0 0 14px' }} htmlFor='work_experience'>
                      {item.name}
                    </label>
                  </div>
                </div>
              ))}
            </div> </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRoleClose}>
            Close
          </Button>
          <Button
            variant="success"
            id="kr_lo_clcik"
            onClick={() => {
              updateUserRole();

              // Pass editorData as an argument to updateUserRole
              // Further processing with editorData if needed
            }}
          >

            Submit
          </Button>
        </Modal.Footer>
      </Modal >
    </div>
  )
}

export default AllUsers
