import React, {useEffect, useState} from 'react'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Select from 'react-select'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

interface Scheme {
  cab_id: string
  cab_first_name: string
  cab_last_name: string
  cab_email: string
  scheme_id: string
  scheme_name: string
  scheme_short_name: string
  location: string
  remark: string
  scheme_date: []
  create_at: string
}

interface DataEntry {
  id: string
  scheme_data: Scheme[] | null
  location: string
  remark: string
  create_at: string
  update_at: string
}
type Props = {
  uniqueCab: string | undefined
}

const ActivityEdit = ({uniqueCab}: Props) => {
  const [schemeData, setSchemeData] = useState<any[]>([])

  const [selectedScheme, setSelectedScheme] = useState<string>()
  const [schemeName, setSchemeName] = useState<string>()
  const [accreditationdate, setAccreditationdate] = useState<string>()
  console.log(accreditationdate, 'accrefhyjegwfusgeu')
  const [reaccreditationdate, setreAccreditationdate] = useState<string>()
  const [reaccreditationduedate, setreAccreditationduedate] = useState<string>()
  const [expirydate, setexpirydate] = useState<string>()
  const [planneddate, setplanneddate] = useState<string>()
  // const [formData, setFormData] = useState<any>({})

  const [globalData, setGlobalData] = useState<any>()
  const [activityAll, setActivityAll] = useState<any[]>([])
  const [cycles, setCycles] = useState<string[]>([])
  // const [allglobalData, setAllglobalData] = useState<any[]>([])
  const [allSchemedata, setAllSchemedata] = useState<any[]>([])
  const [accreditationcycle, setAccreditationcycle] = useState<any>()
  const [selectedDates, setSelectedDates] = useState([new Date()])
  const [location, setlocation] = useState<any>()
  const [remark, setremark] = useState<any>()

  const [selectYear, setselectyear] = useState<any>(null)
  const [selectCycle, setselectCycle] = useState<any>(null)
  const cabID = uniqueCab

  const yearOptions = [
    {label: 'Year 1', value: 1},
    {label: 'Year 2', value: 2},
    {label: 'Year 3', value: 3},
    {label: 'Year 4', value: 4},
  ]

  // useEffect(() => {
  //   getGlobalDetails()
  // }, [cabID])

  const convertToDateObjects = (dateStrings) => {
    if (!dateStrings) return []
    return dateStrings.map((dateStr) => new Date(dateStr))
  }
  // useEffect(() => {
  //   getGlobalDetails()
  // }, [])

  // const getGlobalDetails = () => {
  //   axios
  //     .get(`${API_URL}/cab/global/scheme/data/filter/?cab=${cabID}`)

  //     .then((response) => {
  //       const result = response.data.reverse()
  //       setAllglobalData(result)
  //     })
  //     .catch((error) => {
  //       console.error('API Error:', error)
  //     })
  // }

  useEffect(() => {
    const fetchScheme = async () => {
      try {
        const response = await axios.get(`${API_URL}/global/scheme/list/`)
        const filteredData = response.data.map((scheme: any) => ({
          id: scheme.id,
          short_name: scheme.short_name,
          name: scheme.name,
        }))
        setSchemeData(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchScheme()
  }, [])




  useEffect(() => {
    if (cabID) {
      getActivityAll()
    }
  }, [cabID])

  const getActivityAll = () => {
    axios
      .get(`${API_URL}/arc/assessment/programme/data/filter/?cab=${cabID}`)
      .then((response) => {
        const result = response?.data?.reverse()
        setActivityAll(result)
        const maxCycle = result.reduce(
          (max, item) => (item?.cycle > max ? item.cycle : max),
          -Infinity
        )
        const newCycles = Array.from({length: maxCycle}, (_, i) => (i + 1).toString())
        setCycles(newCycles)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

















  useEffect(() => {
    if (cabID && selectedScheme && selectCycle !== null && selectYear !== null) {
      getActivityDetails()
    }
  }, [cabID, selectedScheme, selectCycle, selectYear])

  const getActivityDetails = () => {
    axios
      .get(
        `${API_URL}/arc/assessment/programme/data/filter/?cab=${cabID}&scheme=${selectedScheme}&cycle=${selectCycle}&year=${selectYear}`
      )

      .then((response) => {
        const result = response?.data[0]
        setGlobalData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  const handleSchemeSelect = (e) => {
    setSelectedScheme(e.value)
    setSchemeName(e.short_name)
  }

  const handleCycleSelect = (e) => {
    console.log(e, 'this is coming e')
    setselectCycle(e.value + 1)
  }
  const handleYearSelect = (e) => {
    console.log(e, 'thi is coming year')
    setselectyear(e.value)
  }

  useEffect(() => {
    if (globalData) {
      setreAccreditationdate(globalData?.re_accreditation_date)
      setreAccreditationduedate(globalData?.re_accreditation_due_date)
      setexpirydate(globalData?.expiry_date)
      setremark(globalData?.remark)
      setlocation(globalData?.location)
      setplanneddate(globalData?.planned_date)
      setAccreditationcycle(globalData?.accreditation_cycle)
      // if (globalData?.conducted_dates) {
      //   const dateStrings = globalData.conducted_dates.replace(/'/g, '"');
      //   setSelectedDates(convertToDateObjects(JSON.parse(dateStrings)));
      // }
      if (globalData && globalData.conducted_dates) {
        try {
          // Replace single quotes with double quotes and parse the JSON string
          const dateStrings = globalData.conducted_dates.replace(/'/g, '"')
          const parsedDates = JSON.parse(dateStrings)

          // Ensure parsedDates is an array before converting to date objects
          if (Array.isArray(parsedDates)) {
            setSelectedDates(convertToDateObjects(parsedDates))
          } else {
            setSelectedDates([]) // Set an empty array if parsedDates is not an array
          }
        } catch (error) {
          console.error('Error parsing conducted_dates:', error)
          setSelectedDates([]) // Set an empty array on parse error
        }
      } else {
        setSelectedDates([])
      }
    }
  }, [globalData, selectedScheme, selectCycle, selectYear])

  const handleDateChange = (dates) => {
    const utcDates = dates.map((date) => {
      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      return utcDate;
    });
    
    setSelectedDates(utcDates);
  }
  const handleSubmit = async () => {
    const payload = {
      // cab:cabID,
      // scheme: selectedScheme,
      // re_accreditation_date:reaccreditationdate,
      // expiry_date: expirydate,
      // re_accreditation_due_date:reaccreditationduedate,

      accreditation_cycle: accreditationcycle,
      // planned_date: moment(accreditationdate).add(10, 'months').format('YYYY-MM-DD'),
      conducted_dates: JSON.stringify(selectedDates),
      location,
      remark,
    }

    try {
      const response = await axios.put(
        `${API_URL}/arc/assessment/programme/data/${globalData?.id}/update/`,
        payload
      )
      console.log('Data submitted successfully:', response.data)
      toast.success('Activity Data Updated Successfully')
      getActivityDetails()
      setGlobalData(null)
    } catch (error) {
      console.error('Error submitting data:', error)
    }
  }

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>Activity Assessment</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='col-xl-12 card card-xl-stretch mb-5 mb-xl-10'>
          <div className='p-3 d-flex justify-content-between w-100 align-items-center'>
            <div className='m-2 col-5'>
              {/* <label className='form-label'>Scheme Name</label> */}
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Scheme'
                options={schemeData?.map((item) => ({
                  label: `${item?.short_name}`,
                  value: item?.id,
                  short_name: item?.short_name,
                }))}
                onChange={handleSchemeSelect}
              />
            </div>
            <div className=' col-3 m-2'>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Cycle'
                options={cycles.map((cycle, index) => ({
                  label: `Cycle${index + 1}`,
                  value: index,
                }))}
                onChange={handleCycleSelect}
              />
            </div>
            <div className=' col-3 m-2'>
              <Select
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Select Year'
                options={yearOptions}
                onChange={handleYearSelect}
              />
            </div>
          </div>
        </div>


        <div className='table-responsive mt-8'>
          <table className='align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Management System
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Inital of accredidation (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  RA due on (MMYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Expiry (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Accredidation Cycle
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Planned (MMYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Conducted (MMDDYYYY)
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Non-key Location
                </th>
                <th
                  style={{border: '1px solid #dddddd', padding: '8px'}}
                  className='ps-4 min-w-150px rounded-start'
                >
                  Next Cycle Remark
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>{schemeName}</div>
                  </div>
                </td>

                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                    { reaccreditationdate ? moment(reaccreditationdate).format('MM-DD-YYYY'):null}
                      {/* {reaccreditationdate} */}
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {/* {expirydate} */}
                      { expirydate ? moment(expirydate).format('MM-DD-YYYY'):null}
                      </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {/* {reaccreditationduedate} */}
                      { reaccreditationduedate ? moment(reaccreditationduedate).format('MM-DD-YYYY'):null}
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={accreditationcycle}
                        onChange={(e) => setAccreditationcycle(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  {planneddate && (
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                      {/* {new Date(planneddate).toLocaleDateString('en-US', {
                            month: '2-digit',
                            year: 'numeric',
                            timeZone: 'UTC'
                          })} */}
                          {planneddate
                            ?   moment(planneddate).format('MM/YYYY')
                            : null}
                      </div>
                    </div>
                  )}
                </td>

                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <ReactDatePicker
                        placeholderText='Select Dates'
                        className='uaf-datepicker'
                        selectedDates={selectedDates}
                        selectsMultiple
                        onChange={handleDateChange}
                        shouldCloseOnSelect={false}
                        disabledKeyboardNavigation
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={location}
                        onChange={(e) => setlocation(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        name='reason_for_updating'
                        value={remark}
                        onChange={(e) => setremark(e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='mb-5 mt-5'>
          <button className='btn btn-primary' type='button' onClick={handleSubmit}>
            Update
            <i className='fa fa-angle-double-right' aria-hidden='true' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActivityEdit
