import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserDataType } from "../AssessorInterface";
import UAFLoader from "../../../loader/UafLoader";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const API_URL = process.env.REACT_APP_API_URL

interface Props {
    userId: string | undefined;
}
interface LocationState {
    userId?: string;
}
interface DataItem {
    id: string
    name: string
}
interface IAFCriticalData {
    id: string;
    name: string;
    scheme: string;
    iaf_sector_doc: File | null

}
interface FileState {
    work_experience_doc: File | null
    training_17011_doc: File | null
    training_17021_1_doc: File | null
    training_17021_2_doc: File | null
    training_17021_3_doc: File | null
    training_17021_10_doc: File | null
    training_17024_doc: File | null
    training_27000_doc: File | null
    training_27005_doc: File | null
    training_27006_doc: File | null
    training_27007_doc: File | null
    training_27008_doc: File | null
    training_22000_doc: File | null
    training_22003_doc: File | null
    training_22003_1_doc: File | null
    training_50001_doc: File | null
    training_50003_doc: File | null
    training_45001_doc: File | null
    training_13485_doc: File | null
    training_9001_doc: File | null
    training_14001_doc: File | null
}
interface FormState {
    first_name: string
    last_name: string
    email: string
    company: string
    phone: string
    profile: File | null
    graduation_certification: File | null
    resume: File | null
    certification: string[]
    country: string
    message: string
    graduation: string
    university_name: string
    graduation_date: string
    work_experience: boolean
    training_17011: boolean
    training_17021_1: boolean
    training_17021_2: boolean
    training_17021_3: boolean
    training_17021_10: boolean
    training_17024: boolean
    training_27000: boolean
    training_27005: boolean
    training_27006: boolean
    training_27007: boolean
    training_27008: boolean
    training_22000: boolean
    training_22003: boolean
    training_22003_1: boolean
    training_50001: boolean
    training_50003: boolean
    training_45001: boolean
    training_13485: boolean
    training_9001: boolean
    training_14001: boolean

    work_experience_doc: File | null
    training_17011_doc: File | null
    training_17021_1_doc: File | null
    training_17021_2_doc: File | null
    training_17021_3_doc: File | null
    training_17021_10_doc: File | null
    training_17024_doc: File | null
    training_27000_doc: File | null
    training_27005_doc: File | null
    training_27006_doc: File | null
    training_27007_doc: File | null
    training_27008_doc: File | null
    training_22000_doc: File | null
    training_22003_doc: File | null
    training_22003_1_doc: File | null
    training_50001_doc: File | null
    training_50003_doc: File | null
    training_45001_doc: File | null
    training_13485_doc: File | null
    training_9001_doc: File | null
    training_14001_doc: File | null
}


const AddManualAssessor = () => {

    const [loader, setLoader] = useState<any>(false);
    const [userData, setUserData] = useState<UserDataType | null>(null);
    const [userDataInfo, setUserDataInfo] = useState<UserDataType | null>(null);
    const [userCertificate, setUserCertificate] = useState<UserDataType>()
    const [userIAFData, setUserIAFData] = useState<UserDataType>()


    const navigate = useNavigate();

    // const navigateFunction = async (id) => {
    //   navigate(`/assessor/details/edit/info/${id}`);
    // };


    const [files, setFiles] = useState<FileState>({
        work_experience_doc: null,
        training_17011_doc: null,
        training_17021_1_doc: null,
        training_17021_2_doc: null,
        training_17021_3_doc: null,
        training_17021_10_doc: null,
        training_17024_doc: null,
        training_27000_doc: null,
        training_27005_doc: null,
        training_27006_doc: null,
        training_27007_doc: null,
        training_27008_doc: null,
        training_22000_doc: null,
        training_22003_doc: null,
        training_22003_1_doc: null,
        training_50001_doc: null,
        training_50003_doc: null,
        training_45001_doc: null,
        training_13485_doc: null,
        training_9001_doc: null,
        training_14001_doc: null,
    })

    console.log(files, 'PRINTED FILE .......')
    const [value, setValue] = useState<FormState>({
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        phone: '',
        resume: null,

        profile: null,
        graduation_certification: null,
        certification: [],
        country: '',
        message: '',
        graduation: '',
        graduation_date: '',
        university_name: '',

        work_experience: false,
        training_17011: false,
        training_17021_1: false,
        training_17021_2: false,
        training_17021_3: false,
        training_17021_10: false,
        training_17024: false,
        training_27000: false,
        training_27005: false,
        training_27006: false,
        training_27007: false,
        training_27008: false,
        training_22000: false,
        training_22003: false,
        training_22003_1: false,
        training_50001: false,
        training_50003: false,
        training_45001: false,
        training_13485: false,
        training_9001: false,
        training_14001: false,

        work_experience_doc: null,
        training_17011_doc: null,
        training_17021_1_doc: null,
        training_17021_2_doc: null,
        training_17021_3_doc: null,
        training_17021_10_doc: null,
        training_17024_doc: null,
        training_27000_doc: null,
        training_27005_doc: null,
        training_27006_doc: null,
        training_27007_doc: null,
        training_27008_doc: null,
        training_22000_doc: null,
        training_22003_doc: null,
        training_22003_1_doc: null,
        training_50001_doc: null,
        training_50003_doc: null,
        training_45001_doc: null,
        training_13485_doc: null,
        training_9001_doc: null,
        training_14001_doc: null,

    })
    console.log(value, "setValuesetValuesetValue");

    useEffect(() => {
        if (userData && userCertificate) {
            setValue({
                first_name: userData?.first_name || '',
                last_name: userData.last_name || '',
                email: userData.email || '',
                company: userData.company || '',
                phone: userData.phone || '',
                resume: userData.resume || null,
                profile: userData.profile || null,
                graduation_certification: userCertificate.graduation_certification || null,
                certification: userData.certification || [],
                country: userData.country || '',
                message: userData.message || '',
                graduation: userCertificate.graduation || '',
                graduation_date: userCertificate.graduation_date || '',
                university_name: userCertificate.university_name || '',
                work_experience: userCertificate.work_experience || false,
                training_17011: userCertificate.training_17011 || false,
                training_17021_1: userCertificate.training_17021_1 || false,
                training_17021_2: userCertificate.training_17021_2 || false,
                training_17021_3: userCertificate.training_17021_3 || false,
                training_17021_10: userCertificate.training_17021_10 || false,
                training_17024: userCertificate.training_17024 || false,
                training_27000: userCertificate.training_27000 || false,
                training_27005: userCertificate.training_27005 || false,
                training_27006: userCertificate.training_27006 || false,
                training_27007: userCertificate.training_27007 || false,
                training_27008: userCertificate.training_27008 || false,
                training_22000: userCertificate.training_22000 || false,
                training_22003: userCertificate.training_22003 || false,
                training_22003_1: userCertificate.training_22003_1 || false,
                training_50001: userCertificate.training_50001 || false,
                training_50003: userCertificate.training_50003 || false,
                training_45001: userCertificate.training_45001 || false,
                training_13485: userCertificate.training_13485 || false,
                training_9001: userCertificate.training_9001 || false,
                training_14001: userCertificate.training_14001 || false,

                work_experience_doc: userCertificate.work_experience_doc || null,
                training_17011_doc: userCertificate.training_17011_doc || null,
                training_17021_1_doc: userCertificate.training_17021_1_doc || null,
                training_17021_2_doc: userCertificate.training_17021_2_doc || null,
                training_17021_3_doc: userCertificate.training_17021_3_doc || null,
                training_17021_10_doc: userCertificate.training_17021_10_doc || null,
                training_17024_doc: userCertificate.training_17024_doc || null,
                training_27000_doc: userCertificate.training_27000_doc || null,
                training_27005_doc: userCertificate.training_27005_doc || null,
                training_27006_doc: userCertificate.training_27006_doc || null,
                training_27007_doc: userCertificate.training_27007_doc || null,
                training_27008_doc: userCertificate.training_27008_doc || null,
                training_22000_doc: userCertificate.training_22000_doc || null,
                training_22003_doc: userCertificate.training_22003_doc || null,
                training_22003_1_doc: userCertificate.training_22003_1_doc || null,
                training_50001_doc: userCertificate.training_50001_doc || null,
                training_50003_doc: userCertificate.training_50003_doc || null,
                training_45001_doc: userCertificate.training_45001_doc || null,
                training_13485_doc: userCertificate.training_13485_doc || null,
                training_9001_doc: userCertificate.training_9001_doc || null,
                training_14001_doc: userCertificate.training_14001_doc || null,
            });
        }
    }, [userData, userCertificate]);

    const [countryData, setCountryData] = useState<DataItem[]>([])
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [selectedSchemesList, setSelectedSchemesList] = useState<string[]>([]);
    const [selectedStandardList, setSelectedStandardList] = useState<string[]>([]);
    const [selectedClusterList, setSelectedClusterList] = useState<string[]>([]);
    const [selectedSchemes, setSelectedSchemes] = useState<{ [key: string]: string[] }>({});
    const [selectedStandards, setSelectedStandards] = useState<{ [key: string]: { [key: string]: string[] } }>({});
    const [selectedClusters, setSelectedClusters] = useState<{ [key: string]: { [key: string]: string[] } }>({});
    const [selelctedIAFCriticalValue, setSelelctedIAFCriticalValue] = useState<IAFCriticalData[]>([])


    const [valueCon, setValueCon] = useState<any>()


    useEffect(() => {
        fetchCountry()
    }, [])

    const fetchCountry = async () => {
        try {
            const response = await axios.get<DataItem[]>(`${API_URL}/certificate/list/country/`)
            setCountryData(response.data)
        } catch (error) {
            // console.error("Error fetching data:", error);
        }
    }

    const handleResume = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, resume: e.target.files ? e.target.files[0] : null })
    }
    const handleGraduate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            graduation_certification: e.target.files ? e.target.files[0] : null,
        })
    }

    const handleCheckboxChange =
        (name: keyof FormState) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue({ ...value, [name]: e.target.checked })
            if (!e.target.checked) {
                setFiles({ ...files, [`${name}_doc`]: null })
            }
        }
    const handleFileChange = (name: keyof FileState) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = value[name.replace('_doc', '') as keyof FormState] // Use type assertion to ensure type safety
        if (!isChecked) {
            toast.error(
                `Please check the corresponding checkbox before uploading the document for ${name.replace(
                    '_doc',
                    ''
                )}`
            )
            return
        }
        if (e.target.files && e.target.files.length > 0) {
            setFiles({ ...files, [name]: e.target.files[0] })
            setValue({ ...value, [name]: e.target.files[0] })
        }
    }
    const Validation = () => {
        if (!value?.first_name || value?.first_name?.trim() === "") {
            toast.error("Please Enter First Name")
            return false;
        }
        else if (!value?.email || value?.email?.trim() === "") {
            toast.error("Please Enter Email")
            return false;
        }
        else if (!value.country) {
            toast.error("Please Enter Country")
            return false;
        } else if (!valueCon) {
            toast.error("Please Enter Phone Number")
            return false;
        }

        return true;

    }
    const handelSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const { first_name, email, country, } = value;
        if (!first_name || !email || !country || !valueCon) {
            toast.error(' Please Fill Mandatory Fields.');
            return;
        }


        try {
            setLoader(true);
            const formData = new FormData()
            formData.append('first_name', value.first_name)
            formData.append('last_name', value.last_name)
            formData.append('email', value.email)
            formData.append('company', value.company)
            formData.append('phone', valueCon)
            formData.append('country', value.country)
            // formData.append('message', value.message)
            formData.append('is_verify_accessor', 'true')
            if (value.resume && value.resume instanceof File) {
                formData.append('resume', value.resume)
            }
            // formData.append("programme", JSON.stringify(selectedPrograms));
            // formData.append("scheme", JSON.stringify(selectedSchemesList));
            // formData.append("standard", JSON.stringify(selectedStandardList));
            // formData.append("cluster", JSON.stringify(selectedClusterList));



            // formData.append("certification", JSON.stringify(value.certification));
            // formData.append('graduation', value.graduation)
            // formData.append('selectedStandards', JSON.stringify(selectedStandards));
            // formData.append('selectedClusters', JSON.stringify(selectedClusters));
            // formData.append('selectedSchemes', JSON.stringify(selectedSchemes));




            // formData.append('graduation_date', JSON.stringify(value.graduation_date))
            // formData.append('university_name', value.university_name)
            // formData.append('work_experience', JSON.stringify(value.work_experience))
            // formData.append('training_17011', JSON.stringify(value.training_17011))
            // formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
            // // formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
            // formData.append('training_17021_2', JSON.stringify(value.training_17021_2))
            // formData.append('training_17021_3', JSON.stringify(value.training_17021_3))
            // formData.append('training_17021_10', JSON.stringify(value.training_17021_10))
            // formData.append('training_17024', JSON.stringify(value.training_17024))
            // formData.append('training_27000', JSON.stringify(value.training_27000))
            // formData.append('training_27005', JSON.stringify(value.training_27005))
            // formData.append('training_27006', JSON.stringify(value.training_27006))
            // formData.append('training_27007', JSON.stringify(value.training_27007))
            // formData.append('training_27008', JSON.stringify(value.training_27008))
            // formData.append('training_22000', JSON.stringify(value.training_22000))
            // formData.append('training_22003', JSON.stringify(value.training_22003))
            // formData.append('training_22003_1', JSON.stringify(value.training_22003_1))
            // formData.append('training_50001', JSON.stringify(value.training_50001))
            // formData.append('training_50003', JSON.stringify(value.training_50003))
            // formData.append('training_45001', JSON.stringify(value.training_45001))
            // formData.append('training_13485', JSON.stringify(value.training_13485))
            // formData.append('training_9001', JSON.stringify(value.training_9001))
            // formData.append('training_14001', JSON.stringify(value.training_14001))
            // if (value.work_experience_doc && value.work_experience_doc instanceof File) {
            //     formData.append('work_experience_doc', value.work_experience_doc)
            // }
            // if (value.training_17011_doc && value.training_17011_doc instanceof File) {
            //     formData.append('training_17011_doc', value.training_17011_doc)
            // }
            // if (value.graduation_certification && value.graduation_certification instanceof File) {
            //     formData.append('graduation_certification', value.graduation_certification)
            // }
            // if (value.training_17021_1_doc && value.training_17021_1_doc instanceof File) {
            //     formData.append('training_17021_1_doc', value.training_17021_1_doc)
            // }
            // if (value.training_17021_2_doc && value.training_17021_2_doc instanceof File) {
            //     formData.append('training_17021_2_doc', value.training_17021_2_doc)
            // }
            // if (value.training_17021_3_doc && value.training_17021_3_doc instanceof File) {
            //     formData.append('training_17021_3_doc', value.training_17021_3_doc)
            // }
            // if (value.training_17021_10_doc && value.training_17021_10_doc instanceof File) {
            //     formData.append('training_17021_10_doc', value.training_17021_10_doc)
            // }
            // if (value.training_17024_doc && value.training_17024_doc instanceof File) {
            //     formData.append('training_17024_doc', value.training_17024_doc)
            // }
            // if (value.training_27000_doc && value.training_27000_doc instanceof File) {
            //     formData.append('training_27000_doc', value.training_27000_doc)
            // }
            // if (value.training_27005_doc && value.training_27005_doc instanceof File) {
            //     formData.append('training_27005_doc', value.training_27005_doc)
            // }
            // if (value.training_27006_doc && value.training_27006_doc instanceof File) {
            //     formData.append('training_27006_doc', value.training_27006_doc)
            // }
            // if (value.training_27007_doc && value.training_27007_doc instanceof File) {
            //     formData.append('training_27007_doc', value.training_27007_doc)
            // }
            // if (value.training_27008_doc && value.training_27008_doc instanceof File) {
            //     formData.append('training_27008_doc', value.training_27008_doc)
            // }
            // if (value.training_22000_doc && value.training_22000_doc instanceof File) {
            //     formData.append('training_22000_doc', value.training_22000_doc)
            // }
            // if (value.training_22003_doc && value.training_22003_doc instanceof File) {
            //     formData.append('training_22003_doc', value.training_22003_doc)
            // }
            // if (value.training_22003_1_doc && value.training_22003_1_doc instanceof File) {
            //     formData.append('training_22003_1_doc', value.training_22003_1_doc)
            // }
            // if (value.training_50001_doc && value.training_50001_doc instanceof File) {
            //     formData.append('training_50001_doc', value.training_50001_doc)
            // }
            // if (value.training_50003_doc && value.training_50003_doc instanceof File) {
            //     formData.append('training_50003_doc', value.training_50003_doc)
            // }
            // if (value.training_45001_doc && value.training_45001_doc instanceof File) {
            //     formData.append('training_45001_doc', value.training_45001_doc)
            // }
            // if (value.training_13485_doc && value.training_13485_doc instanceof File) {
            //     formData.append('training_13485_doc', value.training_13485_doc)
            // }
            // if (value.training_9001_doc && value.training_9001_doc instanceof File) {
            //     formData.append('training_9001_doc', value.training_9001_doc)
            // }
            // if (value.training_14001_doc && value.training_14001_doc instanceof File) {
            //     formData.append('training_14001_doc', value.training_14001_doc)
            // }
            // // Append selelctedIAFCriticalValue data to formData
            // Object.keys(selelctedIAFCriticalValue).forEach(clusterId => {
            //     const clusterData = selelctedIAFCriticalValue[clusterId];
            //     Object.keys(clusterData).forEach(itemId => {
            //         const itemData = clusterData[itemId];
            //         // Append file data if available
            //         if (itemData.file instanceof File) {
            //             formData.append(`selelctedIAFCriticalValue[${clusterId}][${itemId}][file]`, itemData.file);
            //         }
            //     });
            // });

            const response = await axios.post(`${API_URL}/team/assessor/custom/post/`, formData);

            if (response.status === 200) {
                toast.success('Application submitted successfully. Please Add Experience, Training and Program Doc');
                const resposneId = response?.data?.id
                setLoader(false);
                console.log(response, "responseresponseresponseresponse");

                navigate(`/assessor/details/edit/trainingdoc/${resposneId}`);
            } else {
                toast.error('Failed to submit application. Please try again.');
            }

        } catch (error: any) {
            setLoader(false);
            console.error(error);
            if (error.response && error.response.status === 400) {
                if (error.response.data?.includes("Key (email)=")) {
                    toast.error('This email address is already in use. Please use a different email.');
                } else {
                    toast.error('Failed to submit application due to a validation error. Please check your input.');
                }
            } else {
                toast.error('Something went wrong, please try again');
            }
        }
    }

    return (


        <div id="new_assessor_form">
            {loader ? <UAFLoader /> : null}




            <form

                onSubmit={handelSubmit}
            >
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>
                                Assessor Info<span style={{ color: 'red' }}>*</span>
                            </h3>
                        </div>
                    </div>
                    <div className='card-body p-9'>
                        <div className="row">
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'>First Name</label>
                                    <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='First Name'
                                        name="first_name"
                                        value={value?.first_name}
                                        onChange={(e) => setValue({ ...value, first_name: e.target.value })}

                                    />
                                </div>

                            </div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className=' form-label'>Last Name</label>
                                    <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='Last Name'
                                        name="last_name"
                                        value={value?.last_name}
                                        onChange={(e) => setValue({ ...value, last_name: e.target.value })}
                                    />
                                </div>

                            </div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'>Email</label>
                                    <input
                                        type='email'
                                        placeholder='Email address'
                                        value={value?.email}
                                        name='email'
                                        onChange={(e) => setValue({ ...value, email: e.target.value })}
                                        className='form-control form-control-solid'
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'>Mobile</label>
                                    <PhoneInput
                                        className="form-control form-control-solid"
                                        international
                                        defaultCountry="US"
                                        value={valueCon}
                                        onChange={setValueCon} />
                                    {/* <input
                                    type='text'
                                    placeholder='Phone Number'
                                    name='phone'
                                    value={value?.phone}
                                    onChange={(e) =>
                                        setValue({ ...value, phone: e.target.value })
                                    }
                                    className='form-control form-control-solid'
                                /> */}
                                </div>
                            </div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className='form-label'>Company</label>
                                    <input
                                        type='text'
                                        placeholder='Company'
                                        name='Company'
                                        value={value?.company}
                                        onChange={(e) =>
                                            setValue({ ...value, company: e.target.value })
                                        }
                                        className='form-control form-control-solid'
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'>Nationality</label>
                                    <select
                                        onChange={(e) => setValue({ ...value, country: e.target.value })}
                                        value={value.country}
                                        className='form-control form-control-solid'
                                    >
                                        <option value=''>Nationality</option>
                                        {countryData.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div></div>
                            <div className="col-lg-4 fv-row">
                                <div className='mb-5'>
                                    <label
                                        htmlFor='resume'
                                        className={value.resume ? 'file-uploaded-uploaded' : 'file-upload-upload'}
                                        style={{ width: '150px' }}
                                    >
                                        {value.resume ? 'CV Uploaded' : 'Upload CV'}
                                    </label>
                                    <input type='file' id='resume' style={{ display: 'none' }} onChange={handleResume} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* qualification */}
                {/* <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            Qualification<span style={{ color: 'red' }}>*</span>
                        </h3>
                    </div>
                </div>
                <div className='card-body p-9'>
                    <div className='row mb-7'>
                        <div className='col-lg-3 fv-row'>
                            <input
                                type='text'
                                placeholder='Graduation'
                                className='form-control form-control-lg form-control-solid mb-3 '
                                name='graduation'
                                value={value?.graduation}
                                onChange={(e) =>
                                    setValue({
                                        ...value,
                                        graduation: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-3 fv-row'>
                            <input
                                type='text'
                                placeholder='University Name'
                                className='form-control form-control-lg form-control-solid mb-3 '
                                name='university_name'
                                value={value?.university_name}
                                onChange={(e) =>
                                    setValue({
                                        ...value,
                                        university_name: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-3 fv-row'>
                            <input
                                type='text'
                                name='graduation_date'
                                value={value?.graduation_date}
                                className='form-control form-control-lg form-control-solid mb-3 '
                                placeholder='Year'

                                onChange={(e) =>
                                    setValue({
                                        ...value,
                                        graduation_date: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-3 fv-row'>
                            <div className='contact-page__input-box text-center'>
                                <label
                                    htmlFor='graduation_certification'
                                    className={
                                        value.graduation_certification
                                            ? 'file-uploaded-uploaded'
                                            : 'file-upload-upload'
                                    }
                                >
                                    {value.graduation_certification
                                        ? 'Uploaded'
                                        : 'Upload'}
                                </label>
                                <input
                                    type='file'
                                    id='graduation_certification'
                                    style={{ display: 'none' }}
                                    onChange={handleGraduate}
                                />
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}
                {/* Experience */}
                {/* <div className='card mb-5'>
                <div className='card-header cursor-pointer'>
                    <div className='col-lg-12 card-title m-0 d-flex row'>
                        <div className='col-lg-6'>
                            <h3 className='fw-bolder m-0'>Experience / Training</h3>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-end'>
                            <div style={{ fontWeight: '600', paddingTop: '2px' }}>
                                Upload Relevant Doc, If you have
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card-body '>
                    <div className='row mb-7'>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='work_experience'
                                    name='work_experience'
                                    className=''
                                    checked={value.work_experience}
                                    onChange={handleCheckboxChange('work_experience')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='work_experience'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='work_experience'>
                                    Do you have work experience in accreditaion/certification process (Minimum 5
                                    years)
                                </label>
                            </div>
                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.work_experience_doc ? (
                                    <label
                                        htmlFor='work_experience_doc'

                                        className={
                                            files.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.work_experience_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='work_experience_doc'

                                        className={
                                            value.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.work_experience_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='work_experience_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('work_experience_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17011'
                                    name='training_17011'
                                    className=''
                                    checked={value.training_17011}
                                    onChange={handleCheckboxChange('training_17011')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17011'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17011'>
                                    Have you done Training on ISO/IEC 17011
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17011_doc ? (<label
                                    htmlFor='training_17011_doc'
                                    className={
                                        files.training_17011_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                    }
                                >
                                    {files.training_17011_doc ? ' Uploaded' : 'Upload'}
                                </label>) : (
                                    <label
                                        htmlFor='training_17011_doc'
                                        className={
                                            value.training_17011_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17011_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17011_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17011_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17021_1'
                                    name='training_17021_1'
                                    checked={value.training_17021_1}
                                    onChange={handleCheckboxChange('training_17021_1')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17021_1'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_1'>
                                    Have you done Training on ISO/IEC 17021-1
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17021_1_doc ? (
                                    <label
                                        htmlFor='training_17021_1_doc'
                                        className={
                                            files.training_17021_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_17021_1_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_17021_1_doc'
                                        className={
                                            value.training_17021_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17021_1_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17021_1_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17021_1_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17021_2'
                                    name='training_17021_2'
                                    checked={value.training_17021_2}
                                    onChange={handleCheckboxChange('training_17021_2')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17021_2'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_2'>
                                    Have you done Training on ISO/IEC 17021-2
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17021_2_doc ? (
                                    <label
                                        htmlFor='training_17021_2_doc'
                                        className={
                                            files.training_17021_2_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_17021_2_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_17021_2_doc'
                                        className={
                                            value.training_17021_2_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17021_2_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17021_2_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17021_2_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17021_3'
                                    name='training_17021_3'
                                    checked={value.training_17021_3}
                                    onChange={handleCheckboxChange('training_17021_3')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17021_3'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_3'>
                                    Have you done Training on ISO/IEC 17021-3
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17021_3_doc ? (
                                    <label
                                        htmlFor='training_17021_3_doc'
                                        className={
                                            files.training_17021_3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_17021_3_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_17021_3_doc'
                                        className={
                                            value.training_17021_3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17021_3_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17021_3_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17021_3_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17021_10'
                                    name='training_17021_10'
                                    checked={value.training_17021_10}
                                    onChange={handleCheckboxChange('training_17021_10')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17021_10'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_10'>
                                    Have you done Training on ISO/IEC 17021-10
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17021_10_doc ? (
                                    <label
                                        htmlFor='training_17021_10_doc'
                                        className={
                                            files.training_17021_10_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_17021_10_doc ? 'Uploaded' : 'Upload'}
                                    </label>

                                ) : (
                                    <label
                                        htmlFor='training_17021_10_doc'
                                        className={
                                            value.training_17021_10_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17021_10_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17021_10_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17021_10_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_17024'
                                    name='training_17024'
                                    checked={value.training_17024}
                                    onChange={handleCheckboxChange('training_17024')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_17024'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17024'>
                                    Have you done Training on ISO/IEC 17024
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_17024_doc ? (
                                    <label
                                        htmlFor='training_17024_doc'
                                        className={
                                            files.training_17024_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_17024_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_17024_doc'
                                        className={
                                            value.training_17024_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_17024_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_17024_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_17024_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_27000'
                                    name='training_27000'
                                    checked={value.training_27000}
                                    onChange={handleCheckboxChange('training_27000')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_27000'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27000'>
                                    Have you done Training on ISO/IEC 27000
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_27000_doc ? (
                                    <label
                                        htmlFor='training_27000_doc'
                                        className={
                                            files.training_27000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_27000_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_27000_doc'
                                        className={
                                            value.training_27000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_27000_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_27000_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_27000_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_27005'
                                    name='training_27005'
                                    checked={value.training_27005}
                                    onChange={handleCheckboxChange('training_27005')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_27005'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27005'>
                                    Have you done Training on ISO/IEC 27005
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_27005_doc ? (<label
                                    htmlFor='training_27005_doc'
                                    className={
                                        files.training_27005_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                    }
                                >
                                    {files.training_27005_doc ? 'Uploaded' : 'Upload'}
                                </label>) : (<label
                                    htmlFor='training_27005_doc'
                                    className={
                                        value.training_27005_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                    }
                                >
                                    {value.training_27005_doc ? 'Uploaded' : 'Upload'}
                                </label>)}

                                <input
                                    type='file'
                                    id='training_27005_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_27005_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_27006'
                                    name='training_27006'
                                    checked={value.training_27006}
                                    onChange={handleCheckboxChange('training_27006')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_27006'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27006'>
                                    Have you done Training on ISO/IEC 27006
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_27006_doc ? (
                                    <label
                                        htmlFor='training_27006_doc'
                                        className={
                                            files.training_27006_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_27006_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_27006_doc'
                                        className={
                                            value.training_27006_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_27006_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_27006_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_27006_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_27007'
                                    name='training_27007'
                                    checked={value.training_27007}
                                    onChange={handleCheckboxChange('training_27007')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_27007'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27007'>
                                    Have you done Training on ISO/IEC 27007
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_27007_doc ? (<label
                                    htmlFor='training_27007_doc'
                                    className={
                                        files.training_27007_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                    }
                                >
                                    {files.training_27007_doc ? 'Uploaded' : 'Upload'}
                                </label>) : (<label
                                    htmlFor='training_27007_doc'
                                    className={
                                        value.training_27007_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                    }
                                >
                                    {value.training_27007_doc ? 'Uploaded' : 'Upload'}
                                </label>)}

                                <input
                                    type='file'
                                    id='training_27007_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_27007_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_27008'
                                    name='training_27008'
                                    checked={value.training_27008}
                                    onChange={handleCheckboxChange('training_27008')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_27008'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27008'>
                                    Have you done Training on ISO/IEC 27007
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_27008_doc ? (
                                    <label
                                        htmlFor='training_27008_doc'
                                        className={
                                            files.training_27008_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_27008_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_27008_doc'
                                        className={
                                            value.training_27008_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_27008_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_27008_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_27008_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_22000'
                                    name='training_22000'
                                    checked={value.training_22000}
                                    onChange={handleCheckboxChange('training_22000')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_22000'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22000'>
                                    Have you done Training on ISO 22000
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_22000_doc ? (
                                    <label
                                        htmlFor='training_22000_doc'
                                        className={
                                            files.training_22000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_22000_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_22000_doc'
                                        className={
                                            value.training_22000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_22000_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_22000_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_22000_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_22003'
                                    name='training_22003'
                                    checked={value.training_22003}
                                    onChange={handleCheckboxChange('training_22003')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_22003'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003'>
                                    Have you done Training on ISO/IEC 22003
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_22003_doc ? (
                                    <label
                                        htmlFor='training_22003_doc'
                                        className={
                                            files.training_22003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_22003_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_22003_doc'
                                        className={
                                            value.training_22003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_22003_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_22003_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_22003_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_22003_1'
                                    name='training_22003_1'
                                    checked={value.training_22003_1}
                                    onChange={handleCheckboxChange('training_22003_1')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_22003_1'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003_1'>
                                    Have you done Training on ISO/IEC 22003-1
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_22003_1_doc ? (
                                    <label
                                        htmlFor='training_22003_1_doc'
                                        className={
                                            files.training_22003_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_22003_1_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_22003_1_doc'
                                        className={
                                            value.training_22003_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_22003_1_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_22003_1_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_22003_1_doc')}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_50001'
                                    name='training_50001'
                                    className='custom-checkbox'
                                    checked={value.training_50001}
                                    onChange={handleCheckboxChange('training_50001')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_50001'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50001'>
                                    Have you done Training on ISO 50001
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_50001_doc ? (
                                    <label
                                        htmlFor='training_50001_doc'
                                        className={
                                            files.training_50001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_50001_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_50001_doc'
                                        className={
                                            value.training_50001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_50001_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_50001_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_50001_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_50003'
                                    name='training_50003'
                                    className='custom-checkbox'
                                    checked={value.training_50003}
                                    onChange={handleCheckboxChange('training_50003')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_50003'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50003'>
                                    Have you done Training on ISO 50003
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_50003_doc ? (
                                    <label
                                        htmlFor='training_50003_doc'
                                        className={
                                            files.training_50003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_50003_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_50003_doc'
                                        className={
                                            value.training_50003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_50003_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_50003_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_50003_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_45001'
                                    name='training_45001'
                                    checked={value.training_45001}
                                    onChange={handleCheckboxChange('training_45001')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_45001'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_45001'>
                                    Have you done Training on ISO 45001
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_45001_doc ? (
                                    <label
                                        htmlFor='training_45001_doc'
                                        className={
                                            files.training_45001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_45001_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_45001_doc'
                                        className={
                                            value.training_45001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_45001_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_45001_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_45001_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_13485'
                                    name='training_13485'
                                    checked={value.training_13485}
                                    onChange={handleCheckboxChange('training_13485')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_13485'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_13485'>
                                    Have you done Training on ISO 13485
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_13485_doc ? (
                                    <label
                                        htmlFor='training_13485_doc'
                                        className={
                                            files.training_13485_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_13485_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_13485_doc'
                                        className={
                                            value.training_13485_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_13485_doc ? ' Uploaded' : 'Upload '}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_13485_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_13485_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_9001'
                                    name='training_9001'
                                    checked={value.training_9001}
                                    onChange={handleCheckboxChange('training_9001')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_9001'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_9001'>
                                    Have you done Training on ISO 9001
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_9001_doc ? (
                                    <label
                                        htmlFor='training_9001_doc'
                                        className={
                                            files.training_9001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_9001_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_9001_doc'
                                        className={
                                            value.training_9001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_9001_doc ? ' Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_9001_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_9001_doc')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                            <div className=' d-flex'>
                                <input
                                    type='checkbox'
                                    id='training_14001'
                                    name='training_14001'
                                    checked={value.training_14001}
                                    onChange={handleCheckboxChange('training_14001')}
                                />
                                <label
                                    className=' '
                                    style={{ padding: '0 0 0 16px' }}
                                    htmlFor='training_14001'
                                ></label>
                                <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_14001'>

                                    Have you done Training on ISO 14001
                                </label>
                            </div>

                            <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                {files.training_14001_doc ? (
                                    <label
                                        htmlFor='training_14001_doc'
                                        className={
                                            files.training_14001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {files.training_14001_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                ) : (
                                    <label
                                        htmlFor='training_14001_doc'
                                        className={
                                            value.training_14001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                        }
                                    >
                                        {value.training_14001_doc ? 'Uploaded' : 'Upload'}
                                    </label>
                                )}

                                <input
                                    type='file'
                                    id='training_14001_doc'
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange('training_14001_doc')}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div> */}



                {/* <div className='card mb-5'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0  row'>

                        <h3 className='fw-bolder m-0'>Remarks</h3>


                    </div>
                </div>

                <div className='card-body '>

                    <div className='mb-5'>

                        <textarea
                            className='form-control form-control-solid'
                            rows={5}
                            placeholder='Enter Remarks'
                            name='message'
                            onChange={(e) =>
                                setValue({
                                    ...value,
                                    message: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </div> */}
                <div className='mb-5'>

                    <button type='submit' className='btn btn-primary'>

                        Submit
                        <i className='fa fa-angle-double-right' aria-hidden='true' />

                    </button>
                </div>

            </form>

        </div>
    );
};

export default AddManualAssessor;
