// permissionsContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { RequiredData } from '../modules/certificateForms/UserDataInterface';

// Define interfaces for permissions
interface Permission {
  user_id: string;
  is_active: boolean;
}

export interface AssessorPermission extends Permission {
  is_accessor: boolean;
  is_verify_accessor: boolean;
}

export interface CABPermission extends Permission {
  is_cab_client: boolean;
  is_cab_verify: boolean;
}

export interface SuperUserPermission extends Permission {
  is_superuser: boolean;
  is_staffuser: boolean;
}

// Define type for permissions context
interface PermissionsContextType {
  assessorPermission: AssessorPermission;
  cabPermission: CABPermission;
  superUserPermission: SuperUserPermission;
  updatePermissions: (userId: string) => Promise<void>;
}

// Initialize context
const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);
const API_URL = process.env.REACT_APP_API_URL;
// Define provider component
export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [assessorPermission, setAssessorPermission] = useState<AssessorPermission>({
      user_id: "",
      is_active: false,
      is_accessor: false,
      is_verify_accessor: false,
    });

  const [cabPermission, setCABPermission] = useState<CABPermission>({
    user_id: "",
    is_active: false,
    is_cab_client: false,
    is_cab_verify: false,
  });

  const [superUserPermission, setSuperUserPermission] = useState<SuperUserPermission>({
    user_id: "",
    is_active: false,
    is_superuser: false,
    is_staffuser: false,
  });

  // Function to fetch user data and update permissions
  const updatePermissions = async (userId: string) => {
    try {
      const response = await axios.get<RequiredData>(`${API_URL}/account/patch/user/${userId}/`);
      const userData = response.data;

      const assessorPerm: AssessorPermission = {
        user_id: userId,
        is_accessor: userData.is_accessor || false,
        is_verify_accessor: userData.is_verify_accessor || false,
        is_active: userData.is_active || false,
      };

      const cabPerm: CABPermission = {
        user_id: userId,
        is_cab_client: userData.is_cab_client || false,
        is_cab_verify: userData.is_cab_verify || false,
        is_active: userData.is_active || false,
      };

      const superUserPerm: SuperUserPermission = {
        user_id: userId,
        is_superuser: userData.is_superuser || false,
        is_staffuser: userData.is_staffuser || false,
        is_active: userData.is_active || false,
      };

      setAssessorPermission(assessorPerm);
      setCABPermission(cabPerm);
      setSuperUserPermission(superUserPerm);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // throw error;
    }
  };

  // Fetch permissions initially if user_id is available in localStorage
  useEffect(() => {
    const userId = localStorage.getItem("current_user_id");
    if (userId) {
      updatePermissions(userId);
    }
  }, []);

  return (
    <PermissionsContext.Provider value={{ assessorPermission, cabPermission, superUserPermission, updatePermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

// Custom hook to use permissions context
export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};
