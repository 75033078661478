import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;
type Props = {
  uniqueCab: string | undefined;
};
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count:string
}
const OHSMSProgram= ({ uniqueCab }: Props) => {
  const cabID = uniqueCab;
  const [ohsmsAnnexure, setOHSMSAnnxeure] = useState({

   
    iaf_1_1: "1",
    iaf_1_2: "Nil",
    iaf_1_3: "1",
    iaf_1_4: "",
    iaf_1_5: "",
    iaf_1_6: "",
    iaf_1_7: "",
    iaf_1_8: "",

    iaf_1_9: "03 and 30",
    iaf_1_10: " 30",
    iaf_1_11: " 3",
    iaf_1_12: "",
    iaf_1_13: "",
    iaf_1_14: "",
    iaf_1_15: "",
    iaf_1_16: "",

    iaf_1_17: " 17a, 18, 19, 20, 21, 22",
    iaf_1_18: " 17a, 18, 19, 22",
    iaf_1_19: " 20 and 21",
    iaf_1_20: "",
    iaf_1_21: "",
    iaf_1_22: "",
    iaf_1_23: "",
    iaf_1_24: "",

    iaf_1_25: "07a, 08, 09",
    iaf_1_26: "07a, 08",
    iaf_1_27: "9",
    iaf_1_28: "",
    iaf_1_29: "",
    iaf_1_30: "",
    iaf_1_31: "",
    iaf_1_32: "",


    iaf_1_33: "28, 34",
    iaf_1_34: "34",
    iaf_1_35: "28",
    iaf_1_36: "",
    iaf_1_37: "",
    iaf_1_38: "",
    iaf_1_39: "",
    iaf_1_40: "",

    iaf_1_41: "04, 05, 06, 23",
    iaf_1_42: "23",
    iaf_1_43: "[(04 with dying and 05 with tanning)] or 06",
    iaf_1_44: "",
    iaf_1_45: "",
    iaf_1_46: "",
    iaf_1_47: "",
    iaf_1_48: "",

    iaf_1_49: "07b, 10, 12, 13, 14, 15, 16, 17b",
    iaf_1_50: "14,15",
    iaf_1_51: "[ 07b and 10 and 12 and 13 and 16] or 17b",
    iaf_1_52: "",
    iaf_1_53: "",
    iaf_1_54: "",
    iaf_1_55: "",
    iaf_1_56: "",

    iaf_1_57: "2",
    iaf_1_58: "Nil",
    iaf_1_59: "2",
    iaf_1_60: "",
    iaf_1_61: "",
    iaf_1_62: "",
    iaf_1_63: "",
    iaf_1_64: "",

    iaf_1_65: "25, 26, 27",
    iaf_1_66: "27",
    iaf_1_67: "25 or 26",
    iaf_1_68: "",
    iaf_1_69: "",
    iaf_1_70: "",
    iaf_1_71: "",
    iaf_1_72: "",

    iaf_1_73: "31, 24, 39",
    iaf_1_74: "Nil",
    iaf_1_75: "[31 (limited to dangerous goods) and 24] or 39 (limited to NACE 37, 38.1, 38.2, 39)",
    iaf_1_76: "",
    iaf_1_77: "",
    iaf_1_78: "",
    iaf_1_79: "",
    iaf_1_80: "",

    iaf_1_81: "29, 32, 33, 35, 36, 37",
    iaf_1_82: "32, 33, 37",
    iaf_1_83: "29 or 35 or 36",
    iaf_1_84: "",
    iaf_1_85: "",
    iaf_1_86: "",
    iaf_1_87: "",
    iaf_1_88: "",

    iaf_1_89: "11",
    iaf_1_90: "Nil",
    iaf_1_91: "11",
    iaf_1_92: "",
    iaf_1_93: "",
    iaf_1_94: "",
    iaf_1_95: "",
    iaf_1_96: "",

    iaf_1_97: "38",
    iaf_1_98: "Nil",
    iaf_1_99: "38",
    iaf_1_100: "",
    iaf_1_101: "",
    iaf_1_102: "",
    iaf_1_103: "",
    iaf_1_104: "",



});
const [textareaValues, setTextareaValues] = useState<string[]>(Array(14).fill(''));
const [conductedareaValues, setConductedareaValues] = useState(Array(14).fill(''));
const [PlanareaValues, setPlanareaValues] = useState<string[]>(Array(14).fill(''));
const [dates, setDates] = useState(Array(14).fill(null));

const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])
console.log(transferCertificate,"EMS Certificate")

useEffect(() => {
  const fetchtransferCertificate = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=df28ddf3-b330-47d8-abec-a44333e4aabb`
      )
      settransferCertificate(response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6))
    } catch (error) {
      console.error('Error fetching standard data:', error)
    }
  }
  fetchtransferCertificate()
}, [])

useEffect(() => {
  const getOHSMSannexureData = async () => {
      try {
          const response = await axios.get(`${API_URL}/arc/anexxure/report/list/?cab=${cabID}&scheme=df28ddf3-b330-47d8-abec-a44333e4aabb`);
          if (response.status === 200 || response.status === 201) {
              console.log(response.data, "DATA");
              const jsonData = response?.data[0]?.json_value; // Assuming json_value is in the first object
              // setReportId(response.data[0]?.id)
              if (jsonData) {
                  setOHSMSAnnxeure(prevState => ({
                      ...prevState,
                      ...jsonData
                  }));
              }
          }
      } catch (error) {
          console.log(error);
      }
  };
  getOHSMSannexureData();
}, [])

  const handleSubmit = async (e: React.FormEvent) => {
  
    e.preventDefault();
  
    const detailsArray = dates.map((date, index) => ({
      iafCode: `iaf_1_${index * 8 + 1}`,
      witnessDate: date, 
      witnessNote: textareaValues[index],
      conductNote:conductedareaValues[index],
      planNote: PlanareaValues[index]
    }));
  
    const payload = {
      emssubmit: JSON.stringify(ohsmsAnnexure),
      details: JSON.stringify(detailsArray),
      cab: cabID, 
      scheme: 'df28ddf3-b330-47d8-abec-a44333e4aabb', 
    };
  
    try {
      const response = await axios.post(
        `${API_URL}/cab/global/data/post/`,
        payload
      );
      toast.success(" OHSMS Data Submitted")
      
    } catch (error) {
      // console.error('Error saving data:', error);
    }
  };

  const handleDateChange = (index: number, date: Date | null) => {
    const newDates = [...dates];
    newDates[index] = date;
    setDates(newDates);
  };

  const handleConductedareaChange = (index: number, value: string) => {
    const newConductedValues = [...conductedareaValues];
    newConductedValues[index] = value;
    setConductedareaValues(newConductedValues)
  };

  const handleTextareaChange = (index: number, value: string) => {
    const newTextareaValues = [...textareaValues];
    newTextareaValues[index] = value;
    setTextareaValues(newTextareaValues);
  };

  const handlePlanareaChange = (index: number, value: string) => {
    const newPlanareaValues = [...PlanareaValues];
    newPlanareaValues[index] = value;
    setPlanareaValues(newPlanareaValues);
  };

  return (
    <div className='mb-5 mb-xl-8'>
    <div className='card-header border-0 py-5 d-flex mb-5'>
      <h3 className='card-title align-items-start flex-column mt-2'>
        <span className='card-label fw-bold fs-1'>OHSMS Assessment</span>
      </h3>
    </div>
    {/* <h4 className='card-title align-items-start flex-column mt-2'>CAB: Example CAB Name</h4> */}

    <div className='card-body py-3'>
      <div className='table-responsive'>
        <table className='align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-dark bg-light' style={{ fontSize: '14px' }}>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                IAF codes Accredited to CABS
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Non-critical Codes
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Critical Codes
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Codes awarded (from Schedule)
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-200px rounded-start'>
                Witness to be planned in the year / Witness conducted date, Date of Witness and Assessment Number
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Planning the review of client files or verification of adequate competence for non-critical codes during the annual office assessment, including the assessment date and assessment number.
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Total number of certificates issued, including details of the six countries with the highest number of certificates issued since accreditation.
              </th>
              <th style={{ border: '1px solid #dddddd', padding: '8px' }} className='ps-4 min-w-150px rounded-start'>
                Total number of certificates transferred, including details of the six countries with the highest number of certificates transferred since accreditation.
              </th>
            </tr>
          </thead>
          <tbody>
            {[...Array(13)].map((_, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {ohsmsAnnexure[`iaf_1_${index * 8 + 1}`]}
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {ohsmsAnnexure[`iaf_1_${index * 8 + 2}`]}
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {ohsmsAnnexure[`iaf_1_${index * 8 + 3}`]}
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                    {ohsmsAnnexure[`iaf_1_${index * 8 + 4}`]}
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                    <p style={{fontSize:"14px", fontWeight:"200"}}>Planned:</p>
                          <textarea
                            value={textareaValues[index]}
                            onChange={(e) => handleTextareaChange(index, e.target.value)}
                            className='form-control mb-5'
                            rows={5}
                          ></textarea>
                           <p style={{fontSize:"14px", fontWeight:"200"}}>Conducted:</p>
                           <textarea
                            value={conductedareaValues[index]}
                            onChange={(e) => handleConductedareaChange(index, e.target.value)}
                            className='form-control'
                            rows={5}
                          ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea
                        value={PlanareaValues[index]}
                        onChange={(e) => handlePlanareaChange(index, e.target.value)}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate?.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.total_count}
                      </div>
                    ))}
                    </div>
                  </div>
                </td>
                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                    {transferCertificate.map((item, index) => (
                      <div key={index}>
                        {item?.country__name}: {item?.transferred}
                      </div>
                    ))}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='mb-5 mt-5'>
        <button type='submit' className='btn btn-primary' onClick={handleSubmit}>
          Save
          <i className='fa fa-angle-double-right' aria-hidden='true' />
        </button>
      </div>
    </div>
  </div>
  );
};

export default OHSMSProgram;
