import Select, {MultiValue, SingleValue} from 'react-select'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import UAFLoader from '../../loader/UafLoader'
import 'react-datepicker/dist/react-datepicker.css'
import SignaturePadComponent from '../profile/components/SignaturePadComponent'
import { toast } from 'react-toastify'

const ArcMonitoring = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [userData, setUserData] = useState<any>()
  const [roleID, setRoleId] = useState<any>()
  const [loader, setLoader] = useState<any>(false);
  const [monitorDate, setmonitorDate] = useState<Date | null>(null)
  const [signatureImageData, setSignatureImageData] = useState<any>()
  const [allUserData, setAllUserData] = useState<any>([])

  const [value, setValue] = useState<any>({
    evaluator_name: '',
    accreditation_rules: '',
    assessment_principle: '',
    management_principles: '',
    relevant_guidance: '',
    conformity_assessment: '',
    risk_assessment: '',
    general_requirement: '',
    outcomes: '',
    time_frame: '',
    issues:'',
    remark: '',
  })

  const [roleData, setRoleData] = useState<any>([])

  const fetchUserData = async (id: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/team/assessor/filter/?assign_role=${id}`)
      if (response.status === 200 || response.status === 202) {
        setAllUserData(response.data)
      }
    } catch (error) {
      console.error('API Error:', error)
    }
  }
  const fetchRole = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/role/list`)
      if (response.status === 200 || response.status === 202) {
        setRoleData(response.data)
      }
    } catch (error) {
      console.error('API Error:', error)
    }
  }
  useEffect(() => {
    fetchRole()
  }, [])
  const handleRoleChange = (selectedOption) => {
    const roleId = selectedOption.value
    console.log(roleId, 'ROLE ID')
    setRoleId(selectedOption.value)
    fetchUserData(roleId)
  }
  const handleUserChange = (selectedOption) => {
    const UserId = selectedOption.value
    console.log(UserId)
    getFreeUserDetails(UserId)
  }
  const designation = roleID ? roleData.find((item) => item.id === roleID)?.name : ''
  const getFreeUserDetails = async (userId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/team/user/assessor/${userId}/`)
      if (response.status === 200 || response.status === 202) {
        setUserData(response.data)
      }
    } catch (error) {
      // Handle errors
      console.error('API Error:', error)
      // throw error;
    }
  }

  const handleSignatureSave = (dataUrl: string) => {
    setSignatureImageData(dataUrl)
  }

  const handleDateChange = (date: Date | null) => {
    setmonitorDate(date)
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    const postData = {
      ...value,
      assessor: userData?.id,
      monitor_date: monitorDate ? monitorDate.toISOString() : null,
      signature: signatureImageData,
    }

    try {
      setLoader(true)
      const response = await axios.post(`${BASE_URL}/performance/arc/monitor/create`, postData)
      if (response.status === 200 || response.status === 201) {
        // Handle successful response
        setLoader(false)
        toast.success('Form submitted successfully:', response.data)
        setUserData(null);
        setRoleId(null);
        setmonitorDate(null);
        setSignatureImageData(null);
        setValue({
          evaluator_name: '',
          accreditation_rules: '',
          assessment_principle: '',
          management_principles: '',
          relevant_guidance: '',
          conformity_assessment: '',
          risk_assessment: '',
          general_requirement: '',
          outcomes: '',
          time_frame: '',
          issues:'',
          remark: '',
        });
      }
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <div id="arc_memeber_monoitoring_report" >{loader ? <UAFLoader /> : null}
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-3 p-0 '> PERFORMANCE MONITORING REPORT FOR ARC MEMBER</h2>
        </div>
      </div>
      <div className='card card-body py-5'>
        <div className='row jusify-content-space-between'>
          <div className='col-lg-6 d-flex align-items-center justify-content-start'>
            <Select
              className='react-select-styled react-select-solid w-100'
              classNamePrefix='react-select'
              placeholder='Select Role'
              options={roleData?.map((item) => ({label: item?.name, value: item?.id}))}
              onChange={handleRoleChange}
            />
          </div>
          <div className='col-lg-6 d-flex align-items-center justify-content-start'>
            <Select
              className='react-select-styled react-select-solid w-100'
              classNamePrefix='react-select'
              placeholder='Select User'
              options={allUserData?.map((item) => ({
                label: `${item?.first_name} ${item?.last_name}`,
                value: item?.id,
              }))}
              onChange={handleUserChange}
            />
          </div>
        </div>
      </div>
      <div className='card card-flush'>
      <form onSubmit={handleSubmit} id="form_monoitoring_report">
        <div className='card-header pt-5'>
          <div className='col-lg-12'>
            <div className='my-1 row'>
              <h2 className='mb-5 col-lg-6'>Name:</h2>
              <h2 className='mb-5 col-lg-6'>
                {userData?.first_name} {userData?.last_name}
              </h2>
              <h3 className='mb-5 col-lg-6'>Designation:</h3>
              <h3 className='mb-5 col-lg-6'>ARC</h3>

              <h3 className='mb-5 col-6'>Name of Evaluator:</h3>

              <div className=' mb-5 col-6'>
                <input
                id="evaluator_name"
                  value={value?.evaluator_name}
                  name='evaluator_name'
                  className='form-control form-control-solid'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>

              <h3 className='mb-5 col-lg-6'>Date of monitoring:</h3>
              <div className='mb-5 col-lg-6'>
                <ReactDatePicker
                  selected={monitorDate}
                  id="evaluator_name_1"
                  placeholderText='Select Date'
                  className='react_date_picker form-control form-control-solid'
                  onChange={handleDateChange}
                  dropdownMode='select'
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='col-1'>S No</th>
                <th className='col-2'>PARAMETERS</th>
                <th className=''>SATISFACTORY / NEEDS IMPROVEMENT </th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              <tr>
                <td>1</td>
                <td>Knowledge of UAF accreditation’s rules and processes</td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="accreditation_rules"
                    name='accreditation_rules'
                    value={value?.accreditation_rules}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Knowledge of assessment principles, practices and techniques</td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="assessment_principle"
                    name='assessment_principle'
                    value={value?.assessment_principle}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Knowledge of general management system principles and tools</td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="management_principles"
                    name='management_principles'
                    value={value?.management_principles}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  Knowledge of accreditation relevant guidance documents (UAF-GEN-CAB-01 &
                  UAF-GEN-CAB-02) and application documents
                </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="relevant_guidance"
                    name='relevant_guidance'
                    value={value?.relevant_guidance}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  Knowledge of conformity assessment scheme requirements other procedures and
                  methods used by the conformity assessment body
                </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="conformity_assessment"
                    name='conformity_assessment'
                    value={value?.conformity_assessment}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Knowledge of risk based assessment principles</td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="risk_assessment"
                    name='risk_assessment'
                    value={value?.risk_assessment}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  Knowledge of general regulatory requirements related to the conformity assessment
                  activities.
                </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="general_requirement"
                    name='general_requirement'
                    value={value?.general_requirement}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Evaluating the outcomes of the assessment and report writing </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="outcomes"
                    name='outcomes'
                    value={value?.outcomes}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>Time frame adherence as per UAF policies </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="time_frame"
                    name='time_frame'
                    value={value?.time_frame}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Bring out issues /comments </td>
                <td>
                  <textarea
                    className='form-control form-control-solid'
                    rows={7}
                    id="issues"
                    name='issues'
                    value={value?.issues}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className='mb-5 '>
            <label className='col-lg-4 fw-bold text-muted'>Remarks</label>

            <textarea
              className='form-control form-control-solid'
              rows={7}
              id="remark"
              name='remark'
              value={value?.remark}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-5 '>
            <label className='col-lg-4 fw-bold text-muted'>Signature</label>

            <SignaturePadComponent onSave={handleSignatureSave} />
          </div>
        </div>
        <div className='card-footer text-end'>
          <button id="submit_arc_form_report" type='submit' className='btn btn-primary'>
            Submit
          </button>
        </div>
        </form>
      </div>
    </div>
  )
}

export default ArcMonitoring
