import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { toast } from 'react-toastify';

interface SignaturePadProps {
  onSave: (dataUrl: string) => void;
}

const AccreditationScheduleSignature: React.FC<SignaturePadProps> = ({ onSave }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  const [signatureImageData, setSignatureImageData] = useState<string | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      signaturePadRef.current = new SignaturePad(canvas);
    }
  }, []);

  const clear = () => {
    signaturePadRef.current?.clear();
    setSignatureImageData(null);
  };

  const save = () => {
    if (signaturePadRef.current) {
      const dataUrl = signaturePadRef.current.toDataURL('image/png');
      setSignatureImageData(dataUrl);
      onSave(dataUrl);
      toast.success("Signature Saved");
    }
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={200}
        style={{ border: '1px solid #000', borderRadius: '5px' }}
      />
      <button type="button" onClick={clear}>Clear</button>
      <button type="button" onClick={save}>Save</button>
      {/* {signatureImageData && (
        <div>
          <img src={signatureImageData} alt="Signature" style={{ marginTop: '10px' }} />
        </div>
      )} */}
    </div>
  );
};

export default AccreditationScheduleSignature;
