import axios from 'axios';
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { toast } from 'react-toastify';

type Props={
  userId: string | undefined;
  ARC_member: string | undefined;
  ARC_no: string | undefined;
  witnessNumber:string |undefined;
  }
interface WitnessReview {
  id: string;
  review_report_qa: string;
  review_report_qb: string;
  cab: string;
  review: string[];
}
type Assessor = {
  id: string;
  first_name: string;
  last_name: string;
};

type AssessorData = {
  update_at?: string;
  scheme_name?: string;
  assessment_number?: string;
  assessor_data?: Assessor[];
  assessor_planner_updated_at?: string;

};

type WitnessReviewData = {
  assessor_data?: AssessorData[];
  review_report_qa?: string;
  review_report_qb?: string;
};
const GenerateWr = ({ userId ,ARC_member,ARC_no ,witnessNumber}: Props) => {
  const [witnessReviewUpdatedData, setWitnessReviewUpdatedData] = useState<any>()
  const API_URL = process.env.REACT_APP_API_URL;
  const [witnessReview, setWitnessReview] = useState<WitnessReview | null>(null);
  const [witnessReviewData, setWitnessReviewData] = useState<WitnessReviewData | null>(null);
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>([])

  console.log("witnessReview:", witnessReview);
  console.log("witnessReviewData:", witnessReviewData);


  const formatDate = (dateString: string) => {
    return moment(dateString).format('MM/DD/YYYY');
  };
  useEffect(() => {
    if (userId) {
      const getAssessorDataByAssessment = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/cab/filter/scheme/assessor/planner/?cab=${userId}&assessor=&scheme=&assessor_planner=&assessment_type=&assessment_type_name=Witness+Assessment&assessment_number=${witnessNumber}&create_at=&update_at=`
          )
          setAssessorPlannerDataFromAssessment(response.data)
        } catch (error) {
          //console.log(error)
        }
      }
      getAssessorDataByAssessment();
    }
  }, [userId]);
  useEffect(() => {
    const fetchWitnessDetails = async () => {
      if (userId) {
        try {
          const reviewResponse = await axios.get(`${API_URL}/arc/witness/assessment/report/get/?cab=${userId}&assessor=${ARC_member}`);
          setWitnessReviewUpdatedData(reviewResponse?.data);

        } catch (error) {
        }
      }
    };

    fetchWitnessDetails();
  }, [userId]);
  useEffect(() => {
    if (userId) {
      const getwitnessReview = async () => {
        try {
          const response = await axios.get(`${API_URL}/arc/document/review/filter/?cab=${userId}`);
          setWitnessReview(response.data);
        } catch (error) {
          console.error('API Error:', error);
          toast.error("problem to fetch witness review")
        }
      };
      getwitnessReview();
    }
  }, [userId]);
  // useEffect(() => {
  //   if (witnessReview) {
  //     const getwitnessReviewData = async () => {
  //       try {
  //         const response = await axios.get(`${API_URL}/arc/document/review/${witnessReview[0].id}/get/`);
  //         setWitnessReviewData(response.data);
  //       } catch (error) {
  //         console.error('API Error:', error);
  //         toast.error("problem to fetch witness review")
  //       }
  //     };
  //     getwitnessReviewData();
  //   }
  // }, [witnessReview]);

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            Witness Review Report<span style={{ color: 'red' }}>*</span>
          </h3>
        </div>
      </div>
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='w-10px pe-2 fs-7'>Date(s)</th>
                <th className='w-10px pe-2 fs-7'>Schemes</th>
                <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                <th className='w-10px pe-2 fs-7'>Assessment Duration</th>
                <th className='w-10px pe-2 fs-7'>Assessor Name</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {assessorPlannerDataFromAssessment?.map((item) => (
                <tr>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      {item && item.update_at ? formatDate(item.update_at) : '--'}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      {item?.scheme_name}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      {item?.assessment_number}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      {item?.assessment_duration}
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      {item?.assessor_name?.map((assessor, index) => (
                        <span key={assessor[0].id}>
                          {assessor[0].first_name} {assessor[0].last_name}
                          {index < item.assessor_name.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        <div className='row'>
          <div className='mb-5 col-lg-12'>
            <h3>Competence verification of Assessment Team for witness assessement review, if applicable </h3>
            <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
              <thead>
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='w-10px pe-2 fs-7'>Assessor Name / Info</th>
                  <th className='w-10px pe-2 fs-7'>Schemes</th>
                  {/* <th className='w-5px pe-2 fs-7 text-center'>Assessor Info</th> */}
                  <th className='w-10px pe-2 fs-7'>Competence Verification </th>
                  <th className='w-10px pe-2 fs-7'>Remarks</th>
                </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
                {witnessReviewUpdatedData?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        <ul style={{ listStyle: "none" }}>
                          {item?.assessor_data[0].assessor_data?.map((assessor) => (
                            <li className="mb-1" key={assessor.id}>
                              {assessor.first_name} {assessor.last_name}

                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>

                    <td>
                      <div className='text-gray-800 mb-1'>
                        {item.assessor_data[0]?.scheme_name}
                      </div>
                    </td>

                    <td>
                      <div className='text-gray-800 mb-1'>
                        Verified
                      </div>
                    </td>
                    <td>

                      <div className="text-gray-800 mb-1">
                        {item?.remark || ''}
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>


            </table>
          </div>
        </div>
        <div className='row mt-10'>
          <div className='mb-5 col-lg-12'>
            <h3>Review of Witness Assessment Reports: </h3>
            <table className='table align-middle table-row-dashed fs-6 gy-5 px-5' id='kt_table_users'>
              <thead>
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                  <th className='w-10px pe-2 fs-7'>Assessment Number</th>
                  <th className='w-100px pe-2 fs-7'>Schemes</th>
                  <th className='w-250px pe-2 fs-7'>Scopes / Technical Areas </th>
                  <th className='w-150px pe-2 fs-7'>sufficient evidence that the issued NCs, if any, have been closed out? Latest Date of closure  </th>
                  <th className='w-150px pe-2 fs-7'>Does the Witness Assessment report contain clear recommendations?</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {witnessReviewUpdatedData?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        <span>{item.assessor_data[0]?.assessment_number}</span>
                      </div>
                    </td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                      {item.assessor_data[0]?.scheme_name}
                      </div>
                    </td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        <ul>
                          {item?.assessor_data[0]?.cluster_data?.map((value) =>
                            <li>{value?.cluster_name}</li>
                          )}
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        {item?.review_report_qa || ''}
                      </div>
                    </td>
                    <td>
                      <div className='text-gray-800 mb-1'>
                        {item?.review_report_qb || ''}
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateWr