import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../../../../_metronic/helpers'
import axios from 'axios'
import {Link} from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'
import Select from 'react-select'
const API_URL = process.env.REACT_APP_API_URL
interface SchemeName {
  name: string
  id: string
  short_name: string
}

interface CertficateData {
  total_count: string
  country: string
  country__name: string
}
type Props = {
  uniqueCab: string | undefined
}
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
}
const GlobalEdit = ({uniqueCab}: Props) => {
  const [certificateData, setCertificateData] = useState<CertficateData[]>([])
  const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])

  const [cabData, setCabData] = useState<any>()
  const [cabARCData, setCAbARCData] = useState<any>()
  const [globalData, setGlobalData] = useState<any>()
  const [allglobalData, setAllglobalData] = useState<any[]>([])
  const [formData, setFormData] = useState(() => {
    const initialFormData = {
      applicable_standards: '',
      witnessing_details: '',
      another_status: '',
      feedback: '',
      cab_related_bodies: '',
      last_assessment_country: '',
      sampling_transfer: '',
      iaf15_review: '',
      followup_issue: '',
      uaf_noncompliance: '',
      assessment_frequency: '',
      reason_for_updating: '',
    }

    if (globalData) {
      const accreditationDates = globalData.accreditation_dates.reduce((acc, item) => {
        acc[`accreditation_date_${item.id}`] = new Date(item.date)
        return acc
      }, {})

      const reaccreditationDates = globalData.reaccreditation_dates?.reduce((acc, item) => {
        acc[`reaccreditation_date_${item.id}`] = new Date(item.date)
        return acc
      }, {})

      const expiryDates = globalData.expiry_dates?.reduce((acc, item) => {
        acc[`expiry_date_${item.id}`] = new Date(item.date)
        return acc
      }, {})

      const reaccreditationDueDates = globalData.reaccreditation_due_dates?.reduce((acc, item) => {
        acc[`reaccreditation_due_date_${item.id}`] = new Date(item.date)
        return acc
      }, {})

      return {
        ...initialFormData,
        ...accreditationDates,
        ...reaccreditationDates,
        ...expiryDates,
        ...reaccreditationDueDates,
      }
    }

    return initialFormData
  })

  const cabID = uniqueCab

  // Get the date one year earlier
  const today = new Date()
  const lastYear = new Date()
  lastYear.setFullYear(today.getFullYear() - 1)

  // Format the dates as required (ISO 8601 format)
  const formatDate = (date) => date.toISOString()

  const startDate = formatDate(lastYear)
  const endDate = formatDate(today)

  useEffect(() => {
    getGlobalDetails()
  }, [])

  const getGlobalDetails = () => {
    axios
      .get(`${API_URL}/cab/global/scheme/data/filter/?cab=${cabID}`)
      .then((response) => {
        const result = response.data.reverse()
        setAllglobalData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  const handleYearSelect = (e) => {
    console.log(e, 'this is coming e')
    setGlobalData(allglobalData?.[e.value])
  }

  useEffect(() => {
    if (globalData) {
      const accreditationDates = globalData.accreditation_dates.reduce((acc, item) => {
        acc[`accreditation_date_${item.id}`] = item.date ? new Date(item.date) : null;
        return acc;
      }, {});
    
      const reaccreditationDates = globalData.reaccreditation_dates?.reduce((acc, item) => {
        acc[`reaccreditation_date_${item.id}`] = item.date ? new Date(item.date) : null;
        return acc;
      }, {});
    
      const expiryDates = globalData.expiry_dates?.reduce((acc, item) => {
        acc[`expiry_date_${item.id}`] = item.date ? new Date(item.date) : null;
        return acc;
      }, {});
    
      const reaccreditationDueDates = globalData.reaccreditation_due_dates?.reduce((acc, item) => {
        acc[`reaccreditation_due_date_${item.id}`] = item.date ? new Date(item.date) : null;
        return acc;
      }, {});

      setFormData((prevData) => ({
        ...prevData,
        ...accreditationDates,
        ...reaccreditationDates,
        ...expiryDates,
        ...reaccreditationDueDates,
        applicable_standards: globalData.applicable_standards || '',
        witnessing_details: globalData.witnessing_details || '',
        another_status: globalData.another_status || '',
        feedback: globalData.feedback || '',
        cab_related_bodies: globalData.cab_related_bodies || '',
        last_assessment_country: globalData.last_assessment_country || '',
        sampling_transfer: globalData.sampling_transfer || '',
        iaf15_review: globalData.iaf15_review || '',
        followup_issue: globalData.followup_issue || '',
        uaf_noncompliance: globalData.uaf_noncompliance || '',
        assessment_frequency: globalData.assessment_frequency || '',
        reason_for_updating: globalData.reason_for_updating || '',
      }))
    }
  }, [globalData])
  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await axios.get(`${API_URL}/certificate/country/list/${cabID}/`)
        setCertificateData(response?.data?.certificates)
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchCertificate()
  }, [])

  useEffect(() => {
    const fetchtransferCertificate = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&start_date=${startDate}&end_date=${endDate}`
        )
        settransferCertificate(response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6))
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchtransferCertificate()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/arc/management/schedule/data/get/?cab=${cabID}`
        )
        setCabData(response?.data?.cab_data)
        setCAbARCData(response?.data?.arc_data[0])
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: date || globalData[name],
    }))
  }

  const handleSave = async () => {
    const formatDate = (date: Date | null) => {
      if (!date) return null;
      
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    };
    

    const accreditationDates = cabData?.scheme_names?.map((item) => ({
      id: item.id,
      date: formatDate(formData[`accreditation_date_${item.id}`]),
      scheme_name: item?.name,
    }))

    const reaccreditationDates = cabData?.scheme_names?.map((item) => ({
      id: item.id,
      date: formatDate(formData[`reaccreditation_date_${item.id}`]),
      scheme_name: item?.name,
    }))

    const expiryDates = cabData?.scheme_names?.map((item) => ({
      id: item.id,
      date: formatDate(formData[`expiry_date_${item.id}`]),
      scheme_name: item?.name,
    }))

    const reaccreditationDueDates = cabData?.scheme_names?.map((item) => ({
      id: item.id,
      date: formatDate(formData[`reaccreditation_due_date_${item.id}`]),
      scheme_name: item?.name,
    }))

    const dataToSave = {
      cab: cabID,

      accreditation_dates: JSON.stringify(accreditationDates),
      reaccreditation_dates: JSON.stringify(reaccreditationDates),
      expiry_dates: JSON.stringify(expiryDates),
      reaccreditation_due_dates: JSON.stringify(reaccreditationDueDates),
      reason_for_updating: formData.reason_for_updating,
      assessment_frequency: formData.assessment_frequency,
      uaf_noncompliance: formData.uaf_noncompliance,
      followup_issue: formData.followup_issue,
      iaf15_review: formData.iaf15_review,
      sampling_transfer: formData.sampling_transfer,
      last_assessment_country: formData.last_assessment_country,
      cab_related_bodies: formData.cab_related_bodies,
      feedback: formData.feedback,
      another_status: formData.another_status,
      witnessing_details: formData.witnessing_details,
      applicable_standards: formData.applicable_standards,
    }

    try {
      await axios.put(`${API_URL}/cab/global/scheme/data/${globalData?.id}/update/`, dataToSave)
      toast.success('Data Updated successfully')
      // resetForm();
    } catch (error) {
      // console.error('Error saving data:', error)
      toast.error('Something Went Wrong')
    }
  }
  // const resetForm = () => {
  //   setFormData(getInitialFormData(globalData));
  // };

  // const getInitialFormData = (data) => {
  //   const initialFormData = {
  //     applicable_standards: '',
  //     witnessing_details: '',
  //     another_status: '',
  //     feedback: '',
  //     cab_related_bodies: '',
  //     last_assessment_country: '',
  //     sampling_transfer: '',
  //     iaf15_review: '',
  //     followup_issue: '',
  //     uaf_noncompliance: '',
  //     assessment_frequency: '',
  //     reason_for_updating: '',
  //   };

  //   if (data) {
  //     const accreditationDates = data.accreditation_dates.reduce((acc, item) => {
  //       acc[`accreditation_date_${item.id}`] = new Date(item.date);
  //       return acc;
  //     }, {});

  //     const reaccreditationDates = data.reaccreditation_dates?.reduce((acc, item) => {
  //       acc[`reaccreditation_date_${item.id}`] = new Date(item.date);
  //       return acc;
  //     }, {});

  //     const expiryDates = data.expiry_dates?.reduce((acc, item) => {
  //       acc[`expiry_date_${item.id}`] = new Date(item.date);
  //       return acc;
  //     }, {});

  //     const reaccreditationDueDates = data.reaccreditation_due_dates?.reduce((acc, item) => {
  //       acc[`reaccreditation_due_date_${item.id}`] = new Date(item.date);
  //       return acc;
  //     }, {});

  //     return {
  //       ...initialFormData,
  //       ...accreditationDates,
  //       ...reaccreditationDates,
  //       ...expiryDates,
  //       ...reaccreditationDueDates,
  //     };
  //   }

  //   return initialFormData;
  // };
  console.log(globalData,"this is my")

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        {/* <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>Global Assessment</span>
        </h3> */}
      </div>
      <div className='mb-5 col-6'>
        {/* <label className='form-label'>Select Year</label> */}
        <Select
          className='react-select-styled react-select-solid'
          classNamePrefix='react-select'
          placeholder='Select Year'
          options={allglobalData?.map((item, index) => ({
            label: `Cycle${index + 1}`,
            value: index,
          }))}
          onChange={handleYearSelect}
        />
      </div>
      <div className='card-body py-3'>
        {globalData ? (
          <div className='table-responsive'>
            <table className='align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Name of Conformity Assessment Body
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '5px'}}>
                    {cabData?.company}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '5px'}}
                    className='ps-4 rounded-start'
                  >
                    Date of Accreditation and Re-Accreditation for each management system
                    (MM-DD-YYYY)
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '5px'}}>
                    <table >
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>Scheme</th>
                        {cabData?.scheme_names?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item?.id}>
                            {item?.short_name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>
                          Accreditation date
                        </th>
                        {globalData?.accreditation_dates?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item.id}>
                            <ReactDatePicker
                              selected={formData[`accreditation_date_${item.id}`]|| null}
                              placeholderText='Select Date'
                              className='react_date_picker form-control form-control-solid'
                              onChange={(date) =>
                                handleDateChange(date, `accreditation_date_${item.id}`)
                              }
                              dropdownMode='select'
                              showMonthDropdown
                              showYearDropdown
                            />
                          </td>
                        ))}
                       
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>
                          Re-Accreditation date
                        </th>
                        {globalData?.reaccreditation_dates?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item.id}>
                            <ReactDatePicker
                              selected={formData[`reaccreditation_date_${item.id}`] || null}
                              placeholderText='Select Date'
                              className='react_date_picker form-control form-control-solid'
                              onChange={(date) =>
                                handleDateChange(date, `reaccreditation_date_${item.id}`)
                              }
                              dropdownMode='select'
                              showMonthDropdown
                              showYearDropdown
                            />
                          </td>
                        ))}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '5px'}}
                    className='ps-4 rounded-start'
                  >
                    Accreditation Expiry and Reaccreditation Due Date
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '5px'}}>
                    <table style={{borderCollapse: 'collapse', width: '100%'}}>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>Scheme</th>
                        {cabData?.scheme_names?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item?.id}>
                            {item?.short_name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>
                          Current Expiry
                        </th>
                        {globalData?.expiry_dates?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item.id}>
                            <ReactDatePicker
                              selected={formData[`expiry_date_${item.id}`] || null}
                              placeholderText='Select Date'
                              className='react_date_picker form-control form-control-solid'
                              onChange={(date) => handleDateChange(date, `expiry_date_${item.id}`)}
                              dropdownMode='select'
                              showMonthDropdown
                              showYearDropdown
                            />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th style={{border: '1px solid #dddddd', padding: '5px'}}>
                          Reaccreditation assessment Due Month
                        </th>
                        {globalData?.reaccreditation_due_dates?.map((item) => (
                          <td style={{border: '1px solid #dddddd', padding: '5px'}} key={item.id}>
                            <ReactDatePicker
                              selected={formData[`reaccreditation_due_date_${item.id}`] || null}
                              placeholderText='Select Date'
                              className='react_date_picker form-control form-control-solid'
                              onChange={(date) =>
                                handleDateChange(date, `reaccreditation_due_date_${item.id}`)
                              }
                              dropdownMode='select'
                              showMonthDropdown
                              showYearDropdown
                            />
                          </td>
                        ))}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Head office/Main Critical Location
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabData?.address}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Other Key Locations
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabARCData?.recommended_location}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Other Non-Key Locations
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabARCData?.other_location}
                  </td>
                </tr>

                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Month of Key Locations
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    {cabData?.schedule_data?.decision_date}
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Reason for updating
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='reason_for_updating'
                      value={formData?.reason_for_updating || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Assessment Frequency
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='assessment_frequency'
                      value={formData?.assessment_frequency || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                </tr>

                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Factors considered for developing Assessment Program
                  </th>
                  <tr className='fw-bold text-dark bg-light w-100' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      UAF policies’ noncompliance
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='uaf_noncompliance'
                        value={formData?.uaf_noncompliance || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Follow-ups from last assessments or any open issues
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='followup_issue'
                        value={formData?.followup_issue || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Review of IAF MD 15 compliance
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='iaf15_review'
                        value={formData?.iaf15_review || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    (Activities, locations as per IAF MD 12 & 23 and personnel covered by the scope
                    of accreditation)
                  </th>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of CAB other critical locations(s) or locations(s) apart from head
                      office(Main Location)
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='sampling_transfer'
                        value={formData?.sampling_transfer || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      List of the top six countries in which certificates are issued for the
                      accreditation cycle (samples to be taken from these countries)
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      {certificateData.map((item, index) => (
                        <div key={index}>
                          {item?.country__name}: {item?.total_count}
                        </div>
                      ))}
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of countries accredited for sampling where a there is significant
                      number of active certificates issued since accreditation
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      {transferCertificate.map((item, index) => (
                        <div key={index}>
                          {item?.country__name}: {item?.transferred}
                        </div>
                      ))}
                    </td>
                  </tr>

                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of countries added in most recent assessment
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='last_assessment_country'
                        value={formData?.last_assessment_country || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of CAB’s Related Body, If any
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='cab_related_bodies'
                        value={formData?.cab_related_bodies || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of any Adverse feedback or complaint
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='feedback'
                        value={formData.feedback || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      CAB accreditation status with another AB (if yes, mention the accredited
                      schemes and AB)
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='another_status'
                        value={formData?.another_status || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 rounded-start'
                    >
                      Details of Adjustment of the witnessing frequency as per the applicable IAF MD
                      requirements
                    </th>
                    <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                      <textarea
                        name='witnessing_details'
                        value={formData?.witnessing_details || ''}
                        onChange={handleChange}
                        className='form-control'
                        rows={3}
                      ></textarea>
                    </td>
                  </tr>
                </tr>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{width: '20%', border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 rounded-start'
                  >
                    Applicable Accreditation Standards
                  </th>
                  <td style={{width: '80%', border: '1px solid #dddddd', padding: '8px'}}>
                    <textarea
                      name='applicable_standards'
                      value={formData?.applicable_standards || ''}
                      onChange={handleChange}
                      className='form-control'
                      rows={10}
                    ></textarea>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        ) : null}
        {globalData ? (
          <div className='mb-5 mt-5'>
            <button className='btn btn-primary mt-3' onClick={handleSave}>
              Update
              <i className='fa fa-angle-double-right' aria-hidden='true' />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default GlobalEdit
