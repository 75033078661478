import React from "react";
import { PageLink } from "../../../../../_metronic/layout/core";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";

type Props = {
    show: boolean
    handleClose: () => void
}
//   const modalsRoot = document.getElementById('root-modals') || document.body

const BusinessUnitFilter = ({ show, handleClose }: Props) => {

    return (<>

        <Modal
            id='kt_modal_business_unit_filter'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            //   onEntered={loadStepper}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>Filter</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
               <p>Filter Content</p>

            </div>

        </Modal>
    </>
    );
};

export default BusinessUnitFilter;