import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface NCList {
  id: string;
  first_name: string;
  last_name: string;
  nc_management_count: number;
  company:string;
}

const NCGraph: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [openNc, setOpenNC] = useState<NCList[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<NCList[]>(`${BASE_URL}/nc/management/status/count/`);
        if (response.status === 200) {
          setOpenNC(response.data.filter((item)=>item?.nc_management_count>0));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [BASE_URL]);

  const names = openNc.map(item => item.company || 'Unknown');
  const counts = openNc.map(item => item.nc_management_count);

  const options: ApexCharts.ApexOptions = {
    series: [
      {
        name: 'NC Management Count',
        data: counts,
      }
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        },
        colors: {
          ranges: [{
            from: 0,
            to: Number.MAX_VALUE,
            color: '#3E97FF'
          }]
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toString();
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ['#304758'] // Color for data labels
      }
    },
    xaxis: {
      categories: names,
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      },
      labels: {
        style: {
          colors: ['#333'],
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val.toString();
        },
        style: {
          colors: ['#333'],
          fontSize: '12px'
        }
      }
    },
    grid: {
      borderColor: '#e7e7e7',
      strokeDashArray: 4,
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: true
      },
      y: {
        formatter: function(val) {
          return val.toString();
        }
      }
    },
    fill: {
      colors: ['#3E97FF'] // Single color for bars
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={options.series} type="bar" height={350} />
      <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '20px', color: '#444' }}>
        Open NC CAB Wise
      </div>
    </div>
  );
};

export default NCGraph;
