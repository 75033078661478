/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'

import BusinessUnitFilter from '../models/businessUniterFilter'
import Select from 'react-select'
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import CreateManualAssesor from '../../assessorLead/models/CreateManualAssesor'
import UAFLoader from '../../../../loader/UafLoader'
import { KTIcon } from '../../../../../_metronic/helpers'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx';
import { usePermissions } from '../../../../permission_component/PermissionContext'
import debounce from 'lodash/debounce'




// const BusinessUnitList = ({ className }: Props) => (
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: { id: string; name: string }[]
  programme_names: { id: string; name: string }[]
  accessor_approve_date: string
  create_at: string
}
const VerifiedAssessorList: React.FC = () => {
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  const BASE_URL = process.env.REACT_APP_API_URL
  const [value, setValue] = useState<any[]>([])
  const [notificationDetail, setNotificationDetail] = useState<any>({
    subject: "",
    text: "",
    documentFile: null
  })
  const [totalcount, settotalcount] = useState(0);
  console.log(notificationDetail, 'notificationDetail')
  const [selectedItems, setSelectedItems] = useState<any>([])
  const [selectAll, setSelectAll] = useState<boolean>(false);
  console.log(value, 'value')
  console.log(selectedItems, 'selectedItems')
  const [loader, setLoader] = useState<any>(false);

  const [searchQuery, setSearchQuery] = useState('')
  console.log(searchQuery, "searchQuery")

  const [filteredValue, setFilteredValue] = useState<PendingAssessor[]>([])
  console.log(filteredValue, 'filteredValue')
  const [showSendNotificationModal, setShowSendNotificationModal] = useState<boolean>(false);
  const [editorData, setEditorData] = useState('');
  const itemsToDisplay = searchQuery.length > 0 ? filteredValue : value;
  const [pagenumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(1);


  useEffect(() => {
    // Update the selectAll state based on the selectedItems
    if (selectedItems.length === itemsToDisplay.length && itemsToDisplay.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, itemsToDisplay]);



  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY')
  }
  // const fetchData = async () => {
  //   try {
  //     setLoader(true)
  //     const response = await axios.get(
  //       `${BASE_URL}/team/accessor/verified/list/pagination/?page=${pagenumber}`
  //     )
  //     const pages = Math.ceil(response?.data?.count / 10);
  //     settotalcount(response?.data?.count)
  //     // Each page has 10 items
  //     setTotalPages(pages);
  //     if (response.status === 200) {

  //       setValue(response.data?.results)
  //       setLoader(false)
  //     }

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => {


  //   fetchData()
  // }, [pagenumber])
  // // Function to handle search query change
  // const handleSearchChange = async (e) => {
  //   e.preventDefault();
  //   const searchValue = e?.target?.value;
  //   console.log(searchValue, "searchValue");


  //   if (searchValue) {
  //     try {
  //       setLoader(true);
  //       const response = await axios.get(
  //         `${BASE_URL}/team/accessor/verified/list/pagination/?search=${searchValue}`
  //       );

  //       settotalcount(response?.data?.count);
  //       if (response.status === 200) {
  //         setFilteredValue(response.data?.results);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoader(false);
  //     }
  //   }
  //   else if (!searchValue) {
  //     setFilteredValue([])
  //     fetchData();
  //   }

  // };
  const fetchData = async () => {
    try {
      // setLoader(true);
      const response = await axios.get(
        `${BASE_URL}/team/accessor/verified/list/pagination/?page=${pagenumber}`
      );
      const pages = Math.ceil(response?.data?.count / 10);
      settotalcount(response?.data?.count);
      setTotalPages(pages);
      if (response.status === 200) {
        setValue(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagenumber]);

  const handleSearchChange = useCallback(
    debounce(async (e) => {
      const searchValue = e?.target?.value;
      console.log(searchValue, 'searchValue');
      if (searchValue) {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${BASE_URL}/team/accessor/verified/list/pagination/?search=${searchValue}`
          );
          // settotalcount(response?.data?.count);
          if (response.status === 200) {
            setFilteredValue(response.data?.results);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // setLoader(false);
        }
      } else {
        setFilteredValue([]);
        fetchData();
      }
    }, 500),
    []
  );




  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBusinessUnitFilterModal, setShowBusinessUnitFilterModal] = useState<boolean>(false)

  const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setPageNumber(page);
    }
  };




  const handleChangeId = (item) => {
    const itemId = item?.id;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allIds = (filteredValue?.length > 0 ? filteredValue : value)?.map(item => item?.id);
      setSelectedItems(allIds);
    }
    setSelectAll(!selectAll);
  };


  const handleCertification = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationDetail({ ...notificationDetail, documentFile: e.target.files ? e.target.files[0] : null });
  }

  const handleSubmit = async () => {


    try {

      const formData = new FormData();
      formData.append("assessor", JSON.stringify(selectedItems));
      formData.append("subject", notificationDetail.subject);
      formData.append("message", notificationDetail.text);
      formData.append("document", notificationDetail.documentFile);



      const reponse = await axios.post(`${BASE_URL}/team/assessor/send/dummy/email/`, formData);
      toast.success("Notification sent.");
      window.location.reload();

    } catch (error) {

    }
  };
  const downloadExcel = async() => {
    const workbook = XLSX.utils.book_new();
    const response = await axios.get(
      `${BASE_URL}/team/accessor/verified/list/`
    );
    // Prepare data for Excel
    const sheetData = response?.data?.map((item, index) => ({
      'Assessor Name': `${item.first_name} ${item.last_name}`,
      'Contact Info': item.email,
      'country': item?.country_name,
      'Schemes': item.scheme_names.map(certification => certification.name).join(', '), // Assuming scheme_names is an array of objects with a name property
      'Status': 'Approved', // Example status, adjust as needed
      'Approve Date': item.create_at ? formatDate(item.create_at) : '', // Adjust as per your date formatting function
    }));

    // Create worksheet and append data
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Assessor Data'); // Sheet name 'Assessor Data'

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'assessor_data.xlsx');
  };
  return (
    <div id="v_ass_01">
      {loader ? <UAFLoader /> : null}
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>

          <h2 className='mb-3 p-0 '>Verified Assessors{totalcount > 0 && (
            <span
              className='card-label fw-bold fs-1 m-2'
              style={{ backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em' }}
            >
              {totalcount}
            </span>
          )} </h2>
        </div>

        <div className='col-md-6 d-flex align-items-center justify-content-end'>

          {superUserPermission?.is_superuser === true && (
            <button id="download_boo" className='thm-btn mb-3' onClick={downloadExcel}>Download Report</button>
          )}


        </div>
      </div>



      <div className='card card-flush'>
        <div className='card-header pt-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
              <input
                id="le_lu_ri"
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchChange(e);
                  }
                }}
                placeholder="Search user"
              />
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' style={{ gap: "10px " }} data-kt-user-table-toolbar='base'>
              {superUserPermission?.is_superuser === true && (
                <>
                  <button id="le_lu_mai" type='button' className='btn btn-primary float-right' onClick={() => {
                    if (selectedItems && selectedItems.length > 0) {
                      setShowSendNotificationModal(true);
                    } else {
                      toast.error("Select at least 1 assessor");
                    }
                  }} >

                    <KTIcon iconName='plus' className='fs-2' />
                    Send Notification
                  </button>
                  <Link
                    id="le_lu_mai_1"
                    to="/create/assessor/"
                    className='btn btn-primary'
                  >
                    <span className='svg-icon svg-icon-2'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='11.364'
                          y='20.364'
                          width='16'
                          height='2'
                          rx='1'
                          transform='rotate(-90 11.364 20.364)'
                          fill='currentColor'
                        />
                        <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                      </svg>
                    </span>
                    Add Assessor</Link></>
              )}



              {/* </button> */}
              <CreateManualAssesor
                show={showCreateAppModal}
                handleClose={() => setShowCreateAppModal(false)}
                editUserId={''}
              />
              <BusinessUnitFilter
                show={showBusinessUnitFilterModal}
                handleClose={() => setShowBusinessUnitFilterModal(false)}
              />
            </div>
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-user-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-user-table-select='selected_count'></span>Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-user-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>

          </div>
        </div>
        <div className='card-body pt-5'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      checked={selectAll}

                      onChange={handleSelectAll}
                    />
                  </div>
                </th>
                <th className='min-w-125px'>Assessor Name</th>
                <th className='min-w-125px'>Contact Info</th>
                <th className='min-w-125px'>Country</th>
                <th className='min-w-125px'>Schemes</th>
                <th className='min-w-125px'>Status</th>
                <th className='min-w-125px'>Approve Date</th>
                {/* <th className='text-center min-w-100px'>Actions</th> */}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input' type='checkbox' value='1'
                        onChange={() => handleChangeId(item)}
                        checked={selectedItems?.includes(item?.id)}
                        id="le_lu_mai_2"
                      />
                    </div>
                  </td>
                  <td>
                    <Link
                    id="le_lu_mai_3"
                      to={`/assessor/verified/${item?.id}/overview`}
                      className='text-gray-800 text-hover-primary mb-1'
                    >
                      {item?.first_name} {item?.last_name}
                    </Link>
                  </td>

                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div>
                  </td>
                  <td>
                    <div className='text-gray-800 text-hover-primary mb-1'>{item?.country_name}</div>
                  </td>
                  <td>
                    <DropdownButton id='dropdown-basic-button' title='Schemes'>
                      {item.scheme_names?.map((certification, index) => (
                        <Dropdown.Item key={index}>{certification?.name}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </td>
                  <td>
                    <div className='badge badge-light-primary fw-bold'>Approved</div>
                  </td>
                  {/* <td>
                  {item?.accessor_approve_date
                    ? moment(item.accessor_approve_date).format('LL')
                    : null}
                </td> */}
                  <td>{item && item.create_at ? formatDate(item.create_at) : ''}</td>

                  {/* <td className='text-center'>
                    <a
                      // href={`/assessor/verified/${item?.id}/actions`}
                      className='btn btn-light btn-active-light-primary btn-sm'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      Actions
                      <span className='svg-icon svg-icon-5 m-0'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <Link
                          to={`/assessor/verified/${item?.id}/settings`}
                          className='menu-link px-3'
                        >
                          Edit
                        </Link>
                      </div>
                      <div className='menu-item px-3'>
                        <a
                          href='#'
                          className='menu-link px-3'
                          data-kt-users-table-filter='delete_row'
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <ul className='pagination'>
            <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
              <a   id="le_lu_mai_4" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                <i className='previous'></i>
              </a>
            </li>
            {totalPagesArray.map((page,index) => (
              <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                <a  id={`le_lu_page_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
                  {page}
                </a>
              </li>
            ))}
            <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
              <a   id="le_lu_mai_5" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div >
      {/* send notification modal */}
      < Modal show={showSendNotificationModal} onHide={() => setShowSendNotificationModal(false)} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>Send Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control form-control-lg border-1 mb-2"
            id="Subject"
            name="Subject"
            placeholder="Subject"
            onChange={(e) => setNotificationDetail({ ...notificationDetail, subject: e?.target?.value })}

            required
          />

          <textarea name="" className='form-control form-control-lg border-1 mb-2' id="" rows={3} onChange={(e) => setNotificationDetail({ ...notificationDetail, text: e?.target?.value })} placeholder='Enter message'>

          </textarea>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <div className="contact-page__input-box text-center">
              <label htmlFor="certification_file" className="file-upload-upload">{notificationDetail.documentFile ? "Doc Uploaded" : "Upload Doc"}</label>
              <input id="certification_file" type="file"
                onChange={handleCertification}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSendNotificationModal(false)}>
            Close
          </Button>
          <Button
            id="nand_le"
            variant="success"
            onClick={() => {
              handleSubmit()
              setShowSendNotificationModal(false);
              // OnEmailSend();
              // Here you can use editorData for further processing
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal >
    </div>
  )
}
export { VerifiedAssessorList }
