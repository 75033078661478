import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL
type Props = {
  show: boolean
  handleClose: () => void
  showAssessentClicked: any
  approval: () => void
}
const AssessmentPlannerModal = ({show, handleClose, showAssessentClicked, approval}: Props) => {
  interface FormData {
    cab: string
    assessment?: any
    sub_total_pre_taxes: any
    taxes_if_any: any
    grand_total: any
    net_payable_amount: any
    net_amount_invoice: any
    assessor_planner: any
    any_other_charges: any
    remarks: any
  }
  const initialFormData: FormData = {
    cab: '',
    assessment: [],
    sub_total_pre_taxes: '',
    taxes_if_any: '',
    grand_total: '',
    net_payable_amount: '',
    net_amount_invoice: '',
    assessor_planner: '',
    any_other_charges: '',
    remarks: '',
  }
  interface AccData {
    Ac_with_institution_bic: string
    account_name: string
    account_number: string
    ach_routing_no: string
    bank_address: string
    id: string
    wire_routing_no: string
  }
  const initialAccData: AccData = {
    Ac_with_institution_bic: '',
    account_name: '',
    account_number: '',
    ach_routing_no: '',
    bank_address: '',
    id: '',
    wire_routing_no: '',
  }

  const navigate = useNavigate()
  const [formData, setFormData] = useState<FormData>(initialFormData)
  console.log(showAssessentClicked, 'showAssessentClicked')
  const [assessment, setAssessment] = useState<any>()
  const [userData, setUserData] = useState<any>()
  const [accountDetails, setAccountDetails] = useState<AccData>(initialAccData)
  const [totalAssessorDay, setTotalAssessorDay] = useState<string>('')
  const [assessorDayFee, setAssessorDayFee] = useState<any>('')
  const [totalAmountUsd, setTotalAmountUsd] = useState<string>('')
  console.log(userData, 'userData')
  const getBankList = () => {
    axios
      .get(`${API_URL}/cab/bank/list/`)
      .then((response) => {
        const result = response.data?.[0]
        const data = {
          Ac_with_institution_bic: result?.Ac_with_institution_bic,
          account_name: result?.account_name,
          account_number: result?.account_number,
          ach_routing_no: result?.ach_routing_no,
          bank_address: result?.bank_address,
          id: result?.id,
          wire_routing_no: result?.wire_routing_no,
        }
        setAccountDetails(data)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  const handleUpdateBankList = () => {
    const {id, ...accData} = accountDetails
    axios
      .put(`${API_URL}/cab/bank/${id}/update/`, accData)
      .then((response) => {
        getBankList()
        console.log('PUT request successful:', response.data)
      })
      .catch((error) => {
        console.error('Error sending PUT request:', error)
      })
  }
  useEffect(() => {
    getBankList()
  }, [])
  const validateForm = () => {
    if (!assessorDayFee || assessorDayFee.trim() === '') {
      toast.error('Please Enter assessor day fee.')
      return false
    }

    if (!formData.taxes_if_any || formData.taxes_if_any.trim() === '') {
      toast.error('Please Enter Other taxes.')
      return false
    }
    if (!formData.grand_total || formData.grand_total.trim() === '') {
      toast.error('Please Enter Grand Total.')
      return false
    }
    if (!formData.net_payable_amount || formData.net_payable_amount.trim() === '') {
      toast.error('Please Enter Net Payable on account of this Invoice.')
      return false
    }
    if (!formData.net_amount_invoice || formData.net_amount_invoice.trim() === '') {
      toast.error('Please Enter Net Amount of Invoice.')
      return false
    }
    if (!accountDetails.account_name || accountDetails.account_name.trim() === '') {
      toast.error('Please Enter Account Name.')
      return false
    }
    if (!accountDetails.bank_address || accountDetails.bank_address.trim() === '') {
      toast.error('Please Enter Bank Address.')
      return false
    }
    if (!accountDetails.account_number || accountDetails.account_number.trim() === '') {
      toast.error('Please Enter Account Number.')
      return false
    }
    if (!accountDetails.ach_routing_no || accountDetails.ach_routing_no.trim() === '') {
      toast.error('Please Enter ACH Routing Number.')
      return false
    }
    if (!accountDetails.wire_routing_no || accountDetails.wire_routing_no.trim() === '') {
      toast.error('Please Enter Wire Routing Number.')
      return false
    }
    if (
      !accountDetails.Ac_with_institution_bic ||
      accountDetails.Ac_with_institution_bic.trim() === ''
    ) {
      toast.error('Please Enter A/c with Institution BIC.')
      return false
    }
    return true
  }
  const handleEstimationData = async () => {
    if (!validateForm()) return
    const data = formData
    data.cab = showAssessentClicked?.cab
    data.sub_total_pre_taxes =
      showAssessentClicked?.schemeData
        ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
        ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee
    data.any_other_charges = formData?.any_other_charges
    data.remarks = formData?.remarks
    data.taxes_if_any = formData?.taxes_if_any
    data.grand_total = formData?.grand_total
    data.net_payable_amount = formData?.net_payable_amount
    data.assessor_planner = showAssessentClicked?.id
    data.net_amount_invoice = formData?.net_amount_invoice
    data.assessment = [
      {
        assessment: showAssessentClicked?.assessment_type,
        remark: showAssessentClicked?.remark,
        total_assessor_day: showAssessentClicked?.schemeData
          ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
          ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0),
        assessor_day_fee: assessorDayFee,
        total_amount_usd:
          showAssessentClicked?.schemeData
            ?.filter((i) => i?.assessment_duration !== undefined && i?.assessment_duration !== null)
            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) * assessorDayFee,
      },
    ]

    try {
      const response = await axios.post(`${API_URL}/cab/send/assessment/estimation/`, data)

      toast.success('Assessment Invoice sent successfully.')
      GenerateMasterFormLink(response?.data?.id)
      setFormData(initialFormData)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }
  const handleSendEstimation = () => {
    handleEstimationData()
    handleUpdateBankList()
  }
  const GenerateMasterFormLink = (id) => {
    axios
      .get(
        `${API_URL}/cab/send/estimation/assessment/expire/link/${showAssessentClicked?.cab}/${id}/`
      )
      .then((response) => {
        const result = response.data
        console.log(result, 'result')
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  return (
    <Modal
      id='assessment-fee'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Assessment Fee Invoice</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='container'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer app_fee_card'>
              <div className='card-title m-0 d-flex justify-content-between align-items-center w-100'>
                <h4 className='fw-bolder m-0'>
                  Invoice Number: <span className='inline_master_head'>24/XX123/CAB</span>
                </h4>
                <h4 className='fw-bolder m-0'>
                  Dated:{' '}
                  <span className='inline_master_head'>
                    {moment(new Date(showAssessentClicked?.create_at)).format('DD/MMM/YYYY')}
                  </span>
                </h4>
              </div>
              <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                <span className='fs-18 pt-5'>
                  <strong>CAB Organisation: </strong>
                  {showAssessentClicked?.cab_company}
                </span>
              </div>
            </div>
            <div className='card-body px-9 py-5'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 app_fee_form'>
                <thead>
                  <tr className='text-start  fw-bold  text-uppercase gs-0'>
                    <th className='max-w-100px'></th>
                    <th className='max-w-100px'>Total Assessor days</th>
                    <th className='max-w-100px'>Assessor day Fee ($)</th>
                    <th className='max-w-100px'>Total Amount USD($)</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  <tr>
                    <td>
                      <div className='d-flex gap-5'>
                        <span className='min-w-150px d-flex align-items-center'>
                          {' '}
                          <strong>{showAssessentClicked?.assessment_name}</strong>
                        </span>
                        <span className='min-w-150px d-flex align-items-center'>
                          {' '}
                          <strong>{showAssessentClicked?.remark}</strong>
                        </span>
                      </div>
                    </td>
                    {/* <td> <input type="number" className="form-control form-control-solid" placeholder="--" value={showAssessentClicked?.schemeData
                                            ?.filter(i => i?.assessment_duration)
                                            ?.reduce((sum, item) => sum + item.assessment_duration, "")} />
                                        </td> */}
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={showAssessentClicked?.schemeData
                          ?.filter(
                            (i) =>
                              i?.assessment_duration !== undefined &&
                              i?.assessment_duration !== null
                          )
                          ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0)}
                        readOnly
                      />
                    </td>

                    <td>
                      {' '}
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={assessorDayFee}
                        onChange={(e) => setAssessorDayFee(e.target.value)}
                      />
                    </td>
                    <td>
                      {' '}
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          showAssessentClicked?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                          assessorDayFee
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Sub Total Pre Taxes</td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          showAssessentClicked?.schemeData
                            ?.filter(
                              (i) =>
                                i?.assessment_duration !== undefined &&
                                i?.assessment_duration !== null
                            )
                            ?.reduce((sum, item) => sum + parseFloat(item.assessment_duration), 0) *
                          assessorDayFee
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Any Other Charges</td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        onChange={(e) =>
                          setFormData({...formData, any_other_charges: e?.target?.value})
                        }
                      />
                    </td>
                  </tr>
                  {/* <tr>
                                        <td>Remarks</td>
                                        <td><input type="text" className="form-control form-control-solid" placeholder="--" onChange={(e) => setFormData({ ...formData, remark: e?.target?.value })} /></td>
                                    </tr> */}
                  <tr>
                    <td>Taxes, if any</td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.taxes_if_any}
                        onChange={(e) => setFormData({...formData, taxes_if_any: e?.target?.value})}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Grand Total </td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.grand_total}
                        onChange={(e) => setFormData({...formData, grand_total: e?.target?.value})}
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Net Payable on account of this Invoice </td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.net_payable_amount}
                        onChange={(e) =>
                          setFormData({...formData, net_payable_amount: e?.target?.value})
                        }
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Net Amount of Invoice USD ($) </td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={formData?.net_amount_invoice}
                        onChange={(e) =>
                          setFormData({...formData, net_amount_invoice: e?.target?.value})
                        }
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer app_fee_card'>
              <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                <span className='fs-18'>International Transfers can be made to the:</span>
              </div>
            </div>
            <div className='card-body px-9 py-5'>
              <div className='mb-5'>
                <label className='required form-label'>Account Name</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter Account Name'
                  value={accountDetails?.account_name}
                  onChange={(e) =>
                    setAccountDetails({...accountDetails, account_name: e?.target?.value})
                  }
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>Bank Address</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter Address'
                  value={accountDetails?.bank_address}
                  onChange={(e) =>
                    setAccountDetails({...accountDetails, bank_address: e?.target?.value})
                  }
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>Account Number</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter Account Number'
                  value={accountDetails?.account_number}
                  onChange={(e) =>
                    setAccountDetails({...accountDetails, account_number: e?.target?.value})
                  }
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>ACH ROUTING NO.</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter ACH ROUTING NO.'
                  value={accountDetails?.ach_routing_no}
                  onChange={(e) =>
                    setAccountDetails({...accountDetails, ach_routing_no: e?.target?.value})
                  }
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>WIRE ROUTING NO</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter WIRE ROUTING NO'
                  value={accountDetails?.wire_routing_no}
                  onChange={(e) =>
                    setAccountDetails({...accountDetails, wire_routing_no: e?.target?.value})
                  }
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>A/c with Institution BIC</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter A/c with Institution BIC'
                  value={accountDetails?.Ac_with_institution_bic}
                  onChange={(e) =>
                    setAccountDetails({
                      ...accountDetails,
                      Ac_with_institution_bic: e?.target?.value,
                    })
                  }
                />
              </div>
              <div className='mb-5'>
                <label htmlFor='remarks' className='form-label'>
                  Remarks
                </label>
                <textarea
                  id='remarks'
                  rows={3}
                  className='form-control form-control-solid'
                  placeholder='Remarks'
                  onChange={(e) => setFormData({...formData, remarks: e?.target?.value})}
                />
              </div>
            </div>
          </div>
          <div className='d-flex gap-5'>
            <button className='thm-btn' onClick={handleSendEstimation}>
              Send
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default AssessmentPlannerModal
