import React from 'react'
import {CardsWidget20, CardsWidget7} from '../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import NCGraph from './NCGraph'

const NCDashboard = () => {
  return (
    <div id="nc_ka_dashboard" className='container'>
      <h1>Dashboard</h1>
    <div className='row mb-5 mb-xl-10 mt-5 mt-xl-10'>
      <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10 '>
        {/* <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10'
          style={{
            backgroundColor: '#F1416C',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <div
            className='card-header pt-5'
            style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '20px',
            }}
          >
            <div className='card-title d-flex flex-column align-items-center'>
              <span
                className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mb-5'
                style={{fontSize: '8rem',justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',}}
              >
                69
              </span>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2' style={{fontSize: '4rem'}}>
                C.A Pending
              </span>
            </div>
          </div>
        </div> */}

      </div>
      {/* <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10 '>
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10'
          style={{
            backgroundColor: '#136ba1',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <div
            className='card-header pt-5'
            style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '20px',
            }}
          >
            <div className='card-title d-flex flex-column align-items-center'>
              <span
                className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mb-5'
                style={{fontSize: '8rem',justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',}}
              >
                69
              </span>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2' style={{fontSize: '4rem'}}>
                Evidence Pending
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10 '>
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10'
          style={{
            backgroundColor: '#F1416C',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <div
            className='card-header pt-5'
            style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '20px',
            }}
          >
            <div className='card-title d-flex flex-column align-items-center'>
              <span
                className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mb-5'
                style={{fontSize: '8rem',justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',}}
              >
                69
              </span>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2' style={{fontSize: '4rem'}}>
                Closed NC's
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10 '>
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10'
          style={{
            backgroundColor: '#136ba1',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <div
            className='card-header pt-5'
            style={{
              backgroundColor: 'white',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              padding: '20px',
            }}
          >
            <div className='card-title d-flex flex-column align-items-center'>
              <span
                className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2 mb-5'
                style={{fontSize: '8rem',justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',}}
              >
                69
              </span>
              <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2' style={{fontSize: '4rem'}}>
                Open NC's
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </div>


{/* <div className="card px-6 py-3" style={{
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      padding: '20px',
      borderRadius: '15px',
      marginBottom: '20px'
    }}>
    <h2>Open NC's</h2>
    <NCGraph/>
    </div> */}
    </div>
  )
}

export default NCDashboard
