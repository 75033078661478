import React from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
import { AssessmentPlannerWrapper } from "./component/AssessmentPlannerWrapper";
const organisationBreadCrumbs: Array<PageLink> = [
    {
      title: 'Organisation',
      path: '/organisation/AssessmentPlanner',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const AssessmentPlanner: React.FC = () => {
    return (<div id = "assessor_id">

            <PageTitle breadcrumbs={organisationBreadCrumbs}>Assessment Planner</PageTitle>
            <AssessmentPlannerWrapper/>


      </div>
    );
  };

  export default AssessmentPlanner;