import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import _ from 'lodash';

const BASE_URL = process.env.REACT_APP_API_URL;

interface ProgrammeData {
  id: string;
  name: string;
  description: string;
}

interface SchemeData {
  id: string;
  name: string;
  programme: string;
  description: string;
}

interface StandardData {
  id: string;
  name: string;
  scheme: string;
  description: string;
}

interface ClusterData {
  id: string;
  name: string;
  standard: string;
  description: string;
  scheme_id: string;
}

interface Standred {
  id: string;
  name: string;
}

interface IAFCriticalData {
  id: string;
  name: string;
  scheme: string;
  iaf_sector_doc: File | null;
}

interface SelectedIAFCritical {
  [clusterId: string]: {
    [criticalId: string]: {
      checked: boolean;
      file: File | null;
    };
  };
}

const CheckboxHierarchy: React.FC<{
  selelctedIAFCriticalValue: SelectedIAFCritical;
  schemeList: string[];
  userStandred: Standred[];
  selectedPrograms: string[];
  selectedSchemes: { [key: string]: string[] };
  selectedStandardList: string[];
  selectedSchemesList: string[];
  selectedCluster: string[];
  selectedStandards: { [key: string]: { [key: string]: string[] } };
  setSelelctedIAFCriticalValue: React.Dispatch<React.SetStateAction<SelectedIAFCritical>>;
  setSchemeList: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCluster: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPrograms: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedSchemes: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  setSelectedStandards: React.Dispatch<React.SetStateAction<{ [key: string]: { [key: string]: string[] } }>>;
  setSelectedSchemesList: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedStandardList: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({
  selelctedIAFCriticalValue,
  setSelelctedIAFCriticalValue,
  selectedCluster,
  setSelectedCluster,
  schemeList,
  setSchemeList,
  userStandred,
  selectedPrograms,
  selectedSchemes,
  selectedStandardList,
  selectedSchemesList,
  selectedStandards,
  setSelectedPrograms,
  setSelectedSchemes,
  setSelectedStandards,
  setSelectedSchemesList,
  setSelectedStandardList,
}) => {
    const [programmes, setProgrammes] = useState<ProgrammeData[]>([]);
    const [schemes, setSchemes] = useState<SchemeData[]>([]);
    const [standards, setStandards] = useState<StandardData[]>([]);
    const [clusters, setClusters] = useState<ClusterData[]>([]);
    console.log("clusters:",clusters);
    
    const [IAFCritical, setIAFCritical] = useState<IAFCriticalData[]>([]);
    const [expandedProgramIds, setExpandedProgramIds] = useState<string[]>([]);

    const fetchData = useCallback(async (endpoint: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
      try {
        const response = await axios.get(`${BASE_URL}${endpoint}`);
        setState(response.data);
      } catch (error) {
        console.error(`Error fetching ${endpoint} data:`, error);
      }
    }, []);

    useEffect(() => {
      fetchData('/global/programme/list/', setProgrammes);
    }, [fetchData]);

    useEffect(() => {
      fetchData('/global/scheme/list/', setSchemes);
    }, [fetchData]);

    useEffect(() => {
      fetchData('/global/standard/list/', setStandards);
    }, [fetchData]);

    useEffect(() => {
      const fetchClusters = async () => {
        try {
          const promises = userStandred.map(async (standard) => {
            const response = await axios.get(`${BASE_URL}/global/cluster/filter/?standard=${standard.id}`);
            return response.data;
          });
          const clusterData = await Promise.all(promises);
          setClusters(clusterData.flat());
        } catch (error) {
          console.error('Error fetching cluster data:', error);
        }
      };
      fetchClusters();
    }, [userStandred]);

    const handleFileUpload = useCallback(
      (itemId: string, clusterId: string, file: File) => {
        if (file) { // Check if a file is uploaded
          setSelelctedIAFCriticalValue((prevState) => {
            const updatedState = {
              ...prevState,
              [clusterId]: {
                ...prevState[clusterId],
                [itemId]: {
                  ...prevState[clusterId]?.[itemId],
                  checked: true, // Ensure checkbox remains checked when a file is uploaded
                  file: file,
                },
              },
            };
            return updatedState;
          });
        }
      },
      [setSelelctedIAFCriticalValue]
    );

    const handleIAFCheckboxChange = useCallback(
      (itemId: string, clusterId: string, type: 'checkbox' | 'file') => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, files } = e.target;

        if (type === 'file' && files && files.length > 0) {
          handleFileUpload(itemId, clusterId, files[0]);
          return;
        }

        if (!checked) {
          // Delete the file details
          setSelelctedIAFCriticalValue((prevState) => {
            const updatedState = {
              ...prevState,
              [clusterId]: {
                ...prevState[clusterId],
                [itemId]: {
                  ...prevState[clusterId]?.[itemId],
                  checked: false,
                  file: null,
                },
              },
            };
            return updatedState;
          });
          return;
        }

        setSelelctedIAFCriticalValue((prevState) => {
          const updatedState = {
            ...prevState,
            [clusterId]: {
              ...prevState[clusterId],
              [itemId]: {
                ...prevState[clusterId]?.[itemId],
                checked: true,
                file: prevState[clusterId]?.[itemId]?.file,
              },
            },
          };

          if (!checked) {
            delete updatedState[clusterId][itemId];
          } else {
            // Hide all other criticals for this cluster
            Object.keys(updatedState[clusterId]).forEach((key) => {
              if (key !== itemId) {
                delete updatedState[clusterId][key];
              }
            });
          }

          return updatedState;
        });
      },
      [handleFileUpload, setSelelctedIAFCriticalValue]
    );



    const fetchIAF = useCallback(
      async (id: string) => {
        try {
          const response = await axios.get(`${BASE_URL}/global/critical/iaf-sector/filter/?scheme=${id}`);
          const newData = response.data.filter((item: IAFCriticalData) => !IAFCritical.some((i) => i.id === item.id));
          setIAFCritical((prev) => [...prev, ...newData]);
        } catch (error) {
          console.error('Error fetching IAF data:', error);
        }
      },
      [IAFCritical]
    );

    const groupedClusters = useMemo(() => _.groupBy(clusters, 'scheme_name'), [clusters]);

    const handleSchemeSelection = useCallback(
      (programId: string, schemeId: string) => {
        setSelectedSchemes((prevState) => {
          const programSelectedSchemes = prevState[programId] || [];
          const updatedSchemes = programSelectedSchemes.includes(schemeId)
            ? programSelectedSchemes.filter((id) => id !== schemeId)
            : [...programSelectedSchemes, schemeId];

          setSelectedSchemesList((prevList) =>
            updatedSchemes.includes(schemeId) ? [...prevList, schemeId] : prevList.filter((id) => id !== schemeId)
          );

          return {
            ...prevState,
            [programId]: updatedSchemes,
          };
        });
      },
      [setSelectedSchemes, setSelectedSchemesList]
    );

    const handleStandardSelection = useCallback(
      (programId: string, schemeId: string, standardId: string) => {
        setSelectedStandards((prevState) => {
          const programSelectedStandards = prevState[programId] || {};
          const schemeSelectedStandards = programSelectedStandards[schemeId] || [];
          const updatedSchemeStandards = schemeSelectedStandards.includes(standardId)
            ? schemeSelectedStandards.filter((id) => id !== standardId)
            : [...schemeSelectedStandards, standardId];

          setSelectedStandardList((prevList) =>
            updatedSchemeStandards.includes(standardId) ? [...prevList, standardId] : prevList.filter((id) => id !== standardId)
          );

          return {
            ...prevState,
            [programId]: {
              ...programSelectedStandards,
              [schemeId]: updatedSchemeStandards,
            },
          };
        });
      },
      [setSelectedStandards, setSelectedStandardList]
    );

    const toggleProgram = useCallback(
      (clusterId: string) => {
        setSelectedCluster((prevSelectedCluster) => {
          const isSelected = prevSelectedCluster.includes(clusterId);
          return isSelected ? prevSelectedCluster.filter((id) => id !== clusterId) : [...prevSelectedCluster, clusterId];
        });

        setExpandedProgramIds((prevExpandedProgramIds) =>
          prevExpandedProgramIds.includes(clusterId) ? prevExpandedProgramIds.filter((id) => id !== clusterId) : [...prevExpandedProgramIds, clusterId]
        );
      },
      [setSelectedCluster, setExpandedProgramIds]
    );

    // const createPayload = useCallback(() => {
    //   const payload = selectedCluster.map((clusterId) => {
    //     const criticalData = selelctedIAFCriticalValue[clusterId]
    //       ? Object.entries(selelctedIAFCriticalValue[clusterId]).map(([criticalId, { file }]) => ({
    //         critircal_id: criticalId,
    //         file: file?.name || '',
    //       }))
    //       : [];
    //     return {
    //       cluster: clusterId,
    //       critical: criticalData,
    //     };
    //   });

    //   return payload;
    // }, [selectedCluster, selelctedIAFCriticalValue]);

    const createPayload = useCallback(() => {
      const payload = selectedCluster.map((clusterId) => {
        const criticalData = selelctedIAFCriticalValue[clusterId]
          ? Object.entries(selelctedIAFCriticalValue[clusterId]).map(([criticalId, { file }]) => ({
            critircal_id: criticalId,
            file: file?.name || '',
          }))
          : [];
        return {
          cluster: clusterId,
          critical: criticalData.length > 0 ? criticalData : undefined, // Only include if criticalData is not empty
        };
      }).filter((item) => item.critical !== undefined); // Remove clusters with empty critical array

      return payload;
    }, [selectedCluster, selelctedIAFCriticalValue]);

    const payload = createPayload();

    console.log('Payload:', JSON.stringify(payload, null, 2));

    return (
      <div className="card mb-5">
        <div className="card-header cursor-pointer">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              Apply for <span style={{ color: 'red' }}>*</span>
            </h3>
          </div>
        </div>
        <div className="card-body">
          <div className="row py-3">
            <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '5px' }}>
              {Object.keys(groupedClusters).map((schemeName) => (
                <div key={schemeName}>
                  <h3 className="fw-bolder py-8">{schemeName} CLUSTERS</h3>
                  {groupedClusters[schemeName].map((clusterItem: ClusterData) => (
                    <div key={clusterItem.id} className="mb-3">
                      <div
                        className="py-8 fv-row d-flex form-control form-control-lg form-control-solid"
                        onClick={() => toggleProgram(clusterItem.id)}
                        style={{ marginBottom: '10px' }}
                      >
                        <input
                          id={clusterItem.name}
                          name={clusterItem.name}
                          type="checkbox"
                          checked={selectedCluster.includes(clusterItem.id)}
                          onClick={() => {
                            fetchIAF(clusterItem.scheme_id);
                            const updatedSelectedIAFCriticalValue = { ...selelctedIAFCriticalValue };
                            IAFCritical.filter((item) => item.scheme === clusterItem.scheme_id).forEach((item) => {
                              if (updatedSelectedIAFCriticalValue[clusterItem.id]?.[item.id]) {
                                delete updatedSelectedIAFCriticalValue[clusterItem.id][item.id];
                              }
                            });
                            setSelelctedIAFCriticalValue(updatedSelectedIAFCriticalValue);
                          }}
                        />
                        <label style={{ marginLeft: '10px' }}>{clusterItem.name}</label>
                      </div>
                      <div className="px-10" style={{ marginLeft: '20px' }}>
                        {expandedProgramIds.includes(clusterItem.id) && (
                          <p className="fs-5 py-3">
                            Initial competence requirements for Critical IAF Sectors (
                            <span style={{ color: 'red' }}>Any one of the following *</span>)
                            {IAFCritical.filter((item) => item.scheme === clusterItem.scheme_id).map((item) => (
                              <div key={item.id} className="col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3">
                                <div className="d-flex">


                                  <label htmlFor={item.name}>
                                    {item.name}
                                  </label>
                                </div>
                                <div className="justify-content-end d-flex" style={{ marginLeft: 'auto' }}>



                                  {/* <input
                                    type="file"
                                    id={item.name}
                                    name={item.name}
                                    checked={selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.checked || false}
                                    onChange={handleIAFCheckboxChange(item.id, clusterItem.id, 'file')}
                                  /> */}
                                  <div>


                                    {selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.file ? (
                                      <a>
                                        <i
                                          onClick={() => {
                                            setSelelctedIAFCriticalValue((prevState) => {
                                              const updatedState = { ...prevState };
                                              delete updatedState[clusterItem.id][item.id];
                                              return updatedState;
                                            });
                                          }}

                                          className="fa fa-trash" style={{ padding: "8px" }} aria-hidden="true"></i>
                                      </a>
                                    ) : (
                                      <input
                                        type="file"
                                        id={item.name}
                                        name={item.name}
                                        checked={selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.checked || false}
                                        onChange={handleIAFCheckboxChange(item.id, clusterItem.id, 'file')}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

export default CheckboxHierarchy;

{/*   <input
                                  type="file"
                                  id={item.name}
                                  name={item.name}
                                  checked={selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.checked || false}
                                  onChange={handleIAFCheckboxChange(item.id, clusterItem.id, 'file')}
                                /> */}
{/* <label style={{ padding: '0 0 0 16px' }} htmlFor={item.name}></label> */ }


{/* <label
                                  htmlFor={`${item.name}`}
                                  className={
                                    selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.file
                                      ? 'file-uploaded-uploaded'
                                      : 'file-upload-upload'
                                  }
                                >
                                  {selelctedIAFCriticalValue[clusterItem.id]?.[item.id]?.file
                                    ? 'Document Uploaded'
                                    : 'Upload Doc'}
                                </label> */}


{/* <input
                                  type="file"
                                  id={`${item.name}_doc`}
                                  onChange={handleIAFCheckboxChange(item.id, clusterItem.id, 'file')}
                                  style={{ display: 'none' }}
                                /> */}