/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import MasterForm from '../modules/assessor-mangement/assessorLead/components/Masterform'
import MasterFormCab from '../modules/apps/cab-management/cab-list/components/MasterForm'
import MasterFormAssessmentFee from '../modules/apps/create-assessment/components/MasterForm'
import MasterFormJobOrder from '../modules/apps/create-assessment/components/MasterFormJobOrder'
import MasterFormCoiCab from '../modules/organisation/AssessmentPlanner/component/MasterFormCoiCab'
import MasterFormCoiAssesor from '../modules/organisation/AssessmentPlanner/component/MasterFormCoiAssesor'
import AssessorNC from '../modules/nc-mangement/AssessorNC'
import CabNCForm from '../modules/nc-mangement/CabNCForm'
import CABreplyForm from '../modules/nc-mangement/CABreplyForm'
import UploadEvidence from '../modules/nc-mangement/UploadEvidence'
import NCAssessorReport from '../modules/nc-mangement/NCAssessorReport'
import ARCMasterFormJobOrder from '../modules/apps/create-assessment/components/ARCMasterFormJobOder'
import {usePermissions} from '../permission_component/PermissionContext'
/**../modules/organisation/AssessmentPlanner/component/MasterFormCoiCab
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  const userId = localStorage.getItem("current_user_id");
  console.log(userId, "userId");
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();
  console.log(currentUser, "currentUser");


  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          {currentUser ? (
            <>


          {/* {currentUser?.is_verify_accessor === true ? (
            <>
             <Route path='/*' element={<PrivateRoutes />} />
             <Route index element={<Navigate to='/assessor/dashboard' />} />
            </>
          ) : currentUser?.is_cab_verify === true ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/cab/dashboard' />} />
            </>
          ) : currentUser?.is_superuser === true ?
            (*/}

                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
              ) : (

              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                <Route path='masterform/:token/:user' element={<MasterForm />} />
                <Route path='masterformcab/:token/:user' element={<MasterFormCab />} />
                <Route path='/NCManagement/assessor/nc/' element={<AssessorNC />} />
              </>
            )
          }

          {/* </>) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
              <Route path='masterform/:token/:user' element={<MasterForm />} />
              <Route path='masterformcab/:token/:user' element={<MasterFormCab />} />
              <Route path='/NCManagement/assessor/nc/' element={<AssessorNC />} />
            </>
          )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
