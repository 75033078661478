import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import { getUserDetails } from './assessorLead/components/AssessorFunction'
import { UserDataType } from './AssessorInterface'
interface PropsData {
    userId: string
  }
const ManualAssessorHeader :React.FC<PropsData> = ({ userId }) => {

    const location = useLocation()


    const [userData, setUserData] = useState<UserDataType>()

    useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await getUserDetails(userId)
            setUserData(result.details)
          } catch (error) {
            // Handle errors
            console.error('Error:', error)
          }
        }
        fetchData()
      }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    {/* <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {userData?.first_name} {userData?.last_name}
                    </a> */}
                    <Link
                      to={`/assessor/verified/${userData?.id}/overview`}
                      className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                    >
                      {userData?.first_name} {userData?.last_name}
                    </Link>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {userData?.country_name}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {userData?.email}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2'
                    >
                      <KTIcon iconName='phone' className='fs-4 me-1' />
                      {userData?.phone}
                    </a>
                  </div>
                </div>

                {/* <div className='d-flex my-4'>
                  <a

                    className='btn btn-sm btn-primary me-3 p-4'
                  >
                    Upload Doc
                  </a>
</div> */}

              </div>


            </div>
          </div>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `edit/info/${userId}/` && 'active')
                  }
                  to={`edit/info/${userId}/`}
                >
                  Add Info
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `edit/trainingdoc/${userId}/` && 'active')
                  }
                  to={`edit/trainingdoc/${userId}/`}
                >
                 Experience / Training Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `edit/programs/info/${userId}/` && 'active')
                  }
                  to={`edit/programs/info/${userId}/`}
                >
                  Program & Schemes Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `edit/critical/doc/${userId}/` && 'active')
                  }
                  to={`edit/critical/doc/${userId}/`}
                >
                  Upload Documents
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
  )
}

export default ManualAssessorHeader