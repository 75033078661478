/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { RequiredData } from '../../../../../app/modules/certificateForms/UserDataInterface'
import { usePermissions } from '../../../../../app/permission_component/PermissionContext'
const SidebarMenuMain = () => {
  const intl = useIntl()
  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();

  useEffect(() => {
    // Example of using updatePermissions
    const userId = localStorage.getItem("current_user_id");
    if (userId) {
      updatePermissions(userId);
    }
  }, []);


  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {/* cab access */}

      {/* {cabPermission?.is_cab_verify === true && (
         <SidebarMenuItem
         to='/dashboard'
         icon='element-11'
         title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
         fontIcon='bi-app-indicator'
       />

      )} */}



      {/* admin access */}

      {superUserPermission?.is_superuser === true && (
        <>
          <SidebarMenuItemWithSub
            to='/organisation'
            title='CAB Management'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem to='/apps/cab-management/cab' title='CAB Leads' hasBullet={true} id="cab-leads-id" />
            <SidebarMenuItem to='/apps/cab-user/cab' title='CAB Users' hasBullet={true} id="cab_user_id_1" />
            <SidebarMenuItem to='/apps/all/cab/certificates' title='CAB Certificates' hasBullet={true} id="cab_user_id_1" />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/assessor'
            title='Competence Management'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem to='/assessor/leads' title='Assessor Leads' hasBullet={true} id="assessor-leads-id" />
            <SidebarMenuItem to='/assessor/verified/list' title='Verified Assessor' hasBullet={true} id="verified-assessor-id_1" />
            <SidebarMenuItem to='/assessor/arc_member' title='ARC Member' hasBullet={true} id="arc-member-id" />
          </SidebarMenuItemWithSub>

        </>
      )}



      {/* assessor access */}
{/* 
      {assessorPermission?.is_verify_accessor === true && (
        <SidebarMenuItemWithSub
          to='/assessor'
          title='Competence Management'
          icon='element-12'
          fontIcon='bi-app-indicator'
        >
          <SidebarMenuItem to='/assessor/verified/list' title='Verified Assessor' hasBullet={true} id="verified_assessor_id_1" />
        </SidebarMenuItemWithSub>
      )} */}


      {/* admin access */}

      {superUserPermission?.is_superuser === true && (
        <>
          <SidebarMenuItemWithSub
            to='/assessor'
            title='Performance Monitoring'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem to='/apps/performance-monitoring/appication-reviewer' title='Application Reviewer' hasBullet={true} id="application_reviewer_id" />
            <SidebarMenuItem to='/apps/performance-monitoring/assessor-monitoring' title='Assessor & Tech Expert' hasBullet={true} id="assessor_monitoring_id" />
            <SidebarMenuItem to='/apps/performance-monitoring/arc-member-monitoring' title='ARC Member' hasBullet={true} id="arc_member_monitoring_id" />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/organisation'
            title='Resource Matrix'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >

            <SidebarMenuItem to='/resource-matrix-global' title='Resource Matrix Global' hasBullet={true} id="resource_matrix_global_id" />
            <SidebarMenuItem to='/resource-matrix-qms' title='Resource Matrix QMS' hasBullet={true} id="resource_matrix_qms_id" />
            <SidebarMenuItem to='/resource-matrix-ems' title='Resource Matrix EMS' hasBullet={true} id="resource_matrix_ems_id" />
            <SidebarMenuItem to='/resource-matrix-ohsms' title='Resource Matrix OHSMS' hasBullet={true} id="resource_matrix_ohsms_id" />
            <SidebarMenuItem to='/resource-matrix-fsms' title='Resource Matrix FSMS' hasBullet={true} id="resource_matrix_fsms_id" />
            <SidebarMenuItem to='/resource-matrix-mdqms' title='Resource Matrix MDQMS' hasBullet={true} id="resource_matrix_mdqms_id" />
            <SidebarMenuItem to='/resource-matrix-tl' title='Resource Matrix TL' hasBullet={true} id="resource_matrix_tl_id" />
            <SidebarMenuItem to='/resource-matrix-ib' title='Resource Matrix IB' hasBullet={true} id="resource_matrix_ib_id" />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/organisation'
            title='Assessment'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            {/* <SidebarMenuItem to='/organisation/businessunit' title='Business Unit' hasBullet={true} />
        <SidebarMenuItem to='/organisation/unitfunction' title='Unit Function' hasBullet={true} /> */}
            <SidebarMenuItem to='/organisation/CreateAssessment' title='Create Assessment' hasBullet={true} id="create_assessment_id" />
            <SidebarMenuItem to='/organisation/AssessmentPlanner' title='Assessment Planner' hasBullet={true} id="assessment_planner_id" />
            <SidebarMenuItem to='/organisation/HistoricalAssessment' title='Historical Assessment' hasBullet={true} id="historical_assessment_id" />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/organisation'
            title='Accreditation'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            {/* <SidebarMenuItem to='/accreditation/cab/report/' title='ARC Reports' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/accreditation/cab/report/create/' title='Create ARC Report' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/accreditation/cab/arc/list/' title='ARC List ' hasBullet={true} /> */}
            <SidebarMenuItem to='/accreditation/cab/assign/arc/' title='Assign ARC ' hasBullet={true} id="assign_arc_id" />

          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/NCManagement/form'
            title='NC Management'
            icon='element-12'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem to='/NCManagement/form' title='NC Form' hasBullet={true} id="nc_form_id" />
            <SidebarMenuItem to='/NCManagement/list' title='NC List' hasBullet={true} id="nc_list_id" />
          </SidebarMenuItemWithSub>
        </>
      )}


      {/* assessor access */}

      {assessorPermission?.is_verify_accessor === true && (
        <SidebarMenuItemWithSub
          to='/NCManagement/form'
          title='NC Management'
          icon='element-12'
          fontIcon='bi-app-indicator'
        >
          <SidebarMenuItem to='/NCManagement/form' title='NC Form' hasBullet={true} id="nc_form_id" />
        </SidebarMenuItemWithSub>
      )}


      {/* admin access */}

      {superUserPermission?.is_superuser === true && (<>
        <SidebarMenuItemWithSub
          to='/crafted/userteam'
          title='User Management'
          icon='profile-circle'
          fontIcon='bi-person'
        >
          <SidebarMenuItem to='/crafted/userteam/rolematrix' title='Role Matrix' hasBullet={true} id="role_matrix_id" />
          <SidebarMenuItem to='/crafted/userteam/group' title='Create Role' hasBullet={true} id="create_role_id" />
          <SidebarMenuItem to='/crafted/userteam/allusers' title='Users' hasBullet={true} id="all_users_id" />
          <SidebarMenuItem hasBullet={true} to='/crafted/uafdocuments' title='UAF Documents' id="uaf_documents_id" />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to='/crafted/content'
          title='Content Management'
          icon='profile-circle'
          fontIcon='bi-person'
        >
      
          <SidebarMenuItem to='/crafted/addblog' title='Add Blog' hasBullet={true} id="add_blog_id" />
          <SidebarMenuItem to='/crafted/bloglist' title='Blogs' hasBullet={true} id="blog_id" />
          <SidebarMenuItem to='/crafted/traininglist' title='Training List' hasBullet={true} id="training_id" />
          <SidebarMenuItem to='/crafted/addtraining' title='Add Training' hasBullet={true} id="blog_id_1" />
          
         {/* <SidebarMenuItem to='/crafted/suspended' title='Suspended and WithDrawn CABS' hasBullet={true} id="suspended"/> */}
         <SidebarMenuItem to='/crafted/notices' title='Notice Changes' hasBullet={true} id="notices"/>
        </SidebarMenuItemWithSub>
         <SidebarMenuItem to='/invoice'  icon='bill' title='Invoice' hasBullet={false} id="invoice-ic"/>
       
      </>
      )}
    </>
  )
}
export { SidebarMenuMain }