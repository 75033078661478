/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import CreateBusinessUnit from "./models/CreateBusiinessUnit"
import BusinessUnitFilter from "./models/businessUniterFilter"

// type Props = {
//   className: string
// }

// const BusinessUnitList = ({ className }: Props) => (
const BusinessUnitList: React.FC = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
   const [showBusinessUnitFilterModal, setShowBusinessUnitFilterModal] = useState<boolean>(false)
  return (
    <div className="card card-flush">
      <div className='card-header pt-5'>
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <span className="svg-icon svg-icon-1 position-absolute ms-6">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
              </svg>
            </span>
            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search user" />
          </div>
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
            <button type="button" className="btn btn-light-primary me-3" onClick={() => setShowBusinessUnitFilterModal(true)}>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                </svg>
              </span>
              Filter</button>
            <button type="button" className="btn btn-primary" onClick={() => setShowCreateAppModal(true)}>
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                </svg>
              </span>
              Add Business Unit</button>
            <CreateBusinessUnit show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
            <BusinessUnitFilter show={showBusinessUnitFilterModal} handleClose={() => setShowBusinessUnitFilterModal(false)} />
          </div>
          <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
            <div className="fw-bold me-5">
              <span className="me-2" data-kt-user-table-select="selected_count"></span>Selected</div>
            <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
          </div>
          {/*  <div className="modal fade" id="kt_modal_export_users" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="fw-bold">Export Users</h2>
                  <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                    <span className="svg-icon svg-icon-1">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                  <form id="kt_modal_export_users_form" className="form" action="#">
                    <div className="fv-row mb-10">
                      <label className="fs-6 fw-semibold form-label mb-2">Select Roles:</label>
                      <select name="role" data-control="select2" data-placeholder="Select a role" data-hide-search="true" className="form-select form-select-solid fw-bold">
                        <option></option>
                        <option value="Administrator">Administrator</option>
                        <option value="Analyst">Analyst</option>
                        <option value="Developer">Developer</option>
                        <option value="Support">Support</option>
                        <option value="Trial">Trial</option>
                      </select>
                    </div>
                    <div className="fv-row mb-10">
                      <label className="required fs-6 fw-semibold form-label mb-2">Select Export Format:</label>
                      <select name="format" data-control="select2" data-placeholder="Select a format" data-hide-search="true" className="form-select form-select-solid fw-bold">
                        <option></option>
                        <option value="excel">Excel</option>
                        <option value="pdf">PDF</option>
                        <option value="cvs">CVS</option>
                        <option value="zip">ZIP</option>
                      </select>
                    </div>
                    <div className="text-center">
                      <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                      <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                        <span className="indicator-label">Submit</span>
                        <span className="indicator-progress">Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
      <div className='card-body pt-5'>
        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
          <thead>
            <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
              <th className="w-10px pe-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" value="1" />
                </div>
              </th>
              <th className="min-w-125px">User</th>
              <th className="min-w-125px">Role</th>
              <th className="min-w-125px">Last login</th>
              <th className="min-w-125px">Two-step</th>
              <th className="min-w-125px">Joined Date</th>
              <th className="text-end min-w-100px">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-semibold">
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-6.jpg" alt="Emma Smith" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Emma Smith</a>
                  <span>smith@kpmg.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">Yesterday</div>
              </td>
              <td></td>
              <td>21 Feb 2023, 6:05 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-danger text-danger">M</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Melody Macy</a>
                  <span>melody@altbox.com</span>
                </div>
              </td>
              <td>Analyst</td>
              <td>
                <div className="badge badge-light fw-bold">20 mins ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>25 Jul 2023, 8:43 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-1.jpg" alt="Max Smith" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Max Smith</a>
                  <span>max@kt.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">3 days ago</div>
              </td>
              <td></td>
              <td>10 Nov 2023, 5:20 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-5.jpg" alt="Sean Bean" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Sean Bean</a>
                  <span>sean@dellito.com</span>
                </div>
              </td>
              <td>Support</td>
              <td>
                <div className="badge badge-light fw-bold">5 hours ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>10 Mar 2023, 11:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-25.jpg" alt="Brian Cox" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Brian Cox</a>
                  <span>brian@exchange.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">2 days ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>22 Sep 2023, 11:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-warning text-warning">C</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Mikaela Collins</a>
                  <span>mik@pex.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">5 days ago</div>
              </td>
              <td></td>
              <td>10 Nov 2023, 10:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-9.jpg" alt="Francis Mitcham" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Francis Mitcham</a>
                  <span>f.mit@kpmg.com</span>
                </div>
              </td>
              <td>Trial</td>
              <td>
                <div className="badge badge-light fw-bold">3 weeks ago</div>
              </td>
              <td></td>
              <td>20 Dec 2023, 10:10 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-danger text-danger">O</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Olivia Wild</a>
                  <span>olivia@corpmail.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">Yesterday</div>
              </td>
              <td></td>
              <td>25 Jul 2023, 5:20 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-primary text-primary">N</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Neil Owen</a>
                  <span>owen.neil@gmail.com</span>
                </div>
              </td>
              <td>Analyst</td>
              <td>
                <div className="badge badge-light fw-bold">20 mins ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>25 Jul 2023, 10:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-23.jpg" alt="Dan Wilson" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Dan Wilson</a>
                  <span>dam@consilting.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">3 days ago</div>
              </td>
              <td></td>
              <td>10 Nov 2023, 6:05 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-danger text-danger">E</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Emma Bold</a>
                  <span>emma@intenso.com</span>
                </div>
              </td>
              <td>Support</td>
              <td>
                <div className="badge badge-light fw-bold">5 hours ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>10 Mar 2023, 2:40 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-12.jpg" alt="Ana Crown" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Ana Crown</a>
                  <span>ana.cf@limtel.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">2 days ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>24 Jun 2023, 11:05 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-info text-info">A</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Robert Doe</a>
                  <span>robert@benko.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">5 days ago</div>
              </td>
              <td></td>
              <td>20 Dec 2023, 11:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-13.jpg" alt="John Miller" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">John Miller</a>
                  <span>miller@mapple.com</span>
                </div>
              </td>
              <td>Trial</td>
              <td>
                <div className="badge badge-light fw-bold">3 weeks ago</div>
              </td>
              <td></td>
              <td>05 May 2023, 9:23 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-success text-success">L</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Lucy Kunic</a>
                  <span>lucy.m@fentech.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">Yesterday</div>
              </td>
              <td></td>
              <td>21 Feb 2023, 6:43 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-danger text-danger">M</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Melody Macy</a>
                  <span>melody@altbox.com</span>
                </div>
              </td>
              <td>Analyst</td>
              <td>
                <div className="badge badge-light fw-bold">20 mins ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>20 Dec 2023, 11:30 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-1.jpg" alt="Max Smith" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Max Smith</a>
                  <span>max@kt.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">3 days ago</div>
              </td>
              <td></td>
              <td>21 Feb 2023, 6:43 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-5.jpg" alt="Sean Bean" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Sean Bean</a>
                  <span>sean@dellito.com</span>
                </div>
              </td>
              <td>Support</td>
              <td>
                <div className="badge badge-light fw-bold">5 hours ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>20 Dec 2023, 11:05 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-25.jpg" alt="Brian Cox" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Brian Cox</a>
                  <span>brian@exchange.com</span>
                </div>
              </td>
              <td>Developer</td>
              <td>
                <div className="badge badge-light fw-bold">2 days ago</div>
              </td>
              <td>
                <div className="badge badge-light-success fw-bold">Enabled</div>
              </td>
              <td>24 Jun 2023, 10:10 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-warning text-warning">C</div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Mikaela Collins</a>
                  <span>mik@pex.com</span>
                </div>
              </td>
              <td>Administrator</td>
              <td>
                <div className="badge badge-light fw-bold">5 days ago</div>
              </td>
              <td></td>
              <td>20 Dec 2023, 6:43 am</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" value="1" />
                </div>
              </td>
              <td className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="assets/media/avatars/300-9.jpg" alt="Francis Mitcham" className="w-100" />
                    </div>
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Francis Mitcham</a>
                  <span>f.mit@kpmg.com</span>
                </div>
              </td>
              <td>Trial</td>
              <td>
                <div className="badge badge-light fw-bold">3 weeks ago</div>
              </td>
              <td></td>
              <td>20 Dec 2023, 5:30 pm</td>
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </a>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <a href="../../demo1/dist/apps/user-management/users/view.html" className="menu-link px-3">Edit</a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul className="pagination">
          <li className="page-item previous disabled">
            <a href="#" className="page-link">
              <i className="previous"></i>
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link">
              1
            </a>
          </li>
          <li className="page-item active">
            <a href="#" className="page-link">
              2
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link">
              3
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link">
              4
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link">
              5
            </a>
          </li>
          <li className="page-item">
            <a href="#" className="page-link">
              6
            </a>
          </li>
          <li className="page-item next">
            <a href="#" className="page-link">
              <i className="next"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )

}
export { BusinessUnitList }
