import React from 'react'

import { PageLink,PageTitle } from '../../../_metronic/layout/core'

import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'


import { VerifiedAssessorDoc } from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorDoc'
import { VerifiedAssessorSetting } from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorSetting'
import VerifiedAssessorHeader from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorHeader'
import VerifiedAssessorOverview from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorOverview'
import VerifiedAssessorDocDetails from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorDocDetails'
import { VerifiedAssessorAssessment } from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorAssessment'
import ARCHeader from './ARCHeader'
import ARCOverview from './ARCOverview'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'ARC',
    path: '/accreditation/cab/arc/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CABarcDetails: React.FC = () => {

  const Id = useParams()
  console.log(Id,"printed IDIDIDI");
  const userId = JSON.stringify(Id)



  return(
    <Routes>
    <Route
      element={
        <>
          {/* <VerifiedAssessorHeader userId={userId}/> */}
          <ARCHeader userId={userId}/>
          <Outlet />
        </>
      }
    >
      <Route
        path=':id/overview'

        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>ARC Report</PageTitle>
            {/* <VerifiedAssessorOverview userId={userId}/> */}
            <ARCOverview userId={userId}/>
          </>

        }
      />
      {/* <Route
        path=':id/documents'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <VerifiedAssessorDoc userId={userId}/>
          </>
        }
      />
       <Route
        path=':id/documents/details/:itemId'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Documents</PageTitle>
            <VerifiedAssessorDocDetails />
          </>
        }
      />
       <Route
        path=':id/settings'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Setting</PageTitle>
            <VerifiedAssessorSetting userId={userId} />
          </>
        }
      />
       <Route
        path=':id/assessment'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Assessments</PageTitle>
            <VerifiedAssessorAssessment userId={userId} />
          </>
        }
      /> */}
      <Route index element={<Navigate to='/accreditation/cab/arc/overview' />} />
    </Route>
  </Routes>

  )
}


export default CABarcDetails
