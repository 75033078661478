import React, { useEffect, useState } from "react";

import '../../../../../../_metronic/assets/css/masterform.css'
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL

const MasterForm: React.FC = () => {
    interface AccData {
        Ac_with_institution_bic: string,
        account_name: string,
        account_number: string,
        ach_routing_no: string,
        bank_address: string,
        id: string,
        wire_routing_no: string
    }

    const initialAccData: AccData = {
        Ac_with_institution_bic: "",
        account_name: "",
        account_number: "",
        ach_routing_no: "",
        bank_address: "",
        id: "",
        wire_routing_no: ""
    };
    const formUrl = useParams()
    const token_url = formUrl?.token || ''
    const userId_url = formUrl?.user || ''
    const [showRejectReason, setShowRejectReason] = useState<boolean>(false)
    const [accountDetails, setAccountDetails] = useState<AccData>(initialAccData);
    const [userDetails, setUserDetails] = useState<any>();
    const [remarks, setRemarks] = useState<string>('');

    const getBankList = () => {
        axios
            .get(`${API_URL}/cab/bank/list/`)
            .then((response) => {
                const result = response.data?.[0]
                const data = {
                    Ac_with_institution_bic: result?.Ac_with_institution_bic,
                    account_name: result?.account_name,
                    account_number: result?.account_number,
                    ach_routing_no: result?.ach_routing_no,
                    bank_address: result?.bank_address,
                    id: result?.id,
                    wire_routing_no: result?.wire_routing_no
                }
                setAccountDetails(data)
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }

    const getUserData = () => {
        axios
            .get(`${API_URL}/cab/payment/details/${userId_url}/`)
            .then((response) => {
                const result = response.data;
                setUserDetails(result);

            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    useEffect(() => {
        getBankList()
        getUserData()
    }, [])

    const handleApproveRejectEstimation = (e) => {        
        if(e === "Reject" && !remarks){
            toast.error("Please enter reason of rejection.");
            return
        }
        const data = {
            user: userId_url,
            token: token_url,
            is_approved: e==="Approve" ? true : false,
            is_reject:  e==="Reject" ? true : false,
            remark: remarks
        }
            try {
                const response = axios.post(`${API_URL}/cab/send/estimation/user/update/`, data);
                toast.success( e==="Approve" ? "Application Fee Approved successfully." : "Application Fee Rejected successfully.")
                setRemarks('')
            } catch (error) {
                console.log(error);
            }

    }
    return (

        <div className="container py-20">
            <div className="row">
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                        alt='Logo'
                        src={('/media/logos/uaf_logo.png')}
                        className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
                    />
                    <div className="uaf_title py-5 text-center">
                        <h2 className="">
                            UNITED ACCREDITATION FOUNDATION
                        </h2>
                        <span className="fs-18">Application Fee Invoice</span>
                    </div>
                </div>
            </div>
            {/* qualification */}
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer app_fee_card'>
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100'>
                        <h4 className='fw-bolder m-0'>Invoice Number: <span className="inline_master_head">{userDetails?.payment?.invoice}</span></h4>
                        <h4 className='fw-bolder m-0'>Dated: <span className="inline_master_head">{moment(userDetails?.payment?.create_at).format("DD/MMM/YYYY")}</span></h4>
                    </div>
                    <div className='card-title m-0 d-flex justify-content-center align-items-center w-100'>
                        <h2 className='fw-bolder pt-5 m-0 invoice_cabName'>{userDetails?.payment?.cab_name}</h2>
                    </div>
                    <div className='card-title m-0 d-flex justify-content-center align-items-center w-100'>
                        <span className="fs-18">({userDetails?.payment?.cab_country})</span>
                    </div>

                </div>
                <div className='card-body px-9 py-5'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 app_fee_form'>
                        <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th className='w-10px pe-2'>S.No</th>
                                <th className='min-w-125px'>Details</th>
                                <th className='min-w-125px'>Fee</th>
                                <th className='min-w-125px'>Manday Rate ($)</th>
                                <th className='min-w-125px'>Total Amount USD($)</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                            {
                                userDetails?.scheme?.map((item, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item?.scheme_name}</td>
                                        <td>{item?.fee}</td>
                                        <td>{item?.manday_rate}</td>
                                        <td>{item?.total_amount_usd}</td>
                                    </tr>
                                ))

                            }
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{userDetails?.scheme?.length + 1}</td>
                                <td>Sub Total Pre Taxes,</td>
                                <td>{userDetails?.payment?.sub_total_pre_taxes}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{userDetails?.scheme?.length + 2}</td>
                                <td>Taxes, if any</td>
                                <td>{userDetails?.payment?.taxes_if_any}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{userDetails?.scheme?.length + 3}</td>
                                <td>Grand Total </td>
                                <td>{userDetails?.payment?.grand_total}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{userDetails?.scheme?.length + 4}</td>
                                <td>Net Payable on account of this Invoice </td>
                                <td>{userDetails?.payment?.net_payable_amount}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Net Amount of Invoice USD ($) </td>
                                <td>{userDetails?.payment?.net_amount_invoice}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="app_fee_msg">
                        <strong>Invoice Due Date:</strong> The Application Fee Invoice is to be paid immediately (before submission of checklists).
                    </div>
                </div>
            </div>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer app_fee_card'>
                    <div className='card-title m-0 d-flex justify-content-start align-items-center w-100'>
                        <span className="fs-18">International Transfers can be made to the:</span>
                    </div>
                </div>
                <div className='card-body px-9 py-5'>
                    <p><strong>Account Name:</strong> {accountDetails?.account_name}</p>
                    <p>{accountDetails?.bank_address}</p>
                    <p><strong>Account Number:</strong> {accountDetails?.account_number} </p>
                    <p><strong>ACH ROUTING NO. :</strong> {accountDetails?.ach_routing_no} </p>
                    <p><strong>WIRE ROUTING NO: </strong>{accountDetails?.wire_routing_no}</p>
                    <p><strong>A/c with Institution BIC:</strong> {accountDetails?.Ac_with_institution_bic}</p>
                </div>
            </div>

            {
                showRejectReason ?
                    <div className='card mb-5 mb-xl-10 mt-4'>
                        <span className="fs-18 p-5 pb-0">Reason of Rejection</span>
                        <div className='contact-page__input-box p-5'>
                            <textarea
                                style={{
                                    color: '#2e2e36',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    width: '100%',
                                    height: '105px',
                                    border: '2px solid transparent',
                                    background: '#f4f4f5',
                                    padding: '20px 30px 20px',
                                    borderRadius: '5px',
                                    marginBottom: '0px',
                                    outline: 'none',
                                    transition: 'all 200ms linear',
                                    transitionDelay: '0.1s',
                                }}
                                name='message'
                                placeholder='Reason of Rejection'
                                defaultValue={''}
                                value={remarks}
                                onChange={(e) => setRemarks(e?.target?.value)}
                            />
                        </div>
                        <div className="d-flex gap-5 p-5 pt-0">
                            <button className='thm-btn' onClick={() => handleApproveRejectEstimation("Reject")}>
                                Submit
                            </button>
                            <button className='app_thm_btn' onClick={() => setShowRejectReason(false)}>
                                Cancel
                            </button>
                        </div>
                    </div> :
                    <div className="d-flex gap-5">
                        <button className='thm-btn' onClick={() => handleApproveRejectEstimation("Approve")}>
                            Approve
                        </button>
                        <button className='app_thm_btn' onClick={() => setShowRejectReason(true)}>
                            Reject
                        </button>
                    </div>
            }
        </div>



    )
}
export default MasterForm;