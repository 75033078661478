import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { KTIcon } from '../../../_metronic/helpers'
import InvoiceChart from './InvoiceChart'

const InvoiceHeader = () => {
    const location = useLocation()
    return (<>
        <div className='col-lg-12'>
            <div className='d-flex justify-content-end mb-3' style={{ gap: "10px " }} data-kt-user-table-toolbar='base'>
                <Link to="/create-invoice"id="le_lu_mai_1" className='btn btn-primary' > Create Invoice</Link>
            </div>
            <div className='row'>
                <div className='col-lg-9 '>
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body pt-9 pb-0'>
                            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                                <div className='flex-grow-1'>
                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        <div className='p-5'>
                                            <p className='text-gray-600  mb-3' style={{ fontWeight: "600" }}>
                                                TOTAL
                                            </p>
                                            <p className='text-dark-400 me-5 mb-4 '
                                                style={{ fontSize: "50px", fontWeight: "600" }}>
                                                $44444
                                            </p>
                                            <p className='text-dark-400 me-5 mb-4 '
                                            >
                                                <span className="badge badge-light-primary " style={{ fontWeight: "600", fontSize: "16px", padding: "5px 15px", borderRadius: "10px" }}>
                                                    213 Invoice
                                                </span>

                                            </p>
                                        </div>

                                        <div className='p-5'>
                                            <p className='text-gray-600  mb-3' style={{ fontWeight: "600" }}>
                                                PAID
                                            </p>
                                            <p className='text-dark-400 me-5 mb-4 '
                                                style={{ fontSize: "50px", fontWeight: "600" }}>
                                                $44444
                                            </p>
                                            <p className='text-dark-400 me-5 mb-4 '
                                            >
                                                <span className="badge badge-light-success " style={{ fontWeight: "600", fontSize: "16px", padding: "5px 15px", borderRadius: "10px" }}>
                                                    213 Invoice
                                                </span>

                                            </p>
                                        </div>
                                        <div className='p-5'>
                                            <p className='text-gray-600  mb-3' style={{ fontWeight: "600" }}>
                                                UNPAID
                                            </p>
                                            <p className='text-dark-400 me-5 mb-4 '
                                                style={{ fontSize: "50px", fontWeight: "600" }}>
                                                $44444
                                            </p>
                                            <p className=' me-5 mb-4 '
                                            >
                                                <span className="badge badge-light-danger " style={{ fontWeight: "600", fontSize: "16px", padding: "5px 15px", borderRadius: "10px" }}>
                                                    213 Invoice
                                                </span>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div
                        className='card card-flush px-6 py-3'
                        style={{ padding: '20px', borderRadius: '5px' }}
                    >

                        <InvoiceChart pending={12} paid={38} draft={20} overdue={30} />
                    </div>
                </div>
            </div>

        </div>
        <div className='d-flex overflow-auto h-55px px-10'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                    <Link
                        className={
                            `nav-link text-active-primary me-6  ` +
                            (location.pathname === `/invoice/all-invoice` && 'active')
                        }
                        to={`/invoice/all-invoice`}
                    >
                        All Invoice
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link
                        className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/invoice/paid-invoice` && 'active')
                        }
                        to={`/invoice/paid-invoice`}
                    >
                        Paid
                    </Link>
                </li>

                <li className='nav-item'>
                    <Link
                        className={
                            `nav-link text-active-primary me-6 ` +
                            (location.pathname === `/invoice/pending-invoice` && 'active')
                        }
                        to={`/invoice/pending-invoice`}
                    >
                        Pending
                    </Link>
                </li>



            </ul>
        </div></>
    )
}

export default InvoiceHeader