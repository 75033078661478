import React, { useEffect, useState, ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";
import axios from "axios";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input'
import AddSiteAddressModal from "./modal/AddSiteAddressModal";


const SiteAddressTable = ({ }) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const [addSiteAddressModal, setAddSiteAddressModal] = useState<boolean>(false)



    return (
        <>
            <div className='card-body p-9'>
                <div className='row gy-10 gx-xl-10'>
                    <div className='card-title m-0 d-flex w-100 justify-content-end'>

                        <button type='button' className='btn btn-primary float-right' onClick={()=>setAddSiteAddressModal(true)}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add Site Address
                        </button>
                    </div>
                    <div className='col-xl-12'>
                        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                            <table className="table align-middle table-row-dashed fs-6 gy-5 location_table_show" id="kt_table_users">
                                <thead>
                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                        <th >S No</th>
                                        <th className="min-w-125px">Address</th>
                                        <th className="min-w-125px">State</th>
                                        <th className="min-w-125px">City</th>
                                        <th className="min-w-125px">Postal</th>
                                        <th className="min-w-125px">Country</th>



                                    </tr>

                                </thead>
                                <tbody className="text-gray-600 fw-semibold">

                                    <tr>
                                        <td> 1</td>

                                    </tr>


                                </tbody>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <AddSiteAddressModal show={addSiteAddressModal} handleClose={() => setAddSiteAddressModal(false)}/>


        </>
    );
};
export default SiteAddressTable;