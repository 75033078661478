import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

interface StatusChartProps {
  majorcount: number;
  minorcount: number;
}

const MyChart: React.FC<StatusChartProps> = ({ majorcount, minorcount }) => {
  useEffect(() => {
 

    const options = {
      series: [majorcount, minorcount],
      chart: {
        width: 480,
        type: 'pie',
      },
      labels: ['Major', 'Minor'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const chart = new ApexCharts(document.querySelector("#chart1"), options);
    chart.render();

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      chart.destroy();
    };
  }, [majorcount, minorcount]); // Dependency array ensures the effect runs when opennc or closenc change

  return (
    <div id="chart1"></div>
  );
};

export default MyChart;
