import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UAFLoader from '../../loader/UafLoader'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
interface FormState {
    training_name: string;
    location: string;
    desc: string;
    training_file: File | null,
    multi_date: Date[];
    trainer_name:string;
}
const AddTraining = () => {
    const navigate = useNavigate();
    const param = useParams();
    const trainingId = param?.id;
    const API_URL = process.env.REACT_APP_API_URL
    const [loader, setLoader] = useState<any>(false);
    const [trainingData, setTrainingData] = useState<any>();
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const [selectedDates, setSelectedDates] = useState([new Date()])
    console.log(selectedDates, "selectedDates");


    const [value, setValue] = useState<FormState>({
        training_name: '',
        location: '',
        desc: '',
        training_file: null,
        multi_date: [] as Date[],
        trainer_name: "",



    })

    const handlePdfIconClick = (url) => {
        setPdfUrl(url)
        setShowPdfModal(true)
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfUrl('') // Clear the PDF URL when the modal is closed
    }
    const handleTrainingFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            training_file: e.target.files ? e.target.files[0] : null,
        })
    }

    useEffect(() => {
        if (trainingData) {
            setValue({
                training_name: trainingData?.training_name || '',
                location: trainingData.location || '',
                desc: trainingData.desc || '',
                training_file: trainingData?.training_file || null,
                multi_date: trainingData?.multi_date || "",
                trainer_name: trainingData?.trainer_name || "",

            })
        }
    }, [trainingData])
    const fetchtrainingDAta = async () => {
        try {
            const response = await axios.get(`${API_URL}/blog/training-article/patch/${trainingId}`)
            setTrainingData(response.data)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        if (trainingId) {
            fetchtrainingDAta()
        } else {
            setValue({
                training_name: '',
                location: '',
                desc: '',
                training_file: null,
                multi_date: [] as Date[],
                trainer_name: '',

            })
        }
    }, [trainingId])
    // const handleDateChange = (dates) => {
    //     setSelectedDates(dates)
    //     setValue({...value, multi_date: dates})
    //   }
    const handleDateChange = (dates) => {
        setValue(prevState => ({
            ...prevState,
            multi_date: dates ? dates : "",

        }));
        setSelectedDates(dates)
    }
    const convertToDateObjects = (dateStrings) => {
        if (!dateStrings) return []
        return dateStrings.map(dateStr => new Date(dateStr))
    }


    useEffect(() => {
        if (trainingData) {
            if (trainingData?.multi_date) {
                const dateStrings = trainingData.multi_date.replace(/'/g, '"');
                const parsedDates = JSON.parse(dateStrings);
                const validDates = parsedDates.filter(date => date !== null && date !== "None");
                setSelectedDates(convertToDateObjects(validDates));
            } else {
                setSelectedDates([]);
            }
        }
    }, [trainingData]);




    const handelSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const { training_name, location, desc, training_file, multi_date, trainer_name } = value;
        if (!training_name || !location || !desc || !multi_date || !trainer_name) {
            toast.error('Please fill in all mandatory fields.');
            return;
        }

        try {
            setLoader(true);
            const formData = new FormData();
            formData.append('training_name', training_name);
            formData.append('location', location);
            formData.append('trainer_name', trainer_name);
            formData.append('desc', desc);
            if (training_file && training_file instanceof File) {
                formData.append('training_file', training_file);
            }
            formData.append('multi_date', JSON.stringify(selectedDates));
            let response;
            if (trainingId) {
                // Update API request (PUT/PATCH)
                response = await axios.put(`${API_URL}/blog/training-article/update/${trainingId}/`, formData);
            } else {
                // Create API request (POST)
                response = await axios.post(`${API_URL}/blog/training-article/create/`, formData);
            }

            if (response.status === 201 || response.status === 200) {
                toast.success(' submitted successfully');
                setLoader(false);
                navigate(`/crafted/traininglist`);
                setValue({
                    training_name: '',
                    location: '',
                    desc: '',
                    training_file: null,
                    multi_date: [] as Date[],
                    trainer_name: ""

                })
                setSelectedDates([new Date()])
            } else {
                setLoader(false);
                toast.error('Failed to submit . Please try again.');
            }
        } catch (error: any) {
            setLoader(false);
            console.error(error);
            toast.error('Something went wrong, please try again');
        }
    };

    const handleEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setValue((prevValue) => ({
            ...prevValue,
            desc: data,
        }));
    }
    return (

        <div id="v_blog_01">
            {loader ? <UAFLoader /> : null}

            <div className='row jusify-content-space-between'>
                <div className='col-md-6 d-flex align-items-center justify-content-start'>

                    <h2 className='mb-3 p-0 '>{trainingId ? "Edit Training" : "Add Training"}

                    </h2>
                </div>
            </div>



            <div className='card card-flush'>

                <div className='card-body pt-5'>

                    <form

                        onSubmit={handelSubmit}
                    >
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Title</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-solid'
                                            placeholder='Enter Training Title'
                                            name="training_name"
                                            value={value?.training_name}
                                            onChange={(e) => setValue({ ...value, training_name: e.target.value })}
                                        />
                                    </div>

                                </div>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Training Dates</label>

                                        <ReactDatePicker
                                            placeholderText='Select Dates'
                                            className='uaf-datepicker'
                                            selectedDates={selectedDates}
                                            selectsMultiple
                                            onChange={handleDateChange}
                                            shouldCloseOnSelect={false}
                                            disabledKeyboardNavigation
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </div>

                                </div>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Trainer'(s) Name</label>
                                        <textarea
                                            rows={5}
                                            className='form-control form-control-solid'
                                            placeholder='Enter Trainer(s) Name'
                                            name="trainer_name"
                                            value={value?.trainer_name}
                                            onChange={(e) => setValue({ ...value, trainer_name: e.target.value })}
                                        />
                                    </div>

                                </div>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Location</label>
                                        <textarea
                                            rows={5}
                                            className='form-control form-control-solid'
                                            placeholder=''
                                            name="location"
                                            value={value?.location}
                                            onChange={(e) => setValue({ ...value, location: e.target.value })}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">

                                            <label
                                                htmlFor='training_file'

                                                className={
                                                    value.training_file ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                }
                                            >
                                                {value.training_file ? 'Uploaded' : 'Upload Doc'}
                                            </label>
                                            <input
                                                type='file'
                                                id='training_file'
                                                style={{ display: 'none' }}
                                                onChange={handleTrainingFile}
                                            />

                                        </div>
                                        {value.training_file ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_file)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className=" fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'> Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={value.desc}
                                        onChange={handleEditorChange}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='mb-5'>

                            <button type='submit' className='btn btn-primary'>
                                {trainingId ? "Update" : "Submit"}

                                <i className='fa fa-angle-double-right' aria-hidden='true' />

                            </button>
                        </div>
                    </form>
                </div>
            </div >
            <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddTraining