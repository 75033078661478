import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select, {SingleValue} from 'react-select'
import ReactDatePicker from 'react-datepicker'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  TablesWidget10,
} from '../../../../_metronic/partials/widgets'
import {ListsWidgetCab} from '../../../../_metronic/partials/widgets/lists/ListWidgetCab'
import CertificateDashboard from '../component/CertificateDashboard'
import moment from 'moment'
import CertificateGraph from '../component/CertificateGraph'
import CertificateCriTable from '../component/CertificateCriTable'
import {RuntimeModule} from 'webpack'
import CriticalTable from '../component/CriticalTable'
import {usePermissions} from '../../../permission_component/PermissionContext'
const BASE_URL = process.env.REACT_APP_API_URL
interface CABType {
  value: string
  label: string
  id: string
}
interface CabInterface {
  id: string
  cab_name: string
  cab_id: string
  cab_company: string
  assessment_number: string
}
interface CABNCInterface {
  id: string
  cab_name: string
  category_name: string
  ncr_status_name: string
  cab_id: string
  assessment_number: string
  cab_company: string
}
interface CountInterface {
  cab_client: string
  historical_cab: string
  new_client: string
  cab_hold: string
  cab_withdraw: string
  cab_suspended: string
  cab_visible: string
  cab_active: string
}
interface ProgrammeData {
  id: string
  name: string
  description: string
}
const CABDashboard = () => {
  const {assessorPermission, cabPermission, superUserPermission, updatePermissions} =
    usePermissions()
  console.log(cabPermission, 'cabPermission')

  const userId = localStorage.getItem('current_user_id')
  console.log(userId, 'userId')

  const [NCData, setNCData] = useState<CabInterface[]>([])
  const [CABCount, setCABCount] = useState<CountInterface>()
  console.log(CABCount, 'CABCount')

  const [selectedCAB, setSelectedCAB] = useState<SingleValue<CABType>>(null)
  const [allOptionValue, setAllOptionValue] = useState<SingleValue<CABType>>(null)

  const [apiData, setApiData] = useState<CABNCInterface[]>([])
  const [showGraph, setshowGraph] = useState(false)

  const [certificateDataList, setCertificateDataList] = useState<any>([])
  const [singleCertificateDataList, setSingleCertificateDataList] = useState<any>([])
  console.log(allOptionValue, 'allOptionValue')

  const [totalActive, setTotalActive] = useState<any>(0)
  const [totalSuspended, setTotalSuspended] = useState<any>(0)
  const [totalWithdrawn, setTotalWithdrawn] = useState<any>(0)
  const [programmes, setProgrammes] = useState<ProgrammeData[]>([])
  const [mergedData, setMergedData] = useState<any[]>([])

  const [reportData, setReportData] = useState<any>()
  // console.log(selectedCAB, 'this is my selected CAB')
  // console.log(NCData, 'NCData')
  // console.log(reportData, 'reportData')
  // console.log(programmes, 'programmes')
  // console.log(certificateDataList, 'certificateDataList')
  const CABoptions: CABType[] = NCData.map((nc) => ({
    value: nc.cab_id,
    label: nc.cab_company,
    id: nc.cab_id,
  }))
  const [CountryData, setCountryData] = useState<any[]>([])

  const [countryDataFull, setCountryDataFull] = useState<any[]>([])
  const [countData, setCountData] = useState<any>()
  console.log(countData, 'countData HELLLOOOO')
  const [cabUser, setCabUser] = useState<any[]>([])
  const [cabUserData, setCabUserData] = useState<any>([])

  const [filterCabData, setFilterCabData] = useState<any>()
  const [totalCountCountry, setTotalCountCountry] = useState(0)
  const [countryList, setCountryList] = useState<any>()
  console.log(countryList, 'countryList')
  const [countryDetails, setcountryDetais] = useState<any>({
    id: '',
    name: '',
  })

  const [cabCountTotal, setCabCountTotal] = useState<any>()
  console.log(cabCountTotal, 'this is cabcountotal')
  console.log(totalCountCountry, 'totalCountCountry')

  const fetchCountData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/certificate/total/count/data/?cab=${selectedCAB?.id}`
      )
      setCountData(response?.data)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }

  useEffect(() => {
    if (selectedCAB) {
      fetchCountData()
    }
  }, [selectedCAB])

  // get country list
  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/list/country/`)

      const allOption = {
        id: 'all',
        name: 'All',
      }

      const updatedCountryList = [allOption, ...response.data]

      // Set the state with the updated array
      setCountryList(updatedCountryList)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCountryData()
  }, [])
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/country/list/`)
      const certificates = response.data.certificates
      const total = certificates?.reduce((sum, certificate) => sum + certificate.total_count, 0)
      const certifi = certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 10)
      // const cabCount = await axios.get(`${BASE_URL}/account/cab/country/count/`);
      // setCabCountTotal(cabCount?.data)
      setTotalCountCountry(total)
      setCountryDataFull(certificates)
      setCountryData(certifi)
    } catch (error) {
      console.error('Error fetching assessment data:', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (cabPermission?.is_cab_verify === true) {
      // const data = countryDataFull?.filter(item => item?.cab == userId)
      // setCountryData(data)
      const fetchCABData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/certificate/country/list/?cab=${userId}`)
          setCountryData(response.data?.certificates) // Set the state with the response data
        } catch (error) {
          console.error('Error fetching CAB data:', error)
        }
      }

      fetchCABData()
    }
  }, [countryDataFull])

  useEffect(() => {
    if (selectedCAB) {
      // const data = countryDataFull?.filter((item) => item?.cab === selectedCAB.id);

      const fetchCABData = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/certificate/country/list/?cab=${selectedCAB.id}`
          )
          setCountryData(response.data?.certificates)
        } catch (error) {
          console.error('Error fetching CAB data:', error)
        }
      }

      fetchCABData()
    }
  }, [selectedCAB])
  // useEffect(() => {
  //   if (selectedCAB) {
  //     const data = countryDataFull?.filter(item => item?.cab == selectedCAB.id)
  //     setCountryData(data)
  //   }
  // }, [selectedCAB])

  const fetchCertificateData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/programme/list/`)


      const responses = await axios.get(`${BASE_URL}/global/programme/list/`)
      const programData = responses.data
      setProgrammes(responses?.data)


      const data = response.data?.certificates
      let mergedData: any = []
      programData.forEach((program) => {
        const certificateItem = data.find((item) => item.programme === program.id) || {
          programme: program.id,
          programme__name: program.name,
          active: 0,
          suspended: 0,
          withdrawn: 0,
        }
        mergedData.push(certificateItem)
      })
      console.log(mergedData, 'this is our merged Data')

      setCertificateDataList(mergedData)
      
      let activeSum = 0
      let suspendedSum = 0
      let withdrawnSum = 0
      mergedData.forEach((item) => {
        activeSum += item.active
        suspendedSum += item.suspended
        withdrawnSum += item.withdrawn
      })
      setTotalActive(activeSum)
      setTotalSuspended(suspendedSum)
      setTotalWithdrawn(withdrawnSum)
      setReportData([])
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCertificateData()
  }, [])
  useEffect(() => {
    axios
      .get(`${BASE_URL}/cab/assessment/id/`)
      .then((response) => {
        // Filter unique cab_id
        const uniqueNCData = response.data.filter(
          (item, index, self) => index === self.findIndex((t) => t.cab_id === item.cab_id)
        )

        // Create the "All" option object
        const allOption = {
          id: 'all',
          assessment_number: 'All',
          cab_name: 'All',
          cab_id: 'all',
          cab_company: 'All',
        }

        // Add the "All" option to the beginning of the array
        const updatedNCData = [allOption, ...uniqueNCData]

        // Set the state with the updated array
        setNCData(updatedNCData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/cab/count/`)
      .then((response) => {
        setCABCount(response.data)
        setCabCountTotal(response.data?.verify_cab)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const handleCABChange = async (selectedOption: SingleValue<CABType>) => {
    console.log(selectedOption, 'selectedOption')
    if (selectedOption?.value === 'all') {
      fetchCertificateData()
      fetchData()
      setAllOptionValue(selectedOption)
      setCountData('')

      return
    }
    setAllOptionValue(null)
    setSelectedCAB(selectedOption)
    setshowGraph(true)
  }
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [dates, setDates] = useState({fromDate: new Date(), toDate: new Date()})

  const [filterDataTable, setFilterDataByTable] = useState<any>({
    cabName: '',
    scheme: '',
    startDate: '',
    endDate: '',
  })
  const {startDate, endDate} = filterDataTable
  console.log(dates, 'ksjkssdbhbvhds')
  const handleChange = (filter) => {
    setSelectedFilter(filter)
    let fromDate = new Date()
    const toDate = new Date()
    switch (filter) {
      case 'Last 1 Month':
        fromDate = moment().subtract(1, 'months').toDate()
        break
      case 'Last 3 Months':
        fromDate = moment().subtract(3, 'months').toDate()
        break
      case 'Last 6 Months':
        fromDate = moment().subtract(6, 'months').toDate()
        break
      case 'Last 1 Year':
        fromDate = moment().subtract(1, 'years').toDate()
        break
      case 'All':
      default:
        fromDate = new Date()
    }
    setDates({fromDate, toDate})
  }

  useEffect(() => {
    const fetchProgrammes = async () => {
      try {
        const responses = await axios.get(`${BASE_URL}/global/programme/list/`)
        const programData = responses.data
        setProgrammes(programData)
      } catch (error) {
        console.error('Error fetching programmes: ', error)
      }
    }

    fetchProgrammes()
  }, [])

  useEffect(() => {
    const fetchCertificates = async () => {
      if (selectedCAB || dates) {
        let query = ''
        if (selectedCAB) {
          query += `cab=${selectedCAB.id}`
        }
        if (
          dates &&
          (new Date(dates.fromDate).getFullYear() !== new Date().getFullYear() ||
            new Date(dates.fromDate).getMonth() !== new Date().getMonth() ||
            new Date(dates.fromDate).getDate() !== new Date().getDate())
        ) {
          query += `${selectedCAB ? '&' : ''}start_date=${moment(dates.fromDate).format(
            'YYYY-MM-DD HH:mm:ss.SSS[Z]'
          )}&end_date=${moment(dates.toDate).format('YYYY-MM-DD HH:mm:ss.SSS[Z]')}`
        }

        try {
          const response = await axios.get<{certificates: any[]}>(
            `${BASE_URL}/certificate/programme/list/?${query}`
          )
          const data = response.data.certificates
          setCertificateDataList(data)
        } catch (error) {
          console.error('Error fetching certificates: ', error)
        }
      }
    }

    fetchCertificates()
  }, [selectedCAB, dates])
  useEffect(() => {
    const fetchSingleCertificates = async () => {
      try {
        const response = await axios.get<{certificates: any[]}>(
          `${BASE_URL}/certificate/programme/list/?cab=${userId}`
        )
        const data = response.data.certificates
        setCertificateDataList(data)
      } catch (error) {
        console.error('Error fetching certificates: ', error)
      }
    }

    if (cabPermission?.is_cab_verify === true) {
      fetchSingleCertificates()
    }
  }, [cabPermission])

  useEffect(() => {
    const fetchReportData = async () => {
      if (selectedCAB) {
        try {
          const responseReport = await axios.get(
            `${BASE_URL}/certificate/scheme/list/${selectedCAB.id}`
          )
          setReportData(responseReport.data.certificates)
        } catch (error) {
          console.error('Error fetching report data: ', error)
        }
      }
    }

    fetchReportData()
  }, [selectedCAB])

  useEffect(() => {
    const mergeData = () => {
      if (certificateDataList.length && programmes.length) {
        const tempMergedData: any[] = programmes.map((program) => {
          const certificateItem = certificateDataList.find(
            (item) => item.programme === program.id
          ) || {
            programme: program.id,
            programme__name: program.name,
            active: 0,
            suspended: 0,
            withdrawn: 0,
          }
          return certificateItem
        })

        setMergedData(tempMergedData)

        // Calculate totals
        const activeSum = tempMergedData.reduce((sum, item) => sum + item.active, 0)
        const suspendedSum = tempMergedData.reduce((sum, item) => sum + item.suspended, 0)
        const withdrawnSum = tempMergedData.reduce((sum, item) => sum + item.withdrawn, 0)

        setTotalActive(activeSum)
        setTotalSuspended(suspendedSum)
        setTotalWithdrawn(withdrawnSum)
      }
    }

    mergeData()
  }, [certificateDataList, programmes])

  const handleCountryChange = async (e) => {
    if (e.value === 'all') {
      setcountryDetais({...countryDetails, id: e?.value, name: e?.label})
      const cabCount = await axios.get(`${BASE_URL}/account/cab/count/`)
      setCabCountTotal(cabCount?.data?.verify_cab)
      // setCabCountTotal(totalCabCount)
      return
    }
    const country = await axios.get(`${BASE_URL}/account/cab/country/count/?country=${e?.value}`)
    setcountryDetais({...countryDetails, id: e?.value, name: e?.label})
    setCabCountTotal(country?.data?.cab_counts?.[0]?.total_count)
  }

  useEffect(() => {
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)
      .then((response) => {
        console.log(response.data, 'helloosss')
        const data = response.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        }))
        setCabUser(data)
        setCabUserData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.active_count : totalActive}
                    {/* {totalActive} */}
                  </span>
                </div>
                <span className='pt-3 fw-semibold fs-1hx'>Active</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.suspended_count : totalSuspended}
                    {/* {totalSuspended} */}
                  </span>
                </div>
                <span className=' pt-3 fw-semibold fs-1hx'>Suspended</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-2 mb-xl-2'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.withdrawn_count : totalWithdrawn}
                    {/* {totalWithdrawn} */}
                  </span>
                </div>
                <span className=' pt-3 fw-semibold fs-1hx'>Withdrawn</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.deleted_count : CABCount?.new_client}
                    {/* {CABCount?.new_client} */}
                  </span>
                </div>
                <span className=' pt-3 fw-semibold fs-1hx'>{countData ? 'Deleted' : 'New'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.hold_count : CABCount?.cab_hold}
                    {/* {CABCount?.new_client} */}
                  </span>
                </div>
                <span className=' pt-3 fw-semibold fs-1hx'>
                  {countData ? 'Re-Accreditation' : 'Updated'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
          <div className='card card-flush mb-5 mb-xl-5'>
            <div className='card-header pt-5'>
              <div className='card-title d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <span className='fs-2hx fw-bold  me-2 lh-1 ls-n2' style={{color: '#136ba1'}}>
                    {countData ? countData?.country_count : countryDataFull.length}
                    {/* {CountryData.length} */}
                  </span>
                </div>
                <span className=' pt-3 fw-semibold fs-1hx'>Countries</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {superUserPermission?.is_superuser === true && (
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='mb-5 col-lg-6 mt-5' style={{color: '#136ba1'}}>
            <Select
              className='cab-control'
              value={allOptionValue || selectedCAB}
              onChange={handleCABChange}
              options={CABoptions}
              placeholder='CAB Organisation'
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#136ba1',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#136ba1',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#136ba1' : 'white',
                  color: state.isSelected ? 'white' : '#136ba1',
                  '&:hover': {
                    backgroundColor: '#136ba1',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>

          {/* <div className='mb-5 col-lg-3 mt-5'>
            <DropdownButton id="date-filter" title={`Filter by Date: ${selectedFilter}`}>
              <Dropdown.Item onClick={() => handleChange('All')}>All</Dropdown.Item>
              <Dropdown.Item onClick={() => handleChange('Last 1 Month')}>Last 1 Month</Dropdown.Item>
              <Dropdown.Item onClick={() => handleChange('Last 3 Months')}>Last 3 Months</Dropdown.Item>
              <Dropdown.Item onClick={() => handleChange('Last 6 Months')}>Last 6 Months</Dropdown.Item>
              <Dropdown.Item onClick={() => handleChange('Last 1 Year')}>Last 1 Year</Dropdown.Item>
            </DropdownButton>
          </div> */}
          <div className='mb-5 col-lg-3 mt-5' style={{color: '#136ba1'}}>
            <Select
              className='cab-control'
              value={
                countryDetails?.id ? {value: countryDetails?.id, label: countryDetails?.name} : null
              }
              onChange={(e) => handleCountryChange(e)}
              options={countryList?.map((item) => ({value: item?.id, label: item?.name}))}
              placeholder='Country '
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#136ba1',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#136ba1',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#136ba1' : 'white',
                  color: state.isSelected ? 'white' : '#136ba1',
                  '&:hover': {
                    backgroundColor: '#136ba1',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>
          <div className='mb-5 col-lg-3 mt-5' style={{display: 'flex', alignItems: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                CAB Count: {cabCountTotal ? cabCountTotal : '0'}
              </span>
            </h3>
          </div>
        </div>
      )}

      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-6 col-lg-6'>
          <CertificateDashboard
            certificateDataList={certificateDataList}
            totalActive={totalActive}
            totalSuspended={totalSuspended}
            totalWithdrawn={totalWithdrawn}
          />
        </div>
        {/* <div className='col-xxl-6 col-lg-6'>
          <CertificateGraph selectedCAB={selectedCAB?.id} />
        </div> */}
        {CountryData?.length > 0 ? (
          <div className='col-xxl-6 col-lg-6'>
            <ListsWidget3 className='card-xxl-stretch mb-xl-3' CountryData={CountryData} />
          </div>
        ) : null}
      </div>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-6 col-lg-6'>
          {/* {reportData?.length ?
            <CertificateCriTable reportData={reportData} />
            : */}
          <CriticalTable />

          {/* } */}
        </div>
      </div>
    </>
  )
}

export default CABDashboard
