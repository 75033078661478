import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { UserDataType } from '../../AssessorInterface'
const BASE_URL = process.env.REACT_APP_API_URL

interface ProgrammeData {
  id: string
  name: string
  description: string
}

interface SchemeData {
  id: string
  name: string
  programme: string
  description: string
}

interface StandardData {
  id: string
  name: string
  scheme: string
  description: string
}

interface ClusterData {
  id: string
  name: string
  standard: string
  description: string
}
interface IAFCriticalData {
  id: string
  name: string
  scheme: string
  iaf_sector_doc: File | null
}

const CheckboxHierarchy: React.FC<{
  userIAFData: UserDataType | undefined
  setUserIAFData: React.Dispatch<React.SetStateAction<UserDataType | undefined>>
  selelctedIAFCriticalValue: IAFCriticalData[]
  selectedPrograms: string[]
  selectedSchemes: { [key: string]: string[] }
  selectedStandardList: string[]
  selectedSchemesList: string[]
  selectedClusterList: string[]
  selectedStandards: { [key: string]: { [key: string]: string[] } }
  setSelelctedIAFCriticalValue: React.Dispatch<React.SetStateAction<IAFCriticalData[]>>
  selectedClusters: { [key: string]: { [key: string]: string[] } }
  setSelectedPrograms: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedSchemes: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>
  setSelectedStandards: React.Dispatch<
    React.SetStateAction<{ [key: string]: { [key: string]: string[] } }>
  >
  setSelectedClusters: React.Dispatch<
    React.SetStateAction<{ [key: string]: { [key: string]: string[] } }>
  >
  setSelectedSchemesList: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedStandardList: React.Dispatch<React.SetStateAction<string[]>>
  setSelectedClusterList: React.Dispatch<React.SetStateAction<string[]>>
}> = ({
  setSelelctedIAFCriticalValue,
  selelctedIAFCriticalValue,
  selectedPrograms,
  selectedSchemes,
  selectedStandardList,
  selectedSchemesList,
  selectedClusterList,
  selectedStandards,
  selectedClusters,
  userIAFData,
  setSelectedPrograms,
  setSelectedSchemes,
  setSelectedStandards,
  setSelectedClusters,
  setSelectedSchemesList,
  setSelectedStandardList,
  setSelectedClusterList,
  setUserIAFData
}) => {
    const [programmes, setProgrammes] = useState<ProgrammeData[]>([])
    const [schemes, setSchemes] = useState<SchemeData[]>([])
    const [standards, setStandards] = useState<StandardData[]>([])
    const [clusters, setClusters] = useState<ClusterData[]>([])
    const [IAFCritical, setIAFCritical] = useState<IAFCriticalData[]>([])
    const [expandedProgramIds, setExpandedProgramIds] = useState<string[]>([])
    console.log(userIAFData, "userIAFData");

    const handleFileUpload = (itemId: string, clusterId: string, file: File) => {
      setSelelctedIAFCriticalValue((prevState) => ({
        ...prevState,
        [clusterId]: {
          ...prevState[clusterId],
          [itemId]: {
            ...(prevState[clusterId]?.[itemId] || {}),
            checked: true,
            file: file,
          },
        },
      }))
    }

    useEffect(() => {
      const fetchProgrammes = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/programme/list/`)
          setProgrammes(response.data)
        } catch (error) {
          console.error('Error fetching programme data:', error)
        }
      }
      fetchProgrammes()
    }, [])

    useEffect(() => {
      const fetchSchemes = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/scheme/list/`)
          setSchemes(response.data)
        } catch (error) {
          console.error('Error fetching scheme data:', error)
        }
      }
      fetchSchemes()
    }, [])

    useEffect(() => {
      const fetchStandards = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/standard/list/`)
          setStandards(response.data)
        } catch (error) {
          console.error('Error fetching standard data:', error)
        }
      }
      fetchStandards()
    }, [])

    useEffect(() => {
      const fetchClusters = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/cluster/list/`)
          setClusters(response.data)
        } catch (error) {
          console.error('Error fetching cluster data:', error)
        }
      }
      fetchClusters()
    }, [])

    const fetchIAF = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/global/critical/iaf-sector/filter/?scheme=${id}`);
        const newData = response.data.filter((item: IAFCriticalData) => !IAFCritical.some((i) => i.id === item.id));
        setIAFCritical((prev) => [...prev, ...newData]);
        // Return the response data
        console.log(response.data, "response.data");
        // setIAFCritical(response.data)
      } catch (error) {
        console.error('Error fetching cluster data:', error);
      }
    };

    const handleSchemeSelection = (programId: string, schemeId: string) => {
      setSelectedSchemes((prevState) => {
        const programSelectedSchemes = prevState[programId] || []
        const index = programSelectedSchemes.indexOf(schemeId)
        const updatedSchemes =
          index === -1
            ? [...programSelectedSchemes, schemeId]
            : programSelectedSchemes.filter((id) => id !== schemeId)

        // Update selectedSchemesList
        const updatedSelectedSchemesList = updatedSchemes.includes(schemeId)
          ? [...selectedSchemesList, schemeId]
          : selectedSchemesList.filter((id) => id !== schemeId)

        setSelectedSchemesList(updatedSelectedSchemesList)

        return {
          ...prevState,
          [programId]: updatedSchemes,
        }
      })
    }

    const handleStandardSelection = (programId: string, schemeId: string, standardId: string) => {
      setSelectedStandards((prevState) => {
        const programSelectedStandards = { ...prevState[programId] } || {}
        const schemeSelectedStandards = programSelectedStandards[schemeId] || []
        const index = schemeSelectedStandards.indexOf(standardId)
        const updatedSchemeStandards =
          index === -1
            ? [...schemeSelectedStandards, standardId]
            : schemeSelectedStandards.filter((id) => id !== standardId)

        // Update selectedStandardList
        const updatedSelectedStandardList = updatedSchemeStandards.includes(standardId)
          ? [...selectedStandardList, standardId]
          : selectedStandardList.filter((id) => id !== standardId)

        setSelectedStandardList(updatedSelectedStandardList)

        return {
          ...prevState,
          [programId]: {
            ...programSelectedStandards,
            [schemeId]: updatedSchemeStandards,
          },
        }
      })
    }
    const handleClusterSelection = (
      programId: string,
      schemeId: string,
      standardId: string,
      clusterId: string
    ) => {
      setSelectedClusters((prevState) => {
        const programSelectedClusters = { ...prevState[programId] } || {}
        const schemeSelectedClusters = programSelectedClusters[schemeId] || {}
        const standardSelectedClusters = schemeSelectedClusters[standardId] || []

        const updatedClusterIds = standardSelectedClusters.includes(clusterId)
          ? standardSelectedClusters.filter((id) => id !== clusterId)
          : [...standardSelectedClusters, clusterId]

        // Update selectedClusterList
        const updatedSelectedClusterList = updatedClusterIds.includes(clusterId)
          ? [...selectedClusterList, clusterId]
          : selectedClusterList.filter((id) => id !== clusterId)

        setSelectedClusterList(updatedSelectedClusterList)

        return {
          ...prevState,
          [programId]: {
            ...programSelectedClusters,
            [schemeId]: {
              ...schemeSelectedClusters,
              [standardId]: updatedClusterIds,
            },
          },
        }
      })

      // Toggle expandedProgramIds to show/hide IAF items
      setExpandedProgramIds((prevExpandedProgramIds) => {
        if (prevExpandedProgramIds.includes(clusterId)) {
          return prevExpandedProgramIds.filter((id) => id !== clusterId)
        } else {
          return [...prevExpandedProgramIds, clusterId]
        }
      })

      // Fetch IAF data for the scheme
      fetchIAF(schemeId)
    }

    const handleIAFCheckboxChange = (itemId, clusterId, type) => (e) => {
      const { checked, files } = e.target

      // If the type is 'file' and a file is selected, handle the file upload
      if (type === 'file' && files && files.length > 0) {
        handleFileUpload(itemId, clusterId, files[0])
        return // Exit early after handling file upload
      }

      // Update the state based on checkbox change
      setSelelctedIAFCriticalValue((prevState) => {
        const updatedState = {
          ...prevState,
          [clusterId]: {
            ...prevState[clusterId],
            [itemId]: {
              ...(prevState[clusterId]?.[itemId] || {}),
              checked: checked,
            },
          },
        }

        // If the checkbox is unchecked, remove the item from the state
        if (!checked) {
          delete updatedState[clusterId][itemId]
        }

        return updatedState
      })
    }

    const toggleProgram = (programId: string) => {
      setSelectedPrograms((prevSelectedPrograms) => {
        const isSelected = prevSelectedPrograms.includes(programId)
        if (isSelected) {
          return prevSelectedPrograms.filter((id) => id !== programId)
        } else {
          return [...prevSelectedPrograms, programId]
        }
      })
      // Toggle the expanded state of the program
      setExpandedProgramIds((prevExpandedProgramIds) => {
        if (prevExpandedProgramIds.includes(programId)) {
          // Collapse the item
          return prevExpandedProgramIds.filter((id) => id !== programId)
        } else {
          // Expand the item
          return [...prevExpandedProgramIds, programId]
        }
      })
    }
    useEffect(() => {
      if (userIAFData) {
        setSelectedPrograms(userIAFData?.user_programmes)
      }

    }, [userIAFData])
    return (
      <div className='card mb-5'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              Apply for<span style={{ color: 'red' }}>*</span>
            </h3>
          </div>
        </div>
        <div className='card-body '>
          <div className='row py-3'>
            <div className='col-lg-12 col-md-12 col-sm-12' style={{ padding: '5px' }}>
              {programmes.map((programme) => (
                <div key={programme.id} className='mb-3' style={{ borderBottom: '1px dotted #b1b1b1' }}>
                  <div onClick={() => toggleProgram(programme.id)} style={{ marginBottom: '10px' }}>
                    <input
                      className='form-check-input'
                      style={{ fontWeight: '500', fontSize: '16px' }}
                      type='checkbox'
                      checked={selectedPrograms.includes(programme.id)}
                      onChange={() => { }}
                    />
                    <label className='form-check-label' style={{ marginLeft: '10px' }}>
                      {programme.name}
                    </label>
                  </div>
                  {selectedPrograms.includes(programme.id) && (
                    <div className='ml-4' style={{ paddingLeft: '35px' }}>
                      {schemes
                        .filter((scheme) => scheme.programme === programme.id)
                        .map((scheme) => (
                          <div key={scheme.id} className='mb-3' style={{ marginBottom: '10px' }}>
                            <input
                              className='form-check-input'
                              style={{ fontWeight: '500', fontSize: '16px' }}
                              type='checkbox'
                              checked={selectedSchemes[programme.id]?.includes(scheme.id)}
                              onChange={() => handleSchemeSelection(programme.id, scheme.id)}
                            />
                            <label
                              className='form-check-label'
                              style={{ marginLeft: '10px', marginBottom: '10px' }}
                            >
                              {scheme.name}
                            </label>
                            {selectedSchemes[programme.id]?.includes(scheme.id) && (
                              <div className='ml-4' style={{ paddingLeft: '55px' }}>
                                {standards
                                  .filter((standard) => standard.scheme === scheme.id)
                                  .map((standard) => (
                                    <div key={standard.id} className='mb-3'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        style={{ fontWeight: '500', fontSize: '16px' }}
                                        checked={selectedStandards[programme.id]?.[
                                          scheme.id
                                        ]?.includes(standard.id)}
                                        onChange={() =>
                                          handleStandardSelection(
                                            programme.id,
                                            scheme.id,
                                            standard.id
                                          )
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        style={{ marginLeft: '10px' }}
                                      >
                                        {standard.name}
                                      </label>
                                      {selectedStandards[programme.id]?.[scheme.id]?.includes(
                                        standard.id
                                      ) && (
                                          <div className='ml-4' style={{ paddingLeft: '65px' }}>
                                            {clusters
                                              .filter((cluster) => cluster.standard === standard.id)
                                              .map((cluster) => (
                                                <div key={cluster.id} className='mb-3'>
                                                  <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    style={{ fontWeight: '500', fontSize: '16px' }}
                                                    checked={selectedClusters[programme.id]?.[
                                                      scheme.id
                                                    ]?.[standard.id]?.includes(cluster.id)}
                                                    onChange={() =>
                                                      handleClusterSelection(
                                                        programme.id,
                                                        scheme.id,
                                                        standard.id,
                                                        cluster.id
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    style={{ marginLeft: '10px' }}
                                                  >
                                                    {cluster.name}
                                                  </label>


                                                  {/* {expandedProgramIds.includes(cluster.id) && (
                                                    <>
                                                      <h5 style={{ marginLeft: '20px' }}>Any One of the Following<span style={{ color: 'red' }}>*</span></h5>
                                                      <div className='px-10' style={{ marginLeft: '20px' }}>
                                                        {IAFCritical.filter(
                                                          (item) => item.scheme === scheme.id
                                                        ).map((item) => (


                                                          <div
                                                            key={item.id}
                                                            className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3'
                                                          >
                                                            <div className='d-flex'>

                                                              <label
                                                                className=''
                                                                style={{ padding: '0 0 0 16px' }}
                                                                htmlFor={item.name}
                                                              ></label>
                                                              <label
                                                                className=''
                                                                style={{ padding: '13px 0 0 0' }}
                                                                htmlFor={item.name}
                                                              >
                                                                {item.name}
                                                              </label>
                                                            </div>
                                                            <div className="justify-content-end d-flex" style={{ marginLeft: 'auto' }}>

                                                              <div>


                                                                {selelctedIAFCriticalValue[cluster.id]?.[item.id]?.file ? (
                                                                  <a>
                                                                    <i
                                                                      onClick={() => {
                                                                        setSelelctedIAFCriticalValue((prevState) => {
                                                                          const updatedState = { ...prevState };
                                                                          delete updatedState[cluster.id][item.id];
                                                                          return updatedState;
                                                                        });
                                                                      }}

                                                                      className="fa fa-trash" style={{ padding: "8px" }} aria-hidden="true"></i>
                                                                  </a>
                                                                ) : (
                                                                  <input
                                                                    type="file"
                                                                    id={item.name}
                                                                    name={item.name}
                                                                    checked={selelctedIAFCriticalValue[cluster.id]?.[item.id]?.checked || false}
                                                                    onChange={handleIAFCheckboxChange(item.id, cluster.id, 'file')}
                                                                  />
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>

                                                        ))}
                                                      </div>
                                                    </>
                                                  )} */}
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

export default CheckboxHierarchy
