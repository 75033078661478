import React, { useRef, useEffect } from 'react';
import SignaturePad from 'signature_pad';
const SignaturePadComponent=({ setSignatureImageData }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      signaturePadRef.current = new SignaturePad(canvas);

    }
  }, []);
  const clear = () => {
    signaturePadRef.current?.clear();
    setSignatureImageData("")
  };
  const save = () => {
    if (signaturePadRef.current) {
      const dataUrl = signaturePadRef.current.toDataURL('image/png');
      setSignatureImageData(dataUrl)
      // You can also save the dataUrl to a server or localStorage here
    }
  };
  return (
    <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={200}
        style={{ border: '1px solid #000', borderRadius: '5px' }}
      />
      <button onClick={clear}>Clear</button>
      <button onClick={save}>Save</button>
    </div>
  );
};
export default SignaturePadComponent;