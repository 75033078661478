import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
// import { ListsWidgetCab } from '../../../_metronic/partials/widgets/lists/ListWidgetCab'

// const DashboardPage: FC = () => (
//   <>

//     <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
//       {/* begin::Col */}
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>
//       <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-5'>
//         <CardsWidget17 className=' mb-5 mb-xl-5' />
//       </div>

//     </div>

//     <div className='row gx-5 gx-xl-10'>
//       <div className='col-xxl-6 mb-5 mb-xl-10'>
//         {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
//       </div>

//     </div>

//     {/* begin::Row */}
//     <div className='row gy-5 gx-xl-8'>
//       <div className='col-xxl-4'>
//         <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
//       </div>
//       <div className='col-xl-8'>
//         <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
//       </div>
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xl-4'>
//         <ListsWidget2 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidgetCab className='card-xl-stretch mb-xl-8' />
//       </div>
//       {/* <div className='col-xl-4'>
//         <ListsWidget6 className='card-xl-stretch mb-xl-8' />
//       </div> */}
//       <div className='col-xl-4'>
//         <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
//         {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
//       </div>
//     </div>
//     {/* end::Row */}

//     <div className='row g-5 gx-xxl-8'>
//       <div className='col-xxl-4'>
//         <MixedWidget8
//           className='card-xxl-stretch mb-xl-3'
//           chartColor='success'
//           chartHeight='150px'
//         />
//       </div>
//       <div className='col-xxl-8'>
//         <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
//       </div>
//     </div>
//   </>
// )

// const DashboardWrapper: FC = () => {
//   const intl = useIntl()
//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
//       <DashboardPage />
//     </>
//   )
// }

// export {DashboardWrapper}

import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Select, { SingleValue } from 'react-select'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import NCDashboard from './NC/NCDashboard'
import CABDashboard from './CAB/CABDashboard'

import NCGraph from '../../modules/nc-mangement/NCGraph'
import CertificateDashboard from './component/CertificateDashboard'
import ReportDashboard from './Report/ReportDashboard'
import { usePermissions } from '../../permission_component/PermissionContext'
import SingleCabDash from './SingleCabDash'
import SingleAssDash from './CAB/SingleAssDash'
interface OptionType {
  value: string
  label: string
}
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'ARC Reports',
    path: '/accreditation/cab/report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper: React.FC = () => {
  const [tabValue, setTabValue] = useState('1')

  const { assessorPermission, cabPermission, superUserPermission, updatePermissions } = usePermissions();

  const options: OptionType[] = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
  ]
  const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(null)
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }



  return (
    <div id="dashboard_1">
      <PageTitle>Dashboard</PageTitle>

      {cabPermission?.is_cab_verify === true && (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label='lab API tabs example'>
                <Tab label='Dashboard' value='1' />
                {/* <Tab label='NC Dashboard' value='2' />
         <Tab label='Report Dashboard' value='3' /> */}
                {/* <Tab label='CAB Dashboard' value='3' />
         <Tab label='Certificate Dashboard' value='4' /> */}
              </TabList>
            </Box>

            <TabPanel value='1'>

              <SingleCabDash />

            </TabPanel>
            {/* <TabPanel value='2'>
       <NCDashboard/>
     </TabPanel> */}
            {/* <TabPanel value='3'>
     </TabPanel> */}
            {/* <TabPanel value='3'>
       <ReportDashboard/>


     </TabPanel> */}

          </TabContext>
        </Box>
      )}

      {superUserPermission?.is_superuser === true && (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label='lab API tabs example'>
                <Tab label='Dashboard' value='1' />
                <Tab label='NC Dashboard' value='2' />
                <Tab label='Report Dashboard' value='3' />
                {/* <Tab label='CAB Dashboard' value='3' />
              <Tab label='Certificate Dashboard' value='4' /> */}
              </TabList>
            </Box>

            <TabPanel value='1'>

              <CABDashboard />

            </TabPanel>
            <TabPanel value='2'>
              <NCDashboard />
            </TabPanel>
            {/* <TabPanel value='3'>
          </TabPanel> */}
            <TabPanel value='3'>
              <ReportDashboard />


            </TabPanel>

          </TabContext>
        </Box>
      )}

      {assessorPermission?.is_verify_accessor === true && (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label='lab API tabs example'>
                <Tab label='Dashboard' value='1' />
                {/* <Tab label='NC Dashboard' value='2' />
                <Tab label='Report Dashboard' value='3' /> */}
                {/* <Tab label='CAB Dashboard' value='3' />
              <Tab label='Certificate Dashboard' value='4' /> */}
              </TabList>
            </Box>

            <TabPanel value='1'>

              <SingleAssDash />

            </TabPanel>
            {/* <TabPanel value='2'>
              <NCDashboard />
            </TabPanel> */}
            {/* <TabPanel value='3'>
          </TabPanel> */}
            {/* <TabPanel value='3'>
              <ReportDashboard />


            </TabPanel> */}

          </TabContext>
        </Box>
      )}
    </div>
  )
}

export { DashboardWrapper }
