import axios from 'axios';
import { AnyMxRecord } from 'dns';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { toast } from 'react-toastify';

const CabInitialFee = ({ selectedOption }) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    interface User {
        id: number;
        company: string;

    }
    const [cabUser, setCabUser] = useState<any>()
    const [cabId, setCabID] = useState<any>()
    const [certificateData, setCertificateData] = useState<any>([])
    console.log(certificateData, "certificateData");
    const [annualFee, setAnnualFee] = useState({
        iaf_1_1: "Application Fee- QMS, Application Fee- EMS, Application Fee- OHSMS",
        iaf_1_2: "",
        iaf_1_3: "",
        iaf_1_4: "",


        iaf_1_7: "Document Review Planning, Reviewing and Reporting",
        iaf_1_8: "",
        iaf_1_9: "",
        iaf_1_10: "",


        iaf_1_13: "Office Assessment for one Critical Location Required office duration should be 5 ",
        iaf_1_14: "",
        iaf_1_15: "",
        iaf_1_16: "",



        iaf_1_19: "Office Assessment Planning and Reporting for Team Leader only ",
        iaf_1_20: "",
        iaf_1_21: "",
        iaf_1_22: "",



        iaf_1_25: "Stage 1 Initial Witness assessment for IMS client (QMS;EMS;OHSMS) IAF code 20. ",
        iaf_1_26: "",
        iaf_1_27: "",
        iaf_1_28: "",


        iaf_1_31: "Stage 2 Initial for which Stage 01 Witness was conducted or Recertification Witness assessment for the same IMS client (QMS;EMS;OHSMS) IAF code 20.",
        iaf_1_32: "",
        iaf_1_33: "",
        iaf_1_34: "",


        iaf_1_37: "Stage 2 Initial or Recertification Witness assessment for an IMS (QMS;EMS) client in IAF Code 12.",
        iaf_1_38: "",
        iaf_1_39: "",
        iaf_1_40: "",


        iaf_1_43: "Stage 2 Initial or Recertification Witness assessment for an IMS (QMS;EMS;OHSMS) client in IAF Code 24. ",
        iaf_1_44: "",
        iaf_1_45: "",
        iaf_1_46: "",

        iaf_1_49: "Stage 2 Initial or Recertification Witness assessment for an IMS (QMS;EMS;OHSMS) client in IAF Code 24. ",
        iaf_1_50: "",
        iaf_1_51: "",
        iaf_1_52: "",

        iaf_1_55: "Each Witness planning and reporting ",
        iaf_1_56: "",
        iaf_1_57: "",
        iaf_1_58: "",

    });
    console.log(annualFee, "FEE");

    const [value, setValue] = useState<User[]>([]);
    console.log(cabUser, "cabUser")
    useEffect(() => {
        axios.get(`${BASE_URL}/account/approved/cab/user/`)
            .then(response => {
                const data = response.data?.map(item => ({
                    label: `${item?.company}`,
                    value: item?.id
                }))
                setValue(data)
            })
            .catch(error => { console.log(error); });
    }, []);
 const submitAnnualFee = async () => {
        try {
            const jsonData = JSON.stringify(annualFee);
            const response = await axios.post(`${BASE_URL}/payment/invoice/create/`, {
                cab: cabId,
                text: selectedOption,
                invoice: jsonData
            });
            if (response.status === 200 || response.status === 201) {
                toast.success('Initial fee generated successfully.');
            } else {
                toast.error('Please try again.');
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='container'>
            <div className='row'>
                <div className="mb-5 col-6">
                    <label className="required form-label">CAB User</label>
                    <Select
                        id="select_user"
                        name="User"
                        options={value}
                        className='react-select-styled'
                        classNamePrefix='react-select'
                        value={cabUser}
                        onChange={(e) => {
                            setCabUser(e);
                            setCabID(e?.value);
                        }}

                    />
                </div>
                <div className="mb-5 col-6 px-10 py-5 ">
                    <h4 className='fw-bolder mb-2'>CAB: <span className="inline_master_head">{cabUser?.label}</span></h4>
                </div>
            </div>
            {cabId ? (
                <div className='py-3'>

                    {/* end::Table container */}
                  
                        {/* <label className='required form-label'>Date/s of Witness assessment/s </label> */}
                        <table className='fee_table table align-middle table-row-dashed fs-6 gy-5 ' id='kt_table_users'>
                            <thead >
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th className='w-250px pe-2 fs-7'>Type of Fees </th>
                                    <th className='w-250px pe-2 fs-7'>Manday Rate* Number of Mandays</th>
                                    <th className='w-250px pe-2 fs-7'>Total Amount in US$</th>
                                    <th className='w-250px pe-2 fs-7'>Remarks</th>
                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold' style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                <tr>
                                    <td>

                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_1: e?.target?.value })}
                                            value={annualFee.iaf_1_1.replace(/, /g, '\n')}
                                            readOnly

                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_2: e?.target?.value })}
                                            value={annualFee.iaf_1_2}

                                        />
                                    </td>
                                    <td> <textarea
                                        rows={5}
                                        name='uaf_requirement'
                                        onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_3: e?.target?.value })}
                                        value={annualFee.iaf_1_3}

                                    /></td>
                                    <td><textarea
                                        rows={5}
                                        name='uaf_requirement'
                                        onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_4: e?.target?.value })}
                                        value={annualFee?.iaf_1_4}

                                    /></td>

                                </tr>

                                <tr>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_7: e?.target?.value })}
                                            value={annualFee?.iaf_1_7}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_8: e?.target?.value })}
                                            value={annualFee?.iaf_1_8}
                                        />
                                    </div></td>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_9: e?.target?.value })}
                                            value={annualFee?.iaf_1_9}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <div className=''>
                                                <textarea

                                                    rows={5}
                                                    name='uaf_requirement'
                                                    onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_10: e?.target?.value })}
                                                    value={annualFee?.iaf_1_10}
                                                />
                                            </div>
                                        </div>
                                    </td>




                                </tr>


                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_13: e?.target?.value })}
                                            value={annualFee?.iaf_1_13}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_14: e?.target?.value })}
                                            value={annualFee?.iaf_1_14}

                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_15: e?.target?.value })}
                                            value={annualFee?.iaf_1_15}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_16: e?.target?.value })}
                                            value={annualFee?.iaf_1_16}
                                        />
                                    </div></td>


                                </tr>

                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_19: e?.target?.value })}
                                            value={annualFee?.iaf_1_19}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_20: e?.target?.value })}
                                            value={annualFee?.iaf_1_20}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_21: e?.target?.value })}
                                            value={annualFee?.iaf_1_21}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_22: e?.target?.value })}
                                            value={annualFee?.iaf_1_22}
                                        />
                                    </div></td>


                                </tr>

                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_25: e?.target?.value })}
                                            value={annualFee?.iaf_1_25}
                                            readOnly
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <div className=''>
                                                <textarea

                                                    rows={5}
                                                    name='uaf_requirement'
                                                    onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_26: e?.target?.value })}
                                                    value={annualFee?.iaf_1_26}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_27: e?.target?.value })}
                                            value={annualFee?.iaf_1_27}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_28: e?.target?.value })}
                                            value={annualFee?.iaf_1_28}
                                        />
                                    </div></td>



                                </tr>

                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_31: e?.target?.value })}
                                            value={annualFee?.iaf_1_31}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_32: e?.target?.value })}
                                            value={annualFee?.iaf_1_32}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_33: e?.target?.value })}
                                            value={annualFee?.iaf_1_33}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <textarea

                                                rows={5}
                                                name='uaf_requirement'
                                                onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_34: e?.target?.value })}
                                                value={annualFee?.iaf_1_34}
                                            />
                                        </div>
                                    </td>


                                </tr>

                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_37: e?.target?.value })}
                                            value={annualFee?.iaf_1_37}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_38: e?.target?.value })}
                                            value={annualFee?.iaf_1_38}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_39: e?.target?.value })}
                                            value={annualFee?.iaf_1_39}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <textarea

                                                rows={5}
                                                name='uaf_requirement'
                                                onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_40: e?.target?.value })}
                                                value={annualFee?.iaf_1_40}
                                            />
                                        </div>
                                    </td>


                                </tr>


                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_43: e?.target?.value })}
                                            value={annualFee?.iaf_1_43}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_44: e?.target?.value })}
                                            value={annualFee?.iaf_1_44}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_45: e?.target?.value })}
                                            value={annualFee?.iaf_1_45}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <textarea

                                                rows={5}
                                                name='uaf_requirement'
                                                onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_46: e?.target?.value })}
                                                value={annualFee?.iaf_1_46}
                                            />
                                        </div>
                                    </td>


                                </tr>



                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_49: e?.target?.value })}
                                            value={annualFee?.iaf_1_49}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_50: e?.target?.value })}
                                            value={annualFee?.iaf_1_50}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_51: e?.target?.value })}
                                            value={annualFee?.iaf_1_51}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <textarea

                                                rows={5}
                                                name='uaf_requirement'
                                                onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_52: e?.target?.value })}
                                                value={annualFee?.iaf_1_52}
                                            />
                                        </div>
                                    </td>


                                </tr>



                                <tr>

                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_55: e?.target?.value })}
                                            value={annualFee?.iaf_1_55}
                                            readOnly
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_56: e?.target?.value })}
                                            value={annualFee?.iaf_1_56}
                                        />
                                    </div></td>
                                    <td><div className='text-gray-800  mb-1'>
                                        <textarea
                                            rows={5}
                                            name='uaf_requirement'
                                            onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_57: e?.target?.value })}
                                            value={annualFee?.iaf_1_57}
                                        />
                                    </div></td>
                                    <td>
                                        <div className='text-gray-800 mb-1'>
                                            <textarea

                                                rows={5}
                                                name='uaf_requirement'
                                                onChange={(e) => setAnnualFee({ ...annualFee, iaf_1_58: e?.target?.value })}
                                                value={annualFee?.iaf_1_58}
                                            />
                                        </div>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                        <div className='text-gray-800  mb-1'>
                            <button className='btn btn-primary' onClick={submitAnnualFee}>Save </button>
                        </div>
                    </div>
                

            ) : null}

        </div>
    )
}

export default CabInitialFee
