import React, { useEffect, useState } from "react";
import Select from 'react-select'
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../../_metronic/helpers";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL

type Props = {
    show: boolean
    handleClose: () => void
    // assessmentData: any
    // urlId: any
    // selectedAssessment: any
    // getAssessmentPlannerData: any
    accessorList: any
    selectedAssessment: any
    urlId: any
    getAssessorDataByAssessment: any


}

const JobOrderPlanner = ({ show, handleClose, selectedAssessment, accessorList, urlId, getAssessorDataByAssessment }: Props) => {
    console.log(selectedAssessment, 'selectedAssessment')
    const options = [
        { value: 'option 1', label: 'Option 1' },
        { value: 'option 2', label: 'Option 2' },
        { value: 'option 3', label: 'Option 3' },
        { value: 'option 4', label: 'Option 4' },
        { value: 'option 5', label: 'Option 5' },
    ]
    const BASE_URL = process.env.REACT_APP_API_URL
    console.log(selectedAssessment, 'selectedAssessment');


    const [assessmentType, setAssessmentType] = useState<any>();
    const [assessorList, setAssessorList] = useState<any>([]);
    const [newAssessmentData, setNewAssessmentData] = useState<any>([]);

    console.log(newAssessmentData, "newAssessmentData");

    const [roleInAssessment, setRoleInAssessment] = useState<any>();
    console.log(assessorList, 'assessorList')
    const [userRole, setUserRole] = useState<any>([])

    console.log(roleInAssessment, 'roleInAssessment')
    console.log(userRole, 'userRole')
    useEffect(() => {
        if (assessorList) {
            const getFilterData = assessorList?.find((item) => item?.id == selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.id)
            console.log(getFilterData, 'getFilterData')
            setRoleInAssessment(getFilterData?.assign_role_dict)
        }

    }, [assessorList, selectedAssessment])
    const [assessmentList, setAssessmentList] = useState<any>([])

    console.log(roleInAssessment, 'roleInAssessment')
    console.log(assessmentList, 'assessmentList')
    const [createJobDetail, setCreateJobDetail] = useState({
        cab: "",
        assessor_planner: "",
        assessment: "",
        assessor: "",
        assessor_role: "",
        system: "",
        on_off_site: "",
        total_manday: "",
        manday_rate: "",
        arrangements_assessor: "",
        value_of_job_usd: "",
        job_order_approved: "",
        job_order_issued: "",
        value_of_job_words: "",
        planner_scheme: "",


    })
    console.log(createJobDetail, 'createJobDetailggggg')

    useEffect(() => {
        if (selectedAssessment || roleInAssessment) {
            console.log(roleInAssessment?.find(item => item.id === selectedAssessment?.assessor_role)?.id, 'jjfjjfjjfj')
            setCreateJobDetail({

                ...createJobDetail,
                assessor_planner: selectedAssessment?.assessor_planner,
                assessor_role: userRole?.find(item => item.name === (selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.role))?.id,
                cab: selectedAssessment?.cab,
                assessment: assessmentList?.find(item => item.label === selectedAssessment?.assessment_type)?.value,
                assessor: selectedAssessment?.assessor_name?.find((i) => i?.[0].assessor_id === urlId)?.[0].assessor_id,
                on_off_site: selectedAssessment?.assessor_data.find((i) => i.assessor?.assessor_id === urlId)?.location,
                system: selectedAssessment?.scheme
            })

        }
    }, [selectedAssessment])
    console.log(createJobDetail, 'createJobDetail')




    const getAssessorsData = () => {
        axios
            .get(`${API_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}&assessor_planner=${selectedAssessment?.assessor_planner}`)
            .then((response) => {
                const data = response?.data
                setNewAssessmentData(data);

            })
            .catch((error) => {
                // Handle errors
                console.error('API Error:', error)
            })
    }
    useEffect(() => {
        if (show) {
            getAssessorsData()
        }
    }, [show])


    useEffect(() => {
        if (newAssessmentData) {
            setCreateJobDetail({ ...createJobDetail, planner_scheme: newAssessmentData?.map((i) => i.id) })
        }
    }, [newAssessmentData])

    useEffect(() => {
        if (newAssessmentData) {
            setCreateJobDetail({ ...createJobDetail, assessor: urlId })
        }
    }, [urlId])

    const getAssessmentList = () => {
        axios.get(`${BASE_URL}/cab/assessment/list/`)
            .then(response => {
                const data = response.data?.map(item => ({
                    label: item?.name,
                    value: item?.id
                }))
                setAssessmentList(data)
            })
            .catch(error => { console.log(error); });
    }
    useEffect(() => {
        getAssessmentList()
    }, []);
    const getAssessorsList = () => {
        axios
            .get(`${API_URL}/cab/assessment/assessor/list/`)
            .then((response) => {
                const data = response?.data
                setAssessorList(data);
            })
            .catch((error) => {
                // Handle errors
                console.error('API Error:', error)
            })
    }
    useEffect(() => {
        if (show) {
            getAssessorsList()
        }
    }, [show])

    const fetchRole = async () => {
        try {
            const response = await axios.get(`${API_URL}/role/list/`
            );
            setUserRole(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchRole()
    }, []);


    const handleRoleChange = (e) => {
        setCreateJobDetail({ ...createJobDetail, assessor_role: e.value || '' })
    }
    const validate = () => {

        if (createJobDetail.total_manday?.trim() === "" || !createJobDetail.total_manday) {
            toast.error("Please Enter Total Mandays")
            return false
        }
        if (createJobDetail.manday_rate?.trim() === "" || !createJobDetail.manday_rate) {
            toast.error("Please Enter Manday Rate")
            return false
        }
        if (createJobDetail.arrangements_assessor?.trim() === " " || !createJobDetail.arrangements_assessor) {
            toast.error("Please Enter Travel Arrangements")
            return false
        }
        if (createJobDetail.value_of_job_usd?.trim() === "" || !createJobDetail.value_of_job_usd) {
            toast.error("Please Enter Value of Job Order")
            return false
        }
        if (createJobDetail.job_order_approved?.trim() === "" || !createJobDetail.job_order_approved) {
            toast.error("Please Enter Job Order Approved By")
            return false
        }
        if (createJobDetail.job_order_issued?.trim() === "" || !createJobDetail.job_order_issued) {
            toast.error("Please Enter Job Order Issued By")
            return false
        }
        if (createJobDetail.value_of_job_words?.trim() === "" || !createJobDetail.value_of_job_words) {
            toast.error("Please Enter Total Value")
            return false
        }

        return true
    };

    // Post Job
    const createJobPost = async () => {
        if (!validate()) return


        try {
            const response = await axios.post(`${API_URL}/cab/job/order/create/`, createJobDetail);
            toast.success("Job Order Created Successfully")
            getAssessorDataByAssessment()
            handleClose();
            setCreateJobDetail({
                ...createJobDetail,
                assessment: "",
                assessor: "",
                assessor_role: "",
                system: "",
                on_off_site: "",
                total_manday: "",
                manday_rate: "",
                arrangements_assessor: "",
                value_of_job_usd: "",
                job_order_approved: "",
                job_order_issued: "",
                value_of_job_words: "",
                planner_scheme: "",


            })

        } catch (error) {
            console.log(error);
        }
    }

    // useEffect(() => {
    //     if (assessorList && selectedAssessment?.assessor) {

    //         const getFilterData = assessorList?.find((item) => item?.id == selectedAssessment?.assessor_name.find((i) => i.id === urlId)?.id)
    //         console.log(getFilterData, 'getFilterData')
    //         setRoleInAssessment(getFilterData?.assign_role_dict)
    //         if (!getFilterData?.assign_role_dict) {
    //             setCreateJobDetail({ ...createJobDetail, assessor_role: '9fba784b-2277-452e-9ff7-94e576589bd1' })

    //         }
    //     }

    // }, [assessorList])



    return (

        <Modal
            id='job_order_model'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            backdrop="static"
        >
            <div className='modal-header'>
                <h2>Create Job Order</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className="container">



                    {/* <div className="jo_fs text-center"> */}
                    {newAssessmentData?.map((all, index) => (

                        <div key={index}>
                            <div className='card mb-5 mb-xl-10'>
                                <div className='card-body px-9 py-5'>
                                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder m-0 col-lg-6'>Type of Assessment: </h4>
                                        <div className="col-lg-6">
                                            <input
                                                type='text'
                                                className='form-control form-control-solid'
                                                value={all?.assessment_type}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <hr className="jo_hr" />

                                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder m-0 col-lg-6'>Scheme: </h4>
                                        <div className="col-lg-6">
                                            <input
                                                type='text'
                                                className='form-control form-control-solid'
                                                value={all?.scheme_short_name}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <hr className="jo_hr" />

                                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder mb-3'>Job Order issued to: </h4>
                                        <Select
                                            className='react-select-styled react-select-solid '
                                            classNamePrefix='react-select'
                                            options={assessmentList?.map(item => ({ value: item?.id, label: `${item?.first_name} ${item?.last_name}` }))}
                                            placeholder='Select an option'
                                            isDisabled
                                            value={selectedAssessment ? {
                                                value: selectedAssessment.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.assessor_id,
                                                label: `${selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.first_name} ${selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.last_name}`
                                            } : null}



                                        />
                                    </div>

                                    {/* </div> */}
                                    {/* <hr className="jo_hr" />


                                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder m-0'>Role in Assessment:</h4>
                                        <Select
                                            isDisabled
                                            className='react-select-styled react-select-solid w-50'
                                            classNamePrefix='react-select'
                                            options={roleInAssessment?.map(item => ({ value: item?.id, label: item?.name }))}
                                            onChange={(e) => handleRoleChange(e)}
                                            // value={roleInAssessment?.length ? createJobDetail?.assessor_role : null}
                                            value={{ value: selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.role, label: selectedAssessment?.assessor_name.find((i) => i?.[0].assessor_id === urlId)?.[0]?.role }}

                                            placeholder='Select an option'
                                        />


                                    </div> */}
                                    {/* <hr className="jo_hr" /> */}
                                    {/* <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder m-0'>SYSTEMS:</h4>
                                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={all?.} />
                                    </div> */}
                                    {/* <hr className="jo_hr" /> */}
                                    {/* <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                        <h4 className='fw-bolder m-0'>Onsite & Offsite:</h4>
                                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={selectedAssessment?.assessor_data?.find((i) => i.assessor?.assessor_id === urlId)?.location} />
                                    </div> */}
                                    {/* <hr className="jo_hr" /> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body px-9 py-5'>
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Number: <span className="inline_master_head">XXXX-XXX</span></h4>
                                <h4 className='fw-bolder m-0'>Job Order date: <span className="inline_master_head">{moment(new Date()).format("DD/MMM/YYYY")}</span></h4>
                            </div>
                            {/* <hr className="jo_hr" /> */}
                            {/* <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>SYSTEMS:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={selectedAssessment?.scheme_short_name} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Onsite & Offsite:</h4>
                                <input type="text" className="form-`control form-control-solid w-50" placeholder="--" disabled value={selectedAssessment?.assessor_data?.find((i) => i.assessor?.assessor_id === urlId)?.location} />
                            </div> */}
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Total Mandays:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, total_manday: e.target.value || '' })} value={createJobDetail?.total_manday} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Manday Rate:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, manday_rate: e.target.value || '' })} value={createJobDetail?.manday_rate} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Travel Arrangements by Assessors if any:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, arrangements_assessor: e.target.value || '' })} value={createJobDetail?.arrangements_assessor} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Value of Job Order in USD:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_usd: e.target.value || '' })} value={createJobDetail?.value_of_job_usd} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Approved By:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_approved: e.target.value || '' })} value={createJobDetail?.job_order_approved} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Job Order Issued By:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_issued: e.target.value || '' })} value={createJobDetail?.job_order_issued} />
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>Total Value of Job Order:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_words: e.target.value || '' })} value={createJobDetail?.value_of_job_words} />
                            </div>

                            <hr className="jo_hr" />
                            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'> Complete Name and Address of Certification Body:<span className="inline_master_head">
                                    {/* {userData?.company},{" "} {userData?.country_name}  */}
                                </span></h4>
                            </div>
                            <hr className="jo_hr" />
                            <div className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
                                <p className="inline_master_head m-0">United Accreditation Foundation Inc (UAF) </p>
                                <img
                                    alt='Logo'
                                    src={('/media/logos/uaf_logo.png')}
                                    className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
                                />
                                <p className="inline_master_head m-0">400 North Center Dr, STE 202 </p>
                                <p className="inline_master_head m-0">Norfolk, VA 23502 </p>
                                <p className="inline_master_head m-0">United States of America</p>
                                <p className="inline_master_head m-0">+1-757-228-5581</p>
                                <p className="inline_master_head m-0">www.uafaccreditation.org </p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-5">
                        <button className='thm-btn' onClick={createJobPost}>
                            Send
                        </button>
                    </div>

                </div>
            </div>



        </Modal>

    );
};

export default JobOrderPlanner;