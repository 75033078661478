/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { getUserDetails } from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorFunction'
import { UserDataType } from '../assessor-mangement/AssessorInterface'
import { ChangeStatus } from '../assessor-mangement/verifiedAssessor/components/VerifiedAssessorFunction'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from 'react-toastify'
import axios from 'axios'
import clsx from 'clsx'
import Select from "react-select";

import CreateManualAssesor from '../assessor-mangement/assessorLead/models/CreateManualAssesor'
import JobOrderPlanner from '../organisation/AssessmentPlanner/component/modal/JobOrderPlanner'
const API_URL = process.env.REACT_APP_API_URL
interface AssessorHeaderProps {
  userId: string
}
interface UserRoleType {
  id: string
  name: string
}
interface User {
  id: number;
  first_name: string;
  last_name: string;
}


const ARCHeader: React.FC<AssessorHeaderProps> = ({ userId }) => {
  const location = useLocation()
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  const [sendShow, setSendShow] = useState(false);
  const [cabUser, setCabUser] = useState<any>()
  const [assessmentData, setAssessmentData] = useState<any>()
  console.log(cabUser, 'cabUser')
  console.log(assessmentData, 'assessmentData')
  const [cabMember, setCabMember] = useState<User[]>([]);


  const [assignRoleShow, setAssignRoleShow] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [userData, setUserData] = useState<UserDataType>()
  const [userRole, setUserRole] = useState<UserRoleType[]>([])
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  console.log(checkedIds, "checkedIds");




  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  // const [jobOrderModal, setJobOrderModal] = useState<boolean>(false);

  // const [jobOrderModalShow, setJobOrderModalShow] = useState<boolean>(false)
  console.log(checkedIds, "checkedIds");
  useEffect(() => {
    axios.get(`${API_URL}/account/approved/cab/user/`)

      .then(response => {
        const data = response.data?.map(item => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id
        }))
        setCabMember(data)
      })
      .catch(error => { console.log(error); });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserDetails(urlId)
        setUserData(result.details)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchData()
  }, [])
  const fetchRole = async () => {
    try {
      const response = await axios.get(`${API_URL}/role/list/`

      );
      setUserRole(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRole()
  }, []);
  const handleClick = () => {
    ChangeStatus(urlId)
  }


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedIds([...checkedIds, name]);
    } else {
      setCheckedIds(checkedIds.filter(id => id !== name));
    }
  };
  const handleRoleClose = () => setAssignRoleShow(false);
  const handleSendClose = () => setSendShow(false);
  const handleInitiateArc = () => setSendShow(true);
  const handleAssignRole = () => setAssignRoleShow(true);
  const [selectedAssessment, setSelectedAssessment] = useState<any>();


  const handleInputChange = (event) => {
    const { name, value } = event.target
    // setFormData({
    //   ...formData,
    //   [name]: value
    // });
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData()
    // setFormData({
    //   ...formData,
    //   EditorData: data
    // });
  };
  const updateUserRole = async (id) => {
    try {
      const response = await axios.post(`${API_URL}/role/user/assign/`, {
        user: id,
        role: checkedIds
      });
      console.log(response.data);
      toast.success('Role Assign Successfully')
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const navigate = useNavigate();

  const navigateFunction = async (id) => {
    navigate(`/assessor/details/edit/info/${id}`);
  };
  const findAssessment = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/cab/filter/scheme/assessor/planner/?cab=${cabUser?.value}&assessor=${urlId}&assessment_type=&create_at=&update_at=`
      )
      setAssessmentData(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (cabUser) {
      findAssessment();
    }
  }, [cabUser])


  const handleSelectChange = (selectedOption) => {
    const assessment = assessmentData.find(item => item.assessment_number === selectedOption.value);
    setSelectedAssessment(assessment);
  };

  return (
    <div id="assign_id_arc">
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {userData?.first_name} {userData?.last_name}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {userData?.country_name}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {userData?.email}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2'
                    >
                      <KTIcon iconName='phone' className='fs-4 me-1' />
                      {userData?.phone}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <a
                    onClick={() => handleAssignRole()}
                    className='btn btn-sm btn-primary me-3 p-4'
                  >
                    Assign Role
                  </a>
                  {/* <a
                    onClick={() => handleInitiateArc()}
                    className='btn btn-sm btn-primary me-3 p-4'
                  >
                    Send E-mail
                  </a> */}

                  {/* <div className='me-2'>

                    <DropdownButton id="dropdown-basic-button" title="Assign Role">
                      {userRole.map((item, index) => (
                        <Dropdown.Item key={index}>{item?.name}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div> */}
                  <div className='me-0'>
                    <DropdownButton id="dropdown-basic-button" title="Actions">
                      <Dropdown.Item >Unapprove</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigateFunction(userData?.id || '')}


                      >Edit</Dropdown.Item>
                      {/* <Dropdown.Item
                        onClick={() => setJobOrderModalShow(true)}



                      >Create Job Order</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleInitiateArc()}>Assign ARC</Dropdown.Item>
                    </DropdownButton>

                  </div>
                </div>

              </div>
              <div className='d-flex my-2 fw-bold fs-6'>
                Status:{' '}
                <div className='badge badge-light-primary fw-bold fs-6'>

                  Verified Assessor
                </div>
              </div>

              {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Completion</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div> */}
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/verified/${urlId}/overview` && 'active')
                  }
                  to={`/assessor/verified/${urlId}/overview`}
                >
                  Overview
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/verified/${urlId}/documents` && 'active')
                  }
                  to={`/assessor/verified/${urlId}/documents`}
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/verified/${urlId}/settings` && 'active')
                  }
                  to={`/assessor/verified/${urlId}/settings`}
                >
                  Settings
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/assessor/verified/${urlId}/assessment` && 'active')
                  }
                  to={`/assessor/verified/${urlId}/assessment`}
                >
                  Assessment
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CreateManualAssesor
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        editUserId={urlId}
      />
      <Modal show={sendShow} onHide={handleSendClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign ARC Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='mb-5 col-lg-6 mt-5' style={{ color: '#136ba1' }}>
            <Select
              className='cab-control'
              // value={selectedCAB}
              // onChange={handleCABChange}
              // options={CABoptions}
              placeholder='CAB Name'
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#136ba1',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#136ba1',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#136ba1' : 'white',
                  color: state.isSelected ? 'white' : '#136ba1',
                  '&:hover': {
                    backgroundColor: '#136ba1',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSendClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleSendClose();
              // OnEmailSend();
              // Here you can use editorData for further processing
            }}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>

      {/* model to select role */}
      <Modal show={assignRoleShow} onHide={handleRoleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='card-body '>
            <div className='row mb-7'>
              {userRole.map(item => (

                <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                  <div className=' d-flex'>
                    <input
                      type="checkbox"
                      name={item.id}
                      id="assign_arc_12"
                      checked={checkedIds.includes(item.id)}
                      onChange={handleCheckboxChange}
                    />
                    <label className=' ' style={{ padding: '4px 0 0 14px' }} htmlFor='work_experience'>
                      {item.name}
                    </label>
                  </div>
                </div>
              ))}
            </div> </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRoleClose}>
            Close
          </Button>
          <Button
            variant="success"
            id="assign_arc_123"
            onClick={() => {
              handleRoleClose();
              updateUserRole(userData?.id); // Pass editorData as an argument to updateUserRole
              // Further processing with editorData if needed
            }}
          >

            Submit
          </Button>
        </Modal.Footer>
      </Modal >

      {/*  create job order modal */}
      {/* model to select role */}
      {/* <Modal show={jobOrderModalShow} onHide={() => setJobOrderModalShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create Job Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='card-body '>


            <div className="row p-5">
              <div className="mb-5 col-6">
                <label className="required form-label">CAB User</label>
                <Select
                  name="User"
                  options={cabMember}
                  className='react-select-styled'
                  classNamePrefix='react-select'
                  value={cabUser}
                  onChange={(e) => setCabUser(e)}
                />
              </div>
              {assessmentData ? <>
                <div className="mb-5 col-6">
                  <label className="required form-label">Assessment Number</label>
                  <Select
                    name="User"
                    options={assessmentData.map(item => ({ label: item.assessment_number, value: item.assessment_number }))}
                    className='react-select-styled'
                    classNamePrefix='react-select'
                    onChange={handleSelectChange}

                  />
                </div>
              </> :

                null}

            </div>
          </div>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setJobOrderModalShow(false)}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              if (selectedAssessment) {
                setJobOrderModalShow(false); setJobOrderModal(true);
              }
            }
            }
          >

            Next
          </Button>
        </Modal.Footer>
      </Modal > */}
      {/* <JobOrderPlanner  selectedAssessment={selectedAssessment} show={jobOrderModal} handleClose={() => setJobOrderModal(false)} accessorList={accessorList} urlId={urlId}/> */}

    </div>
  )
}
export default ARCHeader
