import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserDataType } from "./AssessorInterface";

import CheckboxHierarchy from "./assessorLead/models/CheckboxHierarchy";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import UAFLoader from "../../loader/UafLoader";
import ProgramAndSchemeSection from "../profile/components/CabInfoTabs/component/ProgramAndSchemeSection";
import { getUserDetails } from "./verifiedAssessor/components/VerifiedAssessorFunction";
const API_URL = process.env.REACT_APP_API_URL

interface Props {
    userId: string | undefined;
}

interface IAFCriticalData {
    id: string;
    name: string;
    scheme: string;
    iaf_sector_doc: File | null;
}

const ClusterTrainingData: React.FC<Props> = ({ userId }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<UserDataType | null>(null);
    const [userDataInfo, setUserDataInfo] = useState<UserDataType | null>(null);
    const [userCertificate, setUserCertificate] = useState<UserDataType>();
    const [userIAFData, setUserIAFData] = useState<UserDataType>();

    const [loader, setLoader] = useState<any>(false);
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [selectedSchemesList, setSelectedSchemesList] = useState<string[]>([]);
    const [selectedStandardList, setSelectedStandardList] = useState<string[]>([]);
    const [selectedClusterList, setSelectedClusterList] = useState<string[]>([]);
    const [selectedSchemes, setSelectedSchemes] = useState<{ [key: string]: string[] }>({});
    const [selectedStandards, setSelectedStandards] = useState<{ [key: string]: { [key: string]: string[] } }>({});
    const [selectedClusters, setSelectedClusters] = useState<{ [key: string]: { [key: string]: string[] } }>({});
    const [selelctedIAFCriticalValue, setSelelctedIAFCriticalValue] = useState<IAFCriticalData[]>([]);

    console.log("selectedPrograms:", selectedPrograms);
    console.log("selectedSchemesList:", selectedSchemesList);
    console.log("selectedStandardList:", selectedStandardList);
    console.log("selectedClusters:", selectedClusters);
    console.log("userIAFData:", userIAFData);
 console.log("selectedClusterList:", selectedClusterList);
    useEffect(() => {
        if (userId !== null && userId !== undefined) {
            const fetchDataInfo = async () => {
                try {
                    const result = await getUserDetails(userId);
                    setUserIAFData(result?.details);
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            fetchDataInfo();
        }
    }, [userId]);

    const handelSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            setLoader(true);
            const validUserId = userId ? userId.toString() : "defaultUserId"; // Provide a default value if needed

            const data = {
                user: validUserId,
                programme: selectedPrograms,
                scheme: selectedSchemesList,
                standard: selectedStandardList,
                cluster: selectedClusterList,
            };
console.log(data,"datadatadatadatadatadatadatadata");

            const response = await axios.post(`${API_URL}/team/assessor/custom/cluster/critical/post/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success('Application submitted successfully.');
                const resposneId = response?.data?.user_id;
                console.log(resposneId, "resposneId");

                setLoader(false);
                 navigate(`/assessor/details/edit/critical/doc/${userId}`);
            } else {
                toast.error('Failed to submit application. Please try again.');
                setLoader(false);
            }
        } catch (error) {
            toast.error('Document with this user already exists');
            setLoader(false);
        }
    };

    return (
        <>
            {loader ? <UAFLoader /> : null}
            <form onSubmit={handelSubmit}>
                {/* <CheckboxHierarchy
                    userIAFData={userIAFData}
                    setUserIAFData={setUserIAFData}
                    selectedPrograms={selectedPrograms}
                    selectedSchemes={selectedSchemes}
                    selectedStandards={selectedStandards}
                    selectedClusters={selectedClusters}
                    setSelectedPrograms={setSelectedPrograms}
                    setSelectedSchemes={setSelectedSchemes}
                    setSelectedStandards={setSelectedStandards}
                    setSelectedClusters={setSelectedClusters}
                    selectedSchemesList={selectedSchemesList}
                    setSelectedSchemesList={setSelectedSchemesList}
                    selectedStandardList={selectedStandardList}
                    setSelectedStandardList={setSelectedStandardList}
                    selectedClusterList={selectedClusterList}
                    setSelectedClusterList={setSelectedClusterList}
                    selelctedIAFCriticalValue={selelctedIAFCriticalValue}
                    setSelelctedIAFCriticalValue={setSelelctedIAFCriticalValue}
                /> */}
                 <ProgramAndSchemeSection
                                userIAFData={userIAFData}
                                setUserIAFData={setUserIAFData}
                                selectedPrograms={selectedPrograms}
                                selectedSchemes={selectedSchemes}
                                selectedStandards={selectedStandards}
                                selectedClusters={selectedClusters}
                                setSelectedPrograms={setSelectedPrograms}
                                setSelectedSchemes={setSelectedSchemes}
                                setSelectedStandards={setSelectedStandards}
                                setSelectedClusters={setSelectedClusters}
                                selectedSchemesList={selectedSchemesList}
                                setSelectedSchemesList={setSelectedSchemesList}
                                selectedStandardList={selectedStandardList}
                                setSelectedStandardList={setSelectedStandardList}
                                selectedClusterList={selectedClusterList}
                                setSelectedClusterList={setSelectedClusterList}
                                selelctedIAFCriticalValue={selelctedIAFCriticalValue}
                                setSelelctedIAFCriticalValue={setSelelctedIAFCriticalValue}
                                urlId={userId}
                            />
                <div className='mb-5'>
                    <button type='submit' className='btn btn-primary'>
                        Submit
                        <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button>
                </div>
            </form>
        </>
    );
};

export default ClusterTrainingData;
