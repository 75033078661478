import Select, {MultiValue, SingleValue} from 'react-select'
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import UAFLoader from '../../loader/UafLoader'
import {toast} from 'react-toastify'
import SignaturePadComponent from '../profile/components/SignaturePadComponent'

const EditApplicationReviewer = () => {
    const {id} = useParams();
  const BASE_URL = process.env.REACT_APP_API_URL
  const [userData, setUserData] = useState<any>()
  const [loader, setLoader] = useState<any>(false);
  const [monitorDate, setmonitorDate] = useState<Date | null>(null)
  const [signatureImageData, setSignatureImageData] = useState<any>()
  const [inputValues, setInputValues] = useState<any>({})
  



  const getUserDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/performance/application/reviewer/monitor/retrieve/${id}/`)
      if (response.status === 200 || response.status === 202) {
        setUserData(response.data)
        setInputValues(response.data);
        setmonitorDate(new Date(response.data.monitor_date));
        
      }
    } catch (error) {
      // Handle errors
      console.error('API Error:', error)
      // throw error;
    }
  }
  useEffect(()=> {
    if(id){
    getUserDetails()}
  },[id])

  useEffect(()=>{

  })



  const handleDateChange = (date: Date | null) => {
    setmonitorDate(date)
  }

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setInputValues((prevValue) => ({
      ...prevValue,
      [name]: value,
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    const postData = {
      ...inputValues,
      
      monitor_date: monitorDate ? monitorDate.toISOString() : null,
     
    }

    try {
      setLoader(true)
      const response = await axios.put(`${BASE_URL}/performance/application/reviewer/monitor/update/${id}/`, postData)
      if (response.status === 200 || response.status === 201) {
        // Handle successful response
        setLoader(false)
        toast.success('Form submitted successfully:', response.data)
        getUserDetails();
      }
    } catch (error) {
      setLoader(false)
    }
  }

  return (
    <>{loader ? <UAFLoader /> : null}
      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-3 p-0 '>Update Performance Report</h2>
        </div>
      </div>
      {/* <div className='card card-body py-5'>
    
      
        
      </div> */}
      <div className='card card-flush'>
                <form onSubmit={handleSubmit}>
                    <div className='card-header pt-5'>
                        <div className='col-lg-12'>
                            <div className='my-1 row'>
                                <h2 className='mb-5 col-lg-6'>Name:</h2>
                                <h2 className='mb-5 col-lg-6'>
                                    {userData?.assessor_data
?.name} 
                                </h2>
                                <h3 className='mb-5 col-lg-6'>Designation:</h3>
                                <h3 className='mb-5 col-lg-6'>Application Reviewer</h3>
                                <h3 className='mb-5 col-6'>Name of Evaluator:</h3>
                                <div className=' mb-5 col-6'>
                                    <input
                                        value={inputValues.evaluator_name || ''}
                                        name='evaluator_name'
                                        className='form-control form-control-solid'
                                        type='text'
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <h3 className='mb-5 col-lg-6'>Date of monitoring:</h3>
                                <div className='mb-5 col-lg-6'>
                                    <ReactDatePicker
                                        selected={monitorDate}
                                        placeholderText='Select Date'
                                        className='react_date_picker form-control form-control-solid'
                                        onChange={handleDateChange}
                                        dropdownMode='select'
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body pt-5'>
                        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                            <thead>
                                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                    <th className='col-1'>S No</th>
                                    <th className='col-2'>PARAMETERS</th>
                                    <th className=''>SATISFACTORY / NEEDS IMPROVEMENT </th>
                                    <th className=''>REVIEWER COMMENTS </th>
                                </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-semibold'>
                                <tr>
                                    <td>1</td>
                                    <td>Understanding of Accreditation Standard (ISO/IEC 17011:2017)</td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_1'
                                            value={inputValues.uaf_requirement_1 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_1'
                                            value={inputValues.uaf_comments_1 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Knowledge of UAF accreditation’s rules and processes</td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_2'
                                            value={inputValues.uaf_requirement_2 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_2'
                                            value={inputValues.uaf_comments_2 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        Knowledge of accreditation and accreditation scheme requirements (UAF-CAB-QMS &
                                        UAF-CAB-EMS etc.)
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_3'
                                            value={inputValues.uaf_requirement_3 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_3'
                                            value={inputValues.uaf_comments_3 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        Knowledge of accreditation relevant guidance documents (UAF-GEN-CAB-01 &
                                        UAF-GEN-CAB-02)
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_4'
                                            value={inputValues.uaf_requirement_4 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_4'
                                            value={inputValues.uaf_comments_4 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        Knowledge of accreditation application documents and review of availability of
                                        resources
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_5'
                                            value={inputValues.uaf_requirement_5 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_5'
                                            value={inputValues.uaf_comments_5 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Review of other documents like contract agreement, previous assessment reports</td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_requirement_6'
                                            value={inputValues.uaf_requirement_6 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control form-control-solid'
                                            rows={7}
                                            name='uaf_comments_6'
                                            value={inputValues.uaf_comments_6 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                  <td>7</td>
                  <td>Time frame adherence as per UAF policies</td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_requirement_7'
                      value={inputValues['uaf_requirement_7'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_comments_7'
                      value={inputValues['uaf_comments_7'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Courteous while communicating /interacting with CABs and others</td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_requirement_8'
                      value={inputValues['uaf_requirement_8'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_comments_8'
                      value={inputValues['uaf_comments_8'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Professional – does not conduct personal business</td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_requirement_9'
                      value={inputValues['uaf_requirement_9'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_comments_9'
                      value={inputValues['uaf_comments_9'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  
                </tr>
                <tr>
                  <td>10</td>
                  <td>Any negative feedback received</td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_requirement_10'
                      value={inputValues['uaf_requirement_10'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      className='form-control form-control-solid'
                      rows={7}
                      name='uaf_comments_10'
                      value={inputValues['uaf_comments_10'] || ''}
                      onChange={handleInputChange}
                    />
                  </td>
                  
                </tr>
                            </tbody>
                        </table>
                        <div className='mb-5 '>
              <label className='col-lg-4 fw-bold text-muted'>Remarks</label>

              <textarea
                className='form-control form-control-solid'
                rows={7}
                name='remark'
                value={inputValues?.remark}
                onChange={handleInputChange}
              />
            </div>
                    </div>
                    {/* <div className='card-footer'>
                        <div className='row'>
                            <div className='mb-4 col-md-4'>
                                <label className='form-label fw-bolder text-dark fs-6'>Signature:</label>
                                <SignaturePadComponent onSave={setSignatureImageData} />
                            </div>
                        </div>
                    </div> */}
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loader}>
                            {loader ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
    </>
  )
}

export default EditApplicationReviewer
