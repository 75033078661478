import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import axios from 'axios'
import { Link } from 'react-router-dom'
import UAFLoader from "../../loader/UafLoader";
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Select, { MultiValue, SingleValue } from 'react-select'
const API_URL = process.env.REACT_APP_API_URL

interface MatrixData {
  id: string
  first_name: string
  last_name: string
  email: string
  certification_metrics: string[]
  scheme_dict: {
    id: string
    name: string
  }[]
}
interface Scheme {
  id: string;
  name: string;
}
interface SchemeList {
  id: string
  name: string
  short_name: string
}
interface FilteredData extends MatrixData {
  selectedSchemes: Scheme[];
}

const ResourceMatrix: React.FC = () => {
  const [matrix, setMatrix] = useState<MatrixData[]>([])
  console.log(matrix, "matrix");

  const [schemeList, setSChemeList] = useState<SchemeList[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [loader, setLoader] = useState<any>(false);

  const [filteredValue, setFilteredValue] = useState<MatrixData[]>([])
  console.log(matrix?.length, 'matrix')

  useEffect(() => {
    const getMatrix = async () => {
      try {
        setLoader(true)
        const response = await axios.get<MatrixData[]>(`${API_URL}/team/show/metrics/`)
        setMatrix(response.data)
      } catch (error) {
        console.error('API Error:', error)
        // throw error
      }
      setLoader(false)
    }

    getMatrix()
  }, [])

  useEffect(() => {
    const getSchemeList = async () => {
      try {
        setLoader(true)
        const response = await axios.get<SchemeList[]>(`${API_URL}/global/scheme/list/`)
        setSChemeList(response.data)
      } catch (error) {
        console.error('API Error:', error)
        // throw error
      }
      setLoader(false)
    }

    getSchemeList()
  }, [])

  const getUserCertificates = (user: MatrixData) => {
    const userCertIds = user.scheme_dict.map((cert) => cert.id)
    return schemeList.filter((cert) => userCertIds.includes(cert.id))
  }
  const [searchTerm, setSearchTerm] = useState('');
  console.log(searchTerm, "searchTerm");


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.target?.value)
    // Filter the value array based on the search query
    const filteredData = matrix.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase()
      const searchValue = e.target.value.toLowerCase()

      return (
        fullName.includes(searchValue) ||
        user?.first_name.toLowerCase().includes(searchValue) ||
        user?.last_name.toLowerCase().includes(searchValue)
      )
    })
    console.log(filteredData, 'filteredData')
    setFilteredValue(filteredData)
  }


  const exportToExcel = () => {
    // Prepare the data structure
    const dataToExport: string[][] = []

    // Add the header row with assessor name and scheme short names
    const headerRow: string[] = ['Assessor Name', ...schemeList.map((scheme) => scheme.short_name)]
    dataToExport.push(headerRow)

    // Add the data rows for each assessor
    const rows: string[][] = (searchQuery.length > 0 ? filteredValue : matrix).map((user) => {
      const row: string[] = [`${user.first_name} ${user.last_name}`] // First column is the assessor's name

      // Add a tick mark for each scheme the user is approved for, otherwise an empty cell
      schemeList.forEach((scheme) => {
        const isApproved = getUserCertificates(user).some((c) => c.id === scheme.id)
        row.push(isApproved ? '✔️' : '') // Add a checkmark if approved
      })

      return row
    })

    // Append rows to dataToExport
    dataToExport.push(...rows)

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport)

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Resource Matrix')

    // Write the workbook and trigger a download
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" })
    saveAs(blob, "ResourceMatrix.xlsx")
  }

  // Helper function to convert string to array buffer
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }


  const handleSchemeChange = (selectedOption) => {
    const schemeId = selectedOption.value;

    // Use reduce to accumulate the matching results in a single array
    const filteredResults: FilteredData[] = matrix.reduce((acc: FilteredData[], data: MatrixData) => {
      const selectedSchemes = data.scheme_dict.filter(scheme => scheme.id === schemeId);

      if (selectedSchemes.length > 0) {
        // Include the data and the matching schemes in the results
        acc.push({ ...data, selectedSchemes });
      }
      return acc;
    }, []);

    setFilteredValue(filteredResults)
    // console.log('Filtered Results:', filteredResults);


  };


  return (
    <>{loader ? <UAFLoader /> : null}
      <div className='mb-5 mb-xl-8'>
        <div className='card-header border-0 py-5 d-flex mb-5'>
          <h3 className='card-title align-items-start flex-column mt-2'>
            <span className='card-label fw-bold fs-1  '>Resource Matrix </span>
            {matrix?.length > 0 && (
              <span
                className='card-label fw-bold fs-1 m-2'
                style={{ backgroundColor: '#136ba1', color: 'white', padding: '0.3em 0.5em' }}
              >
                {matrix.length}
              </span>
            )}

          </h3>
          <div className="search-dropdown d-flex " style={{ marginLeft: 'auto' }}>
            <div style={{ marginRight: "18px", border: "1px solid #dddddd", borderRadius: "5px" }}>
              <Select
                id="select_role_id"
                className="react-select-styled react-select-solid w-200px"
                classNamePrefix="react-select"
                placeholder="Select Scheme"
                options={schemeList?.map((item) => ({ label: item?.short_name, value: item?.id }))}
                onChange={(selectedOption) => handleSchemeChange(selectedOption)}
              />

            </div>
            <input
              style={{ border: '1px solid #dddddd' }}
              type='text'
              data-kt-user-table-filter='search'
              onChange={handleSearchChange}
              className='form-control form-control-solid w-500px ps-14'
              placeholder='Search Assessor'
            />
            <button
              onClick={exportToExcel}
              className='btn btn-primary ms-5'
              style={{ marginLeft: '20px' }}
            >
              Export to Excel
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className=' align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{ fontSize: '14px' }}>
                  <th
                    style={{ border: '1px solid #dddddd', padding: '8px' }}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    ASSESSOR NAME
                  </th>
                  {schemeList.map((item) => (
                    <th
                      style={{ border: '1px solid #dddddd', padding: '8px' }}
                      key={item.id}
                      className=''
                    >
                      {item.short_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {matrix.map(user => ( */}
                {(filteredValue.length > 0 ? filteredValue : matrix).map((user, index) => (
                  <tr key={user.id} style={{ borderTop: '1px solid #dddddd' }}>
                    <td style={{ border: '1px solid #dddddd', padding: '8px' }}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/assessor/verified/${user?.id}/overview`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {user.first_name} {user.last_name}
                          </Link>
                        </div>
                      </div>
                    </td>
                    {schemeList.map((cert) => (
                      <td key={cert.id} style={{ border: '1px solid #dddddd', textAlign: 'center' }}>
                        {getUserCertificates(user).some((c) => c.id === cert.id) ? (
                          <KTIcon iconName='check' className='fs-1 text-primary ' />
                        ) : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResourceMatrix
