import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { toast } from 'react-toastify';
import moment from 'moment'

const AssessorARCInvoice = () => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const [assessorUser, setAssessorUser] = useState<any>()
    const [assessorId, setAssessorID] = useState<any>()
    const [value, setValue] = useState<any[]>([]);
    const [arcJOModal, setArcJOModal] = useState<any>();

    const [matchedAssessor, setMatchedAssessor] = useState<any>();
    console.log(arcJOModal, "arcJOModalarcJOModal");
    console.log(matchedAssessor, "matchedAssessor");
    const [arcAssessment, setARCAssessment] = useState<any>([])
    console.log(arcAssessment, "arcAssessment");
    const [createJobDetail, setCreateJobDetail] = useState({
        cab: "",
        assessment: "",
        assessor: "",
        assessor_role: "",
        system: "",
        on_off_site: "",
        total_manday: "",
        manday_rate: "",
        arrangements_assessor: "",
        value_of_job_usd: "",
        job_order_approved: "",
        job_order_issued: "",
        value_of_job_words: ""

    })
    const [roleList, setRoleList] = useState<any>();

    const fetchRoleData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/role/list/`
            )
            const filter = response.data?.map((item) => ({
                label: item?.name,
                value: item?.id
            }))
            setRoleList(filter)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchRoleData();
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}/team/accessor/verified/list/pagination/`)
            .then(response => {
                const data = response.data?.results?.map(item => ({
                    label: `${item?.first_name} ${item?.last_name}`,
                    value: item?.id
                }))
                setValue(data)
            })
            .catch(error => { console.log(error); });
    }, []);

    const getAssessorDataByARC = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/arc/management/filter/?cab=&assessor=${assessorId}`
            )
            setARCAssessment(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAssessorDataByARC()
    }, [assessorId])
    const formatDate = (dateString: string) => {
        return moment(dateString).format('DD/MM/YYYY')
    }

    const validate = () => {
        // if (createJobDetail.system?.trim() === "" || !createJobDetail.system) {
        //     toast.error("Please Enter Systems")
        //     return false
        // }
        if (createJobDetail.total_manday?.trim() === "" || !createJobDetail.total_manday) {
            toast.error("Please Enter Total Mandays")
            return false
        }
        if (createJobDetail.manday_rate?.trim() === "" || !createJobDetail.manday_rate) {
            toast.error("Please Enter Manday Rate")
            return false
        }
        if (createJobDetail.arrangements_assessor?.trim() === " " || !createJobDetail.arrangements_assessor) {
            toast.error("Please Enter Travel Arrangements")
            return false
        }
        if (createJobDetail.value_of_job_usd?.trim() === "" || !createJobDetail.value_of_job_usd) {
            toast.error("Please Enter Value of Job Order")
            return false
        }
        if (createJobDetail.job_order_approved?.trim() === "" || !createJobDetail.job_order_approved) {
            toast.error("Please Enter Job Order Approved By")
            return false
        }
        if (createJobDetail.job_order_issued?.trim() === "" || !createJobDetail.job_order_issued) {
            toast.error("Please Enter Job Order Issued By")
            return false
        }
        if (createJobDetail.value_of_job_words?.trim() === "" || !createJobDetail.value_of_job_words) {
            toast.error("Please Enter Total Value")
            return false
        }

        return true
    };

    useEffect(() => {
        if (arcJOModal && assessorId) {
            const matchedItem = arcJOModal.assessor_details.find(
                (item) => item.assessor === assessorId
            );
            setMatchedAssessor(matchedItem);
        }
    }, [arcJOModal, assessorId]);

    // Post Job
    const createJobPost = async () => {
        if (!validate()) return


        try {
            const data = createJobDetail
            data.assessment = arcJOModal.id
            data.cab = arcJOModal.cab
            data.assessor = assessorId
            data.assessor_role = roleList?.find(item => item.label == matchedAssessor.assessor_role__name)?.value
            data.on_off_site = matchedAssessor.assessor_location
            const response = await axios.post(`${BASE_URL}/arc/job/order/create/`, data);
            toast.success("Job Order Created Successfully")

            setCreateJobDetail({
                ...createJobDetail,
                assessment: "",
                assessor: "",
                assessor_role: "",
                // system: "",
                on_off_site: "",
                total_manday: "",
                manday_rate: "",
                arrangements_assessor: "",
                value_of_job_usd: "",
                job_order_approved: "",
                job_order_issued: "",
                value_of_job_words: ""
            })
            setAssessorUser('');
            setAssessorID('');

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='row'>
            <div className="mb-5 col-6">
                <label className="required form-label">Select Assessor</label>
                <Select
                    id="select_user"
                    name="User"
                    options={value}
                    className='react-select-styled'
                    classNamePrefix='react-select'
                    value={assessorUser}
                    onChange={(e) => {
                        setAssessorUser(e);
                        setAssessorID(e?.value);
                        setArcJOModal("");
                        
                    }}

                />
            </div>
            <div className="mb-5 col-6 px-10 py-5 ">
                <h4 className='fw-bolder mb-2'>Assessor: <span className="inline_master_head">{assessorUser?.label}</span></h4>
            </div>
            <table
                // style={{ margin: '0 0 0 25px' }}
                className='mt-10 table align-middle table-row-dashed fs-6 gy-5 location_table_show'
                id='kt_table_users'
            >
                <thead>
                    <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        {/* <th>Assessment#</th> */}
                        <th className='min-w-125px'>ARC ID</th>
                        <th className='min-w-125px'>CAB</th>
                        <th className='min-w-125px'>Dated</th>
                        <th className='min-w-125px'>Action</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                    {arcAssessment?.map((item, indexValue) => (


                        <>
                            {item?.job_number == null ? (
                                <tr>
                                    <td>
                                        {item?.assess_value}

                                    </td>
                                    <td>
                                        {item?.cab_details?.company}

                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>{item && item.create_at ? formatDate(item.create_at) : ''}</td>
                                    <td className='text-dark fw-bold text-hover-primary  fs-6'>
                                        <button className='btn btn-primary btn-active-light-primary btn-sm '
                                            type='button' onClick={() => setArcJOModal(item)}>
                                            create Job Order
                                        </button>
                                    </td>
                                </tr>
                            ) : null}
                        </>


                    ))}
                </tbody>
            </table>
            {arcJOModal ? (<>
                <div className='card-body px-9 py-5'>
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder mb-3'>Job Order issued to: </h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={`${matchedAssessor?.assessor__first_name} ${matchedAssessor?.assessor__last_name}`} />

                    </div>

                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        {/* <h4 className='fw-bolder m-0'>Job Order Number: <span className="inline_master_head">XXXX-XXX</span></h4> */}
                        <h4 className='fw-bolder m-0'>Job Order date: <span className="inline_master_head">{moment(new Date()).format("DD/MMM/YYYY")}</span></h4>
                    </div>

                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Role in Assessment:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={matchedAssessor?.assessor_role__name} />


                    </div>
                    <hr className="jo_hr" />
                    {/* <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                                <h4 className='fw-bolder m-0'>SYSTEMS:</h4>
                                <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, system: e.target.value || '' })} value={createJobDetail?.system} />
                            </div>
                            <hr className="jo_hr" /> */}
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Onsite & Offsite:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" disabled value={matchedAssessor?.assessor_location} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Total Mandays:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, total_manday: e.target.value || '' })} value={createJobDetail?.total_manday} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Manday Rate:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, manday_rate: e.target.value || '' })} value={createJobDetail?.manday_rate} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Travel Arrangements by Assessors if any:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, arrangements_assessor: e.target.value || '' })} value={createJobDetail?.arrangements_assessor} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'> Total Value of Job Order in USD:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_usd: e.target.value || '' })} value={createJobDetail?.value_of_job_usd} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Job Order Approved By:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_approved: e.target.value || '' })} value={createJobDetail?.job_order_approved} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Job Order Issued By:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_issued: e.target.value || '' })} value={createJobDetail?.job_order_issued} />
                    </div>
                    <hr className="jo_hr" />
                    <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
                        <h4 className='fw-bolder m-0'>Net Amount of Job Order:</h4>
                        <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_words: e.target.value || '' })} value={createJobDetail?.value_of_job_words} />
                    </div>
                   
                </div>
                <div className="d-flex gap-5">
                    <button id='create_arc_job_order_3' className='thm-btn' onClick={createJobPost}>
                        Send
                    </button>
                </div>
            </>) : null}

        </div>
    )
}

export default AssessorARCInvoice