import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { KTIcon } from "../../../../../../_metronic/helpers";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
import ReactDatePicker from 'react-datepicker';
import NewAssementModal from "../../../../apps/create-assessment/create-assessment-components/modals/NewAssementModal";



type Props = {
    show: boolean
    handleClose: () => void
    getAssessmentPlannerData: () => void
    showAssessentClicked: any

}
const API_URL = process.env.REACT_APP_API_URL

const EditAssessmentModal = ({ show, handleClose, showAssessentClicked, getAssessmentPlannerData }: Props) => {
    console.log(showAssessentClicked, 'showAssessentClicked')
    const BASE_URL = process.env.REACT_APP_API_URL
    interface User {
        value: string;
        label: string;
    }


    const [assessmentPlannerDetails, setAssessmentPlannerDetails] = useState({
        cab: "",
        scheme: "",
        assessment_type: "",
        assessment_number: "",
        multi_date: [] as Date[],
    })

    console.log(assessmentPlannerDetails, 'assessmentPlannerDetails gk')


    const [cabUser, setCabUser] = useState<User[]>([]);
    console.log(cabUser, "cabUser");

    const [schemeData, setSchemeData] = useState<any>([]);
    const [cabUserData, setCabUserData] = useState<any>([]);
    const [assessmentSchemeData, setAssessmentSchemeData] = useState<any>();
    console.log(assessmentSchemeData, 'assessmentSchemeData')
    const [multiDate, setMultiDate] = useState<any>([]);
    const [selectedDates, setSelectedDates] = useState([new Date()])


    console.log(cabUser, 'cabUser')
    const [startDateAssessment, setStartDate] = useState(new Date());


    // const [showNewAssessmentModal, setShowNewAssessmentModal] = useState<boolean>(false)
    const [assessmentType, setAssessmentType] = useState<string>('')
    const [assessmentList, setAssessmentList] = useState<any>([])
    console.log(assessmentList, 'assessmentList')
    const [dateRange, setDateRange] = useState([null]);

    useEffect(() => {
        if (showAssessentClicked) {
            setAssessmentPlannerDetails({
                ...assessmentPlannerDetails,
                cab: showAssessentClicked?.cab,
                assessment_type: showAssessentClicked?.assessment_type,
                multi_date: showAssessentClicked?.multi_date,
                assessment_number: showAssessentClicked?.assessment_number

            });

        }
    }, [showAssessentClicked]);


    useEffect(() => {
        if (showAssessentClicked?.schemeData?.length) {
            const assessmentData = showAssessentClicked?.schemeData?.map((item) => ({
                "scheme": item?.scheme,
                "id": item?.id,
                // "assessment_duration": item?.assessment_duration,
                "remark": item?.remark,
                // "assessor": item?.assessor,
                "major_nc_cab_start_date": item?.major_nc_cab_start_date,
                "major_nc_cab_closer_date": item?.major_nc_cab_closer_date,
                "minor_nc_cab_start_date": item?.minor_nc_cab_start_date,
                "minor_nc_cab_end_date": item?.minor_nc_cab_end_date,
            }))
            setAssessmentSchemeData(assessmentData)
        }

    }, [showAssessentClicked])

    const handleEditAssessorScheme = async (item) => {
        const data = {
            "major_nc_cab_start_date": item?.major_nc_cab_start_date,
            "major_nc_cab_closer_date": item?.major_nc_cab_closer_date,
            "minor_nc_cab_start_date": item?.minor_nc_cab_start_date,
            "minor_nc_cab_end_date": item?.minor_nc_cab_end_date,
            "remark": item?.remark,
        }
        try {
            const response = await axios.put(`${BASE_URL}/cab/assessor/planner/scheme/${item?.id}/update/`, data);

            //  handleSendAgreement(response?.data)

            handleClose();
            getAssessmentPlannerData();

        } catch (error) {
            console.log(error);
        }

    }
    // const handleEditAssessor = async () => {
    //     const data = {
    //         "assessment_start_date": assessmentPlannerDetails?.assessment_start_date,
    //         "assessment_end_date": assessmentPlannerDetails?.assessment_end_date,
    //     }
    //     const response = await axios.put(`${BASE_URL}/cab/assessor/planner/${showAssessentClicked?.id}/update/`, data);
    //     assessmentSchemeData.forEach((item) => {
    //         if (item?.major_nc_cab_closer_date || item?.major_nc_cab_start_date || item?.minor_nc_cab_end_date || item?.minor_nc_cab_start_date) {
    //             handleEditAssessorScheme(item)
    //         }
    //     })
    // }


    const handleEditAssessor = async () => {
        const data = {
            multi_date: Array.isArray(assessmentPlannerDetails.multi_date)
                ? JSON.stringify(assessmentPlannerDetails.multi_date.map(date => date.toISOString()))
                : assessmentPlannerDetails.multi_date,
            assessment_number: assessmentPlannerDetails.assessment_number,
        };

        try {
            // Sending update request to the server
            const response = await axios.put(`${BASE_URL}/cab/assessor/planner/${showAssessentClicked?.id}/update/`, data);

            // Success notification
            toast.success("Assessmment updated successfully!");
            handleClose();
            getAssessmentPlannerData();
            // Handle additional logic for assessor scheme data
            assessmentSchemeData.forEach((item) => {
                if (item?.major_nc_cab_closer_date || item?.major_nc_cab_start_date || item?.minor_nc_cab_end_date || item?.minor_nc_cab_start_date || item?.remark) {
                    handleEditAssessorScheme(item);
                }
            });

        } catch (error) {
            // Error notification
            toast.error("Failed to update assessment.");
        }
    };




    // get cab user
    useEffect(() => {

        axios.get(`${BASE_URL}/account/approved/cab/user/`)

            .then(response => {
                const data = response.data?.map(item => ({
                    label: `${item?.company} `,
                    value: item?.id
                }))
                console.log(response.data)
                setCabUserData(response.data)

                setCabUser(data)
            })
            .catch(error => { console.log(error); });
    }, []);

    // get assessment list
    const getAssessmentList = () => {
        axios.get(`${BASE_URL}/cab/assessment/list/`)
            .then(response => {
                const data = response.data?.map(item => ({
                    label: item?.name,
                    value: item?.id
                }))
                setAssessmentList(data)
            })
            .catch(error => { console.log(error); });
    }
    useEffect(() => {
        getAssessmentList()
    }, []);





    const handleCabUserChange = (e) => {
        setAssessmentPlannerDetails({ ...assessmentPlannerDetails, cab: e.value })
    }

    const handleAssessmentTypeChange = (e) => {
        setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessment_type: e.value })
    }
    const handleAssessmentNumberChange = (e) => {
        setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessment_number: e?.target?.value })
    }
    const convertToDateObjects = (dateStrings) => {
        if (!dateStrings) return []
        return dateStrings.map(dateStr => new Date(dateStr))
    }
    const handleDateChange = (dates) => {
        setAssessmentPlannerDetails(prevState => ({
            ...prevState,
            multi_date: dates ? dates : "",

        }));
        setSelectedDates(dates)
    }

    //   useEffect(() => {
    //     if (showAssessentClicked) {
    //         if (showAssessentClicked?.multi_date) {
    //                     const dateStrings = showAssessentClicked?.multi_date.replace(/'/g, '"');
    //                     setSelectedDates(convertToDateObjects(JSON.parse(dateStrings)));
    //                   } else {
    //                     setSelectedDates([]);
    //                   }

    //     }
    // }, [showAssessentClicked]);
    useEffect(() => {
        if (showAssessentClicked) {
            if (showAssessentClicked?.multi_date) {
                const dateStrings = showAssessentClicked.multi_date.replace(/'/g, '"');
                const parsedDates = JSON.parse(dateStrings);
                const validDates = parsedDates.filter(date => date !== null && date !== "None");
                setSelectedDates(convertToDateObjects(validDates));
            } else {
                setSelectedDates([]);
            }
        }
    }, [showAssessentClicked]);



    const handleSchemeMajorNCCAPStartDate = (schemeItem, e) => {
        const updatedSchemeData = assessmentSchemeData?.map(item => {
            if (item.scheme === schemeItem.scheme) {
                return { ...item, major_nc_cab_start_date: e };
            }
            return item;
        });
        setAssessmentSchemeData(updatedSchemeData);
    }
    const handleSchemeRemark = (schemeItem, e) => {
        const updatedSchemeData = assessmentSchemeData?.map(item => {
            if (item.scheme === schemeItem.scheme) {
                return { ...item, remark: e?.target?.value };
            }
            return item;
        });
        console.log(updatedSchemeData, "updatedSchemeData");

        setAssessmentSchemeData(updatedSchemeData);
    }
    const handleSchemeMajorNCClosureDate = (schemeItem, e) => {
        const updatedSchemeData = assessmentSchemeData?.map(item => {
            console.log(item.scheme === schemeItem.scheme, 'kkkekekkekek')
            if (item.scheme === schemeItem.scheme) {
                return { ...item, major_nc_cab_closer_date: e };
            }
            return item;
        });
        setAssessmentSchemeData(updatedSchemeData);
    }
    const handleSchemeMinorNCCAPstartDate = (schemeItem, e) => {
        const updatedSchemeData = assessmentSchemeData?.map(item => {
            if (item.scheme === schemeItem.scheme) {
                return { ...item, minor_nc_cab_start_date: e };
            }
            return item;
        });
        setAssessmentSchemeData(updatedSchemeData);
    }
    const handleSchemeMinorNCClosureDate = (schemeItem, e) => {
        const updatedSchemeData = assessmentSchemeData?.map(item => {
            if (item.scheme === schemeItem.scheme) {
                return { ...item, minor_nc_cab_end_date: e };
            }
            return item;
        });
        setAssessmentSchemeData(updatedSchemeData);
    }

    // const handleUpdateAssessmentPlanner = async () => {
    //     try {
    //         const data = assessmentPlannerDetails
    //         data.scheme = assessmentSchemeData
    //         const response = await axios.put(`${API_URL}/cab/assessor/planner/${showAssessentClicked?.id}/update/`, data);
    //         toast.success("Assessment Planner Updated Successfully")
    //         setAssessmentPlannerDetails({
    //             ...assessmentPlannerDetails,
    //             cab: "",
    //             assessment_type: "",
    //             assessment_start_date: "",
    //             assessment_end_date: "",
    //         })
    //         handleClose();
    //         getAssessmentPlannerData();
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    useEffect(() => {
        if (assessmentPlannerDetails?.cab) {
            const filterData = cabUserData?.find((item) => item?.id === assessmentPlannerDetails?.cab);
            setSchemeData(filterData?.scheme_names);
        }
    }, [assessmentPlannerDetails?.cab])


    return (<>
        <Modal
            id='assessment-fee'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered '
            show={show}
            onHide={handleClose}
            backdrop={true}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Assessment Planner</Modal.Title>
            </Modal.Header>


            <Modal.Body>

                <div className="container">
                    <div className="row p-5">

                        <div className="mb-5 col-6">
                            <label className="required form-label">CAB </label>
                            <Select
                                className='react-select-styled react-select-solid '
                                isDisabled
                                name="User"
                                // options={roleInAssessment?.map(item => ({ value: item?.id, label: item?.name }))}
                                // value={roleInAssessment?.length ? { value: createJobDetail?.assessor_role, label: roleInAssessment.find(item => item.id === createJobDetail?.assessor_role)?.name } : null}
                                options={cabUser}
                                classNamePrefix='react-select'
                                value={assessmentPlannerDetails?.cab ? { value: assessmentPlannerDetails?.cab, label: cabUser?.find(item => item.value === assessmentPlannerDetails?.cab)?.label } : null}
                                onChange={(e) => handleCabUserChange(e)}

                            />

                        </div>
                        {/* <div className="mb-5 col-6">
                            <label className="required form-label">CAB Scheme</label>
                            <Select
                                className='react-select-styled react-select-solid '
                                isMulti

                                name="User"
                                // options={roleInAssessment?.map(item => ({ value: item?.id, label: item?.name }))}
                                // value={roleInAssessment?.length ? { value: createJobDetail?.assessor_role, label: roleInAssessment.find(item => item.id === createJobDetail?.assessor_role)?.name } : null}
                                options={schemeData?.map(item => ({ value: item?.id, label: item?.name }))}

                                classNamePrefix='react-select'
                                // value={assessmentPlannerDetails?.scheme ? { value: assessmentPlannerDetails?.scheme, label: schemeData?.find(item => item.id === assessmentPlannerDetails?.scheme)?.name } : null}
                                onChange={(e) => handleCabSchemeChange(e)}

                            />
                        </div> */}


                    </div>
                    {showAssessentClicked?.schemeData?.length ? (
                        <table className="table scheme_table">
                            <thead>
                                <tr>
                                    <th >Scheme Name</th>
                                    {/* <th >Assessment Duration</th> */}
                                    <th >Remark</th>
                                    {/* <th >Assessor Name</th> */}
                                    <th>In case of witness assessment,Please write IAF Codes for QMS,EMS and OHS, Sub Categories for FSMS , technical areas for MDQMS </th>
                                    <th >Major NC CAP Start Date</th>
                                    <th >Major NC Closure Date</th>
                                    <th >Minor NC CAP start Date</th>
                                    <th >Minor NC Closure Date</th>
                                    <th>In case the IAF Code or cateogry or technical area is not written in  witness report, please write the scope</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showAssessentClicked?.schemeData.map((item, index) => (
                                    <>
                                        <tr key={index}>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                    value={item?.scheme_short_name}
                                                    disabled
                                                />
                                            </td>
                                            {/* <td>
                                            <input
                                                type="number"
                                                className="form-control form-control-solid"
                                                placeholder="--"
                                                value={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).assessment_duration : ""}


                                                onChange={(e) => handleSchemeAssetDuration(item, e)}

                                            />
                                        </td> */}
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                    defaultValue={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).remark : ""}
                                                    onChange={(e) =>
                                                        handleSchemeRemark(item, e)
                                                        // console.log(e?.target.value)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                    // value={item?.scheme_name}
                                                    disabled
                                                />
                                            </td>

                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).major_nc_cab_start_date : ""}

                                                    // selected={assessmentPlannerDetails?.major_nc_cab_start_date}
                                                    onChange={(date) => handleSchemeMajorNCCAPStartDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />

                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).major_nc_cab_closer_date : ""}


                                                    // selected={assessmentPlannerDetails?.major_nc_cab_closer_date}
                                                    onChange={(date) => handleSchemeMajorNCClosureDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).minor_nc_cab_start_date : ""}

                                                    // selected={assessmentPlannerDetails?.minor_nc_cab_start_date}
                                                    onChange={(date) => handleSchemeMinorNCCAPstartDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <ReactDatePicker
                                                    className='react_date_picker form-control form-control-solid '
                                                    selected={assessmentSchemeData?.find(i => i.scheme === item.scheme) ? assessmentSchemeData?.find(i => i.scheme === item.scheme).minor_nc_cab_end_date : ""}

                                                    // selected={assessmentPlannerDetails?.minor_nc_cab_end_date}
                                                    onChange={(date) => handleSchemeMinorNCClosureDate(item, date)
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid"
                                                    placeholder="--"
                                                    // value={item?.scheme_name}
                                                    disabled
                                                />
                                            </td>
                                        </tr>

                                    </>
                                ))}
                            </tbody>
                        </table>
                    ) : null}



                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Number</label>
                            <input
                                type="text"
                                className="form-control form-control-solid"
                                placeholder="--"
                                value={assessmentPlannerDetails?.assessment_number}
                                onChange={(e) => handleAssessmentNumberChange(e)}
                            />
                        </div>
                    </div>



                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Type</label>
                            <Select
                                className='react-select-styled react-select-solid '
                                isDisabled

                                name="User"
                                options={assessmentList}
                                classNamePrefix='react-select'
                                onChange={(e) => handleAssessmentTypeChange(e)}
                                value={assessmentPlannerDetails?.assessment_type ? { value: assessmentPlannerDetails?.assessment_type, label: assessmentList?.find(item => item.value === assessmentPlannerDetails?.assessment_type)?.label } : null}



                            />
                        </div>
                    </div>
                    {/* <div className="row p-5">


                        <div className="mb-5 col-6 d-flex align-items-end">
                            <div className="d-flex gap-5">
                                <button type='button' className='btn btn-primary' onClick={() => setShowNewAssessmentModal(true)} >
                                    <KTIcon iconName='plus' className='fs-2' />
                                    Create New Assessment Type
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Remark</label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.remark}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, remark: e.target.value })
                                }

                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Duration </label>
                            <input
                                type="number" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.assessment_duration}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessment_duration: e.target.value })
                                }

                            />
                        </div>


                    </div> */}
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="form-label">Assessment Dates </label>
                            <br />
                            {/* <ReactDatePicker
                                placeholderText='Select Dates'
                                className='react_date_picker form-control form-control-solid'
                                selectsRange={true}
                                startDate={dateRange[0]}
                                endDate={dateRange[1]}
                                onChange={handleDateChange}
                                isClearable={true}
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                            /> */}
                            <ReactDatePicker
                                placeholderText='Select Dates'
                                className='uaf-datepicker'
                                selectedDates={selectedDates}
                                selectsMultiple
                                onChange={handleDateChange}
                                shouldCloseOnSelect={false}
                                disabledKeyboardNavigation
                                showMonthDropdown
                                showYearDropdown
                            />


                        </div>





                    </div>

                    {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className=" form-label">Major NC CAP Start Date
                            </label>
                            <br />

                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '

                                selected={assessmentPlannerDetails?.major_nc_cab_start_date}
                                onChange={(date) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, major_nc_cab_start_date: date })
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className=" form-label">Major NC Closure Date</label>
                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '

                                selected={assessmentPlannerDetails?.major_nc_cab_closer_date}
                                onChange={(date) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, major_nc_cab_closer_date: date })
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>




                    </div> */}
                    {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className=" form-label">Minor NC CAP start Date
                            </label>
                            <br />

                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '

                                selected={assessmentPlannerDetails?.minor_nc_cab_start_date}
                                onChange={(date) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, minor_nc_cab_start_date: date })
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className=" form-label">Minor NC Closure Date                            </label>
                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '

                                selected={assessmentPlannerDetails?.minor_nc_cab_end_date}
                                onChange={(date) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, minor_nc_cab_end_date: date })
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />

                        </div>




                    </div> */}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="success"
                    onClick={handleEditAssessor}
                // OnEmailSend();
                // Here you can use editorData for further processing

                >
                    Update
                </Button>
            </Modal.Footer>



        </Modal>
        {/* <NewAssementModal show={showNewAssessmentModal} handleClose={() => setShowNewAssessmentModal(false)} assessmentType={assessmentType} setAssessmentType={setAssessmentType} handleNewAssessmentType={handleNewAssessmentType} /> */}

    </>
    );
};
export default EditAssessmentModal;