    /* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid '>

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/skyline.jpg')})` }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-top-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12 ' >
            <div className='d-grid'>
              <div className=' text-center'>
              <img alt='Logo' style={{width:"200px"}} src={toAbsoluteUrl('/media/logos/uaf_logo.png')} className='' />
                {/* <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon-32x32.png')} className='' /> */}
              </div>

            </div>
          </Link>
          {/* end::Logo */}


          {/* begin::Title */}
          {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Unify and Simplify Cybersecurity
          </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className='text-white  text-center fs-18'>
            Depart from spreadsheets and adopt innovation to manage your cyber risks and compliance obligations.
          </div> */}
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
       {/* begin::Body */}
       <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}


      </div>
      {/* end::Body */}

    </div>
  )
}

export { AuthLayout }
