import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  uniqueCab: string | undefined
}

interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count: string
}

const OtherProgram = ({uniqueCab}: Props) => {
  const [schemeData, setSchemeData] = useState<any[]>([])
  console.log(schemeData, 'this is schemeData')
  const [selectedScheme, setSelectedScheme] = useState<string>()
  const [schemeName, setSchemeName] = useState<string>()
  const [transferCertificate, setTransferCertificate] = useState<transferCertificateData[]>([])
  const [witnessPlanningYear, setWitnessPlanningYear] = useState<string>('')
  const [witnessConductingYear, setConductingPlanningYear] = useState<Date | null>(null)
  const [otherProgramDetails, setOtherProgramDetails] = useState<any[]>([])
  const [witnessDateAndAssessmentNumber, setWitnessDateAndAssessmentNumber] = useState<string>('')
  const [clientFilesReview, setClientFilesReview] = useState<string>('')

  const cabID = uniqueCab

  useEffect(() => {
    if (cabID && selectedScheme) {
      const fetchTransferCertificate = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=${selectedScheme}`
          )
          setTransferCertificate(response?.data?.certificates)
        } catch (error) {
          console.error('Error fetching standard data:', error)
        }
      }
      fetchTransferCertificate()
    }
  }, [cabID, selectedScheme])
  const fetchOtherProgram = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/cab/global/data/scheme/filter/?cab=${cabID}&scheme=${selectedScheme}`
      )
      setOtherProgramDetails(response?.data.reverse())
    } catch (error) {
      console.error('Error fetching standard data:', error)
    }
  }
  useEffect(() => {
    if (cabID && selectedScheme) {
    
      fetchOtherProgram()
    }
  }, [cabID, selectedScheme])

  useEffect(() => {
    const fetchScheme = async () => {
      try {
        const response = await axios.get(`${API_URL}/global/scheme/list/`)
        const filteredData = response.data.map((scheme: any) => ({
          id: scheme.id,
          short_name: scheme.short_name,
          name: scheme.name,
        }))
        setSchemeData(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchScheme()
  }, [])

  const handleSchemeSelect = (e) => {
    setSelectedScheme(e.value)
    setSchemeName(e.short_name)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const dataToSubmit = {
      cab: cabID,
      scheme: selectedScheme,
      witness_planning_year: witnessPlanningYear ,
      witness_conducting_year: witnessConductingYear ? witnessConductingYear.getFullYear() : null,
      witness_date_assessment: witnessDateAndAssessmentNumber,
      client_file_review: clientFilesReview,
    }

    try {
      await axios.post(`${API_URL}/cab/global/data/scheme/create/`, dataToSubmit)
      toast.success('Data submitted successfully')
      fetchOtherProgram();
      setWitnessPlanningYear('');
      setConductingPlanningYear(null);
      setWitnessDateAndAssessmentNumber('');
      setClientFilesReview('');
    } catch (error) {
      // console.error('Error submitting data:', error)
    }
  }

  return (
    <>
      
        <div className='mb-5 col-6'>
          <label className='form-label'>Scheme Name</label>
          <Select
            className='react-select-styled react-select-solid'
            classNamePrefix='react-select'
            placeholder='Select Scheme'
            options={schemeData?.map((item) => ({
              label: `${item?.short_name}`,
              value: item?.id,
              short_name: item?.short_name,
            }))}
            onChange={handleSchemeSelect}
          />
        </div>
        <div className='mb-5 mb-xl-8'>
          <div className='card-header border-0 py-5 d-flex mb-5'>
            <h3 className='card-title align-items-start flex-column mt-2'>
              <span className='card-label fw-bold fs-1'>{schemeName} Assessment</span>
            </h3>
          </div>
          <div className='card-body py-3'>
          {otherProgramDetails.length > 0 ? (
            <div className='table-responsive'>
              <table className='align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Witness planning year
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Scheme accredited
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Witness to be planned in the year / Witness Date and Assessment Number
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Planning the review of client files or verification of adequate competence for
                      non-critical codes
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total number of certificates issued, including details of the six countries
                      with the highest number of certificates issued since accreditation.
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total number of certificates transferred, including details of the six
                      countries with the highest number of certificates transferred since
                      accreditation.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                      {otherProgramDetails.map((item, index) => (
                        <tr key={index}>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {item ?.witness_planning_year}
                              </div>
                            </div>
                          </td>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {schemeName}
                              </div>
                            </div>
                          </td>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {item?.witness_conducting_year}
                                <textarea
                                  value={item?.witness_date_assessment}
                                 readOnly
                                  className='form-control'
                                  rows={2}
                                ></textarea>
                              </div>
                            </div>
                          </td>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <textarea
                                  value={item?.client_file_review}
                                  readOnly
                                  className='form-control'
                                  rows={4}
                                ></textarea>
                              </div>
                            </div>
                          </td>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {transferCertificate.map((transferItem, transferIndex) => (
                                  <div key={transferIndex}>
                                    {transferItem.country__name}: {transferItem.total_count}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </td>
                          <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {transferCertificate.map((transferItem, transferIndex) => (
                                  <div key={transferIndex}>
                                    {transferItem.country__name}: {transferItem.transferred}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                   
                  
                </tbody>
              </table>
            </div>) : null}
            <form onSubmit={handleSubmit}>
          {schemeName &&  <div className='table-responsive mt-8'>
              <table className='align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Witness planning year
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Scheme accredited
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Witness to be planned in the year / Witness Date and Assessment Number
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Planning the review of client files or verification of adequate competence for
                      non-critical codes
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total number of certificates issued, including details of the six countries
                      with the highest number of certificates issued since accreditation.
                    </th>
                    <th
                      style={{border: '1px solid #dddddd', padding: '8px'}}
                      className='ps-4 min-w-150px rounded-start'
                    >
                      Total number of certificates transferred, including details of the six
                      countries with the highest number of certificates transferred since
                      accreditation.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {/* <DatePicker
                            selected={witnessPlanningYear}
                            onChange={(date: Date) => setWitnessPlanningYear(date)}
                            showYearPicker
                            dateFormat='yyyy'
                            className='form-control mb-2'
                          /> */}
                          <textarea
                            value={witnessPlanningYear}
                            onChange={(e) =>setWitnessPlanningYear(e.target.value)}
                            className='form-control'
                            rows={2}
                          ></textarea>
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>{schemeName}</div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <DatePicker
                            selected={witnessConductingYear}
                            onChange={(date: Date) => setConductingPlanningYear(date)}
                            showYearPicker
                            dateFormat='yyyy'
                            className='form-control mb-2'
                          />
                          <textarea
                            value={witnessDateAndAssessmentNumber}
                            onChange={(e) => setWitnessDateAndAssessmentNumber(e.target.value)}
                            className='form-control'
                            rows={2}
                          ></textarea>
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <textarea
                            value={clientFilesReview}
                            onChange={(e) => setClientFilesReview(e.target.value)}
                            className='form-control'
                            rows={4}
                          ></textarea>
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {transferCertificate.map((item, index) => (
                            <div key={index}>
                              {item.country__name}: {item.total_count}
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {transferCertificate.map((item, index) => (
                            <div key={index}>
                              {item.country__name}: {item.transferred}
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>}
            <div className='mb-5 mt-5'>
              <button type='submit' className='btn btn-primary'>
                Save
                <i className='fa fa-angle-double-right' aria-hidden='true' />
              </button>
            </div>
            </form>
          </div>
        </div>
      
    </>
  )
}

export default OtherProgram
