import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import '../../../../src/_metronic/assets/css/certificateCss.css'
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import remarkicon from '../../../_metronic/assets/images/remark.png'
import { getUserDetails } from '../assessor-mangement/assessorLead/components/AssessorFunction'
import { UserData, IafData } from './UserDataInterface'
import axios from 'axios'


interface FormDataProps {
    userData: UserData
}

const AMSform: React.FC<FormDataProps> = ({ userData }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const myid = userData?.id
    const [userDataget, setUserDataget] = useState<UserData>()
    const [userInfo, setUserInfo] = useState<UserData>()
    console.log("userDataget:", userDataget);

    const [userDataiaf, setUserDataiaf] = useState<IafData[]>([])
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [status, setstatus] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')

    const graduation = 'graduation'
    const work_experience = 'workexperience'
    const training_17011 = 'training17011'
    const training_17021_2015 = 'training1702115'
    const training_17021_2017 = 'training1702117'
    const training9001 = 'training9001'
    const internal_training = 'internal_training'
    const fetchData = async () => {
        try {
            const result = await getUserDetails(myid)
            setUserInfo(result?.details)
            setUserDataget(result?.documents)
            setUserDataiaf(
                result?.iaf_critical.filter(
                    (item) => item.scheme_id === '5e26665a-01e1-41c1-9e29-b2c8a977c252'
                )
            )
            // console.log(result?.documents?.id,"this is user id")
        } catch (error) {
            // Handle errors
            // console.error('Error:', error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    const handlePdfIconClick = (url) => {
        setPdfUrl(url)
        setShowPdfModal(true)
    }
    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfUrl('') // Clear the PDF URL when the modal is closed
    }
    /*Change Document Status To Approve */

    const handleStatusChange = async (statusItem: string) => {
        const endpoints = {
            graduation: `${API_URL}/team/graduation/certification/${userDataget?.id}/update`,
            workexperience: `${API_URL}/team/work/experience/${userDataget?.id}/update`,
            training17011: `${API_URL}/team/training/17011/${userDataget?.id}/update`,
            training1702115: `${API_URL}/team/training/17021-1/${userDataget?.id}/update`,
            training1702117: `${API_URL}/team/training/17021-3/${userDataget?.id}/update`,
            training9001: `${API_URL}/team/training/9001/${userDataget?.id}/update`,
            internal_training: `${API_URL}/team/internal/training/${userDataget?.id}/update`,
        }

        const payloads = {
            graduation: {
                graduation_certification_approved: true,
                graduation_certification_pending: false,
            },
            workexperience: { work_experience_approved: true, work_experience_pending: false },
            training17011: { training_17011_approved: true, training_17011_pending: false },
            training1702115: { training_17021_1_approved: true, training_17021_1_pending: false },
            training1702117: { training_17021_3_approved: true, training_17021_3_pending: false },
            training9001: { training_9001_approved: true, training_9001_pending: false },
            internal_training: { internal_training_approved: true, internal_training_pending: false },
        }

        try {
            const response = await axios.put(endpoints[statusItem], payloads[statusItem])
            if (response.status === 200) {
                toast.success(`${statusItem} Certificate Approved`)
                fetchData()
            }
        } catch (error) {
            // Handle errors
        }
    }

    /*Change Document IAF To Approve */

    const handleStatusIaf = async (id: string) => {
        try {
            const response = await axios.put(`${API_URL}/team/accessor/iaf-critical/${id}/update/`, {
                id_approved: true,
                id_reject: false,
            })
            if (response.status === 200) {
                toast.success(`Certificate Approved`)
                fetchData()
            }
        } catch (error) {
            // Handle errors
        }
    }

    /*Change Document Status To Pending */

    const handleStatusPending = async (statusItem: string) => {
        const endpoints = {
            graduation: `${API_URL}/team/graduation/certification/${userDataget?.id}/update`,
            workexperience: `${API_URL}/team/work/experience/${userDataget?.id}/update`,
            training17011: `${API_URL}/team/training/17011/${userDataget?.id}/update`,
            training1702115: `${API_URL}/team/training/17021-1/${userDataget?.id}/update`,
            training1702117: `${API_URL}/team/training/17021-3/${userDataget?.id}/update`,
            training9001: `${API_URL}/team/training/9001/${userDataget?.id}/update`,
            internal_training: `${API_URL}/team/internal/training/${userDataget?.id}/update`,
        }

        const payloads = {
            graduation: {
                graduation_certification_pending: true,
                graduation_certification_approved: false,
            },
            workexperience: { work_experience_pending: true, work_experience_approved: false },
            training17011: { training_17011_pending: true, training_17011_approved: false },
            training1702115: { training_17021_1_pending: true, training_17021_1_approved: false },
            training1702117: { training_17021_3_pending: true, training_17021_3_approved: false },
            training9001: { training_9001_pending: true, training_9001_approved: false },
            internal_training: { internal_training_pending: true, internal_training_approved: false },
        }

        try {
            const response = await axios.put(endpoints[statusItem], payloads[statusItem])
            if (response.status === 200) {
                toast.error('Document Unapproved Add Remarks')
                fetchData()
            }
        } catch (error) {
            // Handle errors
        }
    }

    /*Change Document IAF To Pending */

    const handlePendingIaf = async (id: string) => {
        try {
            const response = await axios.put(`${API_URL}/team/accessor/iaf-critical/${id}/update/`, {
                id_approved: false,
                id_reject: true,
            })
            if (response.status === 200) {
                toast.error(`Document Unapproved Add Remarks`)
                fetchData()
            }
        } catch (error) {
            // Handle errors
        }
    }

    /*Handle Send Remark */
    const [showRemark, setShowRemark] = useState({})
    const [remarkText, setRemarkText] = useState({})
    const [selectedItemId, setSelectedItemId] = useState(null)

    const handleRemarkIconClick = (itemId) => {
        // Toggle the visibility of the textarea if it's already open
        if (selectedItemId === itemId) {
            setSelectedItemId(null)
        } else {
            // Open the textarea for the clicked item
            setSelectedItemId(itemId)
        }
    }

    const handleChangeIaf = (event, itemId) => {
        const { value } = event.target
        setRemarkText((prevState) => ({
            ...prevState,
            [itemId]: value,
        }))
    }

    const handleIafRemark = (itemId) => {
        // Implement your logic to handle the submission of the remark
        console.log('Remark submitted for item ID:', itemId)
        axios
            .put(`${API_URL}/team/accessor/iaf-critical/${itemId}/update/`, { remark: remarkText[itemId] })
            .then((response) => {
                // Handle success
                // console.log('PUT request successful:', response.data)
                setShowRemark((prevState) => ({
                    ...prevState,
                    [itemId]: false, // Close the text area after submission for a specific row
                }))
                if (response.status === 200) {
                    // Setgrad(false)
                    toast.success('Remarks Sent')
                    setSelectedItemId(null)
                    // console.log(response.data)
                }
                fetchData()
            })
            .catch((error) => {
                // Handle error
                // console.error('Error sending PUT request:', error)
            })
    }

    const getAPIURL = (rowKey) => {
        switch (rowKey) {
            case 'graduation_remark':
                return `${API_URL}/team/graduation/certification/${userDataget?.id}/update`
            case 'work_remark':
                return `${API_URL}/team/work/experience/${userDataget?.id}/update`
            case 'training_17011':
                return `${API_URL}/team/training/17011/${userDataget?.id}/update`
            case 'training_17021_1':
                return `${API_URL}/team/training/17021-1/${userDataget?.id}/update`
            case 'training_17021_3':
                return `${API_URL}/team/training/17021-3/${userDataget?.id}/update`
            case 'training9001':
                return `${API_URL}/team/training/9001/${userDataget?.id}/update`
            case 'internal_training':
                return `${API_URL}/team/internal/training/${userDataget?.id}/update`
            default:
                return '' // Handle default case
        }
    }
    const getRemarkParamName = (rowKey) => {
        switch (rowKey) {
            case 'graduation_remark':
                return 'graduation_certification_remark'
            case 'work_remark':
                return 'work_experience_remark'
            case 'training_17011':
                return 'training_17011_remark'
            case 'training_17021_1':
                return 'training_17021_1_remark'
            case 'training_17021_3':
                return 'training_17021_3_remark'
            case 'training9001':
                return 'training_9001_remark'
            case 'internal_training':
                return 'internal_training_remark'
            // Add more cases as needed
            default:
                return '' // Handle default case
        }
    }

    const handleRemarkClick = (rowKey) => {
        setShowRemark((prevState) => ({
            ...prevState,
            [rowKey]: !prevState[rowKey], // Toggle the state for the clicked row
        }))
    }

    const handleChangeRemark = (event, rowKey) => {
        setRemarkText({ ...remarkText, [rowKey]: event.target.value })
    }
    /* Submit Remark Functionality*/
    const handleSubmitRemark = (rowKey) => {
        // Logic to handle submission of the remark for a specific row
        // Here you can send a PUT request to the appropriate API for the row
        const remarkParamName = getRemarkParamName(rowKey) // Get the parameter name for the remark based on the row
        axios
            .put(getAPIURL(rowKey), { [remarkParamName]: remarkText[rowKey] })
            .then((response) => {
                // Handle success
                // console.log('PUT request successful:', response.data)
                setShowRemark((prevState) => ({
                    ...prevState,
                    [rowKey]: false, // Close the text area after submission for a specific row
                }))
                if (response.status === 200) {
                    // Setgrad(false)
                    toast.success('Remarks Submitted ')
                    // console.log(response.data)
                }
            })
            .catch((error) => {
                // Handle error
                // console.error('Error sending PUT request:', error)
            })
        fetchData()
    }

    /* Submit All Functionality*/
    const handleSubmitfinal = async () => {
        try {
            const response = await axios.put(`${API_URL}/team/document/reviewer/data/update/`, {
                user: userData?.id,
                document_reviewer: true,
            })

            // Handle success
            console.log('PUT request successful:', response.data)
            if (response.status === 200) {
                // Setgrad(false)
                toast.success('Documents Reviewed')
                // console.log(response.data)
            }
        } catch (error) {
            // Handle error
            // console.error('Error sending PUT request:', error)
        }
        fetchData()
    }
    return (
        <div className=''>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-custom-header '>
                    <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                        <div className=''>
                            <img
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                                className='h-60px h-lg-60px app-sidebar-logo-default theme-light-show'
                            />
                        </div>
                        <div className='mt-5'>
                            <h2 style={{ fontSize: '18px' }}>UNITED ACCREDITATION FOUNDATION</h2>
                        </div>
                    </div>
                </div>+
                <div className='px-20 py-3 text-center cf-border-bottom'>
                    <h2 className='cf-text-gray' style={{ fontSize: '14px' }}>
                        Initial Competence Evaluation - AMS
                    </h2>
                </div>
                <div className='px-20 py-3 cf-border-bottom'>
                    <div className='row '>
                        <div className='col-lg-6 col-xl-6 mt-1'>
                            <h2 className='px-5' style={{ fontSize: '16px' }}>
                                Assessor Name / Lead Assessor
                            </h2>
                        </div>
                        <div className='col-lg-6 col-xl-6 text-center mt-1'>
                            <h2 className='' style={{ fontSize: '16px' }}>
                                {userData?.first_name} {userData?.last_name}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='card-body px-20'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                        <thead>
                            <tr
                                style={{ background: '#f7f7f7' }}
                                className='text-start  fw-bold  text-uppercase gs-0'
                            >
                                <th className='px-5 min-w-100px'>Competence Parameters Requirement</th>
                                <th className='px-5 min-w-125px'>Actual</th>
                                <th className='px-5 min-w-150px'>Status</th>
                                <th className='px-5 min-w-120px'>Doc</th>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <th className='px-5 min-w-150px'>Accepted/Deferred</th>
                                        <th className='px-5 min-w-120px'>Remarks</th>
                                    </>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        Under Graduate (Bachelor)*
                                    </div>
                                </td>

                                <td>
                                    {userDataget?.graduation_certification ? (
                                        <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                    ) : (
                                        <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.graduation_certification_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.graduation_certification ? (
                                        <>
                                            <a
                                                onClick={() => handlePdfIconClick(userDataget?.graduation_certification)}
                                            // href='#'

                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.graduation_certification ? (
                                                <>
                                                    <div className='success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px', cursor: 'pointer' }} // Added cursor pointer for better UX
                                                            onClick={() => handleStatusChange(graduation)}
                                                        ></i>
                                                    </div>

                                                    <div className='danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x text-danger'
                                                            style={{ fontSize: '25px', cursor: 'pointer' }} // Added cursor pointer for better UX
                                                            onClick={() => handleStatusPending(graduation)}
                                                        ></i>
                                                    </div>
                                                </>
                                            ) : null}
                                        </td>
                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('graduation_remark')}
                                            />
                                        </td>

                                    </>
                                ) : null}
                            </tr>
                            {showRemark['graduation_remark'] && (
                                <tr>
                                    <td className='align-items-center'>
                                        <textarea
                                            value={remarkText['graduation_remark']}
                                            onChange={(event) => handleChangeRemark(event, 'graduation_remark')}
                                            placeholder='Enter your remark...'
                                            className='form-control mr-2'
                                            rows={3}
                                            style={{ flex: 1 }}
                                        ></textarea>
                                    </td>
                                    <td colSpan={1}></td>
                                    {/* <td></td>
                  <td></td>
                  <td></td> */}
                                    <td className='align-items-center'>
                                        <Button
                                            variant='primary'
                                            onClick={() => handleSubmitRemark('graduation_remark')}
                                        >
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        Work experience in accreditaion/certification process (Minimum 5 years)*
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.work_experience_doc ? (
                                        <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                    ) : (
                                        <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.work_experience_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.work_experience_doc ? (
                                        <>
                                            <a
                                                onClick={() => handlePdfIconClick(userDataget?.work_experience_doc)}
                                                // href='#'
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.work_experience_doc ? (
                                                <>
                                                    <div className=' success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(work_experience)}
                                                        ></i>
                                                    </div>

                                                    <div
                                                        className=' danger_class badge badge-light-danger fw-bold m-2'
                                                        onClick={() => setstatus(false)}
                                                    >
                                                        <i
                                                            className='bi bi-x danger text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(work_experience)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>

                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('work_remark')}
                                            />
                                        </td>
                                    </>
                                ) : null}
                            </tr>
                            {showRemark['work_remark'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['work_remark']}
                                            onChange={(event) => handleChangeRemark(event, 'work_remark')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td> */}
                                    <td colSpan={1}></td>
                                    {/* <td></td>
                  <td></td>
                  <td></td> */}
                                    <td className=' align-items-center'>
                                        <Button variant='primary' onClick={() => handleSubmitRemark('work_remark')}>
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}





                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        UAF internal training for accreditation process*
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.internal_training === true ? (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.internal_training_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>

                                <td>
                                    {userDataget?.internal_training_doc ? (
                                        <>
                                            <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                                onClick={() => handlePdfIconClick(userDataget?.internal_training_doc)}
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.internal_training_doc ? (
                                                <>
                                                    <div className='success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(internal_training)}
                                                        ></i>
                                                    </div>
                                                    <div className='danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x  text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(internal_training)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>
                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('training_17011')}
                                            />
                                        </td>
                                    </>) : null}
                            </tr>
                            {showRemark['internal_training'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['internal_training']}
                                            onChange={(event) => handleChangeRemark(event, 'internal_training')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                                    <td colSpan={1}></td>
                                    <td className=' align-items-center'>
                                        <Button variant='primary' onClick={() => handleSubmitRemark('internal_training')}>
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}











                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        Training on ISO/IEC 17011
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.training_17011 === true ? (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_17011_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>

                                <td>
                                    {userDataget?.training_17011_doc ? (
                                        <>
                                            <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                                onClick={() => handlePdfIconClick(userDataget?.training_17011_doc)}
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.training_17011_doc ? (
                                                <>
                                                    <div className='success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(training_17011)}
                                                        ></i>
                                                    </div>
                                                    <div className='danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x  text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(training_17011)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>
                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('training_17011')}
                                            />
                                        </td>
                                    </>) : null}
                            </tr>
                            {showRemark['training_17011'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['training_17011']}
                                            onChange={(event) => handleChangeRemark(event, 'training_17011')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                                    <td colSpan={1}></td>
                                    <td className=' align-items-center'>
                                        <Button variant='primary' onClick={() => handleSubmitRemark('training_17011')}>
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        Training on ISO/IEC 17021-1
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.training_17021_1 === true ? (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_17021_1_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_17021_1_doc ? (
                                        <>
                                            <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                                onClick={() => handlePdfIconClick(userDataget?.training_17021_1_doc)}
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.training_17021_1_doc ? (
                                                <>
                                                    <div className='success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(training_17021_2015)}
                                                        ></i>
                                                    </div>
                                                    <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x  text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(training_17021_2015)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>
                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('training_17021_1')}
                                            />
                                        </td>
                                    </>) : null}
                            </tr>
                            {showRemark['training_17021_1'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['training_17021_1']}
                                            onChange={(event) => handleChangeRemark(event, 'training_17021_1')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                                    <td colSpan={1}></td>
                                    <td className=' align-items-center'>
                                        <Button
                                            variant='primary'
                                            onClick={() => handleSubmitRemark('training_17021_1')}
                                        >
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        Training on ISO/IEC 17021-3
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.training_17021_3 === true ? (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_17021_3_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_17021_3_doc ? (
                                        <>
                                            <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                                onClick={() => handlePdfIconClick(userDataget?.training_17021_3_doc)}
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.training_17021_3_doc ? (
                                                <>
                                                    <div className='success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(training_17021_2017)}
                                                        ></i>
                                                    </div>
                                                    <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x  text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(training_17021_2017)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>
                                        <td>
                                            {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('training_17021_3')}
                                            />
                                        </td>
                                    </>) : null}
                            </tr>
                            {showRemark['training_17021_3'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['training_17021_3']}
                                            onChange={(event) => handleChangeRemark(event, 'training_17021_3')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                                    <td colSpan={1}></td>
                                    <td className=' align-items-center'>
                                        <Button
                                            variant='primary'
                                            onClick={() => handleSubmitRemark('training_17021_3')}
                                        >
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td className='px-5'>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                        {/* Witness Assessor: */}
                                        Lead Auditor training of ISO 9001
                                    </div>
                                </td>
                                <td>
                                    {userDataget?.training_9001_doc ? (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_9001_approved ? (
                                        <div className='badge badge-light-success fw-bold'>Approved</div>
                                    ) : (
                                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                    )}
                                </td>
                                <td>
                                    {userDataget?.training_9001_doc ? (
                                        <>
                                            <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                                onClick={() => handlePdfIconClick(userDataget?.training_9001_doc)}
                                                className=' me-3'
                                            >
                                                <img src='/media/doc.png' alt='' />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className=' me-3'>
                                                <img src='/media/no-doc.png' alt='' />
                                            </div>
                                        </>
                                    )}
                                </td>
                                {userInfo?.is_verify_accessor === false ? (
                                    <>
                                        <td>
                                            {userDataget?.training_9001_doc ? (
                                                <>
                                                    <div className=' success_class badge badge-light-success fw-bold m-2'>
                                                        <i
                                                            className='bi bi-check text-success'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusChange(training9001)}
                                                        ></i>
                                                    </div>
                                                    <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                                                        <i
                                                            className='bi bi-x  text-danger'
                                                            style={{ fontSize: '25px' }}
                                                            onClick={() => handleStatusPending(training9001)}
                                                        ></i>
                                                    </div>
                                                </>) : null}
                                        </td>
                                        <td>
                                            <img
                                                src={remarkicon}
                                                alt='Remark'
                                                style={{ width: '25px', height: '25px' }}
                                                className='m-2'
                                                onClick={() => handleRemarkClick('training9001')}
                                            />
                                        </td>
                                    </>) : null}
                            </tr>
                            {showRemark['training9001'] && (
                                <tr>
                                    <td className=' align-items-center'>
                                        <textarea
                                            value={remarkText['training9001']}
                                            onChange={(event) => handleChangeRemark(event, 'training9001')}
                                            placeholder='Enter your remark...'
                                            className='form-control'
                                            rows={3}
                                        ></textarea>
                                    </td>
                                    {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                                    <td colSpan={1}></td>
                                    <td className=' align-items-center'>
                                        <Button variant='primary' onClick={() => handleSubmitRemark('training9001')}>
                                            Submit
                                        </Button>
                                    </td>
                                </tr>
                            )}

                            {userDataiaf.map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr>
                                        <td className='px-5'>
                                            <span>{item?.cluster_name}</span>
                                            <div className='text-gray-800 text-hover-primary mb-1'>
                                                {item?.critical_name}
                                            </div>
                                        </td>
                                        <td>
                                            {item?.file ? (
                                                <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                                            ) : (
                                                <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                                            )}
                                        </td>
                                        <td>
                                            {item?.id_approved ? (
                                                <div className='badge badge-light-success fw-bold'>Approved</div>
                                            ) : (
                                                <div className='badge badge-light-danger fw-bold'>Pending...</div>
                                            )}
                                        </td>
                                        <td>
                                            {item?.file ? (
                                                <a onClick={() => handlePdfIconClick(item?.file)} className='me-3'>
                                                    <img src='/media/doc.png' alt='' />
                                                </a>
                                            ) : (
                                                <div className='me-3'>
                                                    <img src='/media/no-doc.png' alt='' />
                                                </div>
                                            )}
                                        </td>
                                        {userInfo?.is_verify_accessor === false ? (
                                            <>
                                                <td>
                                                    {item?.file ? (
                                                        <>
                                                            <div className=' success_class badge badge-light-success fw-bold m-2'>
                                                                <i
                                                                    className='bi bi-check text-success'
                                                                    style={{ fontSize: '25px' }}
                                                                    onClick={() => handleStatusIaf(item?.id)}
                                                                ></i>
                                                            </div>
                                                            <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                                                                <i
                                                                    className='bi bi-x text-danger'
                                                                    style={{ fontSize: '25px' }}
                                                                    onClick={() => handlePendingIaf(item?.id)}
                                                                ></i>
                                                            </div>
                                                        </>) : null}
                                                </td>
                                                <td>
                                                    <img
                                                        src={remarkicon}
                                                        alt='Remark'
                                                        style={{ width: '25px', height: '25px' }}
                                                        className='m-2'
                                                        onClick={() => handleRemarkIconClick(item.id)}
                                                    />
                                                </td>
                                            </>) : null}
                                    </tr>

                                    {selectedItemId === item.id && (
                                        <tr>
                                            <td className='align-items-center'>
                                                <textarea
                                                    value={remarkText[selectedItemId] || ''}
                                                    onChange={(event) => handleChangeIaf(event, selectedItemId)}
                                                    placeholder='Enter your remark...'
                                                    className='form-control'
                                                    rows={3}
                                                ></textarea>
                                            </td>
                                            <td colSpan={1}></td>
                                            <td className='align-items-center'>
                                                <Button variant='primary' onClick={() => handleIafRemark(selectedItemId)}>
                                                    Submit
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Display the PDF in the modal */}
                        {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
                        <iframe src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
                    </Modal.Body>
                </Modal>
                <div className='p-3 text-center cf-border-top'>
                    <p>
                        <span style={{ fontWeight: '600' }}>NOTE:</span> A Technical Expert, having above
                        mentioned work experience in said technical area, shall be additional member along with
                        assessor(s) in cases where assessor(s) is/are falling short of any of above
                        requisite/attributes of competence
                    </p>
                </div>
                <div className='p-3 text-center cf-border-top'>
                    <p>
                        <span style={{ color: 'red' }}>*</span> These requirements are aplicable to document
                        reviewer, office assessor, office lead assessor, witness assessor, member of
                        accreditation review committee
                    </p>
                </div>
                <div className='m-2 text-center '>
                    {userInfo?.is_verify_accessor === false ? (

                        <>  {userDataget?.graduation_certification ? (
                            <Button variant='primary' onClick={() => handleSubmitfinal()}>
                                Save
                            </Button>
                        ) : null}</>
                    ) : null}

                </div>
            </div>
        </div>
    );
}

export default AMSform;