
import React from "react";

const UAFLoader = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.8)",
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 99999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    className="loader-container"
    >

      <div className="logo-container">
      {/* <img src='/media/doc.png' alt='' /> */}
    <img
      src="/media/uaf.gif"
      alt="Site Logo"
      className="logo zooming"
      style={{ width: "100px", height: "auto" }}
    />
  </div>


    </div>
  );
};
export default UAFLoader;
