import React, {useEffect, useState, ChangeEvent} from 'react'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import PhoneInput from 'react-phone-number-input'
import {toast} from 'react-toastify'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
const BASE_URL = process.env.REACT_APP_API_URL

const CertificateDashboard = ({
  certificateDataList,
  totalActive,
  totalSuspended,
  totalWithdrawn,
}) => {
  // const [certificateCount, setCertificateCount] = useState<any>([])
  // const [options, setOptions] = useState<ApexOptions>({
  //   chart: {
  //     id: 'certificate-expiry-chart',
  //     type: 'bar',
  //     height: 350,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   xaxis: {
  //     categories: [], 
  //   },
  //   yaxis: {
  //     title: {
  //       text: 'Number of Certificates',
  //     },
  //   },
  // })

  // useEffect(() => {
  //   const fetchProgrammes = async () => {
  //     try {
       
  //       const responses = await axios.get(`${BASE_URL}/certificate/filter/`)
  //       setCertificateCount(responses?.data)
     
  //     } catch (error) {
  //       console.error('Error fetching programme data:', error)
  //     }
  //   }
  //   fetchProgrammes()
  // }, [])

  // useEffect(() => {
  //   if (certificateCount.length) {
  //     const countsByMonth = countCertificatesByMonth()
  //     const chartData = generateChartData(countsByMonth)
  //     setOptions((prevOptions) => ({
  //       ...prevOptions,
  //       xaxis: {
  //         categories: chartData.map((item) => item.x),
  //       },
  //     }))
  //   }
  // }, [certificateCount])
  // const countCertificatesByMonth = () => {
  //   const currentDate = new Date()
  //   const sixMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 0)
  //   const certificates = certificateCount?.filter(
  //     (cert) => new Date(cert.expiry_date) <= sixMonthsLater
  //   )
  //   console.log(certificates, 'certificates')
  //   const countsByMonth: Record<number, number> = {}
  //   certificates.forEach((cert) => {
  //     const expiryMonth = new Date(cert.expiry_date).getMonth()
  //     countsByMonth[expiryMonth] = (countsByMonth[expiryMonth] || 0) + 1
  //   })
  //   return countsByMonth
  // }
  // const generateChartData = (countsByMonth: Record<number, number>) => {
  //   const months = [
  //     'Jan',
  //     'Feb',
  //     'Mar',
  //     'Apr',
  //     'May',
  //     'Jun',
  //     'Jul',
  //     'Aug',
  //     'Sep',
  //     'Oct',
  //     'Nov',
  //     'Dec',
  //   ]
  //   const data: {x: string; y: number}[] = []
  //   for (let i = 0; i < 6; i++) {
  //     const monthIndex = (new Date().getMonth() + i) % 12
  //     const monthName = months[monthIndex]
  //     data.push({x: monthName, y: countsByMonth[monthIndex] || 0})
  //   }
  //   return data
  // }
  // useEffect(() => {
  //   if (certificateCount?.lenght) {
  //     const countsByMonth = countCertificatesByMonth()
  //     const chartData = generateChartData(countsByMonth)
  //     setOptions((prevOptions) => ({
  //       ...prevOptions,
  //       xaxis: {
  //         categories: chartData.map((item) => item?.x),
  //       },
  //     }))
  //   }
  // }, [certificateCount])

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>CERTIFICATE LIST</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>PROGRAM</th>
                <th className='min-w-140px'>ACTIVE</th>
                <th className='min-w-120px'>SUSPENDED</th>
                <th className='min-w-100px '>WITHDRAWN</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {certificateDataList?.map((item) => (
                <>
                  {item?.programme__name ? (
                    <tr key={item.id}>
                      <td className='min-w-125px ps-4 text-dark fw-bold text-hover-primary  fs-6'>{item?.programme__name}</td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.active}</td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.suspended}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{item?.withdrawn}</td>

                      {/* <td>{getStatusCounts(item.id, 'Active')}</td>
                                        <td>{getStatusCounts(item.id, 'Suspended')}</td>
                                        <td>{getStatusCounts(item.id, 'Withdrawn')}</td> */}
                    </tr>
                  ) : null}
                </>
              ))}
              <tr className='fw-bold'>
                <td className='min-w-125px ps-4'>Total</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>{totalActive}</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>{totalSuspended}</td>
                <td className='text-dark fw-bold text-hover-primary fs-6'>{totalWithdrawn}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
export default CertificateDashboard
