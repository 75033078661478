import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserDataType } from "./AssessorInterface";
import { getUserDetails } from "./assessorLead/components/AssessorFunction";
import CheckboxHierarchy from "./assessorLead/models/CheckboxHierarchy";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import UAFLoader from "../../loader/UafLoader";
import { Modal } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL

interface Props {
    userId: string | undefined;
}
interface LocationState {
    userId?: string;
}
interface UserDocIdInterface {
    id?: string;
}
interface DataItem {
    id: string
    name: string
}
interface IAFCriticalData {
    id: string;
    name: string;
    scheme: string;
    iaf_sector_doc: File | null

}
interface FileState {
    work_experience_doc: File | null
    training_17011_doc: File | null
    training_17021_1_doc: File | null
    training_17021_2_doc: File | null
    training_17021_3_doc: File | null
    training_17021_10_doc: File | null
    training_17024_doc: File | null
    training_17025_doc: File | null
    training_27000_doc: File | null
    training_27005_doc: File | null
    training_27006_doc: File | null
    training_27007_doc: File | null
    training_27008_doc: File | null
    training_22000_doc: File | null
    training_22003_doc: File | null
    training_22003_1_doc: File | null
    training_50001_doc: File | null
    training_50003_doc: File | null
    training_45001_doc: File | null
    training_13485_doc: File | null
    training_9001_doc: File | null
    training_14001_doc: File | null
    internal_training_doc: File | null
    IEC17025_2017_doc: File | null
    IAF_MD_9_2022_doc: File | null
    IAF_MD_8_2022_doc: File | null
    GHTF_SG4_SG3_doc: File | null
    IAF_MD_22_2022_doc: File | null
    IEC17020_2012_doc: File | null
    training_27001_doc: File | null,
}
interface FormState {

    graduation_certification: File | null
    graduation_certification_approved: boolean
    internal_training_approved: boolean
    training_9001_approved: boolean




    IEC17025_2017_approved: boolean
    IEC17025_2017_doc: File | null


    training_17025_approved: boolean
    training_17025_doc: File | null

    training_13485_approved: boolean
    training_14001_approved: boolean
    training_17011_approved: boolean
    training_17021_1_approved: boolean
    training_17021_2_approved: boolean
    training_17021_3_approved: boolean
    training_17021_10_approved: boolean
    training_17024_approved: boolean
    training_22000_approved: boolean
    training_22001_approved: boolean
    training_22003_1_approved: boolean
    training_22003_approved: boolean
    training_27000_approved: boolean
    training_27005_approved: boolean
    training_27006_approved: boolean
    training_27007_approved: boolean
    training_27008_approved: boolean
    training_45001_approved: boolean
    training_50001_approved: boolean
    training_50003_approved: boolean
    work_experience_approved: boolean

    training_27001: boolean,
    training_27001_doc: File | null,
    training_27001_approved: boolean,


    IAF_MD_8_2022: boolean,
    IAF_MD_8_2022_doc: File | null,
    IAF_MD_8_2022_approved: boolean,

    IEC17020_2012: boolean,
    IEC17020_2012_doc: File | null,
    IEC17020_2012_approved: boolean,

    IAF_MD_22_2022: boolean,
    IAF_MD_22_2022_doc: File | null,
    IAF_MD_22_2022_approved: boolean,

    IAF_MD_9_2022: boolean,
    IAF_MD_9_2022_doc: File | null,
    IAF_MD_9_2022_approved: boolean,

    GHTF_SG4_SG3: boolean,
    GHTF_SG4_SG3_doc: File | null,
    GHTF_SG4_SG3_approved: boolean,

    IEC17025_2017: boolean
    training_17025: boolean
    graduation: string
    university_name: string
    graduation_date: string
    work_experience: boolean
    training_17011: boolean
    training_17021_1: boolean
    training_17021_2: boolean
    training_17021_3: boolean
    training_17021_10: boolean
    training_17024: boolean
    training_27000: boolean
    training_27005: boolean
    training_27006: boolean
    training_27007: boolean
    training_27008: boolean
    training_22000: boolean
    training_22003: boolean
    training_22003_1: boolean
    training_50001: boolean
    training_50003: boolean
    training_45001: boolean
    training_13485: boolean
    training_9001: boolean
    training_14001: boolean
    internal_training: boolean
    work_experience_doc: File | null
    training_17011_doc: File | null
    training_17021_1_doc: File | null
    training_17021_2_doc: File | null
    training_17021_3_doc: File | null
    training_17021_10_doc: File | null
    training_17024_doc: File | null
    training_27000_doc: File | null
    training_27005_doc: File | null
    training_27006_doc: File | null
    training_27007_doc: File | null
    training_27008_doc: File | null
    training_22000_doc: File | null
    training_22003_doc: File | null
    training_22003_1_doc: File | null
    training_50001_doc: File | null
    training_50003_doc: File | null
    training_45001_doc: File | null
    training_13485_doc: File | null
    training_9001_doc: File | null
    training_14001_doc: File | null
    internal_training_doc: File | null,
}

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Assessor',
        path: '/accessor/verified/overview/*',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
// const CreateAssessorManaul = ({ editUserId}: Props) => {
const TrainingData: React.FC<Props> = ({ userId }) => {

    const [loader, setLoader] = useState<any>(false);
    const navigate = useNavigate();
    const [userData, setUserData] = useState<UserDataType | null>(null);
    const [userDataInfo, setUserDataInfo] = useState<UserDataType | null>(null);
    const [userCertificate, setUserCertificate] = useState<UserDataType>()
    const [userDocId, setUserDocId] = useState<UserDocIdInterface>()
    const [userIAFData, setUserIAFData] = useState<UserDataType>()
    console.log("userDocId:", userDocId);
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const handlePdfIconClick = (url) => {
        setPdfUrl(url)
        setShowPdfModal(true)
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfUrl('') // Clear the PDF URL when the modal is closed
    }

    // const fetchData = async (id: string) => {

    //     try {
    //         if (id) {
    //             const result = await getUserDetails(id);
    //             setUserData(result.details);
    //             // Assuming these are the correct functions to set the state for certificates and IAF data
    //             setUserCertificate(result.documents);
    //             setUserIAFData(result.iaf_critical);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };
    useEffect(() => {
        if (userId !== null && userId !== undefined) {
            const fetchDataInfo = async () => {
                try {
                    const result = await getUserDetails(userId);

                    setUserCertificate(result.documents);
                    setUserDocId(result?.documents?.id)

                } catch (error) {

                    console.error('Error:', error);
                }
            };
            fetchDataInfo();
        }
    }, [userId]);

    console.log('userId:', userId);
    console.log('userData:', userCertificate);



    const [files, setFiles] = useState<FileState>({
        work_experience_doc: null,
        training_17011_doc: null,
        training_17021_1_doc: null,
        training_17021_2_doc: null,
        training_17021_3_doc: null,
        training_17021_10_doc: null,
        training_17024_doc: null,
        training_27000_doc: null,
        training_27005_doc: null,
        training_27006_doc: null,
        training_27007_doc: null,
        training_27008_doc: null,
        training_22000_doc: null,
        training_22003_doc: null,
        training_22003_1_doc: null,
        training_50001_doc: null,
        training_50003_doc: null,
        training_45001_doc: null,
        training_13485_doc: null,
        training_9001_doc: null,
        training_14001_doc: null,
        training_17025_doc: null,
        internal_training_doc: null,
        IEC17025_2017_doc: null,
        IAF_MD_9_2022_doc: null,
        GHTF_SG4_SG3_doc: null,
        IAF_MD_8_2022_doc: null,
        IAF_MD_22_2022_doc: null,
        IEC17020_2012_doc: null,
        training_27001_doc: null,

    })

    console.log(files, 'PRINTED FILE .......')
    const [value, setValue] = useState<FormState>({

        graduation_certification: null,
        graduation: '',
        graduation_date: '',
        university_name: '',
        work_experience: false,
        IEC17025_2017: false,

        training_27001: false,
        training_27001_doc: null,
        training_27001_approved: true,

        IAF_MD_8_2022: false,
        IAF_MD_8_2022_doc: null,
        IAF_MD_8_2022_approved: true,


        IEC17020_2012: false,
        IEC17020_2012_doc: null,
        IEC17020_2012_approved: true,

        IAF_MD_9_2022: false,
        IAF_MD_9_2022_doc: null,
        IAF_MD_9_2022_approved: true,

        GHTF_SG4_SG3: false,
        GHTF_SG4_SG3_doc: null,
        GHTF_SG4_SG3_approved: true,

        IAF_MD_22_2022: false,
        IAF_MD_22_2022_doc: null,
        IAF_MD_22_2022_approved: true,



        graduation_certification_approved: true,
        internal_training_approved: true,
        training_9001_approved: true,
        training_13485_approved: true,
        training_14001_approved: true,
        training_17011_approved: true,
        training_17021_1_approved: true,
        training_17021_2_approved: true,
        training_17021_3_approved: true,
        training_17021_10_approved: true,
        training_17024_approved: true,
        training_22000_approved: true,
        training_22001_approved: true,
        training_22003_1_approved: true,
        training_22003_approved: true,
        training_27000_approved: true,
        training_27005_approved: true,
        training_27006_approved: true,
        training_27007_approved: true,
        training_27008_approved: true,
        training_45001_approved: true,
        training_50001_approved: true,
        training_50003_approved: true,
        work_experience_approved: true,
        IEC17025_2017_approved: true,

        training_17025_approved: true,

        training_17025: false,
        training_17011: false,
        training_17021_1: false,
        training_17021_2: false,
        training_17021_3: false,
        training_17021_10: false,
        training_17024: false,
        training_27000: false,
        training_27005: false,
        training_27006: false,
        training_27007: false,
        training_27008: false,
        training_22000: false,
        training_22003: false,
        training_22003_1: false,
        training_50001: false,
        training_50003: false,
        training_45001: false,
        training_13485: false,
        training_9001: false,
        training_14001: false,
        internal_training: false,

        IEC17025_2017_doc: null,
        work_experience_doc: null,
        training_17011_doc: null,
        training_17021_1_doc: null,
        training_17021_2_doc: null,
        training_17021_3_doc: null,
        training_17021_10_doc: null,
        training_17024_doc: null,
        training_17025_doc: null,
        training_27000_doc: null,
        training_27005_doc: null,
        training_27006_doc: null,
        training_27007_doc: null,
        training_27008_doc: null,
        training_22000_doc: null,
        training_22003_doc: null,
        training_22003_1_doc: null,
        training_50001_doc: null,
        training_50003_doc: null,
        training_45001_doc: null,
        training_13485_doc: null,
        training_9001_doc: null,
        training_14001_doc: null,
        internal_training_doc: null,
    });

    console.log(value, "setValuesetValuesetValue");

    useEffect(() => {
        if (userCertificate) {
            setValue({

                graduation_certification: userCertificate.graduation_certification || null,
                graduation: userCertificate.graduation || '',
                graduation_date: userCertificate.graduation_date || '',
                university_name: userCertificate.university_name || '',
                work_experience: userCertificate.work_experience || false,

                IAF_MD_22_2022: userCertificate.IAF_MD_22_2022 || false,
                IAF_MD_22_2022_doc: userCertificate.IAF_MD_22_2022_doc || null,
                IAF_MD_22_2022_approved: userCertificate.IAF_MD_22_2022_approved || false,


                training_27001: userCertificate.training_27001 || false,
                training_27001_doc: userCertificate.training_27001_doc || null,
                training_27001_approved: userCertificate.training_27001_approved || false,



                IAF_MD_9_2022: userCertificate.IAF_MD_9_2022 || false,
                IAF_MD_9_2022_approved: userCertificate.IAF_MD_9_2022_approved || false,
                IAF_MD_9_2022_doc: userCertificate.IAF_MD_9_2022_doc || null,

                IAF_MD_8_2022: userCertificate.IAF_MD_8_2022 || false,
                IAF_MD_8_2022_approved: userCertificate.IAF_MD_8_2022_approved || false,
                IAF_MD_8_2022_doc: userCertificate.IAF_MD_8_2022_doc || null,

                IEC17020_2012: userCertificate.IEC17020_2012 || false,
                IEC17020_2012_doc: userCertificate.IEC17020_2012_doc || null,
                IEC17020_2012_approved: userCertificate.IEC17020_2012_approved || false,



                GHTF_SG4_SG3: userCertificate.GHTF_SG4_SG3 || false,
                GHTF_SG4_SG3_doc: userCertificate.GHTF_SG4_SG3_doc || null,
                GHTF_SG4_SG3_approved: userCertificate.GHTF_SG4_SG3_approved || false,


                internal_training_approved: userCertificate.internal_training_approved || true,
                training_9001_approved: userCertificate.training_9001_approved || true,
                graduation_certification_approved: userCertificate.graduation_certification_approved || true,

                training_13485_approved: userCertificate.training_13485_approved || true,
                training_14001_approved: userCertificate.training_14001_approved || true,
                training_17011_approved: userCertificate.training_17011_approved || true,
                training_17021_1_approved: userCertificate.training_17021_1_approved || true,
                training_17021_2_approved: userCertificate.training_17021_2_approved || true,
                training_17021_3_approved: userCertificate.training_17021_3_approved || true,
                training_17021_10_approved: userCertificate.training_17021_10_approved || true,
                training_17024_approved: userCertificate.training_17024_approved || true,
                training_22000_approved: userCertificate.training_22000_approved || true,
                training_22001_approved: userCertificate.training_22001_approved || true,
                training_22003_1_approved: userCertificate.training_22003_1_approved || true,
                training_22003_approved: userCertificate.training_22003_approved || true,
                training_27000_approved: userCertificate.training_27000_approved || true,
                training_27005_approved: userCertificate.training_27005_approved || true,
                training_27006_approved: userCertificate.training_27006_approved || true,
                training_27007_approved: userCertificate.training_27007_approved || true,
                training_27008_approved: userCertificate.training_27008_approved || true,
                training_45001_approved: userCertificate.training_45001_approved || true,
                training_50001_approved: userCertificate.training_50001_approved || true,
                training_50003_approved: userCertificate.training_50003_approved || true,
                work_experience_approved: userCertificate.work_experience_approved || true,
                training_17025_approved: userCertificate.training_17025_approved || true,
                IEC17025_2017_approved: userCertificate.IEC17025_2017_approved || true,



                training_17011: userCertificate.training_17011 || false,
                IEC17025_2017: userCertificate.IEC17025_2017 || false,
                training_17021_1: userCertificate.training_17021_1 || false,
                training_17021_2: userCertificate.training_17021_2 || false,
                training_17021_3: userCertificate.training_17021_3 || false,
                training_17021_10: userCertificate.training_17021_10 || false,
                training_17024: userCertificate.training_17024 || false,
                training_17025: userCertificate.training_17025 || false,
                training_27000: userCertificate.training_27000 || false,
                training_27005: userCertificate.training_27005 || false,
                training_27006: userCertificate.training_27006 || false,
                training_27007: userCertificate.training_27007 || false,
                training_27008: userCertificate.training_27008 || false,
                training_22000: userCertificate.training_22000 || false,
                training_22003: userCertificate.training_22003 || false,
                training_22003_1: userCertificate.training_22003_1 || false,
                training_50001: userCertificate.training_50001 || false,
                training_50003: userCertificate.training_50003 || false,
                training_45001: userCertificate.training_45001 || false,
                training_13485: userCertificate.training_13485 || false,
                training_9001: userCertificate.training_9001 || false,
                training_14001: userCertificate.training_14001 || false,
                internal_training: userCertificate.internal_training || false,
                work_experience_doc: userCertificate.work_experience_doc || null,
                training_17011_doc: userCertificate.training_17011_doc || null,
                training_17021_1_doc: userCertificate.training_17021_1_doc || null,
                training_17021_2_doc: userCertificate.training_17021_2_doc || null,
                training_17021_3_doc: userCertificate.training_17021_3_doc || null,
                training_17021_10_doc: userCertificate.training_17021_10_doc || null,
                training_17024_doc: userCertificate.training_17024_doc || null,
                training_17025_doc: userCertificate.training_17025_doc || null,
                training_27000_doc: userCertificate.training_27000_doc || null,
                training_27005_doc: userCertificate.training_27005_doc || null,
                training_27006_doc: userCertificate.training_27006_doc || null,
                training_27007_doc: userCertificate.training_27007_doc || null,
                training_27008_doc: userCertificate.training_27008_doc || null,
                training_22000_doc: userCertificate.training_22000_doc || null,
                training_22003_doc: userCertificate.training_22003_doc || null,
                training_22003_1_doc: userCertificate.training_22003_1_doc || null,
                training_50001_doc: userCertificate.training_50001_doc || null,
                training_50003_doc: userCertificate.training_50003_doc || null,
                training_45001_doc: userCertificate.training_45001_doc || null,
                training_13485_doc: userCertificate.training_13485_doc || null,
                training_9001_doc: userCertificate.training_9001_doc || null,
                training_14001_doc: userCertificate.training_14001_doc || null,
                internal_training_doc: userCertificate.internal_training_doc || null,

                IEC17025_2017_doc: userCertificate.IEC17025_2017_doc || null,


            });
        }
    }, [userCertificate]);





    const handleGraduate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            graduation_certification: e.target.files ? e.target.files[0] : null,
        })
    }   

    const handleCheckboxChange =
        (name: keyof FormState) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = e.target.checked;

            setValue((prevValue) => ({
                ...prevValue,
                [name]: isChecked,
            }));

            if (!isChecked) {
                setFiles((prevFiles) => ({
                    ...prevFiles,
                    [`${name}_doc`]: null,
                }));

                setValue((prevValue) => ({
                    ...prevValue,
                    [`${name}_doc`]: null,
                }));
            }
        };

    const handleFileChange = (name: keyof FileState) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const relatedField = name.replace('_doc', '') as keyof FormState;
        const isChecked = value[relatedField];

        if (!isChecked) {
            toast.error(
                `Please check the corresponding checkbox before uploading the document for ${relatedField}`
            );
            return;
        }

        if (e.target.files && e.target.files.length > 0) {
            setFiles((prevFiles) => ({
                ...prevFiles,
                [name]: e.target.files![0],
            }));

            setValue((prevValue) => ({
                ...prevValue,
                [name]: e.target.files![0],
            }));
        }
    };
    const handelSubmit = async (event: React.FormEvent) => {
        // if (!Validation()) return;
        event.preventDefault()
        console.log(value, 'Printed Value.......!')

        try {
            setLoader(true);
            const formData = new FormData()

            const validUserId = userId ? userId.toString() : "defaultUserId"; // Provide a default value if needed

            formData.append('user', validUserId);
            formData.append('graduation', value.graduation)
            formData.append('university_name', value.university_name)
            formData.append('graduation_date', value.graduation_date)
            formData.append('work_experience', JSON.stringify(value.work_experience))
            formData.append('training_17011', JSON.stringify(value.training_17011))
            formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
            formData.append('internal_training', JSON.stringify(value.internal_training))
            formData.append('training_17021_2', JSON.stringify(value.training_17021_2))
            formData.append('training_17021_3', JSON.stringify(value.training_17021_3))
            formData.append('training_17021_10', JSON.stringify(value.training_17021_10))
            formData.append('training_17024', JSON.stringify(value.training_17024))
            formData.append('training_17025', JSON.stringify(value.training_17025))
            formData.append('training_27000', JSON.stringify(value.training_27000))
            formData.append('training_27005', JSON.stringify(value.training_27005))
            formData.append('training_27006', JSON.stringify(value.training_27006))
            formData.append('training_27007', JSON.stringify(value.training_27007))
            formData.append('training_27008', JSON.stringify(value.training_27008))
            formData.append('training_22000', JSON.stringify(value.training_22000))
            formData.append('training_22003', JSON.stringify(value.training_22003))
            formData.append('training_22003_1', JSON.stringify(value.training_22003_1))
            formData.append('training_50001', JSON.stringify(value.training_50001))
            formData.append('training_50003', JSON.stringify(value.training_50003))
            formData.append('training_45001', JSON.stringify(value.training_45001))
            formData.append('training_13485', JSON.stringify(value.training_13485))
            formData.append('training_9001', JSON.stringify(value.training_9001))
            formData.append('training_14001', JSON.stringify(value.training_14001))
            formData.append('IEC17025_2017', JSON.stringify(value.IEC17025_2017))

            formData.append('IAF_MD_8_2022', JSON.stringify(value.IAF_MD_8_2022))
            formData.append('IAF_MD_9_2022', JSON.stringify(value.IAF_MD_9_2022))
            formData.append('IAF_MD_22_2022', JSON.stringify(value.IAF_MD_22_2022))


            formData.append('GHTF_SG4_SG3', JSON.stringify(value.GHTF_SG4_SG3))
            formData.append('IEC17020_2012', JSON.stringify(value.IEC17020_2012))
            formData.append('training_27001', JSON.stringify(value.training_27001))

            if (value.training_27001_doc && value.training_27001_doc instanceof File) {
                formData.append('training_27001_doc', value.training_27001_doc)
                formData.append('training_27001_approved', JSON.stringify(true))
            }

            if (value.IEC17020_2012_doc && value.IEC17020_2012_doc instanceof File) {
                formData.append('IEC17020_2012_doc', value.IEC17020_2012_doc)
                formData.append('IEC17020_2012_approved', JSON.stringify(true))
            }

            if (value.IAF_MD_22_2022_doc && value.IAF_MD_22_2022_doc instanceof File) {
                formData.append('IAF_MD_22_2022_doc', value.IAF_MD_22_2022_doc)
                formData.append('IAF_MD_22_2022_approved', JSON.stringify(true))
            }
            if (value.GHTF_SG4_SG3_doc && value.GHTF_SG4_SG3_doc instanceof File) {
                formData.append('GHTF_SG4_SG3_doc', value.GHTF_SG4_SG3_doc)
                formData.append('GHTF_SG4_SG3_approved', JSON.stringify(true))
            }
            if (value.IAF_MD_9_2022_doc && value.IAF_MD_9_2022_doc instanceof File) {
                formData.append('IAF_MD_9_2022_doc', value.IAF_MD_9_2022_doc)
                formData.append('IAF_MD_9_2022_approved', JSON.stringify(true))
            }
            if (value.IAF_MD_8_2022_doc && value.IAF_MD_8_2022_doc instanceof File) {
                formData.append('IAF_MD_8_2022_doc', value.IAF_MD_8_2022_doc)
                formData.append('IAF_MD_8_2022_approved', JSON.stringify(true))
            }

            if (value.IEC17025_2017_doc && value.IEC17025_2017_doc instanceof File) {
                formData.append('IEC17025_2017_doc', value.IEC17025_2017_doc)
                formData.append('IEC17025_2017_approved', JSON.stringify(true))
            }
            if (value.work_experience_doc && value.work_experience_doc instanceof File) {
                formData.append('work_experience_doc', value.work_experience_doc)
                formData.append('work_experience_approved', JSON.stringify(true))
            }
            if (value.internal_training_doc && value.internal_training_doc instanceof File) {
                formData.append('internal_training_doc', value.internal_training_doc)
                formData.append('internal_training_approved', JSON.stringify(true))
            }
            if (value.training_17011_doc && value.training_17011_doc instanceof File) {
                formData.append('training_17011_doc', value.training_17011_doc)
                formData.append('training_17011_approved', JSON.stringify(true))
            }
            if (value.graduation_certification && value.graduation_certification instanceof File) {
                formData.append('graduation_certification', value.graduation_certification)
                formData.append('graduation_certification_approved', JSON.stringify(true))
            }
            if (value.training_17021_1_doc && value.training_17021_1_doc instanceof File) {
                formData.append('training_17021_1_doc', value.training_17021_1_doc)
                formData.append('training_17021_1_approved', JSON.stringify(true))
            }
            if (value.training_17021_2_doc && value.training_17021_2_doc instanceof File) {
                formData.append('training_17021_2_doc', value.training_17021_2_doc)
                formData.append('training_17021_2_approved', JSON.stringify(true))
            }
            if (value.training_17021_3_doc && value.training_17021_3_doc instanceof File) {
                formData.append('training_17021_3_doc', value.training_17021_3_doc)
                formData.append('training_17021_3_approved', JSON.stringify(true))
            }
            if (value.training_17021_10_doc && value.training_17021_10_doc instanceof File) {
                formData.append('training_17021_10_doc', value.training_17021_10_doc)
                formData.append('training_17021_10_approved', JSON.stringify(true))
            }
            if (value.training_17024_doc && value.training_17024_doc instanceof File) {
                formData.append('training_17024_doc', value.training_17024_doc)
                formData.append('training_17024_approved', JSON.stringify(true))
            }
            if (value.training_17025_doc && value.training_17025_doc instanceof File) {
                formData.append('training_17025_doc', value.training_17025_doc)
                formData.append('training_17025_approved', JSON.stringify(true))
            }
            if (value.training_27000_doc && value.training_27000_doc instanceof File) {
                formData.append('training_27000_doc', value.training_27000_doc)
                formData.append('training_27000_approved', JSON.stringify(true))
            }
            if (value.training_27005_doc && value.training_27005_doc instanceof File) {
                formData.append('training_27005_doc', value.training_27005_doc)
                formData.append('training_27005_approved', JSON.stringify(true))
            }
            if (value.training_27006_doc && value.training_27006_doc instanceof File) {
                formData.append('training_27006_doc', value.training_27006_doc)
                formData.append('training_27006_approved', JSON.stringify(true))
            }
            if (value.training_27007_doc && value.training_27007_doc instanceof File) {
                formData.append('training_27007_doc', value.training_27007_doc)
                formData.append('training_27007_approved', JSON.stringify(true))
            }
            if (value.training_27008_doc && value.training_27008_doc instanceof File) {
                formData.append('training_27008_doc', value.training_27008_doc)
                formData.append('training_27008_approved', JSON.stringify(true))
            }
            if (value.training_22000_doc && value.training_22000_doc instanceof File) {
                formData.append('training_22000_doc', value.training_22000_doc)
                formData.append('training_22000_approved', JSON.stringify(true))
            }
            if (value.training_22003_doc && value.training_22003_doc instanceof File) {
                formData.append('training_22003_doc', value.training_22003_doc)
                formData.append('training_22003_approved', JSON.stringify(true))
            }
            if (value.training_22003_1_doc && value.training_22003_1_doc instanceof File) {
                formData.append('training_22003_1_doc', value.training_22003_1_doc)
                formData.append('training_22003_1_approved', JSON.stringify(true))
            }
            if (value.training_50001_doc && value.training_50001_doc instanceof File) {
                formData.append('training_50001_doc', value.training_50001_doc)
                formData.append('training_50001_approved', JSON.stringify(true))
            }
            if (value.training_50003_doc && value.training_50003_doc instanceof File) {
                formData.append('training_50003_doc', value.training_50003_doc)
                formData.append('training_50003_approved', JSON.stringify(true))
            }
            if (value.training_45001_doc && value.training_45001_doc instanceof File) {
                formData.append('training_45001_doc', value.training_45001_doc)
                formData.append('training_45001_approved', JSON.stringify(true))
            }
            if (value.training_13485_doc && value.training_13485_doc instanceof File) {
                formData.append('training_13485_doc', value.training_13485_doc)
                formData.append('training_13485_approved', JSON.stringify(true))
            }
            if (value.training_9001_doc && value.training_9001_doc instanceof File) {
                formData.append('training_9001_doc', value.training_9001_doc)
                formData.append('training_9001_approved', JSON.stringify(true))
            }
            if (value.training_14001_doc && value.training_14001_doc instanceof File) {
                formData.append('training_14001_doc', value.training_14001_doc)
                formData.append('training_14001_approved', JSON.stringify(true))
            }


            let response;
            if (userCertificate) {
                // Update existing data
                response = await axios.put(`${API_URL}/team/training/experience/${userDocId}/update/`, formData);
            } else {
                // Create new data
                response = await axios.post(`${API_URL}/team/assessor/custom/training/experience/post/`, formData);
            }

            if (response.status === 201 || response.status === 200) {
                toast.success('Application submitted successfully. Please select a cluster and upload relevant documents.');
                const resposneId = response?.data?.user_id;
                console.log(resposneId, "resposneId");

                setLoader(false);
                navigate(`/assessor/details/edit/programs/info/${userId}`);
            } else {
                toast.error('Failed to submit application. Please try again.');
                setLoader(false);
            }

        } catch (error) {
            toast.error('something went wrong');
            setLoader(false);
        }
    };
    return (
        <>
            {loader ? <UAFLoader /> : null}
            <form

                onSubmit={handelSubmit}
            >
                {/* qualification */}
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>
                                Qualification<span style={{ color: 'red' }}>*</span>
                            </h3>
                        </div>
                    </div>
                    <div className='card-body p-9'>
                        <div className='row mb-7'>
                            <div className='col-lg-3 fv-row'>
                                <input
                                    type='text'
                                    placeholder='Graduation'
                                    className='form-control form-control-lg form-control-solid mb-3 '
                                    name='graduation'
                                    value={value?.graduation}
                                    onChange={(e) =>
                                        setValue({
                                            ...value,
                                            graduation: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-lg-3 fv-row'>
                                <input
                                    type='text'
                                    placeholder='University Name'
                                    className='form-control form-control-lg form-control-solid mb-3 '
                                    name='university_name'
                                    value={value?.university_name}
                                    onChange={(e) =>
                                        setValue({
                                            ...value,
                                            university_name: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-lg-3 fv-row'>
                                <input
                                    type='text'
                                    name='graduation_date'
                                    value={value?.graduation_date}
                                    className='form-control form-control-lg form-control-solid mb-3 '
                                    placeholder='Year'

                                    onChange={(e) =>
                                        setValue({
                                            ...value,
                                            graduation_date: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-lg-3 fv-row d-flex'>
                                <div className="col-lg-9 ">
                                    <div className='contact-page__input-box text-center'>
                                        <label
                                            htmlFor='graduation_certification'
                                            className={
                                                value.graduation_certification
                                                    ? 'file-uploaded-uploaded'
                                                    : 'file-upload-upload'
                                            }
                                        >
                                            {value.graduation_certification
                                                ? 'Uploaded'
                                                : 'Upload'}
                                        </label>
                                        <input
                                            type='file'
                                            id='graduation_certification'
                                            style={{ display: 'none' }}
                                            onChange={handleGraduate}
                                        />
                                    </div>
                                </div>
                                {value.graduation_certification ? (<>
                                    <div className="col-lg-3">
                                        <a
                                            onClick={() => handlePdfIconClick(value.graduation_certification)}
                                            // href='#'
                                            className=' me-3'
                                        >
                                            <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                        </a>
                                    </div>
                                </>) : null}





                            </div>
                        </div>


                    </div>
                </div>
                {/* Experience */}
                <div className='card mb-5'>
                    <div className='card-header cursor-pointer'>
                        <div className='col-lg-12 card-title m-0 d-flex row'>
                            <div className='col-lg-6'>
                                <h3 className='fw-bolder m-0'>Experience / Training</h3>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-end'>
                                <div style={{ fontWeight: '600', paddingTop: '2px' }}>
                                    Upload Relevant Doc, If you have
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-body '>
                        <div className='row mb-7'>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='work_experience'
                                        name='work_experience'
                                        className=''
                                        checked={value.work_experience}
                                        onChange={handleCheckboxChange('work_experience')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='work_experience'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='work_experience'>
                                        Do you have work experience in accreditaion/certification process (Minimum 5
                                        years)
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.work_experience_doc ? (
                                                <label
                                                    htmlFor='work_experience_doc'

                                                    className={
                                                        files.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.work_experience_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='work_experience_doc'

                                                    className={
                                                        value.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.work_experience_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='work_experience_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('work_experience_doc')}
                                            />

                                        </div>
                                        {value.work_experience_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.work_experience_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>
                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='internal_training'
                                        name='internal_training'
                                        className=''
                                        checked={value.internal_training}
                                        onChange={handleCheckboxChange('internal_training')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='internal_training'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='internal_training'>
                                        Have you done UAF internal training for accreditation process? If yes, then upload the
                                        relevant docs.
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.internal_training_doc ? (
                                                <label
                                                    htmlFor='work_experience_doc'

                                                    className={
                                                        files.internal_training_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.internal_training_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='internal_training_doc'

                                                    className={
                                                        value.internal_training_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.internal_training_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='internal_training_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('internal_training_doc')}
                                            />

                                        </div>
                                        {value.internal_training_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.internal_training_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>




                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17011'
                                        name='training_17011'
                                        className=''
                                        checked={value.training_17011}
                                        onChange={handleCheckboxChange('training_17011')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17011'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17011'>
                                        Have you done Training on ISO/IEC 17011
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17011_doc ? (
                                                <label
                                                    htmlFor='training_17011_doc'

                                                    className={
                                                        files.training_17011_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17011_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17011_doc'

                                                    className={
                                                        value.training_17011_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17011_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17011_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17011_doc')}
                                            />

                                        </div>
                                        {value.training_17011_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17011_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='IEC17020_2012'
                                        name='IEC17020_2012'
                                        className=''
                                        checked={value.IEC17020_2012}
                                        onChange={handleCheckboxChange('IEC17020_2012')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='IEC17020_2012'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='IEC17020_2012'>
                                        Have you done Training on ISO/IEC 17020
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.IEC17020_2012_doc ? (
                                                <label
                                                    htmlFor='IEC17020_2012_doc'

                                                    className={
                                                        files.IEC17020_2012_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.IEC17020_2012_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='IEC17020_2012_doc'

                                                    className={
                                                        value.IEC17020_2012_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.IEC17020_2012_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='IEC17020_2012_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('IEC17020_2012_doc')}
                                            />

                                        </div>
                                        {value.IEC17020_2012_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.IEC17020_2012_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17021_1'
                                        name='training_17021_1'
                                        checked={value.training_17021_1}
                                        onChange={handleCheckboxChange('training_17021_1')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17021_1'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_1'>
                                        Have you done Training on ISO/IEC 17021-1
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17021_1_doc ? (
                                                <label
                                                    htmlFor='training_17021_1_doc'

                                                    className={
                                                        files.training_17021_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17021_1_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17021_1_doc'

                                                    className={
                                                        value.training_17021_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17021_1_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17021_1_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17021_1_doc')}
                                            />

                                        </div>
                                        {value.training_17021_1_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17021_1_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17021_2'
                                        name='training_17021_2'
                                        checked={value.training_17021_2}
                                        onChange={handleCheckboxChange('training_17021_2')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17021_2'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_2'>
                                        Have you done Training on ISO/IEC 17021-2
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17021_2_doc ? (
                                                <label
                                                    htmlFor='training_17021_2_doc'

                                                    className={
                                                        files.training_17021_2_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17021_2_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17021_2_doc'

                                                    className={
                                                        value.training_17021_2_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17021_2_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17021_2_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17021_2_doc')}
                                            />

                                        </div>
                                        {value.training_17021_2_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17021_2_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17021_3'
                                        name='training_17021_3'
                                        checked={value.training_17021_3}
                                        onChange={handleCheckboxChange('training_17021_3')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17021_3'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_3'>
                                        Have you done Training on ISO/IEC 17021-3
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17021_3_doc ? (
                                                <label
                                                    htmlFor='training_17021_3_doc'

                                                    className={
                                                        files.training_17021_3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17021_3_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17021_3_doc'

                                                    className={
                                                        value.training_17021_3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17021_3_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17021_3_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17021_3_doc')}
                                            />

                                        </div>
                                        {value.training_17021_3_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17021_3_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17021_10'
                                        name='training_17021_10'
                                        checked={value.training_17021_10}
                                        onChange={handleCheckboxChange('training_17021_10')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17021_10'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_10'>
                                        Have you done Training on ISO/IEC 17021-10
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17021_10_doc ? (
                                                <label
                                                    htmlFor='training_17021_10_doc'

                                                    className={
                                                        files.training_17021_10_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17021_10_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17021_10_doc'

                                                    className={
                                                        value.training_17021_10_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17021_10_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17021_10_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17021_10_doc')}
                                            />

                                        </div>
                                        {value.training_17021_10_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17021_10_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_17024'
                                        name='training_17024'
                                        checked={value.training_17024}
                                        onChange={handleCheckboxChange('training_17024')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_17024'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17024'>
                                        Have you done Training on ISO/IEC 17024
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_17024_doc ? (
                                                <label
                                                    htmlFor='training_17024_doc'

                                                    className={
                                                        files.training_17024_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_17024_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_17024_doc'

                                                    className={
                                                        value.training_17024_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_17024_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_17024_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_17024_doc')}
                                            />

                                        </div>
                                        {value.training_17024_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_17024_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>




                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='IEC17025_2017'
                                        name='IEC17025_2017'
                                        checked={value.IEC17025_2017}
                                        onChange={handleCheckboxChange('IEC17025_2017')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='IEC17025_2017'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='IEC17025_2017'>
                                        Have you done Training on ISO/IEC 17025
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.IEC17025_2017_doc ? (
                                                <label
                                                    htmlFor='IEC17025_2017_doc'

                                                    className={
                                                        files.IEC17025_2017_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.IEC17025_2017_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='IEC17025_2017_doc'

                                                    className={
                                                        value.IEC17025_2017_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.IEC17025_2017_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='IEC17025_2017_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('IEC17025_2017_doc')}
                                            />

                                        </div>
                                        {value.IEC17025_2017_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.IEC17025_2017_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>






















                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27000'
                                        name='training_27000'
                                        checked={value.training_27000}
                                        onChange={handleCheckboxChange('training_27000')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27000'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27000'>
                                        Have you done Training on ISO/IEC 27000
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27000_doc ? (
                                                <label
                                                    htmlFor='training_27000_doc'

                                                    className={
                                                        files.training_27000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27000_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27000_doc'

                                                    className={
                                                        value.training_27000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27000_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27000_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27000_doc')}
                                            />

                                        </div>
                                        {value.training_27000_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27000_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27001'
                                        name='training_27001'
                                        checked={value.training_27001}
                                        onChange={handleCheckboxChange('training_27001')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27001'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27001'>
                                        Have you done Training on ISO/IEC 27001
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27001_doc ? (
                                                <label
                                                    htmlFor='training_27001_doc'

                                                    className={
                                                        files.training_27001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27001_doc'

                                                    className={
                                                        value.training_27001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27001_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27001_doc')}
                                            />

                                        </div>
                                        {value.training_27001_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27001_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27005'
                                        name='training_27005'
                                        checked={value.training_27005}
                                        onChange={handleCheckboxChange('training_27005')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27005'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27005'>
                                        Have you done Training on ISO/IEC 27005
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27005_doc ? (
                                                <label
                                                    htmlFor='training_27005_doc'

                                                    className={
                                                        files.training_27005_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27005_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27005_doc'

                                                    className={
                                                        value.training_27005_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27005_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27005_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27005_doc')}
                                            />

                                        </div>
                                        {value.training_27005_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27005_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27006'
                                        name='training_27006'
                                        checked={value.training_27006}
                                        onChange={handleCheckboxChange('training_27006')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27006'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27006'>
                                        Have you done Training on ISO/IEC 27006
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27006_doc ? (
                                                <label
                                                    htmlFor='training_27006_doc'

                                                    className={
                                                        files.training_27006_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27006_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27006_doc'

                                                    className={
                                                        value.training_27006_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27006_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27006_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27006_doc')}
                                            />

                                        </div>
                                        {value.training_27006_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27006_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27007'
                                        name='training_27007'
                                        checked={value.training_27007}
                                        onChange={handleCheckboxChange('training_27007')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27007'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27007'>
                                        Have you done Training on ISO/IEC 27007
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27007_doc ? (
                                                <label
                                                    htmlFor='training_27007_doc'

                                                    className={
                                                        files.training_27007_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27007_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27007_doc'

                                                    className={
                                                        value.training_27007_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27007_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27007_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27007_doc')}
                                            />

                                        </div>
                                        {value.training_27007_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27007_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_27008'
                                        name='training_27008'
                                        checked={value.training_27008}
                                        onChange={handleCheckboxChange('training_27008')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_27008'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27008'>
                                        Have you done Training on ISO/IEC 27008
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_27008_doc ? (
                                                <label
                                                    htmlFor='training_27008_doc'

                                                    className={
                                                        files.training_27008_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_27008_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_27008_doc'

                                                    className={
                                                        value.training_27008_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_27008_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_27008_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_27008_doc')}
                                            />

                                        </div>
                                        {value.training_27008_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_27008_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_22000'
                                        name='training_22000'
                                        checked={value.training_22000}
                                        onChange={handleCheckboxChange('training_22000')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_22000'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22000'>
                                        Have you done Training on ISO 22000
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_22000_doc ? (
                                                <label
                                                    htmlFor='training_22000_doc'

                                                    className={
                                                        files.training_22000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_22000_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_22000_doc'

                                                    className={
                                                        value.training_22000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_22000_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_22000_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_22000_doc')}
                                            />

                                        </div>
                                        {value.training_22000_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_22000_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_22003'
                                        name='training_22003'
                                        checked={value.training_22003}
                                        onChange={handleCheckboxChange('training_22003')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_22003'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003'>
                                        Have you done Training on ISO/IEC 22003
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_22003_doc ? (
                                                <label
                                                    htmlFor='training_22003_doc'

                                                    className={
                                                        files.training_22003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_22003_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_22003_doc'

                                                    className={
                                                        value.training_22003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_22003_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_22003_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_22003_doc')}
                                            />

                                        </div>
                                        {value.training_22003_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_22003_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_22003_1'
                                        name='training_22003_1'
                                        checked={value.training_22003_1}
                                        onChange={handleCheckboxChange('training_22003_1')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_22003_1'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003_1'>
                                        Have you done Training on ISO/IEC 22003-1
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_22003_1_doc ? (
                                                <label
                                                    htmlFor='training_22003_1_doc'

                                                    className={
                                                        files.training_22003_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_22003_1_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_22003_1_doc'

                                                    className={
                                                        value.training_22003_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_22003_1_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_22003_1_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_22003_1_doc')}
                                            />

                                        </div>
                                        {value.training_22003_1_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_22003_1_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_50001'
                                        name='training_50001'
                                        className='custom-checkbox'
                                        checked={value.training_50001}
                                        onChange={handleCheckboxChange('training_50001')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_50001'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50001'>
                                        Have you done Training on ISO 50001
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_50001_doc ? (
                                                <label
                                                    htmlFor='training_50001_doc'

                                                    className={
                                                        files.training_50001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_50001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_50001_doc'

                                                    className={
                                                        value.training_50001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_50001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_50001_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_50001_doc')}
                                            />

                                        </div>
                                        {value.training_50001_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_50001_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_50003'
                                        name='training_50003'
                                        className='custom-checkbox'
                                        checked={value.training_50003}
                                        onChange={handleCheckboxChange('training_50003')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_50003'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50003'>
                                        Have you done Training on ISO 50003
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_50003_doc ? (
                                                <label
                                                    htmlFor='training_50003_doc'

                                                    className={
                                                        files.training_50003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_50003_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_50003_doc'

                                                    className={
                                                        value.training_50003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_50003_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_50003_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_50003_doc')}
                                            />

                                        </div>
                                        {value.training_50003_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_50003_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_45001'
                                        name='training_45001'
                                        checked={value.training_45001}
                                        onChange={handleCheckboxChange('training_45001')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_45001'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_45001'>
                                        Have you done Training on ISO 45001
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_45001_doc ? (
                                                <label
                                                    htmlFor='training_45001_doc'

                                                    className={
                                                        files.training_45001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_45001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_45001_doc'

                                                    className={
                                                        value.training_45001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_45001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_45001_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_45001_doc')}
                                            />

                                        </div>
                                        {value.training_45001_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_45001_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_13485'
                                        name='training_13485'
                                        checked={value.training_13485}
                                        onChange={handleCheckboxChange('training_13485')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_13485'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_13485'>
                                        Have you done Training on ISO 13485
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_13485_doc ? (
                                                <label
                                                    htmlFor='training_13485_doc'

                                                    className={
                                                        files.training_13485_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_13485_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_13485_doc'

                                                    className={
                                                        value.training_13485_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_13485_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_13485_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_13485_doc')}
                                            />

                                        </div>
                                        {value.training_13485_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_13485_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_9001'
                                        name='training_9001'
                                        checked={value.training_9001}
                                        onChange={handleCheckboxChange('training_9001')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_9001'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_9001'>
                                        Have you done Training on ISO 9001
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_9001_doc ? (
                                                <label
                                                    htmlFor='training_9001_doc'

                                                    className={
                                                        files.training_9001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_9001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_9001_doc'

                                                    className={
                                                        value.training_9001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_9001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_9001_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_9001_doc')}
                                            />

                                        </div>
                                        {value.training_9001_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_9001_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='training_14001'
                                        name='training_14001'
                                        checked={value.training_14001}
                                        onChange={handleCheckboxChange('training_14001')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='training_14001'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_14001'>

                                        Have you done Training on ISO 14001
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.training_14001_doc ? (
                                                <label
                                                    htmlFor='training_14001_doc'

                                                    className={
                                                        files.training_14001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.training_14001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='training_14001_doc'

                                                    className={
                                                        value.training_14001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.training_14001_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='training_14001_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('training_14001_doc')}
                                            />

                                        </div>
                                        {value.training_14001_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.training_14001_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='IAF_MD_9_2022'
                                        name='IAF_MD_9_2022'
                                        checked={value.IAF_MD_9_2022}
                                        onChange={handleCheckboxChange('IAF_MD_9_2022')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='IAF_MD_9_2022'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='IAF_MD_9_2022'>

                                        Have you done Training on IAF MD 9
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.IAF_MD_9_2022_doc ? (
                                                <label
                                                    htmlFor='IAF_MD_9_2022_doc'

                                                    className={
                                                        files.IAF_MD_9_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.IAF_MD_9_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='IAF_MD_9_2022_doc'

                                                    className={
                                                        value.IAF_MD_9_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.IAF_MD_9_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='IAF_MD_9_2022_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('IAF_MD_9_2022_doc')}
                                            />

                                        </div>
                                        {value.IAF_MD_9_2022_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.IAF_MD_9_2022_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='IAF_MD_8_2022'
                                        name='IAF_MD_8_2022'
                                        checked={value.IAF_MD_8_2022}
                                        onChange={handleCheckboxChange('IAF_MD_8_2022')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='IAF_MD_8_2022'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='IAF_MD_8_2022'>

                                        Have you done Training on IAF MD 8
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.IAF_MD_8_2022_doc ? (
                                                <label
                                                    htmlFor='IAF_MD_8_2022_doc'

                                                    className={
                                                        files.IAF_MD_8_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.IAF_MD_8_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='IAF_MD_8_2022_doc'

                                                    className={
                                                        value.IAF_MD_8_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.IAF_MD_8_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='IAF_MD_8_2022_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('IAF_MD_8_2022_doc')}
                                            />

                                        </div>
                                        {value.IAF_MD_8_2022_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.IAF_MD_8_2022_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>

                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='GHTF_SG4_SG3'
                                        name='GHTF_SG4_SG3'
                                        checked={value.GHTF_SG4_SG3}
                                        onChange={handleCheckboxChange('GHTF_SG4_SG3')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='GHTF_SG4_SG3'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='GHTF_SG4_SG3'>

                                        Have you done Training on GHTF SG4 and SG3
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.GHTF_SG4_SG3_doc ? (
                                                <label
                                                    htmlFor='GHTF_SG4_SG3_doc'

                                                    className={
                                                        files.GHTF_SG4_SG3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.GHTF_SG4_SG3_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='GHTF_SG4_SG3_doc'

                                                    className={
                                                        value.GHTF_SG4_SG3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.GHTF_SG4_SG3_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='GHTF_SG4_SG3_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('GHTF_SG4_SG3_doc')}
                                            />

                                        </div>
                                        {value.GHTF_SG4_SG3_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.GHTF_SG4_SG3_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                            <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                                <div className=' d-flex'>
                                    <input
                                        type='checkbox'
                                        id='IAF_MD_22_2022'
                                        name='IAF_MD_22_2022'
                                        checked={value.IAF_MD_22_2022}
                                        onChange={handleCheckboxChange('IAF_MD_22_2022')}
                                    />
                                    <label
                                        className=' '
                                        style={{ padding: '0 0 0 16px' }}
                                        htmlFor='IAF_MD_22_2022'
                                    ></label>
                                    <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='IAF_MD_22_2022'>

                                        Have you done Training on IAF MD 22
                                    </label>
                                </div>
                                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                                    <div className="d-flex">
                                        <div className="">
                                            {files.IAF_MD_22_2022_doc ? (
                                                <label
                                                    htmlFor='IAF_MD_22_2022_doc'

                                                    className={
                                                        files.IAF_MD_22_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {files.IAF_MD_22_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            ) : (
                                                <label
                                                    htmlFor='IAF_MD_22_2022_doc'

                                                    className={
                                                        value.IAF_MD_22_2022_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                                                    }
                                                >
                                                    {value.IAF_MD_22_2022_doc ? 'Uploaded' : 'Upload'}
                                                </label>
                                            )}

                                            <input
                                                type='file'
                                                id='IAF_MD_22_2022_doc'
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange('IAF_MD_22_2022_doc')}
                                            />

                                        </div>
                                        {value.IAF_MD_22_2022_doc ? (<>
                                            <div>
                                                <a
                                                    onClick={() => handlePdfIconClick(value.IAF_MD_22_2022_doc)}
                                                    // href='#'
                                                    className=' me-3'
                                                >
                                                    <img style={{ width: "45px" }} src='/media/doc.png' alt='' />
                                                </a>
                                            </div>
                                        </>) : null}


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-5'>

                    <button type='submit' className='btn btn-primary'>

                        Submit
                        <i className='fa fa-angle-double-right' aria-hidden='true' />

                    </button>
                </div>

            </form>
            <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Display the PDF in the modal */}
                    {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
                    <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default TrainingData;