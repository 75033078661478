import React from "react";
import FormRenderer from "../../../certificateForms/FormRender";
import { useLocation, useParams } from "react-router-dom";


const VerifiedAssessorDocDetails = () => {
    const { itemId } = useParams(); // Use object destructuring to get itemId
    console.log(itemId, "hello"); // Log itemId
    const data = useLocation()
    console.log(data, "DATA");

    const userData = (data as any)?.state?.userData;
    console.log(userData, "DATA");
    // console.log(formId?.itemId,"hello");
    return (
        <div className='row'>
            <FormRenderer formId={itemId} userData={userData} />
        </div>
    )
}
export default VerifiedAssessorDocDetails