import React, {useEffect, useState, ChangeEvent} from 'react'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import {toast} from 'react-toastify'
import ReactDatePicker from 'react-datepicker'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { startOfMonth, addMonths, setDate } from 'date-fns';
import {KTIcon} from '../../../../_metronic/helpers'

const BASE_URL = process.env.REACT_APP_API_URL
const AddCertificateData = () => {
  const navigate = useNavigate()
  const param = useParams()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  console.log(id, 'hello')

  const userId = param?.id
  // const [auditorModel,setAuditorModel] = useState<any>(false);
  const [auditorModel, setAuditorModel] = useState<boolean>(false)
  const [auditData, setAuditData] = useState<any>()
  const [userData, setUserData] = useState<any>()
  const [auditorData, setAuditorData] = useState<any[]>([])
  const [standardData, setStandardData] = useState<any[]>([])
  const [scopeData, setScopeData] = useState<any[]>([])
  console.log(auditData, 'auditData')
  const [countryList, setCountryList] = useState<any>()

  const [certificateData, setCertificateData] = useState<any>()
  console.log(certificateData, 'certificateData')
  const handleClose = () => {
    setAuditorModel(false)
  }
  const [addCertificateDetail, setAddCertificateDetail] = useState({
    cab: userId,
    certificateId: '',
    programme: '',
    scheme: '',
    standard: '',
    cluster: '',
    crt: '',
    issue_date: '',
    expiry_date: '',
    status: '',
    description: '',
    // organisation fields
    organisation_id: '',
    organisation: '',
    trading_name: '',
    primary_contact: '',
    organisation_phone_number: '',
    organisation_email: '',
    location_certified_address: '',
    city: '',
    state: '',
    post_code: '',
    country: '',
    countryName: '',
    remarks: '',
    initial_certificate_date: '',
    auditors: [
      {
        audit_type: '',
        auditor: [],
        commenced_date: '',
        total_days: '',
      },
    ],
  })
  console.log(addCertificateDetail, 'addCertificateDetail')

  const handleCountryChange = (e) => {
    setAddCertificateDetail({
      ...addCertificateDetail,
      country: e?.value,
      countryName: e?.label,
    })
  }
  const handleAuditorChange = (index, selectedOptions) => {
    const updatedRows = [...rows]
    updatedRows[index].auditor = selectedOptions.map((option) => option.value)
    setRows(updatedRows)
  }
  useEffect(() => {
    if (id) {
      const fetchAudit = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/certificate/new/create/${id}/update`) 
          // const response = await axios.get(`${BASE_URL}/certificate/combined/${id}/patch`)
          setCertificateData(response?.data)
        } catch (error) {
          console.error('Error fetching cluster data:', error)
        }
      }
      fetchAudit()
    }
  }, [id])
  useEffect(() => {
    
      const fetchStandard = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/standard/list/`) 
          // const response = await axios.get(`${BASE_URL}/certificate/combined/${id}/patch`)
          setStandardData(response?.data)
        } catch (error) {
          console.error('Error fetching cluster data:', error)
        }
      }
      fetchStandard()
   
  }, [])
  useEffect(() => {
    
      const fetchScopes = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/global/cluster/list/`) 
          // const response = await axios.get(`${BASE_URL}/certificate/combined/${id}/patch`)
          setScopeData(response?.data)
        } catch (error) {
          console.error('Error fetching cluster data:', error)
        }
      }
      fetchScopes()
   
  }, [])

  const fetchAuditor = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/audit/filter/?certification=${id}`)
      const auditResponse = response?.data

      // Map the fetched audit data into the `rows` structure
      const formattedRows = auditResponse.map((audit) => ({
        id: audit.id,
        audit_type: audit.audit_type,
        auditor: audit.auditor,
        commenced_date: audit.commenced_date,
        total_days: audit.total_days,
      }))

      // Update the `rows` state
      setRows(formattedRows)
    } catch (error) {
      console.error('Error fetching audit data:', error)
    }
  }
  useEffect(() => {
    if (id) {
      fetchAuditor()
    }
  }, [id])

  useEffect(() => {
    if (certificateData) {
      setAddCertificateDetail({
        ...addCertificateDetail,
        cab: certificateData?.cab,
        certificateId: certificateData?.certificateId,
        programme: certificateData?.programme,
        scheme: certificateData?.scheme,
        standard: certificateData?.standard,
        cluster: certificateData?.cluster,
        crt: certificateData?.crt,
        issue_date: certificateData?.issue_date,
        expiry_date: certificateData?.expiry_date,
        status: certificateData?.status,
        description: certificateData?.description,
        organisation_id: certificateData?.organisation_id,
        organisation: certificateData?.organisation,
        trading_name: certificateData?.trading_name,
        primary_contact: certificateData?.primary_contact,
        organisation_phone_number: certificateData?.organisation_phone_number,
        organisation_email: certificateData?.organisation_email,
        location_certified_address: certificateData?.location_certified_address,
        city: certificateData?.city,
        state: certificateData?.state,
        post_code: certificateData?.post_code,
        country: certificateData?.country,
      })
    }
  }, [certificateData])

  const [auditorDetails, setAuditorDetails] = useState<any>({
    audit_type: '',
    auditor: [],
    auditor_email: '',
    commenced_date: '',
    total_days: '',
  })
  const AuditType = [
    {value: 'Stage 1', label: 'Stage 1'},
    {value: 'Stage 2', label: 'Stage 2'},
    {value: 'Surveillance', label: 'Surveillance'},
    {value: 'Re-Certification', label: 'Re-Certification'},
    {value: 'Special Audit', label: 'Special Audit'},
  ]

  const auditorTypeChange = (e) => {
    setAuditorDetails({...auditorDetails, audit_type: e?.value})
  }

  const handleAuditorChangeAdd = (selectedOptions) => {
    const auditorIds = selectedOptions ? selectedOptions.map((option) => option.value) : []
    setAuditorDetails({
      ...auditorDetails,
      auditor: auditorIds,
    })
  }

  // get country list
  const fetchCountryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/list/country/`)
      setCountryList(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCountryData()
  }, [])

  const certificationType = [
    {
      label: 'Initial',
      value: 'Initial',
    },
    {label: 'Transferred', value: 'Transferred'},
    {label: 'Re-Certification', value: 'ReCertification'},
  ]
  const certificationStatus = [
    {label: 'Active', value: 'Active'},
    {label: 'Suspended', value: 'Suspended'},
    {label: 'Withdrawn', value: 'Withdrawn'},
    {label: 'Deleted', value: 'Deleted'},
    {label: 'Re-Certification', value: 'Re-Certification'},
  ]

  const handleClusterChange = (e) => {
    setAddCertificateDetail({
      ...addCertificateDetail,
      cluster: e?.map((i) => i?.value),
    })
  }
  const handleSchemeChange = (e) => {
    setAddCertificateDetail({...addCertificateDetail, scheme: e.value})
  }
  const handleProgrammeChange = (e) => {
    setAddCertificateDetail({...addCertificateDetail, programme: e.value})
  }
  const handleStandardChange = (e) => {
    setAddCertificateDetail({...addCertificateDetail, standard: e.value})
  }
  const handleStatusChange = (e) => {
    setAddCertificateDetail({...addCertificateDetail, status: e.value})
  }
  const handleCertificateTypeChange = (e) => {
    setAddCertificateDetail({...addCertificateDetail, crt: e.value})
  }

  const handleDeleteAuditor = async (row_id) => {
    try {
      const response = await axios.post(`${BASE_URL}/certificate/new/create/`)
      toast.success('Auditor Delete Successfully')
    } catch (error) {
      console.log(error)
    }
  }

  const getUserDetails = () => {
    axios
      .get(`${BASE_URL}/account/patch/user/${userId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getUserDetails()
  }, [userId])

  const fetchAuditors = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/auditor/filter/data/?cab=${userId}`)

      setAuditorData(response?.data)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }

  useEffect(() => {
    if (userId) {
      fetchAuditors()
    }
  }, [userId])

  const ValidateFields = () => {
    if (!addCertificateDetail?.certificateId) {
      toast.error('Please Enter Certificate Id')
      return false
    }
    if (!addCertificateDetail?.crt) {
      toast.error('Please Enter Certificate Type')
      return false
    }
    if (!addCertificateDetail?.programme) {
      toast.error('Please Select Program')
      return false
    }
    if (!addCertificateDetail?.scheme) {
      toast.error('Please Select Scheme')
      return false
    }
    if (!addCertificateDetail?.standard) {
      toast.error('Please Select Certificate Standard')
      return false
    }
    if (!addCertificateDetail?.cluster) {
      toast.error('Please Select Scope/Technical Area')
      return false
    }
    if (!addCertificateDetail.issue_date) {
      toast.error('Please Enter Original Issue Date')
      return false
    }
    if (!addCertificateDetail.expiry_date) {
      toast.error('Please Enter Expiry Date')
      return false
    }

    if (!addCertificateDetail?.status) {
      toast.error('Please Select Status')
      return false
    }
    if (!addCertificateDetail?.description) {
      toast.error('Add Description')
      return false
    }
    if (
      addCertificateDetail?.status === 'Suspended' ||
      addCertificateDetail?.status === 'Withdrawn' ||
      addCertificateDetail?.status === 'Deleted'
    ) {
      if (!addCertificateDetail?.remarks) {
        toast.error('Add Remark')
        return false
      }
    }

    if (!addCertificateDetail?.organisation) {
      toast.error('Please Enter Organisation')
      return false
    }
    if (!addCertificateDetail?.organisation_email) {
      toast.error('Please Enter Organisation Email')
      return false
    }
    if (!addCertificateDetail?.location_certified_address) {
      toast.error('Please Enter Address')
      return false
    }
    if (!addCertificateDetail?.city) {
      toast.error('Please Enter City')
      return false
    }

    if (!addCertificateDetail?.country) {
      toast.error('Please Enter Country')
      return false
    }
    if (
      addCertificateDetail?.crt === 'Transferred' ||
      addCertificateDetail?.crt === 'ReCertification'
    ) {
      if (!addCertificateDetail.initial_certificate_date) {
        toast.error('Add Initial Certificate Date')
        return false
      }
    }
    return true
  }

  const handleAddCetificate = async () => {
    if (!ValidateFields()) return
    try {
      const response = await axios.post(`${BASE_URL}/certificate/new/create/`, addCertificateDetail)
      toast.success('Certificate Created Successfully')
      navigate(`/crafted/pages/profile/${userId}/certificates`)

      setAddCertificateDetail({
        ...addCertificateDetail,
        cab: userId,
        certificateId: '',
        programme: '',
        scheme: '',
        standard: '',
        cluster: '',
        crt: '',
        issue_date: '',
        expiry_date: '',
        status: '',
        description: '',
        // organisation fields
        organisation_id: '',
        organisation: '',
        trading_name: '',
        primary_contact: '',
        organisation_phone_number: '',
        organisation_email: '',
        location_certified_address: '',
        city: '',
        state: '',
        post_code: '',
        country: '',
        countryName: '',
        remarks: '',
        initial_certificate_date: '',
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateCetificate = async () => {
    if (!ValidateFields()) return
    try {
      if (certificateData) {
        // If editDataClick is present, hit the update API
        const response = await axios.put(
          `${BASE_URL}/certificate/new/create/${id}/update/`,
          addCertificateDetail
        )
        toast.success('Certificate Updated Successfully')

       
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const [rows, setRows] = useState([{ id: 1, auditType: '', auditor: '', date: '', totalDays: '' }]);

  const [rows, setRows] = useState([
    {
      id: '',
      audit_type: '',
      auditor: [],
      commenced_date: '',
      total_days: '',
    },
  ])
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows]
    updatedRows[index][field] = value
    setRows(updatedRows)
  }

  const handleAddRow = () => {
    const newRow = {
      id: '',
      audit_type: '',
      auditor: [],
      commenced_date: '',
      total_days: '',
    }
    setRows([...rows, newRow])
  }
  useEffect(() => {
    if (rows?.length > 0) {
      setAddCertificateDetail((prevDetails) => ({
        ...prevDetails,
        auditors: rows,
      }))
    }
  }, [rows])

  const handleDeleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index)) // Filter out the row at the given index
  }



  const handleAuditorupdate = async () => {
    try {
      const updatePromises = rows.map((row) =>
        axios.put(`${BASE_URL}/certificate/audit/${row.id}/update/`, row)
      )
      await Promise.all(updatePromises)

      toast.success('Auditors Updated Successfully')
    } catch (error) {
      console.log(error)
      toast.error('Failed to update auditors')
    }
  }
  const handleSaveAuditor = async () => {
    const data = {
      ...auditorDetails,
      cab: userId,
      certification: id,
    }
    try {
      const response = await axios.post(`${BASE_URL}/certificate/audit/create/`, data)
      toast.success('Auditor Created Successfully')
      handleClose()
      fetchAuditor() // Refresh the list of auditors after adding a new one
    } catch (error) {
      console.error('Error creating auditor:', error)
      toast.error('Failed to create auditor')
    }
  }
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
          {certificateData ? 
            <h3 className='fw-bolder m-0'>Edit Certificate</h3>
          :
          <h3 className='fw-bolder m-0'>Add Certificate</h3>}
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>CAB</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  value={userData?.company}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Certificate Id</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='--'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      certificateId: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.certificateId}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Certificate Type</label>
                <Select
                  className='react-select-styled react-select-solid '
                  name='User'
                  classNamePrefix='react-select'
                  options={certificationType}
                  onChange={handleCertificateTypeChange}
                  value={{value: addCertificateDetail?.crt, label: addCertificateDetail?.crt}}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Program</label>
                <Select
                  className='react-select-styled react-select-solid '
                  name='User'
                  classNamePrefix='react-select'
                  options={userData?.programme_names?.map((i) => ({label: i?.name, value: i?.id}))}
                  onChange={(e) => handleProgrammeChange(e)}
                  value={userData?.programme_names
                    ?.filter((item) => addCertificateDetail?.programme?.includes(item.id))
                    ?.map((item) => ({label: item.name, value: item.id}))}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Scheme</label>
                <Select
                  className='react-select-styled react-select-solid '
                  name='User'
                  classNamePrefix='react-select'
                  options={userData?.scheme_names?.map((i) => ({label: i?.name, value: i?.id}))}
                  onChange={(e) => handleSchemeChange(e)}
                  value={userData?.scheme_names
                    ?.filter((item) => addCertificateDetail?.scheme?.includes(item.id))
                    ?.map((item) => ({label: item.name, value: item.id}))}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Certificate Standard</label>
                <Select
                  className='react-select-styled react-select-solid '
                  name='User'
                  classNamePrefix='react-select'
                  options={standardData?.map((i) => ({label: i?.name, value: i?.id}))}
                  onChange={(e) => handleStandardChange(e)}
                  value={standardData
                    ?.filter((item) => addCertificateDetail?.standard?.includes(item.id))
                    ?.map((item) => ({label: item.name, value: item.id}))}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Scope/Technical Area</label>
                <Select
                  className='react-select-styled react-select-solid '
                  isMulti
                  name='User'
                  options={scopeData?.map((i) => ({label: i?.name, value: i?.id}))}
                  classNamePrefix='react-select'
                  onChange={(e) => handleClusterChange(e)}
                  value={scopeData
                    ?.filter((item) => addCertificateDetail?.cluster?.includes(item.id))
                    ?.map((item) => ({label: item.name, value: item.id}))}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Original Issue Date</label>
                <ReactDatePicker
                  className='react_date_picker form-control form-control-solid '
                  selected={addCertificateDetail?.issue_date}
                  placeholder='--'
                  // selected={assessmentPlannerDetails?.major_nc_cab_start_date}
                  onChange={(date) =>
                    setAddCertificateDetail({...addCertificateDetail, issue_date: date})
                  }
                  peekNextMonth
                  minDate={startOfMonth(new Date())}
                  maxDate={setDate(addMonths(new Date(), 1), 2)}
                  dropdownMode='select'
                />
              </div>
            </div>
            {addCertificateDetail?.crt === 'Transferred' ||
            addCertificateDetail?.crt === 'ReCertification' ? (
              <div className='col-lg-4 fv-row'>
                <div className='mb-5'>
                  <label className='required form-label'>Initial Certificate Date</label>
                  <ReactDatePicker
                    className='react_date_picker form-control form-control-solid '
                    selected={addCertificateDetail?.initial_certificate_date}
                    placeholder='--'
                    onChange={(date) =>
                      setAddCertificateDetail({
                        ...addCertificateDetail,
                        initial_certificate_date: date,
                      })
                    }
                    peekNextMonth
                    
                    dropdownMode='select'
                  />
                </div>
              </div>
            ) : null}
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Expiry Date</label>
                <ReactDatePicker
                  className='react_date_picker form-control form-control-solid '
                  selected={addCertificateDetail.expiry_date}
                  placeholder='--'
                  onChange={(date) =>
                    setAddCertificateDetail({...addCertificateDetail, expiry_date: date})
                  }
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Status</label>
                <Select
                  className='react-select-styled react-select-solid '
                  name='User'
                  classNamePrefix='react-select'
                  options={certificationStatus}
                  onChange={handleStatusChange}
                  value={{value: addCertificateDetail?.status, label: addCertificateDetail?.status}}
                />
              </div>
            </div>

            <div className='col-12 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Description of Scope</label>
                <textarea
                  rows={3}
                  className='form-control form-control-solid'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      description: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.description}
                ></textarea>
              </div>
            </div>

            {addCertificateDetail?.status === 'Suspended' ||
            addCertificateDetail?.status === 'Withdrawn' ||
            addCertificateDetail?.status === 'Deleted' ? (
              <div className='col-12 fv-row'>
                <div className='mb-5'>
                  <label className='required form-label'>Remark</label>
                  <textarea
                    rows={3}
                    className='form-control form-control-solid'
                    onChange={(e) =>
                      setAddCertificateDetail({...addCertificateDetail, remarks: e?.target?.value})
                    }
                    value={addCertificateDetail?.remarks}
                  ></textarea>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Organisation</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>Organisation ID</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      organisation_id: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.organisation_id}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Organisation</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='--'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      organisation: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.organisation}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>Trading Name</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  type='text'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      trading_name: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.trading_name}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>Primary Contact</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  type='text'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      primary_contact: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.primary_contact}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>Organisation Phone Number</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  type='number'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      organisation_phone_number: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.organisation_phone_number}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Organisation Email</label>
                <input
                  type='email'
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      organisation_email: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.organisation_email}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Location Certified Address</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({
                      ...addCertificateDetail,
                      location_certified_address: e?.target?.value,
                    })
                  }
                  value={addCertificateDetail?.location_certified_address}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>City</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({...addCertificateDetail, city: e?.target?.value})
                  }
                  value={addCertificateDetail?.city}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>State</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({...addCertificateDetail, state: e?.target?.value})
                  }
                  value={addCertificateDetail?.state}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className=' form-label'>Postal Code</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='User'
                  onChange={(e) =>
                    setAddCertificateDetail({...addCertificateDetail, post_code: e?.target?.value})
                  }
                  value={addCertificateDetail?.post_code}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Country</label>
                <Select
                  className='react-select-styled react-select-solid '
                  classNamePrefix='react-select'
                  options={countryList?.map((item) => ({value: item?.id, label: item?.name}))}
                  placeholder='Select Country'
                  onChange={(e) => handleCountryChange(e)}
                  value={{
                    value: addCertificateDetail?.country,
                    label: countryList?.find((item) => item.id === addCertificateDetail?.country)
                      ?.name,
                  }}
                />
              </div>
            </div>

           
          </div>
          {id ? (
            <div className='row p-5'>
              <div className='mb-2 col-6 align-text-end'>
                <button className='thm-btn' onClick={handleUpdateCetificate}>
                  Update
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

     {/*    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='px-10 py-5' style={{borderBottom: '1px solid #f2f2f2'}}>
          <div className='row jusify-content-space-between'>
            <div className='col-md-6 d-flex align-items-center justify-content-start'>
              <h3 className='fw-bolder'>Audit</h3>
            </div>
            <div className='col-md-6 d-flex align-items-center justify-content-end'>
              {id ? (
                <button className='thm-btn' onClick={() => setAuditorModel(true)}>
                  +
                </button>
              ) : (
                <button className='thm-btn' onClick={handleAddRow}>
                  +
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='card-body p-9'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr className='text-start  fw-bold  text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    #
                  </div>
                </th>
                <th className='min-w-250px required'>Audit Type</th>
                <th className='min-w-300px required'>Auditor</th>
                <th className='min-w-125px required '>Date Commenced On-Site</th>
                <th className='min-w-50px required'>Total Days</th>
                <th className='min-w-50px'>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className='text-gray-800 mb-1'>{index + 1}</div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <Select
                        className='react-select-styled react-select-solid'
                        name='User'
                        classNamePrefix='react-select'
                        options={AuditType}
                        value={AuditType.find((option) => option.value === row.audit_type)}
                        onChange={(selectedOption) =>
                          handleRowChange(index, 'audit_type', selectedOption?.value)
                        } 
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <Select
                        isMulti
                        className='react-select-styled react-select-solid'
                        name='auditor'
                        options={
                          auditorData?.map((i) => ({
                            label: i?.name,
                            value: i?.id,
                          })) || [] 
                        }
                        classNamePrefix='react-select'
                        value={row.auditor
                          ?.map((auditorId) =>
                            auditorData.find((auditor) => auditor.id === auditorId)
                          )
                          .map((auditor) => ({
                            label: auditor?.name,
                            value: auditor?.id,
                          }))}
                        onChange={(selectedOptions) => handleAuditorChange(index, selectedOptions)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <ReactDatePicker
                        selected={row.commenced_date ? new Date(row.commenced_date) : null} // Ensure it's a Date object
                        placeholderText='Select Date'
                        className='react_date_picker form-control form-control-solid'
                        dropdownMode='select'
                        onChange={(date) => handleRowChange(index, 'commenced_date', date)} // Handle date change
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                  </td>
                  <td>
                    <div className='text-gray-800 mb-1'>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Total Days'
                        value={row.total_days}
                        onChange={(e) => handleRowChange(index, 'total_days', e?.target?.value)}
                      />
                    </div>
                  </td>
                  <td>
                    {index !== 0 && (
                      <a
                        onClick={() => handleDeleteRow(index)}
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {id ? (
          <div className='row p-5'>
            <div className='mb-2 col-6 align-text-end'>
              <button className='thm-btn' onClick={() => handleAuditorupdate()}>
                Edit & Save
              </button>
            </div>
          </div>
        ) : null}
      </div>*/}
      {!id ? (
        <div className='row p-5'>
          <div className='mb-2 col-6 align-text-end'>
            <button className='thm-btn' onClick={handleAddCetificate}>
              Submit
            </button>
          </div>
        </div>
      ) : null}

      <Modal
        id='kt_modal_create_app'
        show={auditorModel}
        onHide={handleClose}
        className='CertificateModalSection'
      >
        <div className='modal-header'>
          <h2>Add New Auditor</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon className='fs-1' iconName='cross' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='container'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 d-block w-100 '
              id='kt_table_users'
            >
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-250px'>Audit Type</th>
                  <th className='min-w-125px'>Auditor</th>
              
                  <th className='min-w-125px'>Date Commenced On-Site </th>
                  <th className='min-w-125px'>Total Days</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                <tr>
                  <td>
                    <Select
                      className='react-select-styled react-select-solid '
                      name='User'
                      classNamePrefix='react-select'
                      options={AuditType}
                      onChange={(e) => auditorTypeChange(e)}
                    />
                  </td>
                  
                  <td>
                  <div className='text-gray-800 mb-1'>
                    <Select
                      isMulti
                      className='react-select-styled react-select-solid'
                      name='auditor'
                      options={
                        auditorData?.map((i) => ({
                          label: i?.name,
                          value: i?.id,
                        })) || []
                      }
                      classNamePrefix='react-select'
                      onChange={handleAuditorChangeAdd} 
                    />
                  </div>
                </td>
                  
                 
                  <td>
                    <ReactDatePicker
                      selected={auditorDetails?.commenced_date}
                      placeholderText='Select Date'
                      className='react_date_picker form-control form-control-solid'
                      dropdownMode='select'
                      onChange={(e) => setAuditorDetails({...auditorDetails, commenced_date: e})}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='--'
                      onChange={(e) =>
                        setAuditorDetails({...auditorDetails, total_days: e?.target?.value})
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='row p-5'>
              <div className='mb-2 col-6 align-text-end'>
                <button className='thm-btn' onClick={handleSaveAuditor}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddCertificateData
