import React from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import { Documents } from "../profile/components/Documents";
import { UAFDocumentWrapper } from "./component/UAFDocumentWrapper";
const organisationBreadCrumbs: Array<PageLink> = [
    {
        title: 'UAF Documents',
        path: 'crafted/uafdocuments',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const UAFDocuments: React.FC = () => {
    
    return (<>
        <PageTitle breadcrumbs={organisationBreadCrumbs}>UAF Documents</PageTitle>
        <UAFDocumentWrapper />
    </>
    );
};
export default UAFDocuments;