/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { AssessorSetting } from './AssessorSetting'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import { AssessorDetails } from './AssessorDetails'


interface AssessorIDProps {
  userId: string;
}

const AssessorOverview: React.FC<AssessorIDProps> = ({ userId }) => {

  return (


    <AssessorDetails userId={userId} />

  )
}
export default AssessorOverview;
