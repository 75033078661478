import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../../_metronic/helpers";

type Props = {
    show: boolean
    handleClose: () => void
    assessmentType:string
    setAssessmentType: string
}

const NewAssementModal = ({ show, handleClose,assessmentType, setAssessmentType,handleNewAssessmentType }) => {

    return (<>

        <Modal
            id='assessment'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className='modal-header'>
                <h2>Add New Assessment Type</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTIcon className='fs-1' iconName='cross' />
                </div>
                {/* end::Close */}
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className="container">
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body px-9 py-5'>
                            <div className="mb-5">
                                <label className="required form-label">Assessment Type</label>
                                <input type="text" className="form-control form-control-solid" placeholder="Enter Assessment Type" value={assessmentType} onChange={(e) => setAssessmentType(e?.target?.value)} />
                            </div>
                            <div className="d-flex gap-5">
                                <button className='thm-btn' onClick={handleNewAssessmentType}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </Modal>
    </>
    );
};

export default NewAssementModal;