import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
// import masterform
import '../../../../../../src/_metronic/assets/css/masterform.css'
import { useParams } from 'react-router-dom'
import { getFreeUserDetails } from './AssessorFunction'
import { UserDataType } from '../../AssessorInterface'
import CheckboxHierarchy from './CheckboxHierarchy'
import _ from 'lodash';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UAFLoader from '../../../../loader/UafLoader'
interface Requestcertificate {
  id: string
  name: string
}
interface IAFCriticalData {
  id: string;
  name: string;
  scheme: string;
  iaf_sector_doc: File | null

}

interface SelectedIAFCritical {
  [clusterId: string]: {
    [criticalId: string]: {
      checked: boolean;
      file: File | null;
    };
  };
}

interface FileState {
  work_experience_doc: File | null
  training_17011_doc: File | null
  training_17021_1_doc: File | null
  training_17021_2_doc: File | null
  training_17021_3_doc: File | null
  training_17021_10_doc: File | null
  training_17024_doc: File | null
  training_27000_doc: File | null
  training_27005_doc: File | null
  training_27006_doc: File | null
  training_27007_doc: File | null
  training_27008_doc: File | null
  training_22000_doc: File | null
  training_22003_doc: File | null
  training_22003_1_doc: File | null
  training_50001_doc: File | null
  training_50003_doc: File | null
  training_45001_doc: File | null
  training_13485_doc: File | null
  training_9001_doc: File | null
  training_14001_doc: File | null
  internal_training_doc: File | null
}
interface FormState {
  first_name: string
  last_name: string
  email: string
  company: string
  phone: string
  profile: File | null
  graduation_certification: File | null
  certification: string[]
  country: string
  message: string
  graduation: string
  university_name: string
  graduation_date: Date | string
  work_experience: boolean
  training_17011: boolean
  training_17021_1: boolean
  training_17021_2: boolean
  training_17021_3: boolean
  training_17021_10: boolean
  training_17024: boolean
  training_27000: boolean
  training_27005: boolean
  training_27006: boolean
  training_27007: boolean
  training_27008: boolean
  training_22000: boolean
  training_22003: boolean
  training_22003_1: boolean
  training_50001: boolean
  training_50003: boolean
  training_45001: boolean
  training_13485: boolean
  training_9001: boolean
  training_14001: boolean
  internal_training: boolean

  work_experience_doc: File | null
  training_17011_doc: File | null
  training_17021_1_doc: File | null
  training_17021_2_doc: File | null
  training_17021_3_doc: File | null
  training_17021_10_doc: File | null
  training_17024_doc: File | null
  training_27000_doc: File | null
  training_27005_doc: File | null
  training_27006_doc: File | null
  training_27007_doc: File | null
  training_27008_doc: File | null
  training_22000_doc: File | null
  training_22003_doc: File | null
  training_22003_1_doc: File | null
  training_50001_doc: File | null
  training_50003_doc: File | null
  training_45001_doc: File | null
  training_13485_doc: File | null
  training_9001_doc: File | null
  training_14001_doc: File | null
  internal_training_doc: File | null
}

const MasterForm: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL

  const formUrl = useParams()
  const token_url = formUrl?.token || ''
  const userId_url = formUrl?.user || ''

  const [loader, setLoader] = useState<any>(false);
  const [certificateRequest, setCertificateRequest] = useState<Requestcertificate[]>([])
  const [selectedRequest, setSelectedRequest] = useState<any>([])


  const [schemeList, setSchemeList] = useState<any>([])
  const [chooseShemeId, setChooseShemeId] = useState<any>()



  const [selelctedIAFCriticalValue, setSelelctedIAFCriticalValue] = useState<SelectedIAFCritical>({});
  // const [schemeList, setSchemeList] = useState<string[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<string[]>([]);

  const [userStandred, setUserStandred] = useState<any[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedSchemes, setSelectedSchemes] = useState<{ [key: string]: string[] }>({});
  const [selectedStandards, setSelectedStandards] = useState<{ [key: string]: { [key: string]: string[] } }>({});
  const [selectedSchemesList, setSelectedSchemesList] = useState<string[]>([]);
  const [selectedStandardList, setSelectedStandardList] = useState<string[]>([]);

  const [tabValue, setTabValue] = React.useState('1');


  const [userData, setUserData] = useState<UserDataType>()
  console.log(selelctedIAFCriticalValue, "selelctedIAFCriticalValue........ok");
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getFreeUserDetails(userId_url)
        setUserData(result)
      } catch (error) {
        // Handle errors
        console.error('Error:', error)
      }
    }
    fetchData()
  }, [userId_url])

  useEffect(() => {
    if (userData?.standard_names) {
      setUserStandred(userData?.standard_names)
    }
  }, [userData])
  useEffect(() => {
    if (userData?.scheme_names) {
      setSchemeList(userData?.scheme_names)
    }
  }, [userData])
  console.log(userData, 'userData')

  const [files, setFiles] = useState<FileState>({
    work_experience_doc: null,
    training_17011_doc: null,
    training_17021_1_doc: null,
    training_17021_2_doc: null,
    training_17021_3_doc: null,
    training_17021_10_doc: null,
    training_17024_doc: null,
    training_27000_doc: null,
    training_27005_doc: null,
    training_27006_doc: null,
    training_27007_doc: null,
    training_27008_doc: null,
    training_22000_doc: null,
    training_22003_doc: null,
    training_22003_1_doc: null,
    training_50001_doc: null,
    training_50003_doc: null,
    training_45001_doc: null,
    training_13485_doc: null,
    training_9001_doc: null,
    training_14001_doc: null,
    internal_training_doc: null,
  })

  console.log(files, 'PRINTED FILE .......')
  const [value, setValue] = useState<FormState>({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    phone: '',

    profile: null,
    graduation_certification: null,
    certification: [],
    country: '',
    message: '',
    graduation: '',
    graduation_date: '',
    university_name: '',

    work_experience: false,
    training_17011: false,
    training_17021_1: false,
    training_17021_2: false,
    training_17021_3: false,
    training_17021_10: false,
    training_17024: false,
    training_27000: false,
    training_27005: false,
    training_27006: false,
    training_27007: false,
    training_27008: false,
    training_22000: false,
    training_22003: false,
    training_22003_1: false,
    training_50001: false,
    training_50003: false,
    training_45001: false,
    training_13485: false,
    training_9001: false,
    training_14001: false,
    internal_training: false,

    work_experience_doc: null,
    training_17011_doc: null,
    training_17021_1_doc: null,
    training_17021_2_doc: null,
    training_17021_3_doc: null,
    training_17021_10_doc: null,
    training_17024_doc: null,
    training_27000_doc: null,
    training_27005_doc: null,
    training_27006_doc: null,
    training_27007_doc: null,
    training_27008_doc: null,
    training_22000_doc: null,
    training_22003_doc: null,
    training_22003_1_doc: null,
    training_50001_doc: null,
    training_50003_doc: null,
    training_45001_doc: null,
    training_13485_doc: null,
    training_9001_doc: null,
    training_14001_doc: null,
    internal_training_doc: null,
    // is_accessor:true
  })

  const handleCheckboxChange =
    (name: keyof FormState) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, [name]: e.target.checked })
      if (!e.target.checked) {
        setFiles({ ...files, [`${name}_doc`]: null })
      }
    }
  const handleFileChange = (name: keyof FileState) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = value[name.replace('_doc', '') as keyof FormState] // Use type assertion to ensure type safety
    if (!isChecked) {
      toast.error(
        `Please check the corresponding checkbox before uploading the document for ${name.replace(
          '_doc',
          ''
        )}`
      )
      return
    }
    if (e.target.files && e.target.files.length > 0) {
      setFiles({ ...files, [name]: e.target.files[0] })
      setValue({ ...value, [name]: e.target.files[0] })
    }
  }

  const fetchCertificate = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/certificate/list/certification/`)
      setCertificateRequest(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (selectedOptions) => {
    setSelectedRequest(selectedOptions)
  }

  useEffect(() => {
    fetchCertificate()
  }, [])
  const handleGraduate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      graduation_certification: e.target.files ? e.target.files[0] : null,
    })
  }

  // const handelSubmit = async (event: React.FormEvent) => {
  //   // if (!Validation()) return;
  //   event.preventDefault()
  //   console.log(value, 'Printed Value.......!')

  //   try {
  //     const formData = new FormData()

  //     // formData.append("certification", JSON.stringify(value.certification));
  //     formData.append('graduation', value.graduation)

  //     formData.append('token', token_url)
  //     formData.append('user', userId_url)

  //     formData.append('graduation_date', JSON.stringify(value.graduation_date))
  //     formData.append('university_name', value.university_name)
  //     formData.append('work_experience', JSON.stringify(value.work_experience))
  //     formData.append('training_17011', JSON.stringify(value.training_17011))
  //     formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
  //     formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
  //     formData.append('training_17021_2', JSON.stringify(value.training_17021_2))
  //     formData.append('training_17021_3', JSON.stringify(value.training_17021_3))
  //     formData.append('training_17021_10', JSON.stringify(value.training_17021_10))
  //     formData.append('training_17024', JSON.stringify(value.training_17024))
  //     formData.append('training_27000', JSON.stringify(value.training_27000))
  //     formData.append('training_27005', JSON.stringify(value.training_27005))
  //     formData.append('training_27006', JSON.stringify(value.training_27006))
  //     formData.append('training_27007', JSON.stringify(value.training_27007))
  //     formData.append('training_27008', JSON.stringify(value.training_27008))
  //     formData.append('training_22000', JSON.stringify(value.training_22000))
  //     formData.append('training_22003', JSON.stringify(value.training_22003))
  //     formData.append('training_22003_1', JSON.stringify(value.training_22003_1))
  //     formData.append('training_50001', JSON.stringify(value.training_50001))
  //     formData.append('training_50003', JSON.stringify(value.training_50003))
  //     formData.append('training_45001', JSON.stringify(value.training_45001))
  //     formData.append('training_13485', JSON.stringify(value.training_13485))
  //     formData.append('training_9001', JSON.stringify(value.training_9001))
  //     formData.append('training_14001', JSON.stringify(value.training_14001))
  //     formData.append('internal_training', JSON.stringify(value.internal_training))
  //     if (value.work_experience_doc && value.work_experience_doc instanceof File) {
  //       formData.append('work_experience_doc', value.work_experience_doc)
  //     }
  //     if (value.internal_training_doc && value.internal_training_doc instanceof File) {
  //       formData.append('internal_training_doc', value.internal_training_doc)
  //     }
  //     if (value.graduation_certification && value.graduation_certification instanceof File) {
  //       formData.append('graduation_certification', value.graduation_certification)
  //     }
  //     if (value.training_17021_1_doc && value.training_17021_1_doc instanceof File) {
  //       formData.append('training_17021_1_doc', value.training_17021_1_doc)
  //     }
  //     if (value.training_17021_2_doc && value.training_17021_2_doc instanceof File) {
  //       formData.append('training_17021_2_doc', value.training_17021_2_doc)
  //     }
  //     if (value.training_17021_3_doc && value.training_17021_3_doc instanceof File) {
  //       formData.append('training_17021_3_doc', value.training_17021_3_doc)
  //     }
  //     if (value.training_17021_10_doc && value.training_17021_10_doc instanceof File) {
  //       formData.append('training_17021_10_doc', value.training_17021_10_doc)
  //     }
  //     if (value.training_17024_doc && value.training_17024_doc instanceof File) {
  //       formData.append('training_17024_doc', value.training_17024_doc)
  //     }
  //     if (value.training_27000_doc && value.training_27000_doc instanceof File) {
  //       formData.append('training_27000_doc', value.training_27000_doc)
  //     }
  //     if (value.training_27005_doc && value.training_27005_doc instanceof File) {
  //       formData.append('training_27005_doc', value.training_27005_doc)
  //     }
  //     if (value.training_27006_doc && value.training_27006_doc instanceof File) {
  //       formData.append('training_27006_doc', value.training_27006_doc)
  //     }
  //     if (value.training_27007_doc && value.training_27007_doc instanceof File) {
  //       formData.append('training_27007_doc', value.training_27007_doc)
  //     }
  //     if (value.training_27008_doc && value.training_27008_doc instanceof File) {
  //       formData.append('training_27008_doc', value.training_27008_doc)
  //     }
  //     if (value.training_22000_doc && value.training_22000_doc instanceof File) {
  //       formData.append('training_22000_doc', value.training_22000_doc)
  //     }
  //     if (value.training_22003_doc && value.training_22003_doc instanceof File) {
  //       formData.append('training_22003_doc', value.training_22003_doc)
  //     }
  //     if (value.training_22003_1_doc && value.training_22003_1_doc instanceof File) {
  //       formData.append('training_22003_1_doc', value.training_22003_1_doc)
  //     }
  //     if (value.training_50001_doc && value.training_50001_doc instanceof File) {
  //       formData.append('training_50001_doc', value.training_50001_doc)
  //     }
  //     if (value.training_50003_doc && value.training_50003_doc instanceof File) {
  //       formData.append('training_50003_doc', value.training_50003_doc)
  //     }
  //     if (value.training_45001_doc && value.training_45001_doc instanceof File) {
  //       formData.append('training_45001_doc', value.training_45001_doc)
  //     }
  //     if (value.training_13485_doc && value.training_13485_doc instanceof File) {
  //       formData.append('training_13485_doc', value.training_13485_doc)
  //     }
  //     if (value.training_9001_doc && value.training_9001_doc instanceof File) {
  //       formData.append('training_9001_doc', value.training_9001_doc)
  //     }
  //     if (value.training_14001_doc && value.training_14001_doc instanceof File) {
  //       formData.append('training_14001_doc', value.training_14001_doc)
  //     }
  //     // Append selelctedIAFCriticalValue data to formData
  //     Object.keys(selelctedIAFCriticalValue).forEach(clusterId => {
  //       const clusterData = selelctedIAFCriticalValue[clusterId];
  //       Object.keys(clusterData).forEach(itemId => {
  //         const itemData = clusterData[itemId];
  //         // Append file data if available
  //         if (itemData.file instanceof File) {
  //           formData.append(`selelctedIAFCriticalValue[${clusterId}][${itemId}][file]`, itemData.file);
  //         }
  //       });
  //     });
  //     await axios.post(`${BASE_URL}/team/training/experience/form/`, formData)

  //     toast.success('Application Submitted successfully.')
  //     // router.push("/");
  //   } catch (error) {
  //     toast.error('Something went wrong, please try again')
  //   }
  // }
  const handelSubmit = async (event: React.FormEvent) => {
    // if (!Validation()) return;
    event.preventDefault()
    console.log(value, 'Printed Value.......!')

    try {
      setLoader(true);
      const formData = new FormData()

      formData.append('token', token_url)
      formData.append('user', userId_url)


      formData.append('graduation', value.graduation)
      formData.append('university_name', value.university_name)
      formData.append('graduation_date', JSON.stringify(value.graduation_date))
      formData.append('work_experience', JSON.stringify(value.work_experience))
      formData.append('training_17011', JSON.stringify(value.training_17011))
      formData.append('training_17021_1', JSON.stringify(value.training_17021_1))
      formData.append('internal_training', JSON.stringify(value.internal_training))
      formData.append('training_17021_2', JSON.stringify(value.training_17021_2))
      formData.append('training_17021_3', JSON.stringify(value.training_17021_3))
      formData.append('training_17021_10', JSON.stringify(value.training_17021_10))
      formData.append('training_17024', JSON.stringify(value.training_17024))
      formData.append('training_27000', JSON.stringify(value.training_27000))
      formData.append('training_27005', JSON.stringify(value.training_27005))
      formData.append('training_27006', JSON.stringify(value.training_27006))
      formData.append('training_27007', JSON.stringify(value.training_27007))
      formData.append('training_27008', JSON.stringify(value.training_27008))
      formData.append('training_22000', JSON.stringify(value.training_22000))
      formData.append('training_22003', JSON.stringify(value.training_22003))
      formData.append('training_22003_1', JSON.stringify(value.training_22003_1))
      formData.append('training_50001', JSON.stringify(value.training_50001))
      formData.append('training_50003', JSON.stringify(value.training_50003))
      formData.append('training_45001', JSON.stringify(value.training_45001))
      formData.append('training_13485', JSON.stringify(value.training_13485))
      formData.append('training_9001', JSON.stringify(value.training_9001))
      formData.append('training_14001', JSON.stringify(value.training_14001))



      if (value.work_experience_doc && value.work_experience_doc instanceof File) {
        formData.append('work_experience_doc', value.work_experience_doc)

      }
      if (value.internal_training_doc && value.internal_training_doc instanceof File) {
        formData.append('internal_training_doc', value.internal_training_doc)
      }
      if (value.training_17011_doc && value.training_17011_doc instanceof File) {
        formData.append('training_17011_doc', value.training_17011_doc)
      }
      if (value.graduation_certification && value.graduation_certification instanceof File) {
        formData.append('graduation_certification', value.graduation_certification)
      }
      if (value.training_17021_1_doc && value.training_17021_1_doc instanceof File) {
        formData.append('training_17021_1_doc', value.training_17021_1_doc)
      }
      if (value.training_17021_2_doc && value.training_17021_2_doc instanceof File) {
        formData.append('training_17021_2_doc', value.training_17021_2_doc)
      }
      if (value.training_17021_3_doc && value.training_17021_3_doc instanceof File) {
        formData.append('training_17021_3_doc', value.training_17021_3_doc)
      }
      if (value.training_17021_10_doc && value.training_17021_10_doc instanceof File) {
        formData.append('training_17021_10_doc', value.training_17021_10_doc)
      }
      if (value.training_17024_doc && value.training_17024_doc instanceof File) {
        formData.append('training_17024_doc', value.training_17024_doc)
      }
      if (value.training_27000_doc && value.training_27000_doc instanceof File) {
        formData.append('training_27000_doc', value.training_27000_doc)
      }
      if (value.training_27005_doc && value.training_27005_doc instanceof File) {
        formData.append('training_27005_doc', value.training_27005_doc)
      }
      if (value.training_27006_doc && value.training_27006_doc instanceof File) {
        formData.append('training_27006_doc', value.training_27006_doc)
      }
      if (value.training_27007_doc && value.training_27007_doc instanceof File) {
        formData.append('training_27007_doc', value.training_27007_doc)
      }
      if (value.training_27008_doc && value.training_27008_doc instanceof File) {
        formData.append('training_27008_doc', value.training_27008_doc)
      }
      if (value.training_22000_doc && value.training_22000_doc instanceof File) {
        formData.append('training_22000_doc', value.training_22000_doc)
      }
      if (value.training_22003_doc && value.training_22003_doc instanceof File) {
        formData.append('training_22003_doc', value.training_22003_doc)
      }
      if (value.training_22003_1_doc && value.training_22003_1_doc instanceof File) {
        formData.append('training_22003_1_doc', value.training_22003_1_doc)
      }
      if (value.training_50001_doc && value.training_50001_doc instanceof File) {
        formData.append('training_50001_doc', value.training_50001_doc)
      }
      if (value.training_50003_doc && value.training_50003_doc instanceof File) {
        formData.append('training_50003_doc', value.training_50003_doc)
      }
      if (value.training_45001_doc && value.training_45001_doc instanceof File) {
        formData.append('training_45001_doc', value.training_45001_doc)
      }
      if (value.training_13485_doc && value.training_13485_doc instanceof File) {
        formData.append('training_13485_doc', value.training_13485_doc)
      }
      if (value.training_9001_doc && value.training_9001_doc instanceof File) {
        formData.append('training_9001_doc', value.training_9001_doc)
      }
      if (value.training_14001_doc && value.training_14001_doc instanceof File) {
        formData.append('training_14001_doc', value.training_14001_doc)
      }


      const response = await axios.post(`${BASE_URL}/team/training/experience/form/`, formData);

      if (response.status === 200) {
        toast.success('Application submitted successfully. Please select a cluster and upload relevant documents.');
        setLoader(false);
        // navigate(`/assessor/details/edit/programs/info/${userId}`);
      } else {
        toast.error('Failed to submit application. Please try again.');
        setLoader(false);
      }

    } catch (error) {
      toast.error('Training And Experience with this user already exists')
      setLoader(false);
    }
  }


  const handelClusterSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log(value, 'Printed Value.......!');

    try {
      setLoader(true);
      const formData = new FormData();

      formData.append('token', token_url);
      formData.append('user', userId_url);

      // Append selelctedIAFCriticalValue data to formData
      Object.keys(selelctedIAFCriticalValue).forEach(clusterId => {
        const clusterData = selelctedIAFCriticalValue[clusterId];
        Object.keys(clusterData).forEach(itemId => {
          const itemData = clusterData[itemId];
          // Append file data if available
          if (itemData.file instanceof File) {
            formData.append('cluster', clusterId);
            formData.append('critical', itemId);
            formData.append('file', itemData.file);
          }
        });
      });

      const response = await axios.post(`${BASE_URL}/team/assessor/iaf/critical/post/`, formData);
      if (response.status === 201) {
        const responseId = response?.data?.user_id;
        console.log(responseId, "responseId");
        setLoader(false);
        toast.success('Application submitted successfully.');
        // navigate(`/assessor/verified/${userId_url}/overview`);
      } else {
        toast.error('Failed to submit application. Please try again.');
        setLoader(false);
      }
    } catch (error) {

      toast.error('Something went wrong');
      setLoader(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <> {loader ? <UAFLoader /> : null}
      <div className='container py-20'>
        <div className='row'>
          <div className='d-flex flex-column justify-content-center align-items-center w-100'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
              className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
            />
            <div className='uaf_title py-5 text-center'>
              <h2 className=''>UNITED ACCREDITATION FOUNDATION</h2>
              <span className='fs-18'>Initial Competence Evaluation</span>
              {/* <div className="fs-18">EMS</div> */}
            </div>
          </div>
        </div>






        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Experience & Training" value="1" />
                <Tab label="Upload Cluster Doc" value="2" />
                {/* <Tab label="Item Three" value="3" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <form
                // onSubmit={(e) => e.preventDefault()}
                onSubmit={handelSubmit}
              >
                {/* qualification */}
                <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'>
                        Qualification<span style={{ color: 'red' }}>*</span>
                      </h3>
                    </div>
                  </div>
                  <div className='card-body p-9'>
                    <div className='row mb-7'>
                      <div className='col-lg-3 fv-row'>
                        <input
                          type='text'
                          placeholder='Graduation'
                          className='form-control form-control-lg form-control-solid mb-3 '
                          name='graduation'
                          onChange={(e) =>
                            setValue({
                              ...value,
                              graduation: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-lg-3 fv-row'>
                        <input
                          type='text'
                          placeholder='University Name'
                          className='form-control form-control-lg form-control-solid mb-3 '
                          name='university_name'
                          onChange={(e) =>
                            setValue({
                              ...value,
                              university_name: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-lg-3 fv-row'>
                        <input
                          type='text'
                          name='graduation_date'
                          className='form-control form-control-lg form-control-solid mb-3 '
                          placeholder='month/year'
                          onChange={(e) =>
                            setValue({
                              ...value,
                              graduation_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-lg-3 fv-row'>
                        <div className='contact-page__input-box text-center'>
                          <label
                            htmlFor='graduation_certification'
                            className={
                              value.graduation_certification
                                ? 'file-uploaded-uploaded'
                                : 'file-upload-upload'
                            }
                          >
                            {value.graduation_certification
                              ? 'Uploaded Graduation '
                              : 'Upload Graduation'}
                          </label>
                          <input
                            type='file'
                            id='graduation_certification'
                            style={{ display: 'none' }}
                            onChange={handleGraduate}
                          />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                {/* Experience */}
                <div className='card mb-5'>
                  <div className='card-header cursor-pointer'>
                    <div className='col-lg-12 card-title m-0 d-flex row'>
                      <div className='col-lg-6'>
                        <h3 className='fw-bolder m-0'>Experience / Training</h3>
                      </div>
                      <div className='col-lg-6 d-flex justify-content-end'>
                        <div style={{ fontWeight: '600', paddingTop: '2px' }}>
                          Upload Relevant Doc, If you have
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-body '>
                    <div className='row mb-7'>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='work_experience'
                            name='work_experience'
                            className=''
                            checked={value.work_experience}
                            onChange={handleCheckboxChange('work_experience')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='work_experience'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='work_experience'>
                            Do you have work experience in accreditaion/certification process (Minimum 5
                            years)
                          </label>
                        </div>
                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='work_experience_doc'
                            className={
                              files.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.work_experience_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='work_experience_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('work_experience_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='internal_training'
                            name='internal_training'
                            className=''
                            checked={value.internal_training}
                            onChange={handleCheckboxChange('internal_training')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='internal_training'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='internal_training'>
                            Have you done UAF internal training for accreditation process? If yes, then upload the
                            relevant docs.
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          {files.internal_training_doc ? (<label
                            htmlFor='internal_training_doc'
                            className={
                              files.internal_training_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.internal_training_doc ? ' Uploaded' : 'Upload'}
                          </label>) : (
                            <label
                              htmlFor='internal_training_doc'
                              className={
                                value.internal_training_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                              }
                            >
                              {value.internal_training_doc ? ' Uploaded' : 'Upload'}
                            </label>
                          )}

                          <input
                            type='file'
                            id='internal_training_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('internal_training_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17011'
                            name='training_17011'
                            className=''
                            checked={value.training_17011}
                            onChange={handleCheckboxChange('training_17011')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17011'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17011'>
                            Have you done Training on ISO/IEC 17011
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17011_doc'
                            className={
                              files.training_17011_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17011_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17011_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17011_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17021_1'
                            name='training_17021_1'
                            checked={value.training_17021_1}
                            onChange={handleCheckboxChange('training_17021_1')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17021_1'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_1'>
                            Have you done Training on ISO/IEC 17021-1
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17021_1_doc'
                            className={
                              files.training_17021_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17021_1_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17021_1_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17021_1_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17021_2'
                            name='training_17021_2'
                            checked={value.training_17021_2}
                            onChange={handleCheckboxChange('training_17021_2')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17021_2'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_2'>
                            Have you done Training on ISO/IEC 17021-2
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17021_2_doc'
                            className={
                              files.training_17021_2_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17021_2_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17021_2_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17021_2_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17021_3'
                            name='training_17021_3'
                            checked={value.training_17021_3}
                            onChange={handleCheckboxChange('training_17021_3')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17021_3'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_3'>
                            Have you done Training on ISO/IEC 17021-3
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17021_3_doc'
                            className={
                              files.training_17021_3_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17021_3_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17021_3_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17021_3_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17021_10'
                            name='training_17021_10'
                            checked={value.training_17021_10}
                            onChange={handleCheckboxChange('training_17021_10')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17021_10'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17021_10'>
                            Have you done Training on ISO/IEC 17021-10
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17021_10_doc'
                            className={
                              files.training_17021_10_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17021_10_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17021_10_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17021_10_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_17024'
                            name='training_17024'
                            checked={value.training_17024}
                            onChange={handleCheckboxChange('training_17024')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_17024'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_17024'>
                            Have you done Training on ISO/IEC 17024
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_17024_doc'
                            className={
                              files.training_17024_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_17024_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_17024_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_17024_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_27000'
                            name='training_27000'
                            checked={value.training_27000}
                            onChange={handleCheckboxChange('training_27000')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_27000'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27000'>
                            Have you done Training on ISO/IEC 27000
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_27000_doc'
                            className={
                              files.training_27000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_27000_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_27000_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_27000_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_27005'
                            name='training_27005'
                            checked={value.training_27005}
                            onChange={handleCheckboxChange('training_27005')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_27005'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27005'>
                            Have you done Training on ISO/IEC 27005
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_27005_doc'
                            className={
                              files.training_27005_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_27005_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_27005_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_27005_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_27006'
                            name='training_27006'
                            checked={value.training_27006}
                            onChange={handleCheckboxChange('training_27006')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_27006'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27006'>
                            Have you done Training on ISO/IEC 27006
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_27006_doc'
                            className={
                              files.training_27006_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_27006_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_27006_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_27006_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_27007'
                            name='training_27007'
                            checked={value.training_27007}
                            onChange={handleCheckboxChange('training_27007')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_27007'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27007'>
                            Have you done Training on ISO/IEC 27007
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_27007_doc'
                            className={
                              files.training_27007_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_27007_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_27007_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_27007_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_27008'
                            name='training_27008'
                            checked={value.training_27008}
                            onChange={handleCheckboxChange('training_27008')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_27008'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_27008'>
                            Have you done Training on ISO/IEC 27007
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_27008_doc'
                            className={
                              files.training_27008_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_27008_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_27008_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_27008_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_22000'
                            name='training_22000'
                            checked={value.training_22000}
                            onChange={handleCheckboxChange('training_22000')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_22000'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22000'>
                            Have you done Training on ISO 22000
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_22000_doc'
                            className={
                              files.training_22000_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_22000_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_22000_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_22000_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_22003'
                            name='training_22003'
                            checked={value.training_22003}
                            onChange={handleCheckboxChange('training_22003')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_22003'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003'>
                            Have you done Training on ISO/IEC 22003
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_22003_doc'
                            className={
                              files.training_22003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_22003_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_22003_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_22003_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_22003_1'
                            name='training_22003_1'
                            checked={value.training_22003_1}
                            onChange={handleCheckboxChange('training_22003_1')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_22003_1'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_22003_1'>
                            Have you done Training on ISO/IEC 22003-1
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_22003_1_doc'
                            className={
                              files.training_22003_1_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_22003_1_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_22003_1_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_22003_1_doc')}
                          />
                        </div>
                      </div>

                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_50001'
                            name='training_50001'
                            className='custom-checkbox'
                            checked={value.training_50001}
                            onChange={handleCheckboxChange('training_50001')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_50001'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50001'>
                            Have you done Training on ISO 50001
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_50001_doc'
                            className={
                              files.training_50001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_50001_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_50001_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_50001_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_50003'
                            name='training_50003'
                            className='custom-checkbox'
                            checked={value.training_50003}
                            onChange={handleCheckboxChange('training_50003')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_50003'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_50003'>

                            Have you done Training on ISO 50003
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_50003_doc'
                            className={
                              files.training_50003_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_50003_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_50003_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_50003_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_45001'
                            name='training_45001'
                            checked={value.training_45001}
                            onChange={handleCheckboxChange('training_45001')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_45001'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_45001'>

                            Have you done Training on ISO 45001
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_45001_doc'
                            className={
                              files.training_45001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_45001_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_45001_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_45001_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_13485'
                            name='training_13485'
                            checked={value.training_13485}
                            onChange={handleCheckboxChange('training_13485')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_13485'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_13485'>
                            Have you done Training on ISO 13485
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_13485_doc'
                            className={
                              files.training_13485_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_13485_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_13485_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_13485_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_9001'
                            name='training_9001'
                            checked={value.training_9001}
                            onChange={handleCheckboxChange('training_9001')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_9001'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_9001'>
                            Have you done Training on ISO 9001
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_9001_doc'
                            className={
                              files.training_9001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_9001_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_9001_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_9001_doc')}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                        <div className=' d-flex'>
                          <input
                            type='checkbox'
                            id='training_14001'
                            name='training_14001'
                            checked={value.training_14001}
                            onChange={handleCheckboxChange('training_14001')}
                          />
                          <label
                            className=' '
                            style={{ padding: '0 0 0 16px' }}
                            htmlFor='training_14001'
                          ></label>
                          <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='training_14001'>
                            Have you done Training on ISO 14001
                          </label>
                        </div>

                        <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                          <label
                            htmlFor='training_14001_doc'
                            className={
                              files.training_14001_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                            }
                          >
                            {files.training_14001_doc ? 'Document Uploaded' : 'Upload Doc'}
                          </label>
                          <input
                            type='file'
                            id='training_14001_doc'
                            style={{ display: 'none' }}
                            onChange={handleFileChange('training_14001_doc')}
                          />
                        </div>
                      </div>


                      <div className='col-lg-12   form-control form-control-lg form-control-solid mb-3 '>
                        <div className='contact-page__input-box'>
                          <textarea
                            style={{
                              color: '#2e2e36',
                              fontSize: '16px',
                              fontWeight: 500,
                              width: '100%',
                              height: '155px',
                              border: '2px solid transparent',
                              background: '#f4f4f5',
                              padding: '20px 30px 20px',
                              borderRadius: '5px',
                              marginBottom: '0px',
                              outline: 'none',
                              transition: 'all 200ms linear',
                              transitionDelay: '0.1s',
                            }}
                            name='message'
                            placeholder='Remarks'
                            defaultValue={''}
                            onChange={(e) => setValue({ ...value, message: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end */}
                <button type='submit'>
                  <span className='btn'>
                    Submit
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </span>
                </button>
              </form>


            </TabPanel>
            <TabPanel value="2">
              <form
                // onSubmit={(e) => e.preventDefault()}
                onSubmit={handelClusterSubmit}
              >
                <CheckboxHierarchy
                  selelctedIAFCriticalValue={selelctedIAFCriticalValue}
                  setSelelctedIAFCriticalValue={setSelelctedIAFCriticalValue}
                  schemeList={schemeList}
                  selectedCluster={selectedCluster}
                  setSelectedCluster={setSelectedCluster}
                  setSchemeList={setSchemeList}
                  userStandred={userStandred}
                  selectedPrograms={selectedPrograms}
                  selectedSchemes={selectedSchemes}
                  selectedStandards={selectedStandards}
                  setSelectedPrograms={setSelectedPrograms}
                  setSelectedSchemes={setSelectedSchemes}
                  setSelectedStandards={setSelectedStandards}
                  selectedSchemesList={selectedSchemesList}
                  setSelectedSchemesList={setSelectedSchemesList}
                  selectedStandardList={selectedStandardList}
                  setSelectedStandardList={setSelectedStandardList}
                />
                <button type='submit'>
                  <span className='btn'>
                    Submit
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </span>
                </button>
              </form>

            </TabPanel>
            {/* <TabPanel value="3">Item Three</TabPanel> */}
          </TabContext>
        </Box>







        {/* IAF */}
        {/* <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                IAF SECTOR SPECIFIC COMPETENCE EVALUATION </h3>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              {groupedIAFScheme.map((groupedData, index) => (
                <div key={`cluster_${index}`}>

                  {Object.keys(groupedData).map(schemeName => (
                    <div key={schemeName} className="scheme-wrapper">
                      <div className='py-8'>
                        <h3 className='fw-bolder'>{schemeName}</h3>
                        <p className='fs-5'>Initial competence requirements for Critical IAF Sectors (<span style={{ color: "red" }}>Any one of the following *</span> )
                        </p></div>
                      {groupedData[schemeName].map(programme => (
                      <div key={programme.id} className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                          <div className=' d-flex'>
                            <input
                              type='checkbox'
                              id='work_experience'
                              name='work_experience'
                              className=''
                            />
                            <label
                              className=' '
                              style={{ padding: '0 0 0 16px' }}
                              htmlFor='work_experience'
                            ></label>
                            <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='work_experience'>
                              {programme.name}
                            </label>
                          </div>
                          <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                            <label
                              htmlFor='work_experience_doc'
                              className={
                                files.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                              }
                            >
                              {files.work_experience_doc ? 'Document Uploaded' : 'Upload Doc'}
                            </label>
                            <input
                              type='file'
                              id='work_experience_doc'
                              style={{ display: 'none' }}
                              onChange={handleFileChange('work_experience_doc')}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}




              <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                <div className=' d-flex'>
                  <input
                    type='checkbox'
                    id='work_experience'
                    name='work_experience'
                    className=''
                  // checked={value.work_experience}
                  // onChange={handleCheckboxChange('work_experience')}
                  />
                  <label
                    className=' '
                    style={{ padding: '0 0 0 16px' }}
                    htmlFor='work_experience'
                  ></label>
                  <label className=' ' style={{ padding: '18px 0 0 0' }} htmlFor='work_experience'>
                    Do you have One-year full time Quality management system related work experience in relevant IAF code in the core areas.
                  </label>
                </div>
                <div className='justify-content-end ' style={{ padding: '5px', marginLeft: 'auto' }}>
                  <label
                    htmlFor='work_experience_doc'
                    className={
                      files.work_experience_doc ? 'file-uploaded-uploaded' : 'file-upload-upload'
                    }
                  >
                    {files.work_experience_doc ? 'Document Uploaded' : 'Upload Doc'}
                  </label>
                  <input
                    type='file'
                    id='work_experience_doc'
                    style={{ display: 'none' }}
                    onChange={handleFileChange('work_experience_doc')}
                  />
                </div>
              </div>



            </div>

          </div>
        </div> */}

        {/* witness assessor */}
        {/* <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              Witness Assessor </h3>
          </div>


        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <div className='col-lg-12 fv-row d-flex  mb-3'>


              <input
                type='checkbox'
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className=''
                placeholder=''
                style={{ position: 'absolute', zIndex: 1, margin: "17px 0 0 18px" }}
              />
              <label
                className=" form-control form-control-lg form-control-solid mb-3 "
                style={{ padding: "12px 0 0 45px" }}
                htmlFor="vehicle1">Lead Auditor training of ISO 14001.
              </label>
            </div>
            <div className='col-lg-12 fv-row d-flex mb-3'>

              <input
                type='checkbox'
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className=''
                placeholder=''
                style={{ position: 'absolute', zIndex: 1, margin: "17px 0 0 18px" }}
              />
              <label
                className=" form-control form-control-lg form-control-solid mb-3 "
                style={{ padding: "12px 0 0 45px" }}
                htmlFor="vehicle1">Auditing experience in Mandays (Minimum ten)
              </label>
            </div>
            <div className='col-lg-12 fv-row d-flex mb-3'>

              <input
                type='checkbox'
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className=''
                placeholder=''
                style={{ position: 'absolute', zIndex: 1, margin: "17px 0 0 18px" }}
              />
              <label
                className=" form-control form-control-lg form-control-solid mb-3 "
                style={{ padding: "12px 0 0 45px" }}
                htmlFor="vehicle1">Recent witness assessment by any other AB, if any
                EMS Witness Assessor competence available for the IAF Scopes as detailed in UAF F-22
              </label>
            </div>

          </div>
          <div className='row mb-7'>
            <div className='col-lg-6 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 '
                placeholder='Latest date of Contract Agreement signed with Assessor'
              />

            </div>
            <div className='col-lg-6 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 '
                placeholder='Latest date of disclosure statement'
              />
            </div>

          </div>
        </div>
      </div> */}
        {/* ARC */}
        {/* <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              Member of Accreditation Review Committee </h3>
          </div>


        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <div className='col-lg-12 fv-row d-flex  mb-3'>

              <input
                type='checkbox'
                id="vehicle1"
                name="vehicle1"
                value="Bike"
                className=''
                placeholder=''
                style={{ position: 'absolute', zIndex: 1, margin: "17px 0 0 18px" }}
              />
              <label
                className=" form-control form-control-lg form-control-solid mb-3 "
                style={{ padding: "12px 0 0 45px" }}
                htmlFor="vehicle1">Internal training on UAF accreditation process
              </label>
            </div>


          </div>

        </div>
      </div> */}

      </div>
    </>
  )
}
export default MasterForm