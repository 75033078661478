import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select, { SingleValue } from 'react-select';
import {toast} from 'react-toastify'
import UAFLoader from '../../loader/UafLoader'
import ReactDatePicker from 'react-datepicker'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
interface FormState {
  cab_date: Date | null
  is_visible: boolean
  desc: string
}
type SelectOption = {
    label: string;
    value: boolean; 
  };
const AddSuspended = () => {
  const navigate = useNavigate()
  const param = useParams()
  const Id = param?.id
  const API_URL = process.env.REACT_APP_API_URL
  const [loader, setLoader] = useState<any>(false)
  const [suspendedData, setSuspendedData] = useState<FormState | null>(null)


  const [value, setValue] = useState<FormState>({
    cab_date: null,
    is_visible: false,
    desc: '',
  })


  useEffect(() => {
    if (suspendedData) {
      setValue({
        is_visible: suspendedData?.is_visible ?? false,
        cab_date: suspendedData?.cab_date ? new Date(suspendedData.cab_date) : null, // Convert string date to Date object
        desc: suspendedData?.desc || '',
      });
    }
  }, [suspendedData]);
  
  const fetchSuspendedData = async () => {
    try {
      const response = await axios.get(`${API_URL}/blog/suspended/patch/${Id}`);
      setSuspendedData(response.data);
    } catch (error) {
      console.error('Error fetching suspended data:', error);
    }
  };
  
  useEffect(() => {
    if (Id) {
      fetchSuspendedData();
    }
  }, [Id]);
  
  useEffect(() => {
      if (Id) {
        fetchSuspendedData()
      }

  }, [Id])
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    const { cab_date, desc, is_visible } = value;
  
    // Validation
    if (!cab_date || !desc) {
      toast.error('Please fill in all mandatory fields.');
      return;
    }
  
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('cab_date', cab_date instanceof Date ? cab_date.toISOString() : cab_date); // Ensure date is in ISO format
      formData.append('is_visible', is_visible ? 'true' : 'false'); // Boolean converted to 'true' or 'false'
      formData.append('desc', desc);
  
      let response;
      if (Id) {
        // Update existing blog
        response = await axios.put(`${API_URL}/blog/suspended/update/${Id}/`, formData);
      } else {
        // Create new blog
        response = await axios.post(`${API_URL}/blog/suspended/post/`, formData);
      }
  
      if (response.status === 201 || response.status === 200) {
        toast.success('Data submitted successfully');
        setLoader(false);
  
        // Redirect to desired page or reset form
        navigate(`/crafted/suspended`);
        setValue({
          cab_date: null,
          desc: '',
          is_visible: false,
        });
      } else {
        setLoader(false);
        toast.error('Failed to submit. Please try again.');
      }
    } catch (error: any) {
      setLoader(false);
      console.error('Error submitting form:', error);
      toast.error('Something went wrong, please try again.');
    }
  };
  
  


  return (
    <div id='v_blog_01'>
      {loader ? <UAFLoader /> : null}

      <div className='row jusify-content-space-between'>
        <div className='col-md-6 d-flex align-items-center justify-content-start'>
          <h2 className='mb-3 p-0 '>{Id ? 'Edit Suspended' : 'Add Suspended'}</h2>
        </div>
      </div>

      <div className='card card-flush'>
        <div className='card-body pt-5'>
        <form onSubmit={handleSubmit}>
  <div className='row'>
    {/* Date Picker */}
    <div className='mb-5 col-lg-6'>
      <label className='required form-label'>Publish Date</label>
      <br />
      <ReactDatePicker
        selected={value?.cab_date ? new Date(value.cab_date) : null} // Ensure the date is a Date object
        placeholderText='Select Date'
        className='react_date_picker form-control form-control-solid'
        onChange={(cab_date) => setValue({ ...value, cab_date })}
        dropdownMode='select'
        showMonthDropdown
        showYearDropdown
      />
    </div>

    {/* Visibility Select */}
    <div className='mb-5 col-lg-6'>
      <label className='required form-label'>Visible</label>
      <br />
      <Select
        id='is_visible'
        className='react-select-styled react-select-solid'
        name='is_visible'
        value={value?.is_visible ? { label: 'Yes', value: true } : { label: 'No', value: false }} // Map value to boolean true/false
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        classNamePrefix='react-select'
        onChange={(selectedOption: SingleValue<SelectOption>) => {
          if (selectedOption) {
            setValue({ ...value, is_visible: selectedOption.value });
          }
        }}
      />
    </div>
  </div>

  {/* CKEditor for Description */}
  <div className='col-lg-12'>
    <div className='fv-row'>
      <div className='mb-5'>
        <label className='required form-label'>Description</label>
        <CKEditor
          editor={ClassicEditor}
          data={value?.desc || ''}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue({ ...value, desc: data });
          }}
        />
      </div>
    </div>
  </div>

  {/* Submit Button */}
  <div className='mb-5'>
    <button type='submit' className='btn btn-primary'>
      {Id ? 'Update' : 'Submit'}
      <i className='fa fa-angle-double-right' aria-hidden='true' />
    </button>
  </div>
</form>


        </div>
      </div>
    </div>
  )
}

export default AddSuspended
