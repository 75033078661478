import React, { useEffect, useState, ChangeEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import axios from "axios";
import Select, { MultiValue, SingleValue } from 'react-select';
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import _ from 'lodash'
interface OptionType {
    label: string;
    value: string;
}
const ARCAssignModal = ({ show, handleClose, fetchAssesorUser }) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const [cabUserDetail, setCabUserDetail] = useState<any>([]);
    console.log(cabUserDetail, 'cabUserDetail')
    const [assessorDetail, setAssessorDetail] = useState<any>([]);
    const [arcMemberSelect, setARCMemberSelect] = useState<any>([]);
    console.log(arcMemberSelect, "arcMemberSelect");

    const [roleList, setRoleList] = useState<any>();
    const [selectedType, setSelectedType] = useState<any>();
    console.log(selectedType, "selectedType");
    const [typeName, setTypeName] = useState(null);
    const [assessorData, setAssessorData] = useState<any>([]);
    const [rows, setRows] = useState([{ id: 1, type: null, assessment_number: null }]);
    console.log(assessorDetail, 'assessorDetail')
    const AssessmentLocationArr = [
        { value: "Remote", label: "Remote" },
        { value: "Onsite", label: "Onsite" },
        { value: "Offsite", label: "Offsite" },
    ]
    useEffect(() => {
        if (arcMemberSelect.length > 0) {
            setAssessorData(prevAssessorData => {
                const newAssessorData = arcMemberSelect.map(i => {
                    const existingData = prevAssessorData.find(data => data.assessorId === i.value);
                    return existingData || {
                        assessorId: i.value,
                        assessorRole: roleList?.find(i => i.label == "ARC")?.value,
                        assessorLocation: 'Remote',
                        assessorTargets: rows  // Include rows here
                    };
                });
                return newAssessorData;
            });
        }
    }, [arcMemberSelect, rows]);


    const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>([])
    console.log(assessorPlannerDataFromAssessment, 'assessorPlannerDataFromAssessment')
    const [cabId, setCadId] = useState<any>();
    const [assementNumber, setAssementNumber] = useState<any>();
    const [assementType, setAssessmentType] = useState<any[]>([]);
    console.log("assementType:", assementType);
    const uniqueAssessmentNumbers = _.uniqBy(assessorPlannerDataFromAssessment, 'assessment_number');

    console.log(uniqueAssessmentNumbers, "assesss");
    console.log("cabId:", cabId);
    useEffect(() => {
        const getTypeList = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/cab/assessment/list/`
                )
                setAssessmentType(response.data)
            } catch (error) {
                ////console.log(error)
            }
        }
        getTypeList()
    }, [])
    // useEffect(() => {
    //     const getAssessorDataByAssessment = async () => {
    //         try {
    //             const response = await axios.get(
    //                 `${BASE_URL}/cab/filter/scheme/assessor/planner/?cab=${cabId}&assessment_type_name${typeName}=&scheme=&assessor_planner=&assessment_type=&create_at=&update_at=`
    //             )
    //             setAssessorPlannerDataFromAssessment(response.data)
    //         } catch (error) {
    //             ////console.log(error)
    //         }
    //     }
    //     getAssessorDataByAssessment()
    // }, [cabId,typeName])
    const fetcgArcCabUser = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/nc/management/completed/cab/`

            );
            setCabUserDetail(response.data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRoleData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/role/list/`
            )
            const filter = response.data?.map((item) => ({
                label: item?.name,
                value: item?.id
            }))
            setRoleList(filter)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetcgArcCabUser();
        fetchRoleData();
    }, [])

    const handleCabChange = (e) => {
        setCadId(e?.value)
    }


    console.log(rows, "rows");



    const handleAddRow = () => {
        setRows([...rows, { id: rows.length + 1, type: null, assessment_number: null }]);
    };

    const handleTypeChange = (id, selectedOption) => {
        setRows(rows.map(row => row.id === id ? { ...row, type: selectedOption.label } : row));
        setTypeName(selectedOption ? selectedOption.label : null);
    };

    const handleAssessmentChange = (id, selectedOptions) => {
        const selectedAssessmentNumbers = selectedOptions.value;
        setRows(rows.map(row => row.id === id ? { ...row, assessment_number: selectedAssessmentNumbers } : row));
    };

    useEffect(() => {
        const getAssessorDataByAssessment = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/cab/filter/scheme/assessor/planner/?cab=${cabId}&assessment_type_name=${typeName}&scheme=&assessor_planner=&assessment_type=&create_at=&update_at=`
                );
                setAssessorPlannerDataFromAssessment(response.data);
            } catch (error) {
                // Handle the error appropriately
                console.error(error);
            }
        };

        if (cabId && typeName) {
            getAssessorDataByAssessment();
        }
    }, [cabId, typeName]);

    useEffect(() => {
        if (cabId) {
            const fetcgAssesorUser = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/nc/management/missing/assessor/${cabId}/`);
                    setAssessorDetail(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
            fetcgAssesorUser();

        }
    }, [cabId])



    const handleARCMember = (e) => {
        setARCMemberSelect(e)
        // const updatedSchemeData = assessorData?.map(item => {
        //     if (item.assessor === e.value) {
        //         return { ...item, assessorName: e?.map((i) => i?.value), assessorNameData: e };
        //     }
        //     return item;
        // });
        // let prevData=assessorData
        // e.forEach(item => {
        //     let exists= assessorData.find(i=>i.assessor == item.value)
        //     if(!exists){

        //     }
        // });


    }
    // const handleAssessmentNumber = (number) => {
    //     setAssementNumber(number)
    //     // const updatedSchemeData = assessorData?.map(item => {
    //     //     if (item.assessor === e.value) {
    //     //         return { ...item, assessorName: e?.map((i) => i?.value), assessorNameData: e };
    //     //     }
    //     //     return item;
    //     // });
    //     // let prevData=assessorData
    //     // e.forEach(item => {
    //     //     let exists= assessorData.find(i=>i.assessor == item.value)
    //     //     if(!exists){

    //     //     }
    //     // });


    // }
    const handleLocationChange = (selectedOption, index) => {
        const newAssessorData = [...assessorData];
        newAssessorData[index].assessorLocation = selectedOption.value;
        setAssessorData(newAssessorData);
    };

    const handleRoleChange = (selectedOption, index) => {
        const newAssessorData = [...assessorData];
        newAssessorData[index].assessorRole = selectedOption.value;
        setAssessorData(newAssessorData);
    };
    // const assignARCMember = async () => {
    //     try {
    //         const response = await axios.post(`${BASE_URL}/arc/assign/cab/assessor/`, {
    //             cabId: cabId,
    //             assessor_data: assessorData,

    //         });
    //         console.log(response.data);
    //         setAssessorData([])
    //         setCadId('');
    //         setARCMemberSelect('')
    //         handleClose()
    //         fetchAssesorUser()
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };

    const assignARCMember = async () => {

        if (arcMemberSelect?.length === 2) {
            try {
                const payload = {
                    cabId: cabId,
                    assessor_data: assessorData.map(assessor => ({
                        ...assessor,
                        assessorTargets: rows  // Add rows data to each assessor
                    }))
                };

                const response = await axios.post(`${BASE_URL}/arc/assign/cab/assessor/`, payload);
                if (response.status === 200 || response.status === 201) {
                    toast.success("ARC Member assigned successfully")
                    setAssessorData([]);
                    setCadId('');
                    setARCMemberSelect('');
                    handleClose();
                    fetchAssesorUser();
                    setRows([{ id: 1, type: null, assessment_number: null }]); // Reset rows
                } else {
                    toast.error('Failed to Assign ARC. Please try again.');
                }
                console.log(response.data);

            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            toast.warning("Please Select Two ARC Member")
        }

    };

    return (
        <div id="assign_arc_model">
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2>Assign ARC</h2>
                    <div id="arc_id_click" className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTIcon className='fs-1' iconName='cross' />
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="container">
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body border-top p-9'>

                                <div className="row col-md-12">
                                    <div className="mb-5 col-6">
                                        <label className='form-label'>Cab Organisation</label>
                                        <Select
                                            className='react-select-styled react-select-solid'
                                            classNamePrefix='react-select'
                                            placeholder='Select Cab Organisation'
                                            options={cabUserDetail?.map((item) => ({ label: item?.registrar__company, value: item?.registrar }))}
                                            onChange={(e) => handleCabChange(e)}
                                            id="arc_id_click_1"
                                        />
                                    </div>
                                    <div className="mb-5 col-6">
                                        <label className='form-label'>ARC Member</label>
                                        <Select
                                            className='react-select-styled react-select-solid'
                                            classNamePrefix='react-select'
                                            placeholder='Select Assessor Name'
                                            isMulti
                                            id="arc_id_click_2"
                                            options={assessorDetail?.map((item) => ({ label: `${item?.first_name} ${item?.last_name}`, value: item?.id }))}
                                            onChange={(e) => handleARCMember(e)}
                                        />
                                    </div>

                                    {rows.map((row, index) => (
                                        <div key={row.id} className="row col-md-12">
                                            <div className="mb-5 col-6">
                                                <label className='form-label'>Select Assessment Type</label>
                                                <Select
                                                id="arc_id_click_3"
                                                    className='react-select-styled react-select-solid'
                                                    classNamePrefix='react-select'
                                                    placeholder='Select Assessment Type'
                                                    options={assementType?.map((item) => ({ label: item?.name, value: item?.id }))}
                                                    onChange={(e) => handleTypeChange(row.id, e)}
                                                />
                                            </div>
                                            <div className="mb-5 col-6">
                                                <label className='form-label'>Select Assessment</label>
                                                <Select
                                                id="arc_id_click_4"
                                                    className="react-select-styled react-select-solid"
                                                    classNamePrefix="react-select"
                                                    placeholder="Select Assessment"
                                                    // isMulti
                                                    options={uniqueAssessmentNumbers?.map((item) => ({
                                                        label: `${item?.assessment_number}`,
                                                        value: item?.assessment_number,
                                                    }))}
                                                    onChange={(selectedOptions) => handleAssessmentChange(row.id, selectedOptions)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <button type="button" id="arc_id_click_6" onClick={handleAddRow} className="btn btn-primary">Add New Assessment</button>
                                </div>

                                {/* <div className="row col-md-12">
                                <div className="mb-5 col-6">
                                    <label className=' form-label'>Cab Organisation</label>

                                    <Select
                                        className='react-select-styled react-select-solid '
                                        classNamePrefix='react-select'
                                        placeholder='Select Cab Organisation'
                                        options={cabUserDetail?.map((item) => ({ label: item?.registrar__company, value: item?.registrar }))}
                                        onChange={(e) => handleCabChange(e)}
                                    />

                                </div>
                                <div className="mb-5 col-6">
                                    <label className=' form-label'>Select Assessment Type</label>

                                    <Select
                                        className='react-select-styled react-select-solid '
                                        classNamePrefix='react-select'
                                        placeholder='Select Cab Organisation'
                                        options={assementType?.map((item) => ({ label: item?.name, value: item?.id }))}
                                        onChange={(e) => handleTypeChange(e)}
                                    />

                                </div>
                                <div className="mb-5 col-6">
                                    <label className='form-label'>Select Assessment</label>

                                    <Select
                                        className="react-select-styled react-select-solid"
                                        classNamePrefix="react-select"
                                        placeholder="Select Assessor Name"
                                        isMulti
                                        options={assessorPlannerDataFromAssessment?.map((item) => ({
                                            label: `${item?.assessment_number}`,
                                            value: item?.assessment_number,
                                        }))}
                                        onChange={(selectedOptions: MultiValue<OptionType>) => {
                                            const selectedAssessmentNumbers = selectedOptions.map((option) => option.value);
                                            handleAssessmentNumber(selectedAssessmentNumbers);
                                        }}
                                    />

                                </div>
                                <div className="mb-5 col-6">
                                    <label className='form-label'>ARC Member</label>

                                    <Select
                                        className='react-select-styled react-select-solid '
                                        classNamePrefix='react-select'
                                        placeholder='Select Assessor Name'
                                        isMulti
                                        options={assessorDetail?.map((item) => ({ label: `${item?.first_name} ${item?.last_name}`, value: item?.id }))}
                                        onChange={(e) => handleARCMember(e)}
                                    />

                                </div>
                            </div> */}

                                {/* {arcMemberSelect?.length ? <>

                                    <div className="col-12">
                                        <table className="table table-striped mt-3">
                                            <thead>
                                                <tr>
                                                    <th className="col-4">ARC Member</th>
                                                    <th className="col-4">Location</th>
                                                    <th className="col-4">Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {arcMemberSelect?.map((i, index) => {
                                                    return (

                                                        <tr>
                                                            <td>
                                                                {i?.label}

                                                            </td>
                                                            <td>
                                                                <Select
                                                                    className='react-select-styled react-select-solid '
                                                                    name="User"
                                                                    options={AssessmentLocationArr}
                                                                    classNamePrefix='react-select'
                                                                    onChange={(selectedOption) => handleLocationChange(selectedOption, index)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Select
                                                                    className='react-select-styled react-select-solid '
                                                                    classNamePrefix='react-select'
                                                                    options={roleList}
                                                                    placeholder='Select Role'
                                                                    onChange={(selectedOption) => handleRoleChange(selectedOption, index)}
                                                                />
                                                            </td>
                                                        </tr>

                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </>

                                    :

                                    null} */}









                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={() => {
                            assignARCMember();
                        }}
                        id="arc_id_click_10"
                    >
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default ARCAssignModal;