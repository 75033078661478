/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import BusinessUnitFilter from '../organisation/businessUnit/models/businessUniterFilter'
import Select from 'react-select'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import CreateManualAssesor from '../assessor-mangement/assessorLead/models/CreateManualAssesor'
import UAFLoader from '../../loader/UafLoader'



// const BusinessUnitList = ({ className }: Props) => (
interface PendingAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  is_assessor: boolean
  is_verify_assessor: boolean
  assessor: string
  store_password: string
  scheme_names: {id:string; name:string}[]
  programme_names:{id:string; name:string}[]
  accessor_approve_date: string
  create_at :string
  company :string
}
const ARCList: React.FC = () => {
  const BASE_URL = process.env.REACT_APP_API_URL
  const [value, setValue] = useState<PendingAssessor[]>([])
  console.log(value,'valuejskkjdkjd')
  const [loader, setLoader] = useState<any>(false);

  const [searchQuery, setSearchQuery] = useState('')
  const [filteredValue, setFilteredValue] = useState<PendingAssessor[]>([])

  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY')
  }
  // Function to handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
    // Filter the value array based on the search query
    const filteredData = value.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase()
      const searchValue = e.target.value.toLowerCase()
      const programMatch = user?.programme_names.some((certification) =>
        certification?.name.toLowerCase().includes(searchValue)
      )
      const certificationMatch = user?.scheme_names.some((certification) =>
        certification?.name.toLowerCase().includes(searchValue)
      )
      return (
        fullName.includes(searchValue) ||
        user?.first_name.toLowerCase().includes(searchValue) ||
        user?.email.toLowerCase().includes(searchValue)||
        certificationMatch || programMatch
        // user?.certification_names.toLowerCase().includes(searchValue)
      )
    })
    setFilteredValue(filteredData)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true)
        const response = await axios.get<PendingAssessor[]>(
          `${BASE_URL}/team/accessor/verified/list/`
        )
        if (response.status === 200) {
          setValue(response.data)
          setLoader(false)
        }

      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showBusinessUnitFilterModal, setShowBusinessUnitFilterModal] = useState<boolean>(false)

  return (
    <>
   {loader ? <UAFLoader /> : null}
    <div className='card card-flush'>
      <div className='card-header pt-5'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='17.0365'
                  y='15.1223'
                  width='8.15546'
                  height='2'
                  rx='1'
                  transform='rotate(45 17.0365 15.1223)'
                  fill='currentColor'
                />
                <path
                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              onChange={handleSearchChange}
              placeholder='Search user'
            />
          </div>
        </div>
        {/* <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

               <Link to="/create/assessor/"
               className='btn btn-primary'
               >
              <span className='svg-icon svg-icon-2'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='11.364'
                    y='20.364'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-90 11.364 20.364)'
                    fill='currentColor'
                  />
                  <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                </svg>
              </span>
            Add Assessor</Link>

            <CreateManualAssesor
              show={showCreateAppModal}
              handleClose={() => setShowCreateAppModal(false)}
              editUserId={''}
            />
            <BusinessUnitFilter
              show={showBusinessUnitFilterModal}
              handleClose={() => setShowBusinessUnitFilterModal(false)}
            />
          </div>
          <div
            className='d-flex justify-content-end align-items-center d-none'
            data-kt-user-table-toolbar='selected'
          >
            <div className='fw-bold me-5'>
              <span className='me-2' data-kt-user-table-select='selected_count'></span>Selected
            </div>
            <button
              type='button'
              className='btn btn-danger'
              data-kt-user-table-select='delete_selected'
            >
              Delete Selected
            </button>
          </div>

        </div> */}
      </div>
      <div className='card-body pt-5'>
        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
          <thead>
            {/* <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'> */}
            <tr className='text-start  fw-bold  text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_table_users .form-check-input'
                    value='1'
                  />
                </div>
              </th>
              <th className='min-w-125px'>CAB Organisation</th>
              <th className='min-w-125px'>Contact Info</th>
              <th className='min-w-125px'>Schemes</th>
              <th className='min-w-125px'>Status</th>
              <th className='min-w-125px'>Approve Date</th>
              <th className='text-center min-w-100px'>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {(searchQuery.length > 0 ? filteredValue : value)?.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <Link
                    to={`/accreditation/cab/arc/${item?.id}/overview`}
                    className='text-gray-800 text-hover-primary mb-1'

                  >
                    {item?.company} 
                  </Link>
                </td>
                <td>
                  <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div>
                </td>
                <td>
                  <DropdownButton id='dropdown-basic-button' title='Schemes'>
                    {item.scheme_names?.map((certification, index) => (
                      <Dropdown.Item key={index}>{certification?.name}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                </td>
                <td>
                  <div className='badge badge-light-primary fw-bold'>Approved</div>
                </td>
                {/* <td>
                  {item?.accessor_approve_date
                    ? moment(item.accessor_approve_date).format('LL')
                    : null}
                </td> */}
                <td>{item && item.create_at ? formatDate(item.create_at) : ''}</td>

                <td className='text-center'>
                  <a
                    // href={`/assessor/verified/${item?.id}/actions`}
                    className='btn btn-light btn-active-light-primary btn-sm'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                  >
                    Actions
                    <span className='svg-icon svg-icon-5 m-0'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </a>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <Link
                        to={`/assessor/verified/${item?.id}/settings`}
                        className='menu-link px-3'
                      >
                        Edit
                      </Link>
                    </div>
                    <div className='menu-item px-3'>
                      <a
                        href='#'
                        className='menu-link px-3'
                        data-kt-users-table-filter='delete_row'
                      >
                        Delete
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className='pagination'>
          <li className='page-item previous disabled'>
            <a href='#' className='page-link'>
              <i className='previous'></i>
            </a>
          </li>
          <li className='page-item'>
            <a href='#' className='page-link'>
              1
            </a>
          </li>
          <li className='page-item active'>
            <a href='#' className='page-link'>
              2
            </a>
          </li>
          <li className='page-item'>
            <a href='#' className='page-link'>
              3
            </a>
          </li>
          <li className='page-item'>
            <a href='#' className='page-link'>
              4
            </a>
          </li>
          <li className='page-item'>
            <a href='#' className='page-link'>
              5
            </a>
          </li>
          <li className='page-item'>
            <a href='#' className='page-link'>
              6
            </a>
          </li>
          <li className='page-item next'>
            <a href='#' className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    </>
  )
}
export  default ARCList
