import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import { Link } from "react-router-dom";
import UAFLoader from "../../loader/UafLoader";
const API_URL = process.env.REACT_APP_API_URL
interface RoleData {
    id: string;
    name: string;
}


interface MatrixData {
    id: string;
    first_name: string;
    last_name: string;
    assign_role_dict: {
        id: string;
        name: string;
    }[];
}
const RoleMatrix: React.FC = () => {

    const [searchQuery, setSearchQuery] = useState('')
    const [loader, setLoader] = useState<any>(false);
    const [userData, setUserData] = useState<MatrixData[]>([]);
    const [filteredValue, setFilteredValue] = useState<MatrixData[]>([])
    const [roleList, setRoleList] = useState<RoleData[]>([]);
    console.log(userData, "userData");
    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_URL}/role/list/`
            );
            setRoleList(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoader(true)
                const response = await axios.get(`${API_URL}/global/user/scheme/filter/?&is_verify_accessor=true`);
                setUserData(response?.data);
                setLoader(false)
            } catch (error) {
                console.error('Error fetching standard data:', error);
            }
        };
        fetchUsers();
    }, []);

    const getUserCertificates = (user: MatrixData) => {
        const userCertIds = user.assign_role_dict.map(cert => cert.id);
        return roleList.filter(cert => userCertIds.includes(cert.id));
    };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e?.target?.value)
        // Filter the value array based on the search query
        const filteredData = userData.filter((user) => {
            const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase()
            const searchValue = e.target.value.toLowerCase()

            return (
                fullName.includes(searchValue) ||
                user?.first_name.toLowerCase().includes(searchValue) ||
                user?.last_name.toLowerCase().includes(searchValue)
            )
        })
        console.log(filteredData, "filteredData");
        setFilteredValue(filteredData)
    }



    return (
        <>{loader ? <UAFLoader /> : null}
        <div className="mb-5 mb-xl-8">
            <div className='card-header border-0 py-5 d-flex mb-5'>
                <h3 className='card-title align-items-start flex-column mt-2'>
                    <span className='card-label fw-bold fs-1'>Role Matrix</span>
                </h3>
                <input style={{ marginLeft: "auto", border: "1px solid #dddddd" }}
                    type='text'
                    data-kt-user-table-filter='search'
                    onChange={handleSearchChange}
                    className='form-control form-control-solid w-300px ps-14'
                    placeholder='Search Assessor'
                />
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className=' align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold text-dark bg-light' style={{ fontSize: "14px" }}>
                                <th style={{ border: "1px solid #dddddd", padding: "8px" }} className='ps-4 min-w-200px rounded-start'> NAME</th>
                                {roleList.map(item => (
                                    <th style={{ border: "1px solid #dddddd", padding: "8px" }} key={item.id} className='min-w-200px'>{item.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {(searchQuery.length > 0 ? filteredValue : userData).map((user, index) => (
                                <tr  /* style={{ border: "1px solid #333",...(i === userData.length - 1 && { borderBottom: "1px solid #333 !important" })}} */ >
                                    <td style={{ border: "1px solid #dddddd", padding: "8px" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <Link to={`/assessor/verified/${user?.id}/overview`} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                    {user.first_name} {user.last_name}
                                                </Link>
                                            </div>
                                        </div>
                                    </td>
                                    {roleList.map((cert) => (
                                        <td key={cert.id} style={{ border: "1px solid #dddddd", textAlign: "center" }}>
                                            {getUserCertificates(user).some(c => c.id === cert.id) ? (
                                                <KTIcon iconName='check' className='fs-1 text-primary ' />
                                            ) : null}
                                        </td>
                                    ))}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    );
};

export default RoleMatrix;