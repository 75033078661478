import { useEffect, useState } from "react";
import { KTCard, KTIcon } from "../../../../../_metronic/helpers"
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NewAssementModal from "../../../apps/create-assessment/create-assessment-components/modals/NewAssementModal";
import { useNavigate } from "react-router-dom";
import CreateHistoricalAssessmentModal from "../modals/CreateHistoricalAssessmentModal";
import BasicDetailsHistoricalAssessmentModal from "../modals/BasicDetailsHistoricalAssessmentModal";
const API_URL = process.env.REACT_APP_API_URL
interface CabUser {
    id: string
    first_name: string
    last_name: string
    email: string
    phone: string
    scheme_names: { id: string; name: string }[]
    standard_names: { id: string; name: string }[]
    programme_names: { id: string; name: string }[]
    create_at: string
    company: string
}
const CabUserDefault: CabUser = {
    id: "",
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    phone: "",
    scheme_names: [],
    standard_names: [],
    programme_names: [],
    create_at: "",
}
const HistoricalAssessmentWrapper = () => {
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_API_URL
    interface User {
        value: string;
        label: string;
    }
    const [createHistoricalAssessmentModal, setCreateHistorialAssessmentModal] = useState<CabUser>(CabUserDefault);
    const [cabData, setCabData] = useState<CabUser[]>([])
    console.log(cabData, 'cabData');
    const [cabRequestModal, setCabRequestModal] = useState<boolean>(false);
    useEffect(() => {
        if (!cabRequestModal)
            axios.get(`${BASE_URL}/account/historical/cab/user/`)
                .then(response => setCabData(response.data))
                .catch(error => { console.log(error); });
    }, [cabRequestModal]);
    return (
        <>
            <div className="card card-flush">
                <div className='card-header pt-5'>
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                </svg>
                            </span>
                            <input id="search_button" type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search user" />
                        </div>
                    </div>
                    {/* <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                            <button id="add_button" type="button" className="btn btn-primary" onClick={() => setCabRequestModal(true)}>

                                Add Historical CAB</button>
                        </div></div> */}
                </div>
                <div className='card-body pt-5 assesment_planner_table'>
                    {/* <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                        <thead>
                            <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                <th className="w-10px pe-2">
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                        <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" value="1" />
                                    </div>
                                </th>
                                <th className="min-w-125px">Cab Name</th>
                                <th className="min-w-125px">Assessment Type
                                </th>
                                <th className="min-w-125px">Assessment Number
                                </th>
                                <th className="min-w-125px">Date of Assessment
                                </th>
                                <th className="min-w-125px">Scheme
                                </th>
                                <th className="min-w-125px">IAF Code/ Technical Area in case of Witness
                                </th>
                                <th className="min-w-125px">Name of Assessor
                                </th>
                                <th className="min-w-125px">Assessment Report Available
                                </th>
                                <th className="min-w-125px">Any NC Raised
                                </th>
                                <th className="min-w-125px">If Yes, Number of NCs
                                </th>
                                <th className="min-w-125px">NC Issue Date
                                </th>
                                <th className="min-w-125px">Minor NC Closure Date</th>
                                <th className="min-w-125px">NC Closure Date
                                </th>
                                <th className="min-w-125px">Remark</th>
                                <th className="text-end min-w-100px">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-semibold">
                            <tr >
                                <td>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="1" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                        <thead>
                            {/* <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'> */}
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>
                                <th className='w-10px pe-2'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            data-kt-check='true'
                                            data-kt-check-target='#kt_table_users .form-check-input'
                                            value='1'
                                        />
                                    </div>
                                </th>
                                <th className='min-w-125px'>CAB Organisation</th>
                                <th className='min-w-125px'>Contact Info</th>
                                <th className='min-w-125px'>Schemes</th>
                                <th className='min-w-125px'>Standards</th>
                                <th className="min-w-125px">IAF Code/ Technical Area in case of Witness</th>
                                <th className="min-w-125px">Assessment Type</th>
                                <th className="min-w-125px">Assessment Number </th>
                                <th className="min-w-125px">Date of Assessment</th>
                                <th className="min-w-125px">Name of Assessor </th>
                                <th className="min-w-125px">Assessment Report Available </th>
                                <th className="min-w-125px">Any NC Raised</th>
                                <th className="min-w-125px">If Yes, Number of NCs</th>
                                <th className="min-w-125px">NC Issue Date</th>
                                <th className="min-w-125px">Minor NC Closure Date</th>
                                <th className="min-w-125px">NC Closure Date </th>
                                <th className="min-w-125px">Remark</th>
                                <th className='text-center min-w-100px'>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                            {cabData?.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input className='form-check-input' type='checkbox' value='1' />
                                        </div>
                                    </td>
                                    <td>
                                        {item?.company}
                                    </td>
                                    <td>
                                        <div className='text-gray-800 text-hover-primary mb-1'>{item?.email}</div>
                                    </td>
                                    <td>
                                        <DropdownButton id='dropdown-basic-button' title='Schemes'>
                                            {item.scheme_names?.map((certification, index) => (
                                                <Dropdown.Item key={index}>{certification?.name}</Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </td>
                                    <td>
                                        <DropdownButton id='dropdown-basic-button' title='Standards'>
                                            {item.standard_names?.map((certification, index) => (
                                                <Dropdown.Item key={index}>{certification?.name}</Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">-- </td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="min-w-125px">--</td>
                                    <td className="text-end"><div className="dropdown"><button className="btn btn-light btn-active-light-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton0" data-bs-toggle="dropdown" aria-expanded="false">Actions</button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton0" >
                                            <li><a className="dropdown-item" onClick={() => setCreateHistorialAssessmentModal(item)}>Edit</a></li>
                                            <li><a className="dropdown-item" href="#">Delete</a>
                                            </li>
                                        </ul>
                                    </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <BasicDetailsHistoricalAssessmentModal cabRequestModal={cabRequestModal} setCabRequestModal={setCabRequestModal} /> */}
            <CreateHistoricalAssessmentModal show={createHistoricalAssessmentModal} handleClose={() => setCreateHistorialAssessmentModal(CabUserDefault)} />
        </>
    )
}
export default HistoricalAssessmentWrapper