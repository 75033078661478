import React from 'react'
import LocationTab from '../../../profile/components/CabInfoTabs/LocationTab'
import ReviewerTab from './PerformanceTab/ReviewerTab'
import ARCTab from './PerformanceTab/ARCTab'
import AssessorTab from './PerformanceTab/AssessorTab'

const VerifiedAssessorPerformance = ({userId}) => {
  const userIdObject = JSON.parse(userId)
  const uuid = userIdObject['*']
  const uuidRegex =
    /\b([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/g
  const uuids = uuid.match(uuidRegex)
  const urlId = uuids[0]
  return (
    <div className='card mb-5 mb-xl-10'>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
        <li className='nav-item'>
          <a
            className='nav-link active  cab_tab_color_black'
            data-bs-toggle='tab'
            href='#kt_tab_pane_1'
          >
            Application Reviewer Performance
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link cab_tab_color_black'
           data-bs-toggle='tab'
            href='#kt_tab_pane_2'>
            ARC Member Performance
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link cab_tab_color_black' 
          data-bs-toggle='tab'
           href='#kt_tab_pane_3'>
            Assessor Performance
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
      
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='show'>
            <ReviewerTab urlId={urlId} />
            </div>
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <ARCTab urlId={urlId} />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
            <AssessorTab urlId={urlId} />
          </div>
       
      </div>
    </div>
  )
}

export default VerifiedAssessorPerformance
