import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import "../../../../src/_metronic/assets/css/certificateCss.css"
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import remarkicon from '../../../_metronic/assets/images/remark.png'
import { getUserDetails } from '../assessor-mangement/assessorLead/components/AssessorFunction'
import { UserData, IafData } from './UserDataInterface'
import axios from 'axios'
interface FormDataProps {
  userData: UserData;
}
// interface UserData {
//     id: string;
//     first_name: string;
//     last_name: string;
//     email: string;
//     company: string;
//     phone: string;
//     profile: File | null;
//     certification: string[];
//     country: string;
//     message: string;
//     graduation: string;
//     company_web: string;
//     university_name: string;
//     graduation_date: Date | null;
//     work_experience: boolean;
//     training_17011: boolean;
//     training_27000: boolean;
//     training_17021_2: boolean;
//     training_27001: boolean;
//     training_270000: boolean;
//     training_17024: boolean;
//     training_27000: boolean;
//     training_27005: boolean;
//     training_27006: boolean;
//     training_27007: boolean
//     training_27008: boolean;
//     training_22000: boolean;
//     training_22003: boolean;
//     training_22003_1: boolean;
//     training_50001: boolean;
//     training_50003: boolean;
//     training_45001: boolean;
//     training_13485: boolean;
//     training_27008: boolean;
//     training_14001: boolean;
//     resume: File | null;

//     work_experience_doc: File | null;
//     training_17011_doc: File | null;
//     training_27000_doc: File | null;
//     training_17021_2_doc: File | null;
//     training_27001_doc: File | null;
//     training_270000_doc: File | null;
//     training_17024_doc: File | null;
//     training_27000_doc: File | null;
//     training_27005_doc: File | null;
//     training_27006_doc: File | null;
//     training_27007_doc: File | null;
//     training_27008_doc: File | null;
//     training_22000_doc: File | null;
//     training_22003_doc: File | null;
//     training_22003_1_doc: File | null;
//     training_50001_doc: File | null;
//     training_50003_doc: File | null;
//     training_45001_doc: File | null;
//     training_13485_doc: File | null;
//     training_27008_doc: File | null;
//     training_14001_doc: File | null;
// }
const API_URL = process.env.REACT_APP_API_URL
const ISMSform: React.FC<FormDataProps> = ({ userData }) => {
  const myid = userData?.id
  const [userDataget, setUserDataget] = useState<UserData>()
  const [userInfo, setUserInfo] = useState<UserData>()


  const [userDataiaf, setUserDataiaf] = useState<IafData[]>([])
  console.log("userDataget:", userDataget);
  console.log("userDataget:", userInfo);
  console.log("userDataget:", userDataiaf);
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [status, setstatus] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const graduation = 'graduation'
  const work_experience = 'workexperience'
  const training_17011 = 'training17011'
  const training_27000 = 'training_27000'
  const training_27001 = 'training_27001'
  const training_27005 = 'training_27005'
  const training_27006 = 'training_27006'
  const training_27007 = 'training_27007'
  const training_27008 = 'training_27008'
  const internal_training = "internal_training"
  const fetchData = async () => {
    try {
      const result = await getUserDetails(myid)
      setUserInfo(result?.details)
      setUserDataget(result?.documents)
      setUserDataiaf(
        result?.iaf_critical.filter(
          (item) => item.scheme_id === '6cf8cc83-36dd-4661-a0a7-ff87224bcace'
        )
      )
      // console.log(result?.documents?.id,"this is user id")
    } catch (error) {
      // Handle errors
      // console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handlePdfIconClick = (url) => {
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }
  /*Change Document Status To Approve */

  const handleStatusChange = async (statusItem: string) => {
    const endpoints = {
      graduation: `${API_URL}/team/graduation/certification/${userDataget?.id}/update`,
      workexperience: `${API_URL}/team/work/experience/${userDataget?.id}/update`,
      training17011: `${API_URL}/team/training/17011/${userDataget?.id}/update`,
      training_27000: `${API_URL}/team/training/27000/${userDataget?.id}/update`,
      training_27001: `${API_URL}/team/training/27001/${userDataget?.id}/update`,
      training_27005: `${API_URL}/team/training/27005/${userDataget?.id}/update`,
      training_27006: `${API_URL}/team/training/27006/${userDataget?.id}/update`,
      training_27007: `${API_URL}/team/training/27007/${userDataget?.id}/update`,
      training_27008: `${API_URL}/team/training/27008/${userDataget?.id}/update`,
      internal_training: `${API_URL}/team/internal/training/${userDataget?.id}/update`,
    }

    const payloads = {
      graduation: {
        graduation_certification_approved: true,
        graduation_certification_pending: false,
      },
      workexperience: { work_experience_approved: true, work_experience_pending: false },
      training17011: { training_17011_approved: true, training_17011_pending: false },
      training_27000: { training_27000_approved: true, training_27000_pending: false },
      training_27001: { training_27001_approved: true, training_27001_pending: false },
      training_27005: { training_27005_approved: true, training_27005_pending: false },
      training_27006: { training_27006_approved: true, training_27006_pending: false },
      training_27007: { training_27007_approved: true, training_27007_pending: false },
      training_27008: { training_27008_approved: true, training_27008_pending: false },
      internal_training: { internal_training_approved: true, internal_training_pending: false },
    }

    try {
      const response = await axios.put(endpoints[statusItem], payloads[statusItem])
      if (response.status === 200) {
        toast.success(`${statusItem} Certificate Approved`)
        fetchData()
      }
    } catch (error) {
      // Handle errors
    }
  }

  /*Change Document IAF To Approve */

  const handleStatusIaf = async (id: string) => {
    try {
      const response = await axios.put(`${API_URL}/team/accessor/iaf-critical/${id}/update/`, {
        id_approved: true,
        id_reject: false,
      })
      if (response.status === 200) {
        toast.success(`Certificate Approved`)
        fetchData()
      }
    } catch (error) {
      // Handle errors
    }
  }

  /*Change Document Status To Pending */

  const handleStatusPending = async (statusItem: string) => {
    const endpoints = {
      graduation: `${API_URL}/team/graduation/certification/${userDataget?.id}/update`,
      workexperience: `${API_URL}/team/work/experience/${userDataget?.id}/update`,
      training17011: `${API_URL}/team/training/17011/${userDataget?.id}/update`,
      training_27000: `${API_URL}/team/training/27000/${userDataget?.id}/update`,
      training_27001: `${API_URL}/team/training/27001/${userDataget?.id}/update`,
      training_27005: `${API_URL}/team/training/27005/${userDataget?.id}/update`,
      training_27006: `${API_URL}/team/training/27006/${userDataget?.id}/update`,
      training_27007: `${API_URL}/team/training/27007/${userDataget?.id}/update`,
      training_27008: `${API_URL}/team/training/27008/${userDataget?.id}/update`,
      internal_training: `${API_URL}/team/internal/training/${userDataget?.id}/update`,
    }

    const payloads = {
      graduation: {
        graduation_certification_pending: true,
        graduation_certification_approved: false,
      },
      workexperience: { work_experience_pending: true, work_experience_approved: false },
      training17011: { training_17011_pending: true, training_17011_approved: false },
      training_27000: { training_27000_pending: true, training_27000_approved: false },
      training_27001: { training_27001_pending: true, training_27001_approved: false },
      training_27005: { training_27005_pending: true, training_27005_approved: false },
      training_27006: { training_27006_pending: true, training_27006_approved: false },
      training_27007: { training_27007_pending: true, training_27007_approved: false },
      training_27008: { training_27008_pending: true, training_27008_approved: false },
      internal_training: { internal_training_pending: true, internal_training_approved: false },
    }

    try {
      const response = await axios.put(endpoints[statusItem], payloads[statusItem])
      if (response.status === 200) {
        toast.error('Document Unapproved Add Remarks')
        fetchData()
      }
    } catch (error) {
      // Handle errors
    }
  }

  /*Change Document IAF To Pending */

  const handlePendingIaf = async (id: string) => {
    try {
      const response = await axios.put(`${API_URL}/team/accessor/iaf-critical/${id}/update/`, {
        id_approved: false,
        id_reject: true,
      })
      if (response.status === 200) {
        toast.error(`Document Unapproved Add Remarks`)
        fetchData()
      }
    } catch (error) {
      // Handle errors
    }
  }

  /*Handle Send Remark */
  const [showRemark, setShowRemark] = useState({})
  const [remarkText, setRemarkText] = useState({})
  const [selectedItemId, setSelectedItemId] = useState(null)

  const handleRemarkIconClick = (itemId) => {
    // Toggle the visibility of the textarea if it's already open
    if (selectedItemId === itemId) {
      setSelectedItemId(null)
    } else {
      // Open the textarea for the clicked item
      setSelectedItemId(itemId)
    }
  }

  const handleChangeIaf = (event, itemId) => {
    const { value } = event.target
    setRemarkText((prevState) => ({
      ...prevState,
      [itemId]: value,
    }))
  }

  const handleIafRemark = (itemId) => {
    // Implement your logic to handle the submission of the remark
    console.log('Remark submitted for item ID:', itemId)
    axios
      .put(`${API_URL}/team/accessor/iaf-critical/${itemId}/update/`, { remark: remarkText[itemId] })
      .then((response) => {
        // Handle success
        // console.log('PUT request successful:', response.data)
        setShowRemark((prevState) => ({
          ...prevState,
          [itemId]: false, // Close the text area after submission for a specific row
        }))
        if (response.status === 200) {
          // Setgrad(false)
          toast.success('Remarks Sent')
          setSelectedItemId(null)
          // console.log(response.data)
        }
        fetchData()
      })
      .catch((error) => {
        // Handle error
        // console.error('Error sending PUT request:', error)
      })
  }

  const getAPIURL = (rowKey) => {
    switch (rowKey) {
      case 'graduation_remark':
        return `${API_URL}/team/graduation/certification/${userDataget?.id}/update`
      case 'work_remark':
        return `${API_URL}/team/work/experience/${userDataget?.id}/update`
      case 'training_17011':
        return `${API_URL}/team/training/17011/${userDataget?.id}/update`
      case 'training_27000':
        return `${API_URL}/team/training/27000/${userDataget?.id}/update`
      case 'training_27001':
        return `${API_URL}/team/training/27001/${userDataget?.id}/update`
      case 'training_27005':
        return `${API_URL}/team/training/27005/${userDataget?.id}/update`
      case 'training_27006':
        return `${API_URL}/team/training/27006/${userDataget?.id}/update`
      case 'training_27007':
        return `${API_URL}/team/training/27007/${userDataget?.id}/update`
      case 'training_27008':
        return `${API_URL}/team/training/27008/${userDataget?.id}/update`
      case 'internal_training':
        return `${API_URL}/team/internal/training/${userDataget?.id}/update`
      default:
        return '' // Handle default case
    }
  }
  const getRemarkParamName = (rowKey) => {
    switch (rowKey) {
      case 'graduation_remark':
        return 'graduation_certification_remark'
      case 'work_remark':
        return 'work_experience_remark'
      case 'training_17011':
        return 'training_17011_remark'
      case 'training_27000':
        return 'training_27000_remark'
      case 'training_27001':
        return 'training_27001_remark'
      case 'training_27005':
        return 'training_27005_remark'
      case 'training_27007':
        return 'training_27007_remark'
      case 'training_27006':
        return 'training_27006_remark'
      case 'training_27008':
        return 'training_27008_remark'
      case 'internal_training':
        return 'internal_training_remark'
      // Add more cases as needed
      default:
        return '' // Handle default case
    }
  }

  const handleRemarkClick = (rowKey) => {
    setShowRemark((prevState) => ({
      ...prevState,
      [rowKey]: !prevState[rowKey], // Toggle the state for the clicked row
    }))
  }

  const handleChangeRemark = (event, rowKey) => {
    setRemarkText({ ...remarkText, [rowKey]: event.target.value })
  }
  /* Submit Remark Functionality*/
  const handleSubmitRemark = (rowKey) => {
    // Logic to handle submission of the remark for a specific row
    // Here you can send a PUT request to the appropriate API for the row
    const remarkParamName = getRemarkParamName(rowKey) // Get the parameter name for the remark based on the row
    axios
      .put(getAPIURL(rowKey), { [remarkParamName]: remarkText[rowKey] })
      .then((response) => {
        // Handle success
        // console.log('PUT request successful:', response.data)
        setShowRemark((prevState) => ({
          ...prevState,
          [rowKey]: false, // Close the text area after submission for a specific row
        }))
        if (response.status === 200) {
          // Setgrad(false)
          toast.success('Remarks Submitted ')
          // console.log(response.data)
        }
      })
      .catch((error) => {
        // Handle error
        // console.error('Error sending PUT request:', error)
      })
    fetchData()
  }

  /* Submit All Functionality*/
  const handleSubmitfinal = async () => {
    try {
      const response = await axios.put(`${API_URL}/team/document/reviewer/data/update/`, {
        user: userData?.id,
        document_reviewer: true,
      })

      // Handle success
      console.log('PUT request successful:', response.data)
      if (response.status === 200) {
        // Setgrad(false)
        toast.success('Documents Reviewed')
        // console.log(response.data)
      }
    } catch (error) {
      // Handle error
      // console.error('Error sending PUT request:', error)
    }
    fetchData()
  }

  return (
    <div className=''>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-custom-header '>
          <div className='d-flex flex-column justify-content-center align-items-center py-5'>
            <div className=''>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                className='h-60px h-lg-60px app-sidebar-logo-default theme-light-show'
              />
            </div>
            <div className='mt-5'>
              <h2 style={{ fontSize: '18px' }}>UNITED ACCREDITATION FOUNDATION</h2>
            </div>
          </div>
        </div>
        <div className='px-20 py-3 text-center cf-border-bottom'>
          <h2 className='cf-text-gray' style={{ fontSize: '14px' }}>
            Initial Competence Evaluation - ISMS
          </h2>
        </div>
        <div className='px-20 py-3 cf-border-bottom'>
          <div className='row '>
            <div className='col-lg-6 col-xl-6 mt-1'>
              <h2 className='px-5' style={{ fontSize: '16px' }}>
                Assessor Name / Lead Assessor
              </h2>
            </div>
            <div className='col-lg-6 col-xl-6 text-center mt-1'>
              <h2 className='' style={{ fontSize: '16px' }}>
                {userData?.first_name} {userData?.last_name}
              </h2>
            </div>
          </div>
        </div>
        <div className='card-body px-20'>
          <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
              <tr
                style={{ background: '#f7f7f7' }}
                className='text-start  fw-bold  text-uppercase gs-0'
              >
                <th className='px-5 min-w-100px'>Competence Parameters Requirement</th>
                <th className='px-5 min-w-125px'>Actual</th>
                <th className='px-5 min-w-150px'>Status</th>
                <th className='px-5 min-w-120px'>Doc</th>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <th className='px-5 min-w-150px'>Accepted/Deferred</th>
                    <th className='px-5 min-w-120px'>Remarks</th>
                  </>
                ) : null}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Under Graduate (Bachelor)*
                  </div>
                </td>

                <td>
                  {userDataget?.graduation_certification ? (
                    <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                  ) : (
                    <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                  )}
                </td>
                <td>
                  {userDataget?.graduation_certification_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.graduation_certification ? (
                    <>
                      <a
                        onClick={() => handlePdfIconClick(userDataget?.graduation_certification)}
                        // href='#'
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.graduation_certification ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px', cursor: 'pointer' }} // Added cursor pointer for better UX
                              onClick={() => handleStatusChange(graduation)}
                            ></i>
                          </div>

                          <div className='danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x text-danger'
                              style={{ fontSize: '25px', cursor: 'pointer' }} // Added cursor pointer for better UX
                              onClick={() => handleStatusPending(graduation)}
                            ></i>
                          </div>
                        </>
                      ) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('graduation_remark')}
                      />
                    </td>

                  </>
                ) : null}
              </tr>
              {showRemark['graduation_remark'] && (
                <tr>
                  <td className='align-items-center'>
                    <textarea
                      value={remarkText['graduation_remark']}
                      onChange={(event) => handleChangeRemark(event, 'graduation_remark')}
                      placeholder='Enter your remark...'
                      className='form-control mr-2'
                      rows={3}
                      style={{ flex: 1 }}
                    ></textarea>
                  </td>
                  <td colSpan={1}></td>
                  {/* <td></td>
                  <td></td>
                  <td></td> */}
                  <td className='align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('graduation_remark')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}

              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Work experience in accreditaion/certification process (Minimum 5 years)*
                  </div>
                </td>
                <td>
                  {userDataget?.work_experience_doc ? (
                    <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                  ) : (
                    <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                  )}
                </td>
                <td>
                  {userDataget?.work_experience_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.work_experience_doc ? (
                    <>
                      <a
                        onClick={() => handlePdfIconClick(userDataget?.work_experience_doc)}
                        // href='#'
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.work_experience_doc ? (
                        <>
                          <div className=' success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(work_experience)}
                            ></i>
                          </div>

                          <div
                            className=' danger_class badge badge-light-danger fw-bold m-2'
                            onClick={() => setstatus(false)}
                          >
                            <i
                              className='bi bi-x danger text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(work_experience)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>

                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('work_remark')}
                      />
                    </td>
                  </>
                ) : null}
              </tr>
              {showRemark['work_remark'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['work_remark']}
                      onChange={(event) => handleChangeRemark(event, 'work_remark')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td> */}
                  <td colSpan={1}></td>
                  {/* <td></td>
                  <td></td>
                  <td></td> */}
                  <td className=' align-items-center'>
                    <Button variant='primary' onClick={() => handleSubmitRemark('work_remark')}>
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    UAF internal training for accreditation process*
                  </div>
                </td>
                <td>
                  {userDataget?.internal_training === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.internal_training_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>

                <td>
                  {userDataget?.internal_training_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.internal_training_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.internal_training_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(internal_training)}
                            ></i>
                          </div>
                          <div className='danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(internal_training)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_17011')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['internal_training'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['internal_training']}
                      onChange={(event) => handleChangeRemark(event, 'internal_training')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button variant='primary' onClick={() => handleSubmitRemark('internal_training')}>
                      Submit
                    </Button>
                  </td>
                </tr>
              )}

              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO/IEC 17011
                  </div>
                </td>
                <td>
                  {userDataget?.training_17011 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_17011_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>

                <td>
                  {userDataget?.training_17011_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_17011_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_17011_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_17011)}
                            ></i>
                          </div>
                          <div className='danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_17011)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_17011')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_17011'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_17011']}
                      onChange={(event) => handleChangeRemark(event, 'training_17011')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button variant='primary' onClick={() => handleSubmitRemark('training_17011')}>
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO 27000
                  </div>
                </td>
                <td>
                  {userDataget?.training_27000 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27000_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27000_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27000_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27000_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27000)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27000)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27000')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27000'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27000']}
                      onChange={(event) => handleChangeRemark(event, 'training_27000')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('training_27000')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO/IEC 27001
                  </div>
                </td>
                <td>
                  {userDataget?.training_27001 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27001_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27001_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27001_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27001_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27001)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27001)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27001')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27001'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27001']}
                      onChange={(event) => handleChangeRemark(event, 'training_27001')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('training_27001')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO/IEC 27005
                  </div>
                </td>
                <td>
                  {userDataget?.training_27005 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27005_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27005_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27005_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27005_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27005)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27005)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27005')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27005'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27005']}
                      onChange={(event) => handleChangeRemark(event, 'training_27005')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('training_27005')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO/TS 27006
                  </div>
                </td>
                <td>
                  {userDataget?.training_27006 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27006_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27006_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27006_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27006_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27006)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27006)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27006')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27006'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27006']}
                      onChange={(event) => handleChangeRemark(event, 'training_27006')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('training_27006')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}
              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    Training on ISO/IEC 27007
                  </div>
                </td>
                <td>
                  {userDataget?.training_27007 === true ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27007_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27007_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27007_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27007_doc ? (
                        <>
                          <div className='success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27007)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27007)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      {/* <i className='bi bi-chat  text-primary' style={{fontSize: '25px'}}></i> */}
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27007')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27007'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27007']}
                      onChange={(event) => handleChangeRemark(event, 'training_27007')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button
                      variant='primary'
                      onClick={() => handleSubmitRemark('training_27007')}
                    >
                      Submit
                    </Button>
                  </td>
                </tr>
              )}

              <tr>
                <td className='px-5'>
                  <div className='text-gray-800 text-hover-primary mb-1'>
                    {/* Witness Assessor: */}
                    Training of ISO/IEC TS 27008
                  </div>
                </td>
                <td>
                  {userDataget?.training_27008_doc ? (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                    </>
                  ) : (
                    <>
                      <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                    </>
                  )}
                </td>
                <td>
                  {userDataget?.training_27008_approved ? (
                    <div className='badge badge-light-success fw-bold'>Approved</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bold'>Pending...</div>
                  )}
                </td>
                <td>
                  {userDataget?.training_27008_doc ? (
                    <>
                      <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                        onClick={() => handlePdfIconClick(userDataget?.training_27008_doc)}
                        className=' me-3'
                      >
                        <img src='/media/doc.png' alt='' />
                      </a>
                    </>
                  ) : (
                    <>
                      <div className=' me-3'>
                        <img src='/media/no-doc.png' alt='' />
                      </div>
                    </>
                  )}
                </td>
                {userInfo?.is_verify_accessor === false ? (
                  <>
                    <td>
                      {userDataget?.training_27008_doc ? (
                        <>
                          <div className=' success_class badge badge-light-success fw-bold m-2'>
                            <i
                              className='bi bi-check text-success'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusChange(training_27008)}
                            ></i>
                          </div>
                          <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                            <i
                              className='bi bi-x  text-danger'
                              style={{ fontSize: '25px' }}
                              onClick={() => handleStatusPending(training_27008)}
                            ></i>
                          </div>
                        </>) : null}
                    </td>
                    <td>
                      <img
                        src={remarkicon}
                        alt='Remark'
                        style={{ width: '25px', height: '25px' }}
                        className='m-2'
                        onClick={() => handleRemarkClick('training_27008')}
                      />
                    </td>
                  </>) : null}
              </tr>
              {showRemark['training_27008'] && (
                <tr>
                  <td className=' align-items-center'>
                    <textarea
                      value={remarkText['training_27008']}
                      onChange={(event) => handleChangeRemark(event, 'training_27008')}
                      placeholder='Enter your remark...'
                      className='form-control'
                      rows={3}
                    ></textarea>
                  </td>
                  {/* <td></td>
                  <td></td>
                  <td></td>
                  <td></td> */}
                  <td colSpan={1}></td>
                  <td className=' align-items-center'>
                    <Button variant='primary' onClick={() => handleSubmitRemark('training_27008')}>
                      Submit
                    </Button>
                  </td>
                </tr>
              )}

              {userDataiaf.map((item) => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td className='px-5'>
                      <span>{item?.cluster_name}</span>
                      <div className='text-gray-800 text-hover-primary mb-1'>
                        {item?.critical_name}
                      </div>
                    </td>
                    <td>
                      {item?.file ? (
                        <div className='text-gray-800 text-hover-primary mb-1'>Yes</div>
                      ) : (
                        <div className='text-gray-800 text-hover-primary mb-1'>No</div>
                      )}
                    </td>
                    <td>
                      {item?.id_approved ? (
                        <div className='badge badge-light-success fw-bold'>Approved</div>
                      ) : (
                        <div className='badge badge-light-danger fw-bold'>Pending...</div>
                      )}
                    </td>
                    <td>
                      {item?.file ? (
                        <a onClick={() => handlePdfIconClick(item?.file)} className='me-3'>
                          <img src='/media/doc.png' alt='' />
                        </a>
                      ) : (
                        <div className='me-3'>
                          <img src='/media/no-doc.png' alt='' />
                        </div>
                      )}
                    </td>
                    {userInfo?.is_verify_accessor === false ? (
                      <>
                        <td>
                          {item?.file ? (
                            <>
                              <div className=' success_class badge badge-light-success fw-bold m-2'>
                                <i
                                  className='bi bi-check text-success'
                                  style={{ fontSize: '25px' }}
                                  onClick={() => handleStatusIaf(item?.id)}
                                ></i>
                              </div>
                              <div className=' danger_class badge badge-light-danger fw-bold m-2'>
                                <i
                                  className='bi bi-x text-danger'
                                  style={{ fontSize: '25px' }}
                                  onClick={() => handlePendingIaf(item?.id)}
                                ></i>
                              </div>
                            </>) : null}
                        </td>
                        <td>
                          <img
                            src={remarkicon}
                            alt='Remark'
                            style={{ width: '25px', height: '25px' }}
                            className='m-2'
                            onClick={() => handleRemarkIconClick(item.id)}
                          />
                        </td>
                      </>) : null}
                  </tr>

                  {selectedItemId === item.id && (
                    <tr>
                      <td className='align-items-center'>
                        <textarea
                          value={remarkText[selectedItemId] || ''}
                          onChange={(event) => handleChangeIaf(event, selectedItemId)}
                          placeholder='Enter your remark...'
                          className='form-control'
                          rows={3}
                        ></textarea>
                      </td>
                      <td colSpan={1}></td>
                      <td className='align-items-center'>
                        <Button variant='primary' onClick={() => handleIafRemark(selectedItemId)}>
                          Submit
                        </Button>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Display the PDF in the modal */}
            {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
            <iframe src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
          </Modal.Body>
        </Modal>
        <div className='p-3 text-center cf-border-top'>
          <p>
            <span style={{ fontWeight: '600' }}>NOTE:</span> A Technical Expert, having above
            mentioned work experience in said technical area, shall be additional member along with
            assessor(s) in cases where assessor(s) is/are falling short of any of above
            requisite/attributes of competence
          </p>
        </div>
        <div className='p-3 text-center cf-border-top'>
          <p>
            <span style={{ color: 'red' }}>*</span> These requirements are aplicable to document
            reviewer, office assessor, office lead assessor, witness assessor, member of
            accreditation review committee
          </p>
        </div>
        <div className='m-2 text-center '>
          {userInfo?.is_verify_accessor === false ? (

            <>  {userDataget?.graduation_certification ? (
              <Button variant='primary' onClick={() => handleSubmitfinal()}>
                Save
              </Button>
            ) : null}</>
          ) : null}

        </div>
      </div>
    </div>
  );
}

export default ISMSform;