import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const CabAuditor = () => {
  const uuid = useParams()
  const urlId = uuid?.id
  const API_URL = process.env.REACT_APP_API_URL
  const [userData, setUserData] = useState<any>()

  const [addAuditorDetail, setAddAuditorDetail] = useState({
    cab: urlId,
    name: '',
    email: '',
    role: '',
  })

  const handleAuditorChange = (e) => {
    setAddAuditorDetail({...addAuditorDetail, role: e.value})
  }

  const getUserDetails = () => {
    axios
      .get(`${API_URL}/account/patch/user/${urlId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        console.error('API Error:', error)
      })
  }

  useEffect(() => {
    getUserDetails()
  }, [urlId])

  const auditorType = [
    {
      label: 'Lead Auditor',
      value: 'Lead Auditor',
    },
    {label: 'Auditor', value: 'Auditor'},
    {label: 'Technical Expert', value: 'Technical Expert'},
  ]

  // Validation for required fields
  const validateForm = () => {
    if (!addAuditorDetail.name || !addAuditorDetail.email || !addAuditorDetail.role) {
      toast.error('Please fill out all required fields.')
      return false
    }
    return true
  }

  const handleAddAuditor = async () => {
    if (!validateForm()) return

    try {
      const response = await axios.post(`${API_URL}/certificate/auditor/post/data/`, addAuditorDetail)

      if (response.status === 200 || response.status === 201) {
        toast.success('Auditor Created Successfully')
      } else {
        toast.error(`Failed to create auditor. Status: ${response.status}`)
      }

      // Reset the form
      setAddAuditorDetail({
        cab: urlId,
        name: '',
        email: '',
        role: '',
      })
    } catch (error) {
      console.log(error)
      toast.error('An error occurred while creating the auditor')
    }
  }

  return (
    <div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Add Auditor</h3>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className='row'>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>CAB</label>
                <input
                  className='form-control form-control-solid'
                  name='User'
                  value={userData?.company || ''}
                  readOnly
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Auditor Name</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='--'
                  onChange={(e) =>
                    setAddAuditorDetail({
                      ...addAuditorDetail,
                      name: e.target.value,
                    })
                  }
                  value={addAuditorDetail?.name}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Auditor Email</label>
                <input
                  type='email'
                  className='form-control form-control-solid'
                  placeholder='--'
                  onChange={(e) =>
                    setAddAuditorDetail({
                      ...addAuditorDetail,
                      email: e.target.value,
                    })
                  }
                  value={addAuditorDetail?.email}
                />
              </div>
            </div>
            <div className='col-lg-4 fv-row'>
              <div className='mb-5'>
                <label className='required form-label'>Auditor Role</label>
                <Select
                  className='react-select-styled react-select-solid'
                  name='User'
                  classNamePrefix='react-select'
                  options={auditorType}
                  onChange={handleAuditorChange}
                  value={{value: addAuditorDetail?.role, label: addAuditorDetail?.role}}
                />
              </div>
            </div>
          </div>
          <div className='row p-5'>
            <div className='mb-2 col-6 align-text-end'>
              <button className='thm-btn' onClick={handleAddAuditor}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CabAuditor
