import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL



export const getUserDetails = async (userId: string) => {
    try {
      const response = await axios.get(`${API_URL}/team/user/data/${userId}/`);
      return response.data;
    } catch (error) {
      // Handle errors
      console.error('API Error:', error);
      // throw error;
    }
  };
  export const getCertificateList = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/list/certification/`);
      return response.data;
    } catch (error) {
      // Handle errors
      console.error('API Error:', error);
      // throw error;
    }
  };

  export const ChangeStatus = async (Id: string) => {
    try{
      const response = await axios.put(`${API_URL}/team/accessor/data/update/`,{is_verify_accessor:true,id:Id});
      console.log(response.data);
    } catch(error) {
      console.log('API Error',error);
    }
  }