import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import axios from 'axios'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<any | undefined>()
  // const [getDetails,setDetails] = useState<DetailModel | undefined>()
  console.log(currentUser,"HEHEHEHEHHEHHEHE");

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      // window.location.reload();
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.clear();

  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

// const TOKEN_CHECK_INTERVAL = 300000; 


const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()

  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)



///////////////checkTokenValidity/////////////////

// const checkTokenValidity = async () => {
//   try {
//     if (auth && auth.access) {
//       // Make a request to validate the token
//       const response = await axios.post('/api/check-token', { token: auth.access });
//       if (response.data.valid) {
//         console.log('Token is still valid');
//       } else {
//         throw new Error('Token expired');
//       }
//     }
//   } catch (error) {
//     console.error('Token check failed or token expired', error);
//     logout(); // If token is invalid or expired, log the user out
//     window.location.href = '/login'; // Redirect to login page
//   }
// };


////////////////////////checkTokenValidity//////////////


  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          console.log(data,"DATA");

          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access) {
      requestUser(auth.access)
    } else {
       logout()
      console.log(auth);

      setShowSplashScreen(false)
    }
   
 

    // eslint-disable-next-line
  }, [])




  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
