import axios from 'axios';
import React, { useEffect, useState } from 'react'
type Props = {
  userId: string;
};

const ARCOverview = ({userId}:Props) => {
const[userData,setUserData] = useState<any[]>([])

  const API_URL = process.env.REACT_APP_API_URL
  const getUserDetails = () => {
    axios
      .get(`${API_URL}/account/patch/user/${userId}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    getUserDetails()
  }, [])
  return (
    <></>
  //   <div className='row gy-10 gx-xl-10'>
  //   <div className='col-xl-4  '>
  //     <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
  //       <div className='card-header cursor-pointer'>
  //         <div className='card-title m-0'>
  //           <h3 className='fw-bolder m-0'>CAB Details</h3>
  //         </div>


  //       </div>

  //       <div className='card-body p-9'>
  //         <div className='row mb-7'>
  //           <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

  //           <div className='col-lg-8'>
  //             <span className='fw-bolder fs-6 text-dark'>
  //               {userData?.first_name} {userData?.last_name}
  //             </span>
  //           </div>
  //         </div>

  //         {userData?.company ? (
  //           <div className='row mb-7'>
  //             <label className='col-lg-4 fw-bold text-muted'>Company</label>

  //             <div className='col-lg-8 fv-row'>
  //               <span className='fw-bold fs-6'>{userData?.company}</span>
  //             </div>
  //           </div>
  //         ) : null}

  //         {userData?.phone ? (
  //           <div className='row mb-7'>
  //             <label className='col-lg-4 fw-bold text-muted'>Contact Phone</label>

  //             <div className='col-lg-8 d-flex align-items-center'>
  //               <span className='fw-bolder fs-6 me-2'>{userData?.phone}</span>
  //             </div>
  //           </div>
  //         ) : null}

  //         {userData?.company_web ? (
  //           <div className='row mb-7'>
  //             <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

  //             <div className='col-lg-8'>
  //               <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
  //                 {userData?.company_web}
  //               </a>
  //             </div>
  //           </div>
  //         ) : null}

  //         <div className='row mb-7'>
  //           <label className='col-lg-4 fw-bold text-muted'>
  //             Country

  //           </label>

  //           <div className='col-lg-8'>
  //             <span className='fw-bolder fs-6 text-dark'>{userData?.country_name}</span>
  //           </div>
  //         </div>

  //         <div className='row mb-7'>
  //           <label className='col-lg-4 fw-bold text-muted'>Communication</label>

  //           <div className='col-lg-8'>
  //             <span className='fw-bolder fs-6 text-dark'>{userData?.email}</span>
  //           </div>
  //         </div>
  //         <div className='row mb-7'>
  //           <label className='col-lg-4 fw-bold text-muted'>Resume:</label>

  //           <div className='col-lg-8'>
  //             {userData?.resume ? (
  //               <a onClick={() => handlePdfIconClick(userData?.resume)}>
  //                 <img src='/media/doc.png' alt='' />
  //               </a>
  //             ) : null}
  //           </div>
  //         </div>


  //       </div>
  //     </div>
  //   </div>
  //   <div className='col-xl-4'>
  //     <div className='card card-xl-stretch mb-5 mb-xl-8'>
  //       <div className='card-header border-1'>
  //         <h3 className='card-title fw-bold text-dark'>Programs Approved for</h3>
  //       </div>
  //       <div className='card-body pt-6'>
  //         {formQuery?.map((item) => (
  //           <div className='d-flex align-items-center mb-8' key={item?.id}>
  //             <span className='bullet bullet-vertical h-40px bg-primary'></span>
  //             <div className='flex-grow-1' style={{marginLeft: '10px'}}>
  //               <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
  //                 {item?.name}
  //               </a>

  //             </div>
  //             <span className='badge badge-light-primary fs-8 fw-bold'>Approved</span>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  //   <div className='col-xl-4'>
  //     <div className='card card-xl-stretch mb-5 mb-xl-8'>
  //       <div className='card-header border-1'>
  //         <h3 className='card-title fw-bold text-dark'>Schemes Approved for</h3>
  //       </div>
  //       <div className='card-body pt-6 accordion' id='accordionExample'>
  //         {Object.keys(groupedData)?.map((schemeName, index) => (
  //           <div className='accordion-item' key={index}>
  //             <h2 className='accordion-header' id={`heading${index}`}>
  //               <button
  //                 className='accordion-button'
  //                 type='button'
  //                 data-bs-toggle='collapse'
  //                 data-bs-target={`#collapse${index}`}
  //                 aria-expanded='true'
  //                 aria-controls={`collapse${index}`}
  //               >
  //                 <span className='bullet bullet-vertical h-40px bg-primary'></span>
  //                 <div className='flex-grow-1' style={{marginLeft: '10px'}}>
  //                   <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
  //                     {schemeName}
  //                   </span>
  //                 </div>
  //                 <span className='badge badge-light-primary fs-8 fw-bold'>Approved</span>
  //               </button>
  //             </h2>
  //             <div
  //               id={`collapse${index}`}
  //               className='accordion-collapse collapse show'
  //               aria-labelledby={`heading${index}`}
  //               data-bs-parent='#accordionExample'
  //             >
  //               <div className='accordion-body'>
  //                 {groupedData[schemeName].map((item, idx) => (
  //                   <li key={idx}>{item.cluster_name}</li>
  //                 ))}
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  //   {assignedRole?.length > 0 ? (
  //     <div className='col-xl-4'>
  //       <div className='card card-xl-stretch mb-5 mb-xl-8'>
  //         <div className='card-header border-1'>
  //           <h3 className='card-title fw-bold text-dark'>Assigned Roles</h3>
  //         </div>
  //         <div className='card-body pt-6'>
  //           {assignedRole?.map((item) => (
  //             <div className='d-flex align-items-center mb-8' key={item?.id}>
  //               <span className='bullet bullet-vertical h-40px bg-primary'></span>
  //               <div className='flex-grow-1' style={{marginLeft: '10px'}}>
  //                 <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
  //                   {item?.name}
  //                 </a>

  //               </div>
  //               <span className='badge badge-light-primary fs-8 fw-bold'>Verified</span>
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   ) : null}
  // </div>
  )
}

export default ARCOverview