import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CertificateGraph from './component/CertificateGraph';


interface ProgrammeData {
    id: string
    name: string
    description: string
}
const SingleCabDash = () => {
    const userId = localStorage.getItem("current_user_id");
    console.log(userId, "userId");
    const BASE_URL = process.env.REACT_APP_API_URL

    const [countData, setCountData] = useState<any>()
    const [certificateDataList, setCertificateDataList] = useState<any[]>([])
    console.log(countData, "countData");
    const [programmes, setProgrammes] = useState<ProgrammeData[]>([])
    const [totalActive, setTotalActive] = useState<any>(0);
    const [totalSuspended, setTotalSuspended] = useState<any>(0);
    const [totalWithdrawn, setTotalWithdrawn] = useState<any>(0);
    const [reportData, setReportData] = useState<any>();
    console.log(totalActive, "totalActive");
    const fetchCountData = async () => {

        try {
            const response = await axios.get(`${BASE_URL}/certificate/total/count/data/?cab=${userId}`)
            setCountData(response?.data)

        } catch (error) {
            console.error('Error fetching cluster data:', error)
        }
    }
    useEffect(() => {
        fetchCountData()
    }, [])
    // const activeUsers = countData?.filter(item => item.status === "Active");
    // const withdrawnUsers = countData?.filter(item => item.status === "Withdrawn");
    // const suspendedUsers = countData?.filter(item => item.status === "Suspended");
    // const deletedUsers = countData?.filter(item => item.status === "Deleted");
    // const reaccreditationUsers = countData?.filter(item => item.status === "Reaccreditation");
    // console.log(activeUsers, "activeUsers");



    useEffect(() => {
        const fetchSingleCertificates = async () => {
            try {
                const response = await axios.get<{ certificates: any[] }>(
                    `${BASE_URL}/certificate/programme/list/?cab=${userId}`
                );
                const data = response.data.certificates;
                setCertificateDataList(data);
            } catch (error) {
                console.error("Error fetching certificates: ", error);
            }

        }
        fetchSingleCertificates();
    }, []);
    const fetchCertificateData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/certificate/programme/list/`
            )
            const responses = await axios.get(`${BASE_URL}/global/programme/list/`)
            const programData = responses.data;
            setProgrammes(responses?.data)
            const data = response.data?.certificates;
            let mergedData: any = [];
            programData.forEach(program => {
                const certificateItem = data.find(item => item.programme === program.id) || {
                    programme: program.id,
                    programme__name: program.name,
                    active: 0,
                    suspended: 0,
                    withdrawn: 0
                };
                mergedData.push(certificateItem);
            });
            setCertificateDataList(mergedData);
            // Calculate totals
            let activeSum = 0;
            let suspendedSum = 0;
            let withdrawnSum = 0;
            mergedData.forEach(item => {
                activeSum += item.active;
                suspendedSum += item.suspended;
                withdrawnSum += item.withdrawn;
            });
            setTotalActive(activeSum);
            setTotalSuspended(suspendedSum);
            setTotalWithdrawn(withdrawnSum);
            setReportData([]);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCertificateData();
    }, [])
    return (
        <div id="cab_dashboard">
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.total_count}
                                    </span>
                                </div>
                                <span className='pt-3 fw-semibold fs-1hx'>Total</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.active_count}
                                    </span>
                                </div>
                                <span className='pt-3 fw-semibold fs-1hx'>Active</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                         {countData?.suspended_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Suspended</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-2 mb-xl-2'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.withdrawn_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Withdrawn</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.hold_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Re-Accreditation</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-5 mb-xl-5'>
                    <div className='card card-flush mb-5 mb-xl-5'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.country_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Countries</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row gy-5 gx-xl-8'>
                <div className='col-xxl-6 col-lg-6'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>CERTIFICATE LIST</span>
                            </h3>
                        </div>

                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>PROGRAM</th>
                                            <th className='min-w-140px'>ACTIVE</th>
                                            <th className='min-w-120px'>SUSPENDED</th>
                                            <th className='min-w-100px '>WITHDRAWN</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-gray-600 fw-semibold'>
                                        {certificateDataList?.map((item) => (
                                            <>
                                                {item?.programme__name ? (
                                                    <tr key={item.id}>
                                                        <td className='min-w-125px ps-4 text-dark fw-bold text-hover-primary  fs-6'>{item?.programme__name}</td>
                                                        <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.active}</td>
                                                        <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.suspended}</td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{item?.withdrawn}</td>

                                                        {/* <td>{getStatusCounts(item.id, 'Active')}</td>
                                        <td>{getStatusCounts(item.id, 'Suspended')}</td>
                                        <td>{getStatusCounts(item.id, 'Withdrawn')}</td> */}
                                                    </tr>
                                                ) : null}
                                            </>
                                        ))}
                                        <tr className='fw-bold'>
                                            <td className='min-w-125px ps-4'>Total</td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>{totalActive}</td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>{totalSuspended}</td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>{totalWithdrawn}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* end::Table container */}
                        </div>
                        {/* begin::Body */}
                    </div>
                </div>
                <div className='col-xxl-6 col-lg-6'>
                    <CertificateGraph selectedCAB={userId} />
                </div>
            </div>
        </div>
    )
}

export default SingleCabDash