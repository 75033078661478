import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'

import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import { json } from 'stream/consumers'

const API_URL = process.env.REACT_APP_API_URL

interface CabInterface {
  id: string
  assessment_number: string
  date_of_ncr: string
  ncr_number: string
  uaf_requirements: string
  non_conformity_observed: string

  scheme_text:string
  standard_list: {name: string; id: string}[]
  standard_clause: string
  category_name: string
  team_leader_list: {first_name: string; id: string; last_name: string}[]
  team_member_list: {first_name: string; id: string; last_name: string}[]
  team_leader:string[]
  team_member:string[]
  registrar_name: string
  registrar_company:string
  cab_representative: string
  evidence_reference: string
  cab_representative_email: string[]
  action_status: string
  remarks: string
  evidence_document: string
  email: string[]
}

interface FormState {
  assessment_number: string
  registrar_name: string
  ncr_number: string
  date_of_ncr: string
  registrar_company:string
  uaf_requirements: string
  non_conformity_observed: string

  scheme_text:string
  category_name: string
  standard_clause: string
  team_leader_list: {first_name: string; id: string; last_name: string}[]
  team_member_list: {first_name: string; id: string; last_name: string}[]
  team_leader:string[]
  team_member:string[]
  nc_id: string

  email: string[]
}
interface CategoryItem {
  id: string
  name: string
}
interface StatusItem {
  id: string
  name: string
}

const NCEdit = () => {
  const {token, id: nc_id, userId} = useParams<{token?: string; id?: string; userId?: string}>()
  // console.log('formUrl:', {token, id: nc_id, userId})

  const [value, setValue] = useState<FormState>({
    assessment_number: '',
    registrar_name: '',
    ncr_number: '',
    date_of_ncr: '',
    nc_id: '',
    uaf_requirements: '',
    non_conformity_observed: '',
    registrar_company:'',

    scheme_text:'',
    category_name: '',
    standard_clause: '',
    team_leader_list: [],
    team_member_list: [],
    team_member:[],
    team_leader:[],
    email: [],
  })

  const [cabData, setCabData] = useState<CabInterface | null>(null)
  const [ncrDate, setNcrDate] = useState<Date | null>(null)
  const [categoryData, setCategoryData] = useState<CategoryItem[]>([])
  const [statusData, setStatusData] = useState<StatusItem[]>([])
  const [selectedLeaders, setSelectedLeaders] = useState<any[]>([])
  const [selectedMembers, setSelectedMembers] = useState<any[]>([])
  const [assessorList,setassessorList]= useState<any[]>([])


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValue(prevValue => ({
      ...prevValue,
      [name]: value,
    }));
  };


  const handleNcrDateChange = (date: Date | null) => {
    setNcrDate(date)
    setValue((prevValue) => ({
      ...prevValue,
      date_of_ncr: date ? moment(date).format('MM-DD-YYYY') : '',
    }))
  }

  const getCABNC = async () => {
    try {
      const response = await axios.get<CabInterface>(`${API_URL}/nc/management/${nc_id}/patch/`)
      setCabData(response.data)

    } catch (error) {
      console.error(error)
    }
  }

  const fetchAssessor = async () => {
    try {
      const response = await axios.get<any[]>(`${API_URL}/team/accessor/verified/list/`)
      const simplifiedAssessors = response.data.map((assessor: any) => ({
        id: assessor.id,
        first_name: assessor.first_name,
        last_name: assessor.last_name,
    }));
    setassessorList(simplifiedAssessors);
    } catch (error) {}
  }


  useEffect(() => {
    getCABNC()
    fetchCategory()
    fetchStatus()
    fetchAssessor()
  }, [nc_id])

  useEffect(() => {
    if (cabData) {
      setValue((prevState) => ({
        ...prevState,
        assessment_number: cabData?.assessment_number || '',
        registrar_name: cabData?.registrar_name || '',
        ncr_number: cabData?.ncr_number || '',
        registrar_company:cabData?.registrar_company|| '',
        nc_id: cabData?.id || '',
        email: cabData?.email || [],
        date_of_ncr: cabData?.date_of_ncr || '',
        uaf_requirements: cabData?.uaf_requirements || '',
        non_conformity_observed: cabData?.non_conformity_observed || '',
        scheme_text: cabData?.scheme_text || '',
        category_name: cabData?.category_name || '',
        standard_clause: cabData?.standard_clause || '',
        // team_leader_list: cabData?.team_leader_list || [],

        // team_member_list: cabData?.team_member_list || [],

      }))
      if (cabData?.date_of_ncr) {
        setNcrDate(new Date(cabData?.date_of_ncr))
      }
      if (cabData?.team_leader_list) {
        setSelectedLeaders(cabData?.team_leader)
      }
      if (cabData?.team_member_list) {
        setSelectedMembers(cabData?.team_member)
      }
    }
  }, [cabData])

  const fetchCategory = async () => {
    try {
      const response = await axios.get<CategoryItem[]>(`${API_URL}/nc/category/list/`)
      setCategoryData(response.data)
    } catch (error) {}
  }
  const fetchStatus = async () => {
    try {
      const response = await axios.get<StatusItem[]>(`${API_URL}/nc/status/list/`)
      setStatusData(response.data)
    } catch (error) {}
  }

  const handleleaderSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value
    if (selectedId && !selectedLeaders?.includes(selectedId)) {
      setSelectedLeaders([...selectedLeaders, selectedId])
    }
  }

  const handleMemberSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value
    if (selectedId && !selectedMembers?.includes(selectedId)) {
      setSelectedMembers([...selectedMembers, selectedId])
    }
  }

  const handleRemoveLeader = (leaderId: string) => {
    setSelectedLeaders(selectedLeaders?.filter((id) => id !== leaderId))
  }

  const handleRemoveMember = (memberId: string) => {
    setSelectedMembers(selectedMembers?.filter((id) => id !== memberId))
  }








  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    console.log(value, 'Printed Value.......!')

    try {
      // const formData = new FormData()
      // formData.append('ncr_number', value.ncr_number)
      // formData.append('date_of_ncr', value.date_of_ncr)
      // formData.append('uaf_requirements', value.uaf_requirements)
      // formData.append('non_conformity_observed', value.non_conformity_observed)
      // formData.append('scheme_text',value.scheme_text)
      // formData.append('team_leader',JSON.stringify(team_leader))
      // formData.append('team_member', JSON.stringify(selectedMembers))
      // const category = categoryData.find(item => item.name === value.category_name);
      // if (category) {
      //   formData.append('category', category.id);
      // }
      // formData.append('standard_clause', value.standard_clause);
      const category = categoryData.find(item => item.name === value.category_name);
      const requestData = {
        ncr_number: value.ncr_number || '',
        date_of_ncr: value.date_of_ncr || '',
        uaf_requirements: value.uaf_requirements|| '',
        non_conformity_observed: value.non_conformity_observed || "",
        team_leader: selectedLeaders || [],
        team_member: selectedMembers || [],
        scheme_text: value.scheme_text || "",
        standard_clause: value.standard_clause || "",
        category: category?.id ?? "",
      };

      await axios.put(`${API_URL}/nc/management/${nc_id}/update/data/`, requestData)

      toast.success('Application Submitted successfully.')
    } catch (error) {
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <div id="kr_lo_edit">
      <h2>Edit Nonconfirmity</h2>

      <div className='py-lg-10 px-lg-10'>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            <form onSubmit={handleSubmit} id="kr_lo_form">
              <div className='row'>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Assessment</label>
                  <input
                    value={value?.assessment_number}
                    className='form-control form-control-solid'
                    type='text'
                    readOnly
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>CAB</label>
                  <input
                    value={value?.registrar_company}
                    className='form-control form-control-solid'
                    type='text'
                    readOnly
                  />
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>NCR Number</label>
                  <input
                    name='ncr_number'
                    value={value.ncr_number}
                    className='form-control form-control-solid'
                    type='text'
                    onChange={handleChange}
                    id="kr_lo_form_2"
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Date of NCR</label>
                  <br />
                  <ReactDatePicker
                   id="kr_lo_form_3"
                    selected={ncrDate}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={handleNcrDateChange}
                    dropdownMode='select'
                  />
                </div>
                <div className='mb-5'>
                  <label className='required form-label'>Standard/UAF Requirements</label>
                  <textarea
                   id="kr_lo_form_4"
                    name='uaf_requirements'
                    className='form-control form-control-solid'
                    rows={5}
                    value={value.uaf_requirements}
                    onChange={handleChange}
                  />
                </div>
                <div className='mb-5'>
                  <label className='required form-label'>Non-Conformity Observed</label>
                  <textarea
                   id="kr_lo_form_5"
                    name='non_conformity_observed'
                    className='form-control form-control-solid'
                    rows={5}
                    value={value.non_conformity_observed}
                    onChange={handleChange}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Scheme</label>
                  <input
                   id="kr_lo_form_6"
                    type='text'
                    className='form-control form-control-solid'
                    name='scheme_text'
                    value={value?.scheme_text}
                    onChange={handleChange}

                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Category</label>
                  <select
                   id="kr_lo_form_7"
                    name='category_name'
                    value={value.category_name}
                    className='form-control form-control-solid'
                    onChange={handleChange}
                  >
                    <option value=''>Select Category</option>
                    {categoryData.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Standard / Clause No.</label>
                  <input
                   id="kr_lo_form_8"
                    type='text'
                    className='form-control form-control-solid'
                    name='standard_clause'
                    value={value.standard_clause}
                    onChange={handleChange}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Team Leader, UAF</label>
                  <select
                   id="kr_lo_form_12"
                    className='form-control form-control-solid'
                    onChange={handleleaderSelectChange}
                    value=''
                  >
                    <option value=''></option>
                    {assessorList
                      .filter((item) => !selectedLeaders?.includes(item.id))
                      .map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.first_name + ' ' + item?.last_name}
                        </option>
                      ))}
                  </select>
                  <div
                    className='selected-standards mt-3'
                    style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px'}}
                  >
                    {selectedLeaders?.map((leaderId) => {
                      const leader = assessorList?.find((item) => item.id === leaderId)
                      return (
                        <span
                          key={leaderId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            padding: '5px 10px',
                          }}
                        >
                          {leader?.first_name + ' ' + leader?.last_name}
                          <span
                            style={{
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                            onClick={() => handleRemoveLeader(leaderId)}
                          >
                            &times;
                          </span>
                        </span>
                      )
                    })}
                  </div>
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Team Member, UAF</label>
                  <select
                    className='form-control form-control-solid'
                     id="kr_lo_form_22"
                    onChange={handleMemberSelectChange}
                    value=''
                  >
                    <option value=''></option>
                    {assessorList
                      .filter((item) => !selectedMembers?.includes(item.id))
                      .map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.first_name + ' ' + item?.last_name}
                        </option>
                      ))}
                  </select>
                  <div
                    className='selected-standards mt-3'
                    style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px'}}
                  >
                    {selectedMembers?.map((memberId) => {
                      const member = assessorList?.find((item) => item.id === memberId)
                      return (
                        <span
                          key={memberId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '15px',
                            padding: '5px 10px',
                          }}
                        >
                          {member?.first_name + ' ' + member?.last_name}
                          <span
                            style={{
                              marginLeft: '10px',
                              cursor: 'pointer',
                              color: 'red',
                              fontWeight: 'bold',
                            }}
                            onClick={() => handleRemoveMember(memberId)}
                          >
                            &times;
                          </span>
                        </span>
                      )
                    })}
                  </div>
                </div>

                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>CAB Representative</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    name='cab_representative'
                    value={cabData?.cab_representative}
                    readOnly
                  />
                </div>

                <div className='mb-5'>
                  <button type='submit'  id="kr_lo_form_29" className='btn btn-primary'>
                    Update NCR
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NCEdit
