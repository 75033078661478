/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import axios from 'axios'
import uaflogo from '../../../../../public/media/logos/uaf_logo.png'
// import { fetchAndUpdatePermissions } from '../../../../app/permission_component/GetPermissions'
// import { getPermissions } from '../../../../app/permission_component/GetPermissions'
interface UserData {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: number
  company: string
  company_web: string
  subject: string
  message: string
  last_login: string
  is_cab_client: boolean
  cab_profile: string
  address: string
  country: string
  language: string
  currency: string
  img: string
  country_name: string
}
interface RequiredData {
  is_active: boolean
  is_cab_user: boolean
  is_cab_active: boolean
  is_cab_visible: boolean
  is_cab_suspended: boolean
  is_cab_withdraw: boolean
  is_cab_deleted: boolean
  is_cab_hold: boolean
  is_cab_team: boolean
  is_client: boolean
  is_historical_cab: boolean
  is_cab_client: boolean
  is_cab_verify: boolean
  is_cab_rejected: boolean
  is_auditor: boolean
  is_verify_auditor: boolean
  is_document_review_pending: boolean
  is_document_pending: boolean
  pending_management_review: boolean
  is_potential_assessor: boolean
  is_potential_assessor_reject: boolean
  is_accessor: boolean
  is_verify_accessor: boolean
  is_witness: boolean
  is_verify_witness: boolean
  is_office_assessment: boolean
  is_verify_office_assessment: boolean
  is_document_reviewer: boolean
  is_verify_document_reviewer: boolean
  is_credential: boolean
  is_executive: boolean
  is_sr_executive: boolean
  is_manager: boolean
  is_sr_manager: boolean
  is_director: boolean
  is_assessors: boolean
  is_technical_expert: boolean
  is_ARC_members: boolean
  is_lead_assessor: boolean
  is_account: boolean
}

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const API_URL = process.env.REACT_APP_API_URL
  const user_id = localStorage.getItem('current_user_id')
  const {auth} = useAuth()
  const [userData, setUserData] = useState<UserData>()
  console.log(currentUser, auth, 'auth')
  // const [permissionData, setPermissionData] = useState<RequiredData | null>(null);
  // console.log(permissionData, "permissionData");
  const getUserDetails = () => {
    axios
      .get(`${API_URL}/account/patch/user/${user_id}/`)
      .then((response) => {
        const result = response.data
        setUserData(result)
      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  // const extractRequiredData = (data: any): RequiredData => {
  //   return {
  //     is_active: data?.is_active,
  //     is_cab_user: data?.is_cab_user,
  //     is_cab_active: data?.is_cab_active,
  //     is_cab_visible: data?.is_cab_visible,
  //     is_cab_suspended: data?.is_cab_suspended,
  //     is_cab_withdraw: data?.is_cab_withdraw,
  //     is_cab_deleted: data?.is_cab_deleted,
  //     is_cab_hold: data?.is_cab_hold,
  //     is_cab_team: data?.is_cab_team,
  //     is_client: data?.is_client,
  //     is_historical_cab: data?.is_historical_cab,
  //     is_cab_client: data?.is_cab_client,
  //     is_cab_verify: data?.is_cab_verify,
  //     is_cab_rejected: data?.is_cab_rejected,
  //     is_auditor: data?.is_auditor,
  //     is_verify_auditor: data?.is_verify_auditor,
  //     is_document_review_pending: data?.is_document_review_pending,
  //     is_document_pending: data?.is_document_pending,
  //     pending_management_review: data?.pending_management_review,
  //     is_potential_assessor: data?.is_potential_assessor,
  //     is_potential_assessor_reject: data?.is_potential_assessor_reject,
  //     is_accessor: data?.is_accessor,
  //     is_verify_accessor: data?.is_verify_accessor,
  //     is_witness: data?.is_witness,
  //     is_verify_witness: data?.is_verify_witness,
  //     is_office_assessment: data?.is_office_assessment,
  //     is_verify_office_assessment: data?.is_verify_office_assessment,
  //     is_document_reviewer: data?.is_document_reviewer,
  //     is_verify_document_reviewer: data?.is_verify_document_reviewer,
  //     is_credential: data?.is_credential,
  //     is_executive: data?.is_executive,
  //     is_sr_executive: data?.is_sr_executive,
  //     is_manager: data?.is_manager,
  //     is_sr_manager: data?.is_sr_manager,
  //     is_director: data?.is_director,
  //     is_assessors: data?.is_assessors,
  //     is_technical_expert: data?.is_technical_expert,
  //     is_ARC_members: data?.is_ARC_members,
  //     is_lead_assessor: data?.is_lead_assessor,
  //     is_account: data?.is_account,
  //   };
  // };
  // useEffect(() => {
  //   const requiredData = extractRequiredData(userData);
  //   setPermissionData(requiredData);
  // }, [userData])
  // useEffect(() => {
  //   if (permissionData) {
  //     const truePermissions = Object.entries(permissionData)
  //       .filter(([key, value]) => value === true)
  //       .map(([key, value]) => ({ [key]: value }));
  //     const truePermissionsObject = Object.assign({}, ...truePermissions);
  //     localStorage.setItem('truePermissions', JSON.stringify(truePermissionsObject));

  //     console.log('Stored true permissions in localStorage:', truePermissionsObject);
  //   }
  // }, [permissionData]);
  // useEffect(() => {
  //   if (permissionData) {
  //     const truePermissionsObject = Object.keys(permissionData)
  //       .filter(key => permissionData[key] === true)
  //       .reduce((acc, key) => {
  //         acc[key] = true;
  //         return acc;
  //       }, {});

  //     localStorage.setItem('truePermissions', JSON.stringify(truePermissionsObject));

  //     console.log('Stored true permissions in localStorage:', truePermissionsObject);
  //   }
  // }, [permissionData]);
  // useEffect(() => {
  //   if (permissionData) {
  //     try {
  //       Object.keys(permissionData).forEach(key => {
  //         if (permissionData[key] === true) {
  //           localStorage.setItem(key, JSON.stringify(permissionData[key]));
  //         }
  //       });
  //     } catch (error) {
  //       console.error('Error saving permission data to localStorage:', error);
  //     }
  //   }
  // }, [permissionData]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const result = await fetchAndUpdatePermissions(user_id);
  //       console.log(result,"DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
  //     } catch (error) {
  //       // Handle errors
  //       console.error('Error:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className=' me-5'>
            <img
              style={{width: '120px', height: '60px'}}
              alt='Logo'
              src='https://uafaccreditation.s3.amazonaws.com/logo-main.png'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userData?.first_name} {userData?.last_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            {/* <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {userData?.email}
            </a> */}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {/*
      <div className='menu-item px-5'>
        <Link to={`/crafted/account/overview/`} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
      <Link to={`/crafted/account/documents/`} className='menu-link px-5'>
          <span className='menu-text'>My Documents</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </Link>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      {/* <div className='separator my-2'></div> */}

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
