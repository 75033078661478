import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UAFLoader from '../../loader/UafLoader'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
interface FormState {
    title: string;
    short_desc: string;
    desc: string;
    image: File | null;
    imageUrl: string | null;
}
const AddBlog = () => {
    const navigate = useNavigate();
    const param = useParams();
    const blogId = param?.id;
    const API_URL = process.env.REACT_APP_API_URL
    const [loader, setLoader] = useState<any>(false);
    const [blogData, setBlogData] = useState<FormState | null>(null);
    const [imageURL, setImageURL] = useState<string | null>(null);


    const [value, setValue] = useState<FormState>({
        title: '',
        short_desc: '',
        desc: '',
        image: null,
        imageUrl: ""



    })
    const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        setValue((prevValue) => ({
            ...prevValue,
            image: file,
        }));
        if (file) {
            setImageURL(URL.createObjectURL(file));
        } else {
            setImageURL(null);
        }
    };

    const Validation = () => {
        if (!value?.title || value?.title?.trim() === "") {
            toast.error("Please Enter Title")
            return false;
        }
        else if (!value?.short_desc || value?.short_desc?.trim() === "") {
            toast.error("Please Enter Short Desc")
            return false;
        }
        else if (!value.desc || value?.desc?.trim() === "") {
            toast.error("Please Enter Description")
            return false;
        } else if (!value.image) {
            toast.error("Upload Image")
            return false;
        }

        return true;

    }

    useEffect(() => {
        if (blogData) {
            setValue({
                title: blogData?.title || '',
                short_desc: blogData.short_desc || '',
                desc: blogData.desc || '',
                image: blogData.image || null,
                imageUrl: blogData.imageUrl || null,

            })
        }
    }, [blogData])
    const fetchBlogDAta = async () => {
        try {
            const response = await axios.get(`${API_URL}/blog/patch/${blogId}`)
            setBlogData(response.data)
            setBlogData({
                ...response.data,
                imageUrl: response.data.image || null,

            });
        } catch (error) {
            // console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        if (blogId) {
            fetchBlogDAta()
        } else {
            setValue({
                title: '',
                short_desc: '',
                desc: '',
                image: null,
                imageUrl: null,

            })
        }
    }, [blogId])
    const handelSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const { title, short_desc, desc, image } = value;
        if (!title || !short_desc || !desc || !image) {
            toast.error('Please fill in all mandatory fields.');
            return;
        }

        try {
            setLoader(true);
            const formData = new FormData();
            formData.append('title', title);
            formData.append('short_desc', short_desc);
            formData.append('desc', desc);
            if (image && image instanceof File) {
                formData.append('image', image);
            }

            let response;
            if (blogId) {
                // Update API request (PUT/PATCH)
                response = await axios.put(`${API_URL}/blog/update/${blogId}/`, formData);
            } else {
                // Create API request (POST)
                response = await axios.post(`${API_URL}/blog/create/`, formData);
            }

            if (response.status === 201 || response.status === 200) {
                toast.success('Blog submitted successfully');
                setLoader(false);
                navigate(`/crafted/bloglist`);
                setValue({
                    title: '',
                    short_desc: '',
                    desc: '',
                    image: null,
                    imageUrl: null,

                })
            } else {
                setLoader(false);
                toast.error('Failed to submit blog. Please try again.');
            }
        } catch (error: any) {
            setLoader(false);
            console.error(error);
            toast.error('Something went wrong, please try again');
        }
    };

    const handleEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setValue((prevValue) => ({
            ...prevValue,
            desc: data,
        }));
    }
    return (

        <div id="v_blog_01">
            {loader ? <UAFLoader /> : null}

            <div className='row jusify-content-space-between'>
                <div className='col-md-6 d-flex align-items-center justify-content-start'>

                    <h2 className='mb-3 p-0 '>{blogId ? "Edit Blog" : "Add Blog"}

                    </h2>
                </div>
            </div>



            <div className='card card-flush'>

                <div className='card-body pt-5'>

                    <form

                        onSubmit={handelSubmit}
                    >
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Title</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-solid'
                                            placeholder='Title'
                                            name="title"
                                            value={value?.title}
                                            onChange={(e) => setValue({ ...value, title: e.target.value })}
                                        />
                                    </div>

                                </div>
                                <div className=" fv-row">
                                    <div className='mb-5'>
                                        <label className='required form-label'>Short Description</label>
                                        <textarea
                                            rows={10}
                                            className='form-control form-control-solid'
                                            placeholder='Short Description'
                                            name="short_desc"
                                            value={value?.short_desc}
                                            onChange={(e) => setValue({ ...value, short_desc: e.target.value })}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-6'>
                                {imageURL ? <img style={{ width: "750px", height: "290px" }} src={imageURL} alt="blog" /> :
                                    blogId ?
                                        <img style={{ width: "750px", height: "290px" }} src={blogData?.imageUrl || ''} alt="blog" /> : null
                                }
                                <div className='mt-5'>
                                    <label
                                        htmlFor='image'
                                        className={value.image ? ' required file-uploaded-uploaded' : ' required file-upload-upload'}
                                        style={{ width: '200px' }}
                                    >
                                        {value.image ? 'Image Uploaded' : 'Upload Image'}
                                    </label>
                                    <input type='file' id='image' style={{ display: 'none' }} onChange={handleImage} />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className=" fv-row">
                                <div className='mb-5'>
                                    <label className='required form-label'> Description</label>
                                    {/* <textarea
                                        rows={10}
                                        className='form-control form-control-solid'
                                        placeholder=' Description'
                                        name="desc"
                                        value={value?.desc}
                                        onChange={(e) => setValue({ ...value, desc: e.target.value })}
                                    /> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={value.desc}
                                        onChange={handleEditorChange}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='mb-5'>

                            <button type='submit' className='btn btn-primary'>
                                {blogId ? "Update" : "Submit"}

                                <i className='fa fa-angle-double-right' aria-hidden='true' />

                            </button>
                        </div>
                    </form>
                </div>
            </div >

        </div>
    )
}

export default AddBlog