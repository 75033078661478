import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import AccreditationScheduleSignature from './AccreditationScheduleSignature'

const API_URL = process.env.REACT_APP_API_URL

const AccreditationSchedule = () => {
  const {id} = useParams()
  const userId = id
  const [signatureImageData, setSignatureImageData] = useState<any>()
  const [cabData, setCabData] = useState<any>()
  console.log(cabData, 'cabData')
  const [standardData, setStandardData] = useState<any[]>([])

  console.log(standardData, 'standardData')

  const [cabARCData, setCAbARCData] = useState<any>()
  console.log(cabARCData, 'cabARCData')
  const [cabLocationData, setCAbLocationData] = useState<any[]>([])
  const [cabARCDataCountry, setCAbARCDataCountry] = useState<any[]>([])
  const [schemeClusters, setSchemeClusters] = useState<any>([])
  console.log('schemeClusters:', schemeClusters)
  const [singleCab, setSingleCab] = useState<any>([])
  console.log('singleCab:', singleCab)
  const [value, setValue] = useState({
    cab: '',
    cab_company: '',
    registration_number: '',
    main_key_location: '',
    issue_number: '',
    issue_date: '',
    other_key_location: '',
    other_key_location_reg: '',
    approved_countries: {},
    other_location: '',
    accreditation_valid_date: '',
    accreditation_valid_upto_date: '',
    remark: '',
    scheme_data: {},
    member_signature: '',
    member_name: '',
    signature_date: '',
  })

  const navigate = useNavigate()

  const handleChange = (event, schemeName) => {
    const {value: inputValue} = event.target
    setValue((prevState) => ({
      ...prevState,
      scheme_data: {
        ...prevState.scheme_data,
        [schemeName]: inputValue,
      },
    }))
  }

  const handleSignatureSave = (dataUrl: string) => {
    setSignatureImageData(dataUrl)
    setValue((prevState) => ({
      ...prevState,
      member_signature: dataUrl,
    }))
  }
  useEffect(() => {
    if (cabData) {
      const getCABUserDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/account/patch/user/${cabData?.id}/`)
          setSingleCab(response.data)
        } catch (error) {
          console.error('API Error:', error)
          // throw error;
        }
      }
      getCABUserDetails()
    }
  }, [cabData])
  useEffect(() => {
    if (cabData) {
      setValue((prevValue) => ({
        ...prevValue,
        cab: cabData?.id,
        cab_company: cabData?.company,
        registration_number: cabData?.license_number,
        main_key_location: cabLocationData[0]?.id,
        // issue_number: "",
        // issue_date: "",
        other_key_location: cabARCData?.recommended_location,
        // other_key_location_reg: "",
        approved_countries: cabARCDataCountry?.map((i) => i?.id),
        other_location: cabARCData?.other_location,
        // accreditation_valid_date: "",
        // accreditation_valid_upto_date: "",
        // remark: "",

        member_signature: signatureImageData,
      }))
    }
  }, [cabData, cabARCData, cabARCDataCountry, signatureImageData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/arc/management/schedule/data/get/?cab=${id}`)
        setCabData(response?.data?.cab_data)
        setCAbARCData(response?.data?.arc_data[0])
        setCAbARCDataCountry(response?.data?.arc_data[0]?.country_data)
        setCAbLocationData(response?.data?.key_location)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    const fetchStandardData = async () => {
      try {
        const responses = await Promise.all(
          cabData?.scheme_names.map((scheme) =>
            axios.get(`${API_URL}/global/standard/filter/?scheme=${scheme?.id}`)
          )
        )
        const data = responses.map((response) => response.data)
        setStandardData(data)
      } catch (error) {
        console.error('Error fetching assessment data:', error)
      }
    }

    fetchStandardData()
  }, [cabData])

  useEffect(() => {
    const getSchemeClusterName = async () => {
      try {
        const response = await axios.get(`${API_URL}/account/cab/scheme/filter/?id=${userId}`)
        setSchemeClusters(response.data[0]?.scheme_with_cluster)
      } catch (error) {
        console.error('API Error:', error)
        // throw error;
      }
    }
    getSchemeClusterName()
  }, [])
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    console.log(value, 'Printed Value.......!')
    const {issue_number, issue_date, accreditation_valid_date, accreditation_valid_upto_date} =
      value
    if (
      !issue_number ||
      !issue_date ||
      !accreditation_valid_date ||
      !accreditation_valid_upto_date
    ) {
      toast.error(' Please fill in Required the details.')
      return
    }

    try {
      const response = await axios.post(`${API_URL}/arc/schedule/data/create/`, value)
      if (response.status === 200 || response.status === 201) {
        toast.success('Schedule Created Successfully.')
        navigate(`/crafted/pages/profile/${id}/schedule`)
      } else {
        toast.error('Failed to submit application. Please try again.')
      }
    } catch (error) {
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <div>
      <h2>Accreditation Schedule</h2>
      <form onSubmit={handleSubmit}>
        <div className='py-lg-10 px-lg-10'>
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='row'>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Accredited to ISO/IEC 17021-1:2015</label>
                  <div className='row'>
                    {cabData?.scheme_names.map((scheme) => (
                      <div key={scheme?.id} className='mb-5'>
                        <label>{scheme?.short_name}</label>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='Please Enter A Unique Issue Number'
                          value={value?.scheme_data[scheme?.short_name] || ''} // Display the stored value
                          onChange={(e) => handleChange(e, scheme?.short_name)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>CAB Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Issue Number '
                    name='cab'
                    onChange={(e) => setValue({...value, cab_company: e?.target?.value})}
                    value={value?.cab_company}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Issue No</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Issue Number '
                    name='ncr_number'
                    onChange={(e) => setValue({...value, issue_number: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Issue Date</label>
                  <br />
                  <ReactDatePicker
                    selected={value?.issue_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, issue_date: date})}
                    dropdownMode='select'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Main Key Location Address and Contact Details
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Please Enter A Unique Standard/ClauseNo'
                    value={cabData?.address}
                    name='standard_clause'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Legal Registration details</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    name='cab_representative'
                    value={value?.registration_number}
                    onChange={(e) => setValue({...value, registration_number: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Other Key Location(s)</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_key_location}
                    onChange={(e) => setValue({...value, other_key_location: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Legal Registration Details of Other Key Location
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_key_location_reg}
                    onChange={(e) => setValue({...value, other_key_location_reg: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>Other Location(s)</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    value={value?.other_location}
                    onChange={(e) => setValue({...value, other_location: e.target.value})}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className=' form-label'>
                    Countries where UAF accredited certificates can be Issued (Subject to Compliance
                    with UAF Policy For CAB Operations in Sanctioned Countries)
                  </label>
                  <Select
                    isMulti
                    className='react-select-styled react-select-solid '
                    name='User'
                    classNamePrefix='react-select'
                    isDisabled
                    value={cabARCDataCountry?.map((i) => ({label: i?.name, value: i?.id}))}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Accreditation Valid Date</label>
                  <br />
                  <ReactDatePicker
                    selected={value?.accreditation_valid_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, accreditation_valid_date: date})}
                    dropdownMode='select'
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Accreditation Valid up to </label>
                  <br />
                  <ReactDatePicker
                    selected={value?.accreditation_valid_upto_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, accreditation_valid_upto_date: date})}
                    dropdownMode='select'
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <div className='mb-5'>
                  <label className=' form-label'>Remarks</label>
                  <textarea
                    className='form-control form-control-solid'
                    rows={12}
                    placeholder='Enter Remarks'
                    value={value?.remark}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        remark: e.target.value,
                      })
                    }
                  />
                </div>

                <div className='mb-5 mt-5 text-center'>
                  <h2 className=''>Certification Standards: </h2>
                  <ul style={{listStyleType: 'none'}}>
                    {standardData?.map((standard) => (
                      <li className=' form-label'>
                        {standard[0]?.name}--{standard[0]?.scheme_name}--Requirements
                      </li>
                    ))}
                  </ul>
                </div>

                <div className='mb-10 mt-10 text-center'>
                  <h2 className='mb-5'>SCOPE OF ACCREDITATION: </h2>

                  {schemeClusters?.map((scheme) => (
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5 px-5'
                      id='kt_table_users'
                      style={{border: '1px solid #d6d6d6'}}
                    >
                      <thead>
                        <tr className='text-center  fw-bold  text-uppercase gs-0'>
                          <th className=' pe-2 fs-7' style={{background: '#f2f2f2'}}>
                            {' '}
                            {scheme?.scheme_name}
                          </th>
                        </tr>
                      </thead>

                      <tbody className='text-gray-600 fw-semibold '>
                        {scheme?.clusters?.map((item) => (
                          <tr>
                            <td>
                              <div className='text-gray-800 mb-1'>{item?.name}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Member Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Member Name '
                    name='member_name'
                    onChange={(e) => setValue({...value, member_name: e?.target?.value})}
                    value={value?.member_name}
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='required form-label'>Date </label>
                  <br />
                  <ReactDatePicker
                    selected={value?.signature_date}
                    placeholderText='Select Date'
                    className='react_date_picker form-control form-control-solid'
                    onChange={(date) => setValue({...value, signature_date: date})}
                    dropdownMode='select'
                  />
                </div>
                <div className='mb-5 col-lg-6'>
                  <label className='col-lg-4 fw-bold text-muted'>Signature</label>
                  <AccreditationScheduleSignature onSave={handleSignatureSave} />
                </div>
              </div>
            </div>
            <div className='mb-5 px-10'>
              <button type='submit' className='btn btn-primary'>
                Save Accreditation Schedule
                <i className='fa fa-angle-double-right' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default AccreditationSchedule
