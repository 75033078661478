import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import ReactDatePicker from 'react-datepicker';
import axios from "axios";
import CheckboxHierarchy from "../../../../modules/apps/cab-user/Approved-cab-user/components/modal/Component/CheckboxHierarchy";
import HistoricalCheckboxHierarchy from "../components/HistoricalCheckboxHierarchy";
const API_URL = process.env.REACT_APP_API_URL
const CreateHistoricalAssessmentModal = ({ show, handleClose }) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
    const [selectedSchemes, setSelectedSchemes] = useState<{ [key: string]: string[] }>({});
    const [selectedStandards, setSelectedStandards] = useState<{ [key: string]: { [key: string]: string[] } }>({});
    console.log(selectedStandards, 'selectedStandards')
    const [selectedSchemesList, setSelectedSchemesList] = useState<string[]>([]);
    const [selectedStandardList, setSelectedStandardList] = useState<string[]>([]);
    const [schemesList, setSchemesList] = useState();
    const [schemeData, setSchemeData] = useState([])
    const [clustersList, setClustersList] = useState();
    const [assessorsList, setAssessorsList] = useState<any>([]);
    const [assessorModal, setAssessorModal] = useState<boolean>(false)
    const [checkedIds, setCheckedIds] = useState<string[]>([]);
    const [selectedAssessors, setSelectedAssessors] = useState<any>([])
    console.log(selectedAssessors, selectedAssessors);
    const [historicalAssessmentDetail, setHistoricalAssessmentDetail] = useState({
        cab_name: "",
        assessment_type: "",
        assessment_number: "",
        date_of_assessment: "",
        scheme: "",
        iaf_code_technical_witness: "",
        name_of_assessor: "",
        assessment_report_available: "",
        any_nc_raised: "",
        number_of_ncs: "",
        nc_issue_date: "",
        nc_closer_date: "",
        remarks: "",
        is_approved: "",
        is_reject: "",
    })
    console.log(historicalAssessmentDetail, 'historicalAssessmentDetail')
    console.log(schemeData, 'schemeData');
    const getAssessorsList = () => {
        axios
            .get(`${API_URL}/team/assessor/filter/`)
            .then((response) => {
                const result = response.data?.map(item => ({
                    label: `${item?.first_name} ${item.last_name}`,
                    value: item?.id
                }))
                setAssessorsList(result)
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    const getSchemesList = () => {
        axios
            .get(`${API_URL}/global/scheme/list/`)
            .then((response) => {
                const result = response.data?.map((item) => ({
                    label: item?.name,
                    value: item?.id
                }))
                setSchemesList(result)
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    useEffect(() => {
        getSchemesList()
        getClustersList()
        getAssessorsList()
    }, [])
    const getClustersList = () => {
        axios
            .get(`${API_URL}/global/cluster/list`)
            .then((response) => {
                const result = response.data?.map((item) => ({
                    label: item?.name,
                    value: item?.id
                }))
                setClustersList(result)
            })
            .catch((error) => {
                console.error('API Error:', error)
            })
    }
    const handleSchemeData = (e) => {
        setSchemeData(e)
        setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, scheme: e.value });
    }
    const handleCreateHistoricalAssessment = async () => {
        try {
            const response = await axios.post(`${API_URL}/cab/historical/data/post/`, historicalAssessmentDetail);
            setHistoricalAssessmentDetail({
                ...historicalAssessmentDetail,
                cab_name: "",
                assessment_type: "",
                assessment_number: "",
                date_of_assessment: "",
                scheme: "",
                iaf_code_technical_witness: "",
                name_of_assessor: "",
                assessment_report_available: "",
                any_nc_raised: "",
                number_of_ncs: "",
                nc_issue_date: "",
                nc_closer_date: "",
                remarks: "",
            })
            console.log(response, 'response')
            handleClose();
        } catch (error) {
            console.log(error);
        }
    }
    const handleAssessmentReport = (e) => {
        setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, assessment_report_available: e?.value })
    }
    const handleNCRaised = (e) => {
        setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, any_nc_raised: e.value })
    }
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        if (checked) {
            setCheckedIds([...checkedIds, name]);
        } else {
            setCheckedIds(checkedIds.filter(e => e !== name));
        }
    };
    const handleSelectedAssessments = () => {
        const selectedData = assessorsList?.filter(item => checkedIds?.includes(item?.value));
        setSelectedAssessors(selectedData)
    }
    return (<>
        <Modal
            id='Historical_Assessment'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show.id}
            onHide={handleClose}
            backdrop={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create Historical Assessment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Cab Name</label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                onChange={(e) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, cab_name: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Type </label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                onChange={(e) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, assessment_type: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Number</label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                onChange={(e) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, assessment_number: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">Date of Assessment</label>
                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={(date) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, date_of_assessment: date })
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Scheme</label>
                            <Select
                                className='react-select-styled react-select-solid '
                                isMulti
                                name="User"
                                options={schemesList}
                                classNamePrefix='react-select'
                                onChange={handleSchemeData}
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">IAF Code/ Technical Area in case of Witness
                            </label>
                            <Select
                                className='react-select-styled react-select-solid '
                                name="User"
                                classNamePrefix='react-select'
                            />
                        </div>
                    </div> */}
                    {/* <div className="row p-5">
                        <div className="mb-5 col-12">
                            <HistoricalCheckboxHierarchy
                                selectedPrograms={selectedPrograms}
                                selectedSchemes={selectedSchemes}
                                selectedStandards={selectedStandards}
                                setSelectedPrograms={setSelectedPrograms}
                                setSelectedSchemes={setSelectedSchemes}
                                setSelectedStandards={setSelectedStandards}
                                selectedSchemesList={selectedSchemesList}
                                setSelectedSchemesList={setSelectedSchemesList}
                                selectedStandardList={selectedStandardList}
                                setSelectedStandardList={setSelectedStandardList}
                            />
                        </div>
                    </div> */}
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessors
                            </label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                value={selectedAssessors?.map(item => item?.label)?.join(', ')}
                                onClick={() => setAssessorModal(true)}

                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="form-label">Assessment Report Available
                            </label>
                            <Select
                                className='react-select-styled react-select-solid '
                                name="User"
                                options={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                classNamePrefix='react-select'
                                onChange={handleAssessmentReport}
                            />
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="form-label">Any NC Raised
                            </label>
                            <Select
                                className='react-select-styled react-select-solid '
                                name="User"
                                options={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                classNamePrefix='react-select'
                                onChange={handleNCRaised}
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">If Yes, Number of NCs
                            </label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                onChange={(e) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, number_of_ncs: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">NC Issue Date
                            </label>
                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={(date) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, nc_issue_date: date })
                                }
                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">NC Closure Date
                            </label>
                            <ReactDatePicker
                                className='react_date_picker form-control form-control-solid '
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={(date) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, nc_closer_date: date })
                                }
                            />
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Remarks
                            </label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--"
                                onChange={(e) => setHistoricalAssessmentDetail({ ...historicalAssessmentDetail, remarks: e.target.value })
                                }
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="success"
                    onClick={handleCreateHistoricalAssessment}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={assessorModal} onHide={() => setAssessorModal(false)} size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Select Assessor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='card-body '>
                    <div className='row mb-7'>
                        {assessorsList.map((item, index) => (
                            <div className='col-md-6 d-flex  mb-3  '>
                                <div className=' d-flex form-control form-control-lg form-control-solid w-100'>
                                    <input
                                        type="checkbox"
                                        name={item.value}
                                        checked={checkedIds.includes(item.value)}
                                        onChange={handleCheckboxChange}
                                        id={`assess_${index}`}
                                    />
                                    <label className=' ' style={{ padding: '4px 0 0 14px' }} htmlFor={`assess_${index}`}>
                                        {item.label}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div> </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setAssessorModal(false)}>
                    Close
                </Button>
                <Button
                    variant="success"
                    onClick={() => { handleSelectedAssessments(); setAssessorModal(false) }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal >
    </>
    );
};
export default CreateHistoricalAssessmentModal;