import React, { useEffect, useState, ChangeEvent } from "react";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../../../../_metronic/helpers";
import axios from "axios";
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input'


const AddSiteAddressModal = ({ show, handleClose }) => {
    const BASE_URL = process.env.REACT_APP_API_URL

    const [countryList, setCountryList] = useState<any>();
    // get country list
    const fetchCountryData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/certificate/list/country/`
            )
            setCountryList(response.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCountryData()
    }, [])


    return (
        <>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2>Add Team Member</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTIcon className='fs-1' iconName='cross' />
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="container">
                        <div className='card mb-5 mb-xl-10'>
                            <div className='card-body border-top p-9'>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Contact</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Contact'/>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Phone' />

                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Postal Code</label>
                                    <div className='col-lg-8 fv-row'>

                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Postal Code'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>   City
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='City'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>State</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='State'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Country
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <Select
                                            className='react-select-styled react-select-solid '
                                            classNamePrefix='react-select'
                                            options={countryList?.map((item) => ({ value: item?.id, label: item?.name }))}
                                            placeholder='Select Country'
                                            />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Address'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Description of Scope</label>
                                    <div className='col-lg-8 fv-row'>


                                        <textarea rows={3} className="form-control form-control-solid"

                                        ></textarea>
                                    </div>
                                </div>

                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>

                                <button type='submit' className='btn btn-primary' >
                                    Save
                                </button>



                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default AddSiteAddressModal;