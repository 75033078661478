import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

interface InvoiceChartProps {
    pending: number;
    paid: number;
    draft:number;
    overdue:number;
}

const InvoiceChart: React.FC<InvoiceChartProps> = ({ pending, paid,draft,overdue }) => {
  useEffect(() => {
   
    const options = {
      series: [pending, paid,draft,overdue],
      chart: {
        width: 350,
        type: 'pie',
      },
      labels: ["Pending", "Paid","Draft","Overdue"],
      responsive: [{
        breakpoint: 350,
        options: {
          chart: {
            width: 350
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const chart = new ApexCharts(document.querySelector("#chart2"), options);
    chart.render();

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      chart.destroy();
    };
  }, [pending, paid,draft,overdue]); // Dependency array ensures the effect runs when opennc or closenc change

  return (
    <div id="chart2"></div>
  );
};

export default InvoiceChart;
