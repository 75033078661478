import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'

import 'react-datepicker/dist/react-datepicker.css'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import moment from 'moment'
import {Modal} from 'react-bootstrap'

const API_URL = process.env.REACT_APP_API_URL
interface Remark {
  remark: string
}
interface CabInterface {
  id: string
  assessment_number: string
  date_of_ncr: string
  ncr_number: string
  uaf_requirements: string
  non_conformity_observed: string
  scheme_list: {name: string; id: string; short_name: string}[]
  scheme_text:string
  standard_list: {name: string; id: string}[]
  standard_clause: string
  category_name: string
  team_leader_list: {first_name: string; id: string; last_name: string}[]
  team_member_list: {first_name: string; id: string; last_name: string}[]
  registrar_name: string
  cab_representative: string
  evidence_reference: string
  cab_representative_email: string[]
  action_status: string
  remarks: string
  nc_remark: Remark[]
  remark: string
  rca: string
  pc: string
  evidence_document: string
}
interface RemarkInterface {
  id: string
  cab_remark: string
  assessor_remark: string
  rca_remark: string
  pc_remark: string
  evidence_remark: string
  document: File | null
  nc_management: string
  create_at: string
}

interface FormState {
  nc_id: string
  rca: string
  pc: string
  remark: string
  cab_evidence: File | null
}

const AssessorNC = () => {
  const formUrl = useParams()
  console.log('formUrl:', formUrl)
  const token_Id = formUrl?.token || ''
  const nc_id = formUrl?.ncId || ''
  const user_id = formUrl?.userId || ''
  const assessor_id = formUrl?.assessorId || ''
  const variables_id = formUrl?.variablesId || ''
  console.log(assessor_id, 'this is assessor_id')
  console.log(variables_id, 'this is variables_id')
  console.log(nc_id, 'this is my nc_idddddd')
  console.log(user_id, 'this is my user')
  const [value, setValue] = useState<FormState>({
    nc_id: '',
    rca: '',
    pc: '',
    remark: '',
    cab_evidence: null,
  })

  const [cabData, setCabData] = useState<CabInterface | null>(null)
  const [remarkData, setRemarkData] = useState<RemarkInterface[]>([])

  const [isApproved, setIsApproved] = useState<boolean | null>(null)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const handlePdfIconClick = (url: any) => {
    setPdfUrl(url)
    setShowPdfModal(true)
  }
  const handleClosePdfModal = () => {
    setShowPdfModal(false)
    setPdfUrl('') // Clear the PDF URL when the modal is closed
  }
  console.log(remarkData, 'this is remark Data')
  const getCABNC = async () => {
    try {
      const response = await axios.get<CabInterface>(`${API_URL}/nc/management/${nc_id}/patch/`)
      setCabData(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  const getCABremark = async () => {
    try {
      // api/nc/management/remark/filter/?nc_management=b39996a6-8c4f-46c2-8f96-8bf47cc2687f
      const response = await axios.get<RemarkInterface[]>(
        `${API_URL}/nc/management/remark/filter/?nc_management=${nc_id}`
      )
      setRemarkData(response.data)
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    getCABNC()
    getCABremark()

  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e.target
    setRemarkData((prevData) => {
      const updatedData = [...prevData]
      if (updatedData[0]) {
        updatedData[0] = {...updatedData[0], [name]: value}
      }
      return updatedData
    })
  }

  const handleEvidence = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setValue({...value, cab_evidence: e.target.files[0]})
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    console.log(value, 'Printed Value.......!')

    try {
      const formData = new FormData()
      formData.append('token', token_Id)
      formData.append('assessor', assessor_id)
      formData.append('variables', variables_id)
      formData.append('registrar', user_id)
      formData.append('nc_management', nc_id)
      formData.append('assessor_remark', value.remark)
      formData.append('is_approved', isApproved ? 'true' : 'false')
      formData.append('is_rejected', isApproved ? 'false' : 'true')

      // if (value.cab_evidence && value.cab_evidence instanceof File) {
      //   formData.append('cab_document', value.cab_evidence)
      // }

      await axios.post(`${API_URL}/nc/management/assessor/remark/`, formData)

      toast.success('Application Submitted Email Send.')
    } catch (error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const handleApprove = () => {
    setIsApproved(true)
  }

  const handleResend = () => {
    setIsApproved(false)
  }

  return (
    <div id="nc_assessor" className='container py-20'>
      <div className='row'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
            className='h-50px h-lg-100px app-sidebar-logo-default theme-light-show'
          />
          <div className='uaf_title py-5 text-center'>
            <h2 className=''>UNITED ACCREDITATION FOUNDATION</h2>
            <span className='fs-18'>NC Assessor Evaluation</span>
            {/* <div className="fs-18">EMS</div> */}
          </div>
        </div>
      </div>

      <div className='py-lg-10 px-lg-10'>
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            <div className='row'>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Assessment</label>
                <input
                id="nc_assessor_1"
                  value={cabData?.assessment_number}
                  className='form-control form-control-solid'
                  type='text'
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Registrar</label>
                <input
                 id="nc_assessor_2"
                  value={cabData?.registrar_name}
                  className='form-control form-control-solid'
                  type='text'
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>NCR Number</label>
                <input
                 id="nc_assessor_3"
                  value={cabData?.ncr_number}
                  className='form-control form-control-solid'
                  type='text'
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Date of NCR</label>
                <input
                 id="nc_assessor_4"
                  value={moment(cabData?.date_of_ncr).format('MM/DD/YYYY')}
                  className='form-control form-control-solid'
                  type='text'
                  readOnly
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>Standard/UAF Requirements</label>
                <textarea
                 id="nc_assessor_5"
                  className='form-control form-control-solid'
                  rows={5}
                  name='uaf_requirement'
                  value={cabData?.uaf_requirements}
                  readOnly
                />
              </div>
              <div className='mb-5'>
                <label className='required form-label'>Non-Conformity Observed</label>
                <textarea
                 id="nc_assessor_6"
                  className='form-control form-control-solid'
                  rows={5}
                  value={cabData?.non_conformity_observed}
                  name='non_conformity'
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Scheme</label>
                <input
                  type='text'
                   id="nc_assessor_7"
                  className='form-control form-control-solid'
                  name='scheme'
                  value={cabData?.scheme_text}
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Category</label>
                <input
                 id="nc_assessor_8"
                  value={cabData?.category_name}
                  className='form-control form-control-solid'
                  type='text'
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Standard / Clause No.</label>
                <input
                 id="nc_assessor_9"
                  type='text'
                  className='form-control form-control-solid'
                  name='standard'
                  value={cabData?.standard_clause}
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Team Leader, UAF</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='leader'
                   id="nc_assessor_10"
                  value={cabData?.team_leader_list
                    .map((leader) => leader.first_name + ' ' + leader.last_name)
                    .join(', ')}
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Team Member, UAF</label>
                <input
                  type='text'
                   id="nc_assessor_11"
                  className='form-control form-control-solid'
                  name='members'
                  value={cabData?.team_member_list
                    .map((member) => member.first_name + ' ' + member.last_name)
                    .join(', ')}
                  readOnly
                />
              </div>
              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>CAB Representative</label>
                <input
                  type='text'
                   id="nc_assessor_12"
                  className='form-control form-control-solid'
                  name='cab_representative'
                  value={cabData?.cab_representative}
                  readOnly
                />
              </div>
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-body p-9'>
                  <div className='mb-5'>
                    <label className=' form-label'>Assessor Remarks</label>
                    <textarea
                    id="nc_assessor_13"
                      className='form-control form-control-solid'
                      rows={5}
                      value={cabData?.remarks}
                      name='remark'
                    />
                  </div>
                </div>
              </div>

              <div className='mb-5 col-lg-6'>
                <label className='required form-label'>Evidence reference</label>
                <input
                  type='text'
                  id="nc_assessor_14"
                  className='form-control form-control-solid'
                  name='evidence'
                  value={cabData?.evidence_reference}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-body p-9'>
            {remarkData.map((item,index) => (
              <>
                <h5>Date: {moment(item?.create_at).format('MM/DD/YY hh:mm A')}</h5>
                {item?.rca_remark ? (
                  <div className='mb-5'>
                    <label className='form-label'>Root Cause Analysis (RCA)</label>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      id={`hello_rac_${index}`}
                      placeholder='Enter Root Cause Analysis'
                      name='rca_remark'
                      value={item?.rca_remark || ''}
                      readOnly
                      // onChange={handleChange}
                    />
                  </div>
                ) : null}
                {item?.pc_remark ? (
                  <div className='mb-5'>
                    <label className='form-label'>Proposed Correction and Corrective Action</label>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      id={`hello_pc_${index}`}
                      placeholder='Enter Proposed Correction and Corrective Action'
                      name='pc_remark'
                      value={item?.pc_remark || ''}
                      readOnly
                      // onChange={handleChange}
                    />
                  </div>
                ) : null}
                {item?.cab_remark ? (
                  <div className='mb-5'>
                    <label className='form-label'>CAB Remarks</label>
                    <textarea
                      className='form-control form-control-solid'
                      rows={5}
                      id={`hello_cab_remarks_${index}`}
                      placeholder='Enter CAB Remarks'
                      name='cab_remark'
                      value={item?.cab_remark || ''}
                      // onChange={handleChange}
                      readOnly
                    />
                  </div>
                ) : null}
                {item?.document ? (
                  <div className='mt-7 col-lg-6 mb-5'>
                    <label className='required form-label'>Uploaded Document:</label>
                    <a id={`hello_doc_${index}`} onClick={() => handlePdfIconClick(item?.document)}>
                      <img src='/media/doc.png' alt='' />
                    </a>
                  </div>
                ) : null}
                {item?.assessor_remark ? (
                  <div className='mb-5'>
                    <label className='form-label'>Assessor Remarks</label>
                    <textarea
                    id={`hello_remarks_${index}`}
                      className='form-control form-control-solid'
                      rows={5}
                      placeholder='Enter Root Cause Analysis'
                      name='rca_remark'
                      value={item?.assessor_remark || ''}
                      readOnly
                      // onChange={handleChange}
                    />
                  </div>
                ) : null}

              </>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit}  id="le_lo_form">
          <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
            <div className='card-body p-9'>
              <div className='mb-5'>
                <label className='form-label'>Your Remarks</label>
                <textarea
                  className='form-control form-control-solid'
                  rows={5}
                   id="le_lo_form_1"
                  placeholder='Enter Remarks'
                  name='remark'
                  onChange={(e) =>
                    setValue({
                      ...value,
                      remark: e.target.value,
                    })
                  }
                />
              </div>
              {/* <div className='text-end'>
                <label
                  htmlFor='cab_evidence'
                  className={value.cab_evidence ? 'file-uploaded-uploaded' : 'file-upload-upload'}
                  style={{width: '155px', marginTop: '25px'}}
                >
                  {value.cab_evidence ? 'Doc Uploaded' : 'Upload Doc'}
                </label>
                <input
                  type='file'
                  id='cab_evidence'
                  style={{display: 'none'}}
                  onChange={handleEvidence}
                />
              </div> */}
              <div className='d-flex'>
                <div className='mb-5 px-10'>
                  <button type='submit' id="kar_lo_approve" className='btn btn-success' onClick={handleApprove}>
                    Approved
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>
                </div>
                <div className='mb-5 px-10'>
                  <button type='submit' className='btn btn-danger' id="kar_lo_resend" onClick={handleResend}>
                    Resend
                    <i className='fa fa-angle-double-right' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display the PDF in the modal */}
          {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            title='DOCX Viewer'
            width='100%'
            height='500px'
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AssessorNC
