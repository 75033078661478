import React from 'react'


import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import InvoiceHeader from './InvoiceHeader'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import AllInvoice from './AllInvoice'
import PaidInvoice from './PaidInvoice'
import PendingInvoice from './PendingInvoice'


const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Invoice',
    path: '/invoice',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Invoice',
    path: '/invoice',
    isSeparator: true,
    isActive: false,
  },
]

const InvoicePage: React.FC = () => {




  return(
    <div id="hello_123">
    <Routes>
    <Route
      element={
        <>
          <InvoiceHeader  />
          <Outlet />
        </>
      }
    >
      <Route
        path='/all-invoice'

        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>All Invoice</PageTitle>
            <AllInvoice />
          </>

        }
      />
      <Route
        path='/paid-invoice'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Paid Invoice</PageTitle>
            <PaidInvoice />
          </>
        }
      />
       <Route
        path='/pending-invoice'
        element={
          <>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Pending Invoice</PageTitle>
            <PendingInvoice />
          </>
        }
      />
      
      <Route index element={<Navigate to='/invoice/all-invoice/' />} />
    </Route>
  </Routes>
 </div>
  )
}


export default InvoicePage
