import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import "../../../../src/_metronic/assets/css/certificateCss.css"
interface FormDataProps {
    userData: UserData;
}
interface UserData {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    company: string;
    phone: string;
    profile: File | null;
    certification: string[];
    country: string;
    message: string;
    graduation: string;
    company_web: string;
    university_name: string;
    graduation_date: Date | null;
    work_experience: boolean;
    training_17011: boolean;
    training_17021_1: boolean;
    training_17021_2: boolean;
    training_17021_3: boolean;
    training_17021_10: boolean;
    training_17024: boolean;
    training_27000: boolean;
    training_27005: boolean;
    training_27006: boolean;
    training_27007: boolean
    training_27008: boolean;
    training_22000: boolean;
    training_22003: boolean;
    training_22003_1: boolean;
    training_50001: boolean;
    training_50003: boolean;
    training_45001: boolean;
    training_13485: boolean;
    training_9001: boolean;
    training_14001: boolean;
    resume: File | null;

    work_experience_doc: File | null;
    training_17011_doc: File | null;
    training_17021_1_doc: File | null;
    training_17021_2_doc: File | null;
    training_17021_3_doc: File | null;
    training_17021_10_doc: File | null;
    training_17024_doc: File | null;
    training_27000_doc: File | null;
    training_27005_doc: File | null;
    training_27006_doc: File | null;
    training_27007_doc: File | null;
    training_27008_doc: File | null;
    training_22000_doc: File | null;
    training_22003_doc: File | null;
    training_22003_1_doc: File | null;
    training_50001_doc: File | null;
    training_50003_doc: File | null;
    training_45001_doc: File | null;
    training_13485_doc: File | null;
    training_9001_doc: File | null;
    training_14001_doc: File | null;
}
const FMSform: React.FC<FormDataProps> = ({ userData }) => {
    console.log(userData, "FMS");
    return (
        <div className="">
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-custom-header '>
                    <div className="d-flex flex-column justify-content-center align-items-center py-5">
                        <div className="">
                            <img
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/uaf_logo.png')}
                                className='h-60px h-lg-60px app-sidebar-logo-default theme-light-show'
                            />
                        </div>
                        <div className="mt-5">
                            <h2 style={{ fontSize: "18px" }} >
                                UNITED ACCREDITATION FOUNDATION
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="px-20 py-3 text-center cf-border-bottom">
                    <h2 className="cf-text-gray" style={{ fontSize: "14px" }}>Initial Competence Evaluation - FSMS</h2>
                </div>
                <div className="px-20 py-3 cf-border-bottom">
                    <div className="row ">
                        <div className="col-lg-6 col-xl-6 mt-1">
                            <h2 className="px-5" style={{ fontSize: "16px" }}>Assessor Name / Lead Assessor</h2>
                        </div>
                        <div className="col-lg-6 col-xl-6 text-center mt-1">

                            <h2 className="" style={{ fontSize: "16px" }}>{userData?.first_name}{" "}{userData?.last_name}</h2>
                        </div>
                    </div>

                </div>
                <div className='card-body px-20'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                        <thead>
                            <tr style={{ background: "#f7f7f7" }} className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                <th className="px-5 min-w-125px">Competence Parameters Requirement</th>
                                <th className="px-5 min-w-125px">Actual</th>
                                <th className="px-5 min-w-125px">Accepted/Deferred</th>
                                <th className="px-5 min-w-150px">Doc</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-semibold">
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Under Graduate (Bachelor)*</div>
                                </td>
                                <td>
                                    {userData?.graduation ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.profile ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">
                                        Work experience in accreditaion/certification process (Minimum 5 years)*
                                    </div>
                                </td>
                                <td>
                                    {userData?.work_experience === true ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.work_experience_doc ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Training on ISO/IEC 17011:2017*</div>
                                </td>
                                <td>
                                    {userData?.training_17011 === true ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}
                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.training_17011_doc ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Training on ISO/IEC 17021-1:2015*</div>
                                </td>
                                <td>
                                    {userData?.training_17021_1 === true ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}
                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.training_17021_1_doc ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Training on ISO/IEC 17021-3:2017*</div>
                                </td>
                                <td>
                                    {userData?.training_17021_3 === true ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}
                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.training_17021_3_doc ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">
                                        Document Reviewer: Internal training on UAF accreditation process
                                    </div>
                                </td>
                                <td>

                                    <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    <div className='btn btn-sm btn-primary me-3'
                                    >
                                        No Doc
                                    </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">
                                        Lead Assessor: Knowledge of ISO 9001, with minimum two years work experience as an accreditation assessor, or minimum three years FMS auditing experience.
                                    </div>
                                </td>
                                <td>
                                    {userData?.training_9001 === true ? (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>
                                    </>) : (<>
                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>
                                    </>)}
                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>
                                    {userData?.training_9001_doc ? (<>
                                        <a /* onClick={() => setShowSendEmailToCabModal(true)} */
                                            href='#'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Uploaded Doc
                                        </a>
                                    </>) : (<>
                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>
                                    </>)}

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of Updated CV</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">Yes</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'>
                                            No Doc
                                        </div>

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of Risk Assessment carried by UAF</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">
                                        Assessor: Knowledge of ISO 9001, with minimum one year work experience as an accreditation assessor, or minimum two years FMS auditing experience.
                                    </div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of Updated CV</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">pending</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>

                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of Risk Assessment carried by UAF</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of onsite witness during office assessment</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Witness Assessor: Lead Auditor training of ISO 9001</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Auditing experience in Mandays (Minimum ten)</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Recent witness assessment by any other AB, if any</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">FMS Witness Assessor competence available for the IAF Scopes as detailed in UAF F-22</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of Contract Agreement signed with Assessor</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest date of disclosure statement</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Member of Accreditation Review Committee: Internal training on UAF accreditation process</div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">
                                        Approval Status : The personnel is approved as: Office Assessor/Lead Assessor/ Document Review Assessor/Lead Assessor/Witness Assessor and Member Accreditation Review committee
                                    </div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Review Done by </div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Approval Done by </div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>


                                </td>
                            </tr>
                            <tr>
                                <td className="px-5">
                                    <div className="text-gray-800 text-hover-primary mb-1">Latest Date of Review or updation of this document </div>
                                </td>
                                <td>

                                        <div className="text-gray-800 text-hover-primary mb-1">No</div>

                                </td>
                                <td>
                                    <div className="badge badge-light-danger fw-bold">Pending...</div>
                                </td>
                                <td>

                                        <div className='btn btn-sm btn-primary me-3'
                                        >
                                            No Doc
                                        </div>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="p-3 text-center cf-border-top">
                    <p>
                        <span style={{ fontWeight: "600" }}>NOTE:</span>  A Technical Expert, having above mentioned work experience in said technical area, shall be additional member along with assessor(s) in cases where assessor(s) is/are falling short of any of above requisite/attributes of competence
                    </p>
                </div>
                <div className="p-3 text-center cf-border-top">
                    <p>
                        <span style={{ color: "red" }}>*</span> These requirements are aplicable to document reviewer, office assessor, office lead assessor, witness assessor, member of accreditation review committee
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FMSform;