import React, { useState } from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
import { CardsWidget17, CardsWidget20, CardsWidget7, ListsWidget26 } from "../../../../_metronic/partials/widgets";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { BusinessUnitList } from "./BusinessUnitList";
import CreateBusinessUnit from "./models/CreateBusiinessUnit";
import "../../../../_metronic/assets/css/businessCss.css"

const organisationBreadCrumbs: Array<PageLink> = [
    {
        title: 'Organisation',
        path: '/organisation/businessunit',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const BusinessUnit: React.FC = () => {

    return (<>

        <PageTitle breadcrumbs={organisationBreadCrumbs}>Buniness Unit List</PageTitle>

        {/* <div className='d-flex align-items-center gap-2 gap-lg-3'>
        <a
          href='#'
          onClick={() => setShowCreateAppModal(true)}
          className='float-end btn btn-sm fw-bold btn-primary'
        >
          Create Business Unit
        </a>
        </div> */}


        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-lg-12 mb-xl-10'>
                <BusinessUnitList  />
            </div>
        </div>
        {/* <CreateBusinessUnit show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} /> */}
    </>
    );
};

export default BusinessUnit;