import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownButton, Modal, Button } from 'react-bootstrap'
import { KTIcon } from '../../../_metronic/helpers'

const PendingInvoice = () => {
  return (
    <div className='card card-flush'>

    <div className='card-header pt-5'>
        <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
                <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <rect
                            opacity='0.5'
                            x='17.0365'
                            y='15.1223'
                            width='8.15546'
                            height='2'
                            rx='1'
                            transform='rotate(45 17.0365 15.1223)'
                            fill='currentColor'
                        />
                        <path
                            d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                            fill='currentColor'
                        />
                    </svg>
                </span>
                <input
                    id="le_lu_ri"
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-250px ps-14"
                    // onChange={handleSearchChange}
                    // onKeyDown={(e) => {
                    //   if (e.key === 'Enter') {
                    //     handleSearchChange(e);
                    //   }
                    // }}
                    placeholder="Search Invoice ID"
                />
            </div>
        </div>
        <div className='card-toolbar'>
            <div className='d-flex justify-content-end' style={{ gap: "10px " }} data-kt-user-table-toolbar='base'>
                <a id="le_lu_mai_1" className='btn btn-primary' > Export</a>
            </div>
            <div
                className='d-flex justify-content-end align-items-center d-none'
                data-kt-user-table-toolbar='selected'
            >
                <div className='fw-bold me-5'>
                    <span className='me-2' data-kt-user-table-select='selected_count'></span>Selected
                </div>
                <button
                    type='button'
                    className='btn btn-danger'
                    data-kt-user-table-select='delete_selected'
                >
                    Delete Selected
                </button>
            </div>
        </div>
    </div>

    <div className='card-body pt-5'>
        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
            <thead>
                <tr className='text-start  fw-bold  text-uppercase gs-0'>
                    <th className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                            // checked={selectAll}

                            // onChange={handleSelectAll}
                            />
                        </div>
                    </th>
                    <th className='min-w-125px'>Invoice ID</th>
                    <th className='min-w-125px'>Client Name</th>
                    <th className='min-w-125px'>Assement Number</th>
                    <th className='min-w-125px'>Date</th>
                    <th className='min-w-125px'>Status</th>
                    <th className='min-w-125px'>Amount</th>
                    <th className='text-center min-w-100px'>Actions</th>
                </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
                <tr>
                    <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input' type='checkbox' value='1'
                                //   onChange={() => handleChangeId(item)}
                                //   checked={selectedItems?.includes(item?.id)}
                                id="le_lu_mai_2"
                            />
                        </div>
                    </td>
                    <td>
                        <Link
                            id="le_lu_mai_3"
                            to={`/`}
                            className='text-gray-800 text-hover-primary mb-1'
                        >
                            #1234
                        </Link>
                    </td>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                                <span className="text-gray-800 text-hover-primary mb-1">Jon Doe</span>
                                <span>test@example.us</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className='text-gray-800 text-hover-primary mb-1'>11-111-1111</div>
                    </td>
                    <td>dates
                        {/* {item && item.create_at ? formatDate(item.create_at) : ''} */}</td>
                    <td>
                        <div className='badge badge-light-primary fw-bold'>Approved</div>
                    </td>
                    <td>
                        <div className='text-gray-800 text-hover-primary mb-1'>$2323</div>

                    </td>
                    <td>
                        <div className='d-flex justify-content-center flex-shrink-0'>

                            <Link
                                to={`/`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTIcon iconName='pencil' className='fs-3' />
                            </Link>
                            <a
                                //  onClick={() => ShowDeleteModal(item?.id)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTIcon iconName='trash' className='fs-3' />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        {/* <ul className='pagination'>
  <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
    <a   id="le_lu_mai_4" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
      <i className='previous'></i>
    </a>
  </li>
  {totalPagesArray.map((page,index) => (
    <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
      <a  id={`le_lu_page_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  ))}
  <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
    <a   id="le_lu_mai_5" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
      <i className='next'></i>
    </a>
  </li>
</ul> */}
    </div></div>
  )
}

export default PendingInvoice