import React, { useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Select from 'react-select'
import AssessmentInvoice from './AssessmentInvoice'
import CabAnnualFee from './CabAnnualFee'
import CabInitialFee from './CabInitialFee'
import AssessorJobOrder from './AssessorJobOrder'
import AssessorARCInvoice from './AssessorARCInvoice'
import OtherForAssessor from './OtherForAssessor'
import OtherForCab from './OtherForCab'
import OtherInvoice from './OtherInvoice'

const CreateInvoice = () => {
    const accountBreadCrumbs: Array<PageLink> = [
        {
            title: 'Invoice',
            path: '/invoice',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Invoice',
            path: '/invoice',
            isSeparator: true,
            isActive: false,
        },
    ]
    const [selectedUser, setSelectedUser] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');
    console.log(selectedOption, "selectedOption");


    const assessorOption = [
        { label: 'Job Order', value: 'job_order' },
        { label: 'ARC Invoice', value: 'arc_invoice' },
        { label: 'Other', value: 'other_assessor' },
    ];
    const cabOption = [
        { label: 'Assessment Invoice', value: 'assessment_invoice' },
        { label: 'Annual & certificate Fee', value: 'annual_certificate_fee' },
        { label: 'Initial Fee', value: 'initial_fee' },
        { label: 'Other', value: 'other_cab' },
    ];
    const otherOption = [
        
        { label: 'Other', value: 'other_invoice' },
    ];

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };

    const handleAssessorChange = (selectedOption) => {
        setSelectedOption(selectedOption ? selectedOption.value : '');
    };
    const handleCabChange = (selectedOption) => {
        setSelectedOption(selectedOption ? selectedOption.value : '');
    };
    const handleOtherChange = (selectedOption) => {
        setSelectedOption(selectedOption ? selectedOption.value : '');
    };
    return (
        <> <PageTitle breadcrumbs={accountBreadCrumbs}>Create Invoice</PageTitle>
            <div className='col-lg-12'>
                <div className='row'>
                    <div className='col-lg-9'>
                        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                            <div className='card-header cursor-pointer'>
                                <div className='card-title m-0'>
                                    <h3 className='fw-bolder m-0'>Invoice</h3>
                                </div>

                            </div>
                            <div className='card-body p-9'>
                                <div className='row gy-10 gx-xl-10'>
                                    <div className='col-xl-12'>
                                        {selectedOption === "assessment_invoice" && (
                                            <AssessmentInvoice />
                                        )
                                        }
                                        {selectedOption === "annual_certificate_fee" && (
                                            <CabAnnualFee selectedOption={selectedOption}/>
                                        )
                                        }
                                        {selectedOption === "initial_fee" && (
                                            <CabInitialFee selectedOption={selectedOption}/>
                                        )
                                        }
                                        {selectedOption === "job_order" && (
                                            <AssessorJobOrder />
                                        )
                                        }
                                         {selectedOption === "arc_invoice" && (
                                            <AssessorARCInvoice />
                                        )
                                        } 
                                        {selectedOption === "other_assessor" && (
                                            <OtherForAssessor />
                                        )
                                        }
                                        {selectedOption === "other_cab" && (
                                            <OtherForCab />
                                        )
                                        }
                                        {selectedOption === "other_invoice" && (
                                            <OtherInvoice />
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* sidebar */}
                    <div className='col-lg-3'>
                        <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                            <div className='card-header cursor-pointer'>
                                <div className='card-title m-0'>
                                    <h3 className='fw-bolder m-0'>Invoice Tools</h3>
                                </div>

                            </div>
                            <div className='card-body p-9'>
                                <div className='row gy-10 gx-xl-10'>
                                    <div className='col-xl-12'>
                                        <div className=' card-xl-stretch mb-5 mb-xl-10 ' id='kt_profile_details_view'>
                                            <div>
                                                <div className='mb-3'>
                                                    <div className='mb-3'>
                                                        <h3 className='mb-2'>Invoice To:</h3>
                                                        <div className='d-flex'>
                                                            <div className='me-3'>
                                                                <input
                                                                    type="radio"
                                                                    id="option2"
                                                                    name="cab"
                                                                    value="CAB"
                                                                    className='me-1'
                                                                    checked={selectedUser === 'CAB'}
                                                                    onChange={handleUserChange}
                                                                />
                                                                <label htmlFor="option2">CAB</label>
                                                            </div>
                                                            <div className='me-3'>
                                                                <input
                                                                    type="radio"
                                                                    id="option1"
                                                                    name="assessor"
                                                                    value="Assessor"
                                                                    className='me-1'
                                                                    checked={selectedUser === 'Assessor'}
                                                                    onChange={handleUserChange}
                                                                />
                                                                <label htmlFor="option1">Assessor</label>
                                                            </div>
                                                            <div className='me-3'>
                                                                <input
                                                                    type="radio"
                                                                    id="option3"
                                                                    name="other"
                                                                    value="Other"
                                                                    className='me-1'
                                                                    checked={selectedUser === 'Other'}
                                                                    onChange={handleUserChange}
                                                                />
                                                                <label htmlFor="option3">Other</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedUser === "Assessor" ?
                                                    <div className='mb-5 mt-5'>
                                                        <h3 className='mb-2'>Invoice Type:</h3>
                                                        <Select
                                                            className='react-select-styled react-select-solid'
                                                            classNamePrefix='react-select'
                                                            options={assessorOption}
                                                            placeholder='Select Invoice Type'
                                                            onChange={handleAssessorChange} // Updated to pass the whole object
                                                            value={assessorOption.find(option => option.value === selectedOption) || null} // Correctly set the value
                                                        />
                                                    </div> : null
                                                }
                                                {selectedUser === "CAB" ?
                                                    <div className='mb-5 mt-5'>
                                                        <h3 className='mb-2'>Invoice Type:</h3>
                                                        <Select
                                                            className='react-select-styled react-select-solid'
                                                            classNamePrefix='react-select'
                                                            options={cabOption}
                                                            placeholder='Select Invoice Type'
                                                            onChange={handleCabChange} // Updated to pass the whole object
                                                            value={cabOption.find(option => option.value === selectedOption) || null} // Correctly set the value
                                                        />
                                                    </div> : null
                                                }
                                                 {selectedUser === "Other" ?
                                                    <div className='mb-5 mt-5'>
                                                        <h3 className='mb-2'>Invoice Type:</h3>
                                                        <Select
                                                            className='react-select-styled react-select-solid'
                                                            classNamePrefix='react-select'
                                                            options={otherOption}
                                                            placeholder='Select Invoice Type'
                                                            onChange={handleOtherChange} // Updated to pass the whole object
                                                            value={otherOption.find(option => option.value === selectedOption) || null} // Correctly set the value
                                                        />
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end */}
                </div>
            </div>
        </>
    )
}

export default CreateInvoice