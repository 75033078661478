import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { toast } from 'react-toastify';
import moment from 'moment'

const BASE_URL = process.env.REACT_APP_API_URL

const AssessorJobOrder = () => {
  const [assessorUser, setAssessorUser] = useState<any>()
  const [assessorId, setAssessorID] = useState<any>()
  const [assementPlannerID, setAssementPlannerID] = useState<any>()
  const [newAssessmentData, setNewAssessmentData] = useState<any>([])
  const [userRole, setUserRole] = useState<any>([])
  const [assessmentList, setAssessmentList] = useState<any>([])
  const [assessorPlannerDataFromAssessment, setAssessorPlannerDataFromAssessment] = useState<any>(
    []
  )
  console.log(assessorPlannerDataFromAssessment, "assessorPlannerDataFromAssessment");
  const [value, setValue] = useState<any[]>([]);
  const [createJobDetail, setCreateJobDetail] = useState({
    cab: "",
    assessor_planner: "",
    assessment: "",
    assessor: "",
    assessor_role: "",
    system: "",
    on_off_site: "",
    total_manday: "",
    manday_rate: "",
    arrangements_assessor: "",
    value_of_job_usd: "",
    job_order_approved: "",
    job_order_issued: "",
    value_of_job_words: "",
    planner_scheme: "",


  })
  useEffect(() => {
    axios.get(`${BASE_URL}/team/accessor/verified/list/pagination/`)
      .then(response => {
        const data = response.data?.results?.map(item => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id
        }))
        setValue(data)
      })
      .catch(error => { console.log(error); });
  }, []);
  const getAssessorDataByAssessment = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/cab/filter/scheme/assessor/planner/pagination/?assessor=${assessorId}}`
        //  `${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${urlId}`
      )
      const result = response.data.results
      setAssessorPlannerDataFromAssessment(result)

    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAssessorDataByAssessment()
  }, [assessorId])
  const getAssessmentList = () => {
    axios.get(`${BASE_URL}/cab/assessment/list/`)
      .then(response => {
        const data = response.data?.map(item => ({
          label: item?.name,
          value: item?.id
        }))
        setAssessmentList(data)
      })
      .catch(error => { console.log(error); });
  }
  useEffect(() => {
    getAssessmentList()
  }, []);
  const formatDate = (dateString: string) => {
    return moment(dateString).format('DD/MM/YYYY')
  }
  const fetchRole = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/role/list/`
      );
      setUserRole(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRole()
  }, []);
  useEffect(() => {
    if (assementPlannerID) {

      setCreateJobDetail({

        ...createJobDetail,
        assessor_planner: assementPlannerID?.assessor_planner,
        assessor_role: userRole?.find(item => item.name === (assementPlannerID?.assessor_name.find((i) => i?.[0].assessor_id === assessorId)?.[0]?.role))?.id,
        cab: assementPlannerID?.cab,
        assessment: assessmentList?.find(item => item.label === assementPlannerID?.assessment_type)?.value,
        assessor: assementPlannerID?.assessor_name?.find((i) => i?.[0].assessor_id === assessorId)?.[0].assessor_id,
        on_off_site: assementPlannerID?.assessor_data.find((i) => i.assessor?.assessor_id === assessorId)?.location,
        system: assementPlannerID?.scheme
      })

    }
  }, [assementPlannerID])
  useEffect(() => {
    if (assessorId) {
      setCreateJobDetail({ ...createJobDetail, assessor: assessorId })
    }
  }, [assessorId])
  useEffect(() => {
    if (newAssessmentData) {
      setCreateJobDetail({ ...createJobDetail, planner_scheme: newAssessmentData?.map((i) => i.id) })
    }
  }, [newAssessmentData])

  const getAssessorsData = () => {
    axios
      .get(`${BASE_URL}/cab/assessor/planner/scheme/data/filter/?assessor=${assessorId}&assessor_planner=${assementPlannerID?.assessor_planner}`)
      .then((response) => {
        const data = response?.data
        setNewAssessmentData(data);

      })
      .catch((error) => {
        // Handle errors
        console.error('API Error:', error)
      })
  }
  useEffect(() => {
    if (assementPlannerID?.assessor_planner) {
      getAssessorsData()
    }
  }, [assementPlannerID?.assessor_planner])
  const validate = () => {

    if (createJobDetail.total_manday?.trim() === "" || !createJobDetail.total_manday) {
      toast.error("Please Enter Total Mandays")
      return false
    }
    if (createJobDetail.manday_rate?.trim() === "" || !createJobDetail.manday_rate) {
      toast.error("Please Enter Manday Rate")
      return false
    }
    if (createJobDetail.arrangements_assessor?.trim() === " " || !createJobDetail.arrangements_assessor) {
      toast.error("Please Enter Travel Arrangements")
      return false
    }
    if (createJobDetail.value_of_job_usd?.trim() === "" || !createJobDetail.value_of_job_usd) {
      toast.error("Please Enter Value of Job Order")
      return false
    }
    if (createJobDetail.job_order_approved?.trim() === "" || !createJobDetail.job_order_approved) {
      toast.error("Please Enter Job Order Approved By")
      return false
    }
    if (createJobDetail.job_order_issued?.trim() === "" || !createJobDetail.job_order_issued) {
      toast.error("Please Enter Job Order Issued By")
      return false
    }
    if (createJobDetail.value_of_job_words?.trim() === "" || !createJobDetail.value_of_job_words) {
      toast.error("Please Enter Total Value")
      return false
    }

    return true
  };
  const createJobPost = async () => {
    if (!validate()) return


    try {
      const response = await axios.post(`${BASE_URL}/cab/job/order/create/`, createJobDetail);
      toast.success("Job Order Created Successfully")
      getAssessorDataByAssessment()
      setCreateJobDetail({
        ...createJobDetail,
        assessment: "",
        assessor: "",
        assessor_role: "",
        system: "",
        on_off_site: "",
        total_manday: "",
        manday_rate: "",
        arrangements_assessor: "",
        value_of_job_usd: "",
        job_order_approved: "",
        job_order_issued: "",
        value_of_job_words: "",
        planner_scheme: "",
      })
      setAssessorPlannerDataFromAssessment("")

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='row'>
      <div className="mb-5 col-6">
        <label className="required form-label">Select Assessor</label>
        <Select
          id="select_user"
          name="User"
          options={value}
          className='react-select-styled'
          classNamePrefix='react-select'
          value={assessorUser}
          onChange={(e) => {
            setAssessorUser(e);
            setAssessorID(e?.value);
          }}

        />
      </div>
      <div className="mb-5 col-6 px-10 py-5 ">
        <h4 className='fw-bolder mb-2'>Assessor: <span className="inline_master_head">{assessorUser?.label}</span></h4>
      </div>


     

        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' >
            <thead >
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>Assessment Number</th>
                <th className='min-w-120px'>Assessment Type</th>
                <th className='min-w-120px'>Scheme</th>
                <th className='min-w-100px '>Assessment Date</th>
                <th className='min-w-100px '>COI</th>
                <th className='min-w-100px '>Order Number</th>
                <th className='min-w-100px '>Action</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {assessorPlannerDataFromAssessment?.map((item) => (
                <>
                  {item?.assessor_data?.find(
                    (i) => i?.assessor?.assessor_id === assessorId
                  )?.assessor_job_order?.length === 0 ? (
                    <tr key={item.id}>
                      <td className='min-w-125px ps-4 text-dark fw-bold text-hover-primary  fs-6'>{item?.assessment_number}</td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.assessment_type}</td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>{item?.scheme_short_name}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{item && item.create_at ? formatDate(item.create_at) : ''}</td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        {item?.assessor_data?.find(
                          (i) => i?.assessor?.assessor_id === assessorId
                        )?.coi_assessor === true && item?.assessor_data?.find(
                          (i) => i?.assessor?.assessor_id === assessorId
                        )?.coi_cab === true ? (<>
                          <div className='badge badge-light fw-bolder'>
                            <div className='badge badge-light-success fw-bold'>
                              Approved...
                            </div>
                          </div>
                        </>) : (<>
                          <div className='badge badge-light fw-bolder'>
                            <div className='badge badge-light-danger fw-bold'>
                              Pending...
                            </div>
                          </div>
                        </>)}
                      </td>
                      <td>
                        {item?.assessor_data?.find(
                          (i) => i?.assessor?.assessor_id === assessorId
                        )?.assessor_job_order?.length ? (
                          <>
                            <div className='badge badge-light fw-bolder'>
                              <div className='badge badge-light-success fw-bold'>
                                {
                                  item?.assessor_data?.find(
                                    (i) => i?.assessor?.assessor_id === assessorId
                                  )?.assessor_job_order?.[0]?.job_order_number
                                }
                              </div>
                            </div>
                          </>
                        ) : (
                          '--'
                        )}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary  fs-6'>
                        <button className='btn btn-primary btn-active-light-primary btn-sm '
                          type='button'
                          onClick={() => setAssementPlannerID(item)}
                        >
                          create Job Order
                        </button>
                      </td>
                    </tr>
                  ) :   null}
                </>
              ))}

            </tbody>
          </table>
        </div>
        



      {assementPlannerID ? <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body px-9 py-5'>
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Job Order Number: <span className="inline_master_head">XXXX-XXX</span></h4>
              <h4 className='fw-bolder m-0'>Job Order date: <span className="inline_master_head">{moment(new Date()).format("DD/MMM/YYYY")}</span></h4>
            </div>

            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Total Mandays:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, total_manday: e.target.value || '' })} value={createJobDetail?.total_manday} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Manday Rate:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, manday_rate: e.target.value || '' })} value={createJobDetail?.manday_rate} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Travel Arrangements by Assessors if any:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, arrangements_assessor: e.target.value || '' })} value={createJobDetail?.arrangements_assessor} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Value of Job Order in USD:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_usd: e.target.value || '' })} value={createJobDetail?.value_of_job_usd} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Job Order Approved By:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_approved: e.target.value || '' })} value={createJobDetail?.job_order_approved} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Job Order Issued By:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, job_order_issued: e.target.value || '' })} value={createJobDetail?.job_order_issued} />
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'>Total Value of Job Order:</h4>
              <input type="text" className="form-control form-control-solid w-50" placeholder="--" onChange={(e) => setCreateJobDetail({ ...createJobDetail, value_of_job_words: e.target.value || '' })} value={createJobDetail?.value_of_job_words} />
            </div>

            <hr className="jo_hr" />
            <div className='card-title m-0 d-flex justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0'> Complete Name and Address of Certification Body:<span className="inline_master_head">
                {/* {userData?.company},{" "} {userData?.country_name}  */}
              </span></h4>
            </div>
            <hr className="jo_hr" />
            <div className='card-title m-0 justify-content-between align-items-center w-100 px-4 py-5'>
              <h4 className='fw-bolder m-0 pb-2'>Issued on behalf of Director</h4>
              <p className="inline_master_head m-0">United Accreditation Foundation Inc (UAF) </p>
              <img
                alt='Logo'
                src={('/media/logos/uaf_logo.png')}
                className='h-30px h-lg-60px py-2 app-sidebar-logo-default theme-light-show'
              />
              <p className="inline_master_head m-0">400 North Center Dr, STE 202 </p>
              <p className="inline_master_head m-0">Norfolk, VA 23502 </p>
              <p className="inline_master_head m-0">United States of America</p>
              <p className="inline_master_head m-0">+1-757-228-5581</p>
              <p className="inline_master_head m-0">www.uafaccreditation.org </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-5">
          <button className='thm-btn' onClick={createJobPost}>
            Send
          </button>
        </div></>
        : null
      }
    </div>
  )
}

export default AssessorJobOrder