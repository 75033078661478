import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL



export const getUserDetails = async (userId: string) => {
  try {
    const response = await axios.get(`${API_URL}/team/user/data/${userId}/`);
    return response.data;
  } catch (error) {
    // Handle errors
    console.error('API Error:', error);
    // throw error;
  }
};
export const getFreeUserDetails = async (userId: string) => {
  try {
    const response = await axios.get(`${API_URL}/team/user/assessor/${userId}/`);
    return response.data;
  } catch (error) {
    // Handle errors
    console.error('API Error:', error);
    // throw error;
  }
};
export const getCertificateList = async () => {
  try {
    const response = await axios.get(`${API_URL}/certificate/list/certification/`);
    return response.data;
  } catch (error) {
    // Handle errors
    console.error('API Error:', error);
    // throw error;
  }
};

export const ChangeStatus = async (Id: string, navigate: any) => {

  try {
    const response = await axios.post(`${API_URL}/team/accessor/data/update/`, { accessor: true, user: Id });
    navigate("/assessor/verified/list")
    console.log(response.data);
    toast.success('Approved As Assessor')
  } catch (error) {
    console.log('API Error', error);
  }
}

export const handledoc = async (Id: string) => {

  try {
    const response = await axios.get(`${API_URL}/team/get/expired/link/${Id}/`);
    const url = response.config.url; // accessing the URL from the config object
    if(url !== null && url !== undefined){
      toast.success("Link is generated successfully, and send it to the Applicant")
    }
    console.log(response.data);
  } catch (error) {
    console.log('API Error', error);
  }
}
