/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { VerifiedAssessorSetting } from './VerifiedAssessorSetting'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import { VerifiedAssessorDetails } from './VerifiedAssessorDetails'


interface AssessorIDProps {
  userId: string;
}

const VerifiedAssessorOverview: React.FC<AssessorIDProps> = ({ userId }) => {
 
  return (
    <VerifiedAssessorDetails userId={userId} />
  )
}
export default VerifiedAssessorOverview;
