import React from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { Outlet, Route, Routes } from "react-router-dom";
const organisationBreadCrumbs: Array<PageLink> = [
    {
      title: 'Organisation',
      path: '/organisation/unitfunction',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
const UnitFunction: React.FC = () => {
    return (<>
        
            <PageTitle breadcrumbs={organisationBreadCrumbs}> Unit Function</PageTitle>
           
        
      <div>
        <h1>Hello, Unit Function form</h1>
        <p>This is a basic function-based component in TypeScript.</p>
      </div>
      </>
    );
  };
  
  export default UnitFunction;