import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import SiteAddressTable from './siteAddress/SiteAddressTable'
import moment from 'moment'
import AddCertificateModal from './modals/AddCertificateModal'
import {KTIcon} from '../../../../_metronic/helpers'
import * as XLSX from 'xlsx'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import UAFLoader from '../../../loader/UafLoader'

interface User {
  value: string
  label: string
}
const CertificateWrapper = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [showAddCertificateModal, setShowAddCertificateModal] = useState<boolean>(false)

  const [certificateData, setCertificateData] = useState<any>()
  const [allcertificateData, setAllCertificateData] = useState<any>()
  const [auditData, setAuditData] = useState<any>()
  const [editDataClick, setEditDataClick] = useState<any>()
  const [reportData, setReportData] = useState<any>()
  const [cabUser, setCabUser] = useState<User[]>([])
  const [cabUserData, setCabUserData] = useState<any>([])
  const [schemeFilter, setSchemeFilter] = useState<any>([])
  const [filterCabData, setFilterCabData] = useState<any>()
  const [mdReport, setMdReport] = useState<any>([])
  const [loader, setLoader] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<any>()

  const [filterDataTable, setFilterDataByTable] = useState<any>({
    cabName: '',
    scheme: '',
    startDate: '',
    endDate: '',
  })
  const {startDate, endDate} = filterDataTable

  const [countryReportData, setCountryReportData] = useState<any>()
  const fetchClusters = async () => {
    setLoader(true)
    try {
      const response = await axios.get(`${API_URL}/certificate/new/create/`)
      setCertificateData(response?.data)
      setAllCertificateData(response?.data)
      setLoader(false)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }

  useEffect(() => {
    if (editDataClick) {
      const fetchAudit = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/certificate/audit/filter/?certification=${editDataClick?.id}&cab=&create_at=&update_at=`
          )
          setAuditData(response?.data)
        } catch (error) {
          console.error('Error fetching cluster data:', error)
        }
      }
      fetchAudit()
    }
  }, [editDataClick])

  // get cab user
  useEffect(() => {
    // fetchData();
    axios
      .get(`${API_URL}/account/approved/cab/user/`)
      .then((response) => {
        console.log(response.data, 'helloosss')
        const data = response.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        }))
        setCabUser(data)
        setCabUserData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const fetchCertificateReport = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/scheme/list/`)
      setReportData(response?.data?.certificates)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }
  const fetchCountryReport = async () => {
    try {
      const response = await axios.get(`${API_URL}/certificate/country/list/`)
      setCountryReportData(response?.data?.certificates)
    } catch (error) {
      console.error('Error fetching cluster data:', error)
    }
  }

  useEffect(() => {
    fetchClusters()
    fetchCertificateReport()
    fetchCountryReport()
  }, [])

  // useEffect(() => {
  //     if (clusterFilterData) {
  //         setAddCertificateDetail({
  //             ...addCertificateDetail, cluster: clusterFilterData?.map((i) => i?.id)
  //         })
  //     }
  // }, [clusterFilterData])
  const downloadReport = () => {
    const workbook = XLSX.utils.book_new()

    // Prepare data for Excel
    const sheetData = reportData.map((item, index) => ({
      'S No': index + 1,
      'Cab Organisation': item?.cab_company_name,
      'Scheme Name': item.scheme__name,
      'Certificate Count': item.total_count,
      'Critical Code': item.iaf_critical_count,
      'Non-Critical Code': item.non_iaf_critical_count,
    }))

    // Create worksheet and append data
    const worksheet = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report Data')

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'report_data.xlsx')
  }

  const downloadExcelCountry = () => {
    const workbook = XLSX.utils.book_new()

    // Prepare data for Excel
    const sheetData = countryReportData.map((item, index) => ({
      'S No': index + 1,
      'Country Name': item.country__name,
      'Total Count': item.total_count,
    }))

    // Create worksheet and append data
    const worksheet = XLSX.utils.json_to_sheet(sheetData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Country Report Data')

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, 'country_report_data.xlsx')
  }

  const handleCabUserChange = (e) => {
    const filter = cabUserData?.find((i) => i?.id === e.value)
    setFilterCabData(filter)
    setFilterDataByTable({...filterDataTable, cabName: e?.value})

    console.log(filter, 'filterfilterfilterfilter')
    const filter2 = filter?.scheme_names?.map((i) => i)
    setSchemeFilter(filter2)
  }
  const handleCabSchemeChange = (e) => {
    setFilterDataByTable({...filterDataTable, scheme: e?.value})
  }

  const handleClickApply = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/certificate/scheme/list/data/?scheme=${filterDataTable?.scheme}&cab=${
          filterDataTable?.cabName
        }&start_date=${moment(filterDataTable?.startDate).format(
          'YYYY-MM-DD HH:mm:ss.SSS[Z]'
        )}&end_date=${moment(filterDataTable?.endDate).format('YYYY-MM-DD HH:mm:ss.SSS[Z]')}`
      )
      setMdReport(response?.data?.certificates)
    } catch (error) {
      console.log(error)
    }
  }

  const filteredData = () => {
    const filtered = certificateData?.filter((item) => {
      // Check for match in cab_name, assessment_name, assessment_number
      const matchesItem =
        item?.cab_company_name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.status?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.assessment_number?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.certificateId?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.organisation_id?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.organisation?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        item?.scheme_data.some((scheme) =>
          scheme.name?.toLowerCase().includes(searchValue?.toLowerCase())
        ) ||
        item?.standard_data.some((scheme) =>
          scheme.name?.toLowerCase().includes(searchValue?.toLowerCase())
        )

      return matchesItem
    })

    setCertificateData(filtered)
  }

  useEffect(() => {
    if (searchValue) {
      filteredData()
    } else {
      setCertificateData(allcertificateData)
    }
  }, [searchValue])
  return (
    <>
      {loader ? <UAFLoader /> : null}

      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6 p-4'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
            Details
          </a>
        </li>
        {/* <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_2"
                    >
                        Site Addresses
                    </a>
                </li> */}
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
          <div className='card-body p-9'>
            <div className='row gy-10 gx-xl-10'>
              <div className='card-title m-0 d-flex w-100 justify-content-between'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search...'
                    onChange={(e) => setSearchValue(e?.target.value)}
                  />
                </div>
                <button
                  type='button'
                  className='btn btn-primary float-right'
                  onClick={() => setShowAddCertificateModal(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Certificate
                </button>
              </div>
              <div className='col-xl-12'>
                <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 location_table_show assessmentPlanner_table p-5'
                    id='kt_table_users'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                        <th className='min-w-60px text-center'>S No</th>
                        <th className='min-w-125px'>CAB Organisation</th>
                        <th className='min-w-125px'>Status</th>
                        {/* <th className="min-w-125px">Create Date</th> */}
                        {/* <th className="min-w-125px">Update Date</th> */}
                        <th className='min-w-125px'>Issue Date</th>
                        <th className='min-w-125px'>Expiry Date</th>
                        <th className='min-w-125px'>Scheme</th>
                        <th className='min-w-125px'>Standard</th>
                        <th className='min-w-125px'>Certification Id</th>
                        <th className='min-w-125px'>Organisation Id</th>
                        <th className='min-w-125px'>Organisation</th>
                        <th className='min-w-125px'>Action</th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {certificateData?.map((item, index) => {
                        return (
                          <tr>
                            <td className='min-w-60px text-center'> {index + 1}</td>
                            <td>{item?.cab_company_name}</td>
                            <td>{item?.status}</td>
                            {/* <td>{item.create_at ? moment(new Date(item.create_at)).format("DD/MMM/YYYY") : "--"}</td> */}
                            {/* <td>{item.update_at ? moment(new Date(item.update_at)).format("DD/MMM/YYYY") : "--"}</td> */}
                            <td>
                              {item.issue_date
                                ? moment(new Date(item.issue_date)).format('DD/MMM/YYYY')
                                : '--'}
                            </td>
                            <td>
                              {item.expiry_date
                                ? moment(new Date(item.expiry_date)).format('DD/MMM/YYYY')
                                : '--'}
                            </td>
                            <td>
                              {item?.scheme_data?.map((scheme) => (
                                <React.Fragment key={scheme.id}>
                                  {scheme.name}
                                  <br />
                                </React.Fragment>
                              )) || '--'}
                            </td>
                            <td>
                              {item?.standard_data?.map((scheme) => (
                                <React.Fragment key={scheme.id}>
                                  {scheme.name}
                                  <br />
                                </React.Fragment>
                              )) || '--'}
                            </td>
                            <td>{item?.certificateId}</td>
                            <td>{item?.organisation_id}</td>
                            <td>{item?.organisation}</td>
                            <td>
                              <div className='dropdown'>
                                <button
                                  className='btn btn-light btn-active-light-primary btn-sm dropdown-toggle'
                                  type='button'
                                  id='dropdownMenuButton0'
                                  data-bs-toggle='dropdown'
                                  aria-expanded='false'
                                >
                                  Actions
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton0'>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      onClick={() => {
                                        setShowAddCertificateModal(true)
                                        setEditDataClick(item)
                                      }}
                                    >
                                      Edit
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className='col-xl-12'>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                  <h2>Report</h2>
                  <button className='btn btn-primary float-right' onClick={downloadReport}>
                    Export
                  </button>
                </div>
                {reportData?.length ? (
                  <>
                    <div
                      className='card card-xl-stretch mb-5 mb-xl-10'
                      id='kt_profile_details_view'
                    >
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5 location_table_show assessmentPlanner_table p-5'
                        id='kt_table_users'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-60px text-center'>S No</th>
                            <th className='min-w-125px'>Cab Organisation</th>
                            <th className='min-w-125px'>Scheme Name</th>
                            <th className='min-w-125px text-center'>Certificate Count</th>
                            <th className='min-w-125px text-center'>Critical Code</th>
                            <th className='min-w-125px text-center'>Non-Critical Code</th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                          {reportData?.map((i, index) => {
                            return (
                              <tr>
                                <td className='min-w-60px text-center'>{index + 1}</td>
                                <td>{i?.cab__company}</td>
                                <td>{i?.scheme__name}</td>
                                <td className='text-center'>{i?.total_count}</td>
                                <td className='text-center'>{i?.iaf_critical_count}</td>
                                <td className='text-center'>{i?.non_iaf_critical_count}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div> */}
{/* 
              <div className='col-xl-12'>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                  <h2>Country Report</h2>
                  <button className='btn btn-primary float-right' onClick={downloadExcelCountry}>
                    Export
                  </button>
                </div>
                {countryReportData?.length ? (
                  <>
                    <div
                      className='card card-xl-stretch mb-5 mb-xl-10'
                      id='kt_profile_details_view'
                    >
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5 location_table_show p-5 assessmentPlanner_table'
                        id='kt_table_users'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                            <th className='min-w-60px text-center'>S No</th>
                            <th className='min-w-125px'>Country Name</th>
                            <th className='min-w-125px text-center'>Certificate Count</th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>
                          {countryReportData?.map((i, index) => {
                            return (
                              <tr>
                                <td className='min-w-60px text-center'>{index + 1}</td>
                                <td>{i?.country__name}</td>
                                <td className='text-center'>{i?.total_count}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div> */}
              <div className='col-xl-12'>
                <div className='row p-5'>
                  <div className='mb-2 col-3'>
                    <label className='required form-label'>Cab Name</label>
                    <Select
                      className='react-select-styled react-select-solid '
                      name='User'
                      options={cabUser}
                      classNamePrefix='react-select'
                      // value={addCertificateDetail?.cab ? { value: addCertificateDetail?.cab, label: cabUser?.find(item => item.value === addCertificateDetail?.cab)?.label } : null}
                      onChange={(e) => handleCabUserChange(e)}
                    />
                  </div>
                  <div className='mb-2 col-3'>
                    <label className='required form-label'>Scheme Name</label>
                    <Select
                      className='react-select-styled react-select-solid '
                      name='User'
                      options={schemeFilter?.map((i) => ({label: i?.name, value: i?.id}))}
                      classNamePrefix='react-select'
                      // value={addCertificateDetail?.cab ? { value: addCertificateDetail?.cab, label: cabUser?.find(item => item.value === addCertificateDetail?.cab)?.label } : null}
                      onChange={(e) => handleCabSchemeChange(e)}
                    />
                  </div>
                  <div className='mb-2 col-3'>
                    <label className='required form-label'>Select Dates</label>
                    <ReactDatePicker
                      className='react-select-styled react-select-solid uaf-datepicker'
                      classNamePrefix='react-select-styled react-select-solid '
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        const [start, end] = update
                        setFilterDataByTable((prevState) => ({
                          ...prevState,
                          startDate: start,
                          endDate: end,
                        }))
                      }}
                      isClearable={true}
                    />
                  </div>
                  <div className='mb-2 col-3'>
                    <button onClick={handleClickApply} className='thm-btn'>
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className='col-xl-12'>
                <div className='d-flex justify-content-between w-100 align-items-center'>
                  <h2>Report</h2>
                </div>

                <div className='card card-xl-stretch mb-5 mb-xl-10' id='kt_profile_details_view'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 location_table_show assessmentPlanner_table p-5'
                    id='kt_table_users'
                  >
                    <thead>
                      <tr className='text-start text-muted fw-bold fs-7  gs-0'>
                        <th className='min-w-60px text-center'>S No</th>
                        <th className='min-w-125px'>
                          Country where UAF Accredited Certificates are Issued{' '}
                        </th>
                        <th className='min-w-125px text-center'>
                          Total Number of Initial and Recertifications Certificates Issued
                        </th>
                        <th className='min-w-125px text-center'>
                          Total Number of Certificates Suspended and Withdrawn
                        </th>
                        <th className='min-w-125px text-center'>
                          Total Number of Auditors (both employed and contracted) operating
                        </th>
                        <th className='min-w-125px text-center'>
                          Total Number of Transfer of Certificates accepted
                        </th>
                        <th className='min-w-125px text-center'>
                          Number of Total Audit Mandays Delivered in the country
                        </th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-semibold'>
                      {mdReport?.map((item, index) => (
                        <tr>
                          <td className='min-w-60px text-center'>{index + 1}</td>
                          <td>{item.country__name}</td>
                          <td className='text-center'>{item?.initial + item?.ReCertification} </td>
                          <td className='text-center'>{item?.suspended + item?.withdrawn} </td>
                          <td className='text-center'>{item?.certificate_audit} </td>
                          <td className='text-center'>{item?.transferred} </td>
                          <td className='text-center'>{item?.mandays}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
        <SiteAddressTable />
      </div>

      {/* <AddCertificateModal
        fetchClusters={fetchClusters}
        show={showAddCertificateModal}
        handleClose={() => {
          setShowAddCertificateModal(false)
          setAuditData([])
        }}
        editDataClick={editDataClick}
        auditData={auditData}
        cabUser={cabUser}
        setCabUser={setCabUser}
        setCabUserData={setCabUserData}
        cabUserData={cabUserData}
      /> */}
    </>
  )
}
export default CertificateWrapper
