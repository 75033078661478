import React, { useEffect, useState } from 'react';

import { UserClusterData, UserDataType } from './AssessorInterface';
import axios from 'axios';
import UAFLoader from '../../loader/UafLoader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from './verifiedAssessor/components/VerifiedAssessorFunction';

const API_URL = process.env.REACT_APP_API_URL;

interface Props {
    userId: string | undefined;
}

interface FileData {
    file: File | null;
    criticalItemId: string | null;
}

interface CriticalData {
    [key: string]: { id: string; name: string }[];
}

const AddCriticalData: React.FC<Props> = ({ userId }) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState<any>(false);
    const [userData, setUserData] = useState<UserClusterData[]>([]);
    console.log("UserDATA:",userData);
    const [userSchemeData, setUserSchemeData] = useState<UserClusterData[]>([]);
    const [userClusterData, setUserClusterData] = useState<UserClusterData[]>([]);
    const [criticalData, setCriticalData] = useState<CriticalData>({});
    const [fileData, setFileData] = useState<{ [key: string]: FileData }>({});
    const [checkedScheme, setCheckedScheme] = useState<{ [key: string]: boolean }>({});
    console.log("userClusterData:",userClusterData);
    console.log("userSchemeData:",userSchemeData);
    console.log("criticalData:",criticalData);
    console.log("fileData:",fileData);
    useEffect(() => {
        if (userId !== null && userId !== undefined) {
            const fetchDataInfo = async () => {
                try {
                    const result = await getUserDetails(userId);
                    setUserData(result?.details);
                    setUserSchemeData(result?.details?.scheme_names);
                    setUserClusterData(result?.details?.cluster_names);
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            fetchDataInfo();
        }
    }, [userId]);






    const getCriticalLoop = async (schemeId: string) => {
        try {
            const response = await axios.get(`${API_URL}/global/critical/iaf-sector/filter/?scheme=${schemeId}`);
            setCriticalData((prevData) => ({
                ...prevData,
                [schemeId]: response?.data,
            }));
            console.log(response,"response");

        } catch (error) {
            console.error('API Error:', error);
            // throw error;
        }
    };

    const handleFileChange = (schemeId: string, criticalItemId: string, file: File | null) => {
        setFileData((prevFileData) => ({
            ...prevFileData,
            [schemeId]: {
                file: file,
                criticalItemId: criticalItemId
            },
        }));
    };

    const handleDeleteFile = (schemeId: string) => {
        setFileData((prevFileData) => {
            const updatedFileData = { ...prevFileData };
            delete updatedFileData[schemeId];
            return updatedFileData;
        });
    };

    const handleCheckboxChange = (schemeId: string) => {
        setCheckedScheme((prevChecked) => {
            const isChecked = !prevChecked[schemeId];
            if (!isChecked) {
                handleDeleteFile(schemeId);
            } else {
                getCriticalLoop(schemeId);
            }
            return { ...prevChecked, [schemeId]: isChecked };
        });
    };

    const handelSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        try {
            setLoader(true);
            const formData = new FormData();
            const validUserId = userId ? userId.toString() : "defaultUserId";
            formData.append('assessor', validUserId);

            // Append fileData to formData
            Object.keys(fileData).forEach((schemeId) => {
                const { file, criticalItemId } = fileData[schemeId];
                if (file) {
                    formData.append('scheme', schemeId);
                    formData.append('critical', criticalItemId || "");
                    formData.append('file', file);
                }
            });

            const response = await axios.post(`${API_URL}/team/assessor/values/post/`, formData);
            if (response.status === 201) {
               const responseId = response?.data?.user_id;
                console.log(responseId, "responseId");
                setLoader(false);
                 toast.success('Application submitted successfully.');
                 navigate(`/assessor/verified/${userId}/overview`);

            } else {
                toast.error('Failed to submit application. Please try again.');
                setLoader(false);
            }

        } catch (error) {
            toast.error('Something went wrong');
            setLoader(false);
        }
    }

    return (
        <>
            {loader ? <UAFLoader /> : null}
            <form onSubmit={handelSubmit}>
                <div className='card mb-5'>
                    <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>
                                Upload Critical / Non Critical Doc<span style={{ color: 'red' }}>*</span>
                            </h3>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row py-3'>
                            <div className='col-lg-12 col-md-12 col-sm-12' style={{ padding: '5px' }}>
                                <div className='mb-3'>
                                    {userSchemeData.map((item) => (
                                        <div key={item.id} className='mb-3' style={{ borderBottom: '1px dotted #b1b1b1' }}>
                                            <div style={{ marginBottom: '10px' }}>
                                                <input
                                                    className='form-check-input'
                                                    style={{ fontWeight: '500', fontSize: '16px' }}
                                                    type='checkbox'
                                                    checked={!!checkedScheme[item.id]}
                                                    onChange={() => handleCheckboxChange(item?.id)}
                                                />
                                                <label className='form-check-label' style={{ marginLeft: '10px' }}>
                                                    {item?.name}
                                                </label>
                                            </div>
                                            {checkedScheme[item.id] && criticalData[item.id] && (
                                                <>
                                                    <h5 style={{ marginLeft: '20px' }}>
                                                        Any One of the Following<span style={{ color: 'red' }}>*</span>
                                                    </h5>
                                                    <div className='px-10' style={{ marginLeft: '20px' }}>
                                                        {criticalData[item.id].map((criticalItem) => (
                                                            <div
                                                                key={criticalItem.id}
                                                                className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3'
                                                            >
                                                                <div className='d-flex'>
                                                                    {/* <label className='' style={{ padding: '0 0 0 16px' }} htmlFor={criticalItem.id}></label> */}
                                                                    <label className='' style={{ padding: '3px 0 0 0' }} htmlFor={criticalItem.id}>
                                                                        {criticalItem.name}
                                                                    </label>
                                                                </div>
                                                                <div className="justify-content-end d-flex" style={{ marginLeft: 'auto' }}>
                                                                    {fileData[item.id]?.file && fileData[item.id].criticalItemId === criticalItem.id ? (
                                                                        <a>
                                                                            <i
                                                                                onClick={() => handleDeleteFile(item.id)}
                                                                                className="fa fa-trash"
                                                                                style={{ padding: "8px" }}
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </a>
                                                                    ) : (
                                                                        <input
                                                                            type="file"
                                                                            id={criticalItem.id}
                                                                            name={criticalItem.name}
                                                                            onChange={(e) => handleFileChange(item.id, criticalItem.id, e.target.files ? e.target.files[0] : null)}
                                                                            disabled={!!fileData[item.id]?.file}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-5'>
                    <button type='submit' className='btn btn-primary'>
                        Submit
                        <i className='fa fa-angle-double-right' aria-hidden='true' />
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddCriticalData;
