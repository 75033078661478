import React, {useEffect, useState} from 'react'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  uniqueCab: string | undefined
}
interface transferCertificateData {
  transferred: string
  country: string
  country__name: string
  total_count: string
}

const QMSEdit = ({uniqueCab}: Props) => {
  const cabID = uniqueCab
  const [qmsAnnexure, setQMSAnnxeure] = useState({
    iaf_1_1: '01,03, 30',
    iaf_1_2: '01, 30',
    iaf_1_3: '3',
    iaf_1_4: '',
    iaf_1_5: '',
    iaf_1_6: '',
    iaf_1_7: '',
    iaf_1_8: '',
    iaf_1_9: '17,18,19, 20, 22',
    iaf_1_10: ' 17,18,19',
    iaf_1_11: ' 20 or 22',
    iaf_1_12: '',
    iaf_1_13: '',
    iaf_1_14: '',
    iaf_1_15: '',
    iaf_1_16: '',
    iaf_1_17: ' 07a, 08, 09',
    iaf_1_18: ' 07a, 08',
    iaf_1_19: ' 9',
    iaf_1_20: '',
    iaf_1_21: '',
    iaf_1_22: '',
    iaf_1_23: '',
    iaf_1_24: '',
    iaf_1_25: '02,15 and 16',
    iaf_1_26: '16',
    iaf_1_27: '02 or 15',
    iaf_1_28: '',
    iaf_1_29: '',
    iaf_1_30: '',
    iaf_1_31: '',
    iaf_1_32: '',
    iaf_1_33: '28 and 34',
    iaf_1_34: '34',
    iaf_1_35: '28',
    iaf_1_36: '',
    iaf_1_37: '',
    iaf_1_38: '',
    iaf_1_39: '',
    iaf_1_40: '',
    iaf_1_41: '04,05,06,14, 23',
    iaf_1_42: ' 04,06, 23',
    iaf_1_43: '05 or 14',
    iaf_1_44: '',
    iaf_1_45: '',
    iaf_1_46: '',
    iaf_1_47: '',
    iaf_1_48: '',
    iaf_1_49: '07b,10 and 12',
    iaf_1_50: '07b,10',
    iaf_1_51: '12',
    iaf_1_52: '',
    iaf_1_53: '',
    iaf_1_54: '',
    iaf_1_55: '',
    iaf_1_56: '',
    iaf_1_57: ' 25,26,27',
    iaf_1_58: ' 25, 27',
    iaf_1_59: '26',
    iaf_1_60: '',
    iaf_1_61: '',
    iaf_1_62: '',
    iaf_1_63: '',
    iaf_1_64: '',
    iaf_1_65: '24,31,39',
    iaf_1_66: '31,39',
    iaf_1_67: '24',
    iaf_1_68: '',
    iaf_1_69: '',
    iaf_1_70: '',
    iaf_1_71: '',
    iaf_1_72: '',
    iaf_1_73: '29, 32, 33, 35, 36, 37',
    iaf_1_74: '29,32, 35, 36',
    iaf_1_75: '33 or 37',
    iaf_1_76: '',
    iaf_1_77: '',
    iaf_1_78: '',
    iaf_1_79: '',
    iaf_1_80: '',
    iaf_1_81: '11',
    iaf_1_82: 'Nil',
    iaf_1_83: '11',
    iaf_1_84: '',
    iaf_1_85: '',
    iaf_1_86: '',
    iaf_1_87: '',
    iaf_1_88: '',
    iaf_1_89: '13',
    iaf_1_90: 'Nil',
    iaf_1_91: '13',
    iaf_1_92: '',
    iaf_1_93: '',
    iaf_1_94: '',
    iaf_1_95: '',
    iaf_1_96: '',
    iaf_1_97: '21',
    iaf_1_98: 'Nil',
    iaf_1_99: '21',
    iaf_1_100: '',
    iaf_1_101: '',
    iaf_1_102: '',
    iaf_1_103: '',
    iaf_1_104: '',
    iaf_1_105: '38',
    iaf_1_106: 'Nil',
    iaf_1_107: '38',
    iaf_1_108: '',
    iaf_1_109: '',
    iaf_1_110: '',
    iaf_1_111: '',
    iaf_1_112: '',
  })
  const [dataId, setDataId] = useState<string>()
  const [textareaValues, setTextareaValues] = useState(Array(14).fill(''))
  const [PlanareaValues, setPlanareaValues] = useState(Array(14).fill(''))
  const [dates, setDates] = useState<Array<Date | null>>(Array(14).fill(null))
  const [transferCertificate, settransferCertificate] = useState<transferCertificateData[]>([])

  const [responseData, setResponseData] = useState<any[]>([])

  console.log(responseData, 'responseData')

  useEffect(() => {
    const fetchQMSData = async () => {
      try {
        console.log('Fetching QMS data for cabID:', cabID)
        const response = await axios.get(
          `${API_URL}/cab/global/data/filter/?cab=${cabID}&scheme=745048c9-17e9-40bc-a8d7-6ef2a91e9aee`
        )
        setResponseData(response.data[0].details)
        setDataId(response.data[0]?.id)
      } catch (error) {
        // console.error('Error fetching standard data:', error)
      }
    }
    if (cabID) fetchQMSData()
  }, [])

  useEffect(() => {
    const fetchtransferCertificate = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/certificate/scheme/list/data/?cab=${cabID}&scheme=745048c9-17e9-40bc-a8d7-6ef2a91e9aee`
        )
        settransferCertificate(response?.data?.certificates?.sort((a, b) => b.total_count - a.total_count)?.slice(0, 6))
      } catch (error) {
        console.error('Error fetching standard data:', error)
      }
    }
    fetchtransferCertificate()
  }, [])

  useEffect(() => {
    const getQMSannexureData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/arc/anexxure/report/list/?cab=${cabID}&scheme=745048c9-17e9-40bc-a8d7-6ef2a91e9aee`
        )
        if (response.status === 200 || response.status === 201) {
          console.log(response.data, 'DATA')
          const jsonData = response.data[0]?.json_value // Assuming json_value is in the first object
          // setReportId(response.data[0]?.id)
          if (jsonData) {
            setQMSAnnxeure((prevState) => ({
              ...prevState,
              ...jsonData,
            }))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    getQMSannexureData()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Prepare details array from responseData for submission
    const detailsArray = responseData.map((item, index) => ({
      iafCode: `iaf_1_${index * 8 + 1}`,
      witnessDate: item?.witnessDate,
      witnessNote: item?.witnessNote,
      conductNote: item?.conductNote,
      planNote: item?.planNote,
    }))

    const payload = {
      // emssubmit: JSON.stringify(qmsAnnexure),
      details: JSON.stringify(detailsArray),
      cab: cabID,
      scheme: '745048c9-17e9-40bc-a8d7-6ef2a91e9aee',
    }

    try {
      // Make PUT request to update data
      const response = await axios.put(`${API_URL}/cab/global/data/${dataId}/update/`, payload)
      console.log('QMS Data Updated', response.data)
      // Handle success (e.g., show success message)
      toast.success('QMS Data Updated')
    } catch (error) {
      toast.error('Error saving data')
    }
  }

  const handleDateChange = (index, date) => {
    console.log(date, 'this is date coming')
    const newResponseData = [...responseData]
    newResponseData[index].witnessDate = date
    setResponseData(newResponseData)
  }

  const handleConductareaChange = (index, value) => {
    const newResponseData = [...responseData]
    newResponseData[index].conductNote = value
    setResponseData(newResponseData)
  }

  const handleTextareaChange = (index, value) => {
    const newResponseData = [...responseData]
    newResponseData[index].witnessNote = value
    setResponseData(newResponseData)
  }

  const handlePlanareaChange = (index, value) => {
    const newResponseData = [...responseData]
    newResponseData[index].planNote = value
    setResponseData(newResponseData)
  }
  const CommonColumns = (
    <>
      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            {transferCertificate.map((item, index) => (
              <div key={index}>
                {item?.country__name}: {item?.total_count}
              </div>
            ))}
          </div>
        </div>
      </td>
      <td style={{border: '1px solid #dddddd', padding: '8px'}}>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            {transferCertificate.map((item, index) => (
              <div key={index}>
                {item?.country__name}: {item?.transferred}
              </div>
            ))}
          </div>
        </div>
      </td>
    </>
  )

  return (
    <div className='mb-5 mb-xl-8'>
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1'>QMS Assessment</span>
        </h3>
      </div>
      {/* <h4 className='card-title align-items-start flex-column mt-2'>CAB: Example CAB Name</h4> */}

      <div className='card-body py-3'>
        <form onSubmit={handleSubmit}>
          <div className='table-responsive'>
            <table className='align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-dark bg-light' style={{fontSize: '14px'}}>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    IAF codes Accredited to CABS
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Non-critical Codes
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Critical Codes
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Codes awarded (from Schedule)
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-200px rounded-start'
                  >
                    Witness to be planned in the year / Witness conducted date, Date of Witness and
                    Assessment Number
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Planning the review of client files or verification of adequate competence for
                    non-critical codes during the annual office assessment, including the assessment
                    date and assessment number.
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Total number of certificates issued, including details of the six countries with
                    the highest number of certificates issued since accreditation.
                  </th>
                  <th
                    style={{border: '1px solid #dddddd', padding: '8px'}}
                    className='ps-4 min-w-150px rounded-start'
                  >
                    Total number of certificates transferred, including details of the six countries
                    with the highest number of certificates transferred since accreditation.
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(14)].map((_, index) => (
                  <tr key={index}>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {qmsAnnexure[`iaf_1_${index * 8 + 1}`]}
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {qmsAnnexure[`iaf_1_${index * 8 + 2}`]}
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {qmsAnnexure[`iaf_1_${index * 8 + 3}`]}
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {qmsAnnexure[`iaf_1_${index * 8 + 4}`]}
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {/* <DatePicker
                            selected={
                              responseData[index]?.witnessDate
                                ? new Date(responseData[index].witnessDate)
                                : null
                            }
                            onChange={(date) => handleDateChange(index, date)}
                            showYearPicker
                            dateFormat='yyyy'
                            className='form-control mb-2'
                          /> */}
                          <p style={{fontSize: '14px', fontWeight: '500'}}>Planned:</p>{' '}
                          <textarea
                            value={responseData[index]?.witnessNote}
                            onChange={(e) => handleTextareaChange(index, e.target.value)}
                            className='form-control'
                            rows={5}
                          ></textarea>
                          <p style={{fontSize: '14px', fontWeight: '500'}} className='mt-3'>
                            Conducted:
                          </p>{' '}
                          <textarea
                            value={responseData[index]?.conductNote}
                            onChange={(e) => handleConductareaChange(index, e.target.value)}
                            className='form-control'
                            rows={5}
                          ></textarea>
                          
                        </div>
                      </div>
                    </td>
                    <td style={{border: '1px solid #dddddd', padding: '8px'}}>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <textarea
                            value={responseData[index]?.planNote}
                            onChange={(e) => handlePlanareaChange(index, e.target.value)}
                            className='form-control'
                            rows={3}
                          ></textarea>
                        </div>
                      </div>
                    </td>

                    {CommonColumns}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-5 mt-5'>
            <button type='submit' className='btn btn-primary'>
              Update
              <i className='fa fa-angle-double-right' aria-hidden='true' />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default QMSEdit
