import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import { KTIcon } from '../../../_metronic/helpers';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
interface FormState {
    title: string
    short_desc: string
    desc: string
    image: File | null
}
const BlogList = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const [blogList, setBlogList] = useState<any[]>([])
    const [blogId, setBlogId] = useState<any>()
    const [pagenumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(1);
    const [totalcount, settotalcount] = useState(0);
    const [showDeleteBlog, setshowDeleteBlog] = useState<boolean>(false)
    const formatDate = (dateString: string) => {
        return moment(dateString).format('DD/MM/YYYY')
    }
    const totalPagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);
    const handlePageChange = (page) => {
      if (page > 0 && page <= totalPages) {
        setPageNumber(page);
      }
    };
   
    const handleDeleteBlog = async () => {
        try {
          const response = await axios.delete(`${API_URL}/blog/destroy/${blogId}/`)
          if (response) {
            toast.success('Blog Deleted Successfully');
            setshowDeleteBlog(false);
            fetchBlogDAta();
            setBlogId("");
          }
        } catch (error) {
          console.log(error)
        }
      }
    const fetchBlogDAta = async () => {
        try {
          // setLoader(true);
          const response = await axios.get(
            `${API_URL}/blog/get/?page=${pagenumber}`
          );
          const pages = Math.ceil(response?.data?.count / 10);
          settotalcount(response?.data?.count);
          setTotalPages(pages);
          if (response.status === 200) {
            setBlogList(response.data?.results);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // setLoader(false);
        }
      };
    
      useEffect(() => {
        fetchBlogDAta();
      }, [pagenumber]);

      const ShowDeleteModal = (id: any) => {
        setBlogId(id)
        setshowDeleteBlog(true)
      }


    return (
        <div id="v_blog_01">

            <div className='row jusify-content-space-between'>
                <div className='col-md-6 d-flex align-items-center justify-content-start'>

                    <h2 className='mb-3 p-0 '>Blog List
                        <span
                            className='card-label fw-bold fs-1 m-2'
                            style={{ backgroundColor: '#136BA1', color: 'white', padding: '0.3em 0.5em' }}
                        >
                            {totalcount}
                        </span>
                    </h2>
                </div>
            </div>



            <div className='card '>
                <div className='card-header pt-5 ' style={{borderBottom:"1px solid #333 !important"}}>
                    <div className='card-title'>
                        {/* <div className='d-flex align-items-center position-relative my-1'>
                            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <rect
                                        opacity='0.5'
                                        x='17.0365'
                                        y='15.1223'
                                        width='8.15546'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 17.0365 15.1223)'
                                        fill='currentColor'
                                    />
                                    <path
                                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </span>
                            <input
                                id="le_lu_ri"
                                type="text"
                                data-kt-user-table-filter="search"
                                className="form-control form-control-solid w-250px ps-14"
                                onChange={handleSearchChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchChange(e);
                                    }
                                }}
                                placeholder="Search"
                            />
                        </div> */}
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' style={{ gap: "10px " }} data-kt-user-table-toolbar='base'>
                            <Link
                                id="le_lu_mai_1"
                                to="/crafted/addblog"
                                className='btn btn-primary'
                            >
                                <span className='svg-icon svg-icon-2'>
                                    <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <rect
                                            opacity='0.5'
                                            x='11.364'
                                            y='20.364'
                                            width='16'
                                            height='2'
                                            rx='1'
                                            transform='rotate(-90 11.364 20.364)'
                                            fill='currentColor'
                                        />
                                        <rect x='4.36396' y='11.364' width='16' height='2' rx='1' fill='currentColor' />
                                    </svg>
                                </span>
                                Add Blog</Link>
                        </div>
                    </div>
                </div>
                <div className='card-body pt-5'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_table_users'>
                        <thead>
                            <tr className='text-start  fw-bold  text-uppercase gs-0'>

                                <th className='min-w-100px'>Image</th>
                                <th className='min-w-125px'>Title</th>
                                <th className='min-w-125px'>Create At</th>
                                <th className='min-w-125px'>Updated At</th>
                                <th className='min-w-125px'>Action</th>

                                {/* <th className='text-center min-w-100px'>Actions</th> */}
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-semibold'>

                        {/* {(filteredValue?.length > 0 ? filteredValue : value)?.map((item, index) => ( */}
                            {blogList?.map((item, index) => (
                <tr key={index}>
                                <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <img style={{width:"80px",height:"80px"}}src={item?.image} />
                                    </div>
                                </td>
                                <td>
                                <div className='text-gray-800 text-hover-primary mb-1'>
                                    {item?.title}
                                    </div>
                                </td>

                                <td>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                    {item && item.create_at ? formatDate(item.create_at) : ''}
                                    </div>
                                </td>
                                <td>
                                    <div className='text-gray-800 text-hover-primary mb-1'>
                                    {item && item.update_at ? formatDate(item.update_at) : ''}
                                        </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-start flex-shrink-0'>

                                        <Link
                                           to = {`/crafted/blog/${item?.id}`}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        >
                                            <KTIcon iconName='pencil' className='fs-3' />
                                        </Link>
                                        <a
                                             onClick={() => ShowDeleteModal(item?.id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        >
                                            <KTIcon iconName='trash' className='fs-3' />
                                        </a>
                                    </div>
                                </td>



                            </tr>
 ))}
                        </tbody>
                    </table>
                    <ul className='pagination'>
                        <li className={`page-item ${pagenumber === 1 ? 'disabled' : ''}`}>
                            <a id="le_lu_mai_4" href='#' className='page-link' onClick={() => handlePageChange(pagenumber - 1)}>
                                <i className='previous'></i>
                            </a>
                        </li>
                        {totalPagesArray.map((page, index) => (
                            <li key={page} className={`page-item ${pagenumber === page ? 'active' : ''}`}>
                                <a id={`le_lu_page_${index}`} href='#' className='page-link' onClick={() => handlePageChange(page)}>
                                    {page}
                                </a>
                            </li>
                        ))}
                        <li className={`page-item ${pagenumber === totalPages ? 'disabled' : ''}`}>
                            <a id="le_lu_mai_5" href='#' className='page-link' onClick={() => handlePageChange(pagenumber + 1)}>
                                <i className='next'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div >
            <Modal
        show={showDeleteBlog}
        onHide={() => setshowDeleteBlog(false)}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Do you want to delete this Blog ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setshowDeleteBlog(false)}>
            Close
          </Button>
          <Button variant='danger' id='delete_cab_lead' onClick={() => handleDeleteBlog()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    )
}

export default BlogList