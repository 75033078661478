import React, { useEffect, useState } from 'react'
import { UserDataType } from '../../../modules/assessor-mangement/AssessorInterface'
import { getUserDetails } from '../../../modules/assessor-mangement/verifiedAssessor/components/VerifiedAssessorFunction';
import UAFLoader from '../../../loader/UafLoader';
import _ from 'lodash'
import axios from 'axios';
// import { string } from 'yup';

const SingleAssDash = () => {

    const BASE_URL = process.env.REACT_APP_API_URL
    const [loader, setLoader] = useState<any>(false);
    const [userData, setUserData] = useState<UserDataType>()
    const [userDataIAF, setUserDataIAF] = useState<UserDataType>()
    const [formQuery, setFormQuery] = useState<any>()
    const [assignedRole, setAssignedRole] = useState<any>()
    const [countData, setCountData] = useState<any>()
    const userId = localStorage.getItem("current_user_id");


    const fetchCountData = async () => {

        try {
            const response = await axios.get(`${BASE_URL}/cab/assessment/count/assessor?=${userId}`)
            setCountData(response?.data)

        } catch (error) {
            console.error('Error fetching cluster data:', error)
        }
    }
    useEffect(() => {
        fetchCountData()
    }, [])


    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                try {
                    setLoader(true);
                    const result = await getUserDetails(userId);
                    setUserData(result.details);
                    setUserDataIAF(result.iaf_critical);
                } catch (error) {
                    console.error('Error:', error);
                }
                setLoader(false);
            };
            fetchData();
        } else {
            console.error("User ID is null");
        }
    }, [userId]);
    useEffect(() => {
        if (userData?.programme_names) {
            setFormQuery(userData?.programme_names)
        }
    }, [userData])
    const groupedData = _.groupBy(userDataIAF, 'scheme_name')
    console.log(groupedData, 'this is grouped')
    useEffect(() => {
        if (userData?.assign_role_dict) {
            setAssignedRole(userData?.assign_role_dict)
        }
    }, [userData])
    return (
        <div id="assessor_dashboard">
            {loader ? <UAFLoader /> : null}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.total_count}
                                    </span>
                                </div>
                                <span className='pt-3 fw-semibold fs-1hx'>Total<br /> Assessments</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                       {countData?.total_completed}
                                    </span>
                                </div>
                                <span className='pt-3 fw-semibold fs-1hx'>Completed<br /> Assessments</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                       {countData?.total_pending}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Pending <br />Assessments</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-2 mb-xl-2'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.completed_report}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Submitted <br /> Assessment Reports</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.pending_report}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Pending <br /> Assessment Reports</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.opened_nc_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Open <br/>NC</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.closed_nc_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Closed <br/>NC</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.total_arc_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Assigned <br/>ARC</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.approved_arc_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Approved <br/>Reports </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-md-1 mb-xl-1'>
                    <div className='card card-flush mb-1 mb-xl-1'>
                        <div className='card-header pt-5'>
                            <div className='card-title d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className='fs-2hx fw-bold  me-2 lh-1 ls-n2'
                                        style={{ color: '#136ba1' }}
                                    >
                                        {countData?.rejected_arc_count}
                                    </span>
                                </div>
                                <span className=' pt-3 fw-semibold fs-1hx'>Rejected <br/>Reports</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5 mb-xl-8'>
                        <div className='card-header border-1'>
                            <h3 className='card-title fw-bold text-dark'>Programs Approved for</h3>
                        </div>
                        <div className='card-body scroll-container pt-6 ' >
                            {formQuery?.map((item) => (
                                <div className='d-flex align-items-center mb-8' key={item?.id}>
                                    <span className='bullet bullet-vertical h-40px bg-primary'></span>
                                    <div className='flex-grow-1' style={{ marginLeft: '10px' }}>
                                        <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                            {item?.name}
                                        </a>
                                        {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                                    </div>
                                    <span className='badge badge-light-primary fs-8 fw-bold'>Approved</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5 mb-xl-8'>
                        <div className='card-header border-1'>
                            <h3 className='card-title fw-bold text-dark'>Schemes Approved for</h3>
                        </div>
                        <div className='card-body scroll-container pt-6 accordion' id='accordionExample'  >
                            {Object.keys(groupedData)?.map((schemeName, index) => (
                                <div className='accordion-item' key={index}>
                                    <h2 className='accordion-header' id={`heading${index}`}>
                                        <button
                                            className='accordion-button'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#collapse${index}`}
                                            aria-expanded='true'
                                            aria-controls={`collapse${index}`}
                                        >
                                            <span className='bullet bullet-vertical h-40px bg-primary'></span>
                                            <div className='flex-grow-1' style={{ marginLeft: '10px' }}>
                                                <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                                    {schemeName}
                                                </span>
                                            </div>
                                            <span className='badge badge-light-primary fs-8 fw-bold'>Approved</span>
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${index}`}
                                        className='accordion-collapse collapse show' // Add the 'show' class here
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            {groupedData[schemeName].map((item, idx) => (
                                                <li key={idx}>{item.cluster_name}</li>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {assignedRole?.length > 0 ? (
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5 mb-xl-8'>
                        <div className='card-header border-1'>
                            <h3 className='card-title fw-bold text-dark'>Assigned Roles</h3>
                        </div>
                        <div className='card-body scroll-container pt-6' >
                            {assignedRole?.map((item) => (
                                <div className='d-flex align-items-center mb-8' key={item?.id}>
                                    <span className='bullet bullet-vertical h-40px bg-primary'></span>
                                    <div className='flex-grow-1' style={{ marginLeft: '10px' }}>
                                        <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                            {item?.name}
                                        </a>
                                        {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                                    </div>
                                    <span className='badge badge-light-primary fs-8 fw-bold'>Verified</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
            </div>

        </div>
    )
}

export default SingleAssDash