import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import { Link } from "react-router-dom";
import UAFLoader from "../../loader/UafLoader";
import { UserDataType } from "../assessor-mangement/AssessorInterface";
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
const API_URL = process.env.REACT_APP_API_URL

interface ClusterData {
  id: string;
  name: string;
  standard: string;
  description: string;
  scheme_id: string;
}
interface MatrixData {
  id: string;
  first_name: string;
  last_name: string;
  cluster_names: {
    id: string;
    name: string;
  }[];
}

interface VerifiedAssessor {
  id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  resume: string
  is_assessor: boolean
  is_verify_assessor: boolean
  is_potential_assessor: boolean
  assessor: string
  store_password: string
  certification_names: string[]
  create_at: string
}



const ResourceMatrixQMS: React.FC = () => {

  const [searchQuery, setSearchQuery] = useState('')
  const [clusters, setClusters] = useState<ClusterData[]>([]);
  const [userData, setUserData] = useState<MatrixData[]>([]);
  const [filteredValue, setFilteredValue] = useState<MatrixData[]>([])
  const [loader, setLoader] = useState<any>(false);
  console.log(userData, "userData");

  useEffect(() => {
    const fetchClusters = async () => {
      try {
        setLoader(true)
        const response = await axios.get(`${API_URL}/global/cluster/filter/?scheme=745048c9-17e9-40bc-a8d7-6ef2a91e9aee`);
        setClusters(response?.data);
      } catch (error) {
        console.error('Error fetching standard data:', error);
      }
      setLoader(false)
    };
    fetchClusters();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoader(true)
        const response = await axios.get(`${API_URL}/global/user/scheme/filter/?user_schemes=745048c9-17e9-40bc-a8d7-6ef2a91e9aee&is_verify_accessor=true`);
        setUserData(response?.data);
      } catch (error) {
        console.error('Error fetching standard data:', error);
      }
      setLoader(false)
    };
    fetchUsers();
  }, []);

  const getUserCertificates = (user: MatrixData) => {
    const userCertIds = user.cluster_names.map(cert => cert.id);
    return clusters.filter(cert => userCertIds.includes(cert.id));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.target?.value)
    // Filter the value array based on the search query
    const filteredData = userData.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase()
      const searchValue = e.target.value.toLowerCase()

      return (
        fullName.includes(searchValue) ||
        user?.first_name.toLowerCase().includes(searchValue) ||
        user?.last_name.toLowerCase().includes(searchValue)
      )
    })
    console.log(filteredData,"filteredData");
    setFilteredValue(filteredData)
  }

  const exportToExcel = () => {
    // Prepare the data structure
    const dataToExport: string[][] = []

    // Add the header row with assessor name and cluster names
    const headerRow: string[] = ['Assessor Name', ...clusters.map((cluster) => cluster.name)]
    dataToExport.push(headerRow)

    // Add the data rows for each assessor
    const rows: string[][] = (searchQuery.length > 0 ? filteredValue : userData).map((user) => {
      const row: string[] = [`${user.first_name} ${user.last_name}`] // First column is the assessor's name

      // Add a tick mark for each cluster the user is approved for, otherwise an empty cell
      clusters.forEach((cluster) => {
        const isApproved = getUserCertificates(user).some((c) => c.id === cluster.id)
        row.push(isApproved ? '✔️' : '') // Add a checkmark if approved
      })

      return row
    })

    // Append rows to dataToExport
    dataToExport.push(...rows)

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(dataToExport)

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Resource Matrix QMS')

    // Write the workbook and trigger a download
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" })
    saveAs(blob, "ResourceMatrixQMS.xlsx")
  }

  // Helper function to convert string to array buffer
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }

  return (
    <>
    {loader ? <UAFLoader /> : null}
    <div className="mb-5 mb-xl-8">
      <div className='card-header border-0 py-5 d-flex mb-5'>
        <h3 className='card-title align-items-start flex-column mt-2'>
          <span className='card-label fw-bold fs-1  '>Resource Matrix QMS</span>
          {userData?.length > 0 && (
  <span
    className='card-label fw-bold fs-1 m-2'
    style={{ backgroundColor: '#136ba1', color: 'white', padding: '0.3em 0.5em' }}
  >
    {userData.length}
  </span>
)}

        </h3>
        <input style={{marginLeft:"auto" ,border:"1px solid #dddddd"}}
          type='text'
          data-kt-user-table-filter='search'
          onChange={handleSearchChange}
          className='form-control form-control-solid w-500px ps-14'
          placeholder='Search Assessor'
        />
         <button
            onClick={exportToExcel}
            className='btn btn-primary ms-5'
            style={{marginLeft: '20px'}}
          >
            Export to Excel
          </button>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className=' align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-dark bg-light' style={{ fontSize: "14px" }}>
                <th style={{ border: "1px solid #dddddd", padding: "8px" }} className='ps-4 min-w-150px rounded-start'>ASSESSOR NAME</th>
                {clusters.map(item => (
                  <th style={{ border: "1px solid #dddddd", padding: "8px" }} key={item.id} className='min-w-150px'>{item.name}</th>
                ))}


              </tr>
            </thead>
            <tbody>
              {/* {userData.map((user) => ( */}
              {(searchQuery.length > 0 ? filteredValue : userData).map((user, index) => (

                <tr  /* style={{ border: "1px solid #333",...(i === userData.length - 1 && { borderBottom: "1px solid #333 !important" })}} */ >
                  <td style={{ border: "1px solid #dddddd", padding: "8px" }}>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={`/assessor/verified/${user?.id}/overview`} className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user?.first_name} {user?.last_name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  {clusters.map((cert) => (
                    <td key={cert.id} style={{ border: "1px solid #dddddd", textAlign: "center" }}>
                      {getUserCertificates(user).some(c => c.id === cert.id) ? (
                        <KTIcon iconName='check' className='fs-1 text-primary ' />
                      ) : null}
                    </td>
                  ))}

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default ResourceMatrixQMS;