import axios, { AxiosError, AxiosResponse } from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/jwt/verify`
export const LOGIN_URL = `${API_URL}/auth/jwt/create/`
export const REGISTER_URL = `${API_URL}/account/v1/registrations/`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/users/reset_password/`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  business_profile: string,
  choose_profile: string,
  choose_other_business: { id: string; name: string }[]
) {

  const res = axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
    business_profile,
    choose_profile,
    choose_other_business
})

return res
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(access: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token: access,
  })
}
