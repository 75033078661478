import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import { Location } from 'history';
import AssessmentPlannerDetailTab from "./AssessmentPlannerDetailTab";
import ReactDatePicker from 'react-datepicker';
import moment from "moment";


const organisationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Organisation',
    path: '/organisation/AssessmentPlanner/detail',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AssessmentPlannerDetail: React.FC = () => {
  const [tabChangeValue, setTabChangeValue] = useState<any>();
  const [prefillData, setPrefillData] = useState<any>();
  const [datesValue, setDatesValue] = useState<any>();
  console.log(tabChangeValue, 'tabChangeValue')
  console.log(prefillData, 'prefillData')
  console.log(datesValue, 'datesValue')

  const location = useLocation();
  const assessmentData = (location.state as any)?.rowData;
  console.log('AssessmentData:', assessmentData);
  useEffect(() => {
    if (assessmentData) {
      setTabChangeValue(assessmentData?.schemeData?.[0])
      setPrefillData(assessmentData)
      const multiDateArray = JSON.parse(assessmentData.multi_date.replace(/'/g, '"'));
      const formattedDates = multiDateArray.map(date => moment(new Date(date)).format("DD/MMM/YYYY"));

      setDatesValue(formattedDates)
    }
  }, [assessmentData])
  // https://backendapi.uafaccreditation.org/api/cab/assessor/planner/scheme/data/filter/?assessor=2d1536d0-0845-4b86-b571-10e8f234315b

//   const createJobOrder = async () => {
//     try {

//         const payload = {
//             cabId: cabId,
//             assessor_data: assessorData,
//             selected: rows,
//         };

//         const response = await axios.post(`${BASE_URL}/arc/assign/cab/assessor/`, payload);
//         console.log(response.data);
//         setAssessorData([]);
//         setCadId('');
//         setARCMemberSelect('');
//         handleClose();
//         fetchAssesorUser();
//         setRows([]);
//     } catch (error) {
//         console.error("Error:", error);
//     }
// };
  return (<>

    <PageTitle breadcrumbs={organisationBreadCrumbs}>Assessment Planner Detail</PageTitle>
    <div className='row gy-10 gx-xl-10'>
      <div className='col-xl-12'>

        <div className="container">
          <div className='row gy-10 gx-xl-10' >
            <div className="col-xl-12">
              <div className="card card-xl-stretch mb-5 mb-xl-10" id="kt_profile_details_view">
                <div className="mt-5 card-header cursor-pointer d-flex justify-content-end">
                  <div className="card-title m-0 w-100 "><h3 className="fw-bolder ">
                    Assessment Details</h3></div>
                  
                </div>
                <div className="card-body p-9">
                  <div className=" mb-7 d-flex" style={{ gap: "20px" }}>
                    <label className="col-md-3 fw-bold text-muted">Assessment Number</label>

                    <span className="fw-bolder fs-6 text-dark">{prefillData?.assessment_number}</span>

                  </div>
                  <div className="mb-7 d-flex" style={{ gap: "20px" }}>
                    <label className="col-md-3 fw-bold text-muted">Cab Organisation</label>

                    <span className="fw-bolder fs-6 text-dark">{prefillData?.cab_company}</span>

                  </div>
                  <div className="mb-7 d-flex" style={{ gap: "20px" }}>
                    <label className="col-md-3 fw-bold text-muted">Assessment Type</label>

                    <span className="fw-bolder fs-6 text-dark">{prefillData?.assessment_name}</span>

                  </div>

                  <div className="mb-7 d-flex" style={{ gap: "20px" }}>
                    <label className="col-md-3 fw-bold text-muted">Assessment Dates</label>

                    <span className="fw-bolder fs-6 text-dark">
                      {datesValue?.map((i, index) => (
                        <React.Fragment key={i}>
                          {i}
                          {index < datesValue?.length - 1 && ", "}
                        </React.Fragment>

                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {assessmentData?.schemeData?.length ?
          <>

            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 p-4">
              {assessmentData?.schemeData.map((assessment, index) => (
                <li className="nav-item" key={assessment.value}>
                  <a
                    className={`nav-link ${index === 0 ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href={`#kt_tab_pane_1`}
                    onClick={() => setTabChangeValue(assessment)}
                  // onClick={() => setTabToTab(assessment?.scheme)}
                  >
                    {assessment.scheme_short_name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade active show"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <AssessmentPlannerDetailTab tabChangeValue={tabChangeValue} />



              </div>
            </div>
          </>
          : null}


      </div>
    </div>


  </>
  );
};

export default AssessmentPlannerDetail;