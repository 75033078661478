import {useEffect, useState} from 'react'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Button, Modal} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import NewAssementModal from '../../../apps/create-assessment/create-assessment-components/modals/NewAssementModal'
import {useNavigate} from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL

const CreateAssessmentPlannerWrapper = () => {
  const navigate = useNavigate()
  const BASE_URL = process.env.REACT_APP_API_URL
  interface User {
    value: string
    label: string
  }
  // interface PendingAssessor {
  //     id: string
  //     first_name: string
  //     last_name: string
  //     email: string
  //     phone: string
  //     is_assessor: boolean
  //     is_verify_assessor: boolean
  //     assessor: string
  //     store_password: string
  //     scheme_names: { id: string; name: string }[]
  //     programme_names: { id: string; name: string }[]
  //     accessor_approve_date: string
  //     create_at: string
  // }
  const [assessmentPlannerDetails, setAssessmentPlannerDetails] = useState({
    cab: '',
    scheme: '',
    multi_date: [new Date()],
    assessment_type: '',
    assessment_number: '',
  })
  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails  ')

  const [assessmentSchemeData, setAssessmentSchemeData] = useState<any>()

  const [cabUser, setCabUser] = useState<User[]>([])
  const [schemeTable, setSchemeTable] = useState<any>([])
  const [cabUserData, setCabUserData] = useState<any>([])
  const [schemeData, setSchemeData] = useState<any>([])
  console.log(schemeData, 'schemeData')
  console.log(cabUser, 'cabUser')

  const [startDateAssessment, setStartDate] = useState(new Date())

  // const [accessorList, setAccessorList] = useState<PendingAssessor[]>([]);
  // console.log(accessorList, 'accessorList')
  const [showNewAssessmentModal, setShowNewAssessmentModal] = useState<boolean>(false)
  const [successModal, setShowSuccessModal] = useState<boolean>(false)
  const [assessmentType, setAssessmentType] = useState<string>('')
  const [reponseAssessmentNumber, setResponseAssessmentNumber] = useState<string>('')
  const [assessmentList, setAssessmentList] = useState<any>([])
  console.log(assessmentPlannerDetails, 'assessmentPlannerDetails')
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectedDates, setSelectedDates] = useState([new Date()])
  const [assessmentNumber, setAssessmentNumber] = useState<string>('')

  console.log(checkedIds, 'checkedIds')
  console.log(selectedDates, 'selectedDates')

  const [selectedAssessment, setSelectedAssessment] = useState<any>([])
  const [assessmentTypeModal, setAssessmentTypeModal] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState([null, null])
  const desiredSequence = [
    'Document Review',
    'Initial Office Assessment',
    'Surveillance Office Assessment',
    'Reaccreditation Office Assessment',
    'Witness Assessment',
    'Scope extension',
  ]
  // get accessor list
  // const fetchData = async () => {
  //     try {
  //         const response = await axios.get<PendingAssessor[]>(
  //             `${BASE_URL}/team/accessor/verified/list/`
  //         )
  //         setAccessorList(response.data)
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  // get cab user
  useEffect(() => {
    // fetchData();
    axios
      .get(`${BASE_URL}/account/approved/cab/user/`)

      .then((response) => {
        console.log(response.data, 'helloosss')
        const data = response.data?.map((item) => ({
          label: item?.company,
          value: item?.id,
        }))
        setCabUserData(response.data)
        setCabUser(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  // get assessment list
  const getAssessmentList = () => {
    axios
      .get(`${BASE_URL}/cab/assessment/list/`)
      .then((response) => {
        console.log(response.data, 'kjdsdsjdksjdkjk')
        const data = response.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
        const sorted = data?.sort(
          (a, b) => desiredSequence.indexOf(a.label) - desiredSequence.indexOf(b.label)
        )
        setAssessmentList(sorted)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getAssessmentList()
  }, [])

  // create new assessment type function
  const handleNewAssessmentType = async () => {
    const data = {
      name: assessmentType,
    }
    try {
      const response = await axios.post(`${BASE_URL}/cab/assessment/create/`, data)
      const newData = {
        label: response?.data?.name,
        value: response?.data?.id,
      }
      getAssessmentList()
      setAssessmentType('')
      setShowNewAssessmentModal(false)
      setCheckedIds([...checkedIds, response?.data?.id])
      const prevData = [...selectedAssessment, newData]
      setSelectedAssessment(prevData)
    } catch (error) {
      console.log(error)
    }
  }

  // assessment type checkbox
  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const { name, checked } = event.target;
  //     if (checked) {
  //         setCheckedIds([...checkedIds, name]);
  //     } else {
  //         setCheckedIds(checkedIds.filter(e => e !== name));
  //     }
  // };

  // const handleSelectedAssessments = () => {
  //     const selectedData = assessmentList?.filter(item => checkedIds?.includes(item?.value));
  //     setSelectedAssessment(selectedData)
  // }

  const handleCabUserChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, cab: e.value})
  }
  const handleCabSchemeChange = (e) => {
    setSchemeTable(e)
    setAssessmentPlannerDetails({...assessmentPlannerDetails, scheme: e?.map((i) => i?.value)})
  }
  useEffect(() => {
    const assessmentData = schemeTable?.map((item) => ({
      scheme: item?.value,
      assessment_duration: assessmentSchemeData.find((i) => i.scheme === item.value)
        ? assessmentSchemeData.find((i) => i.scheme === item.value).assessment_duration
        : '',
      remark: assessmentSchemeData.find((i) => i.scheme === item.value)
        ? assessmentSchemeData.find((i) => i.scheme === item.value).remark
        : '',
      assessor: '',
      major_nc_cab_start_date: '',
      major_nc_cab_closer_date: '',
      minor_nc_cab_start_date: '',
      minor_nc_cab_end_date: '',
    }))
    setAssessmentSchemeData(assessmentData)
  }, [schemeTable])

  useEffect(() => {
    if (assessmentPlannerDetails?.cab) {
      const filterData = cabUserData?.find((item) => item?.id === assessmentPlannerDetails?.cab)
      const alldata = filterData?.scheme_names?.map((e) => ({value: e?.id, label: e?.name}))
      setSchemeData(alldata)
    }
  }, [assessmentPlannerDetails?.cab])
  const handleAssessmentTypeChange = (e) => {
    setAssessmentPlannerDetails({...assessmentPlannerDetails, assessment_type: e.value})
  }
  // const handleAssessmentNameChange = (e) => {
  //     setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessor: e.value })
  // }
  const handleDateChange = (dates) => {
    setSelectedDates(dates)
    setAssessmentPlannerDetails({...assessmentPlannerDetails, multi_date: dates})
  }
  // const handleDateChange = (update) => {
  //     const [startDate, endDate] = update;
  //     setAssessmentPlannerDetails(prevState => ({
  //         ...prevState,
  //         assessment_start_date: startDate ? startDate : "", // Convert to ISO string format
  //         assessment_end_date: endDate ? endDate : "" // Convert to ISO string format
  //     }));
  //     setDateRange(update);
  // };
  const handleCreateAssessmentPlanner = async () => {
    if (!assessmentPlannerDetails?.multi_date?.length) {
      toast.error('Please add at least one date before creating the assessment planner.')
      return false
    }

    if (!assessmentPlannerDetails.assessment_number) {
      toast.error('Assessment number is required.')
      return
    }
    try {
      const data = {...assessmentPlannerDetails, scheme: assessmentSchemeData}
      const response = await axios.post(`${API_URL}/cab/create/assessor/planner/`, data)
      setAssessmentPlannerDetails({
        ...assessmentPlannerDetails,
        cab: '',
        assessment_type: '',
        assessment_number: '',
      })
      setResponseAssessmentNumber(response?.data?.assessment_number)
      console.log(response, 'response')

      setShowSuccessModal(true)
      // navigate("/organisation/AssessmentPlanner")
    } catch (error) {
      console.log(error)
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.error || 'An unexpected error occurred.')
      } else {
        toast.error('An unexpected error occurred. Please try again.')
      }
    }
  }

  // const handleCreateAssessmentPlanner = async () => {

  //   try {
  //     const data = assessmentPlannerDetails
  //     data.scheme = assessmentSchemeData
  //     const response = await axios.post(`${API_URL}/cab/create/assessor/planner/`, data)
  //     setAssessmentPlannerDetails({
  //       ...assessmentPlannerDetails,
  //       cab: '',
  //       assessment_type: '',
  //     })
  //     setResponseAssessmentNumber(response?.data?.assessment_number)
  //     console.log(response, 'response')

  //     setShowSuccessModal(true)
  //     // navigate("/organisation/AssessmentPlanner")
  //   } catch (error) {
  //       console.log(error);
  //       if (axios.isAxiosError(error) && error.response) {
  //         toast.error(error.response.data.error || 'An unexpected error occurred.');
  //       } else {
  //         toast.error('An unexpected error occurred. Please try again.');
  //       }
  //     }
  // }
  const [redirectTimer, setRedirectTimer] = useState(5)

  useEffect(() => {
    if (successModal) {
      const timer = setInterval(() => {
        setRedirectTimer((prevTimer) => prevTimer - 1)
      }, 1000)

      if (redirectTimer === 0) {
        clearInterval(timer)
        navigate('/organisation/AssessmentPlanner')
      }

      return () => clearInterval(timer)
    }
  }, [successModal, redirectTimer])

  const handleSchemeAssetDuration = (schemeItem, event) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      if (item.scheme === schemeItem.value) {
        return {...item, assessment_duration: event.target.value}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleSchemeRemark = (schemeItem, event) => {
    const updatedSchemeData = assessmentSchemeData?.map((item) => {
      console.log(item.scheme === schemeItem.value, 'kkkekekkekek')
      if (item.scheme === schemeItem.value) {
        return {...item, remark: event.target.value}
      }
      return item
    })
    setAssessmentSchemeData(updatedSchemeData)
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target
    if (checked) {
      setCheckedIds([...checkedIds, name])
    } else {
      setCheckedIds(checkedIds.filter((e) => e !== name))
    }
  }
  const handleSelectedAssessments = () => {
    const selectedData = schemeData?.filter((item) => checkedIds?.includes(item?.value))
    console.log()

    setSchemeTable(selectedData)
    setAssessmentPlannerDetails({
      ...assessmentPlannerDetails,
      scheme: selectedData?.map((i) => i?.value),
    })
  }
  return (
    <>
      <KTCard>
        <div className='container'>
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='required form-label'>CAB Organisation</label>
              <Select
                className='react-select-styled react-select-solid '
                name='User'
                // options={roleInAssessment?.map(item => ({ value: item?.id, label: item?.name }))}
                // value={roleInAssessment?.length ? { value: createJobDetail?.assessor_role, label: roleInAssessment.find(item => item.id === createJobDetail?.assessor_role)?.name } : null}
                options={cabUser}
                classNamePrefix='react-select'
                value={
                  assessmentPlannerDetails?.cab
                    ? {
                        value: assessmentPlannerDetails?.cab,
                        label: cabUser?.find((item) => item.value === assessmentPlannerDetails?.cab)
                          ?.label,
                      }
                    : null
                }
                onChange={(e) => handleCabUserChange(e)}
              />
            </div>
            <div className='mb-5 col-6'>
              <label className='required form-label'>CAB Scheme</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='--'
                value={schemeData
                  ?.filter((item) => checkedIds?.includes(item?.value))
                  ?.map((item) => item?.label)
                  ?.join(', ')}
                onClick={() => setAssessmentTypeModal(true)}
              />
              {/* <Select
                                className='react-select-styled react-select-solid '
                                isMulti
                                name="User"
                                options={schemeData}
                                classNamePrefix='react-select'
                                onChange={(e) => handleCabSchemeChange(e)}

                            /> */}
            </div>
          </div>
          {schemeTable?.length ? (
            <table className='table scheme_table'>
              <thead>
                <tr>
                  <th className='col-6'>Scheme Name</th>
                  <th className='col-3'>Assessment Duration</th>
                  <th className='col-3'>Remark</th>
                </tr>
              </thead>
              <tbody>
                {schemeTable.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={item?.label}
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type='number'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          assessmentSchemeData.find((i) => i.scheme === item.value)
                            ? assessmentSchemeData.find((i) => i.scheme === item.value)
                                .assessment_duration
                            : ''
                        }
                        onChange={(e) => handleSchemeAssetDuration(item, e)}
                      />
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='--'
                        value={
                          assessmentSchemeData.find((i) => i.scheme === item.value)
                            ? assessmentSchemeData.find((i) => i.scheme === item.value).remark
                            : ''
                        }
                        onChange={(e) => handleSchemeRemark(item, e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='required form-label'>Assessment Type</label>
              <Select
                className='react-select-styled react-select-solid '
                name='User'
                options={assessmentList}
                classNamePrefix='react-select'
                onChange={(e) => handleAssessmentTypeChange(e)}
                value={
                  assessmentPlannerDetails?.assessment_type
                    ? {
                        value: assessmentPlannerDetails?.assessment_type,
                        label: assessmentList?.find(
                          (item) => item.value === assessmentPlannerDetails?.assessment_type
                        )?.label,
                      }
                    : null
                }
              />
            </div>
            <div className='mb-5 col-6'>
              <label className='required form-label'>Assessment Number</label>
              <input
                id='assessment_number'
                type='text'
                className='form-control form-control-solid'
                placeholder='--'
                value={assessmentPlannerDetails?.assessment_number || ''}
                onChange={(e) =>
                  setAssessmentPlannerDetails((prevDetails) => ({
                    ...prevDetails,
                    assessment_number: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {/* <div className="row p-5">
                        <div className="mb-5 col-6">
                            <label className="required form-label">Remark</label>
                            <input
                                type="text" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.remark}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, remark: e.target.value })
                                }

                            />
                        </div>
                        <div className="mb-5 col-6">
                            <label className="required form-label">Assessment Duration </label>
                            <input
                                type="number" className="form-control form-control-solid" placeholder="--" value={assessmentPlannerDetails?.assessment_duration}
                                onChange={(e) => setAssessmentPlannerDetails({ ...assessmentPlannerDetails, assessment_duration: e.target.value })
                                }

                            />
                        </div>


                    </div> */}
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <label className='form-label'>Assessment Dates </label>
              <br />
              <ReactDatePicker
                placeholderText='Select Dates'
                className='uaf-datepicker'
                selectedDates={assessmentPlannerDetails?.multi_date}
                selectsMultiple
                onChange={handleDateChange}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                showYearDropdown
                showMonthDropdown
              />
              {/* <ReactDatePicker
                                placeholderText='Select Dates'
                                className='react_date_picker form-control form-control-solid'
                                selectsRange={true}
                                startDate={dateRange[0]}
                                endDate={dateRange[1]}
                                onChange={handleDateChange}
                                isClearable={true}
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                            /> */}
            </div>
          </div>
          <div className='row p-5'>
            <div className='mb-5 col-6'>
              <button className='thm-btn' onClick={handleCreateAssessmentPlanner}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </KTCard>
      <NewAssementModal
        show={showNewAssessmentModal}
        handleClose={() => setShowNewAssessmentModal(false)}
        assessmentType={assessmentType}
        setAssessmentType={setAssessmentType}
        handleNewAssessmentType={handleNewAssessmentType}
      />
      {/* assign role modal  */}
      <Modal show={successModal} size='lg' backdrop='static'>
        <Modal.Header>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body'>
            <div className='row mb-7'>
              Assessment Planner Created Successfully
              <br />
              Assessment Number:{reponseAssessmentNumber}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>Redirecting in {redirectTimer} seconds...</Modal.Footer>
      </Modal>
      <Modal
        show={assessmentTypeModal}
        onHide={() => setAssessmentTypeModal(false)}
        size='lg'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Schemes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body '>
            <div className='row mb-7'>
              {schemeData?.map((item, index) => (
                <div className='col-lg-12 fv-row d-flex form-control form-control-lg form-control-solid mb-3  '>
                  <div className=' d-flex'>
                    <input
                      type='checkbox'
                      name={item.value}
                      checked={checkedIds.includes(item.value)}
                      onChange={handleCheckboxChange}
                      id={`assess_${index}`}
                    />
                    <label
                      className=' '
                      style={{padding: '4px 0 0 14px'}}
                      htmlFor={`assess_${index}`}
                    >
                      {item.label}
                    </label>
                  </div>
                </div>
              ))}
            </div>{' '}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setAssessmentTypeModal(false)}>
            Close
          </Button>
          <Button
            variant='success'
            onClick={() => {
              handleSelectedAssessments()
              setAssessmentTypeModal(false)
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateAssessmentPlannerWrapper
