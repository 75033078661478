import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { UserDataType } from "./AssessorInterface";
import { getUserDetails } from "./assessorLead/components/AssessorFunction";
import CheckboxHierarchy from "./assessorLead/models/CheckboxHierarchy";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Modal } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL

interface Props {
    userId: string | undefined;
}
interface LocationState {
    userId?: string;
}
interface DataItem {
    id: string
    name: string
}
interface IAFCriticalData {
    id: string;
    name: string;
    scheme: string;
    iaf_sector_doc: File | null

}

interface FormState {
    first_name: string
    last_name: string
    email: string
    company: string
    phone: string
    resume: File | null
    country: string
    message: string

}



const CreateAssessorManaul: React.FC<Props> = ({ userId }) => {

    const [showPdfModal, setShowPdfModal] = useState(false)
    const [userData, setUserData] = useState<UserDataType | null>(null);
    const [userDataInfo, setUserDataInfo] = useState<UserDataType | null>(null);
    const [userCertificate, setUserCertificate] = useState<UserDataType>()
    const [userIAFData, setUserIAFData] = useState<UserDataType>()
    const [countryData, setCountryData] = useState<DataItem[]>([])
    const [pdfUrl, setPdfUrl] = useState('')
    const fetchData = async (id: string) => {

        try {
            if (id) {
                const result = await getUserDetails(id);
                setUserData(result.details);
                // Assuming these are the correct functions to set the state for certificates and IAF data
                setUserCertificate(result.documents);
                setUserIAFData(result.iaf_critical);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        if (userId !== null && userId !== undefined) {
            const fetchDataInfo = async () => {
                try {
                    const result = await getUserDetails(userId);
                    setUserData(result.details);

                } catch (error) {

                    console.error('Error:', error);
                }
            };
            fetchDataInfo();
        }
    }, [userId]);

    console.log('userId:', userId);
    console.log('userData:', userData);

    const handlePdfIconClick = (url) => {
        setPdfUrl(url)
        setShowPdfModal(true)
    }

    const handleClosePdfModal = () => {
        setShowPdfModal(false)
        setPdfUrl('') // Clear the PDF URL when the modal is closed
    }
    const [value, setValue] = useState<FormState>({
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        phone: '',
        resume: null,
        country: '',
        message: '',
    })
    console.log(value, "setValuesetValuesetValue");

    useEffect(() => {
        if (userData) {
            setValue({
                first_name: userData?.first_name || '',
                last_name: userData.last_name || '',
                email: userData.email || '',
                company: userData.company || '',
                phone: userData.phone || '',
                resume: userData.resume || null,

                country: userData.country || '',
                message: userData.message || '',

            });
        }
    }, [userData]);



    useEffect(() => {
        fetchCountry()
    }, [])

    const fetchCountry = async () => {
        try {
            const response = await axios.get<DataItem[]>(`${API_URL}/certificate/list/country/`)
            setCountryData(response.data)
        } catch (error) {

        }
    }

    const handleResume = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, resume: e.target.files ? e.target.files[0] : null })
    }
    const Validation = () => {
        if (!value?.first_name || value?.first_name?.trim() === "") {
            toast.error("Please Enter First Name")
            return false;
        }
        else if (!value?.email || value?.email?.trim() === "") {
            toast.error("Please Enter Email")
            return false;
        }
        else if (!value.country) {
            toast.error("Please Enter Country")
            return false;
        }

        return true;

    }

    const handelSubmit = async (event: React.FormEvent) => {

        if (!Validation()) return;
        event.preventDefault();

        console.log(value, 'Printed Value.......!')

        try {
            const formData = new FormData()
            formData.append('first_name', value.first_name)
            formData.append('last_name', value.last_name)
            formData.append('email', value.email)
            formData.append('company', value.company)
            formData.append('phone', value.phone)
            formData.append('country', value.country)
            formData.append('message', value.message)
            formData.append('is_verify_accessor', 'true')
            if (value.resume && value.resume instanceof File) {
                formData.append('resume', value.resume)
            }

            const response = await axios.put(`${API_URL}/account/update/user/${userId}/`, formData);

            if (response.status === 200) {
                toast.success('Application submitted successfully. Please Add Experience and Training Doc');
                const resposneId = response?.data?.user_id
                fetchData(resposneId); // Refresh user data after submission
            } else {
                toast.error('Failed to submit application. Please try again.');
            }

        } catch (error) {
            toast.error('Something went wrong, please try again')
        }
    }

    return (
        <form

            onSubmit={handelSubmit}
        >
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            Assessor Info<span style={{ color: 'red' }}>*</span>
                        </h3>
                    </div>
                </div>
                <div className='card-body p-9'>
                    <div className="row">
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='required form-label'>First Name</label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid'
                                    placeholder='First Name'
                                    name="first_name"
                                    value={value?.first_name}
                                    onChange={(e) => setValue({ ...value, first_name: e.target.value })}
                                />
                            </div>

                        </div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='required form-label'>Last Name</label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid'
                                    placeholder='Last Name'
                                    name="last_name"
                                    value={value?.last_name}
                                    onChange={(e) => setValue({ ...value, last_name: e.target.value })}
                                />
                            </div>

                        </div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='required form-label'>Email</label>
                                <input
                                    type='email'
                                    placeholder='Email address'
                                    value={value?.email}
                                    name='email'
                                    onChange={(e) => setValue({ ...value, email: e.target.value })}
                                    className='form-control form-control-solid'
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='required form-label'>Mobile</label>
                                <input
                                    type='text'
                                    placeholder='Phone Number'
                                    name='phone'
                                    value={value?.phone}
                                    onChange={(e) =>
                                        setValue({ ...value, phone: e.target.value })
                                    }
                                    className='form-control form-control-solid'
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='form-label'>Company</label>
                                <input
                                    type='text'
                                    placeholder='Company'
                                    name='Company'
                                    value={value?.company}
                                    onChange={(e) =>
                                        setValue({ ...value, company: e.target.value })
                                    }
                                    className='form-control form-control-solid'
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label className='required form-label'>Nationality</label>
                                <select
                                    onChange={(e) => setValue({ ...value, country: e.target.value })}
                                    value={value.country}
                                    className='form-control form-control-solid'
                                >
                                    <option value=''>Nationality</option>
                                    {countryData.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div></div>
                        <div className="col-lg-4 fv-row">
                            <div className='mb-5'>
                                <label
                                    htmlFor='resume'
                                    className={value.resume ? 'file-uploaded-uploaded' : 'file-upload-upload'}
                                    style={{ width: '150px' }}
                                >
                                    {value.resume ? 'CV Uploaded' : 'Upload CV'}
                                </label>
                                <input type='file' id='resume' style={{ display: 'none' }} onChange={handleResume} />
                                {value.resume ? (<>
                                    <a
                                        onClick={() => handlePdfIconClick(value.resume)}
                                        className=' me-3'
                                    >
                                        <img
                                            style={{ width: "43px" }}
                                            src='/media/doc.png' alt='' />
                                    </a>
                                </>) : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='mb-5'>

                <button type='submit' className='btn btn-primary'>

                    Update
                    <i className='fa fa-angle-double-right' aria-hidden='true' />

                </button>
            </div>
            <Modal show={showPdfModal} onHide={handleClosePdfModal} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Display the PDF in the modal */}
                    {/* <iframe src={pdfUrl} title='PDF Viewer' width='100%' height='500px'></iframe> */}
                    <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`} title='DOCX Viewer' width='100%' height='500px'></iframe>
                </Modal.Body>
            </Modal>
        </form>


    );
};

export default CreateAssessorManaul;
